import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Tree, { TreeNode } from 'rc-tree';
import { toast } from 'react-toastify';
import Tooltip from 'rc-tooltip';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { FILE, FOLDER, CLASSIFIER, NEW, RENAME, DELETE, EMPTY_OBJECT, EMPTY_STRING, COLLECTION, DEFAULT } from '../../Constants';
import { toCapitalize } from '../../_helpers/utils'
import { CircularProgress } from '@material-ui/core';

const STYLE = `
.collapse {
  overflow: hidden;
  display: block;
}
.collapse-active {
  transition: height 0.3s ease-out;
}
`;

class TextDetailsModelTree extends Component {

    state = {
        switcherIcon: <i className="fa fa-plus-square"></i>,
        selectedKeys: ['0'],
        showModal: false,
        modelData: EMPTY_OBJECT,
        modalEvent: null,
        newName: EMPTY_STRING,
    }
    componentDidMount() {
        this.getContainer();
    }
    componentWillUnmount() {
        if (this.cmContainer) {
            ReactDOM.unmountComponentAtNode(this.cmContainer);
            document.body.removeChild(this.cmContainer);
            this.cmContainer = null;
        }
    }

    getContainer() {
        if (!this.cmContainer) {
            this.cmContainer = document.createElement('div');
            this.cmContainer.setAttribute('class', 'model-contextMenuDiv');
            document.body.appendChild(this.cmContainer);
        }
        return this.cmContainer;
    }
    renderCm(info) {
        const nodeType = info.node.props.isLeaf ? FILE : DEFAULT;
        const nodeId = parseInt(info.node.props.eventKey);
        let cmList = [];

        if (nodeType === DEFAULT) {
            if (nodeId === 1 || nodeId === 72)
                cmList = [{ 'list': NEW, 'child': [{ 'list': FOLDER }, { 'list': this.props.treeType === CLASSIFIER ? CLASSIFIER : COLLECTION }] }, { 'list': RENAME }];
            else if (nodeId === 0 || nodeId === -2)
                cmList = [{ 'list': NEW, 'child': [{ 'list': FOLDER }, { 'list': this.props.treeType === CLASSIFIER ? CLASSIFIER : COLLECTION }] }];
            else
                cmList = [{ 'list': NEW, 'child': [{ 'list': FOLDER }, { 'list': this.props.treeType === CLASSIFIER ? CLASSIFIER : COLLECTION }] }, { 'list': RENAME }, { 'list': DELETE }];
        } else {
            cmList = [{ 'list': RENAME }, { 'list': DELETE }];
        }

        const cmLoop = (data) => {
            return data.map((item, i) => {
                if (item.child) {
                    return (<li key={i}>
                        <span className="contextmenu-parent">{item.list}</span>
                        <ul className="contextmenu-ul-ul">{cmLoop(item.child)}</ul></li>)
                } else {
                    return (<li onClick={() => { this.treeContextEvents(item, info) }} key={i}>
                        <span>{item.list}</span>
                    </li>)
                }
            });
        };

        if (this.toolTip) {
            if (this.cmContainer != null) {
                ReactDOM.unmountComponentAtNode(this.cmContainer);
                this.toolTip = null;
            }
        }

        this.toolTip = (
            <Tooltip
                trigger="click" placement="bottomRight" prefixCls="rc-tree-contextmenu"
                defaultVisible overlay={<ul className="contextmenu-ul">{cmLoop(cmList)}</ul>}
            >
                <span />
            </Tooltip>
        );

        const container = this.getContainer();
        Object.assign(this.cmContainer.style, {
            position: 'absolute',
            left: `${info.event.pageX}px`,
            top: `${info.event.pageY}px`,
        });

        ReactDOM.render(this.toolTip, container);
    }

    treeContextEvents = (options, node) => {
        let mevent = null;
        if (options.list === CLASSIFIER || options.list === COLLECTION) {
            mevent = FILE
        } else if (options.list === FOLDER) {
            mevent = DEFAULT;
        } else
            mevent = toCapitalize(options.list);

        this.setState({
            modelData: node.node.props,
            modalEvent: mevent,
            showModal: true,
            newName: options.list === 'Rename' ? node.node.props.title : EMPTY_STRING
        });
        ReactDOM.unmountComponentAtNode(this.cmContainer);
        document.body.removeChild(this.cmContainer);
        this.cmContainer = null;
    }

    onRightClick = (info) => {
        this.setState({ selectedKeys: [info.node.props.eventKey] });
        this.renderCm(info);
    }

    handleModalClose = () => {
        this.setState({
            ...this.state,
            modelData: EMPTY_OBJECT,
            modalEvent: null,
            showModal: false,
            newName: EMPTY_STRING
        })
    }

    updateInput = (e) => {
        this.setState({
            newName: e.target.value
        })
    }

    handleCreate = (e) => {
        if (e.key === 'Enter') {
            if (this.state.newName === EMPTY_STRING)
                return
            else {
                this.handleModalEvents();
            }
        }
    }

    handleModalEvents = () => {
        const { newName, modelData, modalEvent } = this.state;
        if (modalEvent !== DELETE) {
            if (newName === EMPTY_STRING) {
                toast.warn("Name cannot be empty.")
                return
            }
        }
        this.props.handleEvents(newName, modelData, modalEvent);
    }

    render() {

        const { treeType, treeData, loadData, selectedKeys, expandedKeys, onSelect, onExpand } = this.props;
        const { switcherIcon, showModal, modelData, newName, modalEvent } = this.state;
        const uiDialogClass = modalEvent === DEFAULT
            ? "createFolderModel"
            : modalEvent === FILE
                ? "createFileModel"
                : modalEvent === 'Delete'
                    ? "deleteModel"
                    : "renameModel"

        const nodeLoop = (data) => {
            return data.map((item) => {
                if (item.children) {
                    if (item.children.length === 0) {
                        return <TreeNode className={"emptyFolder"} key={item.key} title={item.title} data-id={item.key} data-status={item.status} data-root={item.dataRoot} />
                    } else {
                        return <TreeNode key={item.key} title={item.title} data-id={item.key} data-root={item.dataRoot}> {nodeLoop(item.children)} </TreeNode>
                    }
                } else
                    return <TreeNode key={item.key} title={item.title} data-id={item.key} data-status={item.status} isLeaf={item.isLeaf} data-root={item.dataRoot}/>
            })
        };

        return (
            <React.Fragment>
                <style dangerouslySetInnerHTML={{ __html: STYLE }} />
                <Dialog
                    open={showModal}
                    onClose={this.handleModalClose}
                    aria-describedby="model-tree-dialog"
                    fullWidth={true}
                    maxWidth={'sm'}
                    disableBackdropClick={this.props.creatingNewClassfier}
                    disableEscapeKeyDown={this.props.creatingNewClassfier}
                >
                    <div className={"uiDialog " + uiDialogClass}>
                        <div className="uiDialogTitle">
                            <h6>
                                {modalEvent === DEFAULT
                                    ? 'Create Folder'
                                    : modalEvent === FILE
                                        ? treeType === CLASSIFIER ? 'Create Classifer' : 'Create Collection'
                                        : modalEvent === 'Delete'
                                            ? 'Delete'
                                            : 'Rename'
                                }
                            </h6>
                        </div>
                        <div className="uiDialogContent">
                            {modalEvent === 'Delete'
                                ? <div>Are you sure you want to delete {modelData.isLeaf ? CLASSIFIER : FOLDER} <span className="delete-highlight">
                                    {modelData.title}
                                </span>?</div>
                                : <TextField autoComplete="off"
                                    required
                                    className="modelInput"
                                    id="newItem"
                                    label="Enter text"
                                    margin="normal"
                                    value={newName}
                                    onChange={this.updateInput}
                                    onKeyUp={this.handleCreate}
                                    autoFocus={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            }
                        </div>
                        <footer>
                            <div>
                                <Button onClick={this.handleModalClose} disabled={this.props.creatingNewClassfier}>Cancel</Button>
                                <Button onClick={this.handleModalEvents} color="primary" disabled={this.props.creatingNewClassfier}>
                                    {modalEvent === DELETE ? 'Delete' : modalEvent === RENAME ? 'Update' : 'Ok'}
                                    {this.props.creatingNewClassfier && <CircularProgress color='primary' size={20} style={{ position: 'absolute' }} />}
                                </Button>
                            </div>
                        </footer>
                    </div>

                </Dialog>
                <Tree
                    className="mtree"
                    switcherIcon={switcherIcon}
                    selectedKeys={selectedKeys}
                    expandedKeys={expandedKeys}
                    onSelect={onSelect}
                    onExpand={onExpand}
                    loadData={loadData}
                    onRightClick={this.onRightClick}
                >
                    {nodeLoop(treeData)}
                </Tree>
            </React.Fragment>
        )
    }
}

export default TextDetailsModelTree;
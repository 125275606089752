import React from "react";

import * as XLSX from "xlsx";
import ExportDialog from "./ExportDialog";


//TODO: Remove boilerplate code
export default function ImpactAnalysisExport(data) {

    const extractScore = (arr, threshold) => {
        return arr.map(s => {
            const {label, x, y} = s
            return {'Topic': label, 'Frequency': x, 'Impact': y, 'Threshold': threshold};
        });
    }

    const emptyLine ={'Topic': '', 'Frequency': '', 'Impact': '', 'Threshold': ''}

    const getSelectedThreshold = (selectedThreshold) => {
        switch(selectedThreshold) {
            case '0':
                return 'threshold_00';
            case '5':
                return 'threshold_05';
            case '10':
                return 'threshold_10';
            case '20':
                return 'threshold_20';
            default:
                return 'threshold_00';
        }
    }
    const getScore = (sentiment,scores,selectedThreshold, exportType) => {
        if(exportType === "Current Analysis Data"){
            const threshold = getSelectedThreshold(selectedThreshold)
            return extractScore(scores[threshold][sentiment], selectedThreshold)
        }else{
            return [
                ...extractScore(scores['threshold_00'][sentiment], 0),
                emptyLine,
                ...extractScore(scores['threshold_05'][sentiment], 5),
                emptyLine,
                ...extractScore(scores['threshold_10'][sentiment], 10),
                emptyLine,
                ...extractScore(scores['threshold_20'][sentiment], 20)
            ]
        }
    }

    const exportXLSX = (exportType,headers, brandName, iaDataJson, frequencyAnalysisData,selectedThreshold) => {
        const positiveScore = getScore('positive',iaDataJson,selectedThreshold,exportType)
        const negativeScore = getScore('negative',iaDataJson,selectedThreshold,exportType)
        const positiveFreq = [headers, ...frequencyAnalysisData.positive]
        const negativeFreq = [headers, ...frequencyAnalysisData.negative]
        const positiveImpactAnalysis = XLSX.utils.json_to_sheet(positiveScore);
        const negativeImpactAnalysis = XLSX.utils.json_to_sheet(negativeScore);
        const positiveFreqAnalysis = XLSX.utils.aoa_to_sheet(positiveFreq)
        const negativeFreqAnalysis = XLSX.utils.aoa_to_sheet(negativeFreq)
        const excelSheet = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(excelSheet, positiveImpactAnalysis, "Pos_AI");
        XLSX.utils.book_append_sheet(excelSheet, positiveFreqAnalysis, "Pos_freq")
        XLSX.utils.book_append_sheet(excelSheet, negativeImpactAnalysis, "Neg_AI");
        XLSX.utils.book_append_sheet(excelSheet, negativeFreqAnalysis, "Neg_Freq")
        XLSX.writeFile(excelSheet, brandName + ".xlsx");
    }

    const exportCSV = (exportType,headers, brandName, iaDataJson, frequencyAnalysisData,selectedThreshold) => {
        const positiveScore = getScore('positive',iaDataJson,selectedThreshold,exportType)
        const negativeScore = getScore('negative',iaDataJson,selectedThreshold,exportType)
        const allScore = [emptyLine,{'Topic': 'Positive', 'Frequency': 'Impact', 'Impact': 'Analysis:', 'Threshold': ''},emptyLine, ...positiveScore,
            emptyLine,{'Topic': 'Negative', 'Frequency': 'Impact', 'Impact': 'Analysis:', 'Threshold': ''},emptyLine, ...negativeScore]
        const positiveFreq = [['Frequency ' , 'Analysis'],['' , ''],headers,['' , ''], ['Positive Frequency Analysis:' , ''],['' , ''], ...frequencyAnalysisData.positive]
        const negativeFreq = frequencyAnalysisData.negative
        const aFreq = [['' , ''], ...positiveFreq,['' , ''],['Negative Frequency Analysis:' , ''],['' , ''], ...negativeFreq]
         const allFreq =  aFreq.map(arr => {
                return {'Topic':arr[0], 'Frequency':arr[1]}  ;
            });
        const impactAnalysis = XLSX.utils.json_to_sheet([...allScore, ...allFreq]);
        const excelSheet = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(excelSheet, impactAnalysis, "all");
        XLSX.writeFile(excelSheet, brandName + ".csv");
    }

    const exportData = (exportType, documentType) => {
        console.log("Selected export type " + exportType + " and selected document type " + documentType)
        const headers = ['Topic', 'Frequency'];
        const {brandName, iaDataJson, frequencyAnalysisData,selectedThreshold} = data;
        console.log("Export IA")
        if(documentType == "XLSX"){
            exportXLSX(exportType,headers, brandName, iaDataJson, frequencyAnalysisData,selectedThreshold);
        }else{
            exportCSV(exportType,headers, brandName, iaDataJson, frequencyAnalysisData,selectedThreshold)
        }

    }
    return (
        <ExportDialog exportFun={exportData}/>
    );
}
import "./topic.css";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded";
import SvgIcon from "@material-ui/core/SvgIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import cloneDeep from "lodash.clonedeep";
import {
  switchHighlight,
  selectPaletteType,
  createNewPalette,
  clearPalette,
  selectTopic,
  deleteTopic,
  deleteTopicPalette,
  updateTopicList,
} from "../../../actions/PaletteAction";
import EmotionPalette from "./EmotionPalette";
import clsx from "clsx";
import { resizeSetup } from "../../../_helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    flexBasis: 300,
  },
  formControl: {
    width: "100%",
    "& .MuiFormControlLabel-root": {
      marginRight: 0,
    },
  },
  constants: {
    color: "white",
    marginBottom: "10px",
    padding: "0 0.5em",
    fontSize: "1em",
  },
  cardActions: {
    justifyContent: "center",
  },
  listItem: {
    marginBottom: "10px",
    padding: "0 0.5em",
    fontSize: "1em",
  },
  overlay: {
    position: "absolute",
    zIndex: 100,
    width: "inherit",
    maxWidth: "inherit",
    minWidth: "inherit",
  },
  switch: {
    fontSize: "10px",
    padding: "10px 10px 10px 15px",
    display: "flex",
    justifyContent: "space-between",
  },
  topic: {
    textTransform: "uppercase",
    textAlign: "center",
    margin: "auto",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "0px 8px",
  },
  emotionTitle: {
    display: "flex",
    color: "rgb(0, 0, 0, 0.4)",
    fontSize: "16px",
    fontStyle: "italic",
    alignItems: "flex-end",
    marginTop: "18px",
    "& p": {
      width: "100%",
      margin: "0px",
      paddingBottom: "7px",
      borderBottom: "1px solid rgb(0, 0, 0, 0.4)",
    },
  },
  rightSideIcons: {
    display: "flex",
    justifyContent: "flex-end",
    maxHeight: "30px",
    "& .MuiIconButton-label": {
      fontSize: "1em",
      fontWeight: 500,
    },
    "& .MuiIconButton-sizeSmall": {
      padding: 0,
    },
  },
  resizableContainer: {
    width: "100%",
    display: "flex",
    height: "inherit",
    position: "relative",
    "& .palette-resizer": {
      cursor: "ew-resize",
      height: "100%",
      width: "16px",
      padding: "0 2px",
      transition: "height 0.4s",
      "& .palette-resize-divider": {
        width: "1px",
        height: "calc(100% - 36px)",
        marginTop: "18px",
        backgroundColor: "rgb(0, 0, 0, 0.4)",
        margin: "0 auto",
      },
    },
    "& >div": {
      height: "inherit",
    },
    "& .palette-topics-list": {
      width: "70%",
      minWidth: "50%",
      maxWidth: "70%",
      [theme.breakpoints.down(1368)]: {
        width: "65%",
        minWidth: "50%",
        maxWidth: "65%",
      },
    },
    "& .palette-emotions-list": {
      width: "calc(30% - 16px)",
      minWidth: "calc(30% - 16px)",
      maxWidth: "calc(50% - 16px)",
      flex: "1 1 0%",
      position: "absolute",
      right: 0,
      [theme.breakpoints.down(1368)]: {
        width: "calc(35% - 16px)",
        minWidth: "calc(35% - 16px)",
        maxWidth: "calc(50% - 16px)",
      },
    },
  },
}));

const TopicPaletteWidget = ({
  brandId,
  atdState,
  selectedKey,
  selectedT,
  minimized,
  setMinimized,
  handleSelectedKey,
  data,
  isSelectedTopic,
  activeColors,
  selectedPalette,
  setShouldBlockNavigation,
}) => {
  const dispatch = useDispatch();
  const test = Array(8).fill("test");
  const borderArr = ["pB", "piB", "iB", "lB", "bB", "oB", "gB", "yB"];
  const [border, setBorder] = useState({ index: null, border: "null" });
  const [paletteId, setPalette] = useState("p1");
  const [newPaletteName, setPName] = useState("");
  const [openPaletteModal, setOpenPaletteModal] = useState(false);
  const [list, setList] = useState([]);
  const [pColors, setColors] = useState(test);
  const [showEmotionPalette, setShowEmotionPalette] = useState(false);
  const [paletteChangeKeyPressed, setPaletteChangeKeyPressed] = useState(false);
  const classes = useStyles();
  const allColors = [
    "purple",
    "pink",
    "indigo",
    "lime",
    "brown",
    "orange",
    "green",
    "yellow",
  ];
  const allHex = [
    "#d1c4e9",
    "#f8bbd0",
    "#c5cae9",
    "#d4e157",
    "#d7ccc8",
    "#ffcc80",
    "#ccff90",
    "#fff176",
  ];
  const keyCodes = [49, 50, 51, 52, 53, 54, 55, 56];
  let candidate = classNames(classes.constants, "lightblue");
  let eraser = classNames(classes.constants, "black");

  const acitveColorsRef = useRef();
  const paletteKeyRef = useRef();
  const paletteIdsRef = useRef();
  const borderRef = useRef();
  const brandIdRef = useRef();

  const handlePaletteChangeKeyDown = (e) => {
    if (e.key === "p" && !paletteKeyRef.current) {
      setPaletteChangeKeyPressed(true);
    }
  };

  const handlePaletteChangeKeyUp = useCallback(
    (e) => {
      try {
        if (e.key === "p") {
          setPaletteChangeKeyPressed(false);
        } else if (paletteKeyRef.current) {
          const key = parseInt(e.key);
          if (
            key >= 0 &&
            key <= 9 &&
            data[brandIdRef.current] &&
            paletteIdsRef.current[key]
          ) {
            handleChange(null, paletteIdsRef.current[key]);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    [data]
  );

  const handleKeyPress = (key) => {
    try {
      let keyIndex = keyCodes.indexOf(key);
      let selectedTopic = list[keyIndex];
      if (selectedTopic) {
        let topic = data[brandId].topicLists.byId[selectedTopic];
        handleTopicHighlightSelection(keyIndex, topic);
      } else {
        handleSelectedKey(null);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickOpen = () => {
    setOpenPaletteModal(true);
  };

  const handleClose = () => {
    setOpenPaletteModal(false);
    setPName("");
  };

  const handleChange = useCallback(
    (e, id = "p1") => {
      try {
        e && e.preventDefault();

        let paletteTypeId = e ? e.target.value : id;
        setPalette(paletteTypeId);
        dispatch(selectPaletteType(paletteTypeId));

        if (!data || !data[brandId]) return;

        for (const pId of data[brandId].palettes.allIds) {
          const pType = data[brandId].palettes.byId[pId];
          if (paletteTypeId === pType.id) {
            setList(pType.topicList);
            dispatch(
              updateTopicList(
                pType.topicList.map(
                  (tId) => data[brandId].topicLists.byId[tId].name
                )
              )
            );
          }
        }
        setBorder({ index: null, border: "null" });
      } catch (err) {
        console.log(err);
      }
    },
    [brandId, data, dispatch]
  );

  const handlePChange = (e) => {
    e.preventDefault();
    setPName(e.target.value);
    setBorder({ index: null, border: "null" });
  };

  const handleColorsSwitchChange = (e) => {
    if (atdState === "locked") return;
    dispatch(switchHighlight(e.target.checked));
  };

  const handleNewPalette = () => {
    dispatch(createNewPalette(newPaletteName, data, brandId));
    handleClose();
    setBorder({ index: null, border: "null" });
    setShouldBlockNavigation(true);
  };

  //update the selected topic in palette
  const handleTopicHighlightSelection = (index, topic) => {
    try {
      let tpColor = allColors[index];
      let hex = allHex[index];
      let topicObj = {
        ...topic,
        color: tpColor,
        hex,
        paletteId,
        active: true,
        key: selectedKey,
      };

      dispatch(selectTopic(topicObj));
      setBorder({ index, border: borderArr });
    } catch (err) {
      console.log(err);
    }
  };

  const handlePaletteClear = () => {
    dispatch(clearPalette(selectedPalette, data, brandId));
    dispatch(updateTopicList([]));
    setList([]);
    setShouldBlockNavigation(true);
  };

  const handleDeleteTopic = (tId) => {
    dispatch(deleteTopic([selectedPalette], tId, data, brandId));
    setBorder({ index: null, border: "null" });
    setShouldBlockNavigation(true);
  };

  const deselectTopic = () => {
    let resetObj = {
      name: "null",
      color: "null",
      hex: "null",
      active: false,
    };
    dispatch(selectTopic(resetObj));
  };

  const deletePalette = () => {
    try {
      const updatedData = cloneDeep(data);
      delete updatedData[brandId].palettes.byId[paletteId];
      updatedData[brandId].palettes.allIds = updatedData[
        brandId
      ].palettes.allIds.filter((id) => id !== paletteId);
      updatedData[brandId].topicLists.allIds = updatedData[
        brandId
      ].topicLists.allIds.filter((id) => {
        const count = Object.keys(updatedData[brandId].palettes.byId).reduce(
          (acc, elm) => {
            return updatedData[brandId].palettes.byId[elm].topicList.includes(
              id
            )
              ? acc + 1
              : acc;
          },
          0
        );
        if (!count) delete updatedData[brandId].topicLists.byId[id];
        return count;
      });
      handleChange();
      dispatch(deleteTopicPalette(updatedData));
      setShouldBlockNavigation(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleEmotionPalette = () => {
    setShowEmotionPalette((state) => !state);
  };

  useEffect(() => {
    paletteKeyRef.current = paletteChangeKeyPressed;
  }, [paletteChangeKeyPressed]);

  useEffect(() => {
    borderRef.current = border && border.index;
  }, [border]);

  useEffect(() => {
    if (!data[brandId]) return;

    //set the topic list for selected palette type
    for (const pId of data[brandId].palettes.allIds) {
      const pType = data[brandId].palettes.byId[pId];
      if (paletteId === pType.id) {
        setList(pType.topicList);
        dispatch(
          updateTopicList(
            pType.topicList.map(
              (tId) => data[brandId].topicLists.byId[tId].name
            )
          )
        );
      }
    }

    //handle highlighter turned on
    if (activeColors) {
      if (!acitveColorsRef.current) {
        setColors(allColors);
        acitveColorsRef.current = activeColors;
      }
      let index = -1;
      if (selectedT && selectedT.length) {
        index = list.indexOf(selectedT[0]);
        if (index > -1) {
          let topic = data[brandId].topicLists.byId[selectedT[0]];
          if (isSelectedTopic.name === "null" && !selectedKey) {
            handleTopicHighlightSelection(index, topic);
          } else if (
            isSelectedTopic.name !== "null" &&
            isSelectedTopic.name !== topic.name
          ) {
            setTimeout(() => {
              deselectTopic();
              handleTopicHighlightSelection(index, topic);
            }, 200);
          }
        } else if (index === -1 && isSelectedTopic.name !== "null") {
          setTimeout(() => {
            deselectTopic();
          }, 200);
        }
      }
      if (isSelectedTopic.name !== "null" && index === -1) {
        deselectTopic();
      }
    } else {
      //return to highlighter off state
      if (acitveColorsRef.current) {
        setColors(test);
        acitveColorsRef.current = activeColors;
      }
      if (isSelectedTopic.name !== "null") deselectTopic();
      if (borderRef.current !== null)
        setTimeout(() => {
          setBorder({ index: null, border: "null" });
        }, 200);
    }

    paletteIdsRef.current = data[brandId].palettes.allIds;

    if (selectedKey) {
      handleKeyPress(selectedKey);
    }
  }, [data, activeColors, list, selectedT, selectedKey]);

  useEffect(() => {
    if (showEmotionPalette) {
      resizeSetup("palette-inner-resizer", 16);
    }
  }, [showEmotionPalette]);

  useEffect(() => {
    if (brandId && brandId !== brandIdRef.current) {
      brandIdRef.current = brandId;
      handleChange();
    }
  }, [brandId, handleChange]);

  useEffect(() => {
    window.addEventListener("keydown", handlePaletteChangeKeyDown);
    window.addEventListener("keyup", handlePaletteChangeKeyUp);

    return () => {
      window.removeEventListener("keydown", handlePaletteChangeKeyDown);
      window.removeEventListener("keyup", handlePaletteChangeKeyUp);
    };
  }, [handlePaletteChangeKeyUp]);

  return (
    <Card className={classes.root} variant="elevation" elevation={0}>
      <Grid
        container
        spacing={0}
        className={clsx(classes.switch, { "palette-minimized": minimized })}
      >
        <Grid item sm={10}>
          {minimized ? (
            <Typography variant="body1">Topic Palette</Typography>
          ) : (
            <FormControlLabel
              control={
                <Switch
                  onChange={handleColorsSwitchChange}
                  size="small"
                  checked={activeColors}
                  name="checkedCPalette"
                  color="primary"
                />
              }
              label="Activate Highlighter"
              labelPlacement={minimized ? "up" : "end"}
            />
          )}
        </Grid>
        <Grid item sm={2} className={classes.rightSideIcons}>
          {!minimized && (
            <Tooltip title="Show Emotion Palette">
              <IconButton
                size="small"
                aria-label="Toggle Emotion Palette"
                color={showEmotionPalette ? "primary" : "default"}
                onClick={handleEmotionPalette}
              >
                <SvgIcon fontSize="small" color="inherit">
                  <text
                    x="50%"
                    y="50%"
                    dominantBaseline="central"
                    textAnchor="middle"
                  >
                    &#x24BA;
                  </text>
                </SvgIcon>
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={(minimized ? "Open" : "Minimize") + " Topic Palette"}>
            <IconButton
              size="small"
              aria-label="minimize topic palette"
              onClick={() => setMinimized((state) => !state)}
            >
              <RemoveRoundedIcon style={{ color: minimized ? "#fff" : "" }} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <CardContent>
        <div className={classes.resizableContainer}>
          <div
            className={clsx({ "palette-topics-list": showEmotionPalette })}
            style={{ width: !showEmotionPalette ? "100%" : "" }}
          >
            {brandId && data[brandId] ? (
              <FormControl className={classes.formControl}>
                <InputLabel shrink id="simple-select-label">
                  Palette Type
                </InputLabel>
                <Select
                  labelId="simple-select-label"
                  id="simple-select"
                  value={paletteId}
                  onChange={handleChange}
                  displayEmpty
                >
                  {data[brandId].palettes.allIds.map((pId, index) => {
                    const pType = data[brandId].palettes.byId[pId];
                    return (
                      <MenuItem key={pType.id} value={pType.id}>
                        {pType.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : null}
            {data[brandId] && (
              <List className={classes.overlay}>
                {list.map((tId, index) => {
                  let topic = data[brandId].topicLists.byId[tId];
                  return topic ? (
                    <ListItem key={index} className={classes.listItem}>
                      <ListItemText
                        disableTypography
                        primary={index + 1}
                        style={{ flex: "none", visibility: "hidden" }}
                      />
                      <div className={classes.topic}>{topic.name}</div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDeleteTopic(topic.id)}
                      >
                        <DeleteIcon
                          style={{ fontSize: "16px", verticalAlign: "middle" }}
                        />
                      </div>
                    </ListItem>
                  ) : null;
                })}
              </List>
            )}
            <List>
              {pColors.map((color, index) => {
                return (
                  <ListItem
                    key={index}
                    className={classNames(
                      border.index === index &&
                        activeColors &&
                        isSelectedTopic.active
                        ? border.border[index]
                        : border.index === index && !activeColors
                        ? "noColor"
                        : color,
                      classes.listItem
                    )}
                  >
                    <ListItemText disableTypography primary={index + 1} />
                  </ListItem>
                );
              })}
              <ListItem className={candidate}>
                <ListItemText disableTypography primary="9" />
                <ListItemText disableTypography primary="Candidates" />
              </ListItem>

              <ListItem className={eraser}>
                <ListItemText disableTypography primary="0" />
                <ListItemText disableTypography primary="Eraser" />
                <FontAwesomeIcon size="lg" icon={faEraser} />
              </ListItem>
            </List>
          </div>
          {showEmotionPalette && (
            <div
              className="palette-resizer"
              id="palette-inner-resizer"
              style={{ height: "inherit" }}
            >
              <div className="palette-resize-divider"></div>
            </div>
          )}
          {showEmotionPalette && (
            <div className="palette-emotions-list">
              <div className={classes.emotionTitle}>
                <p>Emotions</p>
              </div>
              <EmotionPalette />
            </div>
          )}
        </div>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleClickOpen}
        >
          Create New
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handlePaletteClear}
        >
          Clear
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={deletePalette}
          disabled={paletteId === "p1"}
        >
          Delete
        </Button>
      </CardActions>

      <Dialog
        open={openPaletteModal}
        onClose={handleClose}
        aria-labelledby="create-new-palette"
      >
        <DialogTitle id="create-new-palette">Create New Palette</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name of new palette"
            type="text"
            value={newPaletteName}
            onChange={handlePChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleNewPalette} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.topicPalettes,
    activeColors: state.isActiveHighlight,
    selectedPalette: state.selectedPalette,
    isSelectedTopic: state.selectedTopic,
  };
};

export default React.memo(connect(mapStateToProps, null)(TopicPaletteWidget));

import React from 'react';
import Fullscreen from "react-full-screen";
import { toast } from "react-toastify";
import {
    Table, TableBody, TableCell, TableHead, TableFooter, TablePagination, TableRow, TableSortLabel, Tooltip, IconButton, Input, InputAdornment
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import client from "../../../_helpers/client";
import { EMPTY_STRING, SENTIMENT, PERFORMANCE, PERSONA, PURCHASEPATH, TOPICS, EMPTY_EXPORT_DATA, ERROR_IN_PROCESS } from '../../../Constants';
import { stableSort, getSorting } from '../../../_helpers/utils';
import { EXPORT_TOPICS_TABLE, EXPORT_PURCHASEPATH_TABLE, EXPORT_PERSONA_TABLE, EXPORT_PERFORMANCE_TABLE, EXPORT_SENTIMENT_TABLE } from '../../../Routes';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';

function columnHeadings(tableType) {
    if (tableType === SENTIMENT) {
        return [{ id: 'media', label: 'Media' }, { id: 'positive', label: 'Positive' }, { id: 'negative', label: 'Negative' }, { id: 'neutral', label: 'Neutral' }]
    } else if (tableType === TOPICS) {
        return [{ id: 'attribute', label: 'Topic' }, { id: 'count', label: 'Count' },]
    } else if (tableType === PERFORMANCE) {
        return [{ id: 'attribute', label: 'Performance' }, { id: 'count', label: 'Count' },]
    } else if (tableType === PERSONA) {
        return [{ id: 'attribute', label: 'Persona' }, { id: 'count', label: 'Count' },]
    } else {
        return [{ id: 'attribute', label: 'Purchasepath' }, { id: 'count', label: 'Count' },]
    }
};

class MediaTable extends React.Component {

    csvLink = React.createRef()
    state = {
        page: 0,
        rowsPerPage: 7,
        order: 'asc',
        orderBy: 'attribute',
        searchKey: EMPTY_STRING,
        tablePreloader: true,
        isFull: false,
        isSearchVisible: false,
        csvData: []
    }

    toastId = null;

    createSortHandler = property => event => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    };

    handleExportCSV2 = (name, responseData) => {
        const blob = new Blob([responseData], {type: "text/csv;charset=utf-8;"});
        const url = window.URL.createObjectURL(new Blob([blob]));
        var link=document.createElement('a');
        document.body.appendChild(link);
        link.href=url;
        link.download = name+".csv";
        link.click();
        document.body.removeChild(link);

        toast.update(this.toastId, {
            type: toast.TYPE.SUCCESS,
            render: name + "data exported successfully.",
            autoClose: 3500,
        })
    }

    handleChangePage = (event, page) => this.setState({ page: page, });
    showSearchInput = () => this.setState({ isSearchVisible: true })
    clearSearch = () => this.setState({ searchKey: EMPTY_STRING, isSearchVisible: false, data: this.props.data })
    goFull = () => this.setState({ isFull: !this.state.isFull });

    exportTableData = chartType => {
        this.toastId = toast(chartType + " data export in progress, please wait...", {
            autoClose: false
        });
        const url = chartType === SENTIMENT
                        ? EXPORT_SENTIMENT_TABLE
                        : chartType === PERFORMANCE
                        ? EXPORT_PERFORMANCE_TABLE
                        : chartType === PERSONA
                            ? EXPORT_PERSONA_TABLE
                            : chartType === PURCHASEPATH
                                ? EXPORT_PURCHASEPATH_TABLE
                                : EXPORT_TOPICS_TABLE;

        client
            .get(url)
            .then( res => {
                let csvData = res.data;
                if(csvData === EMPTY_EXPORT_DATA) 
                    toast.update(this.toastId, {
                        type: toast.TYPE.ERROR,
                        render: EMPTY_EXPORT_DATA,
                        autoClose: 3500,
                  });
                else {
                    this.handleExportCSV2(chartType, csvData);
                } 
            })
            .catch(error => {
                console.log(error)
                toast.update(this.toastId, {
                    type: toast.TYPE.ERROR,
                    render: ERROR_IN_PROCESS,
                    autoClose: 3500,
              });
            });
    }

    render() {
        const { chartTitle, data } = this.props;
        const { page, rowsPerPage, order, orderBy, searchKey, isFull } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
        const reChartTitleArry = chartTitle.split(" ");
        const reChartTitle = reChartTitleArry[0];
        const colHead = columnHeadings(reChartTitle);

        return (
            <React.Fragment>
                <ErrorBoundary>
                <Fullscreen
                    enabled={isFull}
                    onChange={isFull => this.setState({ isFull })}
                >
                    <div className={this.state.isSearchVisible ? "chartHolder seachActive" : "chartHolder"}>
                        <div className="chartTitle">
                            <div className="chartLabel">{chartTitle}</div>
                            <div className="chartTitleActions">
                                <Tooltip title="Export" placement="left"><span>
                                       <IconButton
                                        disabled={data.length === 0}
                                        aria-label="Export"
                                        data-testid="exportBtn"
                                        onClick={()=>this.exportTableData(reChartTitle)}
                                    > <i className="fa fa-download"></i></IconButton>
                                </span></Tooltip>
                                <Tooltip title="Search" placement="left"><span>
                                    <IconButton
                                        disabled={data.length === 0}
                                        aria-label="Search"
                                        onClick={this.showSearchInput}
                                        data-testid="searchBtn"
                                    >
                                        <i className="fa fa-search"></i>
                                    </IconButton>
                                </span></Tooltip>
                                <Tooltip title="Full Screen" placement="left"><span>
                                    <IconButton disabled={data.length === 0} aria-label="Full Screen" onClick={this.goFull} data-testid="fullscreen">
                                        <i className="fa fa-expand"></i></IconButton>
                                </span></Tooltip>
                            </div>
                            {this.state.isSearchVisible
                                ? <Input
                                    id="grid-table-serach"
                                    value={searchKey}
                                    disableUnderline
                                    placeholder="Search here..."
                                    onChange={(e) => { this.setState({ searchKey: e.target.value }) }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Tooltip title="Clear Search" placement="left">
                                                <IconButton aria-label="Clear Search" onClick={this.clearSearch} data-testid="clearSearch"><Close /></IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    }
                                    autoFocus
                                    inputProps={{ "data-testid": "searchBar" }}
                                />
                                : null}
                        </div>
                        <div className="chartContent">
                            {data.length === 0
                                ? <p className="noData">NO DATA</p>
                                : <Table>
                                    <TableHead>
                                        <TableRow>
                                            {colHead.map((row, i) => {
                                                return <TableCell key={i} sortDirection={orderBy === row.id ? order : false}>
                                                    {reChartTitle === SENTIMENT
                                                        ? row.label
                                                        : <Tooltip
                                                            title="Sort"
                                                            placement={'bottom-start'}
                                                            enterDelay={300}
                                                        >
                                                            <TableSortLabel
                                                                active={orderBy === row.id}
                                                                direction={order}
                                                                onClick={this.createSortHandler(row.id)}
                                                            >
                                                                {row.label}
                                                            </TableSortLabel>
                                                        </Tooltip>
                                                    }
                                                </TableCell>
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {stableSort(data, getSorting(order, orderBy))
                                            .filter(row => 
                                                row.attribute.toLowerCase().startsWith(searchKey.toLowerCase())
                                            )
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, i) => {
                                                return reChartTitle === SENTIMENT
                                                    ? <TableRow key={i}>
                                                        <TableCell>{row.attribute}</TableCell>
                                                        <TableCell>{row.positive}</TableCell>
                                                        <TableCell>{row.negative}</TableCell>
                                                        <TableCell>{row.neutral}</TableCell>
                                                    </TableRow>
                                                    : <TableRow key={i}>
                                                        <TableCell>{row.attribute}</TableCell>
                                                        <TableCell>{row.count}</TableCell>
                                                    </TableRow>
                                            })
                                        }
                                        {emptyRows > 0 && (
                                            <TableRow className="emptyRows" style={{ height: 42 * emptyRows }}>
                                                <TableCell colSpan={reChartTitle === SENTIMENT ? 4 : 2} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[6]}
                                                rowsPerPage={rowsPerPage}
                                                colSpan={reChartTitle === SENTIMENT ? 4 : 2}
                                                count={data.length}
                                                page={page}
                                                backIconButtonProps={{ 'aria-label': 'Previous Page', }}
                                                nextIconButtonProps={{ 'aria-label': 'Next Page', }}
                                                onChangePage={this.handleChangePage}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            }
                        </div>
                    </div>
                </Fullscreen>
                </ErrorBoundary>
            </React.Fragment>
        )
    }
}

export default MediaTable;
import "./Workshop.css";
import React, { Suspense } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import uuid from "uuid/v4";
import {
  Grid,
  Paper,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  IconButton,
  InputAdornment,
  Tooltip,
  Zoom,
  Fab,
  Button
} from "@material-ui/core";

import { 
  SearchOutlined, 
  CloseOutlined,
  ArrowBack,
  ArrowDownward,
  ArrowUpward,
  Autorenew
} from "@material-ui/icons";
import AddCircleIcon from '@material-ui/icons/AddCircle';

import {
  GET_BRANDS,
  GET_BRANDS_DATATABLE,
  GET_MODELS,
  GET_MODELS_DATATABLE,
  GET_CLASSIFIERS,
  GET_CLASSIFIERS_DATATABLE,
  GET_COLLECTIONS,
  GET_COLLECTIONS_DATATABLE,
  SEARCH_BRANDS,
  SEARCH_MODELS,
  SEARCH_CLASSIFIERS,
  SEARCH_COLLECTIONS,
  GET_BRAND_BREADCRUMB,
  GET_CLASSIFIER_BREADCRUMB,
  GET_MODEL_BREADCRUMB,
  GET_COLLECTION_BREADCRUMB,
  GET_CATALOGUE,
  GET_CATALOGUE_DATATABLE,
  GET_CATALOGUE_BREADCRUMB,
  UPDATE_CATALOGUE,
  CREATE_CATALOGUE,
  SEARCH_CATALOGUE,
  IS_CATALOGUE_EXISTS,
  REFRESH_MODEL
} from "../../Routes";
import {
  BRAND,
  MODEL,
  CLASSIFIER,
  CATALOGUE,
  SUCCESS,
  WARN,
  EMPTY_MSG,
  EMPTY_STRING,
  ASCENDING_ORDER,
  DEFAULT_ROWS_PER_PAGE,
  DEFAULT_PAGE,
  DEFAULT_COUNT,
  EMPTY_ARRAY,
  DEFAULT,
  VIRTUAL_CLASSIFIER,
  CALCULATED_CLASSIFIER,
  PLAN_PROFESSIONAL,
  PLAN_BASIC
} from "../../Constants";

import client from "../../_helpers/client";
import Breadcrumb from "../../containers/Breadcrumb/Breadcrumb";
import TreeComponent from "./TreeView/TreeView";
import { getUserDetails } from "../../_helpers/index";
import DataPreloader from "../../containers/Preloader/DataPreloader";
import CreateProjectModal from "./CreateProjectModal/CreateProjectModal";
import CatalogueForm from './CatalogueFormModal/CatalogueFormModal';
import {Auth} from "aws-amplify";

const TableView = React.lazy(() => import('./TableView/TableView'));
const EditBrand = React.lazy(() => import('../Brand/EditBrand'));
const EditModel = React.lazy(() => import('../Models/EditModel'));
const EditCollection = React.lazy(() => import('../Collections/Collections'));
const EditClassifier = React.lazy(() => import('../Classifiers/EditClassifier'));
const CandidatePhrases = React.lazy(()=> import('../ListView/CandidatePhrases/CandidatePhrases'));
const GramPhrases = React.lazy(()=> import('../ListView/GramPhrases/GramPhrases'));
const TopicsList = React.lazy(()=> import('../ListView/Topics/Topics'));
const PhrasesList = React.lazy(()=> import('../ListView/Phrases/Phrases'));

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    textAlign: "left",
    color: theme.palette.text.secondary
  },
  paperNoPadding: {
    padding: "0px !important",
    textAlign: "left",
    border: '1px solid #BDBDBD'
  },
  formControl: {
    minWidth: 120,
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  singleModule: {
    padding: "18.5px 14px",
    borderRadius: 4,
    border: "1px solid rgba(0, 0, 0, 0.23)",
    color: "rgba(0,0,0,0.78)",
    fontSize: "1rem"
  }
});

class WorkshopHandler extends React.Component {
  state = {
    loadingTree: true,
    loadingTable: true,
    twitterCallbackDetails: null,
    userPlan: PLAN_BASIC,
    treeView: BRAND,
    openEdit: false,
    labelWidth: DEFAULT_COUNT,
    breadcrumbs: EMPTY_ARRAY,
    itemId: null,
    itemShared: null,
    nodeProps: { "data-shared": null },
    treeSearchReg: EMPTY_STRING,
    treeData: EMPTY_ARRAY,
    expandedKey: ["0"],
    selectedKey: ["0"],
    unfilterdTableData: EMPTY_ARRAY,
    tableData: EMPTY_ARRAY,
    tableOrder: ASCENDING_ORDER,
    tableOrderBy: "name",
    tablePageSize: DEFAULT_ROWS_PER_PAGE,
    tablePageNo: DEFAULT_PAGE,
    tableSearchReg: EMPTY_STRING,
    tableTotalRows: DEFAULT_COUNT,
    isSearch: false,
    isListView: false,
    frameType: "undefined",
    editItem: null,
    nodeParentId: null,

    listView: false,
    listViewNode: null,
    selectedTopic: null,
    candidateRefresh: uuid(),
    gramPhrasesRefresh: uuid(),
    topicsRefresh: uuid(),
    phrasesRefresh: uuid(),
    isAutoRegex: false,
    openProjectModal: false,
    projectDetails: null,
    editCatalogueData: null,
    openCatalogueForm: false,
    savingCatalogueFileData: false,
    rootId: 0,
    treeSortOrder: 'asc',
    treeSortBy: 'name'
  };

  modelClassiferEditUpdate = false;

  //Get Tree Data - Callback
  getTreeData = expandNode => {
    this.setState({
      loadingTree: true,
      treeData: EMPTY_ARRAY,
      expandedKey: EMPTY_ARRAY
    });

    let treeUrl =
      this.state.treeView === BRAND
        ? GET_BRANDS
        : this.state.treeView === MODEL
        ? GET_MODELS
        : this.state.treeView === CLASSIFIER
        ? GET_CLASSIFIERS
        : this.state.treeView === CATALOGUE
        ? GET_CATALOGUE
        : GET_COLLECTIONS;

    client
      .get(treeUrl, { params: {
        parentId: "#",
        isAutoRegex: false,
        sortColumn: this.state.treeSortBy,
        sortOrder: this.state.treeSortOrder
      }})
      .then(res => {
        if (res.data.status === SUCCESS) {
          this.setState({
            ...this.state,
            treeData: res.data.response,
            isSearch: false,
            loadingTree: false,
            treeSearchReg: EMPTY_STRING
          });
          setTimeout(() => {
            this.setState({ ...this.state, expandedKey: expandNode });
          }, 400);
        } else {
          this.setState({
            ...this.state,
            isSearch: false,
            loadingTree: false,
            treeSearchReg: EMPTY_STRING
          });
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
        }
      })
      .catch(error => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
        this.setState({ ...this.state, openEdit: false, loadingTree: false });
      });
  };

  //Get Table Data - Callback
  getTableData = (nodeId, order, orderBy, page, rowsPerPage, searchReg, dataRoot) => {
    this.setState({ loadingTable: true, tableData: EMPTY_ARRAY });
    const { itemShared } = this.state;

    const isAutoRegex = () => {
      if(nodeId) {
        if(nodeId.toString() === '-4') {
          return true
        } else {
          if(dataRoot) {
            if(dataRoot === -4)
              return true
            else
              return false
          } else 
            return false
        }
      }
    }  

    const isAutoRegexVal = isAutoRegex();

    let tableUrl =
      this.state.treeView === BRAND
        ? GET_BRANDS_DATATABLE
        : this.state.treeView === MODEL
        ? GET_MODELS_DATATABLE
        : this.state.treeView === CLASSIFIER
        ? GET_CLASSIFIERS_DATATABLE
        : this.state.treeView === CATALOGUE
        ? GET_CATALOGUE_DATATABLE
        : GET_COLLECTIONS_DATATABLE;

    const tableDataParams = {
      pageSize: rowsPerPage,
      pageNo: page,
      sortColumn: orderBy,
      sortOrder: order,
      searchWord: searchReg
    }

    if (this.state.treeView === CATALOGUE) {
      tableDataParams.id = nodeId.toString();
    } else {
      tableDataParams.parentId = nodeId.toString();
      tableDataParams.isShared = itemShared;
      tableDataParams.isAutoRegex = isAutoRegexVal;
    }

    client
      .get(tableUrl, {
        params: tableDataParams
      })
      .then(res => {
        if (res.data.status === SUCCESS) {

          const md = res.data.response.data.map(t => {
            t['isAutoRegex'] = isAutoRegexVal;
            return t
          })

          this.setState({
            selectedKey: nodeId,
            tableData: md,
            unfilterdTableData: res.data.response.data,
            tableOrder: res.data.response.sortOrder,
            tableOrderBy: res.data.response.sortColumn,
            tablePageSize: res.data.response.pageSize,
            tablePageNo: res.data.response.pageNo,
            tableSearchReg: res.data.response.searchWord,
            tableTotalRows: res.data.response.total,
            openEdit: false,
            loadingTable: false
          });
        } else {
          this.setState({
            openEdit: false,
            loadingTable: false
          });
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
        }
      })
      .catch(error => {
        this.setState({
          openEdit: false,
          loadingTable: false
        });
        toast.error(error.message);
      });
  };

  //Get Breadcrumbs
  getBreadCrumb = id => {
    if (!id || id === "undefined") {
      toast.error("unable to get breadcrumbs.");
      return;
    }
    let url =
      this.state.treeView === BRAND
        ? GET_BRAND_BREADCRUMB
        : this.state.treeView === CLASSIFIER
        ? GET_CLASSIFIER_BREADCRUMB
        : this.state.treeView === MODEL
        ? GET_MODEL_BREADCRUMB
        : this.state.treeView === CATALOGUE
        ? GET_CATALOGUE_BREADCRUMB
        : GET_COLLECTION_BREADCRUMB;

    client
      .get(url, { params: { parentId: id.toString() } })
      .then(res => {
        if (res.data.status === SUCCESS)
          this.setState({
            ...this.state,
            breadcrumbs: res.data.response.breadCrumbPath
          });
        else {
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
        }
      })
      .catch(error => {
        if (error.response) toast.error(error.response.data.message);
      });
  };

  //Dynamic Tree Nodes Render Event
  handleDynamicNodes = (curKey, child) => {
    const { treeData } = this.state;
    const loop = data => {
      data.forEach(item => {
        if (curKey === item.key) {
          item.children = child;
        } else {
          if (item.children) loop(item.children);
        }
      });
    };
    loop(treeData);

    this.setState({
      ...this.state,
      treeData: treeData
    });
  };

  // Render List View
  handleListView = treeNode => {
    this.setState({
      isListView: false,
      screenLoader: true,
      selectedTopic: null
    });
    setTimeout(() => {
      this.setState({
        ...this.state,
        listViewNode: treeNode.node.props,
        isListView: true,
        openEdit: false,
        screenLoader: false
      });
    }, 400);
  };

  //Send Search Tree Node Request
  searchTreeNodes = searchWord => {
    const { treeView } = this.state;
    this.setState({ ...this.state, treeData: [], loadingTree: true });

    let treeUrl =
      treeView === BRAND
        ? SEARCH_BRANDS
        : treeView === MODEL
        ? SEARCH_MODELS
        : treeView === CLASSIFIER
        ? SEARCH_CLASSIFIERS
        : treeView === CATALOGUE
        ? SEARCH_CATALOGUE
        : SEARCH_COLLECTIONS;

    client
      .get(treeUrl, { params: {
        searchWord: searchWord,
        sortColumn: this.state.treeSortBy,
        sortOrder: this.state.treeSortOrder
      }})
      .then(res => {
        if (res.data.status === SUCCESS) {
          this.setState({
            ...this.state,
            treeData: res.data.response,
            loadingTree: false,
            isSearch: true
          });
        } else {
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
          this.setState({
            ...this.state,
            loadingTree: false,
            isSearch: false
          });
        }
      })
      .catch(error => {
        if (error.response) toast.error(error.response.data.message);
      });
  };

  // Search Tree Event
  handleSearchChange = e => {
    const { isSearch, treeSearchReg } = this.state;
    const expKey = [...this.state.expandedKey];
    if (e.key === "Enter" || e.target.value === EMPTY_STRING) {
      if (treeSearchReg.trim().length) {
        this.searchTreeNodes(e.target.value);
      } else if (isSearch) {
        this.getTreeData(expKey);
      }
    }
  };

  //Clear Search on Close button click
  handleClickClearSearch = () => {
    const { isSearch, treeSearchReg } = this.state;
    const expKey = [...this.state.expandedKey];
    if (isSearch) {
      this.setState({
        ...this.state,
        treeSearchReg: EMPTY_STRING,
        isSearch: false
      });
      this.getTreeData(expKey);
    } else {
      if (!treeSearchReg.length) return;
      else {
        this.setState({
          ...this.state,
          treeSearchReg: EMPTY_STRING,
          isSearch: false
        });
      }
    }
  };

  //Data table Search update
  handleTableSearchChange = value => {
    this.setState({
      ...this.state,
      tableSearchReg: value
    });
  };

  //Updated Tree & Table Data from Tree Call Back
  handleDataUpdate = (obj, event) => {
    const {
      treeView,
      treeData,
      tableData,
      selectedKey,
      tableOrder,
      tableOrderBy,
      tablePageNo,
      tablePageSize,
      tableSearchReg
    } = this.state;

    //Rename item from tree and table
    if (event === "rename") {
      const id = parseInt(obj.id);
      const loop = data => {
        data.forEach(item => {
          if (id === parseInt(item.key)) {
            item.title = obj.name;
          } else {
            if (item.children) loop(item.children);
          }
        });
      };
      loop(treeData);

      tableData.map(item => {
        if (id === (treeView === BRAND ? item.nodeId : item.id))
          item.name = obj.name;
        return item;
      });

      this.setState({
        ...this.state,
        treeData: treeData,
        tableData: tableData
      });
    }

    //Remove item from tree and table
    if (event === "delete") {
      const id = obj.itemId[0];
      const loop = data => {
        data.forEach((item, index, object) => {
          if (id.toString() === item.key) {
            object.splice(index, 1);
          } else {
            if (item.children) loop(item.children);
          }
        });
      };
      loop(treeData);

      //Folder Delete
      if (id.toString() === selectedKey.toString()) {
        this.setState({ ...this.state, treeData: treeData });
        this.handleNodeClick(["0"], false);
      } else {
        //Files Delete
        const updatedTableData = tableData.filter(
          item =>
            id.toString() !==
            (treeView === BRAND ? item.nodeId.toString() : item.id.toString())
        );
        this.setState({
          ...this.state,
          treeData: treeData,
          tableData: updatedTableData,
          tableTotalRows: updatedTableData.length
        });
      }
    }

    //Copy and Move update data
    if (event === "drop" || event === "copy") {
      const dataTableId = event === 'drop' ? obj["dropped-node"] :  selectedKey;

      this.getTreeData(this.state.expandedKey);
      this.getTableData(
        dataTableId,
        tableOrder,
        tableOrderBy,
        tablePageNo,
        tablePageSize,
        tableSearchReg
      );
    }
  };

  handleMultipleNodeClick = (selIds) => {
    this.setState({ ...this.state, selectedKey: selIds });
  }

  //Get Table Data for selected tree node
  handleNodeClick = (selId, isShared, dataRoot) => {
    if(selId.length === 0 )
    return 
    this.setState({
      ...this.state,
      loadingTable: true,
      isListView: false,
      openEdit: false,
      tableOrder: ASCENDING_ORDER,
      tableOrderBy: "name",
      tablePageSize: DEFAULT_ROWS_PER_PAGE,
      tablePageNo: DEFAULT_PAGE,
      tableSearchReg: EMPTY_STRING,
      tableTotalRows: DEFAULT_COUNT,
      breadcrumbs: EMPTY_STRING,
      itemShared: isShared,
      rootId: dataRoot,
    });
    setTimeout(() => {
      this.getTableData(
        selId,
        ASCENDING_ORDER,
        "name",
        DEFAULT_PAGE,
        DEFAULT_ROWS_PER_PAGE,
        EMPTY_STRING,
        dataRoot
      );
      this.getBreadCrumb(selId);
    }, 400);
  };

  //Get Table Data from DataTable Events
  updateTableData = (
    order,
    orderBy,
    page,
    rowsPerPage,
    searchReg,
    refreshTree
  ) => {
    const selectedNode = [...this.state.selectedKey];
    const expandNode = [...this.state.expandedKey];
    this.getTableData(
      selectedNode,
      order,
      orderBy,
      page,
      rowsPerPage,
      searchReg
    );
    if (refreshTree) this.getTreeData(expandNode);
  };

  //Update Tree Search Val
  handleTreeSearch = e => {
    this.setState({
      ...this.state,
      treeSearchReg: e.target.value
    });
  };

  //Render Workshop Item component
  renderModuleComponent = type => {
    const {
      selectedKey,
      tableOrder,
      tableOrderBy,
      tablePageSize,
      tablePageNo
    } = this.state;

    const treeUrl =
      type === BRAND
        ? GET_BRANDS
        : type === MODEL
        ? GET_MODELS
        : type === CLASSIFIER
        ? GET_CLASSIFIERS
        : type === CATALOGUE
        ? GET_CATALOGUE
        : GET_COLLECTIONS;

    const tableUrl =
      type === BRAND
        ? GET_BRANDS_DATATABLE
        : type === MODEL
        ? GET_MODELS_DATATABLE
        : type === CLASSIFIER
        ? GET_CLASSIFIERS_DATATABLE
        : type === CATALOGUE
        ? GET_CATALOGUE_DATATABLE
        : GET_COLLECTIONS_DATATABLE;

    const tableParams = {
      pageSize: tablePageSize,
      pageNo: tablePageNo,
      sortColumn: tableOrderBy,
      sortOrder: tableOrder,
      searchWord: EMPTY_STRING,
    }

    if (type === CATALOGUE) tableParams.id = selectedKey.toString();
    else {
      tableParams.parentId = selectedKey.toString();
      tableParams.isAutoRegex = tablePageSize;
    }

    //Fetch Tree Data
    client
      .get(treeUrl, { params: {
        parentId: "#",
        isAutoRegex: false,
        sortColumn: this.state.treeSortBy,
        sortOrder: this.state.treeSortOrder
      }})
      .then(res => {
        if (res.data.status === SUCCESS) {
          this.setState({
            ...this.state,
            treeData: res.data.response,
            expandedKey: ["0"],
            loadingTree: false
          });
          this.getBreadCrumb(selectedKey);

          //Fetch Table Data
          client
            .get(tableUrl, {
              params: tableParams
            })
            .then(res => {
              if (res.data.status === SUCCESS) {
                this.setState({
                  ...this.state,
                  tableData: res.data.response.data,
                  unfilterdTableData: res.data.response.data,
                  tableOrder: res.data.response.sortOrder,
                  tableOrderBy: res.data.response.sortColumn,
                  tablePageSize: res.data.response.pageSize,
                  tablePageNo: res.data.response.pageNo,
                  tableSearchReg: res.data.response.searchWord,
                  tableTotalRows: res.data.response.total,
                  openEdit: false,
                  loadingTable: false
                });
              } else {
                this.setState({ openEdit: false, loadingTable: false });
                if (res.data.status === WARN) toast.warn(res.data.message);
                else toast.error(res.data.message);
              }
            })
            .catch(error => {
              this.setState({
                openEdit: false,
                loadingTable: false
              });
              if (error.response) {
                toast.error(error.response.data.message);
              }
            });
        } else {
          this.setState({
            openEdit: false,
            loadingTable: false
          });
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
        }
      })
      .catch(error => {
        this.setState({
          openEdit: false,
          loadingTable: false,
          loadingTree: false
        });
        if (error.response) {
          toast.error(error.response.data.message);
        }
      });
};

  //on module select
  selectModule = event => {
    this.setState({
      ...this.state,
      treeView: event.target.value,
      openEdit: false,
      isSearch: false,
      treeSearchReg: EMPTY_STRING,
      selectedKey: event.target.value === 'topics' ? ["1"] : ["0"],
      expandedKey: ["0"],
      isListView: false,
      listViewNode: null,
      loadingTree: true,
      loadingTable: true,
      treeData: EMPTY_ARRAY,
      tableData: EMPTY_ARRAY,
      tableOrder: ASCENDING_ORDER,
      tableOrderBy: "name",
      tablePageSize: DEFAULT_ROWS_PER_PAGE,
      tablePageNo: DEFAULT_PAGE,
      tableSearchReg: EMPTY_STRING,
      tableTotalRows: DEFAULT_COUNT,
      breadcrumbs: EMPTY_STRING,
      itemShared: false
    });
    setTimeout(() => {
      this.renderModuleComponent(event.target.value);
    }, 400);
  };

  //Handle node edit screen
  openEditor = (item, nodesExpanded) => {
    
    this.setState({ openEdit: false, screenLoader: true });
    const { treeView } = this.state;

    this.getBreadCrumb(treeView === BRAND ? item.nodeId : item.id);
    let temp = [];
    if (treeView === BRAND) temp.push(item.nodeId.toString());
    else temp.push(item.id.toString());

    setTimeout(() => {
      if (treeView === CLASSIFIER) {
        this.setState({
          ...this.state,
          expandedKey: nodesExpanded,
          itemId: item.id,
          openEdit: true,
          selectedKey: temp,
          editItem: item,
          frameType: item.frameType,
          screenLoader: false,
          isAutoRegex: item.isAutoRegex ? item.isAutoRegex : false 
        });
      } else {
        this.setState({
          ...this.state,
          expandedKey: nodesExpanded,
          itemId: item.id,
          openEdit: true,
          selectedKey: temp,
          editItem: item,
          screenLoader: false,
          isAutoRegex: false
        });
      }
    }, 200);
  };

  //Open Editor after node create
  handleNodeCreate = (d, name, nodeType, classifierType) => {
    const { treeView } = this.state;

    this.setState({
      treeData: EMPTY_ARRAY,
      expandedKey: EMPTY_ARRAY,
      itemId: d.id
    });
    let treeUrl =
      treeView === BRAND
        ? GET_BRANDS
        : treeView === MODEL
        ? GET_MODELS
        : treeView === CLASSIFIER
        ? GET_CLASSIFIERS
        : GET_COLLECTIONS;

    client
      .get(treeUrl, { params: {
        parentId: "#",
        isAutoRegex: false,
        sortColumn: this.state.treeSortBy,
        sortOrder: this.state.treeSortOrder
      } })
      .then(res => {
        if (res.data.status === SUCCESS) {
          this.setState({
            ...this.state,
            isSearch: false,
            treeData: res.data.response,
            treeSearchReg: EMPTY_STRING
          });

          setTimeout(() => {
            if (nodeType === DEFAULT) {
              let selId = [];
              selId.push(d.nodeId.toString());
              this.handleNodeClick(selId, false, classifierType);
              this.setState({
                selectedKey: selId,
                expandedKey: d.expandedNodes
              });
            } else {
              let item = {};
              item["id"] = d.id;
              item["nodeId"] = d.nodeId;
              item["name"] = name;
              item["frameType"] =
                classifierType === -2
                  ? VIRTUAL_CLASSIFIER
                  : classifierType === -3
                  ? CALCULATED_CLASSIFIER
                  : "undefined";
              this.openEditor(item, d.expandedNodes);
              this.setState({ ...this.state, rootId: classifierType })
            }
          }, 400);
        } else {
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
        }
      })
      .catch(error => {
        if (error.response) toast.error(error.response.data.message);
      });
  };

  rescoreBrand = i => {
    console.log("brandId: " + i.id);
  };

  //on node expand
  handleExpandNode = k => {
    this.setState({
      ...this.state,
      expandedKey: k
    });
  };

  //close edit screen
  handleEditClose = () => {
    this.handleNodeClick(this.state.selectedKey, false)
  };

  //List view frame edit callback
  handleFrameEdit = item => {
    this.setState({ screenLoader: true, nodeParentId: item.parentId });
    setTimeout(() => {
      this.setState({ isListView: false, listView: true });
      this.openEditor(item, this.state.expandedKey);
    }, 200);
  };

  updateTreeList=(id,name,eventType)=> {
    const updatedTreeData = [...this.state.treeData];
    
    const loop = data => {
        data.forEach((item, index, object) => {
          if (id.toString() === item.key) {
              if(eventType ==='rename')
                item.title = name;
              else if(eventType==='delete')
                object.splice(index, 1);
                else return
          } else {
            if (item.children) loop(item.children);
          }
        });
      };
      loop(updatedTreeData);
      this.setState({...this.state, treeData: updatedTreeData })
  }

  //List View Call back event - topics
  updateOtherComponents = (prop, id) => {
    const { selectedTopic } = this.state;
    this.getTreeData(this.state.expandedKey);
    if (prop === "delete") {
      if (selectedTopic === id) {
        this.setState({ candidateRefresh: uuid(), selectedTopic: null });
      } else {
        this.setState({ candidateRefresh: uuid(), phrasesRefresh: uuid() });
      }
    }
    if (prop === "merge") {
      this.setState({
        selectedTopic: id,
        topicsRefresh: uuid(),
        phrasesRefresh: uuid()
      });
    }
    if (prop === "rename") {
      this.setState({
        selectedTopic: id,
        candidateRefresh: uuid(),
        phrasesRefresh: uuid()
      });
    }
  };

  //revert to listview on close screen
  handleEditCloseOpenListView = () => {
    const { nodeParentId } = this.state;
    let node = {};
    node["eventKey"] = nodeParentId;
    this.setState({
      ...this.state,
      isListView: true,
      openEdit: false,
      listView: false
    });
  };

  //handle Topics and Candidate Refresh
  handleUpdatedPhrases = () => {
    this.setState({ candidateRefresh: uuid(), topicsRefresh: uuid() });
  };

  //Handle Header Brand Edit Event
  getUrlSearchDetails = () => {
    const n = window.location.search;
    const na = n.split("?").reverse();
    const brandData = na[0].split("=").reverse();
    const brandArray = brandData[0].split("&");
    let itemObj = {};
    itemObj["id"] = brandArray[0];
    itemObj["nodeId"] = brandArray[1];
    this.getTreeData(["0"]);
    this.openEditor(itemObj, ["0"]);
  };

  handleCreateProjectModal = () => {
    this.setState(state => ({
      ...state, openProjectModal: !this.state.openProjectModal
    }));
  };

  updateProjectDetails = (value) => {
    this.setState(state => ({
      ...state, projectDetails: value
    }))
  };

  handleProjectModalClose = () => {
    this.handleNodeClick(this.state.selectedKey)
    this.getTreeData(this.state.expandedKey)
  };

  handleCatalogueEditModal = (item) => {
    this.setState({
      editCatalogueData: item,
      openCatalogueForm: true,
      savingCatalogueFileData: false
    });
  };

  handleDialogClose = () => {
    this.setState({
      editCatalogueData: null,
      openCatalogueForm: false
    });
  };

  // Save updated catalogue file data
  handleCatalogueFileSave = (name, description, delimiter, lineSeparator, file) => {
    try {
      const createOrUpdateCatalogueFile = (url, formData) => {
        client
          .post(url, formData)
          .then((res) => {
            if (res.data.status === SUCCESS) {
              this.setState({
                editCatalogueData: null,
                openCatalogueForm: false,
                savingCatalogueFileData: false
              })
              toast.success(res.data.message ? res.data.message : "Updated successfully")
              const {
                tableOrder,
                tableOrderBy,
                tablePageNo,
                tablePageSize,
                tableSearchReg,
              } = this.state
              this.getTreeData(['0'])
              this.getTableData(
                ['0'],
                tableOrder,
                tableOrderBy,
                tablePageNo,
                tablePageSize,
                tableSearchReg
              )
            } else {
              if (res.data.status === WARN) toast.warn(res.data.message)
              else toast.error(res.data.message)
            }
          })
          .catch((err) => {
            if (err.response) toast.error(err.response.message)
            this.setState({
              editCatalogueData: null,
              openCatalogueForm: false,
              savingCatalogueFileData: false
            })
            console.log(err)
          })
      };

      this.setState({
        savingCatalogueFileData: true
      });

      const formData = new FormData()
      formData.append('name', name)
      formData.append('description', description)
      formData.append('delimiter', delimiter)
      formData.append('lineSeparator', lineSeparator)

      if (this.state.editCatalogueData)
        formData.append('catalogId', this.state.editCatalogueData.id)
      else formData.append('dataCatalogueFile', file)

      if (this.state.editCatalogueData) {
        createOrUpdateCatalogueFile(UPDATE_CATALOGUE, formData);
      } else {
        client
          .get(IS_CATALOGUE_EXISTS, {
            params: { parentId: '0', name, nodeType: 'file' },
          })
          .then((res) => {
            if (res.data.status === SUCCESS) {
              createOrUpdateCatalogueFile(CREATE_CATALOGUE, formData);
            } else {
              if (res.data.status === WARN) toast.warn(res.data.message)
              else toast.error(res.data.message)
              this.setState({
                savingCatalogueFileData: false
              });
            }
          })
          .catch((error) => {
            if (error.response) toast.error(error.response.data.message)
          })
      }
    } catch (err) {
      console.log(err)
      this.setState({
        editCatalogueData: null,
        openCatalogueForm: false,
      })
    }
  }

  // Handle New Catalogue creation event
  handleCatalogueCreate = () => {
    this.setState({
      openCatalogueForm: true
    });
  }

  handleTreeSortByChange = (e) => {
    this.setState((state) => ({
      ...state,
      treeSortBy: e.target.value
    }));
  }

  handleTreeSortOrderChange = (e) => {
    this.setState((state) => ({
      ...state,
      treeSortOrder: state.treeSortOrder === 'asc' ? 'desc' : 'asc'
    }));
  }

  handleModelRefresh = () => {
    const { itemId, treeView } = this.state;
    if(itemId && treeView === MODEL) {
      console.log(itemId)
      client.get(REFRESH_MODEL, { params: { modelId: itemId, isRefreshModel: true } })
    }
  }

  reloadDataAfterBrandNameChange = () => {
    this.getTreeData(this.state.expandedKey);
    this.getBreadCrumb(this.state.selectedKey);
  }

  UNSAFE_componentWillReceiveProps() {
    if (!window.location.search) {
      return;
    } else {
      this.getUrlSearchDetails();
    }
  }

  componentDidUpdate(_, prevState) {
    const { isSearch, treeSearchReg } = this.state;

    if (this.state.treeSortBy !== prevState.treeSortBy) {
      if (isSearch) this.searchTreeNodes(treeSearchReg);
      else this.getTreeData(this.state.expandedKey);
    }
    
    if (this.state.treeSortOrder !== prevState.treeSortOrder) {
      if (isSearch) this.searchTreeNodes(treeSearchReg);
      else this.getTreeData(this.state.expandedKey);
    }
  }

  async componentDidMount() {
    if (!window.location.search) {
      this.renderModuleComponent(BRAND);
    } else {
      this.getUrlSearchDetails();
    }
    const userDetails = await getUserDetails();
    if (userDetails) {
      this.setState({userPlan: userDetails.planId});
    }
  }

  render() {
    const { classes } = this.props;
    const { userPlan, loadingTree, loadingTable, listViewNode, selectedTopic, openProjectModal } = this.state;
    return (
      <React.Fragment>
        { openProjectModal && (
          <CreateProjectModal
            open={openProjectModal}
            handleModal={this.handleCreateProjectModal}
            projectDetails={this.state.projectDetails}
            updateProjectDetails={this.updateProjectDetails}
            handleProjectModalClose={this.handleProjectModalClose}
          />
        )}
        {this.state.openCatalogueForm && (
          <CatalogueForm
            open={this.state.openCatalogueForm}
            data={this.state.editCatalogueData}
            handleCatalogueFileSave={this.handleCatalogueFileSave}
            handleClose={this.handleDialogClose}
            saving={this.state.savingCatalogueFileData}
          />
        )}
        <div className="contentBody">
          {/* Page Heading & Breadcrumb */}
          <Grid container spacing={0} alignItems="flex-end">
            <Grid item xs={12} sm={3} style={{display:'flex', alignItems:'center'}}>
              <h1 style={{marginRight: "20px"}}>Brand Management</h1>
              { this.state.treeView === MODEL && this.state.openEdit
                ? <Tooltip TransitionComponent={Zoom} title="Refresh Model">
                    <Autorenew style={{fontSize: '1.1rem', cursor:'pointer'}} onClick={this.handleModelRefresh}/>
                  </Tooltip> 
                : null } 
            </Grid>
            <Grid item xs={12} sm={9}>
              <Grid container justify="space-between" style={{ marginBottom: "10px" }}>
                <Grid item xs={12} sm={6}>
                  { this.state.treeView === BRAND && (
                    <div className="newProjectBtnHolder">
                      <span>Projects</span>
                      <Button
                        variant="text"
                        color="default"
                        startIcon={<AddCircleIcon />}
                        onClick={this.handleCreateProjectModal}
                      >
                        New Project
                      </Button>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="breadCrumb-holder">
                  <Breadcrumb
                    data={this.state.breadcrumbs}
                    handleBreadCrum={(selId, isShared) =>
                      this.handleNodeClick(selId, isShared)
                    }
                  />
                </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Page Content */}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <Paper
                className={classes.paper}
                elevation={0}
                style={{ marginBottom: 8 }}
              >
                <div className="treeDiv">
                  <form className="treeType" autoComplete="off">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel
                        ref={ref => {
                          this.InputLabelRef = ref;
                        }}
                        name="treeType"
                        htmlFor="treeType"
                      ></InputLabel>

                      {userPlan === PLAN_BASIC ? (
                        <div className={classes.singleModule}>Brands</div>
                      ) : (
                        <Select
                          value={this.state.treeView}
                          onChange={this.selectModule}
                          input={
                            <OutlinedInput
                              labelWidth={this.state.labelWidth}
                              id="treeType"
                            />
                          }
                          inputProps={{"data-testid": "selectModule"}}
                        >
                          <MenuItem value={"brand"}>Brands</MenuItem>
                          <MenuItem value={"model"}>Models</MenuItem>
                          {userPlan === PLAN_PROFESSIONAL ? null : (
                            <MenuItem value={"classifier"}>
                              Classifiers
                            </MenuItem>
                          )}
                          <MenuItem value={"catalogue"}>Data Catalogue</MenuItem>
                        </Select>
                      )}
                    </FormControl>
                  </form>
                </div>
              </Paper>

              <Paper className={classes.paper} elevation={0}>
                {/* Input for tree search */}
                <div className="mainTree">
                  <div className="treeOptions">
                    <div className="treeSearchInput">
                      <TextField
                        id="tree-search"
                        name="tree-search"
                        type="text"
                        label="Search Tree"
                        className={"tree-search"}
                        value={this.state.treeSearchReg}
                        onChange={this.handleTreeSearch}
                        onKeyUp={this.handleSearchChange}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                aria-label="tree search"
                                onClick={this.handleClickClearSearch}
                                data-testid="clearIcon"
                                style={{ padding: '0px', paddingRight: '4px' }}
                              >
                                {this.state.treeSearchReg.length === 0 ? (
                                  <Tooltip
                                    TransitionComponent={Zoom}
                                    title="Search"
                                  >
                                    <SearchOutlined />
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    TransitionComponent={Zoom}
                                    title="Clear Search"
                                  >
                                    <CloseOutlined />
                                  </Tooltip>
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          inputProps: {"data-testid": "treeSearch"}
                        }}
                      />
                    </div>
                    <div className='treeSortSelect'>
                      <TextField
                        id="tree-sort-by-select"
                        select
                        label="Sort By"
                        variant="outlined"
                        margin="dense"
                        value={this.state.treeSortBy}
                        onChange={this.handleTreeSortByChange}
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Tooltip
                                key={`${this.state.treeSortOrder}_order`}
                                title={this.state.treeSortOrder === 'asc' ? 'Asc' : 'Desc'}
                                disableFocusListener
                              >
                                <IconButton
                                  onClick={this.handleTreeSortOrderChange}
                                  style={{ padding: '0px' }}
                                >
                                  {this.state.treeSortOrder === 'asc' ? <ArrowUpward /> : <ArrowDownward />}
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          )
                        }}
                      >
                        <MenuItem value='name'>Name</MenuItem>
                        <MenuItem value='createdAt'>Creation Date</MenuItem>
                        <MenuItem value='modifiedAt'>Modified Date</MenuItem>
                      </TextField>
                    </div>
                  </div>
                  <small><b>Note: Hold the SHIFT key and click the items for multiple selection.</b></small>
                  <div className="treeContainer" id="treeContainer">
                    
                    {this.state.treeData.length > 0 ? (
                      <div>
                        <TreeComponent
                          type={this.state.treeView}
                          data={this.state.treeData}
                          expandedKey={this.state.expandedKey}
                          selectedKey={this.state.selectedKey}
                          rootId={this.state.rootId}
                          handleDataUpdate={this.handleDataUpdate}
                          handleNodeClick={this.handleNodeClick}
                          handleMultipleNodeClick={this.handleMultipleNodeClick}
                          handleExpandNode={this.handleExpandNode}
                          handleDynamicNodes={this.handleDynamicNodes}
                          handleListView={this.handleListView}
                          isSearchTree={this.state.isSearch}
                          handleNodeCreate={this.handleNodeCreate}
                          createCatalogueFile={this.handleCatalogueCreate}
                          frameType={this.state.frameType}
                          treeSortBy={this.state.treeSortBy}
                          treeSortOrder={this.state.treeSortOrder}
                        />
                      </div>
                    ) : (
                      <div>{loadingTree ? <DataPreloader/> : EMPTY_MSG}</div>
                    )}
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={9} style={{position:'relative'}}>
            <Suspense fallback={<DataPreloader/>}>
            
            <Fab size="small" color="secondary" aria-label="resize" className="el-resize">
              <ArrowBack />
            </Fab>
              
              <div className="content-holder">
              
              
                {this.state.screenLoader ? (
                  <div className="maskElement"></div>
                ) : null}
                
                {this.state.openEdit ? (
                  //Edit Screens
                  <div className="edit-mode">
                    {this.state.treeView === BRAND ? (
                      <EditBrand
                        dataType={this.state.treeView}
                        dataId={this.state.itemId}
                        twitterCallbackDetails={this.state.twitterCallbackDetails}
                        handleEditClose={(treeRefresh) => {
                          this.handleNodeClick(this.state.selectedKey)
                          if(treeRefresh)
                          this.getTreeData(this.state.expandedKey)
                        }}
                        reloadDataAfterBrandNameChange={this.reloadDataAfterBrandNameChange}
                      />
                    ) : this.state.treeView === MODEL ? (
                      <EditModel
                        dataType={this.state.treeView}
                        dataId={this.state.itemId}
                        handleEditClose={() =>
                          this.handleNodeClick(this.state.selectedKey)
                        }
                      />
                    ) : this.state.treeView === CLASSIFIER ? (
                      <EditClassifier
                        frameObject={this.state.editItem}
                        frameType={this.state.frameType}
                        dataType={this.state.treeView}
                        dataId={this.state.itemId}
                        listView={this.state.listView}
                        handleOpenEditor={item =>
                          this.openEditor(item, this.state.expandedKey)
                        }
                        handleEditClose={() =>
                          this.handleNodeClick(this.state.selectedKey)
                        }
                        handleEditCloseOpenListView={() =>
                          this.handleEditCloseOpenListView()
                        }
                        isOptionsUpdated={(e)=> this.modelClassiferEditUpdate = true }
                        modelClassifier={false}
                        isAutoRegex={this.state.isAutoRegex}
                      />
                    ) : (
                      <EditCollection
                        dataType={this.state.treeView}
                        dataId={this.state.itemId}
                        handleEditClose={() =>
                          this.handleNodeClick(this.state.selectedKey)
                        }
                      />
                    )}
                   
                  </div>
                ) : this.state.isListView ? (
                  <div className="list-view-mode">
                    {/* Render List View */}
                    <div className="listViewComponent">
                      <Grid container className="rowGrid">
                        <Grid item xs={12} sm={12} md={6} className="grid">
                          <Grid item xs={12} className="innerGrid">
                            <Paper>
                              <CandidatePhrases
                                node={listViewNode}
                                candidateRefresh={this.state.candidateRefresh}
                              />
                            </Paper>
                          </Grid>

                          <Grid item xs={12} className="innerGrid">
                            <Paper>
                              <GramPhrases
                                node={listViewNode}
                                gramPhrasesRefresh={
                                  this.state.gramPhrasesRefresh
                                }
                              />
                            </Paper>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} className="grid">
                          <Grid item xs={12} className="innerGrid">
                            <Grid container className="subRowGrid">
                              <Grid item xs={12} sm={12} md={6} className="subGrid">
                                <Grid item xs={12} className="subinnnerGrid">
                                  <Paper>
                                    <TopicsList
                                      node={listViewNode}
                                      getSelectedTopicPhrases={id =>
                                        this.setState({
                                          selectedTopic: id,
                                          phrasesRefresh: uuid()
                                        })
                                      }
                                      updateOtherComponents={(e, i) =>
                                        this.updateOtherComponents(e, i)
                                      }
                                      handleFrameEdit={item =>
                                        this.handleFrameEdit(item)
                                      }
                                      topicsRefresh={this.state.topicsRefresh}
                                      handleCandidateRefresh={id => {
                                        this.setState({ candidateRefresh: id });
                                      }}
                                      handleGramPhrasesRefresh={id => {
                                        this.setState({
                                          gramPhrasesRefresh: id
                                        });
                                      }}
                                      updateTreeList={(id,name,eventType)=> { 
                                          this.updateTreeList(id,name,eventType) 
                                        }}
                                    />
                                  </Paper>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={12} md={6} className="subGrid">
                                <Grid item xs={12} className="subinnnerGrid">
                                  <Paper>
                                    <PhrasesList
                                      node={listViewNode}
                                      topicId={selectedTopic}
                                      handleUpdatedPhrases={
                                        this.handleUpdatedPhrases
                                      }
                                      phrasesRefresh={this.state.phrasesRefresh}
                                    />
                                  </Paper>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                   
                  </div>
                ) : (
                  <Paper className={classes.paperNoPadding} elevation={0}>
                    {/* Render Data Tables */}
                    <div className="dataTables">
                      { loadingTable && <DataPreloader/> }
                      <TableView
                        isLoading={this.state.loadingTable}
                        type={this.state.treeView}
                        isShared={this.state.itemShared}
                        data={this.state.tableData}
                        total={this.state.tableTotalRows}
                        order={this.state.tableOrder}
                        orderBy={this.state.tableOrderBy}
                        page={this.state.tablePageNo}
                        rowsPerPage={this.state.tablePageSize}
                        searchReg={this.state.tableSearchReg}
                        updateSearch={this.handleTableSearchChange}
                        handleTableData={this.updateTableData}
                        handleOpenEditor={item => {
                          if (this.state.treeView === CATALOGUE) this.handleCatalogueEditModal(item);
                          else this.openEditor(item, this.state.expandedKey);
                        }}
                        showBindedBrands={this.handleBindedBrands}
                        rescoreBrand={this.rescoreBrand}
                      />
                    </div>
                  </Paper>
                )}
              </div>
              </Suspense>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(WorkshopHandler);

import React from "react";

class ErrorRoute extends React.Component {
  render() {
    return (
      <React.Fragment>
        <h1>Error Code: 404</h1>
        <small>Page not found</small>
      </React.Fragment>
    );
  }
}

export default ErrorRoute;

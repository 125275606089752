import { SELECT_TOPIC } from '../actions/types'
import get from 'lodash.get'

let initialState = {
  name: 'null',
  color: 'null',
  hex: 'null',
  active: false,
}

export default function ActivePalette(state = initialState, action) {
  const topic = get(action, 'topic')

  switch (action.type) {
    case SELECT_TOPIC : {
      return topic
    }
    default: {
      return state;
    }
  }
}
import React, {useEffect, useState} from 'react'
import {Route} from 'react-router-dom'
import {Auth} from 'aws-amplify'
import Preloader from '../containers/Preloader/Preloader'
import Logout from "../views/Logout/Logout";
import decode from 'jwt-decode';


const pendo = window.pendo

export const ProtectedRoute = ({component: Component, render, ...rest}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);

    useEffect(() => {
            Auth.currentAuthenticatedUser()
                .then(user => {
                    user.getSession(function(err, session) {
                        if (err) {
                            console.error(err);
                        }else{
                            console.log('session validity: ' + session.isValid());
                            localStorage.setItem("jwtToken", session.getIdToken().jwtToken)
                            const sessionIdInfo = decode(session.getIdToken().jwtToken);
                            const groups = sessionIdInfo['cognito:groups']
                            console.log({groups});
                            if(groups && groups.length > 0){
                                localStorage.setItem("userGroups", JSON.stringify(groups))
                                setIsConfirmed(true)
                            }else {
                                setIsConfirmed(false)
                            }
                        }});
                    setIsAuthenticated(true)
                    const env = process.env.REACT_APP_NODE_ENV
                    if (env && env.toLowerCase() !== 'testing') {
                        pendo.initialize({
                            visitor: {
                                id: user.username
                            },
                            account: {
                                id: user.username
                            }
                        })
                    }
                })
                .catch(err => {
                    console.log('SecureRoute Error:  ', err)
                    Auth.federatedSignIn()
                })

    }, [])

    const wrappedRender = props => {
        if (isAuthenticated && render) {
            if (isConfirmed) {
                return render(props)
            } else {
                console.log("Account not confirmed");
                return < Logout notConfirmed={true}/>
            }
        } else if (isAuthenticated && Component) {
            if (isConfirmed) {
                return <Component {...props} />
            } else {
                console.log("Account not confirmed");
                return < Logout notConfirmed={true}/>
            }
        } else {
            return <Preloader/>
        }
    }

    return <Route {...rest} render={wrappedRender}/>
}

export default ProtectedRoute;
import React, { Component } from 'react';
import PreloaderIcon from 'react-preloader-icon';
import Oval from 'react-preloader-icon/loaders/Oval';
import './Preloader.css'

class Preloader extends Component {
    render() {
        return(
            <div className="preloaderHolder">
                <span className="preloaderDiv">
                    <div className="preloaderContent">
                        <PreloaderIcon
                            loader={Oval}
                            size={36}
                            strokeWidth={14}
                            strokeColor="#2196f3"
                            duration={1000}
                        /><br/>
                        <span className="loaderText">Loading please wait...</span> 
                    </div>
                </span>
            </div>
        )
    }
}

export default Preloader;
import React, {useEffect, useState} from "react";
import Iframe from "react-iframe";
import DataPreloader from "../../containers/Preloader/DataPreloader";
import LoginBg from "../../assets/images/login-bg.png";
import {Auth} from "aws-amplify";
import auth from "../../amplify-config";
import {clearAppLocalStorage} from "../../_helpers";


const oAuth = auth.oauth;

const Logout = (props) => {
    const [kibanaUrl, setKibanaUrl] = useState(null);

    useEffect(() => {
        if (props.notConfirmed) {
            setTimeout(() => {
                try {
                    const kibanaURL = localStorage.getItem("kibanaUrl");
                    if (kibanaURL) setKibanaUrl(kibanaURL);
                    else handleLogout();
                } catch (error) {
                    console.log(error);
                    handleLogout();
                }
            }, 5000);
        }
    }, []);

    const handleLogout = () => {
        // URL to redirect to Hosted UI instead of back to application
        //const url = `https://${oAuth.domain}/logout?redirect_uri=${oAuth.redirectSignOut}&response_type=${oAuth.responseType}&client_id=${Auth.configure().userPoolWebClientId}`;

        Auth.signOut({global: true})
            .then(data => {
                clearAppLocalStorage()
                //window.location.href = url;
            })
            .catch(err => {
                console.log(err);
                clearAppLocalStorage()
                if (err.code == "NotAuthorizedException") {
                    Auth.federatedSignIn()
                        .then(() => {
                            Auth.signOut()
                                .then(() => {
                                    console.log('LOGGED OUT')
                                    //window.location.href = url;
                                })
                        })
                } else {
                    props.history.push("/login");
                }
            });
    };

    return (
        <>
            {kibanaUrl ? (
                <Iframe
                    url={`${kibanaUrl}api/security/logout`}
                    display="none"
                    id="logoutIframe"
                    onLoad={handleLogout}
                />
            ) : null}
            <div className="masterlogin" style={{backgroundImage: "url(" + LoginBg + ")"}}>
                {props.notConfirmed ?(
                <div className="noAccess">
                    <h3>Warning!</h3>
                    <small>Your account confirmation is pending. Please reach out to CoreAdmin@northhighland.com</small>
                </div>): null}
            </div>
        </>
    );
};

export default Logout;

import React, { useEffect, useState } from 'react'
import { getUserDetails } from '../../_helpers'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import client from '../../_helpers/client'
import { GET_NEW_TOKENS } from '../../Routes'
import { SUCCESS } from '../../Constants'
import { toast } from 'react-toastify'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    marginBottom: '1rem'
  },
  spinner: {
    position: 'absolute',
  },
  visibilityIcon: {
    textAlign: 'right',
    padding: 0,
    '&:hover': {
      display: 'inline',
   },
  },
  tableCell: {
    width: '50%',
    '& div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  }
})

const LegacyAPIToken = () => {
  const classes = useStyles()
  const [accessToken, setAccessToken] = useState('')
  const [refreshToken, setRefreshToken] = useState('')
  const [accessTokenVisible, setAccessTokenVisible] = useState(false)
  const [refreshTokenVisible, setRefreshTokenVisible] = useState(false)
  const [generatingTokens, setGeneratingTokens] = useState(false)

  useEffect(() => {
    const getTokens = async () => {
      const userDetails = await getUserDetails()
      if (userDetails) {
        const { accessToken, refreshToken } = userDetails
        setAccessToken(accessToken || '')
        setRefreshToken(refreshToken || '')
      }
    }

    getTokens()
  }, [])

  const generateNewTokens = async () => {
    try {
      setGeneratingTokens(true)
      const { data } = await client.get(GET_NEW_TOKENS)
      if (data.status === SUCCESS) {
        const { access_token, refresh_token } = data.response
        setAccessToken(access_token)
        setRefreshToken(refresh_token)
        const userDetails = await getUserDetails()
        if (userDetails) {
          localStorage.setItem(
            'userDetails',
            JSON.stringify({
              ...userDetails,
              accessToken: access_token,
              refreshToken: refresh_token,
            })
          )
        }
      }
      setGeneratingTokens(false)
    } catch (err) {
      console.log(err)
      setGeneratingTokens(false)
      toast.error('Could not generate tokens. Please Try again.')
    }
  }

  const obfuscateToken = (token) => {
    return token.slice(0, token.lastIndexOf('-') + 1) + token.slice(token.lastIndexOf('-'), token.length).replace(/./g, '*')
  }

  return (
    <>
      <h1>Legacy API Token</h1>
      <TableContainer>
        <Table
          className={classes.table}
          aria-label='Legacy API Tokens Table'
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell>Access Token</TableCell>
              <TableCell>Refresh Token</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <TableCell className={classes.tableCell} style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                {accessToken ? (
                  <div>
                    <span>
                      {accessTokenVisible ? accessToken : obfuscateToken(accessToken)}
                    </span>
                    <IconButton
                      aria-label='Access token visibility button'
                      size='small'
                      onClick={() => setAccessTokenVisible((state) => !state)}
                      className={classes.visibilityIcon}
                    >
                      {accessTokenVisible ? (
                        <VisibilityOffIcon fontSize='small' />
                      ) : (
                        <VisibilityIcon fontSize='small' />
                      )}
                    </IconButton>
                  </div>
                ) : (
                  'Not Available'
                )}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {refreshToken ? (
                  <div>
                    <span>
                      {refreshTokenVisible ? refreshToken : obfuscateToken(refreshToken)}
                    </span>
                    <IconButton
                      aria-label='Refresh token visibility button'
                      size='small'
                      onClick={() => setRefreshTokenVisible((state) => !state)}
                      className={classes.visibilityIcon}
                    >
                      {refreshTokenVisible ? (
                        <VisibilityOffIcon fontSize='small' />
                      ) : (
                        <VisibilityIcon fontSize='small' />
                      )}
                    </IconButton>
                  </div>
                ) : (
                  'Not Available'
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant='contained'
        className='btn btn-primary'
        onClick={generateNewTokens}
        disabled={!!accessToken || !!refreshToken || !!generatingTokens}
      >
        Generate New Token
        {generatingTokens && (
          <CircularProgress size={20} className={classes.spinner} />
        )}
      </Button>
    </>
  )
}

export default LegacyAPIToken

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import DataPreloader from '../../../../containers/Preloader/DataPreloader'

const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.breakpoints.width('md'),
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: theme.breakpoints.width('sm'),
    },
  },
  formControl: {
    marginBottom: theme.spacing(3),
  },
  label: {
    fontWeight: 600,
  },
  textField: {
    '& textarea': {
      fontSize: '1em',
      lineHeight: '1em',
      height: '1.18em',
    },
  },
  removeDuplicatesOverOption: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  removeDuplicatesCountTextfield: {
    width: '60px',
    margin: '0px 4px',
    '& input': {
      padding: '8px'
    }
  }
}))

const PreprocessingSettings = ({
  open,
  dataLoading,
  handleModal,
  nonASCIICharOption,
  handleNonASCIICharOption,
  puntuationOptions,
  handlePunctuationOptions,
  normalizeSpacing,
  handleNormalizeSpacing,
  advancedRules,
  handleAdvancedRules,
  addNewAdvancedRule,
  submit,
  loading,
  disableEdit,
  handleReset,
  duplicatesOption,
  duplicatesCount,
  handleDuplicatesOption,
  handleDuplicatesCount,
  duplicatesCountError
}) => {
  const classes = useStyles()
  const isBigScreen = useMediaQuery('(min-width:1370px)')

  const closeModal = () => {
    handleModal(null, false)
  }

  const handleNonASCIIOptionChange = (e) => {
    handleNonASCIICharOption(e.target.value)
  }

  const handlePunctuationChange = (e) => {
    const { name, checked } = e.target
    handlePunctuationOptions(name, checked)
  }

  const handleSpacingOptionChange = (e) => {
    handleNormalizeSpacing(e.target.checked)
  }

  const handleAdvancedRulesChange = (e) => {
    const { id, name, value } = e.target
    handleAdvancedRules(id, name, value)
  }

  const handleDuplicatesOptionChange = (e) => {
    handleDuplicatesOption(e.target.value)
  }

  const handleDuplicatesCountChange = (e) => {
    handleDuplicatesCount(e.target.value)
  }

  const disableSaveBtn =
    loading || advancedRules.reduce((acc, e) => acc || e.error, false) || duplicatesCountError

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      aria-labelledby='preprocessing-settings'
      maxWidth={isBigScreen ? 'md' : 'sm'}
      fullWidth
    >
      <DialogTitle id='preprocessing-settings'>
        Preprocessing Settings
      </DialogTitle>
      <DialogContent>
        {dataLoading && <DataPreloader />}

        <FormControl
          component='fieldset'
          className={classes.formControl}
          disabled={disableEdit}
        >
          <FormLabel>
            <Typography variant='subtitle1' className={classes.label}>
              Non-ASCII Characters
            </Typography>
          </FormLabel>
          <RadioGroup
            row
            aria-label='non ascii character options'
            name='normalizeNonascii'
            value={nonASCIICharOption}
            onChange={handleNonASCIIOptionChange}
          >
            <FormControlLabel
              value='transliterate'
              control={<Radio color='primary' />}
              label='Unicode transliteration'
            />
            <FormControlLabel
              value='remove'
              control={<Radio color='primary' />}
              label='Remove non-ASCII characters'
            />
            <FormControlLabel
              value=''
              control={<Radio color='primary' />}
              label='Do Nothing'
            />
          </RadioGroup>
        </FormControl>

        <FormControl
          component='fieldset'
          className={classes.formControl}
          disabled={disableEdit}
        >
          <FormLabel>
            <Typography variant='subtitle1' className={classes.label}>
              Normalize Punctuation
            </Typography>
          </FormLabel>
          <FormGroup>
            {puntuationOptions.map((item) => (
              <FormControlLabel
                key={item.name}
                control={
                  <Checkbox
                    checked={item.checked}
                    onChange={handlePunctuationChange}
                    name={item.name}
                  />
                }
                label={item.label}
              />
            ))}
          </FormGroup>
        </FormControl>

        <FormControl
          component='fieldset'
          className={classes.formControl}
          disabled={disableEdit}
        >
          <FormLabel>
            <Typography variant='subtitle1' className={classes.label}>
              Normalize Spacing
            </Typography>
          </FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                checked={normalizeSpacing.checked}
                onChange={handleSpacingOptionChange}
                name='normalizeSpacing'
              />
            }
            label='Replace all white spaces with single space'
          />
        </FormControl>

        <br />

        <FormControl
          component='fieldset'
          className={classes.formControl}
          disabled={disableEdit}
        >
          <FormLabel>
            <Typography variant='subtitle1' className={classes.label}>
              Remove Duplicates
            </Typography>
          </FormLabel>
          <RadioGroup
            aria-label='remove duplicates options'
            name='removeDuplicates'
            value={duplicatesOption}
            onChange={handleDuplicatesOptionChange}
          >
            <FormControlLabel
              value='allow'
              control={<Radio color='primary' />}
              label="Don't Remove Duplicate"
            />
            <FormControlLabel
              value='not_all'
              control={<Radio color='primary' />}
              label={
                <div className={classes.removeDuplicatesOverOption}>
                  Remove duplicate documents over 
                  <TextField
                    className={classes.removeDuplicatesCountTextfield}
                    id='duplicate-over'
                    type='number'
                    variant='outlined'
                    size='small'
                    inputProps={{
                      min: 0,
                      'data-testid': 'duplicate-over'
                    }}
                    value={duplicatesCount}
                    onChange={handleDuplicatesCountChange}
                    error={duplicatesCountError}
                    disabled={disableEdit}
                  /> 
                  words
                </div>
              }
            />
            <FormControlLabel
              value='all'
              control={<Radio color='primary' />}
              label='Remove all duplicate documents'
            />
          </RadioGroup>
        </FormControl>

        <>
          <FormLabel disabled={disableEdit}>
            <Typography
              variant='subtitle1'
              className={classes.label}
              gutterBottom
            >
              Advanced Rules
            </Typography>
          </FormLabel>
          {advancedRules.map((item) => (
            <Grid key={item.id} container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id={item.id}
                  name='regex'
                  placeholder='Regex'
                  variant='outlined'
                  multiline
                  rowsMax={2}
                  margin='dense'
                  fullWidth
                  value={item.regex}
                  onChange={handleAdvancedRulesChange}
                  error={item.error}
                  helperText={item.error ? 'Invalid Regex!' : ''}
                  disabled={disableEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id={item.id}
                  name='replacement'
                  placeholder='Replacement'
                  variant='outlined'
                  multiline
                  rowsMax={2}
                  margin='dense'
                  fullWidth
                  value={item.replacement}
                  onChange={handleAdvancedRulesChange}
                  disabled={disableEdit}
                />
              </Grid>
            </Grid>
          ))}
          <Button
            color='primary'
            size='small'
            onClick={addNewAdvancedRule}
            disabled={disableEdit}
          >
            <AddRoundedIcon fontSize='small' />
            New Rule
          </Button>
        </>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color='default'>
          Close
        </Button>
        {!disableEdit && (
          <>
            <Button onClick={handleReset} color='default' disabled={disableSaveBtn}>
              Reset
            </Button>
            <Button onClick={submit} color='primary' disabled={disableSaveBtn}>
              Save
              {loading && (
                <CircularProgress
                  color='primary'
                  size={20}
                  style={{ position: 'absolute' }}
                />
              )}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default PreprocessingSettings

import React, { Component } from 'react';
import Tree, { TreeNode } from 'rc-tree';
import cssAnimation from 'css-animation';
import client from '../../../_helpers/client';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import { withStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';

import { EMPTY_STRING, EMPTY_ARRAY, SUCCESS, WARN } from '../../../Constants';
import { BRAND_MODEL_TREE, UPDATE_MODEL_ID } from '../../../Routes';

function animate(node, show, done) {
    let height = node.offsetHeight;
    return cssAnimation(node, 'collapse', {
        start() {
            if (!show) {
                node.style.height = `${node.offsetHeight}px`;
            } else {
                height = node.offsetHeight;
                node.style.height = 0;
            }
        },
        active() {
            node.style.height = `${show ? height : 0}px`;
        },
        end() {
            node.style.height = '';
            done();
        },
    });
}

const animation = {
    enter(node, done) {
        return animate(node, true, done);
    },
    leave(node, done) {
        return animate(node, false, done);
    },
};

const styles = {
    root: {
        width: 300,
    },
    slider: {
        padding: '20px 5px',
        margin: '35px 0px 7px'
    },
    active: {
        backgroundColor: '#1b9be5'
    }
};

class Scoring extends Component {
    state = {
        languageSelected: 1,
        showthreshold: false,
        thresholdValue: 0.5,
        switcherIcon: <i className="fa fa-plus-square"></i>,
        selectedModel: EMPTY_STRING,
        selectedKey: [],
        currentModel: EMPTY_STRING,
        currentKey: [],
        expandedKey: ["0"],
        treedata: EMPTY_ARRAY,
        modelTreeLoading: false,
        isModelsVisible: false,
    }

    handleModelsVisible=()=>{
        this.setState({
            ...this.state,
            isModelsVisible: true,
        })
    }

    handleModelChange=()=>{
        const { selectedKey, selectedModel, currentModel, currentKey } = this.state;
        if(selectedKey.length !== 0) {
            client.get(UPDATE_MODEL_ID, { params: { brandId:this.props.brandId, modelId: selectedKey.toString() } })
            .then(res => {
                if(res.data.status === SUCCESS) {
                    this.setState({
                        ...this.state,
                        isModelsVisible: false,
                        currentModel: selectedModel,
                        currentKey: selectedKey
                    })
                    this.props.handleModelDetails(selectedKey)
                } else {
                    toast.error(res.data.message)
                    this.setState({
                        ...this.state,
                        selectedModel: currentModel,
                        selectedKey: currentKey
                    })
                }
            })
            .catch(error => {
                console.log(error)
            })
        }
    }

    handleClose=()=>{
        this.setState({
            ...this.state,
            isModelsVisible: false,
            selectedModel: this.state.currentModel,
            selectedKey: this.state.currentKey,
        })
    }

    onSelect = (info, e) => {
        if (e.selected) {
            if (e.node.props.hasOwnProperty('children') || e.node.props.className === 'emptyFolder') {
                this.setState({
                    ...this.state,
                    selectedKey: EMPTY_ARRAY,
                    selectedModel: EMPTY_STRING
                })
                return
            } 
            this.setState({
                ...this.state,
                selectedKey: info,
                selectedModel: e.node.props.title
            })
            return
        } 
        this.setState({
            ...this.state,
            selectedKey: EMPTY_ARRAY,
            selectedModel: EMPTY_STRING
        })  
    }

    onExpand = (expandedKeys) => {
        this.setState({ ...this.state, expandedKey: expandedKeys })
    }

    setTreeRef = (tree) => {
        this.tree = tree;
    };

    getModelTree = (languageId) => {
        this.setState({ selectedModel: EMPTY_STRING, modelTreeLoading: true})
        const { brandId } = this.props;
        const newProjectModalData = {
            selectedKey: ['360'],
            expandedKey: ["0", "1", "360"],
            selectedModel: 'Standard Cognitive States (NPS)',
        };
        client
            .get(BRAND_MODEL_TREE, { params: { brandId: brandId, languageId: languageId } })
            .then(res => {
                if (res.data.status === SUCCESS) {
                    if ((res.data.response).hasOwnProperty('selectedModel')) {
                        const selectedModelObj = res.data.response.selectedModel;

                        const selectedModelId = [selectedModelObj['id'].toString()];
                        const selectedModelName = selectedModelObj['name'];

                        this.setState({
                            ...this.state,
                            treedata: res.data.response.children,
                            expandedKey: res.data.response.selectedModel.parents,
                            selectedKey: selectedModelId,
                            selectedModel: selectedModelName,
                            currentModel: selectedModelName,
                            currentKey: selectedModelId,
                            modelTreeLoading: false,
                        });
                        this.props.handleModelDetails(selectedModelId);
                    } else {
                        this.setState({
                            ...this.state,
                            treedata: res.data.response.children,
                            modelTreeLoading: false,
                            ...newProjectModalData
                        })
                    }
                } else {
                    if (res.data.status === WARN) toast.warn(res.data.message)
                    else toast.error(res.data.message)
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.status === WARN) toast.warn(error.response.data.message)
                    else toast.error(error.response.data.message)
                }
            });
    }

    handleLanguageChange = (e) => {
        this.props.handleLanguageChange(e.target.value);
        this.getModelTree(e.target.value);
    }

    UNSAFE_componentWillMount() {
        this.getModelTree(this.state.languageSelected);
    }

    render() {

        const { modelTreeLoading, treedata, isModelsVisible } = this.state;
        const { language, focusWords, handleFocusDetails, mountedInProjectModal } = this.props;

        const loop = (data) => {
            return data.map((item) => {
                if (item.children) {
                    if (item.children.length === 0) {
                        return <TreeNode className={"emptyFolder"} key={item.key} title={item.title} data-id={item.key} />
                    } else {
                        return <TreeNode icon={this.state.nodeIcon} title={item.title} key={item.key} data-id={item.key}> {loop(item.children)} </TreeNode>
                    }
                } else
                    return <TreeNode icon={this.state.fileIcon} title={item.title} key={item.key} isLeaf={item.isLeaf} data-id={item.key} />
            });
        };

        const treeNodes = loop(treedata);

        return (
            <div id="scoring-panel" className="flexGrow-01">
                {!mountedInProjectModal && (
                    <Grid container className="rowGrid">
                        <Grid item xs={12} className="innerGrid">
                            <TextField
                                id="focus"
                                label="Focus"
                                className="label"
                                placeholder="All these words"
                                value={focusWords ? focusWords : ''}
                                onChange={(e) => { handleFocusDetails(e.target.value) }}
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid container className="rowGrid">
                    <Grid item xs={12} sm={6} md={6} className="innerGrid">
                        {!mountedInProjectModal && (
                            <>
                                <h4>Language</h4>
                                <div className="languagePlaceholder">
                                    <FormControl className="formControl">
                                        <Select
                                            value={language}
                                            onChange={this.handleLanguageChange}
                                            inputProps={{
                                                id: 'languageId'
                                            }}
                                            data-testid='languageSelect'
                                        >
                                            <MenuItem value={1}>English</MenuItem>
                                            <MenuItem value={2}>Chinese</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </>
                        )}
                        <br/>
                        <h4>Selected Model</h4>
                        <div className="modelPlaceHolder">
                            {this.state.selectedModel === EMPTY_STRING
                                ? null
                                : <p>{this.state.selectedModel}</p>
                            }
                        </div>
                        <br/>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={ !isModelsVisible ? this.handleModelsVisible : this.handleModelChange}
                            disabled={this.state.selectedKey.length === 0 && isModelsVisible}
                        >
                           { isModelsVisible ? 'Done' : 'Change'}
                        </Button>
                        
                    </Grid>
                    { isModelsVisible ? 
                    <Grid item xs={12} sm={6} md={6} className="innerGrid pos-rel">
                        <h4>Models</h4>
                        <span className="model-close-icon" onClick={this.handleClose}><Close /></span>
                        <div className="modelTree" style={mountedInProjectModal ? {marginTop: '15px'} : {}}>
                            { modelTreeLoading ? 'Loading tree...' : 
                            <Tree
                                ref={this.setTreeRef}
                                className="mtree"
                                openAnimation={animation}
                                switcherIcon={this.state.switcherIcon}
                                selectedKeys={this.state.selectedKey}
                                expandedKeys={this.state.expandedKey}
                                onSelect={this.onSelect}
                                onExpand={this.onExpand}
                            >
                                {treeNodes}
                            </Tree>
                            }
                        </div>
                        
                    </Grid> : null }
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(Scoring);
import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export default function FrequencyAnalysis({frequencyAnalysisData}) {

    const [data, setData] = useState({positive: [], neutral: [], negative: []})

    useEffect(() => {
        setData(frequencyAnalysisData)
    })


    const options = (title, color, chartData) => {
        return {
            credits: {
                enabled: false
            },
            chart: {
                type: 'bar'
            },
            colors: [color],
            title: {
                text: title
            },
            xAxis: {
                type: 'category',
                title: {
                    text: 'Topics',
                    align: 'high'
                },

            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Count',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            tooltip: {
                //valueSuffix: 'Topics'
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            series: [{
                type: 'bar',
                name: title,
                data: chartData
            }]
        }
    }

    return (
        <div>
            <h1> Frequency Analysis</h1>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <HighchartsReact highcharts={Highcharts} options={options('Positive Frequency Analysis', '#93cc65', data.positive)}/>
                </Grid>
                <Grid item xs={4}>
                    <HighchartsReact highcharts={Highcharts} options={options('Neutral Frequency Analysis', '#1890ff', data.neutral)}/>
                </Grid>
                <Grid item xs={4}>
                    <HighchartsReact highcharts={Highcharts} options={options('Negative Frequency Analysis', '#f53d2c', data.negative)}/>
                </Grid>
            </Grid>
        </div>
    );
}

import React, {useEffect, useState} from "react";
import client from "../../../_helpers/client";
import {GET_BRAND_DATASET_LABELS, UPDATE_BRAND_DATASET_LABEL} from "../../../Routes";

import {toast} from "react-toastify";

export default function BrandDataSetLabel({brandId}) {

    const [labels, setLabel] = useState([])

    const brandDataLabel = async () => {
        try {
            const res = await client.get(GET_BRAND_DATASET_LABELS, {params: {brandId}});
            if (res.status === 200) {
                setLabel(res.data)
            } else {
                toast.error("Error in getting dataset labels");
            }
        } catch (error) {
            console.error(error);
            toast.error("Error: " + error);
        }
    }

    useEffect(() => {
        brandDataLabel();
    }, [])

    const update = async (brandId, labelId, isSelected) => {
        try {
            const res = await client.get(UPDATE_BRAND_DATASET_LABEL, {params: {brandId, labelId, isSelected}});
            if (res.status === 200) {
                await brandDataLabel();
                toast.success("Dataset label has been updated");
            } else {
                toast.error("Error in updating dataset labels");
            }
        } catch (error) {
            console.error(error);
            toast.error("Error: " + error);
        }
    }

    return (
        <>
            {labels.map(label =>
                (<div className="datasetLabel" key={label.labelId + "-" + label.brandId}>
                    <label>
                        <input type="checkbox" defaultChecked={label.isSelected}
                               onChange={(e) => update(label.brandId, label.labelId, e.target.checked)}/>
                        {label.name}
                    </label>
                </div>)
            )}
        </>
    );
}
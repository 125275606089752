import {Button, Dialog, Grid} from "@material-ui/core";
import React, {useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ExportDialogPanel from "./ExportDialogPanel.css";

export default function ExportDialog(props) {

    const [isOpen, setOpen] = useState(false)
    const [exportType, setExportType] = useState("All Analysis Data")
    const [documentType, setDocumentType] = useState("XLSX")

    const handleClose = () => {
        setOpen(false)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const exportAnalysis = () => {
        console.log("exportAnalysis.....")
        setOpen(false);
        props.exportFun(exportType,documentType)
        // On complete
        setExportType("All Analysis Data")
        setDocumentType("XLSX")
    }

    const changeExportType = (e) => {
        if (e.target.value) {
            setExportType(e.target.value)
        } else {
            console.error("Wrong export type selection " + e.target.value)
        }
    }

    const changeDocumentType = (e) => {
        if (e.target.value) {
            setDocumentType(e.target.value)
        } else {
            console.error("Wrong document type " + e.target.value)
        }
    }

    return (
        <div className="analysisExport">
            <Button variant="contained" color="primary" onClick={() => handleClickOpen()}> Export </Button>
            <Dialog
                open={isOpen}
                onClose={() => handleClose()}
                aria-labelledby="export-confirm"
                maxWidth="lg"
            >
                <div className="brandExportOptions">
                    <div className="uiDialog">
                        <div className="uiDialogTitle">
                            <h6>Analysis Export</h6>
                        </div>
                        <div className="uiDialogContent">
                            <Grid container spacing={4}>
                                <Grid item xs={4}>
                                    <FormControl className="selectFormControl">
                                        <InputLabel htmlFor="documentType">DOCUMENT TYPE *</InputLabel>
                                        <Select
                                            value={documentType}
                                            onChange={e => changeDocumentType(e)}
                                            input={<Input name="documentType" id="documentType"/>}
                                        >
                                            <MenuItem value="XLSX">XLSX</MenuItem>
                                            <MenuItem value="CSV">CSV</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl className="selectFormControl">
                                        <InputLabel htmlFor="exportType">EXPORT TYPE*</InputLabel>
                                        <Select
                                            value={exportType}
                                            onChange={e => changeExportType(e)}
                                            input={<Input name="exportType" id="exportType"/>}
                                        >
                                            <MenuItem value="All Analysis Data">All Analysis Data</MenuItem>
                                            <MenuItem value="Current Analysis Data">Current Analysis Data</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </div>
                        <footer>
                            <div>
                                <Button variant="outlined" color="primary"
                                        onClick={() => exportAnalysis()}>Export</Button>
                                <Button variant="outlined" onClick={() => handleClose()}>Cancel</Button>
                            </div>
                        </footer>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
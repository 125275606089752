import "./Summary.css";
import React from "react";
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";
import client from "../../_helpers/client";
import DataPreloader from "../../containers/Preloader/DataPreloader";
import BrandPostDetails from "../../containers/BrandPostDetails/BrandPostDetails";
import NoBrandSelectedMessage from "../../containers/NoBrandSelected/NoBrandSelected";
import SummaryTable from "./Tables/SummaryTable";
import PieChart from "./Charts/PieChart";
import TrendsChart from "./Charts/TrendsChart";

import {
  SENTIMENT_SUMMARY,
  SENTIMENT_TREND,
  EMOTION_SUMMARY,
  EMOTION_TREND,
  SENTIMENT_MEDIATABLE,
  TOPICS_MEDIATABLE,
  PREFORMANCE_MEDIATABLE,
  PRESONA_MEDIATABLE,
  PURCHASEPATH_MEDIATABLE,
} from "../../Routes";
import {
  EMPTY_ARRAY,
  EMPTY_STRING,
  PLAN_BASIC,
  SUCCESS,
  SENTIMENT_SUMMARY_DETAILS,
  SENTIMENT_TRENDS_DETAILS,
  SENTIMENT_MEDIATABLE_DETAILS,
  EMOTION_SUMMARY_DETAILS,
  EMOTION_TRENDS_DETAILS,
  TOPICS_MEDIATABLE_DETAILS,
  PERFORMANCE_MEDIATALE_DETAILS,
  PERSONA_MEDIATABLE_DETAILS,
  PURCHASEPATH_MEDIATABLE_DETAILS,
} from "../../Constants";
import { getDefaultBrandDetails, getUserDetails } from "../../_helpers/index";
import { brandPostService } from "../../_services";

class Dashboard extends React.Component {
  state = {
    brandId: null,
    userPlan: PLAN_BASIC,
    SentimentSummaryPreloader: true,
    SentimentTrendsPreloader: true,
    EmotionSummaryPreloader: true,
    EmotionTrendsPreloader: true,
    SentimentMediaTablePreloader: true,
    TopicsMediaTablePreloader: true,
    PerformanceMediaTablePreloader: true,
    PersonaMediaTablePreloader: true,
    PurchasepathMediaTablePreloader: true,
    loading: true,
    sentimentSummary: SENTIMENT_SUMMARY_DETAILS,
    sentimentTrends: SENTIMENT_TRENDS_DETAILS,
    sentimentMediaTable: SENTIMENT_MEDIATABLE_DETAILS,
    emotionSummary: EMOTION_SUMMARY_DETAILS,
    emotionTrends: EMOTION_TRENDS_DETAILS,
    topicsMediaTable: TOPICS_MEDIATABLE_DETAILS,
    performanceMediaTable: PERFORMANCE_MEDIATALE_DETAILS,
    personaMediaTable: PERSONA_MEDIATABLE_DETAILS,
    purchasepathMediaTable: PURCHASEPATH_MEDIATABLE_DETAILS,
    totalPostCount: 0,
    currentsPostCount: 0,
    netSentiment: 0,
    filteredSentiment: 0,
    defaultFilter: true,
  };

  getSentimentSummaryChart = () => {
    client
      .get(SENTIMENT_SUMMARY)
      .then((res) => {
        this.setState({
          sentimentSummary: res.data.response[0],
          SentimentSummaryPreloader: false,
        });
      })
      .catch((error) => {
        console.log(error)
        this.setState({ SentimentSummaryPreloader: false });
      });
  };

  getSentimentTrendsChart = (sentiment) => {
    client
      .get(SENTIMENT_TREND, { params: { sentiment: sentiment } })
      .then((res) => {
        this.setState({
          sentimentTrends: res.data.response[0],
          SentimentTrendsPreloader: false,
        });
      })
      .catch((error) => {
        console.log(error)
        this.setState({ SentimentTrendsPreloader: false });
      });
  };

  getEmotionSummaryChart = () => {
    client
      .get(EMOTION_SUMMARY)
      .then((res) => {
        this.setState({
          emotionSummary: res.data.response[0],
          EmotionSummaryPreloader: false,
        });
      })
      .catch((error) => {
        console.log(error)
        this.setState({ EmotionSummaryPreloader: false });
      });
  };

  getEmotionTrendsChart = () => {
    client
      .get(EMOTION_TREND)
      .then((res) => {
        this.setState({
          emotionTrends: res.data.response[0],
          EmotionTrendsPreloader: false,
        });
      })
      .catch((error) => {
        console.log(error)
        this.setState({ EmotionTrendsPreloader: false });
      });
  };

  getSentimentMediaTable = (sentiment) => {
    client
      .get(SENTIMENT_MEDIATABLE, { params: { sentiment: sentiment } })
      .then((res) => {
        this.setState({
          sentimentMediaTable: res.data.response,
          SentimentMediaTablePreloader: false,
        });
      })
      .catch((error) => {
        console.log(error)
        this.setState({ SentimentMediaTablePreloader: false });
      });
  };

  getTopicsMediaTable = () => {
    client
      .get(TOPICS_MEDIATABLE)
      .then((res) => {
        this.setState({
          topicsMediaTable: res.data.response,
          TopicsMediaTablePreloader: false,
        });
      })
      .catch((error) => {
        console.log(error)
        this.setState({ TopicsMediaTablePreloader: false });
      });
  };

  getPerformanceMediaTable = () => {
    client
      .get(PREFORMANCE_MEDIATABLE)
      .then((res) => {
        this.setState({
          performanceMediaTable: res.data.response,
          PerformanceMediaTablePreloader: false,
        });
      })
      .catch((error) => {
        console.log(error)
        this.setState({ PerformanceMediaTablePreloader: false });
      });
  };

  getPersonaMediaTable = () => {
    client
      .get(PRESONA_MEDIATABLE)
      .then((res) => {
        this.setState({
          personaMediaTable: res.data.response,
          PersonaMediaTablePreloader: false,
        });
      })
      .catch((error) => {
        console.log(error)
        this.setState({ PersonaMediaTablePreloader: false });
      });
  };

  getPurchasePathMediaTable = () => {
    client
      .get(PURCHASEPATH_MEDIATABLE)
      .then((res) => {
        this.setState({
          purchasepathMediaTable: res.data.response,
          PurchasepathMediaTablePreloader: false,
        });
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          PurchasepathMediaTablePreloader: false,
        });
      });
  };

  getTotalCurrentPostDetails = (brandId) => {
    if(brandId === 0 || brandId === null)
    return
    brandPostService
      .getTotalCurrentPost(brandId)
      .then((result) => {
        if (result.status === SUCCESS) {
          if (result.response)
            this.setState({
              totalPostCount: result.response.totalPostCount,
              currentsPostCount: result.response.currentsPostCount,
            });
        } else {
          toast.warn(result.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getNetFilterPostDetails = (brandId) => {
    if(brandId === 0 || brandId === null)
    return
    brandPostService
      .getNetFilterPost(brandId)
      .then((result) => {
        if (result.status === SUCCESS) {
          if (result.response)
            this.setState({
              netSentiment: result.response.netSentiment,
              filteredSentiment: result.response.filteredSentiment,
              defaultFilter: result.response.defaultFilter,
            });
        } else {
          toast.warn(result.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadCharts = () => {
    this.getSentimentSummaryChart();
    this.getSentimentTrendsChart(EMPTY_STRING);
    this.getEmotionSummaryChart();
    this.getEmotionTrendsChart();
    this.getSentimentMediaTable(EMPTY_STRING);
    this.getTopicsMediaTable();
    this.getPerformanceMediaTable();
    this.getPersonaMediaTable();
    this.getPurchasePathMediaTable();
  };

  handleSingleClick = (e, bucket) => {
    if (bucket === "sentiment") {
      this.setState({
        ...this.state,
        SentimentMediaTablePreloader: true,
        SentimentTrendsPreloader: true,
        SentimentMediaTable: {
          ...this.SentimentMediaTable,
          data: EMPTY_ARRAY,
        },
        sentimentTrends: {
          ...this.sentimentTrends,
          chartData: EMPTY_ARRAY,
        },
      });
      this.getSentimentMediaTable(e);
      this.getSentimentTrendsChart(e);
    }
  };

  handleDoubleClick = (e) => {
    this.props.history.push({
      pathname: "/textDetails",
      search: "?filter=" + e,
    });
  };

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const userDetails = await getUserDetails();
    if (userDetails) {
      this.setState({ userPlan: userDetails.planId });
    }

    const defaultSelectedBrand = await getDefaultBrandDetails();

    if (defaultSelectedBrand) {
      if (this.state.brandId === defaultSelectedBrand.id) return;
      this.setState({
        brandId: defaultSelectedBrand.id,
        SentimentSummaryPreloader: true,
        SentimentTrendsPreloader: true,
        EmotionSummaryPreloader: true,
        EmotionTrendsPreloader: true,
        SentimentMediaTablePreloader: true,
        TopicsMediaTablePreloader: true,
        PerformanceMediaTablePreloader: true,
        PersonaMediaTablePreloader: true,
        PurchasepathMediaTablePreloader: true,
      });
      this.loadCharts();
      this.getTotalCurrentPostDetails(defaultSelectedBrand.id);
      this.getNetFilterPostDetails(defaultSelectedBrand.id);
    }
  }

  async componentDidMount() {
    const userDetails = await getUserDetails();
    if (userDetails) {
      this.setState({ userPlan: userDetails.planId });
    }

    const defaultSelectedBrand = await getDefaultBrandDetails();
    if (defaultSelectedBrand) {
      this.setState({ brandId: defaultSelectedBrand.id, loading: false });
      this.loadCharts();
      this.getTotalCurrentPostDetails(defaultSelectedBrand.id);
      this.getNetFilterPostDetails(defaultSelectedBrand.id);
    } else 
      this.setState({ loading: false });
  }

  render() {
    const {
      brandId,
      userPlan,
      sentimentSummary,
      sentimentTrends,
      emotionSummary,
      emotionTrends,
      sentimentMediaTable,
      topicsMediaTable,
      performanceMediaTable,
      personaMediaTable,
      purchasepathMediaTable,
      loading,
    } = this.state;

    return (
      <React.Fragment>
        {loading && <DataPreloader text="loading" />}

        <h1>Summary</h1>
        {brandId === null || brandId === 0 ? (
          <NoBrandSelectedMessage {...this.props} />
        ) : (
          <div className="brandContent" id="summaryGrid">
            <BrandPostDetails
              brandId={brandId}
              totalPostCount={this.state.totalPostCount}
              currentsPostCount={this.state.currentsPostCount}
              netSentiment={this.state.netSentiment}
              filteredSentiment={this.state.filteredSentiment}
              defaultFilter={this.state.defaultFilter}
            />
            <Grid container spacing={3}>
              {/* Sentiment Summary Chart */}
              <Grid item xs={12} sm={6} md={4}>
                <div className="chartGrid">
                  {this.state.SentimentSummaryPreloader ? (
                    <DataPreloader />
                  ) : (
                    <PieChart
                      chartTitle={sentimentSummary.title}
                      chartData={sentimentSummary.chartData}
                      handleSingleClick={(e) =>
                        this.handleSingleClick(e, "sentiment")
                      }
                      handleDoubleClick={this.handleDoubleClick}
                    />
                  )}
                </div>
              </Grid>

              {/* Sentiment Trends Chart */}
              <Grid item xs={12} sm={6} md={4}>
                <div className="chartGrid">
                  {this.state.SentimentTrendsPreloader ? (
                    <DataPreloader />
                  ) : (
                    <TrendsChart
                      chartTitle={sentimentTrends.title}
                      chartData={sentimentTrends.chartData}
                      handleSingleClick={(e) =>
                        this.handleSingleClick(e, "sentiment")
                      }
                      handleDoubleClick={this.handleDoubleClick}
                    />
                  )}
                </div>
              </Grid>

              {/* Sentiment by Media Table */}
              <Grid item xs={12} sm={6} md={4}>
                <div className="chartGrid">
                  {this.state.SentimentMediaTablePreloader ? (
                    <DataPreloader />
                  ) : (
                    <SummaryTable
                      chartTitle={sentimentMediaTable.title}
                      data={sentimentMediaTable.data}
                    />
                  )}
                </div>
              </Grid>

              {/* Emotion Summary Chart */}
              <Grid item xs={12} sm={6} md={4}>
                <div className="chartGrid">
                  {this.state.EmotionSummaryPreloader ? (
                    <DataPreloader />
                  ) : (
                    <PieChart
                      chartTitle={emotionSummary.title}
                      chartData={emotionSummary.chartData}
                      handleSingleClick={(e) =>
                        this.handleSingleClick(e, "emotion")
                      }
                      handleDoubleClick={this.handleDoubleClick}
                    />
                  )}
                </div>
              </Grid>

              {/* Emotion Trends Chart */}
              <Grid item xs={12} sm={6} md={4}>
                <div className="chartGrid">
                  {this.state.EmotionTrendsPreloader ? (
                    <DataPreloader />
                  ) : (
                    <TrendsChart
                      chartTitle={emotionTrends.title}
                      chartData={emotionTrends.chartData}
                      handleSingleClick={(e) =>
                        this.handleSingleClick(e, "sentiment")
                      }
                      handleDoubleClick={this.handleDoubleClick}
                    />
                  )}
                </div>
              </Grid>

              {/* Topics by Media Table */}
              {userPlan !== PLAN_BASIC && (
                <Grid item xs={12} sm={6} md={4}>
                  <div className="chartGrid">
                    {this.state.TopicsMediaTablePreloader ? (
                      <DataPreloader />
                    ) : (
                      <SummaryTable
                        chartTitle={topicsMediaTable.title}
                        data={topicsMediaTable.data}
                      />
                    )}
                  </div>
                </Grid>
              )}

              {/* performance by Media Table */}
              <Grid item xs={12} sm={6} md={4}>
                <div className="chartGrid">
                  {this.state.PerformanceMediaTablePreloader ? (
                    <DataPreloader />
                  ) : (
                    <SummaryTable
                      chartTitle={performanceMediaTable.title}
                      data={performanceMediaTable.data}
                    />
                  )}
                </div>
              </Grid>

              {/* persona by Media Table */}
              <Grid item xs={12} sm={6} md={4}>
                <div className="chartGrid">
                  {this.state.PersonaMediaTablePreloader ? (
                    <DataPreloader />
                  ) : (
                    <SummaryTable
                      chartTitle={personaMediaTable.title}
                      data={personaMediaTable.data}
                    />
                  )}
                </div>
              </Grid>

              {/* purchasepath by Media Table */}
              <Grid item xs={12} sm={6} md={4}>
                <div className="chartGrid">
                  {this.state.PurchasepathMediaTablePreloader ? (
                    <DataPreloader />
                  ) : (
                    <SummaryTable
                      chartTitle={purchasepathMediaTable.title}
                      data={purchasepathMediaTable.data}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Dashboard;

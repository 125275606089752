import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TablePagination from '@material-ui/core/TablePagination';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles'
import { toast, Zoom } from 'react-toastify';
import { Search, ArrowUpwardOutlined, ArrowDownwardOutlined, Edit } from '@material-ui/icons';
import { IconButton, TextField, Tooltip } from '@material-ui/core';

import client from '../../../../_helpers/client';
import { SUCCESS, WARN, EMPTY_STRING, EMPTY_ARRAY, ERROR_MESSAGE, NO_DEFAUL_BRAND_SELECTED } from '../../../../Constants';
import { VIEW_SCORED_BRANDS, MAKE_AS_DEFAULT_BRAND } from '../../../../Routes';
import GridPreloader from '../../../../containers/Preloader/DataPreloader';
import { getDefaultBrandDetails } from '../../../../_helpers/index';
import { loadAtd } from '../../../../actions';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: '12px',
    maxWidth: '600px',
    backgroundColor: '#eee',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '500px',
    }
  },
}));


function BrandsPaginationActions(props) {

    const { count, page, rowsPerPage, onChangePage } = props;

    function handleFirstPageButtonClick(event) {
        onChangePage(event, 0);
    }

    function handleBackButtonClick(event) {
        onChangePage(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onChangePage(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className="pageButtons">
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="First Page">
                {<FirstPageIcon />}
            </IconButton>

            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
                {<KeyboardArrowLeft />}
            </IconButton>

            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="Next Page">
                {<KeyboardArrowRight />}
            </IconButton>

            <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="Last Page">
                {<LastPageIcon />}
            </IconButton>
        </div>
    )
}

const DefaultSelectedBrand = props => {
    let toastId = null;

    const dispatch = useDispatch();
    const classes = useStyles();

    const { updateDefaultBrand, handleBrandEdit,handleBrandHaveSensitiveData } = props;
    const [brandDetails, setBrandDetails] = useState(NO_DEFAUL_BRAND_SELECTED);

    const [showBrands, setShowBrands] = useState(false);
    const [showPreLoader, setShowPreLoader] = useState(false);
    const [brandsList, setBrandsList] = useState(EMPTY_ARRAY);

    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    const [sortOrder, setSortOrder] = useState('asc');
    const [searchWord, setSearchWord] = useState(EMPTY_STRING);
    const [totalCount, setTotalCount] = useState(0);
    const [pathName, setPathName] = useState(EMPTY_STRING);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    if (window.location.pathname !== pathName) {
      setPathName(window.location.pathname)
    }

    //Get Scored Brands
    const getScoredBrandsList = (page, pageSize, sortOrder, searchWord) => {
        setShowPreLoader(true);
        client
            .get(VIEW_SCORED_BRANDS, {
                params: {
                    pageNo: page, sortOrder: sortOrder, searchWord: searchWord, pageSize: pageSize
                }
            })
            .then(res => {
                if (res.data.status === SUCCESS) {
                  setBrandsList(res.data.response.brands);
                  setPageNo(res.data.response.pageNo);
                  setPageSize(res.data.response.pageSize);
                  setSortOrder(res.data.response.sortOrder);
                  setSearchWord(res.data.response.searchWord);
                  setTotalCount(res.data.response.total);
                  setShowBrands(true);
                } else {
                  if (res.data.status === WARN) toast.warn(res.data.message)
                  else toast.error(res.data.message)
                }
                setShowPreLoader(false)
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.data.status === WARN) toast.warn(error.response.data.message)
                    else toast.error(error.response.data.message)
                }
                setShowPreLoader(false)
            });
    }

    //Make default select brand event
    const markDefaultSelectedBrand = (e, defaultId) => {
      e.stopPropagation();
      e.preventDefault();

      client
        .get(MAKE_AS_DEFAULT_BRAND, { params: { brandId: defaultId } })
        .then(res => {
          if (res.data.status === SUCCESS) {
              localStorage.setItem("defaultSelectedBrand", JSON.stringify(res.data.response));
              setBrandDetails(res.data.response);
              setShowBrands(false);
              dispatch(loadAtd(true));
              updateDefaultBrand();
              handleBrandHaveSensitiveData();
          } else {
              if (res.data.status === WARN) toast.update(toastId, { type: toast.TYPE.WARNING, render: ERROR_MESSAGE, autoClose: 3500 });
              else toast.update(toastId, { type: toast.TYPE.ERROR, render: ERROR_MESSAGE, autoClose: 3500 });
          }
        })
        .catch(error => {
          if (error.response) {
              if (error.response.data.status === WARN)
                  toast.update(toastId, { type: toast.TYPE.WARNING, render: error.response.data.message, autoClose: 3500 });
              else
                  toast.update(toastId, { type: toast.TYPE.ERROR, 
                      render: error.response.data.message, autoClose: 3500 });
          }
        });
    }

    const handleEdit = (e, item) => {
        e.stopPropagation();
        handleBrandEdit(item);
        setShowBrands(false);
    }

    const handleDefaultEdit = (e) => {
        let item = {};
        item["id"] = brandDetails.id;
        item["name"] = brandDetails.name;
        item["modelName"] = brandDetails.modelName;
        item["myBrandId"] = brandDetails.myBrandId;
        handleEdit(e, item);
    }

    const handleBrandsSort = (e) => {
        let sort = 'asc';
        if (sortOrder === 'asc') sort = 'desc'
        getScoredBrandsList(pageNo, pageSize, sort, searchWord);
    }

    const getFilteredBrands = (searchReg) => getScoredBrandsList(pageNo, pageSize, sortOrder, searchReg);

    const handleBrandsSearch = (e) => {
        if (e.key === undefined) {
            if (document.getElementById('searchScoringBrands').value === EMPTY_STRING)
                document.getElementById('searchScoringBrands').focus();
            else
                getFilteredBrands(document.getElementById('searchScoringBrands').value)
        } else {
            if (e.key === 'Enter' || e.target.value === EMPTY_STRING) {
                if (e.target.value === EMPTY_STRING) {
                    e.target.focus();
                    getScoredBrandsList(1, 100, 'asc', EMPTY_STRING);
                } else
                    getFilteredBrands(e.target.value);
            }
        }
    }

    const handleBrandsChangePage = (event, page) => getScoredBrandsList(page + 1, pageSize, sortOrder, searchWord);

    const showScoredBrands = () => getScoredBrandsList(pageNo, pageSize, sortOrder, searchWord);

    const closeOverlay = () => {
        setShowBrands(false);
        setShowPreLoader(false);
    }

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    useEffect(() => {
        async function getBrandDetails() {
            const defaultSelectedBrand = await getDefaultBrandDetails();
            if (defaultSelectedBrand === null) return;
            setBrandDetails(defaultSelectedBrand);
        }
        getBrandDetails();

        //To change default selected brand
        let t = setInterval(() => {
            if (localStorage.getItem('defaultSelectedBrand') === null) {
                setBrandDetails(NO_DEFAUL_BRAND_SELECTED);
                return
            }
            setBrandDetails(JSON.parse(localStorage.getItem('defaultSelectedBrand')));
        }, 1000);

        return () => clearInterval(t);
    }, []);

    return (
      <div className='defaultBrandContainer'>
        <div className="brandSelected">
          <div className="brandSelectedChild">
            <div className="brandSelectedChildCell">
              <div className="label">BRAND:</div>
              <div
                id="selectedBrandDetails"
                data-stream={brandDetails === null ? 0 : brandDetails.id}
                onClick={showScoredBrands}
              >
                <Tooltip title={brandDetails === null ? 'No Brand' : brandDetails.name} TransitionComponent={Zoom}>
                  <div>
                    <span id="selectedBrandName">
                      {brandDetails === null
                        ? "no Brand Selected"
                        : brandDetails.name.length <= 32
                        ? brandDetails.name
                        : brandDetails.name.substring(0, 30) + ".."}
                    </span>&nbsp;
                  </div>
                </Tooltip><i className="fa fa-angle-down"></i>
              </div>
            </div>
          </div>
          {showBrands && (
            <div className="ovelayDocument" data-testid="overlayDocument" onClick={() => closeOverlay()}></div>
          )}
          <div className="allBrandsHolder">
            {showPreLoader ? (
              <div className="scoredBrandsLoader">
                <GridPreloader />
              </div>
            ) : (
              showBrands && (
                <div className="overlayDetails">
                  <div className="defaultSelectedBrand">
                    <div className="bnd">
                      <label className="brandLabel">
                        {brandDetails !== null ? brandDetails.name : null}
                      </label>
                      <span className="streamId">
                        (StreamId:{" "}
                        {brandDetails !== null ? brandDetails.id : null})
                      </span>
                      <br />
                      <label className="selectedBrandModel">Model: </label>
                      <span className="brandModel">
                        {brandDetails !== null ? brandDetails.modelName : null}
                      </span>
                    </div>
                    <div className="bnd-edit">
                      <Tooltip title="Edit" TransitionComponent={Zoom}>
                        <span>
                          <IconButton
                            disabled={brandDetails && brandDetails.id === 0}
                            onClick={(e) => handleDefaultEdit(e)}
                            data-testid="defaultBrandEditIcon"
                          >
                            <Edit />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                  <div className={"searchSortBrands " + sortOrder}>
                    <div>
                      <Tooltip title="Search" TransitionComponent={Zoom}>
                        <IconButton
                          size="small"
                          onClick={(e) => handleBrandsSearch(e)}
                        >
                          <Search />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className="searchBrand">
                      <TextField
                        id="searchScoringBrands"
                        label=""
                        value={searchWord}
                        onChange={(e) => setSearchWord(e.target.value)}
                        onKeyUp={(e) => handleBrandsSearch(e)}
                        placeholder="Search scored brands"
                        margin="normal"
                        fullWidth
                        inputProps={{ "data-testid": "searchScoredBrands"}}
                      />
                    </div>
                    <div>
                      <Tooltip title="Sort" TransitionComponent={Zoom}>
                        <IconButton
                          size="small"
                          onClick={() => handleBrandsSort()}
                          data-testid="brandSortIcon"
                        >
                          {sortOrder === "asc" ? (
                            <ArrowUpwardOutlined />
                          ) : (
                            <ArrowDownwardOutlined />
                          )}
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>

                  <div className="listItemGroup">
                    {brandsList.length !== 0 ? (
                      brandsList.map((list) => {
                        return (
                          <div
                            className="listItem"
                            key={list.id}
                            onClick={(e) => markDefaultSelectedBrand(e, list.id)}
                          >
                            <label className="brandLabel">{list.name}</label>
                            <span className="streamId">
                              (StreamId: {list.id})
                            </span>
                            <br />
                            <label className="selectedBrandModel">Model: </label>
                            <span className="brandModel">{list.modelName}</span>
                            <div className="hover-options">
                              <span>
                                <Tooltip title="Edit" TransitionComponent={Zoom}>
                                  <IconButton
                                    onClick={(e) => handleEdit(e, list)}
                                    data-testid={`scoredBrandEdit${list.id}`}
                                  >
                                    <Edit />
                                  </IconButton>
                                </Tooltip>
                              </span>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="listItem">No brands found</div>
                    )}
                  </div>
                  <div className="scoredBrandsPagination">
                    <TablePagination
                      component="div"
                      page={pageNo - 1}
                      count={totalCount}
                      rowsPerPage={pageSize}
                      labelRowsPerPage={""}
                      rowsPerPageOptions={[]}
                      onChangePage={() => handleBrandsChangePage()}
                      ActionsComponent={BrandsPaginationActions}
                    />
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        {pathName === '/atd' && brandDetails && !!brandDetails.id && (
          <div className='missionQuesContainer'>
            <div className='missionQuesTitle'>
              <h4>MISSION QUESTION:</h4>
              <Tooltip title='Edit Mission Question'>
                <IconButton
                  className='missionQuesEditBtn'
                  aria-label='Edit Mission Question'
                  onClick={() => props.handleMissionQuesModal(brandDetails.objective)}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            </div>
            {brandDetails.objective ? (
              <>
                <Typography
                  aria-owns={open ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >
                  {brandDetails.objective}
                </Typography>
                <Popover
                  id="mouse-over-popover"
                  className={classes.popover}
                  classes={{
                    paper: classes.paper,
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Typography style={{ fontSize: '14px' }}>{brandDetails.objective}</Typography>
                </Popover>
              </>
            ) : (
              <em>Add a brand objective</em>
            )}
          </div>
        )}
      </div>
    );
}

export default DefaultSelectedBrand
const emailRegExp = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
exports.validateEmail = function (email) {
    if (!email)
        return false;

    if (email.length > 254)
        return false;

    var valid = emailRegExp.test(email);
    if (!valid)
        return false;

    // Further checking of some things regex can't handle
    var parts = email.split("@");
    if (parts[0].length > 64)
        return false;

    var domainParts = parts[1].split(".");
    if (domainParts.some(function (part) { return part.length > 63; }))
        return false;

    return true;
}

const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/;
exports.validatePassword = function (str) {
    if (!str)
        return false;

    var valid = passwordRegExp.test(str);
    if (!valid)
        return false;

    return true;
}

const phoneRegExp = /^(?=.*[0-9])[0-9]+$/;
exports.validatePhone = function (str) {
    if (!str)
        return false;

    let valid = phoneRegExp.test(str);
    if (!valid)
        return false

    return true
}

exports.notEmpty = function (str) {
    if (!str)
        return false;

    return true
}


import './NoBrandSelected.css'
import React from 'react';

class NoBrandSelectedMessage extends React.Component {
    
    render() {
        return(
            <div className="noBrandSelected">
                <h3>Warning!</h3>
                <small>There is no brand selected. Please select brand first. <br/>
                <span onClick={()=> {
                    this.props.history.push('/workshop');
                }}>Click here to create a brand</span>.
                </small>
            </div>
        )
    }
}

export default NoBrandSelectedMessage;
import "./Modal.css";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { CLASSIFIER } from "../../Constants";

class ErrorModel extends React.Component {
  render() {
    const { isOpen, type, bindedBrands, handleClose } = this.props;
    return (
      <Dialog
        className="dialogError"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modelDeleteError"
      >
        <div className="uiDialog deleteModel">
          <div className="uiDialogTitle">
            <h6>Error</h6>
            <div className="modalCloseBtn">
              <IconButton onClick={handleClose} data-testid="close-button">
                <Close />
              </IconButton>
            </div>
          </div>
          <div className="uiDialogContent">
            <div className="modelDeleteDialog">
              <p>{ type === CLASSIFIER ? "Delete the dependency to delete the classifier." :
                "Model is already binded to brand. Please remove it from brand first." }
              </p>
              <table>
                <thead>
                  <tr>
                    <th>{type === CLASSIFIER ? "Classifier" :"Model"}</th>
                    <th>{type === CLASSIFIER ? "Dependency Classifiers" :"Binded Brand"}</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(bindedBrands).map(([key, value]) => {
                    return (
                      <tr key={key}>
                        <td> {`${key}`} </td>
                        <td> {`${value}`} </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default ErrorModel;

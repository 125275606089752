import React, { Component, Suspense } from "react";
import ReactDOM from "react-dom";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const UploadDataSource = React.lazy(() => import("./Upload/Upload"));
const TwitterDataSource = React.lazy(() => import("./Twitter/Twitter"));
const FacebookDataSource = React.lazy(() => import("./FaceBook/FaceBook"));
const BazzarVoice = React.lazy(() => import("./BazzarVoice/BazzarVoice"));
const TypeForm = React.lazy(() => import("./TypeForm/TypeForm"));
const Wufoo = React.lazy(() => import("./Wufoo/Wufoo"));
const APIDataSource = React.lazy(() => import("./API/API"));
const S3DataSource = React.lazy(() => import("./S3/S3"));
const Localytics = React.lazy(() => import("./Localytics/Localytics"));

class DataSourceOptions extends Component {
  state = {
    labelWidth: 0,
    dataSourceType: this.props.dataSourceList[0].id
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
    });
  }

  selectDataSource = e => {
    this.setState({
      ...this.state,
      dataSourceType: e.target.value
    });
  };

  handleAddedDetails = d => {
    this.props.updatedAvailableDetails(d);
  };

  render() {
    const loop = data => {
      return data.map((item, i) => {
        return (
          <MenuItem key={i} value={item.id} data-testid={item.name}>
            {item.name}
          </MenuItem>
        );
      });
    };
    const optionsList = loop(this.props.dataSourceList);

    return (
      <Grid item xs={6} className="grid">
        <Grid item xs={12} className="innerGrid">
          <div className="data-sources-widget">
            <div className="dataSourceSelect">
              <FormControl variant="outlined" className="formControl">
                <InputLabel
                  shrink
                  ref={ref => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="dataSourceType"
                >
                  Selected Data Source
                </InputLabel>
                <Select
                  value={this.state.dataSourceType}
                  onChange={this.selectDataSource}
                  input={
                    <OutlinedInput
                      labelWidth={this.state.labelWidth}
                      name="dataSourceType"
                      id="dataSourceType"
                    />
                  }
                  inputProps={{ "data-testid": "select-input" }}
                >
                  {optionsList}
                </Select>
              </FormControl>
            </div>
          </div>

          <Suspense fallback={null}>
            {this.state.dataSourceType === 1 ? (
              <UploadDataSource
                brandId={this.props.brandId}
                addedDetails={this.handleAddedDetails}
              />
            ) : this.state.dataSourceType === 3 ? (
              <TwitterDataSource
                brandId={this.props.brandId}
                addedDetails={this.handleAddedDetails}
              />
            ) : this.state.dataSourceType === 4 ? (
              <FacebookDataSource
                brandId={this.props.brandId}
                addedDetails={this.handleAddedDetails}
              />
            ) : this.state.dataSourceType === 5 ? (
              <BazzarVoice
                brandId={this.props.brandId}
                addedDetails={this.handleAddedDetails}
              />
            ) : this.state.dataSourceType === 9 ? (
              <TypeForm
                brandId={this.props.brandId}
                addedDetails={this.handleAddedDetails}
              />
            ) : this.state.dataSourceType === 10 ? (
              <Wufoo
                brandId={this.props.brandId}
                addedDetails={this.handleAddedDetails}
              />
            ) : this.state.dataSourceType === 11 ? (
              <APIDataSource
                brandId={this.props.brandId}
                addedDetails={this.handleAddedDetails}
              />
            ) : this.state.dataSourceType === 12 ? (
              <S3DataSource
                brandId={this.props.brandId}
                addedDetails={this.handleAddedDetails}
              />
            ) : this.state.dataSourceType === 16 ? (
              <Localytics
                brandId={this.props.brandId}
                addedDetails={this.handleAddedDetails}
              />
            ) : null}
          </Suspense>
        </Grid>
      </Grid>
    );
  }
}

export default DataSourceOptions;

import React, { Component } from "react";
import client from "../../../_helpers/client";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";

import DataSourceOptions from "./DataSourceOptions";
import AvailableDataSources from "./AvailableInBoundDS";
import PreprocessingSettings from "./PreprocessingSettings/PreprocessingSettings";

import { SUCCESS, WARN, EMPTY_ARRAY, NORMALIZE_PUNCTUATION_OPTIONS, NORMALIZE_SPACING_OPTION, EMPTY_STRING, ERROR } from "../../../Constants";
import { AVAILABLE_DATASOURCES, BRAND_DATASOURCES, DATA_SOURCE_PREPROCESSING, GET_PREPROCESSING_DATA } from "../../../Routes";
import { v4 as uuid } from "uuid";
import BrandDataSetLabel from "./BrandDataSetLabel";

class InBoundDS extends Component {
  state = {
    dataSourceDisplay: false,
    dataSourceAvailable: EMPTY_ARRAY,
    dataSourceList: EMPTY_ARRAY,
    openPreProcessingModal: false,
    loadingPreprocessingData: false,
    preProcessDataSourceId: null,
    newDataSource: false,
    nonASCIICharOption: 'transliterate',
    punctuationOptions: [...NORMALIZE_PUNCTUATION_OPTIONS],
    normalizeSpacing: NORMALIZE_SPACING_OPTION,
    advancedRules: [
      { id: uuid(), regex: EMPTY_STRING, replacement: EMPTY_STRING }
    ],
    submittingPreProcessingData: false,
    removeDuplicatesOption: 'allow',
    removeDuplicatesCount: 6,
    duplicatesCountError: false
  };

  getAvailableDataSources = async () => {
    try {
      const res = await client.get(AVAILABLE_DATASOURCES, {
        params: { brandId: this.props.brandId }
      });
      if (res.data.status === SUCCESS) {
        this.setState({
          ...this.state,
          dataSourceAvailable: res.data.response
        });
        if (this.props.handleBrandDataSources) this.props.handleBrandDataSources(res.data.response);
      } else {
        if (res.data.status === WARN) toast.warn(res.data.message);
        else toast.error(res.data.message);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.status === WARN)
          toast.warn(error.response.data.message);
        else toast.error(error.response.data.message);
      }
    }
  };

  getBrandDataSources = async () => {
    try {
      const res = await client.get(BRAND_DATASOURCES, {
        params: { brandId: this.props.brandId }
      });
      if (res.data.status === SUCCESS) {
        this.setState({
          ...this.state,
          dataSourceList: res.data.response
        });
        if (this.props.handleDataSourcesList) this.props.handleDataSourcesList(res.data.response);
      } else {
        if (res.data.status === WARN) toast.warn(res.data.message);
        else toast.error(res.data.message);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.status === WARN)
          toast.warn(error.response.data.message);
        else toast.error(error.response.data.message);
      }
    }
  };

  showDataSource = () => {
    this.setState({
      ...this.state,
      dataSourceDisplay: !this.state.dataSourceDisplay
    });
  };

  updatedAvailableDetails = () => {
    this.setState({
      ...this.state,
      dataSourceDisplay: false
    });
    this.getAvailableDataSources();
    this.getBrandDataSources();
  };

  refreshAvailableDataSources = () => {
    this.getBrandDataSources();
    client
      .get(AVAILABLE_DATASOURCES, { params: { brandId: this.props.brandId } })
      .then(res => {
        if (res.data.status === SUCCESS) {
          this.setState({
            ...this.state,
            dataSourceAvailable: res.data.response
          });
          if (res.data.response.length === 0) {
            this.setState({
              ...this.state,
              dataSourceDisplay: false
            });
          }
          if (this.props.handleBrandDataSources) this.props.handleBrandDataSources(res.data.response);
        } else {
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.status === WARN)
            toast.warn(error.response.data.message);
          else toast.error(error.response.data.message);
        }
      });
  };

  handlePreprocessingModal = async (id, isNew) => {
    if (this.state.submittingPreProcessingData) return

    this.setState((state) => {
      const updatedState = {
        ...state,
        openPreProcessingModal: !state.openPreProcessingModal,
        preProcessDataSourceId: id,
        newDataSource: isNew,
        loadingPreprocessingData: id ? true : false,
      }
      if (state.openPreProcessingModal) {
        return {
          ...updatedState,
          nonASCIICharOption: 'transliterate',
          punctuationOptions: [...NORMALIZE_PUNCTUATION_OPTIONS],
          normalizeSpacing: NORMALIZE_SPACING_OPTION,
          advancedRules: [
            { id: uuid(), regex: EMPTY_STRING, replacement: EMPTY_STRING },
          ],
        }
      } else return updatedState
    })

    if (id) {
      try {
        const { data } = await client.get(GET_PREPROCESSING_DATA, {
          params: { dataSourceId: id },
        })

        if (data.status === SUCCESS && data.response) {
          const resData = data.response

          let dupOption, dupCount
          if (resData.duplicateRemovalRules && resData.duplicateRemovalRules === 'all') {
            dupOption = 'all'
            dupCount = 6
          } else if (resData.duplicateRemovalRules && resData.duplicateRemovalRules === 'not_all') {
            dupOption = 'not_all';
            dupCount = resData.duplicateRemovalRules || 6
          } else {
            dupOption = 'allow';
            dupCount = resData.duplicateRemovalRules || 6
          }

          this.setState((state) => ({
            ...state,
            nonASCIICharOption: resData.hasOwnProperty('nonASCIICharRule')
              ? resData.nonASCIICharRule
              : state.nonASCIICharOption,
            punctuationOptions: resData.hasOwnProperty('punctuationRules')
              ? state.punctuationOptions.map((e) => {
                  const option = JSON.parse(resData.punctuationRules).find(
                    (p) => p.regex === e.regex
                  )
                  return { ...e, checked: option ? true : false }
                })
              : state.punctuationOptions,
            normalizeSpacing: {
              ...NORMALIZE_SPACING_OPTION,
              checked: resData.spacingRule ? true : false,
            },
            removeDuplicatesOption: dupOption,
            removeDuplicatesCount: dupCount,
            advancedRules: resData.hasOwnProperty('regexRules')
              ? JSON.parse(resData.regexRules).map((e) => ({
                  ...e,
                  id: uuid(),
                }))
              : state.advancedRules,
            loadingPreprocessingData: false,
          }))
        } else {
          this.setState((state) => ({
            ...state,
            loadingPreprocessingData: false,
          }))
        }
      } catch (err) {
        console.error(err)
        this.setState((state) => ({
          ...state,
          loadingPreprocessingData: false,
        }))
      }
    }
  }

  handleNonASCIICharOption = (val) => {
    this.setState((state) => ({
      ...state,
      nonASCIICharOption: val
    }))
  }

  handlePunctuationOptions = (name, val) => {
    this.setState((state) => ({
      ...state,
      punctuationOptions: [...state.punctuationOptions].map((item) => {
        if (item.name === name) {
          return { ...item, checked: val }
        } else return item
      })
    }))
  }

  handleNormalizeSpacing = (val) => {
    this.setState((state) => ({
      ...state,
      normalizeSpacing: {
        ...state.normalizeSpacing,
        checked: val
      }
    }))
  }

  handleAdvancedRules = (id, name, val) => {
    this.setState((state) => ({
      ...state,
      advancedRules: [...state.advancedRules].map((item) => {
        if (item.id === id) {
          if (name === 'regex') {
            return { ...item, [name]: val, error: false }
          } else {
            return { ...item, [name]: val }
          }
        } else return item
      })
    }))
  }

  addNewAdvancedRule = () => {
    this.setState((state) => ({
      ...state,
      advancedRules: [...state.advancedRules].concat({
        id: uuid(), regex: EMPTY_STRING, replacement: EMPTY_STRING
      })
    }))
  }

  submitPreprocessingData = async () => {
    try {
      const formData = new FormData()
      formData.append('dataSourceId', this.state.preProcessDataSourceId)
      formData.append('nonASCIICharRule', this.state.nonASCIICharOption)
      formData.append('punctuationRules', JSON.stringify(
        this.state.punctuationOptions.filter(e => e.checked)
        .map(e => ({ regex: e.regex, replacement: e.replacement}))
      ))
      formData.append('spacingRules', JSON.stringify(
        this.state.normalizeSpacing.checked ? this.state.normalizeSpacing : null
      ))
      formData.append('regexRules', JSON.stringify(
        this.state.advancedRules.filter(e => e.regex !== EMPTY_STRING)
      ))
      if (this.state.removeDuplicatesOption !== 'allow') {
        if (this.state.removeDuplicatesOption === 'all')
          formData.append('duplicateRemovalRules', this.state.removeDuplicatesOption)
        else if (this.state.removeDuplicatesCount === '') {
          this.setState((state) => ({
            ...state,
            duplicatesCountError: true
          }))
          return
        } else formData.append('duplicateRemovalRules', this.state.removeDuplicatesCount)
      }
      try {
        this.setState((state) => ({
          ...state, submittingPreProcessingData: true
        }))
        const { data } = await client.post(DATA_SOURCE_PREPROCESSING, formData)
        if (data.status === SUCCESS) {
          toast.success('Preprocessing settings updated successfully.')
          this.setState((state) => ({
            ...state, submittingPreProcessingData: false
          }))
          this.handlePreprocessingModal()
        } else if (data.status === ERROR) {
          const invalidRegexList = data.response
          this.setState((state) => ({
            ...state,
            submittingPreProcessingData: false,
            advancedRules: [...state.advancedRules].filter(e => e.regex).map(e => {
              if (invalidRegexList.includes(e.id)) {
                return { ...e, error: true }
              } else return e
            })
          }))
        } else {
          toast.error(data.message)
          this.setState((state) => ({
            ...state, submittingPreProcessingData: false
          }))
        }
      } catch(err) {
        toast.error('Could not save the data. Please try again.')
        this.setState((state) => ({
          ...state, submittingPreProcessingData: false
        }))
      }
    } catch(err) {
      console.log(err)
      toast.error('There was some error. Please try again.')
    }
  }

  resetPreProcessingSettings = () => {
    this.setState((state) => ({
      ...state,
      nonASCIICharOption: 'transliterate',
      punctuationOptions: [...NORMALIZE_PUNCTUATION_OPTIONS],
      normalizeSpacing: NORMALIZE_SPACING_OPTION,
      advancedRules: [
        { id: uuid(), regex: EMPTY_STRING, replacement: EMPTY_STRING }
      ],
      removeDuplicatesOption: 'allow',
      removeDuplicatesCount: 6,
      duplicatesCountError: false
    }))
  }

  handleRemoveDuplicatesCountChange = (val) => {
    this.setState((state) => ({
      ...state,
      removeDuplicatesCount: val,
      duplicatesCountError: false
    }))
  }

  handleRemoveDuplicatesOptionChange = (val) => {
    this.setState((state) => ({
      ...state,
      removeDuplicatesOption: val,
      duplicatesCountError: false
    }))
  }

  componentDidMount() {
    const { dataSourcesList, brandDataSources } = this.props;
    if (!brandDataSources) {
      this.getAvailableDataSources();
    } else {
      this.setState((state) => ({
        ...state,
        dataSourceAvailable: brandDataSources
      }));
    }
    if (!dataSourcesList || !dataSourcesList.length) {
      this.getBrandDataSources();
    } else {
      this.setState((state) => ({
        ...state,
        dataSourceList: dataSourcesList
      }));
    }
  }

  render() {
    return (
      <>
        <PreprocessingSettings
          open={this.state.openPreProcessingModal}
          dataLoading={this.state.loadingPreprocessingData}
          handleModal={this.handlePreprocessingModal}
          nonASCIICharOption={this.state.nonASCIICharOption}
          handleNonASCIICharOption={this.handleNonASCIICharOption}
          puntuationOptions={this.state.punctuationOptions}
          handlePunctuationOptions={this.handlePunctuationOptions}
          normalizeSpacing={this.state.normalizeSpacing}
          handleNormalizeSpacing={this.handleNormalizeSpacing}
          advancedRules={this.state.advancedRules}
          handleAdvancedRules={this.handleAdvancedRules}
          addNewAdvancedRule={this.addNewAdvancedRule}
          submit={this.submitPreprocessingData}
          loading={this.state.submittingPreProcessingData}
          disableEdit={!(this.state.newDataSource && this.props.brandStatus !== 0)}
          handleReset={this.resetPreProcessingSettings}
          duplicatesOption={this.state.removeDuplicatesOption}
          duplicatesCount={this.state.removeDuplicatesCount}
          handleDuplicatesOption={this.handleRemoveDuplicatesOptionChange}
          handleDuplicatesCount={this.handleRemoveDuplicatesCountChange}
          duplicatesCountError={this.state.duplicatesCountError}
        />

        <Grid container className="rowGrid">
          <Grid item xs={6} className="grid">
            <div className="newButton">
              <Button
                variant="contained"
                color="primary"
                disabled={
                  this.props.brandStatus === 0 ||
                  this.state.dataSourceList.length === 0
                }
                onClick={this.showDataSource}
                data-testid="newButton"
              >
                New
              </Button>
              {this.props.brandStatus === 0 ? (
                <i
                  className="fa fa-question-circle inprogressHelp"
                  data-toggle="tooltip"
                  title="Can't add data source while brand is in In-Progress state."
                ></i>
              ) : null}
            </div>

            {this.state.dataSourceAvailable.length ? (
              <AvailableDataSources
                datasource={this.state.dataSourceAvailable}
                brandStatus={this.props.brandStatus}
                brandId={this.props.brandId}
                refreshAvailableDataSources={this.refreshAvailableDataSources}
                openPreprocessingModal={this.handlePreprocessingModal}
              />
            ) : (
              <p className="noDataMessage">No inbound data source added</p>
            )}
           <BrandDataSetLabel brandId={this.props.brandId}/>
          </Grid>

          {this.state.dataSourceDisplay ? (
            <DataSourceOptions
              brandId={this.props.brandId}
              dataSourceList={this.state.dataSourceList}
              updatedAvailableDetails={this.updatedAvailableDetails}
            />
          ) : null}
        </Grid>
      </>
    );
  }
}

export default InBoundDS;

import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import client from '../../../../_helpers/client';
import { stringToFilterDate } from '../../../../_helpers/index';
import { toast } from 'react-toastify';
import { DELETE_USER_BRAND, USER_BRANDS } from '../../../../Routes';
import { EMPTY_OBJECT, SUCCESS, EMPTY_ARRAY, WARN } from '../../../../Constants';
import { TableCell, TableRow, Tooltip, IconButton } from '@material-ui/core';
import DataPreloader from '../../../../containers/Preloader/DataPreloader';

export default function RenderUserBrands(props) {
    const { userId } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [userBrands, setUserBrands] = React.useState(EMPTY_ARRAY);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState(EMPTY_OBJECT);

    const columns = [
        { title: 'Name', field: 'name' },
        { title: 'Created', field: 'created' },
        { title: 'Last Update', field: 'updated' },
        { title: 'Status', field: 'status' },
        { title: 'Delete', field: '' },
    ];

    const handleModalClose = () => {
        setModalOpen(false);
        setModalData(EMPTY_OBJECT);
    }

    const handleDeleteConfirm = (rowData) => {
        setModalData(rowData);
        setModalOpen(true);
    }

    let toastId = null;

    //Delete User Brand Event
    const handleBrandDelete = () => {
        toastId = toast("Delete in progress, please wait...", { autoClose: false });
        setModalOpen(false);
        client.get(DELETE_USER_BRAND, { params: { brandId: modalData.id, userId: parseInt(userId) } } )
            .then(res => {
                if (res.data.status === SUCCESS) {
                    let tempData = userBrands.filter(r => r.id !== modalData.id)
                    setUserBrands(tempData);
                    setModalData(EMPTY_OBJECT);
                    toast.update(toastId, { type: toast.TYPE.SUCCESS, render: res.data.message, autoClose: 3500 });
                } else {
                    if (res.data.status === WARN) toast.update(toastId, { type: toast.TYPE.WARNING, render: res.data.message, autoClose: 3500 });
                    else toast.update(toastId, { type: toast.TYPE.ERROR, render: res.data.message, autoClose: 3500 });
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.status === WARN)
                        toast.update(toastId, { type: toast.TYPE.WARNING, render: error.response.data.message, autoClose: 3500 });
                    else
                        toast.update(toastId, { type: toast.TYPE.ERROR, render: error.response.data.message, autoClose: 3500 });
                }
            });
    }

    //Get User Brands
    const getUserBrands = (id) => {
        client
            .get(USER_BRANDS, { params: { userId: parseInt(id) } })
            .then(res => {
                if (res.data.status === SUCCESS) {
                    setUserBrands(res.data.response.brands);
                } else {
                    if (res.data.status === WARN) toast.warn(res.data.message)
                    else toast.error(res.data.message)
                }
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.status === WARN) toast.warn(error.response.data.message)
                    else toast.error(error.response.data.message)
                }
                setIsLoading(false);
            });
    }

    useEffect(() => {
        getUserBrands(userId);
    }, []);

    return (
        <React.Fragment>
            <Dialog
                open={modalOpen}
                onClose={handleModalClose}
                aria-describedby="delete-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        Are you sure want to delete this brand. Please make sure!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose}>Cancel</Button>
                    <Button onClick={handleBrandDelete} color="primary" autoFocus>Delete</Button>
                </DialogActions>
            </Dialog>

            <div className="modal-table">
                {isLoading ? <DataPreloader /> : null}
                <MaterialTable
                    title={"User Brands Details"}
                    columns={columns}
                    data={userBrands}
                    components={{
                        Row: props => (
                            <TableRow>
                                <TableCell>{props.data.name}</TableCell>
                                <TableCell>{stringToFilterDate(props.data.created)}</TableCell>
                                <TableCell>{stringToFilterDate(props.data.updated)}</TableCell>
                                <TableCell>{
                                    props.data.status === 0
                                        ? 'In-progress'
                                        : props.data.status === 1
                                            ? 'Re-score'
                                            : props.data.status === 2
                                                ? 'Failed'
                                                : 'Created'
                                }</TableCell>
                                <TableCell>
                                    <div className="removeIcon">
                                        <Tooltip title="Delete Brand">
                                            <IconButton onClick={() => handleDeleteConfirm(props.data)} size="small">
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </TableCell>
                            </TableRow>
                        )
                    }}
                />
            </div>
        </React.Fragment>
    )

}
import React from 'react'
import Iframe from 'react-iframe'
import { Paper } from '@material-ui/core'

class DecoodaApigee extends React.Component {
  componentDidMount() {
    document.body.classList.remove('sidebar-off-canvas')
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 100)
  }

  render() {
    return (
      <React.Fragment>
        <Paper elevation={2}>
          <Iframe
            url={'https://dev-decooda-api.pantheonsite.io/'}
            width='100%'
            height='740px'
            id='decoodaApigee'
            className='myKibana'
            display='initial'
            position='relative'
            allowFullScreen
          />
        </Paper>
      </React.Fragment>
    )
  }
}

export default DecoodaApigee

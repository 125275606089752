import { 
  ADD_TOPIC, 
  SELECT_PALETTE_TYPE,
  SELECT_TOPIC,
  CREATE_PALETTE,
  DELETE_PALETTE,
  ACTIVATE_PALETTE,
  DEACTIVATE_PALETTE,
  GET_TOPICS_DATA,
  CLEAR_TOPICS,
  DELETE_TOPIC,
  ADD_BRAND_PALETTES,
  SWITCH_HIGHLIGHT,
  SAVE_PALETTE_DATA,
  SET_TOPIC_LIST
} 
from './types'
import { v4 as uuidv4 } from 'uuid';

export const savePaletteData = (data) => {
  return {
    type: SAVE_PALETTE_DATA,
    palette: { ...data }
  }
}

export const addTopicToPalette = (topic, selectedId, data, brandId) => {
  let selectedType, topicId

  if (topic) {
    topicId = topic.id
  }

  if (selectedId) {
    selectedType = selectedId
  }

  const addNewTopic = (pTopicList, topicListIds, tListById) => {

    let stackQueue = {
      size: 8,
      pTopicList,
      topicListIds,
      push: function (elem) {
        if (this.pTopicList.length < this.size) {
          this.pTopicList.push(elem)
        }
      },
      handleAddTopic: function () {
        let newTlistById = Object.assign(tListById, topicObj)
        return newTlistById 
      }
    }

    const topicObj = {
      [topicId]:topic
    }

    if (pTopicList) {
      stackQueue.push(topicId)
      return pTopicList
    }

    if(tListById){
      stackQueue.handleAddTopic()
    }

    if (topicListIds) {
      topicListIds.push(topicId)
      return topicListIds
    }
  }

  data[brandId].palettes.allIds.forEach(pId => {
    if (pId === selectedType) {
      const pType = data[brandId].palettes.byId[pId]
      pType.topicList = addNewTopic(pType.topicList, null, null)
    }
  })

 let unique = data[brandId].topicLists.allIds.filter((t, index)=>{
    return data[brandId].topicLists.allIds.indexOf(t) === index
  })
  data[brandId].topicLists.allIds = unique 

  addNewTopic(null, data[brandId].topicLists.allIds, data[brandId].topicLists.byId)
  
  return {
    type: ADD_TOPIC,
    palette: { ...data }
  }
}

export const selectPaletteType = (selectedId) => {
  return {
    type: SELECT_PALETTE_TYPE,
    selectedId
  }
}

export const createNewPalette = (name, data, brandId) => {
  let newPid = uuidv4();

  let pObj = {
    [newPid] : {
      id: newPid,
      name,
      user: "user1",
      topicList: []
    }
  }

  data[brandId].palettes.allIds.push(newPid)
  let pListById = data[brandId].palettes.byId
  Object.assign(pListById, pObj)

  return {
    type: CREATE_PALETTE,
    palette: {...data}
  }
}

export const deleteTopicPalette = (data) => {
  return {
    type: DELETE_PALETTE,
    palette: data
  }
}

export const activate = () =>{
  return {
    type: ACTIVATE_PALETTE,
    active: true
  }
}

export const deactivate = () =>{
  return {
    type: DEACTIVATE_PALETTE,
    active: false
  }
}

export const switchHighlight = (value) => {
  return {
    type: SWITCH_HIGHLIGHT,
    value
  }
}

export const selectTopic = (topic) =>{
  return{
    type: SELECT_TOPIC,
    topic
  }
}

export const setTopicsData = (data)=>{
  return{
    type: GET_TOPICS_DATA,
    data
  }
}

export const clearPalette = (pTypeId, data, brandId) =>{
  data[brandId].palettes.allIds.forEach(pId => {
    if (pId === pTypeId) {
      const pType = data[brandId].palettes.byId[pId]
      pType.topicList = []
    }
  })

  return {
    type: CLEAR_TOPICS,
    palette: {...data}
  }
}

export const deleteTopic = (paletteIds, topicId, data, brandId) => {
  data[brandId].palettes.allIds.forEach(pId => {
    if (paletteIds.includes(pId)) {
      const pType = data[brandId].palettes.byId[pId]
      pType.topicList = pType.topicList.filter(tId => tId !== topicId)
    }
  })

  return {
    type: DELETE_TOPIC,
    palette: {...data}
  }
}

export const addBrandPalettes = (brandId, data) => {
  const pData = {
    palettes:{
      byId:{
        "p1" : {
          id: "p1",
          name: "Default",
          user: "user1",
          topicList: []
        },
      },
      allIds : ["p1"]
    },
    topicLists:{
      byId:{
        "t1":{
          id: "t1",
          name: "Topic",
        }
      },
      allIds:["t1"]
    },
  }
  const updatedData = { ...data, [brandId]: pData}

  return {
    type: ADD_BRAND_PALETTES,
    palette: {...updatedData}
  }
}

export const updateTopicList = (data) => {
  return {
    type: SET_TOPIC_LIST,
    data 
  }
}
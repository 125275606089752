import React from "react";
import { Grid } from "@material-ui/core";
import ProgressBar from "./../progress-bar";

const HeaderStat = ({ summaryData, phrasesToBeAudited, auditedTopics }) => {
  return (
    <Grid className="project-statistics" container justify="space-between">
      <Grid item xs={12} sm={5} md={3}>
        <ProgressBar
          title="Classified Documents"
          numerator={summaryData.noOfClassifiedDocs}
          denominator={
            summaryData.noOfClassifiedDocs + summaryData.noOfUnclassifiedDocs
          }
        />
      </Grid>
      <Grid item xs={12} sm={5} md={3}>
        <ProgressBar
          title="Audited AI Phrases"
          numerator={phrasesToBeAudited.size}
          denominator={summaryData.noOfTopicalPhrases}
        />
      </Grid>
      <Grid item xs={12} sm={5} md={3}>
        <ProgressBar
          title="Audited AI Topics"
          numerator={auditedTopics.size}
          denominator={
            summaryData.noOfNegativeTopics +
            summaryData.noOfNeutralTopics +
            summaryData.noOfPositiveTopics
          }
        />
      </Grid>
    </Grid>
  );
};

export default HeaderStat;

import React, { useState, useEffect } from 'react'
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Button,
  TextField,
} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import mainLogo from '../../assets/images/main-logo.png'
import Background from '../../assets/images/updateUser.png'
import { UPDATE_USER_PROFILE, UPDATE_USER_PASSWORD } from '../../Routes'
import { EMPTY_STRING, SUCCESS } from '../../Constants'
import client from '../../_helpers/client'
import { toast } from 'react-toastify'
import DataPreloader from '../../containers/Preloader/DataPreloader'
import '../LandingComponent/LandingComponent.css'
import { makeStyles } from '@material-ui/core/styles'
import MuiPhoneNumber from 'material-ui-phone-number'

const useStyles = makeStyles({
  root: {
    borderRadius: '10px',
    width: '250px',
    margin: 'auto',
  },
})

const UpdateProfile = (props) => {
  const [loading, setLoading] = useState(true)
  const [userInfo, setUserInfo] = useState({})
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    mobilePhone: '',
  })
  const [password, setPassword] = useState({})
  const [passwordError, setPasswordError] = useState({})
  const [disabled, setDisabled] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [showDialog, setShowDialog] = useState(false)
  const [updateInProgress, setUpdateInProgress] = useState(false)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    async function fetchData() {
      const userInfo = JSON.parse(localStorage.getItem('userDetails'))
      if (userInfo) {
        const { firstName, lastName, mobileNumber } = userInfo
        const userDetails = {
          firstName: firstName ? firstName : '',
          lastName: lastName ? lastName : '',
          mobilePhone: mobileNumber ? mobileNumber : ''
        }
        setForm((state) => ({
          ...state, ...userDetails
        }))
        setUserInfo({...userDetails})
      }
      setPassword({ oldPassword: '', newPassword: '', confirmPassword: '' })
      setPasswordError({
        oldPasswordError: null,
        newPasswordError: null,
        confirmPasswordError: null,
      })
      setLoading(false)
    }
    fetchData()
  }, [])

  const handleChange = (name, value) => {
    setForm((state) => ({
      ...state,
      [name]: value ? value : '',
    }))
  }

  const handlePasswordChange = (event) => {
    const name = event.target.name,
      value = event.target.value
    setPassword((state) => ({
      ...state,
      [name]: value,
    }))

    let error = passwordFieldsValidation(name, value)

    setDisabled(error)
  }

  const passwordFieldsValidation = (name, value) => {
    let error = false
    if (name === 'oldPassword') {
      if (value === EMPTY_STRING && password.newPassword !== EMPTY_STRING) {
        setPasswordError((state) => ({
          ...state,
          [name + 'Error']: '*Required!',
        }))
        error = true
      } else if (passwordError[name + 'Error']) {
        setPasswordError((state) => ({
          ...state,
          [name + 'Error']: null,
        }))
      }
      if (password.newPassword === EMPTY_STRING) {
        error = true
      }
    } else if (name === 'newPassword') {
      if (value !== EMPTY_STRING && password.oldPassword === EMPTY_STRING) {
        setPasswordError((state) => ({
          ...state,
          oldPasswordError: '*Required!',
        }))
        error = true
      } else if (
        value !== EMPTY_STRING &&
        password.confirmPassword === EMPTY_STRING
      ) {
        error = true
      } else if (value === EMPTY_STRING) {
        setPasswordError((state) => ({
          ...state,
          [name + 'Error']: null,
          oldPasswordError: null,
        }))
        error = true
      }
    }
    if (
      (name === 'newPassword' && value !== password.confirmPassword) ||
      (name === 'confirmPassword' && value !== password.newPassword)
    ) {
      setPasswordError((state) => ({
        ...state,
        newPasswordError: "*Passwords don't match!",
        confirmPasswordError: "*Passwords don't match!",
      }))
      error = true
    } else if (passwordError.newPasswordError) {
      setPasswordError((state) => ({
        ...state,
        newPasswordError: null,
        confirmPasswordError: null,
      }))
    }
    if (
      name === 'confirmPassword' &&
      value === EMPTY_STRING &&
      password.newPassword === EMPTY_STRING
    )
      error = true

    if (name === 'confirmPassword' || name === 'newPassword')
      error = error || Boolean(passwordError.oldPasswordError)
    else
      error =
        error ||
        Boolean(passwordError.newPasswordError) ||
        Boolean(passwordError.confirmPasswordError)

    return error
  }

  const updateUserProfile = () => {
    setUpdateInProgress(true)
    let data = new FormData()
    data.append('firstName', form.firstName)
    data.append('lastName', form.lastName)
    if (form.mobilePhone) {
      data.append('mobilePhone', form.mobilePhone.replace(/[-\s()]/g,''))
    }

    client
      .post(UPDATE_USER_PROFILE, data)
      .then((res) => {
        if (res.data.status === SUCCESS) {
          const d = res.data.response.user
          localStorage.setItem('userDetails', JSON.stringify(d))
          toast.success(res.data.message)
          setUpdateInProgress(false)
          props.history.push('/workshop')
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message)
        setUpdateInProgress(false)
      })
  }

  const updateUserPassword = () => {
    setUpdateInProgress(true)
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    let data = new FormData()
    if (password.oldPassword !== EMPTY_STRING) {
      data.append('oldPassword', password.oldPassword)
      data.append('password', password.newPassword)
      data.append(
        'access_token',
        userInfo.signInUserSession.accessToken.jwtToken
      )
    }
    client
      .post(UPDATE_USER_PASSWORD, data)
      .then((res) => {
        toast.success(res.data.message + ' Please login.')
        setUpdateInProgress(false)
        props.history.push('/logout')
      })
      .catch((error) => {
        toast.error(error.response.data.message)
        setUpdateInProgress(false)
      })
  }

  const handleDialogClose = () => {
    setShowDialog(false)
  }

  const handleClose = () => {
    if (anchorEl) setAnchorEl(false)
    props.history.push('/workshop')
  }

  const handleLogout = () => {
    setAnchorEl(null)
    setShowDialog(true)
  }

  const confirmLogout = () => {
    props.history.push('/logout')
  }

  const handleUpdatePage = () => {
    if (updateInProgress) return
    setDisabled(!isPasswordVisible)
    setIsPasswordVisible(!isPasswordVisible)
  }

  return (
    <div
      className='landing'
      style={{ backgroundImage: 'url(' + Background + ')' }}
    >
      {loading && <DataPreloader text='Loading' />}
      {showDialog && (
        <Dialog
          open={showDialog}
          onClose={handleDialogClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title' data-testid='dialog-title'>
            Logout ?
          </DialogTitle>
          <DialogContent>
            <div>
              You can improve your security further after logging out by closing
              this opened browser.
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              color='default'
              data-testid='closeDialog'
            >
              Close
            </Button>
            <Button
              onClick={confirmLogout}
              color='primary'
              autoFocus
              data-testid='logoutBtn'
            >
              Logout
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <div className='logo-holder'>
        <img src={mainLogo} alt='decooda' />
      </div>
      <div className='userInfo'>
        <div className='user-info'>
          <span data-testid='user-menu'>
            Welcome{' '}
            {userInfo.firstName ? userInfo.firstName + ' ' + userInfo.lastName : ''}
          </span>
        </div>
        <div>
          <IconButton
            aria-label='user-pic'
            aria-controls='user-menu'
            aria-haspopup='true'
            onClick={(e) => {
              setAnchorEl(e.currentTarget)
            }}
            data-testid='menu-button'
          >
            <i className='fa fa-user' aria-hidden='true'></i>
            <i className='fa fa-caret-down' aria-hidden='true'></i>
          </IconButton>
        </div>
        <Menu
          id='landing-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null)
          }}
          data-testid='landing-menu'
        >
          <MenuItem onClick={handleClose} data-testid='dashboard'>
            Brand Management
          </MenuItem>
          <MenuItem onClick={handleLogout} data-testid='logout-menu'>
            Logout
          </MenuItem>
        </Menu>
      </div>

      <Grid
        container
        spacing={2}
        justify='center'
        style={{ marginTop: '15px' }}
      >
        <Grid item xs={12} sm={5} md={3}>
          <Card elevation={4} className={classes.root}>
            <CardContent
              style={{ paddingBottom: '16px', position: 'relative' }}
            >
              {updateInProgress && <DataPreloader text='Updating' />}

              <div className='okta-user-update'>
                <h4>
                  {isPasswordVisible ? 'Update Password' : 'Update Profile'}
                </h4>

                {!isPasswordVisible ? (
                  <div>
                    <TextField
                      id='firstName'
                      name='firstName'
                      label='First Name'
                      variant='outlined'
                      margin='normal'
                      inputProps={{ 'data-testid': 'firstName' }}
                      fullWidth
                      value={form.firstName || ''}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                    />
                    <TextField
                      id='lastName'
                      name='lastName'
                      label='Last Name'
                      variant='outlined'
                      margin='normal'
                      inputProps={{ 'data-testid': 'lastName' }}
                      fullWidth
                      value={form.lastName || ''}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                    />
                    <MuiPhoneNumber
                      id='phone'
                      defaultCountry={'us'}
                      value={form.mobilePhone}
                      variant='outlined'
                      label='Phone'
                      margin='normal'
                      onChange={(val) => handleChange('mobilePhone', val)}
                      disableAreaCodes
                      countryCodeEditable={false}
                    />
                  </div>
                ) : (
                  <div>
                    <TextField
                      autoComplete='off'
                      id='oldPassword'
                      name='oldPassword'
                      label='Current Password'
                      variant='outlined'
                      margin='normal'
                      type='password'
                      inputProps={{ 'data-testid': 'currentPassword' }}
                      fullWidth
                      value={password.oldPassword || ''}
                      onChange={handlePasswordChange}
                      error={Boolean(passwordError.oldPasswordError)}
                      helperText={passwordError.oldPasswordError}
                      required={Boolean(passwordError.oldPasswordError)}
                    />
                    <TextField
                      autoComplete='off'
                      id='newPassword'
                      name='newPassword'
                      label='New Password'
                      variant='outlined'
                      margin='normal'
                      type='password'
                      inputProps={{ 'data-testid': 'newPassword' }}
                      fullWidth
                      value={password.newPassword || ''}
                      onChange={handlePasswordChange}
                      error={Boolean(passwordError.newPasswordError)}
                      helperText={passwordError.newPasswordError}
                      required={Boolean(passwordError.newPasswordError)}
                    />
                    <TextField
                      autoComplete='off'
                      id='confirmPassword'
                      name='confirmPassword'
                      label='Confirm Password'
                      variant='outlined'
                      margin='normal'
                      type='password'
                      inputProps={{ 'data-testid': 'confirmPassword' }}
                      fullWidth
                      value={password.confirmPassword || ''}
                      onChange={handlePasswordChange}
                      error={Boolean(passwordError.confirmPasswordError)}
                      helperText={passwordError.confirmPasswordError}
                      required={Boolean(passwordError.confirmPasswordError)}
                    />
                  </div>
                )}
                <div className='okta-user-update-footer'>
                  <Button onClick={handleClose} disabled={updateInProgress}>
                    Cancel
                  </Button>
                  <Button
                    onClick={
                      isPasswordVisible ? updateUserPassword : updateUserProfile
                    }
                    color='primary'
                    disabled={disabled || updateInProgress}
                    data-testid='submitForm'
                  >
                    Save
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>

          <div className='toogle-password' onClick={handleUpdatePage}>
            {!isPasswordVisible ? 'Update Password' : 'Update Profile'}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default UpdateProfile

import React, { useState, useEffect } from 'react'
import { ResponsiveScatterPlot } from '@nivo/scatterplot'
import OutlierTable from '../OutlierTable/OutlierTable'
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch'
import styles from './scatterPlot.module.css'
import minBy from 'lodash.minby'
import maxBy from 'lodash.maxby'
import { POSITIVE, GREEN, RED } from '../../constants'
import Skeleton from '@material-ui/lab/Skeleton'
const theme = {
  axis: {
    domain: {
      line: {
        stroke: '#ddd',
        strokeWidth: 2
      }
    }
  }
}

const MyResponsiveScatterPlot = ({impactAnalysisData, toggleSentiment }) => {
  const [chartData, setChartData] = useState({ id: 'Chart', data: [] })
  const [outlierData, setOutlierData] = useState([])
  const [sentiment, toggleSwitch] = useState(POSITIVE)
  const [loading, setLoading] = useState(false)

  useEffect( () => {
       const updatedData = sentiment == POSITIVE ? impactAnalysisData['positive'] : impactAnalysisData['negative']
       setChartData({id: 'Chart', data: updatedData})
       setOutlierData([])
  }, [sentiment, impactAnalysisData])


  const onClickHandler = (node, event) => {
    event.persist()
    const data = {
      x: node.data.x,
      y: node.data.y,
      topic: node.data.label,
      frequency: node.data.x_cat,
      impact: node.data.y_cat
    }
    if (event.ctrlKey || event.metaKey) {
      const updatedOutliersData = outlierData.slice().concat(data)
      setOutlierData(updatedOutliersData)
      setChartData(state => ({
        ...state,
        data: chartData.data.filter(item => item.label !== node.data.label)
      }))
    }
  }

  const getSentimentColor = () => {
    if (sentiment === POSITIVE) return GREEN
    else return RED
  }

  const restoreOutlierData = restoreItem => {
    const updatedData = {
      x: restoreItem.x,
      y: restoreItem.y,
      x_cat: restoreItem.frequency,
      y_cat: restoreItem.impact,
      label: restoreItem.topic
    }
    setOutlierData(outlierData.filter(item => item.topic !== restoreItem.topic))
    setChartData(state => ({
      ...state,
      data: state.data.slice().concat(updatedData)
    }))
  }

  const commonProps = {
    theme: theme,
    data: [chartData],
    onClick: onClickHandler,
    colors: getSentimentColor,
    fillColor: '#f50',
    nodeSize: 10,
    blendMode: 'multiply',
    xScale: { type: 'linear', min: 'auto', max: 'auto' },
    yScale: { type: 'linear', min: 'auto', max: 'auto' },
    gridXValues: [0],
    gridYValues: [0],
    axisBottom: {
      format: () => '',
      tickSize: 0,
      tickPadding: 0,
      tickRotation: 0
    },
    margin: {
      top: 10,
      left: 10,
      right: 10,
      bottom: 10
    },
    axisLeft: {
      format: () => '',
      tickSize: 0,
      tickPadding: 0,
      tickRotation: 0
    },
    enableGridX: false,
    enableGridY: false
  }
  const minX = minBy(chartData.data, 'x')
  const maxX = maxBy(chartData.data, 'x')
  const minY = minBy(chartData.data, 'y')
  const maxY = maxBy(chartData.data, 'y')
  return (
    <>
      <div className={styles.heading}>
            Impact Analysis
      </div>
     { 
     !loading
     ?<>
      <div style={{marginTop:12}}>
      <ToggleSwitch
        sentiment={sentiment}
        toggleSwitch={toggleSwitch}
        toggleSentiment={toggleSentiment}
      />
      </div>
      <div className={styles.container}>
        <div className={styles.chartContainer}>
          <div className={styles.yAxis}>
            <div className={styles.axisTicks}>{maxY && maxY.y_cat}</div>
            <div>Impact</div>
            <div className={styles.axisTicks}>{minY && minY.y_cat}</div>
          </div>
          <div className={styles.scatterPlot}>
            {chartData.data && chartData.data.length ? (
              <ResponsiveScatterPlot
                {...commonProps}
                animate={false}
                tooltip={({ node }) => (
                  <div
                    style={{
                      color: node.style.color,
                      backgroundColor: "#FFF",
                      border: 'none',
                      padding: '12px 16px'
                    }}
                  >
                    <strong>Topic:</strong>
                    <br />
                    {`${node.data.label || 'Sample topic'}`}
                  </div>
                )}
              />
            ) : (
              <em>Insufficient Data</em>
            )}
          </div>
        </div>
        <div className={styles.xAxis}>
          <div className={styles.axisTicks}>{minX && minX.x_cat}</div>
          <div>Frequency</div>
          <div className={styles.axisTicks}>{maxX && maxX.x_cat}</div>
        </div>
        <OutlierTable
        data={outlierData}
        restoreOutlier={restoreItem => restoreOutlierData(restoreItem)}
      />
      </div>
      </>
      :<div style={{marginTop:12}}><Skeleton animation='wave' variant='rect' height = {300} /></div>
      }
    </>
  )
}

export default MyResponsiveScatterPlot

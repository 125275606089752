import React from 'react';
import Fullscreen from "react-full-screen";
import ReactHighcharts from 'react-highcharts';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';

// Highcharts exporting
var HighchartsExporting = require('highcharts-exporting');
HighchartsExporting(ReactHighcharts.Highcharts);

class PieChart extends React.Component {

  constructor(props) {
    super();

    this.state = {
      isFull: false,
    };
  }

  goFull = () => {
    this.setState({ isFull: !this.state.isFull });
  }

  render() {
    const { chartTitle, chartData } = this.props;
    let arr = [];
    const singleClick = (event) => {
      if (arr.length === 0) {
        setTimeout(() => {
          if (arr.length === 2)
            this.props.handleDoubleClick(event.point.name);
          else
            this.props.handleSingleClick(event.point.name);

          arr = [];

        }, 300);
      }
      arr.push(event.point.name);
    }

    const config = {
      series: [{
        data: chartData,
      }],
      credits: {
        enabled: false
      },
      legend: {
        itemWidth: 100,
        enable: true,
        events: true
      },
      chart: {
        type: 'pie',
        events: {},
        style: {
          fontFamily: "Poppins",
          color: 'rgba(0, 0, 0, 0.87)',
          textTransform: 'capitalize'
        },
        animation: false,
        reflow: false,
      },
      title: {
        text: ''
      },

      tooltip: {
        pointFormat: '<b>{point.percentage:.5f}%</b>',
        shadow: true,
        positioner: function () {
          return { x: 20, y: 20 };
        }
      },

      plotOptions: {
        pie: {
          cursor: 'pointer',
          showInLegend: true,
          dataLabels: {
            enabled: false,
          },
          innerSize: '50%',
          startAngle: 0,
          endAngle: 360,
          center: ['50%', '50%']
        },
        series: {
          point: {
            events: {
              click: function (event) { singleClick(event); },
              legendItemClick: function () { return false; }
            }
          }
        }
      },
    };

    return <React.Fragment>
      <ErrorBoundary>
      <Fullscreen
        enabled={this.state.isFull}
        onChange={isFull => this.setState({ isFull })}
      >
        <div className="chartHolder">
          <div className="chartTitle">
            <div className="chartLabel">{chartTitle}</div>
            <div className="chartTitleActions">
              <Tooltip title="Full Screen" placement="left"><span>
                <IconButton
                  aria-label="Full Screen"
                  onClick={this.goFull}
                  disabled={chartData.length === 0}
                  data-testid="fullscreen"
                > <i className="fa fa-expand"></i></IconButton>
              </span></Tooltip>
            </div>
          </div>
          <div className="chartContent">
            {chartData.length === 0
              ? <p className="noData">NO DATA</p>
              : <ReactHighcharts ref="chart" config={config} />
            }
          </div>
        </div>
      </Fullscreen>
      </ErrorBoundary>
    </React.Fragment>
  }
}

PieChart.propTypes = {
  chartData: PropTypes.array.isRequired
}

export default (PieChart);
import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1890FF',
  },
}))(LinearProgress);

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginBottom: '5px'
  },
  title: {
    display: 'inline-block',
    fontSize: '14px',
    opacity: '.8',
    marginBottom: '4px',
    whiteSpace: 'nowrap'
  }
});

const ProgressBar = ({ numerator, denominator, title }) => {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <span>{title + ':'}</span>&nbsp;&nbsp;
        <span>{numerator || 0}/{denominator || 0}</span>
      </div>
      <BorderLinearProgress variant="determinate" value={numerator/denominator * 100} />
    </div>
  )
}

export default ProgressBar
import { SET_TOPIC_LIST } from '../actions/types'
import get from 'lodash.get'

let initialState = []

export default function topicList(state = initialState, action) {
  const data = get(action, 'data')

  switch (action.type) {
    case SET_TOPIC_LIST: {
      return data
    }
    default: {
      return state
    }
  }
}
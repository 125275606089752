import React, { useRef, useState } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ArrowRight from '@material-ui/icons/ArrowRight'

const NestedMenuItem = ({
  label,
  menuOpen,
  children,
  selected,
  ...props
}) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)

  const menuItemRef = useRef()
  const containerRef = useRef()
  const subMenuContainerRef = useRef()

  const handleMouseEnter = () => {
    setIsSubMenuOpen(true)
  }

  const handleMouseLeave = () => {
    setIsSubMenuOpen(false)
  }

  const handleFocus = (event) => {
    if (event.target === containerRef.current) {
      setIsSubMenuOpen(true)
    }
  }

  const open = isSubMenuOpen && menuOpen

  return (
    <div
      ref={containerRef}
      onFocus={handleFocus}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={props.onClick}
    >
      <MenuItem
        ref={menuItemRef}
      >
        {label}
        <ArrowRight style={{marginLeft: 'auto'}} />
      </MenuItem>
      <Menu
        id="topical-phrases-options"
        style={{pointerEvents: 'none'}}
        anchorEl={menuItemRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={open}
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
        onClose={() => {
          setIsSubMenuOpen(false)
        }}
      >
        <div ref={subMenuContainerRef} style={{pointerEvents: 'auto'}}>
          {children}
        </div>
      </Menu>
    </div>
  )
}

export default React.forwardRef((props, ref) => {
  return <NestedMenuItem innerRef={ref} {...props} />;
});
import React, { Component } from 'react';
import client from '../../../_helpers/client';
import {getKibanaUrl, handleCsvFileDownload} from '../../../_helpers/utils';
import { toast } from 'react-toastify';
import { Paper,Button,IconButton, Zoom, Tooltip, Dialog, CircularProgress } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { WARN, SUCCESS, DATA_SOURCE_UPLOD, DATA_SOURCE_WUFOO, DATA_SOURCE_API_DATA, DATA_SOURCE_TYPE_FORM } from '../../../Constants';
import {
    DELETE_DATASOURCE,
    GET_CSV_DETAILS,
    EXPORT_DATA_SOURCE,
    ADD_DS_IN_CATALOGUE,
    IS_BRAND_HAVE_LABEL
} from '../../../Routes';
import {ExitToApp, Redo} from '@material-ui/icons';
import ExportWarning from "../../DefaultLayout/Header/ExportWarning";

class AvailableDataSources extends Component {
    state = {
        csvDetails: {},
        showDetails: false,
        modalOpen: false,
        dataSourceId: null,
        isSocialMediaDataSource: true,
        rowId: null,
        toggle: {},
        sourceIdToAddInCatalogue: null,
        isOpenForExport:false,
        isAdmin:false,
        currentRow:null
    }

    componentDidMount() {
        this.handleUserDetails()
    }

    handleUserDetails = () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        if (userDetails === null) {
            console.log("IsAdmin:User does not exist ")
            this.setState({isAdmin: false});
        } else {
            console.log("IsAdmin " +userDetails.isAdmin)
            this.setState({isAdmin: userDetails.isAdmin});
        }
    }

    handleModalClose = () => {
        this.setState({
            ...this.state,
            modalOpen: false,
            isOpenForExport:false,
            dataSourceId: null,
        })
    }

    handleModalAccept = () => {
        client.get(DELETE_DATASOURCE, {
                params: {
                    brandId: this.props.brandId,
                    dataSourceId: this.state.dataSourceId,
                    isSocialMedia: this.state.isSocialMediaDataSource
                }
            }).then(res => {
                if (res.data.status === SUCCESS) {
                    this.props.refreshAvailableDataSources();
                    this.setState({ modalOpen: false, })
                    toast.success(res.data.message);
                } else {
                    if (res.data.status === WARN) toast.warn(res.data.message)
                    else toast.error(res.data.message)
                }
            }).catch(error => {
                if (error.response) {
                    if (error.response.data.status === WARN) toast.warn(error.response.data.message)
                    else toast.error(error.response.data.message)
                }
            })
    }

    deleteDataSource = (row, isSocialMediaDataSource) => {
        this.setState({
            ...this.state,
            modalOpen: true,
            dataSourceId: row.id,
            dataSourceName: row.name,
            isSocialMediaDataSource: isSocialMediaDataSource
        })
    }

    closeDetails = () => {
        this.setState({
            ...this.state,
            rowId: null,
        })
    }

    showDetails = (row) => {
        if (!this.state.showDetails) {
            client.get(GET_CSV_DETAILS, { params: { dataSourceId: row.id } })
                .then(res => {
                    if (res.data.status === SUCCESS) {
                        this.setState({
                            ...this.state,
                            csvDetails: res.data.response,
                            rowId: row.id
                        })
                    } else {
                        if (res.data.status === WARN) toast.warn(res.data.message)
                        else toast.error(res.data.message)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.data.status === WARN) toast.warn(error.response.data.message)
                        else toast.error(error.response.data.message)
                    }
                })
        } else {
            this.setState({ ...this.state, rowId: row.id })
        }
    }

    handleRawDataexport=(row,isExportConfirmedFromAdmin) => {
        const currentRow = row ? row: this.state.currentRow
        const nodeName = currentRow.name;
        if(currentRow.isSensitiveData && !isExportConfirmedFromAdmin){
            this.setState({modalOpen:true,isOpenForExport:true, currentRow:row})
            return
        }
        client.get(EXPORT_DATA_SOURCE, { params: { dataSourceId: currentRow.id } })
        .then(res => {
            let responseData = res.data;
            if (responseData.length === 0) {
                toast.warn("Empty CSV can't be exported.")
                return
            }
            handleCsvFileDownload(responseData, nodeName);
            this.setState({modalOpen:false,isOpenForExport:false, currentRow:null})
        })
        .catch(error => {
            toast.error("unable to export.")
            this.setState({modalOpen:false,isOpenForExport:false, currentRow:null})
            console.log(error)
        })
    }

    handleAddInCatalogue = async (sourceId) => {
      try {
        this.setState({
          sourceIdToAddInCatalogue: sourceId
        })
        await client.post(ADD_DS_IN_CATALOGUE, {
          dataSourceId: sourceId
        })
        toast.success('Data source added in catalougue successfully.')
        this.setState({
          sourceIdToAddInCatalogue: null
        })
        this.props.refreshAvailableDataSources()
      } catch (err) {
        console.log(err)
        toast.error('Could not add data source in catalogue.')
        this.setState({
          sourceIdToAddInCatalogue: null
        })
      }
    }

    renderAvailableData = (row) => {
        switch (row.source) {
            case DATA_SOURCE_UPLOD:
                return (
                    <div className='csv_details dsdetails'>
                        <div className="closeBtnRounded" title="Close" aria-label="Close" onClick={this.closeDetails}>
                            <i className="fa fa-close"></i>
                        </div>
                        <div className="csvDiv">
                            <strong>CSV Details</strong>
                            <p><strong>File Uploaded:</strong>{this.state.csvDetails.fileName}</p>
                            <p><strong>Delimiter:</strong>{this.state.csvDetails.delimiter}</p>
                        </div>
                        <div className="csvDiv">
                            <strong>Scoring Fields</strong>
                            <p><strong>Content Column:</strong>{this.state.csvDetails.content}</p>
                            <p><strong>Author Column:</strong>{this.state.csvDetails.author}</p>
                            <p><strong>Date Column:</strong>{this.state.csvDetails.date}</p>
                        </div>
                    </div>
                )
            case DATA_SOURCE_WUFOO:
                return (
                    <div className="wufoo_details dsdetails">
                        <div className="closeBtnRounded" title="Close" aria-label="Close" onClick={this.closeDetails}>
                            <i className="fa fa-close"></i>
                        </div>
                        <p><b>Hashcode:</b>{row.info.Hashcode}</p>
                        <p><b>Sub Domain:</b>{row.info.SubDomain}</p>
                        <p><b>ApiKey:</b>{row.info.ApiKey}</p>
                    </div>)
            case DATA_SOURCE_API_DATA:
                return (
                    <div className="api_details dsdetails">
                        <div className="closeBtnRounded" title="Close" aria-label="Close" onClick={this.closeDetails}>
                            <i className="fa fa-close"></i>
                        </div>
                        <p><b>BrandId:</b>{this.props.brandId}</p>
                    </div>)
            case DATA_SOURCE_TYPE_FORM:
                return (
                    <div className="typeform_details dsdetails">
                        <div className="closeBtnRounded" title="Close" aria-label="Close" onClick={this.closeDetails}>
                            <i className="fa fa-close"></i>
                        </div>
                        <p><b>Form ID:</b>{row.info.FormId}</p>
                        <p><b>ApiKey:</b>{row.info.ApiKey}</p>
                    </div>)
            default:
                return null;
        }
    }

    render() {

        return (
            <React.Fragment>
                {this.state.isOpenForExport ?
                    (
                        <Dialog
                            open={this.state.modalOpen}
                            onClose={this.handleModalClose}
                            aria-labelledby="export-confirm"
                            maxWidth={"md"}
                        >
                            <div className="uiDialog">
                                <div className="uiDialogTitle">
                                    <h6>Export Dataset CSV</h6>
                                </div>
                                <div className="uiDialogContent">
                                    <ExportWarning isAdmin={this.state.isAdmin}/>
                                    <footer>
                                        <div>
                                            {this.state.isAdmin ? (
                                                <div>
                                                    <Button onClick={this.handleModalClose} color="default">Cancel</Button>
                                                    <Button onClick={() => this.handleRawDataexport(null,true)} color="primary" autoFocus>Yes</Button>
                                                </div>
                                            ) : (
                                                <Button onClick={this.handleModalClose} color="primary" autoFocus>Ok</Button>)
                                            }
                                        </div>
                                    </footer>
                                </div>
                            </div>
                        </Dialog>):
                    this.state.modalOpen ?
                    (<Dialog
                        open={this.state.modalOpen}
                        onClose={this.handleModalClose}
                        aria-labelledby="alert-dialog-title"
                    >
                        <div className="uiDialog deleteModel">
                            <div className="uiDialogTitle"><h6>Removing Data Source!</h6></div>
                            <div className="uiDialogContent">
                                <div>Are you sure you want to remove <span className="delete-highlight">
                                {this.state.dataSourceName}
                            </span>data source from brand ?
                                </div>
                            </div>
                            <footer>
                                <div>
                                    <Button onClick={this.handleModalClose} color="default">Cancel</Button>
                                    <Button onClick={this.handleModalAccept} color="primary" autoFocus>Delete</Button>
                                </div>
                            </footer>
                        </div>
                    </Dialog>):null
                }
                <Paper variant='outlined'>
                    <div className="dataSourceTable">
                        <table className="accordianTables">
                            <thead>
                                <tr>
                                    <th style={{ width: 38 }}></th>
                                    <th style={{ width: 38 }}></th>
                                    <th style={{ width: 38 }}></th>
                                    <th style={{ width: 38 }}></th>
                                    <th>Added Data Source</th>
                                    <th>Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.datasource.map((row, i) => {
                                    return (
                                        <tr id={row.id} key={i} className="data-source-row">
                                            <td style={{ textAlign: "center", verticalAlign: "top" }}>
                                                {row.isNew && this.props.brandStatus !== 0
                                                    ? <div className="canDelete">
                                                         <Tooltip title="Remove" TransitionComponent={Zoom}>
                                                    <IconButton size="small" color="inherit" onClick={() => this.deleteDataSource(row, row.isSocialMedia ? true : false)}>
                                                    <i className="fa fa-times" ></i>
                                                </IconButton>
                                                </Tooltip>
                                                
                                                    </div>
                                                    : <div className="cannotDelete">
                                                        <i className="fa fa-check" style={{fontSize:'13px', paddingTop:'4px'}}></i>
                                                        <Tooltip title="Remove" TransitionComponent={Zoom}>
                                                            <IconButton size="small" color="inherit" onClick={() => this.deleteDataSource(row, row.isSocialMedia ? true : false)}>
                                                            <i className="fa fa-times"></i>
                                                        </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                }
                                            </td>
                                            <td style={{ textAlign: "center", verticalAlign: "top" }}>
                                              <div className="preProcessingSettings">
                                                  <Tooltip title="Preprocessing Settings" TransitionComponent={Zoom}>
                                                      <span>
                                                          <IconButton
                                                              color='primary'
                                                              size='small'
                                                              aria-label="preprocessing settings"
                                                              onClick={() => this.props.openPreprocessingModal(row.id, row.isNew)}
                                                          >
                                                              <SettingsIcon />
                                                          </IconButton>
                                                      </span>
                                                  </Tooltip>
                                              </div>
                                            </td>
                                            <td style={{ textAlign: "center", verticalAlign: "top" }}>
                                              { row.source === DATA_SOURCE_UPLOD ?
                                                <div className="exportDataSource">
                                                  <Tooltip title="Export Data Source" TransitionComponent={Zoom}>
                                                    <span>
                                                        <IconButton
                                                            color='primary'
                                                            size='small'
                                                            aria-label="Export Data Source"
                                                            onClick={() => this.handleRawDataexport(row,false)}
                                                        >
                                                            <Redo />
                                                        </IconButton>
                                                    </span>
                                                  </Tooltip>
                                                </div> : null}
                                            </td>
                                            <td style={{ textAlign: "center", verticalAlign: "top" }}>
                                              {!row.isSensitiveData && row.source === DATA_SOURCE_UPLOD && !row.isCatalogue ? (
                                                row.id === this.state.sourceIdToAddInCatalogue ? (
                                                  <CircularProgress color='primary' size={14} style={{marginTop: '4px'}} />
                                                ) : (
                                                  <div className="preProcessingSettings">
                                                    <Tooltip title="Add in Data Catalogue" TransitionComponent={Zoom}>
                                                        <span>
                                                            <IconButton
                                                                color='primary'
                                                                size='small'
                                                                aria-label='add in data catalogue'
                                                                onClick={() => this.handleAddInCatalogue(row.id)}
                                                            >
                                                                <AddCircleIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                  </div>
                                                )
                                              ) : null}
                                            </td>
                                            <td style={{ verticalAlign: "top" }}>
                                                <div>
                                                    {row.source === DATA_SOURCE_UPLOD || row.source === DATA_SOURCE_WUFOO || row.source === DATA_SOURCE_TYPE_FORM || row.source === DATA_SOURCE_API_DATA
                                                        ? <span 
                                                            className="showDetails" 
                                                            onClick={() => this.showDetails(row)}>{row.name}</span>
                                                        : <span>{row.name}</span>
                                                    }
                                                </div>
                                                { /* Show details holder */}
                                                {this.state.rowId === row.id
                                                    ? this.renderAvailableData(row)
                                                    : null
                                                }
                                            </td>
                                            <td style={{ verticalAlign: "top" }}>
                                                <div>{row.source}</div>
                                            </td>
                                        </tr>

                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </Paper>
            </React.Fragment>
        )
    }
}

export default AvailableDataSources;
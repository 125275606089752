import React from 'react'
import Iframe from 'react-iframe'
import { Paper } from '@material-ui/core'
import DataPreloader from '../../containers/Preloader/DataPreloader'
import NoBrandSelectedMessage from '../../containers/NoBrandSelected/NoBrandSelected'
import { getDefaultBrandDetails, getUserDetails } from '../../_helpers/utils'

class KibanaReport extends React.Component {
  state = {
    brandId: null,
    loadingData: true,
    kibanUrl: null,
  }

  kibanaUrl = async () => {
    document.body.classList.remove('sidebar-off-canvas')
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 100)

    const userDetails = await getUserDetails()

    if (userDetails) {
      this.setState({
        kibanUrl: userDetails.kibanaURL,
        loadingData: false,
      })
    } else {
      this.setState({
        loadingData: false,
      })
    }
  }

  async componentDidMount() {
    const brandDetails = await getDefaultBrandDetails()
    if (brandDetails) {
      this.kibanaUrl()
      this.setState({ brandId: brandDetails.id })
    } else this.setState({ loadingData: false })
  }

  render() {
    const { brandId, kibanUrl, loadingData } = this.state
    const iframeHeight = window.innerHeight - 117

    return (
      <>
        {!brandId ? (
          <div>
            <h1>Kibana report</h1>
            <NoBrandSelectedMessage history={this.props.history} />
          </div>
        ) : (
          <>
            {loadingData && <DataPreloader />}
            <div className='frameDiv'>
              {kibanUrl ? (
                <Paper elevation={0}>
                  <Iframe
                    url={kibanUrl}
                    width='100%'
                    height={`${iframeHeight}px`}
                    id='mykibanDashboard'
                    className='myKibana'
                    display='initial'
                    position='relative'
                    sandbox="allow-same-origin allow-scripts"
                  />
                </Paper>
              ) : (
                <></>
              )}
            </div>
          </>
        )}
      </>
    )
  }
}

export default KibanaReport

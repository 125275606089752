import "./TextDetails.css";
import React from "react";
import ReactDOM from "react-dom";
import qs from "qs";

import {
  Paper,
  Grid,
  TextField,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip as MuiTooltip,
  Zoom,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import client from "../../_helpers/client";
import { toast } from "react-toastify";
import Tooltip from "rc-tooltip";

import DataPreloader from "../../containers/Preloader/DataPreloader";
import NoBrandSelectedMessage from "../../containers/NoBrandSelected/NoBrandSelected";
import TextDetailsModelTree from "./TextDetailsModelTree";
import {
  replaceNumberWithCommas,
  getDefaultBrandDetails,
  getFilterQuery,
  getSelectedText,
  decodeEntities,
} from "../../_helpers/utils";

import {
  PLAN_BASIC,
  PLAN_PROFESSIONAL,
  WARN,
  SUCCESS,
  EMPTY_STRING,
  EMPTY_ARRAY,
  CLASSIFIER,
  RENAME,
  DEFAULT,
  FILE,
  EMPTY_OBJECT,
  CALCULATED_CLASSIFIER,
  VIRTUAL_CLASSIFIER,
  REGULAR_CLASSIFIER,
  DELETE,
  NOTE_ADD_TO_SPAM,
} from "../../Constants";
import {
  DRILLDOWN_VIEW,
  DRILLDOWN_DATA,
  DRILLDOWN_INDIVIDUAL_DELETE,
  GET_CLASSIFIERS,
  ADD_CLASSIFIER,
  GET_MODEL_TREE_DATA,
  IS_CLASSIFIER_EXISTS,
  CREATE_CLASSIFIER,
  RENAME_CLASSIFIER,
  DELETE_CLASSIFIER,
  DRILLDOWN_POPULATE_CACHE,
  SM_REPLY_TIMELINE,
  TREEVIEW_FOR_SPAM,
  ADD_POST_IN_SPAM,
} from "../../Routes";

import LazyList from "react-list-lazy-load";
import ReactList from "react-list";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import Image from "./Image";
import { Add } from "@material-ui/icons";

const getHeight = (index) => 30 + 10 * (index % 10);
getHeight.toJSON = () => getHeight.toString();

class TextDetails extends React.Component {
  constructor(props) {
    super(props);

    this.toggleDrilldownContent = this.toggleDrilldownContent.bind(this);
    this.toggleJsonContent = this.toggleJsonContent.bind(this);
    //this.textRef = React.createRef();
    this.handleRequestPage = this.handleRequestPage.bind(this);
  }

  state = {
    brandId: null,
    loadingData: false,
    checkedAll: false,
    showContent: false,
    showJson: false,
    isLoading: false,
    showModal: false,
    cmVisible: false,
    contextPosX: 0,
    contextPosY: 0,

    isContextMenu: true,
    mediaType: -1,
    contentId: null,

    modalEvent: null,
    modalTreeData: EMPTY_ARRAY,
    modalDeleteItem: { id: null, mediaType: null, title: null },
    selectedKeys: ["0"],
    expandedKeys: ["0"],
    switcherIcon: <i className="fa fa-plus-square"></i>,

    offset: 0,
    totalCount: 0,
    currentCount: 0,
    updatedCount: 0,
    pageSize: 100,
    drillDownContent: EMPTY_ARRAY,

    selected: EMPTY_ARRAY,
    showDrilldownContent: EMPTY_ARRAY,
    showJsonContent: EMPTY_ARRAY,
    showdata: this.displayData,

    isAccessibleMyCollections: false,
    isAccessibleFrame: false,
    isViewJson: false,
    optFilter: EMPTY_ARRAY,

    filterInfo: EMPTY_OBJECT,
    selectedText: EMPTY_STRING,
    isDisabled: true,
    hasMore: true,
    scrolling: false,
    creatingNewClassfier: false
  };

  isMount = null;

  //Toggle Drilldown content
  toggleDrilldownContent = (event, id) => {
    event.persist();
    const { showDrilldownContent } = this.state;
    const selectedContentIndex = showDrilldownContent.indexOf(id);
    let newSelected = EMPTY_ARRAY;

    if (selectedContentIndex === -1) {
      newSelected = newSelected.concat(showDrilldownContent, id);
    } else if (selectedContentIndex === 0) {
      newSelected = newSelected.concat(showDrilldownContent.slice(1));
    } else if (selectedContentIndex === showDrilldownContent.length - 1) {
      newSelected = newSelected.concat(showDrilldownContent.slice(0, -1));
    } else if (selectedContentIndex > 0) {
      newSelected = newSelected.concat(
        showDrilldownContent.slice(0, selectedContentIndex),
        showDrilldownContent.slice(selectedContentIndex + 1)
      );
    }
    this.setState({ ...this.state, showDrilldownContent: newSelected });
  };

  //Toggle JSON data
  toggleJsonContent = (event, id) => {
    event.persist();
    event.target.classList.toggle("active");
    const { showJsonContent } = this.state;
    const selectedJsonIndex = showJsonContent.indexOf(id);
    let newSelected = EMPTY_ARRAY;

    if (selectedJsonIndex === -1) {
      newSelected = newSelected.concat(showJsonContent, id);
    } else if (selectedJsonIndex === 0) {
      newSelected = newSelected.concat(showJsonContent.slice(1));
    } else if (selectedJsonIndex === showJsonContent.length - 1) {
      newSelected = newSelected.concat(showJsonContent.slice(0, -1));
    } else if (selectedJsonIndex > 0) {
      newSelected = newSelected.concat(
        showJsonContent.slice(0, selectedJsonIndex),
        showJsonContent.slice(selectedJsonIndex + 1)
      );
    }
    this.setState({ ...this.state, showJsonContent: newSelected });
  };

  isShowContent = (id) => this.state.showDrilldownContent.indexOf(id) !== -1;
  isShowJsonContent = (id) => this.state.showJsonContent.indexOf(id) !== -1;

  //Get Drilldown Contents
  getDrilldownInfo = () => {
    this.setState({ ...this.state, loadingData: true });
    let fromChart = false,
      filterData = EMPTY_STRING,
      pageName = EMPTY_STRING,
      columnOne = EMPTY_STRING,
      chartName = "getSummaryChartData";

    if (this.props.location.search !== EMPTY_STRING) {
      fromChart = true;
      filterData = getFilterQuery(this.props.location.search);
      pageName =
        filterData === "neutral" ||
        filterData === "positive" ||
        filterData === "negative"
          ? "SentimentController"
          : "EmotionsController";
      columnOne =
        filterData === "neutral" ||
        filterData === "positive" ||
        filterData === "negative"
          ? "valence_direction"
          : "primary_emotion";

      let populateDataObj = EMPTY_OBJECT;
      populateDataObj["pageName"] = pageName;
      populateDataObj["chartName"] = chartName;
      populateDataObj["columnOne"] = columnOne;
      populateDataObj["valueOne"] = filterData;

      client
        .post(DRILLDOWN_POPULATE_CACHE, qs.stringify(populateDataObj))
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status === WARN)
              toast.warn(error.response.data.message);
            else toast.error(error.response.data.message);
          }
        });
    }

    client
      .get(DRILLDOWN_VIEW, { params: { fromChart: fromChart } })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          this.populateCriteriaTemplate(res.data.response.count);
          this.setState({
            ...this.state,
            isAccessibleMyCollections: res.data.response.isAccessibleMyCollections,
            isAccessibleFrame: res.data.response.isAccessibleFrame,
            isViewJson: res.data.response.isViewJson,
            optFilter: res.data.response.optFilter,
            totalCount: res.data.response.count,
            updatedCount: res.data.response.count,
          });
        } else {
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error)
        this.setState({...this.state, loadingData: false})
      });
  };

  //Modal Save Events
  handleModalSave = () => {
    const {
      selectedKeys,
      selectedText,
      isContextMenu,
      mediaType,
      contentId,
    } = this.state;
    const requestUrl = isContextMenu ? ADD_CLASSIFIER : ADD_POST_IN_SPAM;

    let data = {};
    data["classifierId"] = parseInt(selectedKeys.toString());
    data["mediaType"] = mediaType;
    data["classifierItem"] = isContextMenu ? selectedText : NOTE_ADD_TO_SPAM;

    if(!isContextMenu)
    data["contentId"] = contentId;

    //Add selected text as phrase in classifier
    client
      .post(requestUrl, data)
      .then((res) => {
        if (res.data.status === SUCCESS) {
          toast.success(res.data.message);
          this.handleModalClose();
        } else {
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };

  //On Modal Close Event
  handleModalClose = () => {
    this.setState({
      ...this.state,
      selected: EMPTY_ARRAY,
      modalTreeData: EMPTY_ARRAY,
      selectedKeys: ["0"],
      isContextMenu: true,
      mediaType: -1,
      contentId: null,
      showModal: false,
    });
  };

  //on confirm Delete event for drilldown list
  handleDeleteList = (e) => {
    const {
      totalCount,
      currentCount,
      drillDownContent,
      modalDeleteItem,
      selected,
      updatedCount,
    } = this.state;
    let updatedContent = drillDownContent
      .slice()
      .filter((e) => e.uid !== modalDeleteItem.id);
    client
      .get(DRILLDOWN_INDIVIDUAL_DELETE, {
        params: {
          id: modalDeleteItem.id,
          mediaType: modalDeleteItem.mediaType,
        },
      })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          const tempUpdatedCount =
            totalCount === updatedCount ? updatedCount - 1 : updatedCount;

          let tempSel = selected.filter((li) => {
            return li !== modalDeleteItem.id;
          });
          this.setState({
            ...this.state,
            drillDownContent: updatedContent,
            totalCount: totalCount - 1,
            currentCount: currentCount - 1,
            updatedCount: tempUpdatedCount,
            selected: tempSel,
            showModal: false,
          });
          toast.success(res.data.message);
        } else {
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };

  //delete button click event
  removeItem = (mediaType, id, deleteTitle) => {
    let delObj = {};
    delObj["id"] = id;
    delObj["mediaType"] = mediaType;
    delObj["title"] = deleteTitle;
    this.setState({
      showModal: true,
      modalDeleteItem: delObj,
      modalEvent: DELETE,
    });
  };
  //reply button click event
  handleReplyItem = (contentId, mediaType) => {
    let mediaTypeId = 0;
    switch (mediaType) {
      case "twitter":
        mediaTypeId = 1;
        break;
      case "facebook":
        mediaTypeId = 2;
        break;
      default:
        mediaTypeId = 0;
    }
    client
      .get(SM_REPLY_TIMELINE, {
        params: { contentId: contentId, socialEngagementTypeId: mediaTypeId },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error)
      });
  };
  //add to spam button click event
  addToSpamItem = (mediaType, contentId) => {
    let mediaTypeId = 0;
    switch (mediaType) {
      case "twitter":
        mediaTypeId = 1;
        break;
      case "facebook":
        mediaTypeId = 2;
        break;
      default:
        mediaTypeId = 0;
    }
    if (mediaTypeId > 0) {
      this.setState({
        ...this.state,
        isContextMenu: false,
        mediaType: mediaTypeId,
        contentId: contentId,
      });
      this.showClassfierExtension();
    } else toast.error("It does not work with this media type.");
  };

  /* Populate Drill Down Content */
  populateCriteriaTemplate = (count) => {
    if (count === 0) {
      this.setState({
        isLoading: true,
        loadingData: false,
        totalCount: count,
        updatedCount: count,
        drillDownContent: EMPTY_ARRAY,
      });
      return;
    }
    client
      .get(DRILLDOWN_DATA, { params: { offset: 0, limit: 100 } })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          this.setState({
            ...this.state,
            drillDownContent: res.data.response,
            currentCount: res.data.response.length,
            offset: res.data.response.length,
            isLoading: true,
            loadingData: false,
          });
        } else {
          this.setState({
            ...this.state,
            isLoading: true,
            loadingData: false,
          });
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error)
        this.setState({ ...this.state, isLoading: true, loadingData: false });
      });
  };

  //Open Classifier Extension Tree
  handleSelectedText = (e) => {
    const st = window.getSelection().toString();
    const str = e.target.innerText;
    if (st.length === 0) {
      let fullText = getSelectedText(str).replace(/<[^>]+>/gi, "");
      this.setState({ selectedText: decodeEntities(fullText) });
    } else {
      this.setState({ selectedText: st });
    }
  };

  //Get Classifier Tree Data
  showClassfierExtension = () => {
    this.setState({ loadingData: true });
    const { isContextMenu } = this.state;
    const url = isContextMenu ? GET_MODEL_TREE_DATA : TREEVIEW_FOR_SPAM;

    let data = {};
    data["parentId"] = "#";
    if (!isContextMenu) data["socialEngagementTypeId"] = this.state.mediaType;

    client
      .get(url, { params: data })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          this.setState({
            ...this.state,
            modalTreeData: res.data.response,
            modalEvent: CLASSIFIER,
            showModal: true,
            isDisabled: true,
            loadingData: false,
          });
        } else {
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error)
        this.setState({ ...this.state, loadingData: false })
      });
  };

  onNodeSelect = (e, info) => {
    if (info.node.props.isLeaf) {
      this.setState({
        ...this.state,
        selectedKeys: e,
        isDisabled: false,
      });
    } else {
      this.setState({
        ...this.state,
        selectedKeys: e,
        isDisabled: true,
      });
    }
  };

  onNodeExpand = (keys) => {
    this.setState({
      ...this.state,
      expandedKeys: keys,
    });
  };

  //Lazy Load Build
  getNewTreeData = (treeData, curKey, child) => {
    const loop = (data) => {
      data.forEach((item) => {
        if (curKey === item.key) {
          item.children = child;
        } else {
          if (item.children) {
            loop(item.children);
          }
        }
      });
    };
    loop(treeData);
  };

  //On tree node expand
  onLoadData = (treeNode) => {
    return new Promise((resolve) => {
      const data = [...this.state.modalTreeData];

      client
        .get(GET_CLASSIFIERS, { params: { parentId: treeNode.props.eventKey, sortColumn: 'name', sortOrder: 'asc' } })
        .then((res) => {
          if (res.data.status === SUCCESS) {
            this.getNewTreeData(
              data,
              treeNode.props.eventKey,
              res.data.response
            );
            this.setState({ ...this.state, modalTreeData: data });
          } else {
            if (res.data.status === WARN) toast.warn(res.data.message);
            else toast.error(res.data.message);
          }
          resolve();
        })
        .catch((error) => {
          console.log(error)
        });
    });
  };

  //Modal tree ContextMenu events
  handleEvents = (text, node, event) => {
    const { modalEvent } = this.state;
    const parentId = node.eventKey;
    const classiferType =
      node["data-root"] === -3
        ? CALCULATED_CLASSIFIER
        : node["data-root"] === -2
        ? VIRTUAL_CLASSIFIER
        : REGULAR_CLASSIFIER;

    //Create or Rename Context Menu Events
    if (event === DEFAULT || event === FILE || event === RENAME) {
      this.setState((state) => ({
        ...state,
        creatingNewClassfier: true
      }));

      client
        .get(IS_CLASSIFIER_EXISTS, {
          params: { parentId: parentId, name: text, nodeType: event },
        })
        .then((res) => {
          if (res.data.response) {
            toast.warn(res.data.message);
            this.setState((state) => ({
              ...state,
              creatingNewClassfier: false
            }));
          } else {
            // Create New Node
            if (event === DEFAULT || event === FILE) {
              let data = {};
              data["parentId"] = parentId;
              data["name"] = text;
              data["type"] = event;
              data["languageId"] = 1;

              if (modalEvent === CLASSIFIER)
                data["classifierType"] = classiferType;

              client
                .post(CREATE_CLASSIFIER, data)
                .then((result) => {
                  if (result.data.status === SUCCESS) {
                    this.setState({
                      ...this.state,
                      modalTreeData: EMPTY_ARRAY,
                      modalEvent: null,
                      showModal: false,
                      loadingData: false,
                      creatingNewClassfier: false
                    });
                    this.showClassfierExtension();
                  } else {
                    if (res.data.status === WARN) toast.warn(res.data.message);
                    else toast.error(res.data.message);
                    this.setState((state) => ({
                      ...state,
                      creatingNewClassfier: false
                    }));
                  }
                  
                })
                .catch((error) => {
                  console.log(error)
                  this.setState((state) => ({
                    ...state,
                    creatingNewClassfier: false
                  }));
                });
            } else {
              // Rename Node
              client
                .post(RENAME_CLASSIFIER, { id: parentId, name: text })
                .then((result) => {
                  if (result.data.status === SUCCESS) {
                    this.setState({
                      ...this.state,
                      modalTreeData: EMPTY_ARRAY,
                      modalEvent: null,
                      showModal: false,
                      loadingData: true,
                      creatingNewClassfier: false
                    });
                    this.showClassfierExtension();
                  } else {
                    if (res.data.status === WARN) toast.warn(res.data.message);
                    else toast.error(res.data.message);
                    this.setState((state) => ({
                      ...state,
                      creatingNewClassfier: false
                    }));
                  }
                })
                .catch((error) => {
                  console.log(error)
                  this.setState((state) => ({
                    ...state,
                    creatingNewClassfier: false
                  }));
                });
            }
          }
        })
        .catch((error) => {
          console.log(error)
          this.setState((state) => ({
            ...state,
            creatingNewClassfier: false
          }));
        });
    } else {
      client
        .post(DELETE_CLASSIFIER, { id: [parentId], isAutoRegex: node["data-root"] === -4 ? true : false })
        .then((res) => {
          if (res.data.status === SUCCESS) {
            this.setState({
              ...this.state,
              modalTreeData: EMPTY_ARRAY,
              modalEvent: null,
              showModal: false,
              loadingData: true,
            });
            toast.success(res.data.message);
            this.showClassfierExtension();
          } else {
            if (res.data.status === WARN) toast.warn(res.data.message);
            else toast.error(res.data.message);
          }
        })
        .catch((error) => {
                  console.log(error)
        });
    }
  };

  updateClassifierLabel = (e) => {
    this.setState({
      ...this.state,
      selectedText: e.target.value,
    });
  };

  //onscroll drilldown data load
  handleRequestPage = (page, cb) => {
    const {
      offset,
      drillDownContent,
      currentCount,
      updatedCount,
      totalCount,
    } = this.state;

    if (drillDownContent.length >= totalCount) return;

    client
      .get(DRILLDOWN_DATA, { params: { offset: offset, limit: 100 } })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          if (res.data.response.length === 0) {
            cb();
            return;
          }
          let updatedDrillContent = drillDownContent.concat(res.data.response);
          this.setState({
            drillDownContent: updatedDrillContent,
            currentCount: currentCount + res.data.response.length,
            offset: offset + res.data.response.length,
          });
          cb();
        } else {
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
          cb();
        }
      })
      .catch((error) => {
                  console.log(error)
        cb();
      });
  };

  getContainer() {
    if (!this.cmContainer) {
      this.cmContainer = document.createElement("div");
      document.body.appendChild(this.cmContainer);
    }
    return this.cmContainer;
  }

  //Conten Menu for List
  renderContextMenu = (e) => {
    e.preventDefault();

    this.setState({
      cmVisible: !this.state.cmVisible,
      contextPosX: e.pageX,
      contextPosY: e.pageY,
    });

    setTimeout(() => {
      if (this.toolTip) {
        if (this.cmContainer != null) {
          ReactDOM.unmountComponentAtNode(this.cmContainer);
          this.toolTip = null;
        }
      }

      this.toolTip = (
        <Tooltip
          trigger="click"
          placement="bottomRight"
          prefixCls="rc-tree-contextmenu"
          defaultVisible
          overlay={(
            <ul className="contextmenu-ul">
              <li onClick={this.showClassfierExtension}>
                Classifier Extension
              </li>
            </ul>)
          }
        >
          <span />
        </Tooltip>
      );

      const container = this.getContainer();
      Object.assign(this.cmContainer.style, {
        position: "absolute",
        left: `${this.state.contextPosX + 30}px`,
        top: `${this.state.contextPosY - 0}px`,
      });

      ReactDOM.render(this.toolTip, container);
    }, 200);
  };

  async UNSAFE_componentWillReceiveProps() {
    const brandDetails = await getDefaultBrandDetails();
    if (brandDetails) {
      this.setState({ brandId: brandDetails.id });
      if (brandDetails.id === null || brandDetails.id === 0) return;
      this.setState({ currentCount: 0, updatedCount: 0 });
      setTimeout(() => {
        this.getDrilldownInfo();
      }, 400);
    }
  }

  async componentDidMount() {
    this.getContainer();
    const brandDetails = await getDefaultBrandDetails();
    if (brandDetails) {
      this.setState({ brandId: brandDetails.id });
      if (brandDetails.id === null || brandDetails.id === 0) return;
      this.getDrilldownInfo();
    }
  }

  componentWillUnmount() {
    if (this.cmContainer) {
      ReactDOM.unmountComponentAtNode(this.cmContainer);
      document.body.removeChild(this.cmContainer);
      this.cmContainer = null;
      this.setState({ cmVisible: !this.state.cmVisible });
    }
  }

  render() {
    const {
      brandId,
      drillDownContent, totalCount, modalEvent,
      modalTreeData,
      selectedKeys,
      expandedKeys,
      isDisabled,
      selectedText,
      scrolling,
      currentCount,
      updatedCount,
      pageSize,
    } = this.state;

    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const isSpyUser = false;
    const isAdmin = userDetails === null ? false : userDetails.isAdmin;
    const userPlan = userDetails === null ? PLAN_BASIC : userDetails.planId;

    const renderSingleItem = (index, key) => {
      const { drillDownContent } = this.state;
      const ele = drillDownContent[index];
      const isShowContent = this.isShowContent(ele.uid);
      const isShowJsonContent = this.isShowJsonContent(ele.uid);

      return (
        <Paper elevation={1} key={key}>
          <div className={ele.sentiment + " mainDrillBox"}>
            <ul>
              <li className="drillDownContentDiv">
                <div data-id={ele.uid} className="contDiv">
                  <Image icon={ele.media} />
                  <div className="contentHolder">
                    <h4 className="headingAauthor">
                      <span className="author">{ele.authorName}</span>
                      <span className="postDateDrilldown">{ele.date}</span>
                    </h4>
                    <p
                      onClick={(e) => {
                        this.toggleDrilldownContent(e, ele.uid);
                      }}
                    >
                      {ele.title}
                    </p>
                    {isShowContent && (
                      <div className="drilldown-content">
                        {(parseInt(userPlan) === PLAN_BASIC || parseInt(userPlan) === PLAN_PROFESSIONAL) ? (
                          <div>{ele.description}</div>
                        ) : (
                          <div
                            id={ele.contentId}
                            onMouseUp={this.handleSelectedText}
                            onKeyUp={this.handleSelectedText}
                            onContextMenu={this.renderContextMenu}
                          >
                            {ele.description}
                          </div>
                        )}
                      </div>
                    )}

                    {/* Sentiment/Emotion Tags */}
                    <div id="frame-tag">
                      <div id={ele.sentiment} className="frame_tag label">
                        Sentiment: {ele.sentiment}
                      </div>
                      {ele.emotion.map((e, i) => {
                        return (
                          <div id={e} key={i} className="frame_tag label">
                            Emotions: {e}
                          </div>
                        );
                      })}
                      <div id={ele.confidence} className="frame_tag label">
                        Confidence: {ele.confidence}
                      </div>
                      {ele.personas.map((e, i) => {
                        return (
                          <div id={e} key={i} className="frame_tag label">
                            Persona: {e}
                          </div>
                        );
                      })}
                      {ele.performance.map((e, i) => {
                        return (
                          <div id={e} key={i} className="frame_tag label">
                            Performance: {e}
                          </div>
                        );
                      })}
                      {ele.purchasePath.map((e, i) => {
                        return (
                          <div id={e} key={i} className="frame_tag label">
                            PurchasePath: {e}
                          </div>
                        );
                      })}
                      {ele.topics.map((e, i) => {
                        return (
                          <div id={e} key={i} className="frame_tag label">
                            Topic: {e}
                          </div>
                        );
                      })}
                      {ele.filters.map((e, i) => {
                        return (
                          <div id={e} key={i} className="frame_tag label">
                            Filter: {e}
                          </div>
                        );
                      })}
                    </div>

                    {(isAdmin || isSpyUser) && this.state.isViewJson && (
                      <span
                        onClick={(e) => {
                          this.toggleJsonContent(e, ele.uid);
                        }}
                      >
                        View Raw Json
                      </span>
                    )}
                    {isShowJsonContent && (
                      <div className="drilldown-content-json">
                        <pre>
                          {JSON.stringify(
                            JSON.parse(ele.content_json),
                            null,
                            " "
                          )}
                        </pre>
                      </div>
                    )}
                  </div>
                </div>
              </li>
              <li className="drillDownContentOptions text-right">
                {ele.media === "facebook" || ele.media === "twitter" ? (
                  <span>
                    <MuiTooltip title="Add to spam" TransitionComponent={Zoom}>
                      <IconButton
                        onClick={() => this.addToSpamItem(ele.media, ele.uid)}
                        aria-label="Add to spam"
                        size="small"
                        className="addToSpamFab"
                      >
                        <Add />
                      </IconButton>
                    </MuiTooltip>
                  </span>
                ) : null}

                <MuiTooltip title="Delete" TransitionComponent={Zoom}>
                  <IconButton
                    onClick={() =>
                      this.removeItem(ele.media, ele.uid, ele.title)
                    }
                    aria-label="Delete"
                    className="deleteFab"
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </MuiTooltip>
              </li>
            </ul>
          </div>
        </Paper>
      );
    };

    //console.log(drillDownContent)
    return (
      <React.Fragment>
        {this.state.loadingData ? <DataPreloader /> : null}
        <Dialog
          open={this.state.showModal}
          onClose={this.handleModalClose}
          aria-describedby="alert-delete-list"
          fullWidth={true}
          maxWidth={"sm"}
        >
          <DialogTitle>
            {modalEvent === DELETE ? DELETE : "Classifier Extension"}
          </DialogTitle>
          <DialogContent className="deleteContentList">
            {modalEvent === DELETE ? (
              <DialogContentText id="alert-delete-list">
                Are you sure you want to delete{" "}
                <span className="highlightElem">
                  {this.state.modalDeleteItem.title !== null
                    ? this.state.modalDeleteItem.title.trim().slice(0, 61) +
                      "..."
                    : null}{" "}
                  ?
                </span>
              </DialogContentText>
            ) : (
              /* Render Tree  */
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className="jstreeFrameContextmenuHead">
                    <TextDetailsModelTree
                      treeData={modalTreeData}
                      treeType={CLASSIFIER}
                      selectedKeys={selectedKeys}
                      onSelect={this.onNodeSelect}
                      expandedKeys={expandedKeys}
                      onExpand={this.onNodeExpand}
                      loadData={this.onLoadData}
                      handleEvents={this.handleEvents}
                      creatingNewClassfier={this.state.creatingNewClassfier}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  {this.state.isContextMenu ? (
                    <div>
                      <TextField
                        id="classifier-extension"
                        label="Selected Text"
                        value={selectedText}
                        onChange={this.updateClassifierLabel}
                        margin="normal"
                        fullWidth
                      />
                      <small>
                        <b>Note: </b>
                        <br />
                        Add text into the selected Classifier.
                      </small>
                    </div>
                  ) : (
                    <div>
                      <small>
                        <b>Note: </b>
                        <br />
                        It will add author link of post in selected classifier.
                      </small>
                    </div>
                  )}
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleModalClose}>Cancel</Button>
            {modalEvent === DELETE ? (
              <Button onClick={this.handleDeleteList} color="primary" autoFocus>
                Delete
              </Button>
            ) : (
              <Button
                disabled={isDisabled}
                onClick={this.handleModalSave}
                color="primary"
                autoFocus
              >
                Add
              </Button>
            )}
          </DialogActions>
        </Dialog>
        <ErrorBoundary>
          <div className="drillDownScrollInfo">
            <h4>
              Text Details
            </h4>
            <h3>
              Total: <span>{replaceNumberWithCommas(totalCount)}</span>
            </h3>
            <h3>
              Current:{" "}
              <span>
                {currentCount}/{updatedCount}
              </span>
            </h3>
          </div>

          <div
            id="textdetail-content"
            className={scrolling ? "noScroll headerActive" : "noScroll"}
          >
            <h1>
              Text Details
            </h1>
            {brandId === null || brandId === 0 ? (
              <NoBrandSelectedMessage history={this.props.history} />
            ) : (
              <div className="brandContent">
                <div id="drillDownHeader" className="drillDownHeader">
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12}>
                      <ul>
                        <li className="drill-down-header">
                          <h4>
                            Total:{" "}
                            <span>{replaceNumberWithCommas(totalCount)}</span>
                          </h4>
                          <h4>
                            Current:{" "}
                            <span>
                              <b>
                                {currentCount}/{updatedCount}
                              </b>
                            </span>
                          </h4>
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </div>

                {/* Drilldown List */}
                <div 
                    id="drilldownContent" 
                    className="drilldownContent" 
                    style={{height: drillDownContent.length === 0 ? '100px' : '638px'}}>
                  <LazyList
                    pageSize={pageSize}
                    items={drillDownContent}
                    length={totalCount}
                    onRequestPage={this.handleRequestPage}
                  >
                    <ReactList
                      type="variable"
                      length={totalCount}
                      itemRenderer={(index, key) =>
                        drillDownContent[index] != null ? (
                          renderSingleItem(index, key)
                        ) : (
                          <Paper elevation={1} key={key}>
                            <div className="mainDrillBox">Loading…</div>
                          </Paper>
                        )
                      }
                    />
                  </LazyList>
                </div>
              </div>
            )}
          </div>
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

export default TextDetails;

import { LOADING_ATD } from '../actions/types'
import get from 'lodash.get'

let initialState = true

export default function loadAtd(state = initialState, action) {
  const value = get(action, 'value')

  switch (action.type) {
    case LOADING_ATD: {
      return value
    }
    default: {
      return state
    }
  }
}
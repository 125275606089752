import "font-awesome/css/font-awesome.min.css";
import "./App.scss";
import React, {Component} from "react";
import {Provider} from "react-redux";
import configureStore from "../config/configureStore";
import {ToastContainer} from "react-toastify";
import {MuiThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import UpdateProfile from "../views/UpdateProfile/UpdateProfile";
import Login from "../views/Login/Login";
import Logout from "../views/Logout/Logout";
import Layout from "../views/DefaultLayout/Layout";
import ErrorRoute from "../views/ErrorRoute/ErrorRoute";
import Summary from "../views/Summary/Summary";
import TextDetails from "../views/TextDetails/TextDetailsLazy";
import DecoodaApigee from "../views/Apigee/Apigee";
import Workshop from "../views/Workshop/Workshop";
import UserManagement from "../views/DefaultLayout/Header/UserDetails/UserManagement";
import UserEdit from "../views/DefaultLayout/Header/UserDetails/UserEdit";
import PricingView from "../views/DefaultLayout/Header/UserDetails/PricingView";
import ManageSocialMedia from "../views/DefaultLayout/Header/UserDetails/ManageSocialMedia";
import BrandComparison from "../views/BrandComparison/BrandComparison";
import KibanaReport from '../views/KibanaReport/KibanaReport';
import ProtectedRoute from "../_authenticatedRoutes/ProtectedRoute";
import ATD from "../views/ATD/atd";
import LegacyAPIToken from "../views/LegacyAPIToken/LegacyAPIToken";
import {Hub} from "aws-amplify";
import {clearAppLocalStorage, clearCognitoLocalStorageItems} from '../_helpers/utils';
import ImpactAnalysis from '../views/ImpactAnalysis/ImpactAnalysis'


const theme = createMuiTheme({
    palette: {
        primary: {main: "#1890FF"},
        secondary: {main: "#4b90ff"}
    },
    typography: {
        useNextVariants: true,
        fontWeight: 500,
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            "Poppins",
            "sans-serif",
            "Arial",
            '"Apple Color Emoji"',
        ].join(","),
    }
});

const store = configureStore();

class App extends Component {
    componentDidMount() {

        const excludeEnvs = ["production", "prod", "Production", "PRODUCTION", "PROD", "Prod"];
        let title = "Decooda: MC3";

        if (!excludeEnvs.includes(process.env.REACT_APP_NODE_ENV))
            title = ("Decooda: MC3 - " + process.env.REACT_APP_NODE_ENV);

        if (process.env.REACT_APP_NODE_ENV === undefined)
            title = "Decooda: MC3";

        document.title = title;

        Hub.listen('auth', (data) => {
            switch (data.payload.event) {
                case 'signIn':
                    console.log('Logged In');
                    break;
                case 'oAuthSignOut':
                    console.log('Logged Out');
                    break;
                case 'signIn_failure':
                    console.error('Sign In Failed');
                    clearCognitoLocalStorageItems();
                    clearAppLocalStorage();
                    break;
                default:
                    break;
            }
        })
    }

    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <CssBaseline/>
                <div className="App">
                    <ToastContainer
                        autoClose={3500}
                        hideProgressBar={true}
                        newestOnTop={true}
                    />
                    <Provider store={store}>
                        <Router>
                            <Switch>
                                <Route exact path="/" component={Login}/>
                                <Route exact path="/login" component={Login}/>
                                <Route exact path="/logout" component={Logout}/>
                                <ProtectedRoute exact path="/update" component={UpdateProfile}/>
                                <ProtectedRoute
                                    exact
                                    path="/summary"
                                    render={(props) => (
                                        <Layout>
                                            <Summary {...props} />
                                        </Layout>
                                    )}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/textDetails"
                                    render={(props) => (
                                        <Layout>
                                            <TextDetails {...props} />
                                        </Layout>
                                    )}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/workshop"
                                    render={(props) => (
                                        <Layout>
                                            <Workshop {...props} />
                                        </Layout>
                                    )}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/brandComparison"
                                    render={(props) => (
                                        <Layout>
                                            <BrandComparison {...props} />
                                        </Layout>
                                    )}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/kibana"
                                    render={(props) => (
                                        <Layout>
                                            <KibanaReport {...props} />
                                        </Layout>
                                    )}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/users"
                                    render={(props) => (
                                        <Layout>
                                            <UserManagement {...props} />
                                        </Layout>
                                    )}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/pricingView"
                                    render={(props) => (
                                        <Layout>
                                            <PricingView {...props} />
                                        </Layout>
                                    )}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/usersocialmedia"
                                    render={(props) => (
                                        <Layout>
                                            <ManageSocialMedia {...props} />
                                        </Layout>
                                    )}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/userEdit"
                                    render={(props) => (
                                        <Layout>
                                            <UserEdit {...props} />
                                        </Layout>
                                    )}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/atd"
                                    render={(props) => (
                                        <Layout>
                                            <ATD {...props} />
                                        </Layout>
                                    )}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/token"
                                    render={(props) => (
                                        <Layout>
                                            <LegacyAPIToken {...props} />
                                        </Layout>
                                    )}
                                />

                                <ProtectedRoute
                                    exact
                                    path="/impactAnalysis"
                                    render={(props) => (
                                        <Layout>
                                            <ImpactAnalysis  {...props}/>
                                        </Layout>
                                    )}
                                />
                                <ProtectedRoute
                                    path="*"
                                    render={(props) => (
                                        <Layout>
                                            <ErrorRoute {...props} />
                                        </Layout>
                                    )}
                                />
                            </Switch>
                        </Router>
                    </Provider>
                </div>
            </MuiThemeProvider>
        );
    }
}

export default App;

import lodashFilter from "lodash.filter";
import lodashSome from "lodash.some";
import lodashOrderBy from "lodash.orderby";
import { cloneDeep } from "lodash";

import { isEmptyObject } from "../../_helpers/utils";
import { POSITIVE_EMOTIONS, NEGATIVE_EMOTIONS } from "../../Constants";
import * as XLSX from "xlsx";

//String truncate based on before/after word count
export const splitStringByWordCount = (
  str,
  highliter,
  before_no_words,
  after_no_words,
  searchWord
) => {
  let trimmedString = str;
  try {
    if (highliter) {
      const re = new RegExp(
        highliter.trim().replace(/[[\]()*^$?.+]/g, "\\$&"),
        "i"
      );
      if (before_no_words === "" && after_no_words === "") {
        if (searchWord.trim().length === 0) return trimmedString;
        else return highLightSearchWord(trimmedString, highliter);
      } else {
        const strArry = str
          .replace(/  +/g, " ")
          .replace(/(<([^>]+)>)/gi, "")
          .split(re);
        if (before_no_words !== "" && after_no_words !== "") {
          let a = strArry[0]
            ? strArry[0].trim().split(" ").splice(-before_no_words)
            : [];
          let b = [highliter];
          let c = strArry[1]
            ? strArry[1].trim().split(" ").splice(0, after_no_words)
            : [];
          trimmedString = a.join(" ") + " " + b.join(" ") + " " + c.join(" ");
        }

        if (before_no_words !== "" && after_no_words === "") {
          let d = strArry[0]
            ? strArry[0].trim().split(" ").splice(-before_no_words)
            : [];
          let e = [highliter];
          trimmedString = d.join(" ") + " " + e.join("") + " " + strArry[1];
        }

        if (before_no_words === "" && after_no_words !== "") {
          let f = [highliter];
          let g = strArry[1]
            ? strArry[1].trim().split(" ").splice(0, after_no_words)
            : [];
          trimmedString = strArry[0] + " " + f.join("") + " " + g.join(" ");
        }
        return trimmedString;
      }
    } else return str;
  } catch (error) {
    console.log(error);
    return str;
  }
};

//Hightlight by word
export const highLightSearchWord = (str, word) => {
  if (str.trim().length === 0) return "";
  if (word === undefined || word.trim().length === 0 || word.trim().length < 3)
    return str;

  const escapedWord = word.replace(/[[\]()*^$?.+]/g, "\\$&");

  const substring = new RegExp(escapedWord, "ig");
  const cleanedStr = str.replace(/\s+/gi, " ");

  return cleanedStr.replace(substring, (match) => `<span>${match}</span>`);
};

//Hightlight main topic word
export const highLightMainWords = (str, word, color) => {
  if (str.trim().length === 0) return "";
  if (word === undefined || word.trim().length === 0) return str;

  const escapedWord = word.replace(/[[\]()*^$?.+]/g, "\\$&");

  const substring = new RegExp(escapedWord.trim(), "i");

  return str
    .replace(/\s+/g, " ")
    .replace(
      substring,
      (match) =>
        `<mark class="main-topic ${color ? color : ""}">${match}</mark>`
    );
};

//Hightlight Side topic words
export const highLightSideWords = (str, words) => {
  if (str.trim().length === 0) return "";
  if (words === undefined || words.length === 0) return str;

  const escapedWords = words.map((w) => w.replace(/[[\]()*^$?.+]/g, "\\$&"));

  const pattern = new RegExp(`(${escapedWords.join("|")})`, "ig");

  return str
    .replace(/\s+/g, " ")
    .replace(pattern, (match) => `<mark class="side-topic">${match}</mark>`);
};

//Hightlight Candidate Phrase
export const highLightCandidate = (str, words) => {
  if (str.trim().length === 0) return "";
  if (words === undefined || words.length === 0) return str;

  const escapedWords = words.map((w) => w.replace(/[[\]()*^$?.+]/g, "\\$&"));

  const substring = new RegExp(`(${escapedWords.join("|")})`, "ig");

  return str
    .replace(/\s+/g, " ")
    .replace(
      substring,
      (match) => `<mark class="candidate-phrase">${match}</mark>`
    );
};

//Candidate Main Topics context
export const candidateMainHighLight = (str, word) => {
  if (str.trim().length === 0) return "";
  if (word === undefined || word.trim().length === 0) return str;

  const escapedWord = word.replace(/[[\]()*^$?.+]/g, "\\$&");

  const substring = new RegExp(escapedWord.trim(), "ig");

  return str
    .replace(/\s+/g, " ")
    .replace(substring, (match) => `<mark>${match}</mark>`);
};

//Candidate Side topics Highlight
export const candidateSideHighLight = (str, words) => {
  if (str.trim().length === 0) return "";
  if (words === undefined || words.length === 0) return str;

  const escapedWords = words.map((w) => w.replace(/[[\]()*^$?.+]/g, "\\$&"));

  const pattern = new RegExp(`(${escapedWords.join("|")})`, "ig");

  return str
    .replace(/\s+/g, " ")
    .replace(pattern, (match) => `<mark class="side-topic">${match}</mark>`);
};

//Ngram Main Topics context
export const ngramMainHighLight = (str, word) => {
  if (str.trim().length === 0) return "";
  if (word === undefined || word.trim().length === 0) return str;

  const escapedWord = word.replace(/[[\]()*^$?.+]/g, "\\$&");

  const substring = new RegExp(escapedWord.trim(), "ig");

  return str
    .replace(/\s+/g, " ")
    .replace(
      substring,
      (match) => `<mark class="ngram-phrase">${match}</mark>`
    );
};

//Trim phrase context with words before/after
export const doContextTrim = (data, wordsBefore, wordsAfter, search) => {
  if (wordsBefore.length === 0 && wordsAfter.length === 0) return data;
  else
    return data.map((el) => {
      let str = el.context,
        highliter = search.trim().length === 0 ? el.phrase : search,
        tArr = [];
      if (str.length !== 0) {
        tArr = str.map((cx) => {
          cx.context = splitStringByWordCount(
            cx.context,
            highliter,
            wordsBefore,
            wordsAfter,
            search
          );
          return cx;
        });
        el.context = tArr;
      } else {
        el.context = [];
      }
      return el;
    });
};

//Filter by Phrase Pattern
export const doPatternFilter = (list, pattern) => {
  let filteredList = list;
  try {
    if (pattern.length !== 0) {
      filteredList = list.filter((p) => {
        let t1 = pattern.join(",").toLowerCase();
        let t2 = p.pos_tags.join(",").toLowerCase();
        return t2.search(t1) > -1;
      });
    }
    return filteredList;
  } catch (error) {
    console.log(error);
    return list;
  }
};

//Phrases Sorting
export const doPhraseSorting = (list, sortBy, sortType) => {
  let orderedList = list;
  const sort = sortBy === "alphabet" ? "phrase" : sortBy;
  try {
    if (sortBy !== "") orderedList = lodashOrderBy(list, [sort], [sortType]);

    return orderedList;
  } catch (error) {
    console.log(error);
    return list;
  }
};

//Phrase Search
export const doPhraseSearch = (list, word) => {
  let filteredList = list;
  const searchWord = word.trim().replace(/\s+/g, " ").toLowerCase();

  try {
    if (searchWord.length === 0 || searchWord.length < 3) {
      return list;
    } else {
      //adding clean context for search
      const cleanContext = cloneDeep(list).map((li) => {
        li.context.map((lictx) => {
          lictx.contextPlain = lictx.context
            .replace(/\s+/g, " ")
            .replace(/(<([^>]+)>)/gi, "")
            .toLowerCase();
          return lictx;
        });
        return li;
      });

      filteredList = lodashFilter(cloneDeep(cleanContext), (item) => {
        return lodashSome(item.context, (ctx) => {
          return ctx.contextPlain.includes(searchWord);
        });
      });

      filteredList.forEach((el) => {
        const fg = lodashFilter(el.context, (ctx) => {
          return ctx.contextPlain.includes(searchWord);
        });
        fg.map((ct) => {
          ct.context = highLightSearchWord(ct.context, searchWord);
          return ct;
        });
        el.context = fg;
      });

      return filteredList;
    }
  } catch (error) {
    console.log(error);
    return list;
  }
};

//Topic Search
export const doTopicSearch = (list, searchWord, exceptionIds) => {
  let filteredList = list;
  try {
    if (searchWord.trim().length > 1) {
      filteredList = lodashFilter(list, (item) => {
        if (exceptionIds && exceptionIds.includes(item.id)) return true;
        else {
          if (item.name.toLowerCase().indexOf(searchWord.toLowerCase()) !== -1)
            return true;
          else return false;
        }
      });
      return filteredList;
    } else return list;
  } catch (error) {
    console.log(error);
    return list;
  }
};

//Topics Sorting
export const doTopicSorting = (list, sortBy, sortType) => {
  let orderedList = list;
  try {
    if (sortBy === "") {
      orderedList = lodashOrderBy(list, ["frequency"], ["desc"]);
    } else {
      orderedList = lodashOrderBy(
        list,
        function (dateObj) {
          if (sortBy === "date") return new Date(dateObj.createdAt);
          else if (sortBy === "alphabet") return dateObj.name;
          else return dateObj.frequency;
        },
        [sortType]
      );
    }
    return orderedList;
  } catch (error) {
    console.log(error);
  }
};

//Hightlight main topic word
export const mainTopicWithDataAttr = (str, wordObj) => {
  if (str === undefined || str.trim().length === 0) return "";
  if (wordObj === undefined || wordObj.length === 0) return str;

  const escapedWords = wordObj.map((w) => ({
    ...w,
    value: w.value.replace(/[[\]()*^$?.+]/g, "\\$&"),
  }));

  const pattern = new RegExp(
    `${escapedWords.map((e) => e.value).join("|")}`,
    "ig"
  );

  let finalString = str.replace(/\s+/g, " ");
  finalString = finalString.replace(pattern, (match, index) => {
    const obj = escapedWords.find(
      (e) => e.value.toLowerCase() === match.toLowerCase()
    );
    const lIndex = index + match.length;
    if (
      lIndex < finalString.length &&
      finalString[index - 1] === '"' &&
      finalString[lIndex + 1] === '"' &&
      finalString[lIndex + 2] === ">"
    )
      return match;
    return `<mark class="main-topic" data-topic="${
      obj ? obj.topic : escapedWords[0].topic
    }">${match}</mark>`;
  });

  return finalString;
};

export const sideTopicWithDataAttr = (str, wordObj) => {
  if (str === undefined || str.trim().length === 0) return "";
  if (wordObj === undefined || wordObj.length === 0) return str;

  const escapedWords = wordObj.map(w => ({
    ...w, value: w.value.replace(/[[\]()*^$?.+]/g, '\\$&')
  }));
  const pattern = new RegExp(`${escapedWords.map(e => e.value).join("|")}`, "ig");

  let finalString = str.replace(/\s+/g, " ");

  const div = document.createElement("div");
  div.innerHTML = finalString.trim();
  let childArry = [];

  [...div.childNodes].forEach(e => {
    if(e.nodeName === '#text') {
      const markedStr = e.nodeValue.replace(
        pattern,
        (match, index) => {
          const obj = wordObj.find(e => e.value.toLowerCase() === match.toLowerCase());
          if(!obj) return match
          return `<mark class="side-topic" data-topic="${obj.topic}">${match}</mark>`
        }
      )
      childArry.push(markedStr)
    }
    else
      childArry.push(e.outerHTML)
  });

  finalString = childArry.join(" ");
  return finalString;
};

export const searchMainTopicWithDataAttr = (str, wordObj) => {
  if (str === undefined || str.trim().length === 0) return "";
  if (wordObj === undefined || isEmptyObject(wordObj)) return str;

  const substring = new RegExp(
    wordObj.value.trim().replace(/[[\]()*^$?.+]/g, "\\$&"),
    "i"
  );

  return str
    .replace(/\s+/g, " ")
    .replace(
      substring,
      (match) =>
        `<mark class="main-topic" data-topic="${wordObj.topic}">${match}</mark>`
    );
};

//Remove duplicates from array
export const uniqueArray = (array) => {
  const uniqueSet = new Set();

  cloneDeep(array).forEach((p) => {
    p.context.forEach((c) => {
      const splitContextIDArray = c.contextId.split("|");
      if (splitContextIDArray.length >= 3) {
        uniqueSet.add(
          splitContextIDArray[splitContextIDArray.length - 3].trim()
        );
      } else {
        uniqueSet.add(c.contextId.trim());
      }
    });
  });

  return [...uniqueSet];
};

export const snapSelectionToWord = () => {
  var sel;
  // Check for existence of window.getSelection() and that it has a
  // modify() method. IE 9 has both selection APIs but no modify() method.
  if (window.getSelection && (sel = window.getSelection()).modify) {
    sel = window.getSelection();
    if (!sel.isCollapsed) {
      // Detect if selection is backwards
      var range = document.createRange();
      range.setStart(sel.anchorNode, sel.anchorOffset);
      range.setEnd(sel.focusNode, sel.focusOffset);
      var backwards = range.collapsed;
      range.detach();

      // modify() works on the focus of the selection
      var endNode = sel.focusNode,
        endOffset = sel.focusOffset;
      sel.collapse(sel.anchorNode, sel.anchorOffset);

      var direction = [];
      if (backwards) {
        direction = ["backward", "forward"];
      } else {
        direction = ["forward", "backward"];
      }

      sel.modify("move", direction[0], "character");
      sel.modify("move", direction[1], "word");
      sel.extend(endNode, endOffset);
      sel.modify("extend", direction[1], "character");
      sel.modify("extend", direction[0], "word");
    }
  } else if ((sel = document.selection) && sel.type !== "Control") {
    var textRange = sel.createRange();
    if (textRange.text) {
      textRange.expand("word");
      // Move the end back to not include the word's trailing space(s),
      // if necessary
      while (/\s$/.test(textRange.text)) {
        textRange.moveEnd("character", -1);
      }
      textRange.select();
    }
  }
};

// process CSV data
const processCSVData = (dataString) => {
  const dataStringLines = dataString.split(/\r\n|\n/);
  const headers = dataStringLines[0]
    .split(/\t|"|,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/)
    .filter((e) => e !== "");

  if (!["Name", "S/E", "Phrase"].every((e) => headers.includes(e))) {
    throw new Error();
  }

  const list = [];
  for (let i = 1; i < dataStringLines.length; i++) {
    const row = dataStringLines[i].split(
      /\t|"|,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );
    if (headers && row.length >= headers.length) {
      const obj = {};
      for (let j = 0; j < row.length; j++) {
        let d = row[j];
        if (d.length > 0) {
          if (d[0] === '"') d = d.substring(1, d.length - 1);
          if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
        }
        if (headers[j] && j < headers.length - 1) {
          obj[headers[j]] = d;
        } else if (j === headers.length - 1) {
          obj[headers[j]] = d.length ? [d] : [];
        } else if (j > headers.length - 1 && d !== "") {
          obj[headers[headers.length - 1]] = [
            ...obj[headers[headers.length - 1]],
            d,
          ];
        }
      }

      // remove the blank rows
      if (Object.values(obj).filter((x) => x).length > 0) {
        list.push(obj);
      }
    }
  }

  return list
    .filter((e) => e.Name.length)
    .map((e) => ({
      Name: e.Name,
      Emotion: emotionDetection(e["S/E"]),
      Sentiment: sentimentDetection(e["S/E"]),
      Phrase: e.Phrase,
    }));
};

const sentimentDetection = (str) => {
  let out;
  POSITIVE_EMOTIONS.includes(str.toLowerCase()) || str.search(/POS/i) > -1
    ? (out = "POSITIVE")
    : NEGATIVE_EMOTIONS.includes(str.toLowerCase()) || str.search(/NEG/i) > -1
    ? (out = "NEGATIVE")
    : (out = "");
  return out;
};

const emotionDetection = (str) => {
  return POSITIVE_EMOTIONS.includes(str.toLowerCase()) ||
    NEGATIVE_EMOTIONS.includes(str.toLowerCase())
    ? str.toLowerCase()
    : "";
};

export const seedFileParser = (file, fromFile) => {
  return new Promise((resolve, reject) => {
    if (fromFile) {
      const reader = new FileReader();

      reader.onerror = (e) => {
        reader.abort();
        reject(e);
      };

      reader.onload = (evt) => {
        try {
          /* Parse data */
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
          resolve(processCSVData(data));
        } catch (err) {
          reject(err);
        }
      };

      reader.readAsBinaryString(file);
    } else {
      try {
        resolve(processCSVData(file));
      } catch (err) {
        reject(err);
      }
    }
  });
};

export const custSpecialObjToCSV = (objArray) => {
  var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  var str = "";

  str += '"' + Object.keys(objArray[0]).join("@#") + '"' + "\r\n";

  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line !== "") line += "@#";

      line += array[i][index];
    }

    str += '"' + line + '"' + "\r\n";
  }

  return str;
};

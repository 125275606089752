import '../../../containers/Modal/Modal.css';
import React, { Component } from 'react';
import Tree, { TreeNode } from 'rc-tree';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Slider from '@material-ui/core/Slider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { DELETE, DISCOVER_TOPIC, EXPORT, IMPORT, CREATE, FOLDER, COPY, EMPTY_STRING, EMPTY_ARRAY, RENAME, EXPORT_CLASSIFIER_NAMES, EXPORT_ID_NAMES, CLASSIFIER } from '../../../Constants';
import { toCapitalize } from '../../../_helpers/utils';
import { toast } from 'react-toastify';
import SAMPLE_CSV_IMAGE from '../../../assets/images/fileDetails.png';
import SAMPLE_CSV_IMAGE2 from '../../../assets/images/fileDetails2.png';
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

const PrettoSlider = withStyles({
    root: {
        color: '#2196F3',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus,&:hover,&$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

class CustomModal extends Component {

    state = {
        title: null,
        itemName: EMPTY_STRING,
        contextEvent: EMPTY_STRING,
        inputVal: EMPTY_STRING,
        sliderValue: 0.5,
        exportValue: 'json',
        fileDetails: null,
        switcherIcon: <i className="fa fa-plus-square"></i>,
        expandedKey: ["0"],
        modalTreeData: EMPTY_ARRAY,
        selectedKey: [],
        errorMsg: EMPTY_STRING,
        disabledBtn: false,
        pipeline: "Decooda1"
    };

    //Handle Ok Event
    validateInput = () => {
        const isEmpty = this.props.data.itemTitle === 'Create Empty Model' ? true : false;

        if (this.props.data.nodeName === EMPTY_STRING || this.props.data.nodeName.trim().length === 0) {
            this.setState({ errorMsg: "Name cannot be empty." })
        } else {
            this.setState({disabledBtn: true,})
            this.props.handleOk(isEmpty);
        }
            
    }

    //Update input value
    handleContent = (e) => {
        if (e.target.value.length > 40) {
            this.setState({ errorMsg: "Name cannot exceed 40 characters." })
            return
        } else {
            this.setState({ errorMsg: EMPTY_STRING });
            if(this.props.treeType === CLASSIFIER)
            this.props.handleInputChange(e.target.value.replace(/\s{2,}/g, ' ').replace(/[^a-zA-Z0-9_() ]/g,''));
            else
            this.props.handleInputChange(e.target.value.replace(/\s{2,}/g, ' '));
        }
    }

    //auto select rename input
    handleFocus = (e) => { e.target.select() }

    //Handle Copy Node Event
    handleCopyNode = () => {
        if(this.state.selectedKey.length === 0){
            toast.warn("Please select classifer to copy")
            return
        }
        this.props.copyNodeHandler(this.state.selectedKey)
    };

    //Handle Threshold update Event
    handleThresholdSubmit = () => {
        this.props.updateThresholdSubmit(this.state.sliderValue, this.state.pipeline)
        setTimeout(() => {
            this.setState({ sliderValue: 0.5, disabledBtn: false })
        }, 400)
    }

    //update threshold value
    handleSliderChange = (event, newValue) => this.setState({ ...this.state, sliderValue: newValue });

    //Handle Import Event
    handleImport = () => this.props.handleFileUpload(this.state.fileDetails);

    //Handle Export Event
    handleExport = () => this.props.submitExport(this.state.exportValue, this.props.data.nodeName, this.props.data.nodeModule);

    handleClassiferNameExport= () => this.props.submitClassiferNameExport(this.state.exportValue, this.props.data.nodeName);
    
    handleClassiferIdNameExport= () => this.props.submitClassiferIdNameExport(this.state.exportValue, this.props.data.nodeName);
    
    //Update Radio change value
    handleExportRadioChange = (e) => this.setState({ ...this.state, exportValue: e.target.value });

    //On entering droppable area
    onFilesDragEnter = (event) => {
        event.preventDefault()
        event.stopPropagation()

        if (event.target.parentElement.classList.contains('droppableOverlay'))
            event.target.parentElement.classList.add('droppable')
    }

    //On Drag leave Event 
    onFilesDragLeave = (event) => {
        event.preventDefault()
        event.stopPropagation()
        event.target.parentElement.classList.remove('droppable')
    }

    //On Drop Event
    onFileDrop = (event) => {
        event.preventDefault()
        event.stopPropagation()
        this.setState({ fileDetails: event.dataTransfer.files[0] });
    }

    //On file input change
    handleCapture = (event) => this.setState({ fileDetails: event.target.files[0] });

    //Tree node select event
    onNodeSelect = (k, info) => {
        this.setState({ selectedKey: k });
        if(k.length === 0)
        this.setState({ disabledBtn: true })
        else 
        this.setState({ disabledBtn: false })
    }

    componentDidMount() {
        this.setState({
            selectedKey: ['0'],
        }) 
    }

    UNSAFE_componentWillReceiveProps(nextProps, prevState) {
        this.setState({
            inputVal: nextProps.data.itemTitle === 'Rename' ? this.props.initialVal : this.props.val,
            fileDetails: null,
            disabledBtn: nextProps.data.itemEvent === COPY ? true : false,
            exportValue: 'csv',
            selectedKey: nextProps.data.itemEvent === COPY ? [] : ['0'],
        })
    }

    //Disable Nodes
    isDisabled = (item) => {
        const r = this.props.data.itemTitle.split(' ').reverse();
        if (r[0] === 'classifier') {
            const selectedItemRootId = this.props.data.nodeRootId;
            if (selectedItemRootId === 0) {
                if (item.key === '-2' || item.key === '-3')
                    return true
                else {
                    if ((item['dataRoot'] === -2) || (item['dataRoot'] === -3))
                        return true
                }
            } else if(selectedItemRootId === -2) {
                if (item.key === '0' || item.key === '-3')
                    return true
                else {
                    if ((item['dataRoot'] === 0) || (item['dataRoot'] === -3))
                        return true
                }
            } else {
                if (item.key === '0' || item.key === '-2')
                    return true
                else {
                    if ((item['dataRoot'] === 0) || (item['dataRoot'] === -2))
                        return true
                }
            }
        }
    }

    render() {
        
        const uiDialogClass = this.props.data.itemEvent === FOLDER || this.props.data.itemEvent === IMPORT
            ? "createFolderModel"
            : this.props.data.itemEvent === CREATE
                ? "createFileModel"
                : this.props.data.itemEvent === EXPORT
                    ? "exportModel"
                    : this.props.data.itemEvent === DELETE
                        ? "deleteModel"
                        : this.props.data.itemEvent === COPY
                            ? "copyModel"
                            : null

        const loopData = data => {
            return data.map((item) => {
                if (item.children) {
                    const isDisabled = this.isDisabled(item);
                    return (
                        <TreeNode
                            key={item.key} 
                            title={item.title} 
                            data-key={item.key} 
                            disabled={isDisabled} 
                            data-root={item.dataRoot}
                            data-testid={"node" + item.key}
                        >
                            {loopData(item.children)}
                        </TreeNode>
                    );
                } else return null
            });
        };

        return (
            <Dialog
                aria-labelledby="contextual-menu-events"
                open={this.props.open}
                onClose={this.props.handleClose}
                fullWidth={true}
                maxWidth={this.props.data.itemEvent === IMPORT ? 'md' : 'sm'}
            >
                <div className={"uiDialog " + uiDialogClass} data-id={this.props.data.itemParentId}>
                    <div className="uiDialogTitle">
                        <h6> {this.props.data.itemTitle}</h6>
                    </div>
                    <div className="uiDialogContent">
                        {
                            //Delete Message
                            this.props.data.itemEvent === DELETE
                                ? <div>Are you sure you want to delete the selected {this.props.treeType} ?
                                <div className="deleteItemsDiv">
                                        <p>{this.props.data.nodeName}</p>
                                    </div>
                                </div>
                                : //Discover Topic Body
                                this.props.data.itemEvent === DISCOVER_TOPIC
                                    ? <div><div className="thresholdRange">
                                        <PrettoSlider
                                            step={0.01} min={0} max={1}
                                            valueLabelDisplay="auto"
                                            aria-label="pretto slider"
                                            defaultValue={this.state.sliderValue}
                                            onChange={this.handleSliderChange}
                                            data-testid="slider"
                                        />
                                        <div>Selected value: {this.state.sliderValue}</div>
                                        </div>
                                        <FormControl className="decooda-pipeline-select">
                                            <InputLabel id="decooda-pipeline">Pipeline</InputLabel>
                                            <Select
                                                labelId='decooda-pipeline-label'
                                                id='decooda-pipeline'
                                                defaultValue='Decooda1'
                                                value={this.state.pipeline}
                                                onChange={(e) => this.setState({
                                                    pipeline: e.target.value
                                                })}
                                            >
                                                <MenuItem value="Decooda1">Decooda1</MenuItem>
                                                <MenuItem value="Decooda2">Decooda2</MenuItem>
                                            </Select>
                                            </FormControl>
                                    </div>
                                    : //Export Options
                                    this.props.data.itemEvent === EXPORT
                                        ? <div className="exportHolder">
                                            <RadioGroup
                                                style={{ flexDirection: 'row' }}
                                                id="exportFormat"
                                                className="radioInput"
                                                name="exportFormat"
                                                aria-label="exportFormat"
                                                value={this.state.exportValue}
                                                onChange={this.handleExportRadioChange}
                                            >
                                                <FormControlLabel value="csv" control={<Radio />} label="CSV" data-testid="csvRadioBtn" />
                                                <FormControlLabel value="xlsx" control={<Radio />} label="XLSX" data-testid="xlsxRadioBtn" />
                                                { this.props.data.nodeModule === CLASSIFIER ? null :
                                                <FormControlLabel value="json" control={<Radio />} label="JSON" data-testid="jsonRadioBtn" /> }
                                            </RadioGroup>
                                        </div>
                                        : this.props.data.itemEvent === EXPORT_CLASSIFIER_NAMES
                                        ? <div className="exportHolder">
                                        <RadioGroup
                                            style={{ flexDirection: 'row' }}
                                            id="exportFormat"
                                            className="radioInput"
                                            name="exportFormat"
                                            aria-label="exportFormat"
                                            value={this.state.exportValue}
                                            onChange={this.handleExportRadioChange}
                                        >
                                            <FormControlLabel value="csv" control={<Radio />} label="CSV" data-testid="csvRadioBtn" />
                                            <FormControlLabel value="xlsx" control={<Radio />} label="XLSX" data-testid="xlsxRadioBtn" />
                                        </RadioGroup>
                                    </div>
                                    : this.props.data.itemEvent === EXPORT_ID_NAMES
                                    ? <div className="exportHolder">
                                    <RadioGroup
                                        style={{ flexDirection: 'row' }}
                                        id="exportFormat"
                                        className="radioInput"
                                        name="exportFormat"
                                        aria-label="exportFormat"
                                        value={this.state.exportValue}
                                        onChange={this.handleExportRadioChange}
                                    >
                                        <FormControlLabel value="csv" control={<Radio />} label="CSV" data-testid="csvRadioBtn" />
                                        <FormControlLabel value="xlsx" control={<Radio />} label="XLSX" data-testid="xlsxRadioBtn" />
                                    </RadioGroup>
                                </div>
                                        : //Import Options
                                        this.props.data.itemEvent === IMPORT
                                            ? <div className="import-container">
                                                <div className="fileDroppbleHolder">
                                                    <div className="uploadIcon"><CloudUploadIcon /></div>
                                                    <div className="byDrop">Drag and drop .csv file here</div>
                                                    <div style={{ 'color': '#8c8c8c' }}>or</div>
                                                    <div>Browse file</div>
                                                    {this.state.fileDetails === null || this.state.fileDetails === undefined 
                                                        ? null
                                                        : <p className="fileDetails">{this.state.fileDetails.name}</p>
                                                    }
                                                    <div className="droppableOverlay">
                                                        <input
                                                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                            className={"droppableHiddenInput"}
                                                            id="file-upload-btn"
                                                            type="file"
                                                            title={(this.state.fileDetails === null || this.state.fileDetails === undefined ) ? "No file chosen" : this.state.fileDetails.name}
                                                            onChange={this.handleCapture}
                                                            onDragEnter={this.onFilesDragEnter}
                                                            onDragLeave={this.onFilesDragLeave}
                                                            onDrop={this.onFileDrop}
                                                        />
                                                    </div>
                                                </div>
                                                { /* Sample CSV file holder */}
                                                <div className="sample-csv-file">
                                                    <small><b>Note:</b> 
                                                    <br/>
                                                    <span>1) Include only comma separated .csv file * &nbsp;
                                                <i className="fa fa-question-circle fileHelp" aria-hidden="true" title="Sample csv file"></i>
                                                        <img alt="sample-csv-file" src={this.props.data.nodeFrameType === 'calculated_classifier' ? SAMPLE_CSV_IMAGE2 : SAMPLE_CSV_IMAGE} /></span>
                                                    <span>
2) Classifiers CSV should have the same column order as mentioned in above sample CSV.</span>
<span>3) If classifier column has id then it will update the same classifier and if it is empty then  create a new classifier.</span>
<span>4) If Classifier ID does not exist in database, Neither create a new classifier nor update the any classifier.</span>
                                                    </small>
                                                </div>
                                            </div>
                                            : this.props.data.itemEvent === COPY
                                                ? <div className="copyOptions">
                                                    <TextField autoComplete="off"
                                                        className="modelInput"
                                                        id="itemCopyName"
                                                        label={toCapitalize(this.props.treeType) + " Name"}
                                                        margin="normal"
                                                        value={this.props.data.nodeName}
                                                        onChange={this.handleContent}
                                                        autoFocus={true}
                                                        InputLabelProps={{ shrink: true, }}
                                                        inputProps={{"data-testid": "modelInput"}}
                                                    />
                                                    <div className="modalTree">
                                                        {this.props.modelTreeLoading ? 'Loading tree...' : 
                                                        <Tree
                                                            defaultExpandAll
                                                            switcherIcon={this.state.switcherIcon}
                                                            selectedKeys={this.state.selectedKey}
                                                            onSelect={this.onNodeSelect}
                                                        >
                                                            {loopData(this.props.modelTreeData)}
                                                        </Tree> }
                                                    </div>
                                                    <small>Note: Select folder in which you want to copy.</small>
                                                </div>
                                                : /* Default Input Text */
                                                <><TextField autoComplete="off"
                                                    required
                                                    className="modelInput"
                                                    id="itemName"
                                                    label="Enter name"
                                                    margin="normal"
                                                    value={this.props.data.nodeName}
                                                    onChange={this.handleContent}
                                                    autoFocus={true}
                                                    onFocus={this.handleFocus}
                                                    inputProps={{"data-testid": "itemNameInput"}}
                                                />
                                                    {this.state.errorMsg === EMPTY_STRING ? null : <div className="errorMsg">{this.state.errorMsg}</div>}
                                                </>
                        }
                    </div>
                    <footer>
                        <div>
                            <Button onClick={this.props.handleClose}>Cancel</Button>
                            {this.props.data.itemEvent === DELETE
                                ? <Button color="primary" disabled={this.state.disabledBtn} onClick={this.props.deleteItem} data-testid="treeDeleteButton">Delete</Button>
                                : this.props.data.itemEvent === DISCOVER_TOPIC
                                    ? <Button color="primary" disabled={this.state.disabledBtn} onClick={this.handleThresholdSubmit}>Submit</Button>
                                    : this.props.data.itemEvent === EXPORT
                                        ? <Button color="primary" disabled={this.state.disabledBtn} onClick={this.handleExport} data-testid="treeExportBtn">Export</Button>
                                        : this.props.data.itemEvent === EXPORT_CLASSIFIER_NAMES
                                        ? <Button color="primary" disabled={this.state.disabledBtn} onClick={this.handleClassiferNameExport} data-testid="treeExportBtn">Export</Button>
                                        : this.props.data.itemEvent === EXPORT_ID_NAMES
                                        ? <Button color="primary" disabled={this.state.disabledBtn} onClick={this.handleClassiferIdNameExport} data-testid="treeExportBtn">Export</Button>
                                        :this.props.data.itemEvent === IMPORT
                                            ? <Button color="primary" disabled={this.state.fileDetails === null} onClick={this.handleImport}>Upload</Button>
                                            : this.props.data.itemEvent === COPY
                                                ? <Button color="primary" disabled={this.state.disabledBtn} onClick={this.handleCopyNode} data-testid="copyTreeBtn">Copy</Button>
                                                : <Button color="primary" disabled={this.state.disabledBtn} onClick={this.validateInput} data-testid="createButton">
                                                    {this.props.data.itemEvent === RENAME ? 'Update' : 'Create'}
                                                </Button>
                            }
                        </div>
                    </footer>
                </div>
            </Dialog>
        )
    }
}

export default CustomModal;
import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";

const BrandExportOptions = ({
  options,
  brandExportOption,
  brandExportType,
  handleExportType,
  handleExportOption,
  selectAll,
  handleSelectAll,
  checkedItems,
  setCheckedItems,
  ...props
}) => {
  const CHECK_ALL = "CHECK_ALL";
  const UN_CHECK_ALL = "UN_CHECK_ALL";

  const checkHandler = (label) => {
    if (label === CHECK_ALL) setCheckedItems(options);
    else if (label === UN_CHECK_ALL) setCheckedItems([]);
    else {
      if (checkedItems.includes(label)) {
        setCheckedItems(
          checkedItems.slice().filter((checkItem) => label !== checkItem)
        );
      } else setCheckedItems(checkedItems.slice().concat(label));
    }
  };

  return (
    <div className="brandExportOptions">
      <h4
        style={{
          margin: "0px 0px 5px 0px",
          fontWeight: "500",
          color: "rgb(0 0 0 / .5)",
        }}
      >
        Select export type
      </h4>
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="brandExportType"
          name="brandExportType"
          value={brandExportType}
          onChange={(e) => {
            handleExportType(e.target.value);
          }}
        >
          <FormControlLabel value={"csv"} control={<Radio />} label="CSV" />
          &nbsp;&nbsp;
          <FormControlLabel
            value={"json"}
            control={<Radio />}
            label="JSON"
          />
        </RadioGroup>
      </FormControl>
      <h4
        style={{
          margin: "10px 0px 5px 0px",
          fontWeight: "500",
          color: "rgb(0 0 0 / .5)",
        }}
      >
        Select export option
      </h4>
      {brandExportType === "csv" ? (
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="brandExport"
            name="brandExport"
            value={brandExportOption}
            onChange={(e) => {
              handleExportOption(e.target.value);
            }}
          >
            <FormControlLabel
              value={"one"}
              control={<Radio />}
              label="Export with content"
            />
            &nbsp;&nbsp;
            <FormControlLabel
              value={"two"}
              control={<Radio />}
              label="Export without content"
            />
          </RadioGroup>
        </FormControl>
      ) : (
        <div id="jsonOptions">
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  checkedItems.length === 0
                    ? false
                    : options.length === checkedItems.length
                }
                value={CHECK_ALL}
                onChange={() =>
                  checkHandler(
                    options.length === checkedItems.length
                      ? UN_CHECK_ALL
                      : CHECK_ALL
                  )
                }
                name="selectall"
                color="secondary"
              />
            }
            label="Select all"
          />
          <div className="card-lists">
            {options.map((li, i) => {
              return (
                <div key={i} className="card-list">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedItems.includes(li)}
                        onChange={() => checkHandler(li)}
                        name={li}
                        color="secondary"
                      />
                    }
                    label={li}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  )
};

export default BrandExportOptions;

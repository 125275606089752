import React from 'react';
import './NoAccess.css'

class NoAccess extends React.Component {
    render() {
        return(
            <div className="noAccess">
                <h3>Warning!</h3>
                <small>You don't have permission to access.</small>
            </div>
        )
    }
}

export default NoAccess;
import React, { Component } from 'react'
import client from '../../../_helpers/client'
import { toast } from 'react-toastify'
import {
  Paper,
  Button,
  IconButton,
  Zoom,
  Tooltip,
  Dialog,
  CircularProgress
} from '@material-ui/core'

import { DELETE_OUTBOUND_DATASOURCE } from '../../../Routes'
import { SUCCESS, EMPTY_STRING, WARN } from '../../../Constants'

class AvailableOutBoundDB extends Component {
  state = {
    isModalOpen: false,
    dataSourceId: null,
    dataSourceName: EMPTY_STRING
  }

  handleModalClose = (id) => {
    this.setState({
      ...this.state,
      isModalOpen: false,
      dataSourceId: null,
      dataSourceName: EMPTY_STRING
    })
  }

  handleModalAccept = () => {
    client
      .get(DELETE_OUTBOUND_DATASOURCE, {
        params: { id: this.state.dataSourceId }
      })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          this.props.updatedData(res.data.response)
          toast.success(res.data.message)
        } else {
          if (res.data.status === WARN) toast.warn(res.data.message)
          else toast.error(res.data.message)
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === WARN)
            toast.warn(error.response.data.message)
          else toast.error(error.response.data.message)
        }
      })
    this.handleModalClose()
  }

  deleteDataSource = (row) => {
    this.setState({
      ...this.state,
      dataSourceName: row.dbName,
      dataSourceId: row.id,
      isModalOpen: true
    })
  }

  render() {
    const { isModalOpen } = this.state

    return (
      <React.Fragment>
        <Dialog
          open={isModalOpen}
          onClose={this.handleModalClose}
          aria-labelledby='delete--confirm-dialog-title'
        >
          <div className='uiDialog deleteModel'>
            <div className='uiDialogTitle'>
              <h6>Removing Data Source!</h6>
            </div>
            <div className='uiDialogContent'>
              <div>
                Are you sure you want to remove{' '}
                <span className='delete-highlight'>
                  {this.state.dataSourceName}
                </span>
                data source from brand ?
              </div>
            </div>
            <footer>
              <div>
                <Button onClick={this.handleModalClose} color='default'>
                  Cancel
                </Button>
                <Button
                  onClick={this.handleModalAccept}
                  color='primary'
                  autoFocus
                >
                  Delete
                </Button>
              </div>
            </footer>
          </div>
        </Dialog>

        <Paper>
          <div className='outBoundTable'>
            <table className='accordianTables'>
              <thead>
                <tr>
                  <th style={{ width: 38 }}></th>
                  <th>Database Type</th>
                  <th>User Name</th>
                  <th>IP Address</th>
                  <th>Port</th>
                  <th>Database Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.props.data.map((row, i) => {
                  return (
                    <tr id={row.id} key={i}>
                      <td style={{ verticalAlign: 'top', textAlign: 'center' }}>
                        <div className='canDelete'>
                          <Tooltip title='Delete' TransitionComponent={Zoom}>
                            <IconButton
                              size='small'
                              color='inherit'
                              onClick={() => this.deleteDataSource(row)}
                            >
                              <i className='fa fa-times' title='Remove'></i>
                            </IconButton>
                          </Tooltip>
                        </div>
                      </td>
                      <td style={{ verticalAlign: 'top' }}>
                        <div>{row.dbType}</div>
                      </td>
                      <td style={{ verticalAlign: 'top' }}>
                        <div>{row.username}</div>
                      </td>
                      <td style={{ verticalAlign: 'top' }}>
                        <div>{row.ipAddress}</div>
                      </td>
                      <td style={{ verticalAlign: 'top' }}>
                        <div>{row.dbPort}</div>
                      </td>
                      <td style={{ verticalAlign: 'top' }}>
                        <div>{row.dbName}</div>
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <Button
                          style={{ fontSize: '12px' }}
                          variant='contained'
                          color='primary'
                          size='small'
                          onClick={() =>
                            this.props.handleOutboundRequest(row.id)
                          }
                          disabled={
                            this.props.brandStatus !== 1 || (row.status !== 1 && row.status !== 0) || this.props.outboundProgressId === row.id
                          }
                        >
                          Load Data
                          {this.props.outboundProgressId === row.id && <CircularProgress size={16} style={{ position: 'absolute' }} />}
                        </Button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Paper>
      </React.Fragment>
    )
  }
}

export default AvailableOutBoundDB

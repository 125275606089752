import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { EMPTY_STRING } from "../../../Constants";
import { toast } from "react-toastify";

export default function ATDDialog({
  open,
  handleDialogClose,
  handleDialogSave,
  handleDialogDel,
  title,
  dialogData,
}) {
  const [inputValue, setInputValue] = useState(EMPTY_STRING);
  const [newTopicSentiment, setNewTopicSentiment] = useState(EMPTY_STRING);
  const [inputValueError, setInputValueError] = useState(false);

  useEffect(() => {
    if (open) {
      setInputValue(title);
      setNewTopicSentiment(EMPTY_STRING);

      if (title.split(" ").length > 5) setInputValueError(true);
    }
  }, [open, title]);

  const handleOk = () => {
    let returnObj = {};
    returnObj["topicTitle"] = inputValue.trim();
    returnObj["topicSentiment"] = newTopicSentiment;

    const acceptedSentiments = ["POSITIVE", "NEGATIVE", "NEUTRAL"];
    if (!acceptedSentiments.includes(newTopicSentiment)) {
      toast.warn("Topic sentiment should be POSITIVE, NEGATIVE or NEUTRAL.");
      return;
    }

    handleDialogSave(returnObj);
  };

  const handleTopicTitleChange = (e) => {
    setInputValue(e.target.value);
    const inputArray = e.target.value.trim().split(" ");
    if (inputArray.length > 5) {
      setInputValueError(true);
    } else {
      setInputValueError(false);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogData.event === "delete" ? "Delete Phrase" : "Create Topic"}
        </DialogTitle>
        <DialogContent style={{ marginTop: "-15px", marginBottom: "25px" }}>
          {dialogData.event === "delete" ? (
            <React.Fragment>
              Do you want to delete&nbsp;
              <span style={{ color: "#f55c5c" }}>{dialogData.selPhrase}</span>
              &nbsp;from topic&nbsp;
              <em style={{ color: "#000" }}>{dialogData.selTopic}</em>?
            </React.Fragment>
          ) : (
            <React.Fragment>
              <TextField
                autoFocus
                margin="dense"
                fullWidth
                id="topic-title"
                label="Topic Title"
                value={inputValue}
                onFocus={(e) => e.target.select()}
                onChange={handleTopicTitleChange}
                helperText={inputValueError ? "Maximum 5 words allowed." : ""}
                error={inputValueError}
              />
              <br />
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="new-topic-sentiment">
                  Topic Sentiment
                </InputLabel>
                <Select
                  labelId="new-topic-sentiment"
                  id="new-topic-sentiment"
                  value={newTopicSentiment}
                  onChange={(e) => {
                    setNewTopicSentiment(e.target.value);
                  }}
                  data-testid="sentiment-select"
                >
                  <MenuItem value={"POSITIVE"}>POSITIVE</MenuItem>
                  <MenuItem value={"NEGATIVE"}>NEGATIVE</MenuItem>
                  <MenuItem value={"NEUTRAL"}>NEUTRAL</MenuItem>
                </Select>
              </FormControl>
            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions>
          {dialogData.event === "delete" ? (
            <Button onClick={handleDialogDel} color="primary" autoFocus>
              Yes
            </Button>
          ) : (
            <Button
              onClick={handleOk}
              color="primary"
              autoFocus
              disabled={!inputValue || !newTopicSentiment || inputValueError}
            >
              Save
            </Button>
          )}
          <Button onClick={handleDialogClose} color="default">
            {dialogData.event === "delete" ? "No" : "Cancel"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React from 'react';
import Fullscreen from "react-full-screen";
import ReactHighstock from 'react-highcharts/ReactHighstock';
import { trendChart } from './ChartsConfig';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';

// Highcharts exporting
var HighchartsExporting = require('highcharts-exporting');
HighchartsExporting(ReactHighstock.Highcharts);
var Highcharts = require('highcharts/highstock'),
    HighchartsCustomEvents = require('highcharts-custom-events')(Highcharts);

class TrendChart extends React.Component {

  constructor(props) {
    super();

    this.state = {
      isFull: false,
    };
  }

  goFull = () => {
    this.setState({ isFull: !this.state.isFull });
  }

  render() {
    const { chartTitle, chartData } = this.props;

    const singleClick = event => { return null }

    const properties = trendChart({ drillDown: singleClick });

    const config = {
      ...properties,
      series: chartData
    };

    return <React.Fragment>
      <ErrorBoundary>
      <Fullscreen
        enabled={this.state.isFull}
        onChange={isFull => this.setState({ isFull })}
      >
        <div className="chartHolder">
          <div className="chartTitle">
            <div className="chartLabel">{chartTitle}</div>
            <div className="chartTitleActions">
              <Tooltip title="Full Screen" placement="left"><span>
                <IconButton
                  aria-label="Full Screen"
                  onClick={this.goFull}
                  disabled={chartData.length === 0}
                  data-testid="fullscreen"
                > <i className="fa fa-expand"></i></IconButton>
              </span></Tooltip>
            </div>
          </div>
          <div className="chartContent">
            {chartData.length === 0
              ? <p className="noData">NO DATA</p>
              : <ReactHighstock ref="chart" config={config} />
            }
          </div>
        </div>
      </Fullscreen>
      </ErrorBoundary>
    </React.Fragment>
  }
}

TrendChart.propTypes = {
  chartData: PropTypes.array.isRequired
}

export default (TrendChart);
import React from 'react'
import './Breadcrumb.css'

class Breadcrumb extends React.Component {
  handleBreadCrumbChange = (item) => {
    let isShared = item.id === '-1' ? true : null
    this.props.handleBreadCrum([item.id], isShared)
  }

  render() {
    const loop = (data) => {
      return data.map((item) => {
        return (
          <li
            className='breadcrumb'
            key={item.id}
            onClick={() => this.handleBreadCrumbChange(item)}
            data-id='breadcrumb1'
          >
            {item.name}
          </li>
        )
      })
    }

    return (
      <div>
        {this.props.data.length > 0 ? (
          <ul className='breadcrumbDiv'>{loop(this.props.data)}</ul>
        ) : (
          <ul></ul>
        )}
      </div>
    )
  }
}

export default Breadcrumb

import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import client from "../../../_helpers/client";
import { toast } from "react-toastify";
import AvailableOutBoundDB from "./AvailableOutBoundDS";
import {
  WARN,
  SUCCESS,
  EMPTY_STRING,
  OB_IP_ERROR_MESSAGE,
  OB_PASSWORD_ERROR_MESSAGE,
  OB_DATABASENAME_ERROR_MESSAGE,
  OB_PORT_ERROR_MESSAGE,
  OB_USERNAME_ERROR_MESSAGE,
  OB_SCHEMA_ERROR_MESSAGE,
  OB_WAREHOUSE_ERROR_MESSAGE,
} from "../../../Constants";
import {
  BRAND_OUTBOUND_DATASOURCES,
  SAVE_OUTBOUND_DATASOURCES,
  GET_PRODUCT_DETAILS,
  SEND_OUTBOUND_REQUEST
} from "../../../Routes";

import { get } from "lodash";

class OutBoundDS extends Component {
  state = {
    labelWidth: 0,
    visibleOutBoundBD: false,
    isDisabled: true,
    outBounddataSourceType: "noDatabase",
    dataSourceAvailable: [],
    ipaddress: EMPTY_STRING,
    port: "0000",
    username: EMPTY_STRING,
    databasename: EMPTY_STRING,
    password: EMPTY_STRING,
    schema: EMPTY_STRING,
    warehouse: EMPTY_STRING,
    ipaddressError: EMPTY_STRING,
    portError: EMPTY_STRING,
    usernameError: EMPTY_STRING,
    databasenameError: EMPTY_STRING,
    passwordError: EMPTY_STRING,
    schemaError: EMPTY_STRING,
    warehouseError: EMPTY_STRING,
    outboundRequestInProgressForId: null
  };

  getAvailableOutboundDB = () => {
    client
      .get(BRAND_OUTBOUND_DATASOURCES, {
        params: { brandId: this.props.brandId },
      })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          this.setState({
            ...this.state,
            dataSourceAvailable: res.data.response,
          });
        } else {
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === WARN)
            toast.warn(error.response.data.message);
          else toast.error(error.response.data.message);
        }
      });
  };

  componentDidMount() {
    this.getAvailableOutboundDB();
  }

  handleOutBoundDataSource = (e) => {
    if (e.target.value === "mysql") {
      this.setState({
        ...this.state,
        port: 3306,
        isDisabled: false,
        outBounddataSourceType: e.target.value,
        ipaddress: EMPTY_STRING,
        username: EMPTY_STRING,
        databasename: EMPTY_STRING,
        password: EMPTY_STRING,
        ipaddressError: EMPTY_STRING,
        portError: EMPTY_STRING,
        usernameError: EMPTY_STRING,
        databasenameError: EMPTY_STRING,
        passwordError: EMPTY_STRING,
        schemaError: EMPTY_STRING,
        warehouseError: EMPTY_STRING,
      });
    } else if (e.target.value === "postgres") {
      this.setState({
        ...this.state,
        port: 5423,
        isDisabled: false,
        outBounddataSourceType: e.target.value,
        ipaddress: EMPTY_STRING,
        username: EMPTY_STRING,
        databasename: EMPTY_STRING,
        password: EMPTY_STRING,
        ipaddressError: EMPTY_STRING,
        portError: EMPTY_STRING,
        usernameError: EMPTY_STRING,
        databasenameError: EMPTY_STRING,
        passwordError: EMPTY_STRING,
        schemaError: EMPTY_STRING,
        warehouseError: EMPTY_STRING,
      });
    } else if (e.target.value === "redshift") {
      this.setState({
        ...this.state,
        port: 5439,
        isDisabled: false,
        outBounddataSourceType: e.target.value,
        ipaddress: EMPTY_STRING,
        username: EMPTY_STRING,
        databasename: EMPTY_STRING,
        password: EMPTY_STRING,
        ipaddressError: EMPTY_STRING,
        portError: EMPTY_STRING,
        usernameError: EMPTY_STRING,
        databasenameError: EMPTY_STRING,
        passwordError: EMPTY_STRING,
        schemaError: EMPTY_STRING,
        warehouseError: EMPTY_STRING,
      });
    } else if (e.target.value === "snowflake") {
      this.setState({
        ...this.state,
        port: "0000",
        isDisabled: false,
        outBounddataSourceType: e.target.value,
        ipaddress: EMPTY_STRING,
        username: EMPTY_STRING,
        databasename: EMPTY_STRING,
        password: EMPTY_STRING,
        schema: EMPTY_STRING,
        warehouse: EMPTY_STRING,
        ipaddressError: EMPTY_STRING,
        portError: EMPTY_STRING,
        usernameError: EMPTY_STRING,
        databasenameError: EMPTY_STRING,
        passwordError: EMPTY_STRING,
        schemaError: EMPTY_STRING,
        warehouseError: EMPTY_STRING,
      });
    } else if (e.target.value === "mc3" || e.target.value === "fx360") { 
      client.get(GET_PRODUCT_DETAILS, { 
        params: { productName : e.target.value, dbType: "snowflake" }
       })
       .then(res => {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
          this.setState({
            ...this.state,
            isDisabled: false,
            outBounddataSourceType: e.target.value,
            username: get(res.data.response,"username"),
            databasename: get(res.data.response,"dbName"),
            password: get(res.data.response,"password"),
            schema: get(res.data.response,"schemaPrefix")+'_'+userDetails.id,
            warehouse: get(res.data.response,"warehouse") ? get(res.data.response,"warehouse") : "COMPUTE_WH",
            ipaddress: get(res.data.response,"dbUrl"),
          });            
        })
       .catch(error => {
          if(error.response) {
            toast.error(get(error.response.data,"message"))
          } else
          console.log(error)
        })
    } else {
      this.setState({
        ...this.state,
        port: "0000",
        isDisabled: true,
        outBounddataSourceType: e.target.value,
        ipaddress: EMPTY_STRING,
        username: EMPTY_STRING,
        databasename: EMPTY_STRING,
        password: EMPTY_STRING,
        ipaddressError: EMPTY_STRING,
        portError: EMPTY_STRING,
        usernameError: EMPTY_STRING,
        databasenameError: EMPTY_STRING,
        passwordError: EMPTY_STRING,
        schemaError: EMPTY_STRING,
        warehouseError: EMPTY_STRING,
      });
    }
  };

  toggleDataSource = () => {
    this.setState({
      ...this.state,
      visibleOutBoundBD: !this.state.visibleOutBoundBD,
      outBounddataSourceType: "noDatabase",
      ipaddress: EMPTY_STRING,
      port: "0000",
      username: EMPTY_STRING,
      databasename: EMPTY_STRING,
      password: EMPTY_STRING,
      schema: EMPTY_STRING,
      warehouse: EMPTY_STRING,
      isDisabled: true,
      ipaddressError: EMPTY_STRING,
      portError: EMPTY_STRING,
      usernameError: EMPTY_STRING,
      databasenameError: EMPTY_STRING,
      passwordError: EMPTY_STRING,
      schemaError: EMPTY_STRING,
      warehouseError: EMPTY_STRING,
    });
  };

  handleChange = (event, elem) => {
    let val = event.target.value;
    switch (elem) {
      case "ipaddress":
        if (val.length > 0)
          this.setState({ ipaddress: val, ipaddressError: EMPTY_STRING });
        else
          this.setState({
            ipaddress: val,
            ipaddressError: OB_IP_ERROR_MESSAGE,
          });
        break;
      case "port":
        if (val !== EMPTY_STRING)
          this.setState({ port: val, portError: EMPTY_STRING });
        else this.setState({ port: val, portError: OB_PORT_ERROR_MESSAGE });
        break;
      case "username":
        if (val !== EMPTY_STRING)
          this.setState({ username: val, usernameError: EMPTY_STRING });
        else
          this.setState({
            username: val,
            usernameError: OB_USERNAME_ERROR_MESSAGE,
          });
        break;
      case "databasename":
        if (val !== EMPTY_STRING)
          this.setState({ databasename: val, databasenameError: EMPTY_STRING });
        else
          this.setState({
            databasename: val,
            databasenameError: OB_DATABASENAME_ERROR_MESSAGE,
          });
        break;
      case "password":
        if (val !== EMPTY_STRING)
          this.setState({ password: val, passwordError: EMPTY_STRING });
        else
          this.setState({
            password: val,
            passwordError: OB_PASSWORD_ERROR_MESSAGE,
          });
        break;
      case "schema":
        if (val !== EMPTY_STRING)
          this.setState({ schema: val, schemaError: EMPTY_STRING });
        else
          this.setState({ schema: val, schemaError: OB_SCHEMA_ERROR_MESSAGE });
        break;
      case "warehouse":
        if (val !== EMPTY_STRING)
          this.setState({ warehouse: val, warehouseError: EMPTY_STRING });
        else
          this.setState({
            warehouse: val,
            warehouseError: OB_WAREHOUSE_ERROR_MESSAGE,
          });
        break;
      default:
        break;
    }
  };

  uploadDataDetails = () => {
    const {
      outBounddataSourceType,
      ipaddress,
      username,
      password,
      databasename,
      port,
      schema,
      warehouse,
    } = this.state;

    if (ipaddress === EMPTY_STRING)
      this.setState({ ipaddressError: OB_IP_ERROR_MESSAGE });
    else this.setState({ ipaddressError: EMPTY_STRING });

    if (username === EMPTY_STRING)
      this.setState({ usernameError: OB_USERNAME_ERROR_MESSAGE });
    else this.setState({ usernameError: EMPTY_STRING });

    if (password === EMPTY_STRING)
      this.setState({ passwordError: OB_PASSWORD_ERROR_MESSAGE });
    else this.setState({ passwordError: EMPTY_STRING });

    if (databasename === EMPTY_STRING)
      this.setState({ databasenameError: OB_DATABASENAME_ERROR_MESSAGE });
    else this.setState({ databasenameError: EMPTY_STRING });

    if (port === EMPTY_STRING)
      this.setState({ portError: OB_PORT_ERROR_MESSAGE });
    else this.setState({ portError: EMPTY_STRING });

    if (outBounddataSourceType === "snowflake" || outBounddataSourceType === "mc3" || outBounddataSourceType === "fx360") {
      if (schema === EMPTY_STRING)
        this.setState({ schemaError: OB_SCHEMA_ERROR_MESSAGE });
      else this.setState({ schemaError: EMPTY_STRING });

      if (warehouse === EMPTY_STRING)
        this.setState({ warehouseError: OB_WAREHOUSE_ERROR_MESSAGE });
      else this.setState({ warehouseError: EMPTY_STRING });

      if (
        ipaddress === EMPTY_STRING ||
        username === EMPTY_STRING ||
        password === EMPTY_STRING ||
        databasename === EMPTY_STRING ||
        port === EMPTY_STRING ||
        schema === EMPTY_STRING ||
        warehouse === EMPTY_STRING
      )
        return;
    } else {
      if (
        ipaddress === EMPTY_STRING ||
        username === EMPTY_STRING ||
        password === EMPTY_STRING ||
        databasename === EMPTY_STRING ||
        port === EMPTY_STRING
      )
        return;
    }

    console.log("outBounddataSourceType, ",outBounddataSourceType)
    const data = new FormData();
    data.append("brandId", this.props.brandId);
    data.append("db_ip", ipaddress);
    data.append("db_username", username);
    data.append("db_password", password);
    data.append("db_name", databasename);
    data.append("db_port", port);
    data.append("db_type", (outBounddataSourceType === "mc3" || outBounddataSourceType === "fx360") 
                            ? outBounddataSourceType+"_snowflake" : outBounddataSourceType);

    if (outBounddataSourceType === "snowflake" || outBounddataSourceType === "mc3" || outBounddataSourceType === "fx360") {
      data.append("db_schema", schema);
      data.append("db_warehouse", warehouse);
    }

    client
      .post(SAVE_OUTBOUND_DATASOURCES, data)
      .then((res) => {
        if (res.data.status === SUCCESS) {
          this.setState({
            ...this.state,
            dataSourceAvailable: res.data.response,
          });
          this.toggleDataSource();
          toast.success(res.data.message);
        } else {
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error("Outbound details are invalid.");
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error("Outbound details are invalid.") 
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  updatedData = (d) => {
    if (d) this.getAvailableOutboundDB();
  };

  handleOutboundRequest = async (sourceId) => {
    try {
      this.setState((state) => ({
        ...state,
        outboundRequestInProgressForId: sourceId
      }));
      const { data } = await client.get(SEND_OUTBOUND_REQUEST, { params : {
        brandId: this.props.brandId,
        id: sourceId
      }});
      this.setState((state) => ({
        ...state,
        dataSourceAvailable: data.response,
        outboundRequestInProgressForId: null
      }));
      toast.success('Request submitted successfully.');
    } catch (err) {
      console.log(err);
      toast.error('Could not submit the request.');
      this.setState((state) => ({
        ...state,
        outboundRequestInProgressForId: null
      }));
    }
  }

  render() {
    const { outBounddataSourceType } = this.state;
    const isProductDisabled = (outBounddataSourceType === "mc3" || outBounddataSourceType === "fx360")

    return (
      <Grid container className="rowGrid">
        <Grid item xs={this.state.visibleOutBoundBD ? 6 : 10} className="grid">
          <div className="newButton">
            <Button
              variant="contained"
              onClick={this.toggleDataSource}
              className="btn btn-primary"
            >
              New
            </Button>
          </div>
          {this.state.dataSourceAvailable.length ? (
            <AvailableOutBoundDB
              data={this.state.dataSourceAvailable}
              updatedData={this.updatedData}
              brandStatus={this.props.brandStatus}
              brandId={this.props.brandId}
              handleOutboundRequest={this.handleOutboundRequest}
              outboundProgressId={this.state.outboundRequestInProgressForId}
            />
          ) : (
            <p className="noDataMessage">No outbound data source added</p>
          )}
        </Grid>

        {this.state.visibleOutBoundBD && (
          <Grid item xs={6} className="grid">
            <div className="outboundDatabase">
              <h3>Select Database Type</h3>
              <FormControl variant="outlined" className="formControl">
                <InputLabel
                  shrink
                  ref={(ref) => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="outBounddbType"
                ></InputLabel>
                <Select
                  value={outBounddataSourceType}
                  onChange={this.handleOutBoundDataSource}
                  input={
                    <OutlinedInput
                      labelWidth={this.state.labelWidth}
                      id="outBounddbType"
                    />
                  }
                >
                  <MenuItem value="noDatabase">No Database Selected</MenuItem>
                  <MenuItem value="mysql">MYSQL</MenuItem>
                  <MenuItem value="postgres">PostgreSQL</MenuItem>
                  <MenuItem value="redshift">RedShift</MenuItem>
                  <MenuItem value="snowflake">Snowflake</MenuItem>
                  <MenuItem value="mc3">MC3 Snowflake</MenuItem>
                  <MenuItem value="fx360">FX360 Snowflake</MenuItem>
                </Select>
              </FormControl>
            </div>

            <Paper elevation={0}>
              <div className="outboundDBoptions">
                <TextField
                  autoComplete="off"
                  fullWidth
                  required
                  id="ipaddress"
                  name="ipaddress"
                  label="IP Address"
                  margin="normal"
                  value={this.state.ipaddress}
                  onChange={(e) => this.handleChange(e, "ipaddress")}
                  disabled={this.state.isDisabled || isProductDisabled}
                  inputProps={{ "data-testid": "ipInput" }}
                />
                {this.state.ipaddressError !== EMPTY_STRING ? (
                  <span className="errorMsg">{this.state.ipaddressError}</span>
                ) : null}
                {(outBounddataSourceType === "snowflake" || outBounddataSourceType === "mc3" || outBounddataSourceType === "fx360") ? null: 
                <TextField
                  autoComplete="off"
                  fullWidth
                  required
                  id="port"
                  name="port"
                  label="Port"
                  margin="normal"
                  value={this.state.port}
                  onChange={(e) => this.handleChange(e, "port")}
                  disabled={this.state.isDisabled}
                  inputProps={{ "data-testid": "portInput" }}
                />
            } 
                {this.state.portError !== EMPTY_STRING ? (
                  <span className="errorMsg">{this.state.portError}</span>
                ) : null}

                <TextField
                  autoComplete="off"
                  fullWidth
                  required
                  id="username"
                  name="username"
                  label="User Name"
                  margin="normal"
                  value={this.state.username}
                  onChange={(e) => this.handleChange(e, "username")}
                  disabled={this.state.isDisabled || isProductDisabled}
                  inputProps={{ "data-testid": "userNameInput" }}
                />
                
                {this.state.usernameError !== EMPTY_STRING ? (
                  <span className="errorMsg">{this.state.usernameError}</span>
                ) : null}
                <TextField
                  autoComplete="off"
                  fullWidth
                  required
                  id="databasename"
                  name="databasename"
                  label="Database Name"
                  margin="normal"
                  value={this.state.databasename}
                  onChange={(e) => this.handleChange(e, "databasename")}
                  disabled={this.state.isDisabled || outBounddataSourceType === "mc3" || outBounddataSourceType === "fx360"}
                  inputProps={{ "data-testid": "dbNameInput" }}
                />
                {this.state.databasenameError !== EMPTY_STRING ? (
                  <span className="errorMsg">
                    {this.state.databasenameError}
                  </span>
                ) : null}
                <TextField
                  autoComplete="off"
                  fullWidth
                  required
                  type="password"
                  id="dbpassword"
                  name="dbpassword"
                  label="Password"
                  margin="normal"
                  value={this.state.password}
                  onChange={(e) => this.handleChange(e, "password")}
                  disabled={this.state.isDisabled || isProductDisabled}
                  inputProps={{ "data-testid": "dbPasswordInput" }}
                />
                {this.state.passwordError !== EMPTY_STRING ? (
                  <span className="errorMsg">{this.state.passwordError}</span>
                ) : null}
                {(outBounddataSourceType === "snowflake" || outBounddataSourceType === "mc3" || outBounddataSourceType === "fx360") ? (
                  <div>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      required
                      type="text"
                      id="dbschema"
                      name="dbschema"
                      label="Schema"
                      margin="normal"
                      value={this.state.schema}
                      onChange={(e) => this.handleChange(e, "schema")}
                      disabled={this.state.isDisabled}
                      inputProps={{ "data-testid": "dbschema" }}
                    />
                    {this.state.passwordError !== EMPTY_STRING ? (
                      <span className="errorMsg">{this.state.schemaError}</span>
                    ) : null}

                    <TextField
                      autoComplete="off"
                      fullWidth
                      required
                      type="text"
                      id="dbwarehouse"
                      name="dbwarehouse"
                      label="Warehouse"
                      margin="normal"
                      value={this.state.warehouse}
                      onChange={(e) => this.handleChange(e, "warehouse")}
                      disabled={this.state.isDisabled || isProductDisabled}
                      inputProps={{ "data-testid": "dbwarehouse" }}
                    />
                    {this.state.passwordError !== EMPTY_STRING ? (
                      <span className="errorMsg">
                        {this.state.warehouseError}
                      </span>
                    ) : null}
                  </div>
                ) : null}

                <div className="formBtnHolder">
                  <Button
                    className="btn btn-primary"
                    disabled={this.state.isDisabled}
                    onClick={this.uploadDataDetails}
                  >
                    Save
                  </Button>
                  <Button
                    className="btn btn-default"
                    onClick={this.toggleDataSource}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Paper>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default OutBoundDS;

import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Slider from '@material-ui/core/Slider'
import Tooltip from '@material-ui/core/Tooltip'
import InputAdornment from '@material-ui/core/InputAdornment'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import { Info, CloudUpload, Cancel } from '@material-ui/icons'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { EMPTY_STRING, SUCCESS } from '../../../Constants'
import {
  AVAILABLE_SEED_FILE,
  GET_PIPELINE_PARAMS,
  UPLOAD_SEED_FILE,
  REMOVE_SEED_FILE,
} from '../../../Routes'
import client from '../../../_helpers/client'
import { toast } from 'react-toastify'
import SeedFileSample from '../../../assets/images/seed_file_sample.png'
import PipelineParams from '../../ATD/rightside-drawer/pipeline-params'
import  { getFileType } from '../../../_helpers/utils'
import  { seedFileParser } from '../../ATD/atd-utils'

const PrettoSlider = withStyles({
  root: {
    color: '#2196F3',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider)

const useStyles = makeStyles((theme) => ({
  cancelIcon: {
    color: '#ea6a6a',
  },
  cancelIconDisabled: {
    color: '#bbbbbb !important',
    cursor: 'initial !important',
    pointerEvents: 'none',
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  seedSampleText: {
    fontSize: '14px',
    fontStyle: 'italic',
  },
  fileCaption: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  formControl: {
    minWidth: 100,
    '&.MuiInputBase-root': {
      fontSize: '14px !important',
    },
  },
}))

const ATDAccordian = ({
  brandId,
  thresholdValue,
  topicDiscoveryOption,
  handleTopicDiscoveryDetails,
  handleThresholdValueDetails,
  pipeline,
  updatePipeline,
  handleSeedFile,
  seedFileAvailable,
  semanticSimilarity,
  frequencyPercentile,
  tags,
  phraseMinWords,
  phraseMaxWords,
  ignoreSentiment,
  ngramFrequency,
  wordLengthMin,
  wordLengthMax,
  setSemanticSimilarity,
  setfrequencyPercentile,
  documentLimit,
  setDocumentLimit,
  setTags,
  setPhraseMinWords,
  setPhraseMaxWords,
  setIgnoreSentiment,
  setNgramFrequency,
  setWordLengthMin,
  setWordLengthMax,
}) => {
  const [csvFile, setCsvFile] = useState({
    file: EMPTY_STRING,
    name: EMPTY_STRING,
  })
  const [seedFileExists, setSeedFileExists] = useState(false)
  const [fileUploading, setFileUploading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [seeMore, setSeeMore] = useState(false)

  const classes = useStyles()

  const handleThreshold = (e, val) => {
    handleThresholdValueDetails(Number(val.toFixed(2)))
  }

  const handleFileInput = async (e) => {
    e.stopPropagation()
    let inputFile = e.target?.files[0];

    const fileType = getFileType(inputFile)
    if(fileType !== "csv") {
      toast.warn("Accepts only .csv file")
      return
    }
    if (inputFile !== undefined) {
      try {
        const verifySeed = await seedFileParser(inputFile, true);
        if(verifySeed) {
          setCsvFile({
            file: inputFile,
            name: inputFile?.name,
          })
          uploadFile(inputFile);
        }
      } catch(err) {
        toast.error('Uploaded file is not in correct format. Please see sample seed file.')
        return
      }  
    } else {
      setCsvFile({
        file: EMPTY_STRING,
        name: EMPTY_STRING,
      })
    }
  }

  const removeSelectedFile = () => {
    setCsvFile({
      file: EMPTY_STRING,
      name: EMPTY_STRING,
      error: EMPTY_STRING,
    })
  }

  const removeSeedFile=()=> {
    client.get(REMOVE_SEED_FILE, { params : { brandId: brandId } }).then(res=> {
      if(res.data.status === SUCCESS) {
        setSeedFileExists(false)
        removeSelectedFile();
      }
    }).catch(err => { console.log(err)})
  }

  const uploadFile = async (file) => {
    const data = new FormData()
    data.append('brandId', brandId)
    data.append('dataFile', file??csvFile.file)

    setFileUploading(true)
    try {
      const res = await client.post(UPLOAD_SEED_FILE, data)
      if (res.data.status === SUCCESS) {
        setSeedFileExists(true)
        setCsvFile({
          name: res.data.response.seed_file_name,
        })
        handleSeedFile(res.data.response.seed_file_path)
      } else {
        toast.error(res.data.message)
      }
    } catch (error) {
      if (error.response) toast.error(error.response.data.message)
    }
    setFileUploading(false)
  }

  const checkSeedFileExists = async () => {
    try {
      const res = await client.get(AVAILABLE_SEED_FILE, { params: { brandId } })
      if (res.data.status === SUCCESS) {
        if (res.data.response && res.data.response.seed_file_name) {
          setSeedFileExists(true)
          setCsvFile({
            name: res.data.response.seed_file_name,
          })
          handleSeedFile(res.data.response.seed_file_path)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const fetchATDParameters = () => {
    client
      .get(GET_PIPELINE_PARAMS, {
        params: {
          brandId,
        },
      })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          const parameters = res.data.response
          updatePipeline(parameters.pipeline)
          if (parameters.threshold)
            handleThresholdValueDetails(parameters.threshold)
          if (parameters.semanticSimilarity)
            setSemanticSimilarity(parameters.semanticSimilarity)
          if (parameters.ngramFrequency)
            setNgramFrequency(parameters.ngramFrequency)
          if (parameters.wordLengthMax)
            setWordLengthMax(parameters.wordLengthMax)
          if (parameters.wordLengthMin)
            setWordLengthMin(parameters.wordLengthMin)
          if (parameters.ignoreSentimentMinRange || parameters.ignoreSentimentMaxRange)
            setIgnoreSentiment([
              parameters.ignoreSentimentMinRange ? parameters.ignoreSentimentMinRange : 0, 
              parameters.ignoreSentimentMaxRange ? parameters.ignoreSentimentMaxRange : 0
            ])
          if (parameters.pipeline === 'Decooda1') {
            if (parameters.frequencyPercentile)
              setfrequencyPercentile(parameters.frequencyPercentile)
          } else {
            if (parameters.posFocusTags) setTags(parameters.posFocusTags)
            if (parameters.phraseMaxWords)
              setPhraseMaxWords(parameters.phraseMaxWords)
            if (parameters.phraseMinWords)
              setPhraseMinWords(parameters.phraseMinWords)
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (seedFileAvailable === undefined) {
      checkSeedFileExists()
      fetchATDParameters()
    } else if (seedFileAvailable.length) {
      setSeedFileExists(true)
      setCsvFile({
        name: seedFileAvailable.split('/').reverse()[0],
      })
    }
  }, [])

  const threshholdInfo = `Phrases are assigned to Topics if the ML/AI is atleast x% confident of the association. A very high confidence threshold would lead to very few phrases assigned to topics and a very low threshold would do the opposite`

  const open = Boolean(anchorEl)

  return (
    <div id='atd-panel' className='flexGrow-01'>
      <Grid container className='rowGrid' style={{marginBottom:'0px'}}>
        <Grid item xs={12} sm={6} md={6} className='innerGrid'>
          <div className='topicDiscovery'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={topicDiscoveryOption==="enable"}
                  onChange={() => handleTopicDiscoveryDetails('enable')}
                  value="enable"
                />
              }
              label='Enable ATD'
            />
            {topicDiscoveryOption==="enable" && (
              <FormControl className='decooda-pipeline-select'>
                <InputLabel id='decooda-pipeline'>Pipeline</InputLabel>
                <Select
                  labelId='decooda-pipeline-label'
                  id='decooda-pipeline'
                  defaultValue='Decooda1'
                  value={pipeline}
                  onChange={(e) => updatePipeline(e.target.value)}
                >
                  <MenuItem value='Decooda1'>Decooda1</MenuItem>
                  <MenuItem value='Decooda2'>Decooda2</MenuItem>
                </Select>
              </FormControl>
            )}
          </div>
          {topicDiscoveryOption==="enable" &&
            (seedFileExists ? (
              <div className='seedFile'>
                <div className='seedFileTable'>
                  <table>
                    <thead>
                      <tr>
                        <th>Uploaded Seed File</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{csvFile.name}</td>
                        <td><div className='opts'>
                        <Tooltip title='Remove File'>
                          <Cancel
                            className="cancelIcon"
                            onClick={removeSeedFile}
                          />
                        </Tooltip>
                      </div></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className='atdSeedFileUpload'>
                <div className='atdSeedFileUploadInput'>
                  <div className='fileRoot'>
                    <TextField
                      type='text'
                      label=''
                      name='fileDetails'
                      id='fileDetails'
                      margin='normal'
                      placeholder='Include a seed file'
                      accept=".csv,text/csv"
                      fullWidth
                      value={csvFile.name}
                      disabled={seedFileExists || fileUploading}
                      InputProps={{
                        endAdornment: !csvFile.name ? (
                          <InputAdornment position='start'>
                            <CloudUpload />
                          </InputAdornment>
                        ) : null,
                      }}
                    />
                    {!seedFileExists && csvFile.name !== EMPTY_STRING ? (
                      <div className='opts'>
                        <Tooltip title='Remove File'>
                          <Cancel
                            className={
                              classes[
                                fileUploading
                                  ? 'cancelIconDisabled'
                                  : 'cancelIcon'
                              ]
                            }
                            onClick={removeSelectedFile}
                          />
                        </Tooltip>
                      </div>
                    ) : null}
                  </div>
                  <TextField
                    type='file'
                    label=''
                    title=''
                    margin='normal'
                    fullWidth
                    accept=".csv,text/csv"
                    value={EMPTY_STRING}
                    disabled={seedFileExists || fileUploading}
                    onChange={handleFileInput}
                    className={'hidden-input'}
                    inputProps={{ 'data-testid': 'seedfileInput' }}
                  />
                </div>
                <div className='atdUploadFooter'>
                  <div className='seedFileSample'>
                    <Typography
                      aria-owns={open ? 'mouse-over-popover' : undefined}
                      aria-haspopup='true'
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                      className={classes.seedSampleText}
                    >
                      see sample seed file?
                    </Typography>
                    <Popover
                      id='mouse-over-popover'
                      className={classes.popover}
                      classes={{
                        paper: classes.paper,
                      }}
                      open={open}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                      }}
                      onClose={handlePopoverClose}
                      disableScrollLock
                    >
                      <img
                        src={SeedFileSample}
                        width='600px'
                        height='auto'
                        alt='Seed file sample'
                      />
                      <Typography className={classes.fileCaption}>
                        Sample Seed File
                      </Typography>
                    </Popover>
                  </div>
                </div>
              </div>
            ))}
        </Grid>
        <Grid item xs={12} sm={6} md={6} className='innerGrid'>
          {topicDiscoveryOption==="enable" && (
            <div className='innerDivision'>
              <div className='sliderDiv'>
                <div className='thresholdSliderTitle'>
                  <span>Confidence Score Threshold</span>
                  <Tooltip title={threshholdInfo} id='threshhold-info'>
                    <Info />
                  </Tooltip>
                </div>
                <div className='sliderHolder'>
                  <span className='sliderMin'>0</span>
                  <span className='sliderMax'>1</span>
                  <PrettoSlider
                    step={0.01}
                    min={0}
                    max={1}
                    valueLabelDisplay='auto'
                    aria-label='threshold slider'
                    value={thresholdValue}
                    onChange={handleThreshold}
                    data-testid='thresholdSlider'
                  />
                </div>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
      {topicDiscoveryOption==="enable" && (
        <Box width='50%' marginLeft='auto' paddingX={2}>
          <div style={{ textAlign: 'right' }}>
            <Button
              variant='text'
              color='default'
              onClick={() => setSeeMore((state) => !state)}
              style={{ padding: 0 }}
              disableRipple
              disableFocusRipple
            >
              Set More Parameters
              {seeMore ? (
                <ArrowDropDownIcon fontSize='large' color='primary' />
              ) : (
                <ArrowRightIcon fontSize='large' color='primary' />
              )}
            </Button>
          </div>
          <Collapse in={seeMore} timeout='auto' unmountOnExit>
            <Box paddingX={1} mt={2}>
              <PipelineParams
                pipeline={pipeline}
                threshold={thresholdValue}
                isDisabled={false}
                frequencyPercentile={frequencyPercentile}
                setfrequencyPercentile={setfrequencyPercentile}
                documentLimit ={documentLimit}
                setDocumentLimit = {setDocumentLimit}
                semanticSimilarity={semanticSimilarity}
                setSemanticSimilarity={setSemanticSimilarity}
                tags={tags}
                setTags={setTags}
                phraseMinWords={phraseMinWords}
                setPhraseMinWords={setPhraseMinWords}
                phraseMaxWords={phraseMaxWords}
                setPhraseMaxWords={setPhraseMaxWords}
                ngramFrequency={ngramFrequency}
                setNgramFrequency={setNgramFrequency}
                wordLengthMin={wordLengthMin}
                setWordLengthMin={setWordLengthMin}
                wordLengthMax={wordLengthMax}
                setWordLengthMax={setWordLengthMax}
                ignoreSentiment={ignoreSentiment}
                setIgnoreSentiment={(e,newVal)=>{
                  setIgnoreSentiment(newVal)
                }}
                handleThresholdPipelineValues={(val,param)=>{
                  if(param ==="threshold") {
                    handleThresholdValueDetails(val)
                  }

                  if(param === "pipeline") {
                    updatePipeline(val)
                  }

                }}
                topicDiscoveryOption={topicDiscoveryOption}
              />
            </Box>
          </Collapse>
        </Box>
      )}
      <div className="atdOptions">
        <div><FormControlLabel
          control={
            <Checkbox
              checked={topicDiscoveryOption==="blank"}
              onChange={() => handleTopicDiscoveryDetails('blank')}
              value='blank'
            />
          }
          label='Create blank ATD project'
        />
        <Collapse in={topicDiscoveryOption === 'blank'} timeout='auto' unmountOnExit>
          <Box width='50%' paddingX={2} mt={2}>
            <PipelineParams
              pipeline={pipeline}
              threshold={thresholdValue}
              isDisabled={false}
              frequencyPercentile={frequencyPercentile}
              setfrequencyPercentile={setfrequencyPercentile}
              documentLimit ={documentLimit}
              setDocumentLimit = {setDocumentLimit}
              semanticSimilarity={semanticSimilarity}
              setSemanticSimilarity={setSemanticSimilarity}
              tags={tags}
              setTags={setTags}
              phraseMinWords={phraseMinWords}
              setPhraseMinWords={setPhraseMinWords}
              phraseMaxWords={phraseMaxWords}
              setPhraseMaxWords={setPhraseMaxWords}
              ngramFrequency={ngramFrequency}
              setNgramFrequency={setNgramFrequency}
              wordLengthMin={wordLengthMin}
              setWordLengthMin={setWordLengthMin}
              wordLengthMax={wordLengthMax}
              setWordLengthMax={setWordLengthMax}
              ignoreSentiment={ignoreSentiment}
              setIgnoreSentiment={(e,newVal)=>{
                setIgnoreSentiment(newVal)
              }}
              handleThresholdPipelineValues={(val,param)=>{
                if(param ==="threshold") {
                  handleThresholdValueDetails(val)
                }

                if(param === "pipeline") {
                  updatePipeline(val)
                }

              }}
              topicDiscoveryOption={topicDiscoveryOption}
            />
          </Box>
        </Collapse>
        </div>
        <div><FormControlLabel
          control={
            <Checkbox
              checked={topicDiscoveryOption==="disable"}
              onChange={() => handleTopicDiscoveryDetails('disable')}
              value='disable'
            />
          }
          label='Disable ATD'
        /></div>
      </div>
    </div>
  )
}

export default ATDAccordian

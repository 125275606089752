import { SELECT_PALETTE_TYPE } from '../actions/types'
import get from 'lodash.get'


const initialState = "p1" 

export default function TopicPaletteReducer(state = initialState, action) {
  const selectedId = get(action, 'selectedId')

  switch (action.type) {
    case SELECT_PALETTE_TYPE  : {
      return selectedId 
    }
    default: {
      return state;
    }
  }
}
import React, {Component} from 'react'
import Plot from './components/ScatterPlot/scatterPlot'
import client from "../../_helpers/client";
import {GET_BRAND_IMPACT_ANALYSIS} from "../../Routes";
import {Grid} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FrequencyAnalysis from "./FrequencyAnalysis";
import ImpactAnalysisExport from "./ImpactAnaysisExport";


class ImpactAnalysis extends Component {

    state = {
        labelWidth:0,
        sentiment: 'positive',
        iaDataJson:{},
        impactAnalysisData: {},
        frequencyAnalysisData:{},
        brandId: null,
        brandName:null,
        analysis: ['Impact Analysis', 'Frequency Analysis'],
        selectedAnalysis:'Impact Analysis',
        selectedThreshold: '5'
    }

    toggleSentiment = () => {
        const {sentiment} = this.state
        this.setState({
            sentiment: sentiment === 'positive' ? 'negative' : 'positive'
        })
    }

    componentDidMount() {
        const brandDetails = JSON.parse(localStorage.getItem("defaultSelectedBrand"));
        if (brandDetails) {
            this.getImpactData(brandDetails.id)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const brandDetails = JSON.parse(localStorage.getItem("defaultSelectedBrand"));
        if (brandDetails) {
            if (brandDetails.id !== this.state.brandId) {
                this.getImpactData(brandDetails.id)
            }
        }
    }

    correctThresholdPrefix = (threshold) => {
        if (threshold == '0' || threshold == '5') {
            return 'threshold_0' + threshold;
        } else {
            return 'threshold_' + threshold;
        }
    }

    getImpactData = async (brandId) => {
        try {
            const {data} = await client.get(GET_BRAND_IMPACT_ANALYSIS, {params: {brandId}})
            const brandName = data.brand_name
            console.log("brandName " + brandName)
            const selectedThresholdData = data.impact_scores[this.correctThresholdPrefix(this.state.selectedThreshold)]
            this.setState({brandName:brandName, brandId: brandId,iaDataJson:data.impact_scores, impactAnalysisData: selectedThresholdData, frequencyAnalysisData:data.top_topics})
        } catch (error) {
            console.log(error)
        }
    }


    changeAnalysisOrThreshold = (fieldName, e) => {
        if (fieldName == 'selectedThreshold'){
            const selectedThresholdData = this.state.iaDataJson[this.correctThresholdPrefix(e.target.value)]
            this.setState({
                ...this.state,
                selectedThreshold: e.target.value,
                impactAnalysisData: selectedThresholdData
            });
        }else{
            this.setState({
                selectedAnalysis: e.target.value
            });
        }
    };

    render() {
        return (
            <div>
                <Grid container spacing={4} >
                    <Grid item xs={3}>
                        <FormControl className="selectFormControl">
                            <InputLabel htmlFor="analysisType">SELECT ANALYSIS: </InputLabel>
                            <Select
                                value={this.state.selectedAnalysis}
                                onChange={e => this.changeAnalysisOrThreshold( 'selectedAnalysis',e)}
                                input={<Input name="analysisType" id="analysisType" />}
                            >
                                <MenuItem value="Impact Analysis">Impact Analysis</MenuItem>
                                <MenuItem value="Frequency Analysis">Frequency Analysis</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {this.state.selectedAnalysis === 'Impact Analysis' ?
                        (<Grid item xs={3}>
                            <FormControl className="selectFormControl">
                                <InputLabel htmlFor="selectThreshold">SELECT THRESHOLD: </InputLabel>
                                <Select
                                    value={this.state.selectedThreshold}
                                    onChange={e => this.changeAnalysisOrThreshold('selectedThreshold', e)}
                                    input={<Input name="selectThreshold" id="selectThreshold"/>}
                                >
                                    <MenuItem value="0">0</MenuItem>
                                    <MenuItem value="5">5</MenuItem>
                                    <MenuItem value="10">10</MenuItem>
                                    <MenuItem value="15">15</MenuItem>
                                    <MenuItem value="20">20</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>):null
                    }
                    <Grid item xs={3}>
                        <ImpactAnalysisExport selectedThreshold= {this.state.selectedThreshold} brandName={this.state.brandName} iaDataJson={this.state.iaDataJson} frequencyAnalysisData={this.state.frequencyAnalysisData}/>
                    </Grid>
                    <Grid item xs={11}>
                        {
                            this.state.brandId === null ? (<h1> Please select the brand </h1>) :
                                this.state.selectedAnalysis === 'Impact Analysis' ?
                                (<Plot impactAnalysisData={this.state.impactAnalysisData} toggleSentiment={this.toggleSentiment}/>):
                                    (<FrequencyAnalysis frequencyAnalysisData={this.state.frequencyAnalysisData} />)
                        }
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default ImpactAnalysis

import React from "react";

export default function ExportWarning({isAdmin}) {
    return (
        <>
            {isAdmin ? (
                <div>
                    The Dataset you selected has been flagged as containing sensitive information<br/>
                    Are you sure you wish to export this data ?
                </div>
            ) : (
                <div className="brandExportOptions">
                    <h4
                        style={{
                            margin: "0px 0px 5px 0px",
                            fontWeight: "500",
                            color: "rgb(0 0 0 / .5)",
                        }}
                    >
                        This dataset has been flagged as containing sensitive information <br/>
                        and can only be exported by an administrator.
                    </h4>
                </div>)
            }
        </>
    )
};


import React, { Fragment, useState, useEffect } from "react";
import { ReactSortable } from "react-sortablejs";
import { withStyles } from "@material-ui/core/styles";
import { Menu, MenuItem, Button, IconButton } from "@material-ui/core";
import { Favorite, ArrowForward } from "@material-ui/icons";
import { ArrowDropDown } from "@material-ui/icons";
import { SUCCESS, ERROR_UNABLE, EMPTY_ARRAY } from "../../../Constants";
import { SET_FAVORITE_PATTERN } from "../../../Routes";
import client from "../../../_helpers/client";
import { toast } from "react-toastify";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={8}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

export default function DropdownPhrasePattern({
  id,
  label,
  value,
  favoritePatterns,
  updateFavPattern,
  handlePatternfilter,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [fav, setFav] = useState(false);
  const [phrasePattern, setPhrasePattern] = useState([]);

  const unSelectedPatterns = [
    "cconj",
    "num",
    "det",
    "pron",
    "x",
    "adp",
    "adj",
    "aux",
    "noun",
    "propn",
    "part",
    "adv",
    "sym",
    "intj",
    "verb",
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const addtoPhrasePattern = (item) => {
    try {
      const el = {
        id: Math.floor(Math.random() * Math.floor(1000)),
        name: item,
      };
      const ud = phrasePattern.concat(el);
      setPhrasePattern(ud);
    } catch (err) {
      console.log(err);
    }
  };

  const removeFromPhrasePattern = (item) => {
    const fl = phrasePattern.filter((el) => el.id !== item.id);
    setPhrasePattern(fl);
    setFav(false);
  };

  const addToSelectedPattern = (item) => {
    try {
      const us = item.pattern.split(",").map((el) => {
        return { id: Math.floor(Math.random() * Math.floor(1000)), name: el };
      });
      setPhrasePattern(EMPTY_ARRAY.concat(us));
    } catch (err) {
      console.log(err);
    }
  };

  const addToFavourite = () => {
    try {
      let isExists = false;
      const p = phrasePattern.map((el) => el.name);
      favoritePatterns.forEach((el) => {
        if (el.pattern === p.toString()) {
          isExists = true;
        }
      });
      if (isExists) {
        toast.warn("Favourite pattern already exists");
      } else {
        //Add selected pattern as favourite pattern
        client
          .get(SET_FAVORITE_PATTERN, { params: { pattern: p.toString() } })
          .then((res) => {
            if (res.data.status === SUCCESS) {
              if (res.data.response) {
                setFav(true);
                const favObj = { pattern: p.toString(), id: res.data.id };
                const uf = favoritePatterns.concat(favObj);
                updateFavPattern(uf);
              } else {
                toast.error(res.data.message);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error(ERROR_UNABLE);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  //Reset Phrase Patterns
  const resetPhrasePattern = () => {
    setPhrasePattern(EMPTY_ARRAY);
    handlePatternfilter(EMPTY_ARRAY);
    setFav(false);
  };

  //Apply Phrase Patterns
  const handlePatterSelect = () => {
    const a = phrasePattern.map((el) => el.name);
    handlePatternfilter(a);
    setAnchorEl(null);
  };

  useEffect(() => {
    const uv = value.map((el) => {
      return { id: Math.floor(Math.random() * Math.floor(1000)), name: el };
    });
    setPhrasePattern(uv);
    setFav(false);
  }, [value]);

  return (
    <Fragment>
      <div className="display-inlineflex sort-head">
        <div>{label + ":"}&nbsp;</div>
        <div
          style={{ display: "flex", cursor: "pointer" }}
          onClick={handleClick}
        >
          <div
            style={{
              height: "24px",
              lineHeight: "26px",
              textTransform: "capitalize",
            }}
          >
            {value.length !== 0 ? value.slice(0, 3).toString() : null}
          </div>
          <div>
            <ArrowDropDown />
          </div>
        </div>
      </div>

      <StyledMenu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <div className="section1">
            <ReactSortable
              group="groupName"
              animation={200}
              delayOnTouchStart={true}
              delay={2}
              list={phrasePattern}
              setList={setPhrasePattern}
              onChange={() => {
                setFav(false);
              }}
              swap
            >
              {phrasePattern.map((item, i) => {
                return (
                  <div key={i} className="s-grid1">
                    {item.name}
                    <span
                      onClick={() => {
                        removeFromPhrasePattern(item);
                      }}
                    >
                      X
                    </span>
                  </div>
                );
              })}
            </ReactSortable>
            {phrasePattern.length > 0 ? (
              <div className="add-to-fav">
                <IconButton
                  size="small"
                  title="Add to Favorite"
                  onClick={addToFavourite}
                  color={fav ? "secondary" : "default"}
                >
                  <Favorite />
                </IconButton>
              </div>
            ) : (
              <div className="noData">No Phrase Pattern selected.</div>
            )}
          </div>
        </MenuItem>
        <MenuItem>
          <div className="section2">
            {unSelectedPatterns.map((item, i) => (
              <div
                key={i}
                className="s-grid2"
                title="Add to Selection"
                onClick={() => addtoPhrasePattern(item)}
              >
                {"+" + item}
              </div>
            ))}
          </div>
        </MenuItem>
        <MenuItem>
          <div className="section3">
            <span className="favTitle">Favorite Patterns</span>
            {favoritePatterns.map((item) => (
              <div title="Select Pattern" key={item.id} className="fav-pattern">
                <div className="fav-tags">
                  {item.pattern.split(",").map((el, j) => (
                    <div key={j} className="s-grid3">
                      {" "}
                      {el}{" "}
                    </div>
                  ))}
                </div>
                <div
                  className="fav-actions"
                  onClick={() => addToSelectedPattern(item)}
                >
                  <IconButton size="small">
                    <ArrowForward />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        </MenuItem>
        <MenuItem>
          <div className="pattern-footer">
            <Button onClick={resetPhrasePattern}>Remove</Button>
            <Button
              disabled={phrasePattern.length === 0}
              onClick={handlePatterSelect}
              color="primary"
            >
              Apply
            </Button>
          </div>
        </MenuItem>
      </StyledMenu>
    </Fragment>
  );
}

import "./Login.css";
import React, {Component} from "react";
import {Button} from "@material-ui/core";
import LoginLogo from "../../assets/images/login-logo.png";
import LoginBg from "../../assets/images/login-bg.png";
import Preloder from "../../containers/Preloader/DataPreloader";
import {Auth} from "aws-amplify";
import {clearAppLocalStorage} from "../../_helpers/utils";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo: null,
            showPreloader: true,
            serverError: false,
            isDisabled: false,
        };
        this.login = this.login.bind(this);
    }

    async componentDidMount() {
        clearAppLocalStorage()
    }

    async login() {
        clearAppLocalStorage();
        Auth.federatedSignIn();
    }

    render() {
        return (
            <div>
                <div
                    className="masterlogin"
                    style={{backgroundImage: "url(" + LoginBg + ")"}}
                >
                    <div className="login-main">
                        <div className="login-logo-holder">
                            <img src={LoginLogo} alt="decooda"/>
                        </div>
                    </div>
                    <div className="ribbonPlaceholder">
                        <div className="ribbionContent">
                            <div className="login-info top-panel">
                                <h2>Mission Control & Command Center (MC3)</h2>
                                <p>
                                    Leverage the power of artificial intelligence, cognitive
                                    science, and text analytics to deliver insight and business
                                    intelligence for driving real-time marketing, sales, and
                                    customer engagement.
                                </p>
                            </div>

                            <div className="login-info bottom-panel">
                                <h4>About Decooda</h4>
                                <p>
                                    Our platform synthesizes structured and unstructured social
                                    media big data into actionable insights that enable
                                    marketing and sales teams to identify the insights and
                                    behaviors that matter.
                                </p>
                                <a
                                    href="https://decooda.com/demo/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Contact Us
                                </a>
                            </div>
                            <div className="login-holder">
                                <Button
                                    variant="outlined"
                                    id="login-button"
                                    onClick={this.login}
                                    disabled={this.state.isDisabled}
                                >
                                    Login / Sign Up
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;

import React from "react";

import { replaceNumberWithCommas } from "../../_helpers/utils";
import TOTALPOST_IMG from "../../assets/images/totalPost.png";
import CURRENTPOST_IMG from "../../assets/images/currentPost.png";
import NETSENTIMENT_IMG from "../../assets/images/netSentiment.png";
import FILTERSENTIMENT_IMG from "../../assets/images/filteredSentiment.png";
import "./BrandPostDetails.css";

export default function BrandPostDetails({
  totalPostCount,
  currentsPostCount,
  netSentiment,
  filteredSentiment,
  defaultFilter,
}) {
  return (
    <div className="infoHolder">
      <div className="infoDiv">
        <span className="infoIcon">
          <img src={TOTALPOST_IMG} alt="decooda" />
        </span>
        <p className="infoCount">{replaceNumberWithCommas(totalPostCount)}</p>
        <p className="infoTitle">total post</p>
      </div>
      <div className="infoDiv">
        <span className="infoIcon">
          <img src={CURRENTPOST_IMG} alt="decooda" />
        </span>
        <p className="infoCount">
          {replaceNumberWithCommas(currentsPostCount)}
        </p>
        <p className="infoTitle">current post</p>
      </div>
      <div className="infoDiv">
        <span className="infoIcon">
          <img src={NETSENTIMENT_IMG} alt="decooda" />
        </span>
        <p className="infoCount">{netSentiment}</p>
        <p className="infoTitle">net sentiment</p>
      </div>
      {!defaultFilter && (
        <div className="infoDiv">
          <span className="infoIcon">
            <img src={FILTERSENTIMENT_IMG} alt="decooda" />
          </span>
          <p className="infoCount">{filteredSentiment}</p>
          <p className="infoTitle">filtered sentiment</p>
        </div>
      )}
    </div>
  );
}

import "./UserDetails.css";
import React from "react";
import axios from "axios";
import compose from "recompose/compose";
import PropTypes from "prop-types";
import Icon from "@material-ui/core/Icon";
import Preloder from "../../../../containers/Preloader/DataPreloader";
import {
  Menu,
  MenuItem,
  IconButton,
  withStyles,
  Tooltip,
  Zoom,
} from "@material-ui/core";
import client from "../../../../_helpers/client";
import { GET_USER_DETAILS, GET_DEFAULT_BRAND } from "../../../../Routes";

import {
  EMPTY_STRING,
  PLAN_ADMINISTRATION,
  PLAN_PROFESSIONAL,
  PLAN_ENTERPRISE,
} from "../../../../Constants";
import { getUserDetails, updateAppUser } from "../../../../_helpers";

const styles = {
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

class UserDetails extends React.Component {
  state = {
    anchorEl: null,
    showPreloder: false,
    userName: EMPTY_STRING,
    userPlan: "Basic",
    isSpyUser: null,
    isAdmin: false,
  };

  isMount = null;

  handleProfileMenuOpen = (event) => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails === null) {
      this.setState({ anchorEl: event.currentTarget });
    } else {
      this.setState({
        anchorEl: event.currentTarget,
        isSpyUser:
          userDetails.isSpyUser === EMPTY_STRING ? null : userDetails.isSpyUser,
        isAdmin: userDetails.isAdmin,
      });
    }
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleUserLogout = () => {
    this.setState({ anchorEl: null });
    this.props.handleUserLogut();
  };

  handleMainUser = () => {
    this.setState({ showPreloder: true, anchorEl: null });
    localStorage.removeItem("spyUser");
    const requestOne = client.get(GET_USER_DETAILS);
    const requestTwo = client.get(GET_DEFAULT_BRAND);

    axios
      .all([requestOne, requestTwo])
      .then(
        axios.spread((...responses) => {
          const responseOne = responses[0];
          const responseTwo = responses[1];
          updateAppUser(responseOne, responseTwo);
        })
      )
      .catch((errors) => {
        console.log(errors);
      });
  };

  handleUserManagement = (event) => {
    this.setState({ anchorEl: null });
    this.props.routeProps.push("/users");
  };

  handlePricingPlan = () => {
    this.setState({ anchorEl: null });
    this.props.routeProps.push("/pricingView");
  };

  handleManageSocialMedia = () => {
    this.setState({ anchorEl: null });
    this.props.routeProps.push("/usersocialmedia");
  };

  handleEditProfile = () => {
    this.setState({ anchorEl: null });
    this.props.routeProps.push("/update");
  }

  handleAPIToken = () => {
    this.setState({ anchorEl: null });
    this.props.routeProps.push("/token");
  }

  async componentDidMount() {
    const userDetails = await getUserDetails();
    if (userDetails) {
      const up =
        userDetails.planId === PLAN_ADMINISTRATION
          ? "Administration"
          : userDetails.planId === PLAN_PROFESSIONAL
          ? "Professional"
          : userDetails.planId === PLAN_ENTERPRISE
          ? "Enterprise"
          : "Basic";
      this.setState({
        userName: userDetails.email,
        userPlan: up,
      });
    }
  }

  render() {
    const { anchorEl, userName, userPlan, isAdmin } = this.state;
    const { classes } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const spyUser = JSON.parse(localStorage.getItem("spyUser"));

    const renderSpyMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
        classes={{ paper: "theme-list" }}
      >
        <MenuItem onClick={this.handleMainUser}>
          <Icon>group</Icon>Back to you{" "}
          {spyUser ? spyUser.mainUser : EMPTY_STRING}
        </MenuItem>
        <MenuItem onClick={this.handleUserLogout}>
          <Icon>power_settings_new</Icon>Logout
        </MenuItem>
      </Menu>
    );

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
        classes={{ paper: "theme-list" }}
      >
        {/*  <MenuItem onClick={this.handleUserEdit}><Icon>person</Icon>Profile</MenuItem> */}
        <MenuItem onClick={this.handleManageSocialMedia}>
          <Icon>share</Icon>Social Media
        </MenuItem>
        <MenuItem onClick={this.handlePricingPlan}>
          <Icon>attach_money</Icon>Pricing Plan
        </MenuItem>
        <MenuItem onClick={this.handleAPIToken}>
          <Icon>local_activity</Icon>Legacy API Token
        </MenuItem>
        <MenuItem onClick={this.handleEditProfile}>
          <Icon>manage_accounts</Icon>Edit Profile
        </MenuItem>
        {isAdmin ? (
          <MenuItem onClick={this.handleUserManagement}>
            <Icon>group</Icon>User Management
          </MenuItem>
        ) : null}
        <MenuItem onClick={this.handleUserLogout}>
          <Icon>power_settings_new</Icon>Logout
        </MenuItem>
      </Menu>
    );

    return (
      <div className="userDetails">
        {this.state.showPreloder && <Preloder />}

        <Tooltip TransitionComponent={Zoom} title={userName}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            arial-label="User Details"
            aria-owns={isMenuOpen ? "material-appbar" : undefined}
            aria-haspopup="true"
            onClick={this.handleProfileMenuOpen}
          >
            {userName !== EMPTY_STRING ? (
              <div className="userTitleContainer">
                <span className="userTitle" id="userTitle">
                  {userName}
                </span>
                <small>
                    Plan: {userPlan}
                </small>
              </div>
            ) : null}
            <div>
              <span className="userPic">
                {/*<img src={Avatar} alt="User Name"/> */}
                <i className="fa fa-user" aria-hidden="true"></i>
              </span>
            </div>
          </IconButton>
        </Tooltip>

        {anchorEl === null ? null : !spyUser ? renderMenu : renderSpyMenu}
      </div>
    );
  }
}

UserDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles))(UserDetails);

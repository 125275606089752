import React, { Suspense, lazy } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { toast } from "react-toastify";
import {
  AppBar,
  Toolbar,
  IconButton,
  withStyles,
  Button,
  Zoom,
  Dialog,
  Icon,
  Tooltip,
  TextField,
  CircularProgress, Badge,
} from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import client from "../../../_helpers/client";
import {
  SUCCESS,
  WARN,
  EMPTY_OBJECT,
  EMPTY_STRING,
  EMPTY_ARRAY,
  APIGEE_URL
} from "../../../Constants";
import {
  EXPORT_BRAND,
  REFRESH_FILTER,
  ISFILTEREXIST,
  GET_FILTERED_COUNT,
  DELETE_BY_FILTER,
  BRAND_JSON_FIELDS,
  UPDATE_MISSION_QUES, IS_BRAND_HAVE_LABEL,
} from "../../../Routes";

import LogoIcon from "../../../assets/images/logo.svg";
import LogoWithText from "../../../assets/images/decoodaLogo.svg";
import Preloder from "../../../containers/Preloader/Preloader";

import Notifications from "./Notifications/Notifications";
import DefaultSelectedBrand from "./DefaultSelectedBrand/DefaultSelectedBrand";
import UserDetails from "./UserDetails/UserDetails";

import {clearAppLocalStorage, getDefaultBrandDetails, getKibanaUrl} from "../../../_helpers";

import BrandExportOptions from "./BrandExportOptions";
import {Auth} from "aws-amplify";

import FilterWrapper from "./Filter/FilterWrapper";
import ExportWarning from "./ExportWarning";

const styles = {
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

class AppHeader extends React.Component {
  constructor(props) {
    super(props);
    this.handleFullScreen = this.handleFullScreen.bind(this);
  }

  state = {
    showBrands: false,
    brandsList: [],
    showPreLoader: false,
    brandsPreLoader: false,
    brandInfo: null,
    isFullScreen: false,
    inputFocus: false,

    scoredBrandsPageNo: 1,
    scoredBrandsPageSize: 100,
    scoredBrandsSortOrder: "asc",
    scoredBrandsSearchWord: "",
    scoredBrandsTotalCount: 0,

    showFilter: false,
    filterOptions: EMPTY_OBJECT,

    isModalOpen: false,
    modalEventType: null,
    anchorEl: null,
    refreshDefaultBrand: EMPTY_STRING,

    isFilterExist: false,
    hasFilter: false,
    confirmTxt: EMPTY_STRING,
    countMessage: null,
    brandExportOption: "one",
    brandExportType: "csv",
    jsonFields: EMPTY_ARRAY,
    checkedItems: EMPTY_ARRAY,
    missionQues: EMPTY_STRING,
    updatingMissionQues: false,
    isBrandHaveSensitiveData:false,
    isAdmin: false,
    isAdminEligibleForSensitiveData:false
  };

  handleFullScreen = () => {
    var doc = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    var cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
    this.setState({ isFullScreen: !this.state.isFullScreen });

    window.onkeydown = (e) => {
      if (e.key === "Escape") this.setState({ isFullScreen: false });
    };
  };

  openUserLogoutConfirm = () => {
    this.setState({ isModalOpen: true, modalEventType: "logout" });
  };

  //Close Filter
  handleFilterClose = () => {
    this.setState({ showFilter: false });
  };

  //Open Filter
  handleFilterOpen = async () => {
    let defaultBrandDetails = null;
    defaultBrandDetails = await getDefaultBrandDetails();
    if (defaultBrandDetails === null) {
      toast.warn("Filter is not available.");
      return;
    } else {
      if (
        defaultBrandDetails.id === EMPTY_STRING ||
        defaultBrandDetails.id === 0
      ) {
        toast.warn("Filter is not available.");
        return;
      } else {
        this.setState({ showFilter: true });
      }
    }
  };


  handleUserDetails = () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails === null) {
      console.log("IsAdmin:User does not exist ")
      this.setState({isAdmin: false});
    } else {
      console.log("IsAdmin " +userDetails.isAdmin)
      this.setState({isAdmin: userDetails.isAdmin});
    }
  }

  //Reset Brand to Default values
  refreshFilter = () => {
    client
      .get(REFRESH_FILTER)
      .then((res) => {
        if (res.data.status === SUCCESS) {
          let urlPath = window.location.pathname;
          if (urlPath === "/summary" || urlPath === "/textDetails") {
            window.location.reload();
          }
        } else {
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === WARN)
            toast.warn(error.response.data.message);
          else toast.error(error.response.data.message);
        }
      });
  };

  //Get Json Feilds for brand export
  fetchJsonFields = () => {
    client
      .get(BRAND_JSON_FIELDS)
      .then((res) => {
        if (res.data.status === SUCCESS) {
          this.setState({ ...this.state, jsonFields: res.data.response });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleBrandHaveSensitiveData = () => {
    client
        .get(IS_BRAND_HAVE_LABEL)
        .then(res => {
          console.log("isBrandHaveSensitiveData: " + res.data)
          this.setState({isBrandHaveSensitiveData: res.data});
        })
        .catch(err => console.error(err))
  }


  isFilterExistCheck = () => {
    client
      .get(ISFILTEREXIST, { params: { isTextDetails: false } })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          if (res.data.response) this.setState({ isFilterExist: true });
          else this.setState({ isFilterExist: false });
        }
      }).catch((err) => {
        console.log(err);
      });
  };

  deleteFilterdList = () => {
    this.setState({ showPreLoader: true });
    let isTextDetails = false,
        pathname = this.props.history.location.pathname;

    if (pathname === "/textDetails") isTextDetails = true;

    client
      .get(ISFILTEREXIST, { params: { isTextDetails: isTextDetails } })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          client.get(GET_FILTERED_COUNT).then((res) => {
            this.setState({
              countMessage: res.data.message,
              countValue: res.data.response,
              isModalOpen: true,
              modalEventType: "delete",
              showPreLoader: false,
            });
          });
        } else this.setState({ showPreLoader: false });
      })
      .catch((error) => {
        this.setState({ showPreLoader: false });
      });
  };

  //Modal close Event
  handleModalClose = () => {
    this.setState({
      ...this.state,
      modalEventType: null,
      showPreLoader: false,
      isModalOpen: false,
      brandExportType: "csv",
      brandExportOption: "one",
      checkedItems: EMPTY_ARRAY,
      updatingMissionQues: false
    });
  };

  //Modal open Event
  exportBrand = () => {
    this.setState({
      isModalOpen: true,
      modalEventType: "export",
      brandExportOption: "one",
    });
  };



  //Export Default Brand Confirm Event
  exportDefaultBrand = () => {

    const {
      brandExportOption,
      brandExportType,
      jsonFields,
      checkedItems,
      isAdmin,
      isBrandHaveSensitiveData,
      isAdminEligibleForSensitiveData
    } = this.state;
    let contentDisableFlag = null,
      jsonFieldType = null;

    if( isBrandHaveSensitiveData && !isAdmin ) {
      this.setState({
        ...this.state,
        isModalOpen: false,
        brandExportType: "csv",
        brandExportOption: "one",
        checkedItems: EMPTY_ARRAY,
      });
      return
    }
    if( isBrandHaveSensitiveData && isAdmin && !isAdminEligibleForSensitiveData) {
      this.setState({
        isAdminEligibleForSensitiveData: true,
      })
      return;
    }


    if (brandExportType === "csv") {
      jsonFieldType = null;
      contentDisableFlag = brandExportOption === "one" ? false : true;
    } else {
      contentDisableFlag = null;
      if (jsonFields.length === checkedItems.length) jsonFieldType = "all";
      else {
        jsonFieldType = checkedItems.toString();
      }
    }


    client
      .get(EXPORT_BRAND, {
        params: {
          contentDisableFlag: contentDisableFlag,
          format: brandExportType,
          jsonFieldType: jsonFieldType,
        },
      })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          toast.success(res.data.message);
          this.setState({
            ...this.state,
            isModalOpen: false,
            brandExportType: "csv",
            brandExportOption: "one",
            checkedItems: EMPTY_ARRAY,
          });
        } else if (res.data.status === WARN) {
          toast.warn(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === WARN)
            toast.warn(error.response.data.message);
          else toast.error(error.response.data.message);
        }
        this.setState({
          ...this.state,
          isModalOpen: false,
          brandExportType: "csv",
          brandExportOption: "one",
          checkedItems: EMPTY_ARRAY,
        });
      });
  };

  //Filter List Delete
  handleDeleteByFilter = () => {
    client
      .get(DELETE_BY_FILTER, {
        params: { size: parseInt(this.state.countValue) },
      })
      .then((res) => {
        if (res.data.status === SUCCESS)
          toast.success(
            "Deletion in progress. This will take a while. Please check after some time."
          );
        else toast.error(res.data.message);

        this.setState({
          ...this.state,
          countMessage: null,
          countValue: null,
          isModalOpen: false,
          modalEventType: null,
        });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === WARN)
            toast.warn(error.response.data.message);
          else toast.error(error.response.data.message);
        }
      });
  };

  //Logout Confirm Event
  handleLogout = () => {
    Auth.signOut({global: true})
        .then(data => {
          clearAppLocalStorage();
        })
        .catch(err => {
          console.log(err);
          clearAppLocalStorage();
          this.props.history.push("/login");
        });
  };

  handleBrandEdit = (brandDetails) =>
    this.props.history.push(
      "/workshop?editBrand=" + brandDetails.id + "&" + brandDetails.myBrandId
    );

  updateDefaultBrand = () => {
    setTimeout(() => {
      let urlPath = window.location.pathname;
      if (urlPath === "/summary" || urlPath === "/textDetails" || urlPath === "/atd" || urlPath === "/kibana" || urlPath ==="/impactAnalysis")
        this.props.history.push(urlPath);
      this.isFilterExistCheck();
    }, 400);
  };

  handleKibanaOpen = async () => {
    const defaultBrand = await getDefaultBrandDetails();
    if (!defaultBrand.id) {
      toast.error("No Brand Selected");
    } else {
      window.open(this.state.kibanaUrl, "_blank");
      const kibanaUrl = localStorage.getItem("kibanaUrl");
      if (!kibanaUrl) {
        localStorage.setItem("kibanaUrl", this.state.kibanaUrl);
      }
    }
  };

  openApigee = () => {
    window.open(APIGEE_URL, "_blank");
  }

  // Open Mission Ques Update Modal
  handleMissionQuesModal = (data) => {
    this.setState((state) => ({
      ...state,
      isModalOpen: true,
      modalEventType: "mission",
      missionQues: data,
    }))
  }

  // Handle Mission Ques Value change
  handleMissionQuesChange = (e) => {
    this.setState({ missionQues: e.target.value })
  }

  // Update Mission Question
  handleMissionQuesUpdate = async () => {
    try {
      const { missionQues } = this.state
      this.setState({
        updatingMissionQues: true
      })
      const defaultBrand = await getDefaultBrandDetails()
      client
        .post(UPDATE_MISSION_QUES, {
          brandId: defaultBrand.id,
          objective: missionQues ? missionQues : ''
        })
        .then((res) => {
          localStorage.setItem(
            'defaultSelectedBrand',
            JSON.stringify({
              ...defaultBrand,
              objective: missionQues
            })
          )
          setTimeout(() => {
            this.handleModalClose()
          }, 1000)
        })
        .catch((err) => {
          console.log(err)
          toast.error('Could not update mission quesion.')
          this.handleModalClose()
        })
    } catch (err) {
      console.log(err)
      toast.error('Could not update mission quesion.')
      this.handleModalClose()
    }
  }

  async componentDidMount() {
    this.fetchJsonFields();
    this.handleBrandHaveSensitiveData();
    this.handleUserDetails();
    this.isFilterExistCheck();
    const kibanaUrl = await getKibanaUrl();
    this.setState({
      kibanaUrl: kibanaUrl,
    });
  }

  render() {
    const { classes } = this.props;
    const {
      showFilter,
      isModalOpen,
      showPreLoader,
      isFilterExist
    } = this.state;

    return (
      <div className="mainHeader">
        {showPreLoader ? <Preloder /> : null}

        <Dialog
          open={isModalOpen}
          onClose={this.handleModalClose}
          aria-labelledby="export-confirm"
          maxWidth={this.state.modalEventType === "export" ? "md" : "sm"}
        >
          <div className="uiDialog">
            <div className="uiDialogTitle">
              {this.state.modalEventType === "delete" ? (
                <h6 style={{ color: "#f55c5c" }}>Delete Filtered Data</h6>
              ) : this.state.modalEventType === "logout" ? (
                <div className="logout-titile">
                  <ExitToApp /> <span>Logout ?</span>
                </div>
              ) : this.state.modalEventType === "export" ? (
                <h6>Brand Export</h6>
              ) : this.state.modalEventType === "mission" ? (
                <h6>Update Mission Question</h6>
              ) : null}
            </div>
            <div className="uiDialogContent">
              {
                this.state.isBrandHaveSensitiveData && this.state.isAdmin && !this.state.isAdminEligibleForSensitiveData ? (<ExportWarning isAdmin={this.state.isAdmin}/>)
                :this.state.isBrandHaveSensitiveData && !this.state.isAdmin ? (<ExportWarning isAdmin={this.state.isAdmin} />)
                :this.state.modalEventType === "export" ? (
                  <BrandExportOptions
                      options={this.state.jsonFields}
                      brandExportOption={this.state.brandExportOption}
                      brandExportType={this.state.brandExportType}
                      handleExportType={(val) => {
                        this.setState({brandExportType: val});
                      }}
                      handleExportOption={(val) => {
                        this.setState({brandExportOption: val});
                      }}
                      selectAll={this.state.selectAll}
                      handleSelectAll={this.handleSelectAll}
                      checkedItems={this.state.checkedItems}
                      setCheckedItems={(val) => {
                        this.setState({...this.state, checkedItems: val});
                      }}
                  />
              ) : this.state.modalEventType === "logout" ? (
                "You can improve your security further after logging out by closing this opened browser."
              ) : this.state.modalEventType === "delete" ? (
                <div className="filterDelDialog">
                  <p
                    style={{
                      marginBottom: "10px",
                      marginTop: "0px",
                      lineHeight: "20px",
                    }}
                  >
                    {this.state.countMessage + "."}
                  </p>
                  <small style={{ fontWeight: "bold" }}>
                    Please enter{" "}
                    <span style={{ color: "#f55c5c" }}>DELETE</span> text to
                    confirm delete.
                  </small><br/>
                  <TextField
                    id="del-pre-check"
                    //fullWidth
                    value={this.state.confirmTxt}
                    onChange={(e) => {
                      this.setState({ confirmTxt: e.target.value });
                    }}
                    variant="filled"
                    inputProps={{"data-testid": "deleteFilterInput"}}
                  />
                </div>
              ) : this.state.modalEventType === "mission" ? (
                <TextField
                  variant="outlined"
                  value={this.state.missionQues}
                  onChange={this.handleMissionQuesChange}
                  margin="dense"
                  autoFocus
                  fullWidth
                  multiline
                  rows={8}
                  rowsMax={8}
                />
              ) : null}
            </div>
            <footer>
              <div>
                {!(this.state.modalEventType === "export" && this.state.isBrandHaveSensitiveData) || (this.state.modalEventType === "export" && this.state.isAdmin) ?
                (<Button variant="outlined" onClick={this.handleModalClose} disabled={this.state.updatingMissionQues}>
                  {this.state.modalEventType === "export"
                    ? "Cancel"
                    : this.state.modalEventType === "delete"
                    ? "Cancel"
                    : this.state.modalEventType === "logout"
                    ? "No"
                    : this.state.modalEventType === "mission"
                    ? "Cancel"
                    : null}
                </Button>):null }
                {
                  (this.state.modalEventType === "export" && this.state.isBrandHaveSensitiveData && !this.state.isAdminEligibleForSensitiveData && this.state.isAdmin)? (
                      <Button
                          variant="outlined"
                          color="primary"
                          onClick={this.exportDefaultBrand}
                      >
                        Yes
                      </Button>)
                  :this.state.modalEventType === "export" ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={this.exportDefaultBrand}
                  >
                    Ok
                  </Button>
                ) : this.state.modalEventType === "delete" ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={this.handleDeleteByFilter}
                    disabled={this.state.confirmTxt.trim() !== "DELETE"}
                    data-testid="deleteFilterBtn"
                  >
                    Delete
                  </Button>
                ) : this.state.modalEventType === "logout" ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={this.handleLogout}
                  >
                    Yes
                  </Button>
                ) : this.state.modalEventType === "mission" ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={this.handleMissionQuesUpdate}
                    disabled={this.state.updatingMissionQues}
                  >
                    Save
                    {this.state.updatingMissionQues && (
                      <CircularProgress size={20} style={{position: 'absolute'}} />
                    )}
                  </Button>
                ) : null}
              </div>
            </footer>
          </div>
        </Dialog>

        {/* Default Header Holder */}
        <AppBar position="fixed" className="defaultHeaderBar">
          <Toolbar disableGutters>
              {/* Header Left Side Options */}
                <div className={"leftSide"}>
                  {/* Logo Holder */}
                  <div className="logoHolder">
                    <img className="logoIcon" src={LogoIcon} alt="Decooda" />
                    <img className="logoText" src={LogoWithText} alt="Decooda" />
                  </div>

                  {/* Default Brand Holder */}
                  <DefaultSelectedBrand
                    updateDefaultBrand={this.updateDefaultBrand}
                    handleBrandEdit={(item) => this.handleBrandEdit(item)}
                    refreshDefaultBrand={this.state.refreshDefaultBrand}
                    handleMissionQuesModal={this.handleMissionQuesModal}
                    handleBrandHaveSensitiveData ={this.handleBrandHaveSensitiveData}
                  />
                </div>

                {/* Header Right Side Options */}
                <div className={"rightSide"}>
                  {/* User Options */}
                  <UserDetails
                    routeProps={this.props.history}
                    handleUserLogut={this.openUserLogoutConfirm}
                  />

                  {/* Full Screen button */}
                  <div className="fullScreenHolder">
                    <Tooltip
                      title={
                        !this.state.isFullScreen
                          ? "Full Screen"
                          : "Exit Full Screen"
                      }
                      TransitionComponent={Zoom}
                    >
                      <IconButton
                        onClick={this.handleFullScreen}
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="FullScreen"
                        data-testid="fullScreenIcon"
                      >
                        {!this.state.isFullScreen ? (
                          <Icon>fullscreen</Icon>
                        ) : (
                          <Icon>fullscreen_exit</Icon>
                        )}
                      </IconButton>
                    </Tooltip>
                  </div>

                  {/* Brand Export */}
                  <div className="brandExport">
                    <Tooltip title="Export Brand" TransitionComponent={Zoom}>
                      <IconButton
                        onClick={this.exportBrand}
                        className={classes.menuButton}
                        color="inherit"
                        arial-label="Export Brand"
                        data-testid="exportBrandIcon"
                      >
                        <Icon>save_alt</Icon>
                      </IconButton>
                    </Tooltip>
                  </div>

                  {/* Filter Delete*/}
                  <div className="deleterFilterHolder">
                    <Tooltip
                      title="Delete TextDetails"
                      TransitionComponent={Zoom}
                    >
                      <span className="spanBeforeBtn">
                        <IconButton
                          onClick={this.deleteFilterdList}
                          className={classes.menuButton}
                          color="inherit"
                          arial-label="Delete TextDetails"
                          data-testid="deleteFilteredTextdetails"
                          disabled={!isFilterExist}
                        >
                          <Icon>delete_outline</Icon>
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>

                  {/* Refresh selected Brand Filters */}
                  <div className="refreshHolder">
                    <Tooltip title="Refresh Filter" TransitionComponent={Zoom}>
                      <IconButton
                        onClick={this.refreshFilter}
                        className={classes.menuButton}
                        color="inherit"
                        arial-label="Refresh Filter"
                        data-testid="refreshFilterIcon"
                      >
                        <Icon>refresh</Icon>
                      </IconButton>
                    </Tooltip>
                  </div>

                  {/* Header Filter button */}
                  <FilterWrapper
                      showFilter={showFilter}
                      hasFilter={isFilterExist}
                      handleFilterOpen={this.handleFilterOpen}
                      handleFilterClose={this.handleFilterClose}
                  />

                  {/* Notifications Holder */}
                  <Notifications
                    routeProps={this.props}
                    handleUserLogut={this.handleLogout}
                  />
                </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

AppHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withRouter, withStyles(styles))(AppHeader);

import React from "react";
import Fullscreen from "react-full-screen";
import ReactHighcharts from "react-highcharts";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DataPreloader from "../../../containers/Preloader/DataPreloader";
import ErrorBoundary from "../../ErrorBoundary/ErrorBoundary";

// Highcharts exporting
var HighchartsExporting = require("highcharts-exporting");
HighchartsExporting(ReactHighcharts.Highcharts);

class Chart extends React.Component {
  constructor(props) {
    super();

    this.state = {
      isFull: false,
    };
  }

  goFull = () => {
    this.setState({ isFull: !this.state.isFull });
  };

  render() {
    const { loading, chartData } = this.props;

    const config = {
      chart: {
        type: chartData.type,
        zoomType: "xy",
        resetZoomButton: {
          position: {
            x: -5,
            y: 5,
          },
          relativeTo: "chart",
        },
        style: {
          fontFamily: "Poppins",
          textTransform: "capitalize",
        },
      },
      legend: {
        enabled: true,
        itemWidth: 160,
      },
      title: {
        text: null,
      },
      subtitle: {
        text: null,
      },
      xAxis: {
        categories: chartData.categories,
        labels: {
          rotation: -45,
        },
      },
      yAxis: {
        min: 0,
        allowDecimals: false,
        ceiling: 100,
        title: {
          text: "Percentage (%)",
        },
        labels: {
          overflow: "justify",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.6f}</b>%</td></tr>',

        footerFormat: "</table>",
        shared: true,
        useHTML: true,
        style: {
          padding: "0px",
          margin: "0px",
        },
        borderColor: "none",
        shadow: false,
        positioner: function () {
          return { x: 0, y: 25 };
        },
        formatter: function () {
          var s =
            "<div><table class='table table-condensed table-bordered chartTables' style='margin-bottom:0px !important'><tr>" +
            "<td rowspan='2'><b>" +
            this.x +
            "</b></td>";

          this.points.forEach(function (entry) {
            s +=
              '<td style="text-align: center; color:' +
              entry.series.color +
              '">' +
              entry.series.name +
              "</td>";
          });
          s += "</tr><tr>";

          this.points.forEach(function (entry) {
            s += "<td> " + entry.y + "%</td>";
          });

          return s + "</tr></table></div>";
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      credits: {
        enabled: false,
      },
      series: chartData.chartData,
    };

    return (
      <React.Fragment>
        {loading ? (
          <DataPreloader />
        ) : (
          <ErrorBoundary>
            <Fullscreen
              enabled={this.state.isFull}
              onChange={(isFull) => this.setState({ isFull })}
            >
              <div className="chartHolder">
                <div className="chartTitle">
                  <div className="chartLabel">{chartData.title}</div>
                  <div className="chartTitleActions">
                    <Tooltip title="Full Screen" placement="left">
                      <span>
                        <IconButton
                          aria-label="Full Screen"
                          onClick={this.goFull}
                          disabled={chartData.chartData.length === 0}
                          data-testid="fullscreen"
                        >
                          <i className="fa fa-expand"></i>
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>
                </div>
                <div className="chartContent">
                  {chartData.chartData.length === 0 ? (
                    <p className="noData">NO DATA</p>
                  ) : (
                    <ReactHighcharts ref="chart" config={config} />
                  )}
                </div>
              </div>
            </Fullscreen>
          </ErrorBoundary>
        )}
      </React.Fragment>
    );
  }
}

Chart.propTypes = {
  chartData: PropTypes.object.isRequired,
};

export default Chart;

import React from 'react';
import { NavLink } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import { Button, IconButton, Tooltip, Zoom, withStyles } from '@material-ui/core';
import { EMPTY_STRING } from '../../../Constants';
import { getDefaultBrandDetails, getKibanaUrl } from "../../../_helpers";
import { RELEASE_VERSION } from "../../../Constants";
import { toast } from 'react-toastify';
import TimelineIcon from '@material-ui/icons/Timeline';

const styles = {
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
};

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        width: "fit-content",
        backgroundColor: '#4F4F4F',
        color: '#E0E0E0',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        marginLeft: "-194px",
    },
}))(Tooltip);

class AsideBar extends React.Component {

    state = {
        planId: null,
        kibanaURL: EMPTY_STRING,
        onlyIcons: true
    }

    handleSidebarCompact=()=>{
        const { onlyIcons } = this.state;
        this.setState({ onlyIcons: !onlyIcons })
        document.body.classList.toggle("sidebar-compact");
    }

    async componentDidMount() {
        const kibanaUrl = await getKibanaUrl();
        this.setState((state) => ({
            ...state,
            kibanaURL: kibanaUrl
        }));
    }

    handleKibanaOpen = async (e) => {
        e.preventDefault();
        const defaultBrand = await getDefaultBrandDetails();
        if(defaultBrand && defaultBrand.id) {
            if (this.state.kibanaURL) {
                window.open(this.state.kibanaURL);
                const kibanaUrl = localStorage.getItem("kibanaUrl");
                if (!kibanaUrl) {
                    localStorage.setItem("kibanaUrl", this.state.kibanaURL);
                }
            } else {
                toast.error('Could not open Kibana. Please try later.');
            }
        } else {
            toast.warn('No Brand Selected.');
        }
    };

    render() {
        const { onlyIcons } = this.state;
        
        return (
            <div className="sideBar">
                <ul>
                    <li className="hover-disabled">
                        <div className="menuIcon">
                            <IconButton onClick={this.handleSidebarCompact}>
                                { onlyIcons ? <Icon>menu</Icon> : <Icon>close</Icon> }
                            </IconButton>
                        </div>
                    </li>
                    <li>
                        <NavLink to="/workshop">
                            <CustomTooltip
                                title="Brand Management"
                                placement="right"
                                disableHoverListener={!onlyIcons}
                                TransitionComponent={Zoom}
                            >
                                <Button>
                                    <Icon style={{fontSize: '22px'}}>perm_data_setting</Icon>
                                    { onlyIcons ? null : <span className="navLabel">Brand Management</span> }
                                </Button>
                            </CustomTooltip>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/atd">
                            <CustomTooltip
                                title="Classifier Workbench"
                                placement="right"
                                disableHoverListener={!onlyIcons}
                                TransitionComponent={Zoom}
                            >
                                <Button>
                                    <Icon>list_alt</Icon>
                                    { onlyIcons ? null : <span className="navLabel">Classifier Workbench</span> }
                                </Button>
                            </CustomTooltip>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/summary">
                            <CustomTooltip
                                title="Summary"
                                placement="right"
                                disableHoverListener={!onlyIcons}
                                TransitionComponent={Zoom}
                            >
                                <Button>
                                    <Icon>dashboard</Icon>
                                    { onlyIcons ? null : <span className="navLabel">Summary</span>}
                                </Button>
                            </CustomTooltip>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/brandComparison">
                            <CustomTooltip
                                title="Brand Comparison"
                                placement="right"
                                disableHoverListener={!onlyIcons}
                                TransitionComponent={Zoom}
                            >
                                <Button>
                                    <Icon>view_carousel</Icon>
                                    { onlyIcons ? null : <span className="navLabel">Brand Comparison</span> }
                                </Button>
                            </CustomTooltip>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/textDetails">
                            <CustomTooltip
                                title="Text Details"
                                placement="right"
                                disableHoverListener={!onlyIcons}
                                TransitionComponent={Zoom}
                            >
                                <Button>
                                    <Icon>text_fields</Icon>
                                    { onlyIcons ? null : <span className="navLabel">Text Details</span>}
                                </Button>
                            </CustomTooltip>
                        </NavLink>
                    </li>
                    <li>
                        <a onClick={this.handleKibanaOpen} href={this.state.kibanaURL}>
                            <CustomTooltip
                                title="Kibana Report"
                                placement="right"
                                disableHoverListener={!onlyIcons}
                                TransitionComponent={Zoom}
                            >
                                <Button>
                                    <Icon>pie_chart</Icon>
                                    { onlyIcons ? null : <span className="navLabel">Kibana Report</span> }
                                </Button>
                            </CustomTooltip>
                        </a>
                    </li>
                    <li>
                        <NavLink to="/impactAnalysis">
                            <CustomTooltip
                                title="Impact Analysis"
                                placement="right"
                                disableHoverListener={!onlyIcons}
                                TransitionComponent={Zoom}
                            >
                                <Button>
                                    <Icon>insights</Icon>
                                    { onlyIcons ? null : <span className="navLabel">Impact Analysis</span>}
                                </Button>
                            </CustomTooltip>
                        </NavLink>
                    </li>
                </ul>
                <div className="sideBar-Footer">
                    <span className="sideBarFooterProject">CORE | </span>
        <span>{RELEASE_VERSION}</span>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(AsideBar);

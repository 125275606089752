import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { CloudUpload } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      marginBlock: theme.spacing(1),
    },
  },
  fileInput: {
    width: '100%',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    padding: '14px',
    marginBlock: theme.spacing(1),
    border: '1px solid #bbb',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid black'
    },
    '& svg': {
      marginRight: theme.spacing(1),
    },
    '& input': {
      border: 0,
      clip: 'rect(0, 0, 0, 0)',
      height: '1px',
      overflow: 'hidden',
      padding: 0,
      position: 'absolute !important',
      whiteSpace: 'nowrap',
      width: '1px'
    },
    '& #file-input-label': {
      position: 'relative',
      background: '#fff',
      fontSize: '12px',
      color: 'gray',
      marginTop: '-54px',
      marginRight: '-36px',
      padding: '0px 4px 0px 6px'
    }
  },
}))

const CatalogueForm = ({
  open,
  data,
  handleClose,
  handleCatalogueFileSave,
  saving
}) => {
  const classes = useStyles()
  const [name, setName] = useState(data ? data.name : '')
  const [desc, setDesc] = useState(data ? data.desc : '')
  const [delimiter, setDelimiter] = useState(data ? data.delimiter : 'comma')
  const [lineSeparator, setLineSeparator] = useState(data ? data.lineSeparator : 'LF')
  const [file, setFile] = useState(null)

  const handleSave = () => {
    handleCatalogueFileSave(name, desc, delimiter, lineSeparator, file)
  }

  return (
    <Dialog
      className={classes.root}
      open={open}
      onClose={handleClose}
      aria-labelledby='catalogue-form'
      fullWidth
      disableBackdropClick={saving}
      disableEscapeKeyDown={saving}
    >
      <DialogTitle id='catalogue-form'>
        {data ? 'Edit' : 'Create'} Catalogue File
      </DialogTitle>
      <DialogContent>
        <TextField
          id='name'
          name='name'
          label='Name'
          value={name}
          onChange={(e) => setName(e.target.value)}
          variant='outlined'
          fullWidth
          required
          autoFocus
        />
        <TextField
          id='desc'
          name='description'
          label='Description'
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
          variant='outlined'
          multiline
          rowsMax={2}
          fullWidth
        />
        <TextField
          id='lineSeparator'
          name='lineSeparator'
          select
          label='Line Separator'
          value={lineSeparator}
          onChange={(e) => setLineSeparator(e.target.value)}
          variant='outlined'
          fullWidth
          required
        >
          <MenuItem value='LF'>LF</MenuItem>
          <MenuItem value='CR'>CR</MenuItem>
          <MenuItem value='CRLF'>CRLF</MenuItem>
        </TextField>
        <TextField
          id='delimiter'
          name='delimiter'
          select
          label='Delimiter'
          value={delimiter}
          onChange={(e) => setDelimiter(e.target.value)}
          variant='outlined'
          fullWidth
          required
        >
          <MenuItem value='comma'>Comma</MenuItem>
          <MenuItem value='pipe'>Pipe</MenuItem>
          <MenuItem value='tab'>Tab</MenuItem>
        </TextField>
        {!data && (
          <>
            <label className={classes.fileInput}>
              <span id='file-input-label'>File *</span>
              <CloudUpload color='primary' />
              <span>{file ? file.name : 'No file chosen'}</span>
              <TextField
                type='file'
                id='file'
                name='file'
                onChange={(e) => setFile(e.target.files[0])}
              />
            </label>
            <small>* Required</small>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='default' disabled={saving}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          color='primary'
          disabled={!name || (!data && !file) || saving}
        >
          {data ? 'Update' : 'Create'}
          {saving && <CircularProgress size={20} style={{ position: 'absolute'}} />}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CatalogueForm

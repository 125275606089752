import React, { Fragment, useState } from "react";

import { Menu, MenuItem } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";

export default function TopicSentiments({
  id,
  label,
  value,
  options,
  handleChange,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateFilter = (sentiment) => {
    handleChange(sentiment);
    handleClose();
  };

  return (
    <Fragment>
      <div className="display-inlineflex sort-head">
        <div style={{ height: "22px" }}>{label + ":"}&nbsp; </div>
        <div
          style={{ display: "flex", cursor: "pointer" }}
          onClick={handleClick}
        >
          <div
            style={{
              height: "24px",
              lineHeight: "26px",
              textTransform: "capitalize",
            }}
          >
            {value === null ? "ALL" : value}&nbsp;
          </div>
          <div>
            <ArrowDropDown />
          </div>
        </div>
      </div>

      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((li, i) => {
          return (
            <MenuItem
              key={i}
              value={value}
              className={value === li ? "cust-sel selected" : "cust-sel"}
              onClick={() => {
                updateFilter(li);
              }}
            >
              <span>{li}</span>
            </MenuItem>
          );
        })}
      </Menu>
    </Fragment>
  );
}

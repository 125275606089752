import React from 'react';
import TwitterLogin from 'react-twitter-auth/lib/react-twitter-auth-component.js';
import { FacebookProvider, LoginButton } from 'react-facebook';
import { toast } from 'react-toastify';
import client from '../../../../_helpers/client';
import {
    IconButton,
    withStyles,
    Tooltip,
    Zoom,
    Paper,
    Grid, Button,
} from '@material-ui/core';

import DataPreloader from '../../../../containers/Preloader/DataPreloader';
import { TWITTER_GET_AUTHTOKEN, TWITTER_CALLBACK, FACEBOOK_CALLBACK, GET_SOCIAL_MEDIA, DROP_SOCIAL_MEDIA } from '../../../../Routes';
import { WARN, EMPTY_ARRAY, EMPTY_STRING, SUCCESS } from '../../../../Constants';
import { DeleteOutlined, ArrowDropDown } from '@material-ui/icons';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import CloseButton from '@material-ui/icons/Close'
import { Dialog } from '@material-ui/core';
import { stableSort, getSorting } from '../../../../_helpers/utils'


const styles = {
    root: {
        width: '100%',
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
};

class ManageSocialMedia extends React.Component {

    constructor() {
        super();

        this.state = {
            rows: [{ id: 'id', label: 'Id' }, { id: 'media', label: 'Media Type' }],
            order: 'asc',
            orderBy: 'id',
            socialList: EMPTY_ARRAY,
            openDialog: false,
            deletingItem: null,
            searchVal: EMPTY_STRING,
        }

        this.onTwitterFailed = this.onTwitterFailed.bind(this);
        this.onTwitterSuccess = this.onTwitterSuccess.bind(this);
        this.onFacebookFailed = this.onFacebookFailed.bind(this);
        this.onFacebookSuccess = this.onFacebookSuccess.bind(this);
    }

    onTwitterFailed = error => {
        console.log(error.response)
    }

    onTwitterSuccess = data => {
        data.json().then(res => {
            const isAlreadyExist = res.response.isAlreadyExist;
            if (isAlreadyExist)
                toast.warn(res.response.message);
            else {
                toast.success(res.response.message);
                this.getSocialMedia();
            }
        })
    }

    onFacebookFailed = error => {
        console.log(error.response)
    }

    onFacebookSuccess = response => {
        client
            .get(FACEBOOK_CALLBACK, {
                params: {
                    'email': response.profile.email,
                    'facebookUserName': response.profile.name,
                    'userFacebookId': response.profile.id,
                    'userAccessToken': response.tokenDetail.accessToken
                }
            })
            .then(res => {
                const isAlreadyExist = res.data.response.isAlreadyExist;
                if (isAlreadyExist)
                    toast.warn(res.data.response.message);
                else {
                    toast.success(res.data.response.message);
                    this.getSocialMedia();
                }
            })
            .catch(error => {
                if (error.response) toast.error(error.response.data.message)
            })
    }

    //to get social media list
    getSocialMedia = () => {
        client
            .get(GET_SOCIAL_MEDIA)
            .then(res => {
                if (res.data.status === SUCCESS) {
                    let d = [];
                    let n = res.data.response.socialEngagementList.map(list => {
                        let obj = {};
                        obj['id'] = list.id;
                        obj['userName'] = list.userName;
                        obj['media'] = list.engagementType.name;
                        return obj;
                    });
                    this.setState({
                        ...this.state,
                        socialList: d.slice().concat(n),
                    });
                } else {
                    if (res.data.status === WARN) toast.warn(res.data.message)
                    else toast.error(res.data.message)
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.status === WARN) toast.warn(error.response.data.message)
                    else toast.error(error.response.data.message)
                }
            });
    }

    //to remove social media list
    dropSocialMedia = () => {
        client
            .get(DROP_SOCIAL_MEDIA, { params: { id: this.state.deletingItem } })
            .then(res => {
                if (res.data.status === SUCCESS) {
                    const { socialList } = this.state;
                    let del = [];
                    del = socialList.filter(li => this.state.deletingItem !== li.id);
                    this.setState({
                        ...this.state,
                        socialList: del,
                        openDialog: false,
                        deletingItem: null,
                    });
                    toast.success(res.data.message);
                } else {
                    if (res.data.status === WARN) toast.warn(res.data.message)
                    else toast.error(res.data.message)
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.status === WARN) toast.warn(error.response.data.message)
                    else toast.error(error.response.data.message)
                }
            });
    }

    disaplayDeleteAlert = (id) => {
        this.setState({ openDialog: true, deletingItem: id })
    }

    createSortHandler = property => event => {
        const isDesc = this.state.orderBy === property && this.state.order === 'desc';
        this.setState({
            order: isDesc ? 'asc' : 'desc',
            orderBy: property
        })
    };

    handleDialogClose = () => {
        this.setState({ openDialog: false })
    }

    componentDidMount() {
        this.getSocialMedia();
    }

    render() {
        const { classes } = this.props;
        const { socialList, order, orderBy, searchVal } = this.state;
        const jwtToken = localStorage.getItem("jwtToken");
        const customHeader = {};
        customHeader['Authorization'] = jwtToken;

        return (
            <React.Fragment>
                {this.state.visiblePreloader ? <DataPreloader /> : null}
                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleDialogClose}
                >
                    <div className={'uiDialog deleteModel'}>
                        <div className="uiDialogTitle">
                            <h6>Delete</h6>
                        </div>
                        <div className="uiDialogContent">
                            <div>Are you sure want to delete ? </div>
                        </div>
                        <footer>
                            <div>
                                <Button onClick={this.handleDialogClose}>Cancel</Button>
                                <Button onClick={this.dropSocialMedia} color="primary" autoFocus>Delete</Button>
                            </div>
                        </footer>
                    </div>
                </Dialog>
                <h1>Manage Social Media</h1>
                <div className="brandContent user-socialmedia">
                    <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={12} sm={6} md={3}>
                            <div className="sm_btns_cont">
                                <div className="sm_btns">
                                    <h3>Add Account</h3>
                                    <div className="sm-btn twitter-btn">
                                        <TwitterLogin
                                            requestTokenUrl={TWITTER_GET_AUTHTOKEN + "?contentType=public"}
                                            loginUrl={TWITTER_CALLBACK}
                                            onFailure={this.onTwitterFailed}
                                            onSuccess={this.onTwitterSuccess}
                                            showIcon={true}
                                            customHeaders={customHeader}
                                            forceLogin={true}
                                            credentials="omit"
                                            text={'Twitter'}
                                        />
                                    </div>
                                    <div className="sm-btn facebook-btn">
                                        <FacebookProvider appId={231770730495570}>
                                            <LoginButton
                                                scope="email"
                                                onCompleted={this.onFacebookSuccess}
                                                onError={this.onFacebookFailed}
                                            >
                                                <span><i className="fa fa-facebook"></i> Facebook</span>
                                            </LoginButton>
                                        </FacebookProvider>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={9}>
                            <Paper className={classes.root} elevation={4}>
                                <div className="socialMedataTableHeading">
                                    <h3>Active Accounts</h3>
                                    <div className="socilmedia-serach">
                                        <FormControl className={"listViewSearch"}>
                                            <TextField
                                                id="seachmedia-textfield"
                                                placeholder={"Search"}
                                                value={searchVal}
                                                onChange={(e) => { this.setState({ searchVal: e.target.value }) }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Tooltip title="Search">
                                                                <div><IconButton aria-label="search"><Search /></IconButton></div>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="clean input"
                                                                disabled={searchVal.length === 0}
                                                                onClick={(e) => { this.setState({ searchVal: EMPTY_STRING }) }}
                                                            >
                                                                <CloseButton />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="socialMedataTable">
                                    {socialList.length === 0
                                        ? <small>There is no active social media account. Please Add account to continue.</small>
                                        : <div className="stickyHolder">
                                            <Table size="small" aria-label="socilamedia table" className="stickyTable">
                                                <TableHead>
                                                    <TableRow>
                                                        {this.state.rows.map(row => {
                                                            return <TableCell
                                                                key={row.id}
                                                                sortDirection={orderBy === row.id ? order : false}
                                                            >
                                                                <Tooltip
                                                                    TransitionComponent={Zoom}
                                                                    title="Sort"
                                                                    placement={'bottom-start'}
                                                                >
                                                                    <TableSortLabel
                                                                        active={orderBy === row.id}
                                                                        direction={order}
                                                                        onClick={this.createSortHandler(row.id)}
                                                                        IconComponent={ArrowDropDown}
                                                                    >
                                                                        {row.label}
                                                                        {orderBy === row.id ? (
                                                                            <span className={classes.visuallyHidden}>
                                                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                            </span>
                                                                        ) : null}
                                                                    </TableSortLabel>
                                                                </Tooltip>
                                                            </TableCell>
                                                        }, this)}
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {stableSort(socialList, getSorting(order, orderBy))
                                                        .filter(list => list.userName.toLowerCase().includes(searchVal.toLowerCase()) || list.media.toLowerCase().includes(searchVal.toLowerCase()))
                                                        .map(list => (
                                                            <TableRow key={list.id}>
                                                                <TableCell>{list.userName}</TableCell>
                                                                <TableCell>{list.media}</TableCell>
                                                                <TableCell>
                                                                    <Tooltip
                                                                        TransitionComponent={Zoom}
                                                                        title="Remove"
                                                                        placement={'bottom-start'}
                                                                        enterDelay={300}
                                                                    >
                                                                        <IconButton className="deleteBtn" onClick={() => this.disaplayDeleteAlert(list.id)}>
                                                                            <DeleteOutlined />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    }
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        )
    }

}

export default withStyles(styles)(ManageSocialMedia);
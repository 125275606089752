export const ADD_TOPIC = "add/topic"
export const SELECT_PALETTE_TYPE = "select/palette"
export const CREATE_PALETTE = "create/palette"
export const DELETE_PALETTE = "delete/palette"
export const ACTIVATE_PALETTE = "activate/palette"
export const DEACTIVATE_PALETTE = "deactivate/palette"
export const SELECT_TOPIC = "select/topic"
export const GET_TOPICS_DATA = "get/data"
export const CLEAR_TOPICS = "clear/topics"
export const SWITCH_HIGHLIGHT = "switch/highlight"
export const DELETE_TOPIC = "delete/topic"
export const LOADING_ATD = "loading/atd"
export const ADD_BRAND_PALETTES = "add/brandPalettes"
export const SAVE_PALETTE_DATA = "save/paletteData"
export const SET_TOPIC_LIST = "set/topicList"
export const SET_SAVE_LOCATION = "set/saveLocation"
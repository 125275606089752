import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper, } from '@material-ui/core'
import { monthDateYear } from '../../../../_helpers';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  },
  badge: {
    width: '100%'
  }
}))

export default function PricingView (props) {
  
  const { history } = props;
  const classes = useStyles();

  const handlePriceSelect=(e,plan)=> {
    history.push('/workshop');
  }

  return (
    <React.Fragment>
      <div className="pricing-screen">
      <h1>Pricing Plan</h1>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          <Paper elevation={4}>
            <div className='basic'>
            <p className='the-price'>Free</p>
            <div className='pricing-plan'>
              <div className='pBlock'>Basic</div>
              <div className='price-features'>
                <ul className='list-unstyled text-left'>
                  <li>
                    <strong className='text-primary'>Data Sourcing</strong>
                    <ul>
                      <li>
                        <strong>File Upload (2500 Records)</strong>
                      </li>

                      <li>
                        <strong>Twitter / Facebook Integration</strong>
                      </li>

                      <li>
                        <span className='text-muted'>
                          Bazzar Voice Integration
                        </span>
                      </li>

                      <li>
                        <span className='text-muted'>
                          ** Web API for Inbound Streaming Data
                        </span>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong className='text-primary'>
                      Dashboards / Reports
                    </strong>
                    <ul>
                      <li>
                        <strong>Sentiment</strong>
                      </li>

                      <li>
                        <strong>Emotion</strong>
                      </li>

                      <li>
                        <strong>Personas</strong>
                      </li>

                      <li>
                        <strong>Purchase Path</strong>
                      </li>

                      <li>
                        <strong>Performance</strong>
                      </li>

                      <li>
                        <span className='text-muted'>Topics</span>
                      </li>

                      <li>
                        <span className='text-muted'>Home Page Summary</span>
                      </li>

                      <li>
                        <span className='text-muted'>Brand Comparison</span>
                      </li>

                      <li>
                        <span className='text-muted'>Geo</span>
                      </li>

                      <li>
                        <span className='text-muted'>Source Data Summary</span>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong className='text-primary'>Engagement</strong>
                    <ul>
                      <li>
                        <strong>Twitter</strong>
                      </li>

                      <li>
                        <strong>Facebook</strong>
                      </li>

                      <li>
                        <span className='text-muted'>email</span>
                      </li>

                      <li>
                        <span className='text-muted'>Bazzarvoice</span>
                      </li>

                      <li>
                        <span className='text-muted'>TypeForm</span>
                      </li>

                      <li>
                        <span className='text-muted'>Wufoo Form</span>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong className='text-primary'>
                      Models and Classifiers
                    </strong>
                    <ul>
                      <li>
                        <strong>Apply Default Models</strong>
                      </li>

                      <li>
                        <span className='text-muted'>Create Models</span>
                      </li>

                      <li>
                        <span className='text-muted'>Create Classifiers</span>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong className='text-primary'>
                      Monitoring / Listening
                    </strong>
                    <ul>
                      <li>
                        <span className='text-muted'>Rule Based Routing</span>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong className='text-primary'>Advanced Analytics</strong>
                    <ul>
                      <li>
                        <strong>Decooda.R Script Library</strong>
                      </li>

                      <li>
                        <span className='text-muted'>R Studio</span>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong className='text-primary'>
                      Filtering / Exporting
                    </strong>
                    <ul>
                      <li>
                        <strong>Boolean Query Filtering</strong>
                      </li>

                      <li>
                        <span className='text-muted'>Export</span>
                      </li>

                      <li>
                        <span className='text-muted'>Outbound Export</span>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong className='text-primary'>
                      Enterprise Collaboration
                    </strong>
                    <ul>
                      <li>
                        <span className='text-muted'>Real-time chat</span>
                      </li>

                      <li>
                        <span className='text-muted'>Conference calling</span>
                      </li>

                      <li>
                        <span className='text-muted'>File sharing</span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className='price-select'>
                <div className='price-button' role='button' aria-label='Free Plan Button' onClick={(e)=>handlePriceSelect(e,1)}></div>
                <small>Free and Effective date: {monthDateYear(new Date())}.</small>
              </div>
            </div>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <Paper elevation={4}>
            <div className='professional'>
            <p className='the-price'>Please Call</p>
            <div className='pricing-plan '>
              <span className="tag">Popular</span>
              
              <div className='pBlock'>Professional</div>
              <div className='price-features'>
                <ul className='list-unstyled text-left'>
                  <li>
                    <strong className='text-primary'>Data Sourcing</strong>
                    <ul>
                      <li>
                        <strong>File Upload (250MB)</strong>
                      </li>

                      <li>
                        <strong>Twitter / Facebook Integration</strong>
                      </li>

                      <li>
                        <span className='text-muted'>
                          Bazzar Voice Integration
                        </span>
                      </li>

                      <li>
                        <span className='text-muted'>
                          ** Web API for Inbound Streaming Data
                        </span>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong className='text-primary'>
                      Dashboards / Reports
                    </strong>
                    <ul>
                      <li>
                        <strong>Sentiment</strong>
                      </li>

                      <li>
                        <strong>Emotion</strong>
                      </li>

                      <li>
                        <strong>Personas</strong>
                      </li>

                      <li>
                        <strong>Purchase Path</strong>
                      </li>

                      <li>
                        <strong>Performance</strong>
                      </li>

                      <li>
                        <strong>Topics</strong>
                      </li>

                      <li>
                        <strong>Home Page Summary</strong>
                      </li>

                      <li>
                        <strong>Brand Comparison</strong>
                      </li>

                      <li>
                        <strong>Geo</strong>
                      </li>

                      <li>
                        <span className='text-muted'>Source Data Summary</span>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong className='text-primary'>Engagement</strong>
                    <ul>
                      <li>
                        <strong>Twitter</strong>
                      </li>

                      <li>
                        <strong>Facebook</strong>
                      </li>

                      <li>
                        <span className='text-muted'>email</span>
                      </li>

                      <li>
                        <span className='text-muted'>Bazzarvoice</span>
                      </li>

                      <li>
                        <span className='text-muted'>TypeForm</span>
                      </li>

                      <li>
                        <span className='text-muted'>Wufoo Form</span>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong className='text-primary'>
                      Models and Classifiers
                    </strong>
                    <ul>
                      <li>
                        <strong>Apply Default Models</strong>
                      </li>

                      <li>
                        <strong>Create Models</strong>
                      </li>

                      <li>
                        <span className='text-muted'>Create Classifiers</span>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong className='text-primary'>
                      Monitoring / Listening
                    </strong>
                    <ul>
                      <li>
                        <strong>Rule Based Routing</strong>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong className='text-primary'>Advanced Analytics</strong>
                    <ul>
                      <li>
                        <strong>Decooda.R Script Library</strong>
                      </li>

                      <li>
                        <span className='text-muted'>R Studio</span>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong className='text-primary'>
                      Filtering / Exporting
                    </strong>
                    <ul>
                      <li>
                        <strong>Boolean Query Filtering</strong>
                      </li>

                      <li>
                        <strong>Export</strong>
                      </li>

                      <li>
                        <span className='text-muted'>Outbound Export</span>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong className='text-primary'>
                      Enterprise Collaboration
                    </strong>
                    <ul>
                      <li>
                        <span className='text-muted'>Real-time chat</span>
                      </li>

                      <li>
                        <span className='text-muted'>Conference calling</span>
                      </li>

                      <li>
                        <span className='text-muted'>File sharing</span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className='price-select'>
                <div className='price-button'></div>
                <small>Coming Soon..</small>
              </div>
            </div>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <Paper elevation={4}>
            <div className='enterprise'>
            <p className='the-price'>Please Call</p>
            <div className='pricing-plan'>
              
              <div className='pBlock'>Enterprise</div>
              <div className='price-features'>
                <ul className='list-unstyled text-left'>
                  <li>
                    <strong className='text-primary'>Data Sourcing</strong>
                    <ul>
                      <li>
                        <strong>File Upload (1GB)</strong>
                      </li>

                      <li>
                        <strong>Twitter / Facebook Integration</strong>
                      </li>

                      <li>
                        <strong>Bazzar Voice Integration</strong>
                      </li>

                      <li>
                        <strong>** Web API for Inbound Streaming Data</strong>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong className='text-primary'>
                      Dashboards / Reports
                    </strong>
                    <ul>
                      <li>
                        <strong>Sentiment</strong>
                      </li>

                      <li>
                        <strong>Emotion</strong>
                      </li>

                      <li>
                        <strong>Personas</strong>
                      </li>

                      <li>
                        <strong>Purchase Path</strong>
                      </li>

                      <li>
                        <strong>Performance</strong>
                      </li>

                      <li>
                        <strong>Topics</strong>
                      </li>

                      <li>
                        <strong>Home Page Summary</strong>
                      </li>

                      <li>
                        <strong>Brand Comparison</strong>
                      </li>

                      <li>
                        <strong>Geo</strong>
                      </li>

                      <li>
                        <strong>Source Data Summary</strong>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong className='text-primary'>Engagement</strong>
                    <ul>
                      <li>
                        <strong>Twitter</strong>
                      </li>

                      <li>
                        <strong>Facebook</strong>
                      </li>

                      <li>
                        <strong>email</strong>
                      </li>

                      <li>
                        <strong>Bazzarvoice</strong>
                      </li>

                      <li>
                        <strong>TypeForm</strong>
                      </li>

                      <li>
                        <strong>Wufoo Form</strong>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong className='text-primary'>
                      Models and Classifiers
                    </strong>
                    <ul>
                      <li>
                        <strong>Apply Default Models</strong>
                      </li>

                      <li>
                        <strong>Create Models</strong>
                      </li>

                      <li>
                        <strong>Create Classifiers</strong>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong className='text-primary'>
                      Monitoring / Listening
                    </strong>
                    <ul>
                      <li>
                        <strong>Rule Based Routing</strong>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong className='text-primary'>Advanced Analytics</strong>
                    <ul>
                      <li>
                        <strong>Decooda.R Script Library</strong>
                      </li>

                      <li>
                        <strong>R Studio</strong>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong className='text-primary'>
                      Filtering / Exporting
                    </strong>
                    <ul>
                      <li>
                        <strong>Boolean Query Filtering</strong>
                      </li>

                      <li>
                        <strong>Export</strong>
                      </li>

                      <li>
                        <strong>Outbound Export</strong>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong className='text-primary'>
                      Enterprise Collaboration
                    </strong>
                    <ul>
                      <li>
                        <strong>Real-time chat</strong>
                      </li>

                      <li>
                        <strong>Conference calling</strong>
                      </li>

                      <li>
                        <strong>File sharing</strong>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className='price-select'>
                <div className='price-button'></div>
                <small>Coming Soon..</small>
              </div>
            </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
      </div>
    </React.Fragment>
  )
}

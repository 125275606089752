import { useState } from 'react'

const useUndo = (initialState = null) => {
  const [present, setPresent] = useState(initialState)
  const [past, setPast] = useState([])

  const set = (state) => {
    setPast(past.concat([present]))
    setPast([present])
    setPresent(state)
  }

  const undo = () => {
    if (!past.length) return
    const previousState = past[past.length - 1]
    setPresent(previousState)
    setPast(past.slice(0, past.length - 1))
  }

  const canUndo = !!past.length

  return [
    { present, past },
    { set, undo, canUndo }
  ]
}

export default useUndo

import React from "react";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { POSITIVE_EMOTIONS, NEGATIVE_EMOTIONS } from "../../../Constants";
import clsx from "clsx";

const useStyles = makeStyles({
  listItem: {
    color: "white",
    marginBottom: "10px",
    padding: "0 0.5em",
    fontSize: "1em",
    textTransform: "capitalize",
    "& .MuiListItemText-root": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  negative: {
    backgroundColor: "#e86740",
  },
  positive: {
    backgroundColor: "#71a21a",
  },
  delete: {
    backgroundColor: "black",
  },
});

const EmotionPalette = () => {
  const classes = useStyles();

  return (
    <List>
      {NEGATIVE_EMOTIONS.map((item) => (
        <ListItem
          key={item}
          className={clsx(classes.listItem, classes.negative)}
        >
          <ListItemText
            disableTypography
            primary={item == "disappointment" ? "Disappoint" : item}
          />
        </ListItem>
      ))}
      {POSITIVE_EMOTIONS.map((item) => (
        <ListItem
          key={item}
          className={clsx(classes.listItem, classes.positive)}
        >
          <ListItemText disableTypography primary={item} />
        </ListItem>
      ))}
      <ListItem className={clsx(classes.listItem, classes.delete)}>
        <ListItemText disableTypography primary="Delete" />
      </ListItem>
      <ListItem className={clsx(classes.listItem, classes.delete)}>
        <ListItemText disableTypography primary="Delete" />
      </ListItem>
    </List>
  );
};

export default EmotionPalette;

import "./Notifications.css";
import React from "react";
import PropTypes from "prop-types";
import Icon from "@material-ui/core/Icon";
import Tooltop from "@material-ui/core/Tooltip";
import { toast } from "react-toastify";

import {
  Menu,
  IconButton,
  withStyles,
  Badge,
  Button,
  Zoom,
} from "@material-ui/core";
import {
  WARN,
  SUCCESS,
  EMPTY_ARRAY,
  ERROR_MESSAGE,
} from "../../../../Constants";
import {
  CHECK_NOTIFICATIONS,
  GET_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS,
  MAKE_AS_DEFAULT_BRAND,
} from "../../../../Routes";
import client from "../../../../_helpers/client";
import {
  stringToFilterDateTime
} from "../../../../_helpers/utils";

const styles = {
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};
class Notifications extends React.Component {
  state = {
    anchorEl: null,
    notificationCount: 0,
    notifications: EMPTY_ARRAY,
  };

  getNotifications = (event) => {
    this.setState({ anchorEl: event.currentTarget });
    client
      .get(GET_NOTIFICATIONS)
      .then((response) => {
        if (response.data.status === SUCCESS) {
          const ah = response.data.response.map((e) => {
            let divElm = document.createElement("div");
            divElm.innerHTML = e.msg;

            let divChilds = [...divElm.childNodes].map((elm) => {
              if (elm.nodeName === "#text") return elm.nodeValue;
              else {
                if (elm.nodeName === "A") {
                  elm.setAttribute("target", "_blank");
                  return elm.outerHTML;
                } else {
                  return elm.outerHTML;
                }
              }
            });
            e.msg = divChilds.join(" ");
            return e;
          });
          this.setState({
            notifications: ah,
          });
        } else {
          if (response.data.status === WARN) toast.warn(response.data.message);
          else toast.error(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === WARN)
            toast.warn(error.response.data.message);
          else toast.error(error.response.data.message);
        }
      });
  };

  clearNotifications = (e) => {
    client
      .get(CLEAR_NOTIFICATIONS)
      .then((res) => {
        if (res.data.status === SUCCESS) {
          this.setState({
            notifications: [],
            notificationCount: 0,
            anchorEl: null,
          });
        } else {
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === WARN)
            toast.warn(error.response.data.message);
          else toast.error(error.response.data.message);
        }
      });
  };

  checkNotifications = (e) => {
    //Skip check notifications for ATD screen
    var h = window.location.href.split("/").reverse();
    if (h[0] === "atd") return;

    client
      .get(CHECK_NOTIFICATIONS)
      .then((res) => {
        if (res.data.status === SUCCESS) {
          this.setState({ notificationCount: res.data.response.count });
        } else {
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === WARN)
            toast.warn(error.response.data.message);
          else toast.error(error.response.data.message);
        }
      });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  componentDidMount() {
    this.interval = setInterval(() => {
      this.checkNotifications();
    }, 25000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  createNotificationsMarkup = (item) => {
    return {
      __html:
        item.msg +
        " " +
        "<br/><span>" +
        stringToFilterDateTime(item.date) +
        "</span>",
    };
  };

  render() {
    const { anchorEl, notifications } = this.state;
    const { classes, routeProps } = this.props;
    const isMenuOpen = Boolean(anchorEl);

    const handleAtdNavigate = (item) => {
      client
        .get(MAKE_AS_DEFAULT_BRAND, { params: { brandId: item.brand_id } })
        .then((res) => {
          if (res.data.status === SUCCESS) {
            localStorage.setItem(
              "defaultSelectedBrand",
              JSON.stringify(res.data.response)
            );
            routeProps.history.push(`/atd`);
          } else {
            if (res.data.status === WARN)
              toast.update(this.toastId, {
                type: toast.TYPE.WARNING,
                render: ERROR_MESSAGE,
                autoClose: 3500,
              });
            else
              toast.update(this.toastId, {
                type: toast.TYPE.ERROR,
                render: ERROR_MESSAGE,
                autoClose: 3500,
              });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status === WARN)
              toast.update(this.toastId, {
                type: toast.TYPE.WARNING,
                render: error.response.data.message,
                autoClose: 3500,
              });
            else
              toast.update(this.toastId, {
                type: toast.TYPE.ERROR,
                render: error.response.data.message,
                autoClose: 3500,
              });
          }
        });
        this.setState({ anchorEl: null });
    };

    const renderNotifications = (notifications) => {
      return notifications.map((item, i) => {
        return (
          <li
            className={item.message_type === 'atd' ? "atdNotification" : ""}
            onClick={
              item.message_type === 'atd'
                ? () => {
                    handleAtdNavigate(item);
                  }
                : null
            }
            key={i}
            dangerouslySetInnerHTML={this.createNotificationsMarkup(item)}
          />
        );
      });
    };

    return (
      <div className="notificationHolder">
        <Tooltop title="Notifications" TransitionComponent={Zoom}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="Notifications"
            aria-owns={isMenuOpen ? "material-appbar" : undefined}
            aria-haspopup="true"
            onClick={this.getNotifications}
          >
            <Badge
              badgeContent={this.state.notificationCount}
              color="secondary"
            >
              <Icon>notifications_none</Icon>
            </Badge>
          </IconButton>
        </Tooltop>
        <Menu
          className="notificationMenu"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMenuOpen}
          onClose={this.handleMenuClose}
          classes={{ paper: "theme-list" }}
        >
          {notifications.length > 0 ? (
            <div>
              {renderNotifications(notifications)}
              <li className="clearBtn">
                <Button
                  classes={{ label: "notifications" }}
                  onClick={this.clearNotifications}
                  variant="contained"
                  color="secondary"
                >
                  Clear Notifications
                </Button>
              </li>
            </div>
          ) : (
            <li>No Notifications</li>
          )}
        </Menu>
      </div>
    );
  }
}

Notifications.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Notifications);

import React, { Component, createRef } from "react";
import {
  Card,
  CardContent,
  Tooltip,
  IconButton,
  Zoom,
  Menu,
  MenuItem
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { NEGATIVE_EMOTIONS, POSITIVE_EMOTIONS } from '../../../Constants';
import NestedMenuItem from '../nested-menu-item'

export default class ExampleWrapperSimple extends Component {
  state = {
    menuPosition: null,
    isSubMenuOpen: false
  };
  
  containerRef = createRef(null)
  menuContainerRef = createRef(null)
  menuItemRef = createRef(null)

  handleClose = (event) => {
    this.setState({
      menuPosition: null
    });
  };

  handleClick = (event) => {
    this.setState({
      menuPosition: {
        top: event.pageY,
        left: event.pageX
      }
    });
  };

  render() {
    const { name, sentiment, emotion } = this.props.selectedTopic || {}

    return (
      <Card
        className="card-box mb-4-spacing overflow-visible"
        variant="outlined"
      >
        <div className="card-header pr-2">
          <div className="card-header--title" style={{minWidth: this.props.selectedTopic ? '300px' : '140px', marginRight: '10px'}}>
            <div style={{ display: "flex" }}>
              <div className="more-options">
                <Tooltip
                  title="More options"
                  TransitionComponent={Zoom}
                  placement="right"
                >
                  <IconButton onClick={this.handleClick}>
                    <MoreVert />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="more-options-menu"
                  open={!!this.state.menuPosition}
                  anchorReference="anchorPosition"
                  anchorPosition={this.state.menuPosition}
                  onClose={this.handleClose}
                >
                  {this.props.options.map((li, i) => {
                    return (
                      li === "topical" ? (
                        <NestedMenuItem
                          key={li}
                          label={li}
                          menuOpen={!!this.state.menuPosition}
                          onClick={() => {
                            this.props.handleOptions(li);
                            this.handleClose();
                          }}
                        >
                          {this.props.topicalOptions.map((item) => {
                            return (
                              <MenuItem
                                key={item}
                                className={this.props.topicalPhraseType === item ? 'Mui-selected' : 'none'}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.props.handleTopicalOptions(item);
                                  this.handleClose();
                                }}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                        </NestedMenuItem>
                      ) : (
                        <MenuItem
                          key={li}
                          className={this.props.selected === li ? 'Mui-selected' : 'none'}
                          onClick={() => {
                            this.props.handleOptions(li);
                            this.handleClose();
                          }}
                        >
                          {li}
                        </MenuItem>
                      )
                    );
                  })}
                </Menu>
              </div>
              <div className='card-info'>
                <small>{this.props.sectionHeading}</small>
                {this.props.holder === 'phrases' && this.props.selected === "topical" && this.props.topicalPhraseType !== 'all' && (
                  <small>{`(${this.props.topicalPhraseType})`}</small>
                )}
                {this.props.holder === 'phrases' ? (
                  <b>{this.props.loadedItems + '/' + (this.props.count ? this.props.count : 0)} items</b>
                ) : (
                  <b>{(this.props.count ? this.props.count : 0)} items</b>
                )}
              </div>
              {this.props.selectedTopic && (
                <div className='selected-topic-info'>
                  <span>{name}</span>
                  <span>
                    {emotion
                      ? emotion
                      : sentiment &&
                        (NEGATIVE_EMOTIONS.includes(sentiment.toLowerCase()) ||
                          POSITIVE_EMOTIONS.includes(sentiment.toLowerCase()))
                      ? sentiment
                      : 'No Emotion'}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div
            className="card-header--actions"
            style={{minWidth: this.props.selectedTopic ? 'calc(100% - 300px)' : 'calc(100% - 140px)'}}
          >
            {this.props.menu}
          </div>
        </div>
        <CardContent className="p-3 renderList">{this.props.children}</CardContent>
      </Card>
    );
  }
}
import { LOADING_ATD, SET_SAVE_LOCATION } from './types'

export const loadAtd = (value) => ({
  type: LOADING_ATD,
  value
})

export const setSaveLocation = (value) => ({
  type: SET_SAVE_LOCATION,
  value
})
/*  This file defines all application routes (Higher priority routes first)  */
const localApiEndPoint = "http://localhost:9292/mc3";
const apiEndpoint = process.env.REACT_APP_CUSTOM_API_ENDPOINT || localApiEndPoint;

// ENV SETTINGS
export const HOSTPATH = apiEndpoint;

/* Authentication page */
export const AUTHENTICATE = HOSTPATH + "/authentication/authenticate";
export const FORGET_PASSWORD = HOSTPATH + "/authentication/forgetPassword";
export const LOGOUT = HOSTPATH + "/authentication/logout";
export const GET_LANGUAGES = HOSTPATH + "/workshop/language";

export const CHECK_DATA_INDEX = HOSTPATH + "/user/checkUserPlanAndDataIndex";
export const REGISTER_OKTA_USER = HOSTPATH + "/user/registerOktaUser";

/* Registration page */
export const REGISTER = HOSTPATH + "/user/register";
export const CHECK_MAIL = HOSTPATH + "/user/checkEmail";
export const CHECK_PASSWORD = HOSTPATH + "/user/checkPass";

/* HEADER COMPONENT */
export const GET_DEFAULT_BRAND = HOSTPATH + "/brandManager/getDefaultBrand";
export const VIEW_SCORED_BRANDS = HOSTPATH + "/brandManager/viewBrands";
export const MAKE_AS_DEFAULT_BRAND = HOSTPATH + "/brandManager/markBrandAsDefault";
export const EXPORT_BRAND = HOSTPATH + "/brandManager/exportBrand";
export const BRAND_JSON_FIELDS = HOSTPATH + "/brandManager/json_fields";
export const CHECK_NOTIFICATIONS = HOSTPATH + "/user/checkNotifications";
export const GET_NOTIFICATIONS = HOSTPATH + "/user/notifications";
export const CLEAR_NOTIFICATIONS = HOSTPATH + "/user/clearNotification";
export const REFRESH_FILTER = HOSTPATH + "/filter/refreshBrand?isRevert=true&isCompare=false";
export const IS_BRAND_HAVE_LABEL = HOSTPATH + "/brandManager/isBrandHaveSensitiveData"



export const GET_USER_DETAILS = HOSTPATH + "/user/getUserDetails";
export const DELETE_USER = HOSTPATH + "/user/deleteUser";
export const USER_IMPERSONATION = HOSTPATH + "/user/userImpersonation";
export const UPDATE_ADMIN_STATUS = HOSTPATH + "/user/updateAdminStatus"
export const CONFIRM_USER_STATUS = HOSTPATH + "/user/confirmUser"

export const UPDATE_ACTIVE_STATUS = HOSTPATH + "/user/updateActiveStatus";
export const UPDATE_KIBANA_STATUS = HOSTPATH + "/user/changeKibanaAccess";
export const UPDATE_USER_PLAN = HOSTPATH + "/user/updatePlan";
export const BACK_TO_PROFILE = HOSTPATH + "/user/backToProfile";
export const EDIT_FILTERS = HOSTPATH + "/filter/edit";
export const UPDATE_FILTERS = HOSTPATH + "/filter/update";
export const SEARCH_FILTERS = HOSTPATH + "/drilldown/dataForFilteredTextDetails";
export const UPDATE_UNCLASSIFIED = HOSTPATH + "/filter/updateUnclassified";
export const ISFILTEREXIST = HOSTPATH + "/filter/isFilterExist";
export const GET_FILTERED_COUNT = HOSTPATH + "/drilldown/getCountOfData";
export const DELETE_BY_FILTER = HOSTPATH + "/filter/deleteByFilter";

/* USER DETAILS */
export const GET_ALL_USERS = HOSTPATH + "/user/users";
export const USER_BRANDS = HOSTPATH + "/mybrand/userBrands";
export const USER_PROFILE_EDIT = HOSTPATH + "/user/edit";
export const CHECK_OLD_PASSWORD = HOSTPATH + "/user/checkPassword";
export const UPDATE_USER_DETAILS = HOSTPATH + "/user/updateUserDetails";
export const UPDATE_USER_PROFILE = HOSTPATH + "/user/updateProfile";
export const UPDATE_USER_PASSWORD = HOSTPATH + "/user/updatePassword";
export const GET_SOCIAL_MEDIA = HOSTPATH + "/socialmedia/usersocialmedia";
export const DROP_SOCIAL_MEDIA = HOSTPATH + "/socialmedia/dropsocialmedia";
export const DELETE_USER_BRAND = HOSTPATH + "/myBrand/deleteBrandByUserId";
export const UPDATE_MODEL_ID = HOSTPATH + "/myBrand/updateModelId";
export const SET_USER_INDEX = HOSTPATH + "/user/setUpIndex";
export const GET_INDEX_STATUS = HOSTPATH + "/user/getIndexStatus";
export const GET_NEW_TOKENS = HOSTPATH + "/user/createOrRefresh";

/* BRAND COMPONENT */
export const UPDATE_BRAND_IMG = HOSTPATH + "/brands/uploadImage";
export const GET_BRANDS = HOSTPATH + "/myBrand/viewBrandsByParent";
export const GET_BRANDS_DATATABLE = HOSTPATH + "/mybrand/dataTable";
export const SEARCH_BRANDS = HOSTPATH + "/myBrand/searchView";
export const CREATE_BRAND = HOSTPATH + "/mybrand/createBrand";
export const RENAME_BRAND = HOSTPATH + "/mybrand/renameBrand";
export const DELETE_BRAND = HOSTPATH + "/mybrand/deleteBrand";
export const BRAND_DETAILS = HOSTPATH + "/brand/userBrandData";
export const BRAND_INFO = HOSTPATH + "/mybrand/brandInfo";
export const AVAILABLE_DATASOURCES = HOSTPATH + "/brand/availableDataSources";
export const DELETE_DATASOURCE = HOSTPATH + "/brand/deleteBrandDataSource";
export const GET_CSV_DETAILS = HOSTPATH + "/brand/csvDetails";
export const UPLOAD_DATAFILE = HOSTPATH + "/brand/uploadData";
export const SAVE_DATA_DETAILS = HOSTPATH + "/brand/uploadDataDetails";
export const BRAND_DATASOURCES = HOSTPATH + "/mybrand/brandDataSources";
export const BRAND_OUTBOUND_DATASOURCES = HOSTPATH + "/brand/availableOutBoundDB";
export const SAVE_OUTBOUND_DATASOURCES = HOSTPATH + "/brands/saveOutBoundDB";
export const DELETE_OUTBOUND_DATASOURCE = HOSTPATH + "/brands/deleteOutBoundDbData";
export const BRAND_MODEL_TREE = HOSTPATH + "/model/brandModelTreeView";
export const IS_BRAND_EMPTY = HOSTPATH + "/brand/isBrandDataSourceEmpty";
export const BRAND_DATASOURCE_STATUS = HOSTPATH + "/brand/getBrandDataSourceStatus";
export const BRAND_SCORE = HOSTPATH + "/brands/score";
export const DISCOVER_TOPIC_API = HOSTPATH + "/brand/discoverTopic";
export const MOVE_BRAND = HOSTPATH + "/mybrand/move";
export const IS_BRAND_EXISTS = HOSTPATH + "/mybrand/isBrandNameExistAsFileOrFolder";
export const GET_CREATED_PATH = HOSTPATH + "/myBrand/insertedAt";
export const GET_BRAND_BREADCRUMB = HOSTPATH + "/myBrand/getBreadCrumb";
export const GET_BRAND_STATUS = HOSTPATH + "/myBrand/getBrandStatus";
export const UPLOAD_SEED_FILE = HOSTPATH + "/brand/uploadSeedFile";
export const REMOVE_SEED_FILE = HOSTPATH + "/brandManager/removeSeedFile";
export const AVAILABLE_SEED_FILE = HOSTPATH + "/brand/availableSeedFile";
export const DATA_SOURCE_PREPROCESSING = HOSTPATH + "/brand/preprocessingSelections";
export const GET_PREPROCESSING_DATA = HOSTPATH + "/brand/getTextProcessingRules";
export const UPDATE_MISSION_QUES = HOSTPATH + "/brandManager/updateBrandObjective";
export const GET_PRODUCT_DETAILS = HOSTPATH + "/product/getOutboundDetails";
export const SEND_OUTBOUND_REQUEST = HOSTPATH + "/brand/sendOutboundRequest";
export const UPDATE_BRAND_INFO = HOSTPATH + "/brand/updateBrandInfo";

/* BRAND DATA SOURCES */
export const API_DATA_SOURCE = HOSTPATH + "/brand/addApiDataSource";
export const S3_DATA_SOURCE = HOSTPATH + "/brand/addS3DataSource";
export const BAZZAR_VOICE_DATA = HOSTPATH + "/brands/bazaarVoiceData";
export const WUFOO_DATAFILE = HOSTPATH + "/brands/wufooFields";
export const WUFOO_ADDDATASOURCE = HOSTPATH + "/brands/addWufooDataSource";
export const TYPEFORM_FIELDS = HOSTPATH + "/brands/typeFormFields";
export const TYPEFORM_ADDDATASOURCE = HOSTPATH + "/brands/addTypeForm";
export const TWITTER_GET_AUTHTOKEN = HOSTPATH + "/socialmedia/getTwitterToken";
export const TWITTER_CALLBACK = HOSTPATH + "/socialmedia/twittercallback";
export const FACEBOOK_CALLBACK = HOSTPATH + "/socialmedia/saveFaceBookPage";
export const GET_LOCALYTICS_CONTENT_FIELDS = HOSTPATH + "/brand/localyticsContentFields";
export const LOCALYTICS_DATA_SOURCE = HOSTPATH + "/brand/addLocalytics";
export const ADD_DS_IN_CATALOGUE = HOSTPATH + "/brand/addInCatalogue";

/* MODEL COMPONENT */
export const GET_MODELS = HOSTPATH + "/model/viewModelsByParent";
export const GET_MODELS_DATATABLE = HOSTPATH + "/model/dataTable";
export const SEARCH_MODELS = HOSTPATH + "/model/searchTreeView";
export const CREATE_MODEL = HOSTPATH + "/model/create";
export const RENAME_MODEL = HOSTPATH + "/model/rename";
export const DELETE_MODEL = HOSTPATH + "/model/delete";
export const COPY_MODEL = HOSTPATH + "/model/copyModel";
export const COPY_MODEL_TREEDATA = HOSTPATH + "/model/getTreeViewModel";
export const GET_MODEL_DETAILS = HOSTPATH + "/model/edit";
export const GET_MODEL_BUCKET_DATA = HOSTPATH + "/model/getBucketChilds";
export const GET_MODEL_CLASSIFIER_TREE = HOSTPATH + "/model/modelFrameTreeView";
export const SEARCH_MODEL_CLASSIFIER_TREE = HOSTPATH + "/model/searchFrameTreeView";
export const ADD_CLASSIFER_ITEMS = HOSTPATH + "/model/addItem";
export const DELETE_CLASSIFIER_ITEM = HOSTPATH + "/model/deleteItem";
export const EXPORT_JSON_MODEL = HOSTPATH + "/model/exportModel";
export const EXPORT_CSV_MODEL = HOSTPATH + "/model/exportCSVModel";
export const MOVE_MODEL = HOSTPATH + "/model/move";
export const IS_MODEL_EXISTS = HOSTPATH + "/model/isModelNameExist";
export const GET_MODEL_SHARE = HOSTPATH + "/model/getDomainsForShare";
export const UPDATE_MODEL_SHARING_DOMAINS = HOSTPATH + "/model/shareStatus";
export const GET_MODEL_BREADCRUMB = HOSTPATH + "/model/getBreadCrumb";
export const GET_EXPORT_CLASSIFIER_NAMES = HOSTPATH + "/model/exportCSVModelClassifier";
export const DELETE_MULTIPLE_ITEMS = HOSTPATH + "/model/deleteItems";
export const REFRESH_MODEL = HOSTPATH + "/model/refreshModel";

/* DATA CATALOGUE COMPONENT */
export const GET_CATALOGUE = HOSTPATH + "/datacatalogue/viewDataCatalogue";
export const GET_CATALOGUE_DATATABLE = HOSTPATH + "/datacatalogue/catologueDataTable";
export const CREATE_CATALOGUE = HOSTPATH + "/datacatalogue/createDataCatalogue";
export const RENAME_CATALOGUE = HOSTPATH + "/datacatalogue/renameCatalogue";
export const DELETE_CATALOGUE = HOSTPATH + "/datacatalogue/deleteDataCatalogue";
export const UPDATE_CATALOGUE = HOSTPATH + "/datacatalogue/updateDataCatalogue";
export const GET_CATALOGUE_FILE = HOSTPATH + "/datacatalogue/dataCatalogueFile";
export const GET_CATALOGUE_BREADCRUMB = HOSTPATH + "/datacatalogue/getBreadCrumb";
export const SEARCH_CATALOGUE = HOSTPATH + "/datacatalogue/searchTreeview";
export const IS_CATALOGUE_EXISTS = HOSTPATH + "/datacatalogue/isDatacatalogueNameExist";

/* CLASSIFIER COMPONENT */
export const GET_CLASSIFIERS = HOSTPATH + "/frame/viewFramesByParent";
export const GET_CLASSIFIERS_DATATABLE = HOSTPATH + "/frame/getDataTable";
export const SEARCH_CLASSIFIERS = HOSTPATH + "/frame/searchTreeView";
export const CREATE_CLASSIFIER = HOSTPATH + "/frame/create";
export const RENAME_CLASSIFIER = HOSTPATH + "/frame/rename";
export const DELETE_CLASSIFIER = HOSTPATH + "/frame/delete";
export const COPY_CLASSIFIER = HOSTPATH + "/frame/copyClassifier";
export const COPY_CLASSIFIER_TREEDATA = HOSTPATH + "/frame/treeViewCopyClassifier";
//export const GET_CLASSIFIER_DETAILS_INIT = HOSTPATH + "/frame/getRegularFrames";

export const GET_CLASSIFIER_DATA = HOSTPATH + "/classifier/getClassifierData";
export const GET_CLASSIFIER_PHRASES = HOSTPATH + "/classifier/getClassifierPhrases";
export const GET_CLASSIFIER_SHARE = HOSTPATH + "/classifier/getDomainsForShare";
export const GET_CLASSIFIER_DETAILS = HOSTPATH + "/classifier/getClassifierDetails";
export const GET_CLASSIFIER_CATEGORIES = HOSTPATH + "/classifier/getClassifierCategories";

export const UPDATE_CLASSIFIER_TYPE = HOSTPATH + "/classifier/updateClassifierType";
export const UPDATE_CLASSIFIER_SENTIMENT = HOSTPATH + "/classifier/updateSentimentByFrameId";
export const UPDATE_CLASSIFIER_EMOTION = HOSTPATH + "/classifier/updateEmotionByFrameId";
export const UPDATE_CLASSIFIER_SHARING_DOMAINS = HOSTPATH + "/classifier/updateSharingDomains";
export const SHOW_BINDED_MODELS = HOSTPATH + "/frame/modelList";
export const ADD_NEW_PHRASES = HOSTPATH + "/frame/updateFramePhrases";
export const DELETE_PHRASES = HOSTPATH + "/frame/deleteSinglePhrase";
export const RENAME_PHRASE = HOSTPATH + "/frame/updatePhrase";
export const UPDATE_DESC = HOSTPATH + "/classifier/updateDescription";
export const IMPORT_CLASSIFIERS = HOSTPATH + "/classifier/importFrame";
export const EXPORT_CLASSIFIERS = HOSTPATH + "/classifier/exportFolder";
export const EXPORT_CLASSIFIERS_IDNAMES = HOSTPATH + "/frame/exportClassifiers";
export const ADD_CLASSIFIER = HOSTPATH + "/contextmenu/addclassifier";
export const MOVE_CLASSIFIER = HOSTPATH + "/frame/move";
export const IS_CLASSIFIER_EXISTS = HOSTPATH + "/frame/isFrameNameExist";
export const GET_CLASSIFIER_BREADCRUMB = HOSTPATH + "/frame/getBreadCrumb";
export const VC_ADD_CHILDS = HOSTPATH + "/classifier/addChildFrames";
export const VC_DELETED_LIST = HOSTPATH + "/classifier/deletePhrases";
export const VC_RENAME_PHRASES = HOSTPATH + "/classifier/updateVirtualPhrases";
export const VC_UPDATE_CLASSIFIER = HOSTPATH + "/classifier/updateClassifierType";
export const GET_FRAME_CHILD = HOSTPATH + "/classifier/frameChildren";
export const UPDATE_VIRTUAL_CLASSIFIER = HOSTPATH + "/classifier/virtualFrameSave";

export const VC_UPDATE_SENTIMENT_EMOTION = HOSTPATH + "/classifier/updateSentimentAndEmotion";
export const UPDATE_CALCULATED_CLASSIFIER = HOSTPATH + "/classifier/calculatedClassifier";

export const CREATE_CATEGORY = HOSTPATH + "/classifier/createCategory";
export const DELETE_CATEGORY = HOSTPATH + "/classifier/delete";
export const SELECT_CATEGORY = HOSTPATH + "/classifier/selectCategory";
export const DESELECT_CATEGORY = HOSTPATH + "/classifier/unSelectCategory";
export const CLASSIFIER_SPECIFICATION = HOSTPATH + "/classifier/updateFrameSpecification";
export const SCORE_ANALYSIS = HOSTPATH + "/frame/scoreAnalysis";
export const GET_DECOODA_CHINESE_ID = HOSTPATH + "/classifier/getDecoodaChineseId";
export const TEST_CALCULATED_CLASSIFIER = HOSTPATH + "/classifier/testCalcClassifier";
export const GET_REGEX_MATCH = HOSTPATH + "/classifier/testRegex";

/* COLLECTIONS COMPONENT */
export const GET_COLLECTIONS = HOSTPATH + "/collection/viewCollectionsByParent";
export const GET_COLLECTIONS_DATATABLE = HOSTPATH + "/collection/dataTable";
export const SEARCH_COLLECTIONS = HOSTPATH + "/collection/searchView";
export const CREATE_COLLECTION = HOSTPATH + "/collection/create";
export const RENAME_COLLECTION = HOSTPATH + "/collection/rename";
export const DELETE_COLLECTION = HOSTPATH + "/collection/delete";
export const GET_COLLECTION_DETAILS = HOSTPATH + "/collection/edit";
export const GET_CONTENT_DETAILS = HOSTPATH + "/collection/contents";
export const MOVE_COLLECTION = HOSTPATH + "/collection/move";
export const IS_COLLECTION_EXISTS = HOSTPATH + "/collection/isCollectionNameExist";
export const GET_COLLECTION_BREADCRUMB = HOSTPATH + "/collection/getBreadCrumb";

/* TOTAL_POST/CURRENT_POST/NET_SENTIMENT/FILTER_POST */
export const BRAND_TOTAL_CURRENT_POST = HOSTPATH + "/brandManager/populateBrandSummary";
export const BRAND_NET_FILTER_POST = HOSTPATH + "/brandManager/netSentiment";

/* SUMMARY DASHBOARD */
export const SENTIMENT_SUMMARY = HOSTPATH + "/sentiment/summary";
export const SENTIMENT_TREND = HOSTPATH + "/sentiment/summarytrend";
export const EMOTION_SUMMARY = HOSTPATH + "/emotions/summary";
export const EMOTION_TREND = HOSTPATH + "/emotions/trend";
export const SENTIMENT_MEDIATABLE = HOSTPATH + "/sentiment/mediaTypeDataTable";
export const TOPICS_MEDIATABLE = HOSTPATH + "/topics/dataTable";
export const PREFORMANCE_MEDIATABLE = HOSTPATH + "/performance/dataTable";
export const PRESONA_MEDIATABLE = HOSTPATH + "/persona/dataTable";
export const PURCHASEPATH_MEDIATABLE = HOSTPATH + "/purchasepath/dataTable";

export const EXPORT_SENTIMENT_TABLE = HOSTPATH + "/sentiment/exportSentiments";
export const EXPORT_PERFORMANCE_TABLE = HOSTPATH + "/performance/exportPerformance";
export const EXPORT_PERSONA_TABLE = HOSTPATH + "/persona/exportPersonas";
export const EXPORT_PURCHASEPATH_TABLE = HOSTPATH + "/purchasepath/exportPurchasePaths";
export const EXPORT_TOPICS_TABLE = HOSTPATH + "/topics/exportTopics";

/* SENTIMENT COMPONENT */
export const SENTIMENT_BREAKDOWN = HOSTPATH + "/sentiment/breakdown";
export const SENTIMENT_MEDIA = HOSTPATH + "/sentiment/mediatype";
export const SENTIMENT_ALL_MEDIA = HOSTPATH + "/sentiment/mediatypetrend";

/* EMOTION COMPONENT */
export const EMOTION_MEDIA = HOSTPATH + "/emotions/mediaType";
export const EMOTION_POSITIVE_BREAKDOWN = HOSTPATH + "/emotions/positiveTrend";
export const EMOTION_NEGATIVE_BREAKDOWN = HOSTPATH + "/emotions/negativeTrend";

/* PERSONA COMPONENT */
export const PERSONA_TREND = HOSTPATH + "/persona/trendChart";
export const PERSONA_EMOTION = HOSTPATH + "/persona/emotionChart";
export const PERSONA_SENTIMENT = HOSTPATH + "/persona/sentimentChart";
export const PERSONA_TABLE = HOSTPATH + "/persona/dataTable";
export const PERSONA_SUMMARY = HOSTPATH + "/persona/summary";
export const PERSONA_FOR_EMOTION = HOSTPATH + "/persona/forEmotion";
export const PERSONA_EMOTIONS = HOSTPATH + "/persona/emotions";

/* ROUTUNG RULES */
export const ROUTING_RULES_VIEW = HOSTPATH + "/routingrules/view";
export const ROUTING_RULES_EDIT = HOSTPATH + "/routingrules/edit";
export const ROUTING_COLLECTION_TREE = HOSTPATH + "/collection/routeCollectionTree";
export const ROUTING_UPDATE = HOSTPATH + "/routingrules/update";
export const ROUTING_RULE_DELETE = HOSTPATH + "/routingrules/delete";

/* TEXT DETAILS */
export const DRILLDOWN_VIEW = HOSTPATH + "/drilldown/view";
export const DRILLDOWN_POPULATE_CACHE = HOSTPATH + "/drilldown/populatecache";
export const DRILLDOWN_DATA = HOSTPATH + "/drilldown/data";
export const DRILLDOWN_FILTERS = HOSTPATH + "/drilldown/filterdata";
export const DRILLDOWN_INDIVIDUAL_HEADER = HOSTPATH + "/drilldown/getSingleContent";
export const DRILLDOWN_INDIVIDUAL_RAWJSON = HOSTPATH + "/drilldown/content";
export const DRILLDOWN_INDIVIDUAL_DELETE = HOSTPATH + "/drilldown/deleteContent";
export const ADD_TO_COLLECTIONS = HOSTPATH + "/collection/addToCollection";
export const DELETE_FROM_COLLECTIONS = HOSTPATH + "/collection/deleteItem";
export const GET_MODEL_TREE_DATA = HOSTPATH + "/frame/framesForClassifierExtention";
export const TREEVIEW_FOR_SPAM = HOSTPATH + "/frame/treeViewForSpam";
export const SM_REPLY_TIMELINE = HOSTPATH + "/engagement/timeline";
export const ADD_POST_IN_SPAM = HOSTPATH + "/contextmenu/addPostInSpam";

/* LIST VIEW COMPONENT */
export const GET_CANDIDATE_PHRASES_LIST = HOSTPATH + "/frame/candidatesPhrases";
export const ADD_DELETED_TOPICS_PHRASES_INTO_CANDIDATES = HOSTPATH + "/frame/deleteListFrame";
export const ADD_DELETED_PHRASES_INTO_CANDIDATES = HOSTPATH + "/frame/addPhrase";
export const RENAME_CANDIDATE = HOSTPATH + "/frame/updateCandidatePhrase";
export const DELETE_CANDIDATE = HOSTPATH + "/frame/deleteCandidatePhrase";
export const GET_CANDIDATE_PHRASE_CONTENT = HOSTPATH + "/drilldown/candidatePhraseContents";

export const GET_GRAM_PRHARSES_LIST = HOSTPATH + "/frame/getGramPhrases";
export const RENAME_GRAM = HOSTPATH + "/frame/updateGramPhrase";
export const DELETE_GRAM = HOSTPATH + "/frame/deleteGramPhrase";
export const MERGE_GRAM_SINGLE_PHRASE = HOSTPATH + "/frame/mergeGramPhrase";

export const GET_TOPICS_LIST = HOSTPATH + "/frame/topicListView";
export const DELETE_TOPIC = HOSTPATH + "/frame/deleteFrames";
export const CHECK_TOPIC_EXIST = HOSTPATH + "/frame/isTopicNameExist";
export const CREATE_LISTVIEW_TOPIC = HOSTPATH + "/frame/createClassifier";
export const MERGE_TOPICS = HOSTPATH + "/frame/mergeTopics";
export const MERGE_SINGLE_PHRASE = HOSTPATH + "/frame/mergeSinglePhrase";

export const GET_PHRASES_LIST = HOSTPATH + "/frame/phrases";
export const RENAME_LIST_PHRASE = HOSTPATH + "/frame/updatePhrase";
export const MERGE_PHRASES = HOSTPATH + "/frame/mergePhraseWithOtherFrame";

/* OTHER API */
export const GET_KIBANA_URL = HOSTPATH + "/user/kibanaUrl";

/* BRANDS COMPARISON */
export const BRAND_COMPARISON_HEADER = HOSTPATH + "/comparison/brandsComparisonHeader";
export const BRANDS_FOR_COMPARISON = HOSTPATH + "/comparison/brandsForComparison";
export const POPULATE_BRANDS_COMPARISON_CACHE = HOSTPATH + "/comparison/populateBrandsComparisonCache";
export const POPULATE_COMPARISON_CACHE_SELECTEDBRAND = HOSTPATH + "/comparison/populateComparisonCacheWithSelectedBrand";
export const DISPLAY_SENTIMENT_CHART = HOSTPATH + "/comparison/sentiment";
export const DISPLAY_EMOTION_CHART = HOSTPATH + "/comparison/emotion";
export const DISPLAY_PERSONAS_CHART = HOSTPATH + "/comparison/personas";
export const DISPLAY_PERFORMANCE_CHART = HOSTPATH + "/comparison/performance";
export const DISPLAY_PURCHASEPATH_CHART = HOSTPATH + "/comparison/purchasePath";
export const DISPLAY_TOPICS_CHART = HOSTPATH + "/comparison/topics";

export const PROPORTIONAL_VIEW = HOSTPATH + "/comparison/proportionalView";
export const CLEAR_BRANDS_COMPARISON = HOSTPATH + "/comparison/clearBrandForComparison";

/* TOPICS - Auto Topic Discovery - unused */
export const GET_TREE_TOPICS = HOSTPATH + "/mlTopics/getTreeView";
export const GET_CONTEXT_HIGHLIGHTS = HOSTPATH + "/mlPhrases/getContextHighlights";
export const RENAME_ML_PHRASE = HOSTPATH + "/mlTopics/updatePhraseName";
export const UPDATE_PHRASE_SENTIMENT = HOSTPATH + "/mlTopics/updatePhraseSentiment";
export const CANDIDATE_UPDATE_SENTIMENT = HOSTPATH + "/mlTopics/updateCandidatePhraseSentiment";
export const CANDIDATE_RENAME = HOSTPATH + "/mlTopics/updateCandidatePhraseName";

/* TOPICS - Auto Topic Discovery */
export const GET_SUMMARY_DATA = HOSTPATH + "/mlTopics/summaryData";
export const UPLOAD_ATD_SEED_FILE = HOSTPATH + "/brand/uploadSeedFileAndRunATD";
export const FETCH_DISCOVERED_TOPICS = HOSTPATH + "/mlTopics/getDiscoveredTopics";
export const EXPORT_DISCOVERED_TOPIC = HOSTPATH + "/mlPhrases/exportTopicsByFolder";
export const GET_ATD_STATE = HOSTPATH + "/mlTopics/getTopicState";
export const GET_TOPICS = HOSTPATH + "/mlTopics/getTopics";
export const RENAME_TOPICS = HOSTPATH + "/mlTopics/updateTopicName";
export const DELETE_TOPICS = HOSTPATH + "/mlTopics/deleteTopic";
export const UPDATE_TOPIC_SENTIMENT = HOSTPATH + "/mlTopics/updateTopicSentiment";
export const NEW_TOPIC_WITH_PHRASES = HOSTPATH + "/mlTopics/createTopic";
export const IS_TOPIC_EXISTS = HOSTPATH + "/mlTopics/isTopicNameExists";
export const ADD_TOPIC_PHRASE = HOSTPATH + "/mlTopics/dropPhraseInTopics";
export const GET_EXPORT_TOPICS = HOSTPATH + "/mlTopics/exportTopics";

export const GET_PHRASES = HOSTPATH + "/mlTopics/getPhrasesInfo";
export const MOVE_PHRASES = HOSTPATH + "/mlTopics/movePhrases";
export const DELETE_ML_PHRASE = HOSTPATH + "/mlTopics/deletePhrase";

export const GET_CANDIDATE_PHRASES = HOSTPATH + "/mlTopics/candidatePhrases";
export const CANDIDATE_DELETE = HOSTPATH + "/mlTopics/deleteCandidatePhrase";

export const GET_NGRAM_PHRASES = HOSTPATH + "/mlTopics/ngramPhrases";
export const DELETE_NGRAM = HOSTPATH + "/mlTopics/deleteNGrams";

export const GET_UNCLASSIFIED_DATA = HOSTPATH + "/mlTopics/unclassifiedDoc";

export const SELECTED_TEXT_NEW_TOPIC = HOSTPATH + "/mlTopics/newTopicPhrase";
export const SELECTED_TEXT_NEW_PHRASE = HOSTPATH + "/mlTopics/addPhrase";
export const NEW_CANDIDATE_PHRASE = HOSTPATH + "/mlCandidate/addPhrase";
export const ATD_FOLDER_DELETE = HOSTPATH + "/mlTopics/deleteTopicFolder";
export const GET_PHRASE_PATTERN = HOSTPATH + "/mlTopics/getPhrasePatterns";
export const SET_FAVORITE_PATTERN = HOSTPATH + "/mlPhrases/setFavPattern";

export const AUTO_REGEX_REQUEST = HOSTPATH + "/brand/submitAutoRegexRequest";
export const PROCESSED_ENTITY = HOSTPATH + "/mlTopics/updateProcessedDoc";
export const GET_PIPELINE_PARAMS = HOSTPATH + "/brandManager/inputParamValues";

export const GET_TOPICS_PHRASE_CONTEXT = HOSTPATH + "/mlTopics/getTopicsContext";
export const SEARCH_DOCUMENTS = HOSTPATH + "/mlTopics/searchContexts";
export const GET_ALL_CONTEXTS = HOSTPATH + "/mlTopics/getAllContexts";

export const GET_ALL_TOPICS = HOSTPATH + "/mlTopics/getAllTopics";
export const GET_TOPIC_DETAILS = HOSTPATH + "/mlTopics/getTopicDetails";
export const EXPORT_DATA_SOURCE = HOSTPATH + "/brand/exportDataSource";

export const SAVE_TO_CLOUD = HOSTPATH + "/mlTopics/atdCloudSave";
export const GET_CLOUD_DATA = HOSTPATH + "/mlTopics/getCloudSavedData";

// Dataset
export const GET_BRAND_DATASET_LABELS = HOSTPATH + "/brand/getBrandDatasetLabels";
export const UPDATE_BRAND_DATASET_LABEL = HOSTPATH + "/brand/updateBrandDatasetLabel";

// impact analysis
export const GET_BRAND_IMPACT_ANALYSIS = HOSTPATH + "/brand/impactAnalsis"

import { GET_TOPICS_DATA } from '../actions/types'
import get from 'lodash.get'

let initialState = []

export default function ActivePalette(state = initialState, action) {
  const data = get(action, 'data')

  switch (action.type) {
    case GET_TOPICS_DATA : {
      return data
    }
    default: {
      return state;
    }
  }
}
/**
 * Create a new Axios client instance
 * @see https://github.com/mzabriskie/axios#creating-an-instance
 */

import axios from 'axios';
import { Auth } from 'aws-amplify';

const getClient = async (baseUrl = null) => {
  const options = { baseURL: baseUrl, };
  
  try {
    const tokens = await Auth.currentSession()
    options.headers = {
      Authorization: `${tokens.getIdToken().getJwtToken()}`,
    };

    const spyUser = JSON.parse(localStorage.getItem('spyUser'));
    if(spyUser) {
      options.headers = {
        Authorization: `${tokens.getIdToken().getJwtToken()}`,
        Spy_User_Id: `${spyUser.id}`,
      };
    }
  } catch(err) {
    window.location.href = '/login';
  }

  const client = axios.create(options);

  // Add a request interceptor
  client.interceptors.request.use(
    requestConfig => requestConfig,
    requestError => {
      return Promise.reject(requestError)
    }
  );

  // Add a response interceptor
  client.interceptors.response.use(
    response => response,
    responseError => {
      var h = (window.location.href).split('/').reverse();

      if(!responseError.response || 
          responseError.response.status === 440 ||
          responseError.response.status === 401
      ) {
        if(h[0] === 'atd')
          return
      } else {
        console.log('Error', responseError.message);
      }
      return Promise.reject(responseError);
    },
  );

  return client;
};

/**
* Base HTTP Client
*/
export default {
  // Provide request methods with the default base_url
  async get(url, conf = {}) {
    const client = await getClient()
    return client.get(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  async post(url, data = {}, conf = {}) {
    const client = await getClient()
    return client.post(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
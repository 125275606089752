import "./atd-styles/atd.scss";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Button,
  TextField,
  Input,
  InputAdornment,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HeaderCard from "../../containers/decooda-components/header-card";
import TopicScroll from "./topic-scroll";
import PhraseScroll from "./phrase-scroll";
import TopicSentiments from "./sentiment-filters";
import DropDownNgrams from "./ngrams-dropdown";
import DropdownSort from "./dropdown-sort";
import DropdownPhrasePattern from "./phrase-pattern";
import CreateTopicModal from "./new-topic-modal";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TopicPalette from "./topic-palette-widget";
import { Prompt, useLocation } from "react-router";
import { v4 as uuidv4 } from "uuid";

import {
  EMPTY_ARRAY,
  EMPTY_STRING,
  SUCCESS,
  ERROR_MESSAGE,
  EMPTY_OBJECT,
  POSITIVE_EMOTIONS,
  NEGATIVE_EMOTIONS,
} from "../../Constants";
import {
  FETCH_DISCOVERED_TOPICS,
  GET_TOPICS,
  GET_ALL_TOPICS,
  GET_TOPIC_DETAILS,
  GET_CANDIDATE_PHRASES,
  GET_NGRAM_PHRASES,
  GET_UNCLASSIFIED_DATA,
  GET_PHRASE_PATTERN,
  UPLOAD_ATD_SEED_FILE,
  AUTO_REGEX_REQUEST,
  PROCESSED_ENTITY,
  GET_PIPELINE_PARAMS,
  GET_SUMMARY_DATA,
  GET_ALL_CONTEXTS,
  GET_CLOUD_DATA,
  SAVE_TO_CLOUD,
  GET_EXPORT_TOPICS,
} from "../../Routes";
import client from "../../_helpers/client";
import { custSpecialObjToCSV } from "./atd-utils";
import DataPreloader from "../../containers/Preloader/DataPreloader";
import AtdDialog from "./atd-dialog/atd-dialog";
import AtdDrawer from "./rightside-drawer/atd-drawer";
import { toast } from "react-toastify";

import {
  handleCsvFileDownload,
  isEmptyObject,
  localDateTime,
  resizeSetup,
  getUniqueListBy,
} from "../../_helpers/utils";
import {
  doTopicSorting,
  doPhraseSorting,
  doPatternFilter,
  doPhraseSearch,
  doContextTrim,
  highLightCandidate,
  mainTopicWithDataAttr,
  sideTopicWithDataAttr,
  candidateMainHighLight,
  ngramMainHighLight,
  uniqueArray,
  doTopicSearch,
  highLightSearchWord,
} from "./atd-utils";
import { get, cloneDeep, uniqBy } from "lodash";
import lodashOrderBy from "lodash.orderby";
import {
  addBrandPalettes,
  loadAtd,
  savePaletteData,
  setTopicsData,
  setSaveLocation,
  deleteTopic,
} from "../../actions";
import lodashFilter from "lodash.filter";
import lodashSome from "lodash.some";
import loadashValues from "lodash.values";
import lodashMerge from "lodash.merge";
import lodashKeyBy from "lodash.keyby";

import HeaderStat from "./header-stat";
import NoBrandSelected from "../../containers/NoBrandSelected/NoBrandSelected";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import clsx from "clsx";
import * as localForage from "localforage";
import useUndo from "../../hooks/useUndo";

const useStyles = makeStyles((theme) => ({
  paletteMinimized: {
    height: "45px !important",
    overflow: "hidden",
    position: "absolute",
    bottom: 16,
    right: 20,
    transition: "height 0.4s",
  },
  phrasesAndPaletteContainer: {
    width: "100%",
    display: "flex",
    "& .resizer": {
      cursor: "ew-resize",
      height: "100%",
      width: "8px",
      padding: "0 2px",
      transition: "height 0.4s",
      "& .resize-divider": {
        width: "2px",
        height: "inherit",
        backgroundColor: "#ccc",
        margin: "0 auto",
      },
      "& .resize-hook": {
        width: "4px",
        height: "20px",
        position: "absolute",
        top: "50%",
        backgroundColor: "#fff",
        border: "1px solid #aaa",
        borderRadius: "2px",
      },
    },
    "& .phrases-holder": {
      width: "75%",
      minWidth: "50%",
      maxWidth: "85%",
      [theme.breakpoints.down(1368)]: {
        width: "75%",
        minWidth: "65%",
        maxWidth: "80%",
      },
    },
    "& .paletteSection": {
      width: "calc(25% - 8px)",
      minWidth: "calc(15% - 8px)",
      maxWidth: "calc(50% - 8px)",
      flex: "1 1 0%",
      position: "absolute",
      right: 0,
      [theme.breakpoints.down(1368)]: {
        width: "calc(25% - 8px)",
        minWidth: "calc(20% - 8px)",
        maxWidth: "calc(35% - 8px)",
      },
    },
  },
  dialogTitle: {
    paddingBottom: "4px",
  },
}));

const ATD = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  //Topic Palette State
  const isSelectedTopic = useSelector((state) => state.selectedTopic);
  const isTopicPaletteHighlightActive = useSelector(
    (state) => state.isActiveHighlight
  );
  const topicPalettes = useSelector((state) => state.topicPalettes);
  // ATD reload after brand change
  const atdLoading = useSelector((state) => state.atdLoading);
  const saveLocation = useSelector((state) => state.saveLocation);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedDiscoverdTopic, setSelectedDiscoverdTopic] = useState({
    value: 0,
    label: EMPTY_STRING,
  });

  const [atdState, setAtdState] = useState(null);
  const [{ present: topics }, { set: setTopics, undo: undoTopics }] = useUndo(EMPTY_ARRAY);
  const [{ present: dataTopics }, { set: setDataTopics, undo: undoDataTopics }] = useUndo(EMPTY_ARRAY);
  const [selectedTopics, setSelectedTopics] = useState(EMPTY_ARRAY);
  const [prevSelectedTopics, setPrevSelectedTopics] = useState(EMPTY_ARRAY);
  const [selectedTopicSentiment, setSelectedTopicSentiment] = useState(null);
  const [topicCurateType, setTopicCurateType] = useState("all");
  const [topicSortBy, setTopicSortBy] = useState(EMPTY_STRING);
  const [topicSortType, setTopicSortType] = useState("asc");

  const [summaryData, setSummaryData] = useState(EMPTY_OBJECT);
  const [totalPhrases, setTotalPhrases] = useState(0);
  const [phrasesPage, setPhrasesPage] = useState(1);
  const [phrases, setPhrases] = useState(EMPTY_ARRAY);
  const [isCandidate, setIsCandidate] = useState(false);
  const [isNgram, setIsNgram] = useState(false);
  const [isunClassified, setIsunClassified] = useState(false);
  const [selectedPhrases, setSelectedPhrases] = useState(EMPTY_ARRAY);
  const [prevSelectedPhrases, setPrevSelectedPhrases] = useState(EMPTY_ARRAY);
  const [{ present: candidatePhrases }, { set: setCandidatePhrases, undo: undoCandidatePhrases }] = useUndo(EMPTY_ARRAY);
  const [{ present: unClassifiedPhrases }, { set: setUnClassifiedPhrases, undo: undoUnClassifiedPhrases }] = useUndo(EMPTY_ARRAY);

  const [gramWeight, setGramWeight] = useState([2]);
  const [{ present: ngramData }, { set: setNgramData, undo: undoNgramData }] = useUndo(EMPTY_ARRAY);
  const [candidateTotalCount, setCandidateTotalCount] = useState(0);
  const [unclassifiedTotalCount, setUnclassifiedTotalCount] = useState(0);
  const [searchTopic, setSearchTopic] = useState(EMPTY_STRING);
  const [searchWord, setSearchWord] = useState(EMPTY_STRING);
  const [wordsBefore, setWordsBefore] = useState(EMPTY_STRING);
  const [wordsAfter, setWordsAfter] = useState(EMPTY_STRING);
  const [sortBy, setSortBy] = useState(EMPTY_STRING);
  const [sortType, setSortType] = useState("asc");
  const [phrasePattern, setPhrasePattern] = useState(EMPTY_ARRAY);
  const [favoritePatterns, setFavoritePatterns] = useState(EMPTY_ARRAY);

  const [open, setOpen] = useState(false);
  const [newTopicTitle, setNewTopicTitle] = useState(EMPTY_STRING);
  const [byDrop, setByDrop] = useState(true);
  const [appendPhrase, setAppendPhrase] = useState({});
  const [selectedContext, setSelectedContext] = useState(EMPTY_OBJECT);
  const [exportFileName, setExportFileName] = useState(EMPTY_STRING);
  const [calcHeight, setCalcHeight] = useState(200);

  const [addedNewTopic, setAddedNewTopic] = useState(false);
  const [defaultBrand, setDefaultBrand] = useState(null);

  const [createTopicModal, setCreateTopicModal] = useState(false);
  const [phraseBoxEvent, setPhraseBoxEvent] = useState(false);

  const [seedFileData, setSeedFileData] = useState(EMPTY_ARRAY);
  const [dataNotAvailable, setDataNotAvailable] = useState(false);
  const [seedingClassifer, setSeedingClassifer] = useState(false);
  const [auditedTopics, setAuditedTopics] = useState(new Set());
  const [phrasesToBeAudited, setPhrasesToBeAudited] = useState(new Set());
  const [autoSave, setAutoSave] = useState(false);
  const [thresholdValues, setThresholdValues] = useState(EMPTY_ARRAY);
  const [dataLoading, setDataloading] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const [dialogData, setDialogData] = useState({
    event: null,
    selPhrase: null,
    selTopic: null,
    context: null,
    selectedText: null,
  });
  const [loadedContextCount, setLoadedContextCount] = useState(0);
  const [paletteMinimized, setPaletteMinimized] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [searchInfo, setSearchInfo] = useState({ total: 0, classified: 0 });
  const [openNewPhrasesModal, setOpenNewPhrasesModal] = useState(false);
  const [newPhrases, setNewPhrases] = useState(EMPTY_ARRAY);
  const [topicalPhraseType, setTopicalPhraseType] = useState("all");
  const [savingData, setSavingData] = useState(false);
  const [renamedTopics, setRenamedTopics] = useState([]);
  const [undoCallbacks, setUndoCallbacks] = useState([]);
  const [undoableEvent, setUndoableEvent] = useState("");
  const [undoEventProcessed, setUndoEventProcessed] = useState(false);
  const [deletedSelectedTopic, setDeletedSelectedTopic] = useState(false);
  const [curatedTopics, setCuratedTopics] = useState(EMPTY_ARRAY);
  const [prevTopicPalettes, setPrevTopicPalettes] = useState(null);
  const [deletedTopic, setDeletedTopic] = useState(null);
  const [seedingModel, setSeedingModel] = useState(false);

  const autoSaveRef = useRef();
  const dataTopicsRef = useRef();
  const summaryDataRef = useRef();
  const candidatePhrasesRef = useRef();
  const unClassifiedPhrasesRef = useRef();
  const progressDataRef = useRef();
  const selectedDiscoverdTopicRef = useRef();
  const topicPalettesRef = useRef();

  const isTopical = !isCandidate && !isNgram && !isunClassified;

  const defaultSelectedBrand = JSON.parse(
    localStorage.getItem("defaultSelectedBrand")
  );
  if (defaultSelectedBrand) {
    const bId = defaultSelectedBrand.id;
    if (bId !== defaultBrand) {
      setDefaultBrand(bId);
    }
  }

  let toastId = null;

  const doClientOperations = (
    data,
    phrasePattern,
    searchWord,
    sortBy,
    wordsBefore,
    wordsAfter,
    sortType
  ) => {
    try {
      if (data === undefined || data.length === 0) return EMPTY_ARRAY;
      else {
        const filteredArray = doPatternFilter(data, phrasePattern);
        const searchedArray = doPhraseSearch(filteredArray, searchWord);
        const sortedArray = doPhraseSorting(searchedArray, sortBy, sortType);
        const trimmedArray = doContextTrim(
          sortedArray,
          wordsBefore,
          wordsAfter,
          searchWord
        );
        return trimmedArray;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const doClientMark = useCallback(
    (phrasesData, search, screen, topic) => {
      if (phrasesData.length === 0) return [];
      const searchStr = search.trim();
      let markedData = [];

      const renderedScreen = screen
        ? screen
        : isCandidate
        ? "isCandidate"
        : isNgram
        ? "isNgram"
        : isunClassified
        ? "isunClassified"
        : "isTopics";

      const topicSelected =
        topic || dataTopics.find((t) => t.id === selectedTopics[0]);

      try {
        markedData = cloneDeep(phrasesData).map((p) => {
          p.context &&
            p.context.map((ctx) => {
              const ctxSideTopics = ctx.topics
                ? ctx.topics.filter((t) => t.topic)
                : [];
              if (renderedScreen === "isCandidate") {
                const hightLightCandidate =
                  ctx.candidates && ctx.candidates.length
                    ? highLightCandidate(cloneDeep(ctx.context), ctx.candidates)
                    : highLightCandidate(cloneDeep(ctx.context), [p.phrase]);

                const sideTopicHighlight = ctxSideTopics.length
                  ? sideTopicWithDataAttr(
                      hightLightCandidate,
                      ctxSideTopics,
                      isTopicPaletteHighlightActive,
                      ""
                    )
                  : hightLightCandidate;
                ctx.context =
                  searchStr.length === 0
                    ? sideTopicHighlight
                    : highLightSearchWord(sideTopicHighlight, searchStr);
              } else if (renderedScreen === "isNgram") {
                const sideTopicHighlight = ctxSideTopics.length
                  ? sideTopicWithDataAttr(
                      cloneDeep(ctx.context),
                      ctxSideTopics,
                      isTopicPaletteHighlightActive,
                      ""
                    )
                  : cloneDeep(ctx.context);
                ctx.context =
                  searchStr.length === 0
                    ? ngramMainHighLight(sideTopicHighlight, p.phrase)
                    : ngramMainHighLight(sideTopicHighlight, searchStr);
              } else if (renderedScreen === "isunClassified") {
                const sideTopicHighlight = ctxSideTopics.length
                  ? sideTopicWithDataAttr(
                      cloneDeep(ctx.context),
                      ctxSideTopics,
                      isTopicPaletteHighlightActive,
                      ""
                    )
                  : cloneDeep(ctx.context);
                ctx.context =
                  searchStr.length === 0
                    ? sideTopicHighlight
                    : highLightSearchWord(sideTopicHighlight, searchStr);
              } else {
                const mainTopicArray = cloneDeep(ctx.topics).filter(
                  (ct) => ct.value.toLowerCase() === p.phrase.toLowerCase()
                );

                const sideTopicArray = cloneDeep(ctx.topics).filter(
                  (ct) => ct.value.toLowerCase() !== p.phrase.toLowerCase()
                );

                const cleanContext = ctx.context
                  .replace(/\s+/g, " ")
                  .replace(/<\/?span[^>]*>/gi, "")
                  .replace(/<\/?mark[^>]*>/gi, "");

                const highlightCandidate = ctx.candidates
                  ? highLightCandidate(cloneDeep(cleanContext), ctx.candidates)
                  : cloneDeep(cleanContext);

                const hightLightMainTopic = mainTopicWithDataAttr(
                  highlightCandidate,
                  mainTopicArray
                );
                const highlightSideTopic = sideTopicWithDataAttr(
                  hightLightMainTopic,
                  sideTopicArray,
                  isTopicPaletteHighlightActive,
                  topicSelected.name.toLowerCase()
                );

                ctx.context =
                  searchStr.length === 0
                    ? highlightSideTopic
                    : highLightSearchWord(highlightSideTopic, searchStr);
              }
              return ctx;
            });
          return p;
        });
      } catch (error) {
        markedData = phrasesData;
        console.log(error);
      }

      return markedData;
    },
    [
      dataTopics,
      selectedTopics,
      isCandidate,
      isNgram,
      isunClassified,
      isTopicPaletteHighlightActive,
    ]
  );

  //Get Gram Phrases
  const getGramPhrases = useCallback(
    (weight) => {
      const gp = ngramData.find((n) => n.weight === weight);
      if (gp) return get(gp, "data");
      else return EMPTY_ARRAY;
    },
    [ngramData]
  );

  //Set Gram Phrases
  const setGramPhrases = (newData, weight) => {
    setNgramData((state) =>
      state.map((n) => ({
        ...n,
        data: n.weight === weight ? newData : n.data,
      }))
    );
  };

  //Fetch Discoverd Topics
  const getDiscoveredTopics = async () => {
    const updateData = async (data) => {
      const topicsData = doTopicSearch(get(data, "topics"), searchTopic);
      const candidatesData = get(data, "candidates");
      const summaryData = get(data, "summary");
      const progressData = get(data, "progress");
      const paletteData = get(data, "paletteData");
      const candidateCount = get(data, "candidateCount");

      setTopics(EMPTY_ARRAY.concat(topicsData));
      setDataTopics(EMPTY_ARRAY.concat(topicsData));
      setCandidatePhrases(EMPTY_ARRAY.concat(candidatesData));
      setCandidateTotalCount(candidateCount);

      if (topicsData.length > 0) {
        let selectedTopicPhrases = topicsData[0].phrases;
        if (!topicsData[0].isLoaded) {
          try {
            const { data } = await getTopicDetails(topicsData[0].id);
            if (data.status === SUCCESS) {
              const fetchedPhrases = get(data.response[0], "phrases");
              selectedTopicPhrases = topicsData[0].phrases.length
                ? loadashValues(
                    lodashMerge(
                      lodashKeyBy(fetchedPhrases, "phraseId"),
                      lodashKeyBy(topicsData[0].phrases, "phraseId")
                    )
                  )
                : topicsData[0].phrases.concat(fetchedPhrases);

              const updatedTopics = cloneDeep(topicsData).map((dt) => {
                if (dt.id === topicsData[0].id) {
                  dt.phrases = cloneDeep(selectedTopicPhrases);
                  dt.isLoaded = true;
                }
                return dt;
              });
              setTopics(updatedTopics);
              setDataTopics(updatedTopics);
            }
          } catch (err) {
            console.log(err);
          }
        }

        const localTopicPhrases = doPhraseSorting(
          selectedTopicPhrases,
          sortBy,
          sortType
        );
        setSelectedTopics(EMPTY_ARRAY.concat(topicsData[0].id));
        setPrevSelectedTopics(EMPTY_ARRAY.concat(topicsData[0].id));
        setPhrases(EMPTY_ARRAY.concat(localTopicPhrases));
        setTotalPhrases(localTopicPhrases.length);
        if (localTopicPhrases.length > 0)
          setSelectedPhrases(EMPTY_ARRAY.concat(localTopicPhrases[0].phraseId));
      }

      if (summaryData) setSummaryData(summaryData);
      if (progressData) {
        setAuditedTopics(
          new Set(
            topicsData.length && !topicsData[0].isLoaded
              ? progressData.topics.concat(topicsData[0].id)
              : progressData.topics
          )
        );
        setPhrasesToBeAudited(new Set(progressData.phrases));
      }
      if (paletteData)
        dispatch(
          savePaletteData({ ...topicPalettesRef, [defaultBrand]: paletteData })
        );

      setLoading(false);
      dispatch(loadAtd(false));
    };

    client
      .get(FETCH_DISCOVERED_TOPICS)
      .then((res) => {
        if (res.data.status === SUCCESS) {
          const resData = res.data.response;

          if (resData.length > 0) {
            const sortedDiscoverdTopics = lodashOrderBy(
              resData,
              function (dateObj) {
                return new Date(dateObj.createdAt);
              },
              ["desc"]
            );
            let selectedDiscoveredTopic;
            if (defaultBrand) {
              selectedDiscoveredTopic = sortedDiscoverdTopics.find(
                (e) => e.brandId === defaultBrand
              );
            }

            if (selectedDiscoveredTopic) {
              if (!Object.keys(topicPalettes).includes(defaultBrand.toString()))
                dispatch(addBrandPalettes(defaultBrand, topicPalettes));
              setSelectedDiscoverdTopic(selectedDiscoveredTopic);
              setExportFileName(selectedDiscoveredTopic.label);
              setSelectedTopicSentiment(null);
              if (selectedDiscoveredTopic.state)
                setAtdState(selectedDiscoveredTopic.state);
              //setAtdState(null);

              getThresholdValues();
              getPhrasePattern(selectedDiscoveredTopic.value);
              //getNgramData(selectedDiscoveredTopic.value, gramWeight);
              getGramsData(selectedDiscoveredTopic.value);
              getUnclassifiedData(selectedDiscoveredTopic.value);
            } else {
              setDataNotAvailable(true);
              return;
            }

            // Check for temporarily saved ATD data on browser refresh
            const tempData = JSON.parse(localStorage.getItem("tempAtdData"));

            localForage
              .getItem("atdData")
              .then((atdData) => {
                if ((atdData || tempData) && selectedDiscoveredTopic) {
                  const filteredList =
                    tempData && tempData.value === selectedDiscoveredTopic.value
                      ? [tempData]
                      : atdData.filter(
                          (a) => a.value === selectedDiscoveredTopic.value
                        );
                  if (filteredList.length) {
                    updateData(filteredList[0].data);
                  } else {
                    client
                      .get(GET_CLOUD_DATA, {
                        params: { folderId: selectedDiscoveredTopic.value },
                      })
                      .then((res) => {
                        updateData(res.data.response);
                      })
                      .catch((err) => {
                        getSummaryData(selectedDiscoveredTopic.value);
                        getAllTopics(selectedDiscoveredTopic.value);
                        getCandidateData(selectedDiscoveredTopic.value);
                        dispatch(loadAtd(false));
                      });
                  }
                  // Save Temp Data
                  if (tempData && tempData.location === "local") {
                    let dataToBeSaved = {};
                    if (
                      atdData &&
                      atdData.some((d) => d.value === tempData.value)
                    ) {
                      dataToBeSaved = atdData.map((a) => {
                        if (a.value === tempData.value) a.data = tempData.data;
                        return a;
                      });
                    } else
                      dataToBeSaved = atdData
                        ? atdData.concat(tempData)
                        : [tempData];
                    moveTempDataToIndexedDB(dataToBeSaved);
                  } else if (
                    tempData &&
                    tempData.value === selectedDiscoveredTopic.value
                  ) {
                    const formData = {
                      folderId: selectedDiscoveredTopic.value,
                      atdData: JSON.stringify(tempData.data),
                    };
                    client.post(SAVE_TO_CLOUD, formData);
                    localStorage.removeItem("tempAtdData");
                    if (
                      atdData &&
                      atdData.find(
                        (d) => d.value === selectedDiscoveredTopic.value
                      )
                    ) {
                      localForage.setItem(
                        "atdData",
                        atdData.filter(
                          (d) => d.value !== selectedDiscoveredTopic.value
                        )
                      );
                    }
                  }
                } else {
                  client
                    .get(GET_CLOUD_DATA, {
                      params: { folderId: selectedDiscoveredTopic.value },
                    })
                    .then((res) => {
                      updateData(res.data.response);
                    })
                    .catch((err) => {
                      getSummaryData(selectedDiscoveredTopic.value);
                      getAllTopics(selectedDiscoveredTopic.value);
                      getCandidateData(selectedDiscoveredTopic.value);
                      dispatch(loadAtd(false));
                    });
                }
              })
              .catch((err) => {
                console.log(err);
                if (
                  tempData &&
                  tempData.value === selectedDiscoverdTopic.value
                ) {
                  updateData(tempData);
                  localStorage.removeItem("tempAtdData");
                } else {
                  client
                    .get(GET_CLOUD_DATA, {
                      params: { folderId: selectedDiscoveredTopic.value },
                    })
                    .then((res) => {
                      updateData(res.data.response);
                    })
                    .catch((err) => {
                      getSummaryData(selectedDiscoveredTopic.value);
                      getAllTopics(selectedDiscoveredTopic.value);
                      getCandidateData(selectedDiscoveredTopic.value);
                      dispatch(loadAtd(false));
                    });
                }
              });
          } else {
            toast.error(res.data.message);
            setLoading(false);
          }
        } else {
          toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch(loadAtd(false));
        console.log(err);
      });
  };

  //Get all grams data
  const getGramsData = (id) => {
    getNgramData(id, 1, 1);
    getNgramData(id, 2, 1);
    getNgramData(id, 3, 1);
    getNgramData(id, 4, 1);
    getNgramData(id, 5, 1);
  };

  //Fetch N-Grams data
  const getNgramData = (id, weight, pageNo) => {
    client
      .get(GET_NGRAM_PHRASES, {
        params: {
          folderId: id ? id : selectedDiscoverdTopic.value,
          gramWeight: weight.toString(),
          pageNo: pageNo ? pageNo : phrasesPage,
          contextPageNo: 1,
        },
      })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          let ndata = {
            weight: weight,
            data: get(res.data.response, "nGrams"),
            count: get(res.data.response, "count"),
          };
          setNgramData((state) => [...state, ndata]);
        } else {
          setSelectedPhrases(EMPTY_ARRAY);
          setPhrases(EMPTY_ARRAY);
          setLoading(false);
          toast.error(ERROR_MESSAGE);
        }
        setDataloading(false);
      })
      .catch((error) => {
        setLoading(false);
        setDataloading(false);
        console.log(error);
      });
  };

  //Fetch Candidate Data
  const getCandidateData = (id) => {
    client
      .get(GET_CANDIDATE_PHRASES, {
        params: {
          folderId: id ? id : selectedDiscoverdTopic.value,
          pageNo: phrasesPage,
          pageSize: 100,
          contextPageNo: 1,
          contextPageSize: 100,
        },
      })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          setCandidatePhrases(get(res.data.response, "candidates"));
          setCandidateTotalCount(get(res.data.response, "count"));
        } else {
          setSelectedPhrases(EMPTY_ARRAY);
          setPhrases(EMPTY_ARRAY);
          setLoading(false);
          toast.error(ERROR_MESSAGE);
        }
      })
      .catch((error) => {
        setCandidatePhrases(EMPTY_ARRAY);
        setLoading(false);
        console.log(error);
      });
  };

  //Get Unclassided Data
  const getUnclassifiedData = (id) => {
    client
      .get(GET_UNCLASSIFIED_DATA, {
        params: {
          folderId: id ? id : selectedDiscoverdTopic.value,
          pageNo: phrasesPage,
        },
      })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          if (typeof res.data.response === "string")
            setUnClassifiedPhrases(EMPTY_ARRAY);
          else {
            setUnClassifiedPhrases(get(res.data.response, "docList"));
            setUnclassifiedTotalCount(get(res.data.response, "count"));
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setUnClassifiedPhrases(EMPTY_ARRAY);
      });
  };

  const getTopicDetails = async (id) => {
    let response = await client.get(GET_TOPIC_DETAILS, {
      params: { topicId: id },
    });
    if (response.status === 200) {
      return response;
    } else {
      throw new Error(response);
    }
  };

  //Fetch Topics
  const getAllTopics = (folderId) => {
    setLoading(true);
    setTopics(EMPTY_ARRAY);
    client
      .get(GET_ALL_TOPICS, {
        params: {
          folderId: folderId ? folderId : selectedDiscoverdTopic.value,
        },
      })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          const data = get(res.data, "response").map((t) => {
            t.phrases = [];
            return t;
          });

          const resData = doTopicSearch(cloneDeep(data), searchTopic);

          if (resData.length > 0) {
            const r = getTopicDetails(resData[0].id);
            r.then((res) => {
              if (res.data.status === SUCCESS) {
                const fetchedPhrases = get(res.data.response[0], "phrases");

                const ut = cloneDeep(resData).map((t) => {
                  if (t.id === res.data.response[0].id) {
                    t.phrases = uniqBy(
                      t.phrases.concat(fetchedPhrases),
                      "phraseId"
                    );
                    t.isLoaded = true;
                  }
                  return t;
                });
                setTopics(EMPTY_ARRAY.concat(ut));
                setPhrasesToBeAudited(new Set());

                const udt = cloneDeep(data).map((dt) => {
                  if (dt.id === res.data.response[0].id) {
                    dt.phrases = uniqBy(
                      dt.phrases.concat(fetchedPhrases),
                      "phraseId"
                    );
                    dt.isLoaded = true;
                  }
                  return dt;
                });
                setDataTopics(EMPTY_ARRAY.concat(udt));

                setSelectedTopics(EMPTY_ARRAY.concat([ut[0].id]));
                setPrevSelectedTopics(EMPTY_ARRAY.concat([ut[0].id]));
                setAuditedTopics(new Set().add(ut[0].id));
                setPhrases(
                  doClientMark(
                    fetchedPhrases,
                    searchWord,
                    "isTopics",
                    resData[0]
                  )
                );
                if (fetchedPhrases.length > 0) {
                  setSelectedPhrases(
                    EMPTY_ARRAY.concat(fetchedPhrases[0].phraseId)
                  );
                  setTotalPhrases(fetchedPhrases.length);
                  setLoadedContextCount(50);
                }
                setLoading(false);
              } else {
                setLoading(false);
                setPhrases(EMPTY_ARRAY);
                setTotalPhrases(0);
              }
            }).catch((err) => {
              console.log(err);
              setLoading(false);
            });
            return;
          } else {
            setTopics(resData);
            setDataTopics(data);
            setSelectedTopics(EMPTY_ARRAY.concat([resData[0].id]));
            setPrevSelectedTopics(EMPTY_ARRAY.concat([resData[0].id]));
            setAuditedTopics(new Set().add(resData[0].id));
            setLoading(false);
          }
        } else {
          setLoading(false);
          setPhrases(EMPTY_ARRAY);
          setTotalPhrases(0);
        }
      })
      .catch((error) => {
        console.log(error);
        setTopics(EMPTY_ARRAY);
        setDataTopics(EMPTY_ARRAY);
        setPhrases(EMPTY_ARRAY);
        setLoading(false);
        setTotalPhrases(0);
      });
  };

  //Get Summary Data
  const getSummaryData = (id) => {
    client
      .get(GET_SUMMARY_DATA, {
        params: { folderId: id ? id : selectedDiscoverdTopic.value },
      })
      .then((res) => {
        const summaryData = get(res.data, "response");
        setSummaryData(summaryData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Fetch Thresholdvalues
  const getThresholdValues = (name, val) => {
    const defaultSelectedBrand = JSON.parse(
      localStorage.getItem("defaultSelectedBrand")
    );
    if (defaultSelectedBrand) {
      client
        .get(GET_PIPELINE_PARAMS, {
          params: {
            brandId: defaultSelectedBrand.id,
            [name]: val,
          },
        })
        .then((res) => {
          if (res.data.status === SUCCESS) {
            const parameters = res.data.response;
            setThresholdValues([
              { parameter: "threshold", value: parameters.threshold },
              { parameter: "pipeline", value: parameters.pipeline },
            ]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  //Fetch Phrase Pattern
  const getPhrasePattern = () => {
    client
      .get(GET_PHRASE_PATTERN)
      .then((res) => {
        if (res.data.status === SUCCESS) {
          setFavoritePatterns(
            res.data.response.phrasePatterns.favoritePatterns
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //New Topic Button allow drop
  const allowDrop = (e) => {
    try {
      e.preventDefault();
    } catch (err) {
      console.log(err);
    }
  };

  //New Topic Button on drop event
  const newTopicDrop = (e) => {
    try {
      e.preventDefault();
      if (atdState === "locked") return;

      setUndoableEvent("CLEAR");
      const str = window.getSelection().toString().trim();
      if (str.length === 0) {
        //if phrase box dropped
        if (selectedPhrases.length === 0) {
          toast.warn("Select at-least one phrase to create new topic");
          return;
        }
        setNewTopicTitle(EMPTY_STRING);
        setByDrop(true);
        setOpen(true);
      } else {
        let phraseObj = {};
        phraseObj["char_sim"] = 0;
        phraseObj["context"] = [selectedContext];
        phraseObj["frequency"] = 1;
        phraseObj["phraseId"] = Math.floor(10000 + Math.random() * 9000);
        phraseObj["phrase"] = str;
        phraseObj["pos_tags"] = [];
        phraseObj["sem_sim"] = 0;
        phraseObj["text"] = str;

        setByDrop(false);
        setNewTopicTitle(str);
        setAppendPhrase(phraseObj);
        setOpen(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //Input change event
  const handleWordChange = (value, label) => {
    try {
      let val = value.replace(/[^0-9]/g, "").replace(/(\..*?)\..*/g, "$1");

      if (val < 0) return;
      else if (val > 15) val = 15;

      if (label === "before") setWordsBefore(val);
      else setWordsAfter(val);
    } catch (err) {
      console.log(err);
    }
  };

  //Filter N-grams on weight
  const handleNgramWeight = (val) => {
    try {
      if (dataLoading) return;
      setLoadedContextCount(0);
      const gp = ngramData.filter((n) => n.weight === parseInt(val));
      const gd = doClientOperations(
        get(gp[0], "data"),
        EMPTY_ARRAY,
        searchWord,
        sortBy,
        wordsBefore,
        wordsAfter,
        sortType
      );
      setPhrases(uniqBy(gd, "phraseId"));
      setPhrasePattern(EMPTY_ARRAY);
      setGramWeight([get(gp[0], "weight")]);
      setTotalPhrases(get(gp[0], "count"));
      setPhrasesPage(1);
      if (gd.length > 0) {
        setSelectedPhrases(EMPTY_ARRAY.concat(gd[0].phraseId));
        setLoadedContextCount(gd[0].context.length);
      }

      setTimeout(() => {
        if (searchWord.trim().length >= 3)
          hybridSearchSort(sortBy, sortType, "isNgram", val);
      }, 600);
    } catch (err) {
      console.log(err);
    }
  };

  //search/sort documents from back end - candidates/ngrams
  const hybridSearchSort = (sortColumn, sortOrder, documentType, gramVal) => {
    try {
      setUndoableEvent("CLEAR");
      setIsSearching(true);

      const renderedScreen = documentType
        ? documentType
        : isCandidate
        ? "isCandidate"
        : isNgram
        ? "isNgram"
        : isunClassified
        ? "isunClassified"
        : "isTopics";

      const loadUrl =
        renderedScreen === "isCandidate"
          ? GET_CANDIDATE_PHRASES
          : renderedScreen === "isNgram"
          ? GET_NGRAM_PHRASES
          : renderedScreen === "isunClassified"
          ? GET_UNCLASSIFIED_DATA
          : GET_TOPICS;

      const inputs = {
        folderId: parseInt(selectedDiscoverdTopic.value),
        gramWeight:
          renderedScreen === "isNgram"
            ? gramVal
              ? gramVal
              : gramWeight[0]
            : null,
        searchWord: searchWord.trim().length !== 0 ? searchWord.trim() : null,
        sortColumn:
          (renderedScreen === "isCandidate" || renderedScreen === "isNgram") &&
          sortColumn &&
          sortColumn.length !== 0
            ? sortColumn
            : null,
        sortOrder:
          (renderedScreen === "isCandidate" || renderedScreen === "isNgram") &&
          sortColumn &&
          sortColumn.length !== 0
            ? sortOrder
            : null,
        pageNo: 1,
        contextPageNo: 1,
      };

      client
        .get(loadUrl, { params: inputs })
        .then((res) => {
          let responseData = [],
            count = 0;
          const nTotal = get(res.data.response, "nTotal"),
            nUnclassified = get(res.data.response, "nUnclassified");

          if (renderedScreen === "isNgram") {
            responseData = get(res.data.response, "nGrams");
            count = get(res.data.response, "count");
            setGramPhrases(
              cloneDeep(responseData).slice(0, 100),
              gramWeight[0]
            );
          }

          if (renderedScreen === "isCandidate") {
            const curatedCandidates = localStorage.getItem("newCandidates");
            if (curatedCandidates) {
              responseData = get(res.data.response, "candidates").concat(
                JSON.parse(curatedCandidates)
              );
              count =
                get(res.data.response, "count") +
                JSON.parse(curatedCandidates).length;
            } else {
              responseData = get(res.data.response, "candidates");
              count = get(res.data.response, "count");
            }
            setCandidatePhrases(cloneDeep(responseData));
          }

          if (renderedScreen === "isunClassified") {
            const resData = get(res.data.response, "docList");
            responseData = [resData[0]];
            count = get(res.data.response, "count");
            setUnClassifiedPhrases(cloneDeep(responseData));
          }

          if (renderedScreen === "isTopics") {
            const fetchedTopics = get(res.data.response, "topics");
            const filteredTopics = fetchedTopics
              .filter((ft) => dataTopics.find((t) => t.id === ft.id))
              .map((ft) => {
                const currentTopic = dataTopics.find((t) => t.id === ft.id);
                ft.phrases = currentTopic.isLoaded
                  ? ft.phrases.filter((fp) =>
                      currentTopic.phrases.find(
                        (p) => p.phraseId === fp.phraseId
                      )
                    )
                  : ft.phrases;
                return ft;
              });

            //filter topics
            const ut = lodashFilter(
              cloneDeep(filteredTopics).concat(dataTopics),
              (topic) => {
                return lodashSome(topic.phrases, (phrase) => {
                  return lodashSome(phrase.context, (ctx) => {
                    return (
                      ctx.context
                        .replace(/(<([^>]+)>)/gi, "")
                        .toLowerCase()
                        .indexOf(searchWord.toLowerCase()) !== -1
                    );
                  });
                });
              }
            );

            setTopics(EMPTY_ARRAY.concat(ut));

            if (ut.length !== 0) {
              const isExist = lodashSome(ut, (topic) => {
                return topic.id === selectedTopics[0];
              });
              if (!isExist) {
                setSelectedTopics(EMPTY_ARRAY.concat(ut[0].id));
                responseData = ut[0].phrases;
              } else {
                const ft = cloneDeep(ut).filter(
                  (t) => t.id === selectedTopics[0]
                );
                setSelectedTopics(EMPTY_ARRAY.concat(ft[0].id));
                responseData = ft[0].phrases;
              }
              count = responseData.length;
            }
          }

          const formattedData = doClientOperations(
            responseData,
            phrasePattern,
            searchWord,
            sortColumn,
            wordsBefore,
            wordsAfter,
            sortOrder
          );
          setPhrases(doClientMark(formattedData, searchWord, documentType));

          if (formattedData.length > 0) {
            setSelectedPhrases(EMPTY_ARRAY.concat(formattedData[0].phraseId));
          }
          setLoadedContextCount(formattedData.length);
          setTotalPhrases(count);
          setIsSearching(false);

          if (searchWord.trim().length !== 0) {
            setSearchInfo({ total: nTotal, classified: nUnclassified });
            setSearchActive(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  //Topic/Phrase Search Event
  const handlePhraseSearch = async (key) => {
    try {
      setUndoableEvent("CLEAR");
      if (isSearching) return;
      if (atdState === "locked") return;

      if (key === "Backspace" && searchWord.trim().length === 0) {
        if (!isCandidate && !isNgram && !isunClassified) {
          const filterdTopics = getUnfilteredTopics();
          setTopics(EMPTY_ARRAY.concat(filterdTopics));

          if (filterdTopics.length !== 0) {
            setSelectedTopics(EMPTY_ARRAY.concat(filterdTopics[0].id));

            const phraseList = filterdTopics[0].phrases;

            const fp = doClientOperations(
              phraseList,
              phrasePattern,
              searchWord,
              sortBy,
              wordsBefore,
              wordsAfter,
              sortType
            );
            const markedContent = doClientMark(fp, searchWord);

            setPhrases(EMPTY_ARRAY.concat(markedContent));
            if (markedContent.length > 0)
              setSelectedPhrases(EMPTY_ARRAY.concat(markedContent[0].phraseId));
            setTotalPhrases(phraseList.length);
            setLoadedContextCount(50);

            setSearchInfo({ total: 0, classified: 0 });
            setSearchActive(false);
          }
        } else {
          hybridSearchSort();
        }
        return;
      }

      if (key === "Enter") {
        if (searchWord.trim().length < 3) return;
        //Client side - Search
        let phraseList = [];

        if (isCandidate) {
          phraseList = cloneDeep(candidatePhrases);
        }
        if (isNgram) {
          phraseList = getGramPhrases(gramWeight[0]);
        }
        if (isunClassified) {
          phraseList = cloneDeep(unClassifiedPhrases);
        }
        if (!isCandidate && !isNgram && !isunClassified) {
          //filter topics
          const ut = lodashFilter(cloneDeep(topics), (topic) => {
            return lodashSome(topic.phrases, (phrase) => {
              return lodashSome(phrase.context, (ctx) => {
                return (
                  ctx.context
                    .replace(/(<([^>]+)>)/gi, "")
                    .toLowerCase()
                    .indexOf(searchWord.toLowerCase()) !== -1
                );
              });
            });
          });

          setTopics(EMPTY_ARRAY.concat(ut));

          if (ut.length !== 0) {
            const isExist = lodashSome(ut, (topic) => {
              return topic.id === selectedTopics[0];
            });
            if (!isExist) {
              setSelectedTopics(EMPTY_ARRAY.concat(ut[0].id));
              phraseList = ut[0].phrases;
            } else {
              const ft = cloneDeep(ut).filter(
                (t) => t.id === selectedTopics[0]
              );
              setSelectedTopics(EMPTY_ARRAY.concat(ft[0].id));
              phraseList = ft[0].phrases;
            }
          }
        }

        const fp = await doClientOperations(
          phraseList,
          phrasePattern,
          searchWord,
          sortBy,
          wordsBefore,
          wordsAfter,
          sortType
        );
        const markedContent = await doClientMark(fp, searchWord);
        const totalList =
          searchWord.length >= 3 ? markedContent.length : phraseList.length;

        setTotalPhrases(totalList);
        setLoadedContextCount(markedContent.length);
        setPhrases(EMPTY_ARRAY.concat(markedContent));

        if (markedContent.length > 0) {
          const isPhraseSelected = lodashSome(markedContent, (phrase) => {
            return phrase.phraseId === selectedPhrases[0];
          });

          if (isPhraseSelected) {
            setSelectedPhrases(
              EMPTY_ARRAY.concat(
                prevSelectedPhrases.length !== 0
                  ? prevSelectedPhrases[0]
                  : markedContent[0].phraseId
              )
            );
          } else {
            setSelectedPhrases(EMPTY_ARRAY.concat(markedContent[0].phraseId));
          }
        }

        if (searchWord.trim().length < 3) {
          setIsSearching(false);
          setSearchActive(false);
        } else {
          hybridSearchSort();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  //Word Before / word after inputs enter key event
  const handleWordPhrases = () => {
    setUndoableEvent("CLEAR");
    setTimeout(() => {
      try {
        if (atdState === "locked") return;

        let lists = [];
        if (isCandidate) lists = cloneDeep(candidatePhrases);
        else if (isNgram) {
          lists = getGramPhrases(gramWeight[0]);
        } else if (isunClassified) {
          lists = cloneDeep(unClassifiedPhrases);
        } else {
          const tt = cloneDeep(topics).filter(
            (t) => t.id === selectedTopics[0]
          );
          lists = tt[0].phrases;
        }

        const updatedList = doClientOperations(
          cloneDeep(lists),
          phrasePattern,
          searchWord,
          sortBy,
          wordsBefore,
          wordsAfter,
          sortType
        );

        const markedContent = doClientMark(cloneDeep(updatedList), searchWord);

        const filteredPhrases = filterTopicalPhrases(markedContent);
        setPhrases(
          EMPTY_ARRAY.concat(doPhraseSorting(filteredPhrases, sortBy, sortType))
        );
      } catch (err) {
        console.log(err);
      }
    }, 900);
  };

  //Handle Phrases Sorting - Client Side
  const handlePhraseSorting = (col, type) => {
    try {
      setUndoableEvent("CLEAR");
      if (atdState === "locked") return;
      if (isunClassified) return;

      if (isCandidate || isNgram) {
        hybridSearchSort(col, type);
      } else {
        const tt = cloneDeep(topics).filter((t) => t.id === selectedTopics[0]);

        const sortedList = doClientOperations(
          cloneDeep(tt[0].phrases),
          phrasePattern,
          searchWord,
          col,
          wordsBefore,
          wordsAfter,
          type
        );

        const markedContent = doClientMark(sortedList, searchWord);

        const filteredPhrases = filterTopicalPhrases(markedContent);
        setPhrases(EMPTY_ARRAY.concat(filteredPhrases));
      }

      setSortBy(col);
      setSortType(type);
    } catch (err) {
      console.log(err);
    }
  };

  //Filter Phrases by patterns - Client side
  const handlePatternfilter = (phrasePattern) => {
    try {
      setUndoableEvent("CLEAR");
      if (atdState === "locked") return;
      if (isunClassified) return;

      let lists = [];
      if (isCandidate) lists = cloneDeep(candidatePhrases);
      else if (isNgram) {
        lists = getGramPhrases(gramWeight[0]);
      } else {
        const tt = cloneDeep(topics).filter((t) => t.id === selectedTopics[0]);
        lists = tt[0].phrases;
      }

      const fp = doClientOperations(
        cloneDeep(lists),
        phrasePattern,
        searchWord,
        sortBy,
        wordsBefore,
        wordsAfter,
        sortType
      );

      const markedContent = doClientMark(fp, searchWord);

      setPhrasePattern(phrasePattern.length === 0 ? [] : phrasePattern);

      const filteredPhrases = filterTopicalPhrases(markedContent);
      setPhrases(EMPTY_ARRAY.concat(filteredPhrases));
      const updatedSelectedPhrases = filteredPhrases
        .filter((e) => selectedPhrases.includes(e.phraseId))
        .map((e) => e.phraseId);
      if (filteredPhrases.length > 0)
        setSelectedPhrases(
          updatedSelectedPhrases.length
            ? updatedSelectedPhrases
            : EMPTY_ARRAY.concat(filteredPhrases[0].phraseId)
        );
    } catch (err) {
      console.log(err);
    }
  };

  let clickCount = 0;
  let singleClickTimer = null;

  //Phrase select Event
  const handlePhraseClick = (multiple, phrase) => {
    try {
      const selectedIndex = selectedPhrases.findIndex(
        (el) => el === phrase.phraseId
      );
      clickCount++;
      if (clickCount === 1) {
        if (multiple && !phrasesToBeAudited.has(selectedPhrases[0])) {
          const selPhrase = phrases.find(
            (e) => e.phraseId === selectedPhrases[0]
          );
          if (!phrase.isCurated && !selPhrase.isCurated)
            updateAuditedPhrases([phrase.phraseId, selectedPhrases[0]]);
          else if (!phrase.isCurated) updateAuditedPhrases([phrase.phraseId]);
          else if (!selPhrase.isCurated)
            updateAuditedPhrases([selectedPhrases[0]]);
        } else if (!phrase.isCurated) {
          updateAuditedPhrases([phrase.phraseId]);
        }
        singleClickTimer = setTimeout(function () {
          clickCount = 0;

          //Handle Single Click
          if (multiple) {
            let newSelectedPhrases = EMPTY_ARRAY;
            const selected = selectedPhrases;
            if (selectedIndex === -1) {
              newSelectedPhrases = newSelectedPhrases.concat(
                selected,
                phrase.phraseId
              );
            } else if (selectedIndex === 0) {
              newSelectedPhrases = newSelectedPhrases.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
              newSelectedPhrases = newSelectedPhrases.concat(
                selected.slice(0, -1)
              );
            } else if (selectedIndex > 0) {
              newSelectedPhrases = newSelectedPhrases.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
              );
            }
            setSelectedPhrases(newSelectedPhrases);
            setPrevSelectedPhrases(newSelectedPhrases);
          } else {
            setSelectedPhrases(EMPTY_ARRAY.concat(phrase.phraseId));
            setPrevSelectedPhrases(EMPTY_ARRAY.concat(phrase.phraseId));
            if (searchWord.trim().length < 3) {
              setLoadedContextCount(
                phrase.frequency >= 50 ? 50 : phrase.frequency
              );
            } else {
              setLoadedContextCount(phrase.context.length);
            }
          }
        }, 600);
      } else if (clickCount === 2) {
        clearTimeout(singleClickTimer);
        clickCount = 0;
        //Handle Double Click
        if (selectedIndex !== -1) {
          let allSelectedPhraseIds = EMPTY_ARRAY.concat(
            phrases.map((p) => p.phraseId)
          );
          setSelectedPhrases(allSelectedPhraseIds);
          setPrevSelectedPhrases(allSelectedPhraseIds);
          const nonCuratedPhraseIds = phrases
            .filter((p) => !p.isCurated)
            .map((p) => p.phraseId);
          updateAuditedPhrases(nonCuratedPhraseIds);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateAuditedPhrases = useCallback(
    (phraseIds) => {
      try {
        if (!isunClassified && !isNgram && !isCandidate) {
          const newSet = new Set(phrasesToBeAudited);
          phraseIds.forEach((e) => {
            if (!newSet.has(e)) {
              newSet.add(e);
            }
          });
          if (newSet.size !== phrasesToBeAudited.size) {
            setPhrasesToBeAudited(newSet);
            if (!shouldBlockNavigation) setShouldBlockNavigation(true);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    [
      isunClassified,
      isNgram,
      isCandidate,
      phrasesToBeAudited,
      shouldBlockNavigation,
    ]
  );

  //Topic Selected Event
  const handleTopicClick = useCallback(
    (clickedTopic, altKeyDown, ngramPhrase) => {
      //Update phrase on topic click
      const updateTopicSelectPhrases = (
        selPhrases,
        clickedTopic,
        altKeyDown,
        isLoaded
      ) => {
        try {
          if (!clickedTopic.isCurated) {
            setAuditedTopics((state) => new Set(state).add(clickedTopic.id));
            if (!shouldBlockNavigation) setShouldBlockNavigation(true);
          }

          const altClick = altKeyDown && selectedTopics.length;
          let topicId = altClick ? selectedTopics[0] : clickedTopic.id;
          let updatedTopics;

          if (altClick) {
            if (selectedTopics[0] === clickedTopic.id) return;

            const selectedTopicPhrases = phrases.map((e) => e.phrase);

            const phrasesToBeMerged = selPhrases.filter(
              (p) => !selectedTopicPhrases.includes(p.phrase)
            );

            const mergeTopics = (data) =>
              cloneDeep(data)
                .filter((t) => t.id !== clickedTopic.id)
                .map((t) => ({
                  ...t,
                  frequency:
                    t.id === selectedTopics[0]
                      ? uniqueArray(
                          cloneDeep(t.phrases).concat(phrasesToBeMerged)
                        ).length
                      : t.frequency,
                  phrases: cloneDeep(t.phrases)
                    .concat(t.id === selectedTopics[0] ? phrasesToBeMerged : [])
                    .map((p) => ({
                      ...p,
                      phraseId:
                        t.id === selectedTopics[0] &&
                        phrasesToBeMerged.includes(p.phrase)
                          ? Math.floor(1000000 + Math.random() * 900000)
                          : p.phraseId,
                      context: p.context.map((ctx) => ({
                        ...ctx,
                        topics: ctx.topics.map((ctp) => ({
                          ...ctp,
                          topic:
                            ctp.topic.toLowerCase() ===
                            clickedTopic.name.toLowerCase()
                              ? t.name
                              : ctp.topic,
                        })),
                      })),
                    })),
                }));
            updatedTopics = mergeTopics(topics);
            setDeletedTopic(clickedTopic.id);
            setTimeout(() => {
              setTopics([...updatedTopics]);
            }, 200);
            setDataTopics([...mergeTopics(dataTopics)]);
          } else {
            setIsCandidate(false);
            setIsNgram(false);
            setIsunClassified(false);
            setSelectedTopics(EMPTY_ARRAY.concat(topicId));
            setPrevSelectedTopics(EMPTY_ARRAY.concat(topicId));

            if (!isLoaded) {
              updatedTopics = cloneDeep(topics).map((t) => {
                if (t.id === topicId) {
                  t.phrases = cloneDeep(selPhrases);
                  t.isLoaded = true;
                }
                return t;
              });
              setTopics(updatedTopics);

              const udt = cloneDeep(dataTopics).map((dt) => {
                if (dt.id === topicId) {
                  dt.phrases = cloneDeep(selPhrases);
                  dt.isLoaded = true;
                }
                return dt;
              });
              setDataTopics(udt);
            } else {
              updatedTopics = cloneDeep(topics);
            }
          }

          // Update Phrases
          const p = cloneDeep(updatedTopics).filter((t) => t.id === topicId);

          if (p.length > 0) {
            const pd = doClientOperations(
              p[0].phrases,
              phrasePattern,
              searchWord,
              sortBy,
              wordsBefore,
              wordsAfter,
              sortType
            );

            const updatedPhrases = doClientMark(
              pd,
              searchWord,
              "isTopics",
              !altClick && clickedTopic
            );

            const filteredPhrases =
              topicalPhraseType !== "all"
                ? updatedPhrases.filter((p) =>
                    topicalPhraseType === "curated" ? p.isSeed : !p.isSeed
                  )
                : updatedPhrases;

            setPhrases(filteredPhrases);
            if (pd.length > 0) {
              setTotalPhrases(p[0].phrases.length);
            }
            const selectedNgramPhrase =
              ngramPhrase &&
              filteredPhrases.find(
                (p) => p.phrase.toLowerCase() === ngramPhrase.toLowerCase()
              );
            if (selectedNgramPhrase) {
              setSelectedPhrases([selectedNgramPhrase.phraseId]);
              setPrevSelectedPhrases([selectedNgramPhrase.phraseId]);
              document
                .getElementById(`topic_card_${clickedTopic.id}`)
                .scrollIntoView();
            } else if (filteredPhrases.length > 0) {
              setSelectedPhrases(
                altClick
                  ? [...selectedPhrases]
                  : EMPTY_ARRAY.concat(filteredPhrases[0].phraseId)
              );
              setPrevSelectedPhrases(
                altClick
                  ? [...selectedPhrases]
                  : EMPTY_ARRAY.concat(filteredPhrases[0].phraseId)
              );
            }
          }

          if (altClick) {
            setUndoableEvent("MERGE_TOPICS");
            updateTopicPaletteData(clickedTopic.id, "merge");
          }
          setLoading(false);
        } catch (err) {
          console.log(err);
          setLoading(false);
        }
      };

      try {
        if (!clickedTopic.isLoaded) setUndoableEvent("CLEAR");

        if (clickedTopic.isLoaded || searchWord.trim().length >= 3)
          updateTopicSelectPhrases(
            clickedTopic.phrases,
            clickedTopic,
            altKeyDown,
            true
          );
        else {
          setLoading(true);
          getTopicDetails(clickedTopic.id)
            .then(async (res) => {
              if (res.data.status === SUCCESS) {
                let fetchedPhrases = await get(res.data.response[0], "phrases");

                //update phrase context topics with renamed topic value
                const ufp = fetchedPhrases.map((p) => {
                  p.context = p.context.map((pc) => {
                    pc.topics = pc.topics.map((pct) => {
                      const frt = cloneDeep(renamedTopics).find(
                        (f) =>
                          f.oldName.toLowerCase() === pct.topic.toLowerCase()
                      );
                      if (frt) {
                        pct.topic = frt.newName;
                      }
                      return pct;
                    });
                    return pc;
                  });
                  return p;
                });
                fetchedPhrases = ufp;

                let updatedPhrases = clickedTopic.phrases.length
                  ? loadashValues(
                      lodashMerge(
                        lodashKeyBy(fetchedPhrases, "phraseId"),
                        lodashKeyBy(clickedTopic.phrases, "phraseId")
                      )
                    )
                  : clickedTopic.phrases.concat(fetchedPhrases);
                updateTopicSelectPhrases(
                  updatedPhrases,
                  clickedTopic,
                  altKeyDown,
                  false
                );
              } else {
                setLoading(false);
                setPhrases(clickedTopic.phrases || EMPTY_ARRAY);
                if (clickedTopic.phrases.length > 0)
                  setSelectedPhrases(
                    EMPTY_ARRAY.concat(clickedTopic.phrases[0].phraseId)
                  );
                else setSelectedPhrases(EMPTY_ARRAY);
              }
            })
            .catch((_) => {
              setLoading(false);
              setPhrases(clickedTopic.phrases || EMPTY_ARRAY);
              if (clickedTopic.phrases.length > 0)
                setSelectedPhrases([clickedTopic.phrases[0].phraseId]);
              setIsCandidate(false);
              setIsNgram(false);
              setIsunClassified(false);
              setSelectedTopics(EMPTY_ARRAY.concat(clickedTopic.id));
              setPrevSelectedTopics(EMPTY_ARRAY.concat(clickedTopic.id));
              setTotalPhrases(0);
            });
        }
      } catch (err) {
        console.log(err);
      }
    },
    [
      dataTopics,
      setDataTopics,
      topics,
      setTopics,
      phrases,
      selectedPhrases,
      selectedTopics,
      phrasePattern,
      searchWord,
      sortBy,
      sortType,
      wordsBefore,
      wordsAfter,
      shouldBlockNavigation,
      topicalPhraseType,
      renamedTopics,
      doClientMark,
    ]
  );

  //Handle Topic Search
  const handleTopicSearch = (key) => {
    try {
      if (atdState === "locked") return;

      setUndoableEvent("CLEAR");
      //Client side - Search
      if (
        key === "Enter" ||
        (key === "Backspace" && searchTopic.length === 0)
      ) {
        let filterdTopics = cloneDeep(dataTopics)
          .filter((t) => {
            if (selectedTopicSentiment)
              return t.sentiment === selectedTopicSentiment;
            else return true;
          })
          .filter((t) => {
            if (topicCurateType === "all") return true;
            else if (topicCurateType === "curated") return t.isCurated;
            else if (topicCurateType === "seed") return t.isSeedTopic;
            else if (topicCurateType === "non-seed") return !t.isSeedTopic;
            else return !t.isCurated;
          });

        if (searchTopic.length === 0) {
          setTopics(EMPTY_ARRAY.concat(filterdTopics));
        } else {
          filterdTopics = doTopicSearch(
            cloneDeep(filterdTopics),
            searchTopic,
            selectedTopics
          );
          setTopics(EMPTY_ARRAY.concat(filterdTopics));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  //Handle Topic Sorting
  const handleTopicSorting = (sortBy, sortType) => {
    try {
      if (atdState === "locked") return;

      if (sortBy === "magic") {
        const magicSort = topics.sort(function (c, d) {
          let f1 =
            -(Date.parse(c.modifiedAt) - Date.parse(d.modifiedAt)) / 6000;
          let f2 = -(c.frequency - d.frequency) / 5;
          let t3_1 = c.isSeedTopic ? 10 : 0;
          let t3_2 = d.isSeedTopic ? 10 : 0;
          let f3 = -t3_1 + t3_2;
          let t4_1 = c.id === selectedTopics[0] ? 100 : 0;
          let t4_2 = d.id === selectedTopics[0] ? 100 : 0;
          let f4 = -t4_1 + t4_2;
          return f1 + f2 + f3 + f4;
        });

        setTopics(EMPTY_ARRAY.concat(magicSort));
        setTopicSortBy(sortBy);
        //setTopicSortType(sortType);

        return;
      }

      const sortedList = doTopicSorting(topics, sortBy, sortType);
      setTopics(EMPTY_ARRAY.concat(sortedList));
      setTopicSortBy(sortBy);
      setTopicSortType(sortType);
    } catch (err) {
      console.log(err);
    }
  };

  //Filter Topics based on curated/machine
  const showTopicsByCreated = (val) => {
    try {
      if (atdState === "locked") return;

      setSelectedTopics(EMPTY_ARRAY);
      setTopicCurateType(val);

      let ft = cloneDeep(dataTopics)
        .filter((t) => {
          if (val === "all") return true;
          else if (val === "curated") return t.isCurated;
          else if (val === "seed") return t.isSeedTopic;
          else if (val === "non-seed") return !t.isSeedTopic;
          else return !t.isCurated;
        })
        .filter((t) => {
          if (selectedTopicSentiment)
            return t.sentiment === selectedTopicSentiment;
          else return true;
        });

      if (searchTopic.trim() === "") setTopics(ft);
      else {
        ft = doTopicSearch(cloneDeep(ft), searchTopic);
        setTopics(ft);
      }

      if (isCandidate || isNgram || isunClassified) return;

      if (ft.length > 0) {
        const id = ft[0].id;
        setSelectedTopics(EMPTY_ARRAY.concat(id));
        const fp = doClientOperations(
          ft[0].phrases,
          phrasePattern,
          searchWord,
          sortBy,
          wordsBefore,
          wordsAfter,
          sortType
        );
        const filteredPhrases = filterTopicalPhrases(fp);
        setPhrases(filteredPhrases);
        if (filteredPhrases.length > 0)
          setSelectedPhrases(EMPTY_ARRAY.concat(filteredPhrases[0].phraseId));
      } else {
        setPhrases(EMPTY_ARRAY);
        setSelectedPhrases(EMPTY_ARRAY);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //Filter Topics by Sentiment
  const handleTopicsFilter = (sentiment) => {
    const updateTopicPhrases = (tPhrases, ft) => {
      setTopics(EMPTY_ARRAY.concat(searchTopic.trim().length > 0 ? doTopicSearch(ft, searchTopic) : ft));
      if (isCandidate || isNgram || isunClassified) return;
      const fp = doClientOperations(
        tPhrases,
        phrasePattern,
        searchWord,
        sortBy,
        wordsBefore,
        wordsAfter,
        sortType
      );
      const filteredPhrases =
        isTopical && topicalPhraseType !== "all"
          ? fp.filter((p) =>
              topicalPhraseType === "curated" ? p.isSeed : !p.isSeed
            )
          : fp;
      setPhrases(filteredPhrases);
      setSelectedPhrases(
        filteredPhrases.length
          ? EMPTY_ARRAY.concat(filteredPhrases[0].phraseId)
          : EMPTY_ARRAY
      );
    };

    try {
      setUndoableEvent("CLEAR");
      const val = sentiment === "ALL" ? null : sentiment;
      if (atdState === "locked") return;
      setSelectedTopics(EMPTY_ARRAY);
      setSelectedTopicSentiment(val === "ALL" ? null : val);
      setPhrases(EMPTY_ARRAY);
      let ft = [];

      ft = cloneDeep(dataTopics)
        .filter((t) => {
          if (val) return t.sentiment === val;
          else return true;
        })
        .filter((t) => {
          if (topicCurateType === "all") return true;
          else if (topicCurateType === "curated") return t.isCurated;
          else if (topicCurateType === "seed") return t.isSeedTopic;
          else if (topicCurateType === "non-seed") return !t.isSeedTopic;
          else return !t.isCurated;
        });

      if (ft.length > 0) {
        const id = ft[0].id;
        let tPhrases = [];
        setSelectedTopics(EMPTY_ARRAY.concat(id));
        if (ft[0].isLoaded) {
          tPhrases = ft[0].phrases;
          updateTopicPhrases(tPhrases, ft);
        } else {
          setLoading(true);
          getTopicDetails(ft[0].id)
            .then(async (res) => {
              tPhrases = await get(res.data.response[0], "phrases");
              ft[0].phrases = tPhrases;
              ft[0].isLoaded = true;
              updateTopicPhrases(tPhrases, ft);

              const topicId = get(res.data.response[0], "id");
              //update topics model
              if (tPhrases.length > 0) {
                const udt = cloneDeep(dataTopics).map((t) => {
                  if (t.id === topicId) {
                    t.phrases = tPhrases;
                    t.isLoaded = true;
                  }
                  return t;
                });
                setDataTopics(EMPTY_ARRAY.concat(udt));
              }
              setLoading(false);
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
            });
        }
        setTopics(
          EMPTY_ARRAY.concat(
            searchTopic.trim().length > 0 ? doTopicSearch(ft, searchTopic) : ft
          )
        );
      } else {
        setPhrases(EMPTY_ARRAY);
        setSelectedPhrases(EMPTY_ARRAY);
        setTopics(EMPTY_ARRAY);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleDialogClose = () => {
    try {
      setOpen(false);
      setDialogData({
        event: null,
        selPhrase: null,
        selTopic: null,
        context: null,
        selectedText: null,
      });
      if (newTopicTitle) setNewTopicTitle(EMPTY_STRING);
    } catch (err) {
      console.log(err);
    }
  };

  const removeHighlight = (text, phrase, context) => {
    try {
      setUndoableEvent("CLEAR");
      //update for non topic phrases
      if (isCandidate || isNgram || isunClassified) {
        const up = cloneDeep(phrases).map((p) => {
          if (p.phraseId === phrase.phraseId) {
            p.context.map((ctx) => {
              if (ctx.contextId === context.contextId) {
                if (text.toLowerCase() === phrase.phrase.toLowerCase()) {
                  const uc = ctx.context
                    .replace(/\s+/g, " ")
                    .replace(/(<([^>]+)>)/gi, "");
                  ctx.context = highLightCandidate(uc, ctx.candidates);
                } else {
                  ctx.candidates = ctx.candidates
                    ? ctx.candidates.filter(
                        (cc) => cc.toLowerCase() !== text.toLowerCase()
                      )
                    : [];
                  const uc = ctx.context
                    .replace(/\s+/g, " ")
                    .replace(/(<([^>]+)>)/gi, "");
                  const ucc = highLightCandidate(uc, ctx.candidates);
                  ctx.context = highLightCandidate(ucc, [phrase.phrase]);
                }
              }
              return ctx;
            });
          }
          return p;
        });
        setPhrases(EMPTY_ARRAY.concat(up));

        const list = isCandidate
          ? candidatePhrases
          : isNgram
          ? getGramPhrases(gramWeight[0])
          : unClassifiedPhrases;
        const ul = cloneDeep(list).map((p) => {
          if (p.phraseId === phrase.phraseId) {
            p.context.map((ctx) => {
              if (ctx.contextId === context.contextId) {
                if (text.toLowerCase() === phrase.phrase.toLowerCase()) {
                  const uc = ctx.context
                    .replace(/\s+/g, " ")
                    .replace(/(<([^>]+)>)/gi, "");
                  ctx.context = highLightCandidate(uc, ctx.candidates);
                } else {
                  ctx.candidates = ctx.candidates
                    ? ctx.candidates.filter(
                        (cc) => cc.toLowerCase() !== text.toLowerCase()
                      )
                    : [];
                  const uc = ctx.context
                    .replace(/\s+/g, " ")
                    .replace(/(<([^>]+)>)/gi, "");
                  const ucc = highLightCandidate(uc, ctx.candidates);
                  ctx.context = highLightCandidate(ucc, [phrase.phrase]);
                }
              }
              return ctx;
            });
          }
          return p;
        });
        if (isCandidate) setCandidatePhrases(EMPTY_ARRAY.concat(ul));
        if (isNgram) setGramPhrases(ul, gramWeight[0]);
        if (isunClassified) setUnClassifiedPhrases(EMPTY_ARRAY.concat(ul));
      } else {
        const up = cloneDeep(phrases).map((p) => {
          if (p.phraseId === phrase.phraseId) {
            p.context.map((ctx) => {
              if (ctx.contextId === context.contextId) {
                ctx.topics = ctx.topics.filter(
                  (ct) => ct.value.toLowerCase() !== text.toLowerCase()
                );
                const selectedTopic = cloneDeep(topics).filter(
                  (t) => t.id === selectedTopics[0]
                );
                const mainTopicArray = cloneDeep(ctx.topics).filter(
                  (ct) => ct.value.toLowerCase() === p.phrase.toLowerCase()
                );
                const sideTopicArray = cloneDeep(ctx.topics).filter(
                  (ct) => ct.value.toLowerCase() !== p.phrase.toLowerCase()
                );

                const mtda = mainTopicWithDataAttr(
                  ctx.context.replace(/\s+/g, " ").replace(/(<([^>]+)>)/gi, ""),
                  mainTopicArray
                );
                ctx.context = sideTopicWithDataAttr(
                  mtda,
                  sideTopicArray,
                  isTopicPaletteHighlightActive,
                  selectedTopic[0].name
                );
              }
              return ctx;
            });
          }
          return p;
        });
        setPhrases(EMPTY_ARRAY.concat(up));

        //update Data Topics
        const updatedDatatopics = cloneDeep(dataTopics).map((dt) => {
          if (dt.id === selectedTopics[0]) {
            dt.phrases.map((p) => {
              if (p.phraseId === phrase.phraseId) {
                p.context.map((ctx) => {
                  if (ctx.contextId === context.contextId) {
                    ctx.topics = ctx.topics.filter(
                      (ct) => ct.value.toLowerCase() !== text.toLowerCase()
                    );
                    const selectedTopic = cloneDeep(topics).filter(
                      (t) => t.id === selectedTopics[0]
                    );
                    const mainTopicArray = cloneDeep(ctx.topics).filter(
                      (ct) => ct.value.toLowerCase() === p.phrase.toLowerCase()
                    );

                    const sideTopicArray = cloneDeep(ctx.topics).filter(
                      (ct) => ct.value.toLowerCase() !== p.phrase.toLowerCase()
                    );

                    const mtda = mainTopicWithDataAttr(
                      ctx.context
                        .replace(/\s+/g, " ")
                        .replace(/(<([^>]+)>)/gi, ""),
                      mainTopicArray
                    );

                    ctx.context = sideTopicWithDataAttr(
                      mtda,
                      sideTopicArray,
                      isTopicPaletteHighlightActive,
                      selectedTopic[0].name
                    );
                  }
                  return ctx;
                });
              }
              return p;
            });
          }
          return dt;
        });
        setDataTopics(EMPTY_ARRAY.concat(updatedDatatopics));

        //update Topics
        const updatedTopics = cloneDeep(topics).map((dt) => {
          if (dt.id === selectedTopics[0]) {
            dt.phrases.map((p) => {
              if (p.phraseId === phrase.phraseId) {
                p.context.map((ctx) => {
                  if (ctx.contextId === context.contextId) {
                    ctx.topics = ctx.topics.filter(
                      (ct) => ct.value.toLowerCase() !== text.toLowerCase()
                    );
                    const selectedTopic = cloneDeep(topics).filter(
                      (t) => t.id === selectedTopics[0]
                    );
                    const mainTopicArray = cloneDeep(ctx.topics).filter(
                      (ct) => ct.value.toLowerCase() === p.phrase.toLowerCase()
                    );

                    const sideTopicArray = cloneDeep(ctx.topics).filter(
                      (ct) => ct.value.toLowerCase() !== p.phrase.toLowerCase()
                    );

                    const mtda = mainTopicWithDataAttr(
                      ctx.context
                        .replace(/\s+/g, " ")
                        .replace(/(<([^>]+)>)/gi, ""),
                      mainTopicArray
                    );

                    ctx.context = sideTopicWithDataAttr(
                      mtda,
                      sideTopicArray,
                      isTopicPaletteHighlightActive,
                      selectedTopic[0].name
                    );
                  }
                  return ctx;
                });
              }
              return p;
            });
          }
          return dt;
        });
        setTopics(EMPTY_ARRAY.concat(updatedTopics));
      }
      setShouldBlockNavigation(true);
    } catch (err) {
      console.log(err);
    }
  };

  //fetch phrases page data for candidate/ngrams
  const loadMorePhrases = (idsToRemove) => {
    try {
      if (dataLoading) return;
      setUndoableEvent("CLEAR");
      if (isCandidate || isNgram) {
        setDataloading(true);
        const loadUrl = isCandidate ? GET_CANDIDATE_PHRASES : GET_NGRAM_PHRASES;

        const loadedPhrases = isCandidate
          ? candidatePhrases
          : getGramPhrases(gramWeight[0]);

        const filterdPhrases =
          idsToRemove.length > 0
            ? loadedPhrases.filter((p) => !idsToRemove.includes(p.phraseId))
            : loadedPhrases;

        const pageNo = filterdPhrases
          ? Math.floor(filterdPhrases.length / 100) + 1
          : 1;

        let data = {};
        data.folderId = selectedDiscoverdTopic.value;
        data.pageNo = pageNo;
        data.contextPageNo = 1;
        data.searchWord = searchWord.trim().length !== 0 ? searchWord : null;
        data.sortOrder = sortBy.length !== 0 ? sortType : null;
        data.sortColumn = sortBy.length !== 0 ? sortBy : null;

        if (isNgram) data.gramWeight = gramWeight.toString();

        client
          .get(loadUrl, { params: data })
          .then((res) => {
            if (res.data.status === SUCCESS) {
              if (isCandidate) {
                const list = get(res.data.response, "candidates");
                const fc =
                  idsToRemove.length > 0
                    ? EMPTY_ARRAY.concat(list)
                    : cloneDeep(candidatePhrases).concat(list);

                setCandidatePhrases(EMPTY_ARRAY.concat(fc));
                const phrasesList = candidatePhrases.concat(list);
                if (idsToRemove.length > 0) {
                  const filteredList = phrasesList.filter(
                    (p) => !idsToRemove.includes(p.phraseId)
                  );
                  setPhrases(
                    doClientOperations(
                      filteredList,
                      phrasePattern,
                      searchWord,
                      sortBy,
                      wordsBefore,
                      wordsAfter,
                      sortType
                    )
                  );
                } else {
                  setPhrases(
                    doClientOperations(
                      phrasesList,
                      phrasePattern,
                      searchWord,
                      sortBy,
                      wordsBefore,
                      wordsAfter,
                      sortType
                    )
                  );
                }
              }

              if (isNgram) {
                const newList = get(res.data.response, "nGrams");
                const updatedCount = get(res.data.response, "count");

                const updatedNGramData = ngramData.map((n) =>
                  n.weight === gramWeight[0]
                    ? {
                        ...n,
                        data:
                          idsToRemove.length > 0
                            ? n.data
                                .filter(
                                  (n) => !idsToRemove.includes(n.phraseId)
                                )
                                .concat(newList)
                            : n.data.concat(newList),
                        count: updatedCount,
                      }
                    : { ...n }
                );

                setNgramData(updatedNGramData);

                const gp = updatedNGramData.filter(
                  (n) => n.weight === gramWeight[0]
                );

                const gpo = doClientOperations(
                  get(gp[0], "data"),
                  phrasePattern,
                  searchWord,
                  sortBy,
                  wordsBefore,
                  wordsAfter,
                  sortType
                );

                setTotalPhrases(get(gp[0], "count"));

                if (idsToRemove.length > 0) {
                  const filteredList = gpo;
                  const un = uniqBy(filteredList, "phraseId");
                  setPhrases(
                    EMPTY_ARRAY.concat(uniqBy(filteredList, "phraseId"))
                  );
                  if (un.length > 0) {
                    setSelectedPhrases(EMPTY_ARRAY.concat(un[0].phraseId));
                  }
                } else {
                  const unn = uniqBy(gpo, "phraseId");
                  setPhrases(EMPTY_ARRAY.concat(uniqBy(gpo, "phraseId")));
                  if (unn.length > 0) {
                    setSelectedPhrases(EMPTY_ARRAY.concat(unn[0].phraseId));
                  }
                }
              }
              setPhrasesPage(pageNo);
              setDataloading(false);
              setLoading(false);
              setShouldBlockNavigation(true);
            } else setDataloading(false);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setDataloading(false);
            setLoading(false);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  //get more context data
  const updateTopicalContexts = (newContext) => {
    try {
      setUndoableEvent("CLEAR");
      const ut = cloneDeep(topics).map((t) => {
        if (t.id === selectedTopics[0]) {
          t.phrases = t.phrases.map((p) => {
            if (p.phraseId === parseInt(selectedPhrases[0])) {
              p.context = p.context.concat(newContext);
            }
            return p;
          });
        }
        return t;
      });
      setTopics(EMPTY_ARRAY.concat(ut));

      const udt = cloneDeep(dataTopics).map((t) => {
        if (t.id === selectedTopics[0]) {
          t.phrases = t.phrases.map((p) => {
            if (p.phraseId === parseInt(selectedPhrases[0])) {
              p.context = p.context.concat(newContext);
            }
            return p;
          });
        }
        return t;
      });
      setDataTopics(EMPTY_ARRAY.concat(udt));

      // Update Phrases
      const resData = doTopicSearch(ut, searchTopic);
      const p = resData.filter((t) => t.id === selectedTopics[0]);
      if (p.length > 0) {
        const pd = doClientOperations(
          cloneDeep(p[0].phrases),
          phrasePattern,
          searchWord,
          sortBy,
          wordsBefore,
          wordsAfter,
          sortType
        );
        const updatedPhrases = doClientMark(pd, searchWord);
        const updatedLoadedcontext = cloneDeep(updatedPhrases).filter(
          (up) => up.phraseId === selectedPhrases[0]
        );
        setPhrases(updatedPhrases);
        if (pd.length > 0) {
          setTotalPhrases(p[0].phrases.length);
          if (updatedLoadedcontext.length > 0)
            setLoadedContextCount(updatedLoadedcontext[0].context.length);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  //Update pagination data
  const updateRequestPage = (data, count) => {
    try {
      setUndoableEvent("CLEAR");
      if (isunClassified) {
        const newData = data[0].context;
        const ph = cloneDeep(unClassifiedPhrases).map((uc) => {
          uc.context = uc.context.concat(newData);
          return uc;
        });
        const fp = doClientOperations(
          ph,
          phrasePattern,
          searchWord,
          sortBy,
          wordsBefore,
          wordsAfter,
          sortType
        );
        setPhrases(EMPTY_ARRAY.concat(fp));
        setUnclassifiedTotalCount(count);
        setUnClassifiedPhrases(EMPTY_ARRAY.concat(ph));
        return;
      }

      if (isCandidate) {
        const ph = cloneDeep(candidatePhrases).map((p) => {
          if (p.phraseId === selectedPhrases[0])
            p.context = uniqBy(cloneDeep(p.context).concat(data), "contextId");
          return p;
        });
        const fp = doClientOperations(
          ph,
          phrasePattern,
          searchWord,
          sortBy,
          wordsBefore,
          wordsAfter,
          sortType
        );
        const uniPhrases = uniqBy(ph, "phraseId");
        setPhrases(doClientMark(uniPhrases, searchWord));
        setCandidatePhrases(ph);

        const ab = cloneDeep(fp).filter(
          (p) => p.phraseId === selectedPhrases[0]
        );
        setLoadedContextCount(ab[0].context.length);
      }

      if (isNgram) {
        const nPhrases = getGramPhrases(gramWeight[0]);
        const matchedPhrase = nPhrases.map((p) => {
          if (p.phraseId === selectedPhrases[0])
            p.context = uniqBy(p.context.concat(data), "contextId");
          return p;
        });
        const ph = doClientOperations(
          matchedPhrase,
          phrasePattern,
          searchWord,
          sortBy,
          wordsBefore,
          wordsAfter,
          sortType
        );
        const uniPhrases = uniqBy(ph, "phraseId");
        setPhrases(doClientMark(uniPhrases, searchWord));
        setGramPhrases(matchedPhrase, gramWeight[0]);

        const ab = cloneDeep(ph).filter(
          (p) => p.phraseId === selectedPhrases[0]
        );
        setLoadedContextCount(ab[0].context.length);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getTotalContext = (ids) => {
    const payLoad = {
      folderId: selectedDiscoverdTopic.value,
      phraseIds: ids,
      docType: isCandidate ? "candidates" : isNgram ? "ngrams" : "topics",
    };

    return client.post(GET_ALL_CONTEXTS, payLoad);
  };

  //New Topic from Phrse box events
  const handleNewTopic = useCallback((phrase, multiple) => {
    setUndoableEvent("CLEAR");
    if (!multiple) {
      setAppendPhrase(phrase);
      setPhraseBoxEvent(true);
    }
    setOpen(true);
    setByDrop(true);
  }, []);

  //New Topic Event
  const createNewTopic = async (dataObj) => {
    try {
      if (
        dataTopics.find(
          (t) => t.name.toLowerCase() === dataObj.topicTitle.toLowerCase()
        )
      ) {
        toast.warn(`Topic name ${dataObj.topicTitle} already exists.`);
        return;
      }

      setUndoableEvent("CLEAR");
      setOpen(false);
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));

      //new topic created on unclassified documents
      if (isunClassified) {
        const updatePhrases = (data) =>
          cloneDeep(data).map((p) => {
            if (p.phraseId === selectedContext.phraseId) {
              p.context = p.context.map((pc) => {
                if (pc.contextId === selectedContext.contextId) {
                  const mainTopicObj = {
                    topic: "",
                    value: appendPhrase.text,
                    frequency: 1,
                  };
                  pc.context = candidateMainHighLight(
                    pc.context,
                    appendPhrase.text
                  );
                  pc.topics = pc.topics
                    ? pc.topics.concat(mainTopicObj)
                    : [mainTopicObj];
                }
                return pc;
              });
            }
            return p;
          });
        setPhrases(updatePhrases(phrases));
        setUnClassifiedPhrases(updatePhrases(unClassifiedPhrases));

        const newPhrase = cloneDeep(appendPhrase);
        const mainTopicObj = {
          topic: dataObj.topicTitle,
          value: newPhrase.phrase,
          frequency: 1,
        };

        newPhrase.context = newPhrase.context.map((ctx) => {
          return {
            contextId: uuidv4(),
            topics: [mainTopicObj],
            context: mainTopicWithDataAttr(selectedContext.context, [
              mainTopicObj,
            ]),
          };
        });

        newPhrase.isCurated = true;

        let newTopic = {};
        newTopic["createdAt"] = localDateTime(new Date());
        newTopic["frequency"] = 1;
        newTopic["id"] = Math.floor(1000000 + Math.random() * 900000);
        newTopic["isCurated"] = true;
        newTopic["isFolder"] = false;
        newTopic["isLoaded"] = true;
        newTopic["modifiedAt"] = localDateTime(new Date());
        newTopic["name"] = dataObj.topicTitle;
        newTopic["parentId"] = selectedDiscoverdTopic.value;
        newTopic["phrases"] = EMPTY_ARRAY.concat(newPhrase);
        newTopic["sentiment"] = dataObj.topicSentiment;
        newTopic["sentimentScore"] = 0;
        newTopic["userId"] = userDetails.id;

        setTopics(cloneDeep(topics).concat(newTopic));
        setDataTopics(cloneDeep(dataTopics).concat(newTopic));
        setAppendPhrase({});
        setNewTopicTitle(EMPTY_STRING);
        setCuratedTopics((state) => state.concat(newTopic.id));
        setShouldBlockNavigation(true);
        setAddedNewTopic(true);

        return;
      }

      //create new topic when selected phrase boxes are dropped on 'New Topic' or icon event
      if (byDrop) {
        let cpc = [];
        if (isCandidate || isNgram) {
          const phraseList = isCandidate
            ? candidatePhrases
            : getGramPhrases(gramWeight[0]);
          const cp = phraseBoxEvent
            ? [appendPhrase]
            : cloneDeep(phraseList).filter((p) =>
                selectedPhrases.includes(p.phraseId)
              );

          cpc = cp.map((p) => {
            p.isCurated = true;
            p.isSeed = true;
            p.context = p.context.map((ctx) => {
              let ctxobj = {};
              let topicsObj = {
                topic: dataObj.topicTitle,
                value: p.phrase,
                frequency: 1,
              };
              ctxobj["contextId"] = ctx.contextId;
              ctxobj["topics"] = [topicsObj].concat(
                ctx.topics ? ctx.topics : []
              );
              ctxobj["context"] = ctx.context;
              return ctxobj;
            });
            return p;
          });
          if (isNgram) handleProcessedEntity("ngram", cp[0].phraseId, "move");
        } else {
          const ft = cloneDeep(topics).filter(
            (topic) => topic.id === selectedTopics[0]
          );
          const selPhrases = phraseBoxEvent
            ? [appendPhrase]
            : ft[0].phrases.filter((p) => selectedPhrases.includes(p.phraseId));

          cpc = selPhrases.map((p) => {
            p.isCurated = true;
            p.isSeed = true;
            p.isCurated = true;
            return p;
          });
        }

        let fetchedContexts = [];
        try {
          const { data } = await getTotalContext(selectedPhrases);
          if (data.status === SUCCESS) fetchedContexts = data.response;
        } catch (err) {
          console.log(err);
        }

        const selTopic = isTopical
          ? topics.find((t) => t.id === selectedTopics[0])
          : null;

        const finalPhrases = cloneDeep(cpc).map((fp) => {
          const currentContexts = fetchedContexts.find(
            (fc) => fc.phraseId === fp.phraseId
          );
          fp.context = getUniqueListBy(
            fp.context.concat(currentContexts ? currentContexts.data : []),
            "contextId"
          ).map((fpc) => {
            const mainTopicArray = [
              { topic: dataObj.topicTitle, value: fp.phrase },
            ];
            const sideTopicArray = fpc.topics
              ? fpc.topics.filter(
                  (ct) =>
                    ct.topic.toLowerCase() !== dataObj.topicTitle.toLowerCase()
                )
              : [];

            const cleanContext = fpc.context
              .replace(/\s+/g, " ")
              .replace(/<\/?span[^>]*>/gi, "")
              .replace(/<\/?mark[^>]*>/gi, "");

            const highlightCandidate = fpc.candidates
              ? highLightCandidate(cloneDeep(cleanContext), fpc.candidates)
              : cloneDeep(cleanContext);

            const hightLightMainTopic = mainTopicWithDataAttr(
              highlightCandidate,
              mainTopicArray
            );
            const highlightSideTopic = sideTopicWithDataAttr(
              hightLightMainTopic,
              sideTopicArray,
              isTopicPaletteHighlightActive,
              dataObj.topicTitle.toLowerCase()
            );

            fpc.context =
              searchWord.length === 0
                ? highlightSideTopic
                : highLightSearchWord(highlightSideTopic, searchWord);

            fpc.topics = selTopic
              ? fpc.topics.map((ct) => {
                  if (ct.topic === selTopic.name) {
                    ct.topic = dataObj.topicTitle;
                    ct.frequency = 1;
                  }
                  return ct;
                })
              : fpc.topics;
            return fpc;
          });
          return fp;
        });

        let topicObj = {};
        topicObj["createdAt"] = localDateTime(new Date());
        topicObj["frequency"] = uniqueArray(finalPhrases).length;
        topicObj["id"] = Math.floor(1000000 + Math.random() * 900000);
        topicObj["isCurated"] = true;
        topicObj["isFolder"] = false;
        topicObj["isLoaded"] = true;
        topicObj["modifiedAt"] = localDateTime(new Date());
        topicObj["name"] = dataObj.topicTitle;
        topicObj["parentId"] = selectedDiscoverdTopic.value;
        topicObj["phrases"] = EMPTY_ARRAY.concat(finalPhrases);
        topicObj["sentiment"] = dataObj.topicSentiment;
        topicObj["sentimentScore"] = 0;
        topicObj["userId"] = userDetails.id;

        setCuratedTopics((state) => state.concat(topicObj.id));

        //update topics
        const newTopics = cloneDeep(topics).concat(topicObj);
        const fop = phraseBoxEvent
          ? phrases.filter((el) => appendPhrase.phraseId !== el.phraseId)
          : phrases.filter(
              (el) => !selectedPhrases.includes(parseInt(el.phraseId))
            );
        const updatedTopicFrequency = uniqueArray(fop).length;
        const ut = newTopics.map((t) => {
          if (t.id === selectedTopics[0]) {
            t.frequency = updatedTopicFrequency;
            t.phrases = fop;
          }
          return t;
        });

        //update dataTopics
        const ndt = cloneDeep(dataTopics).concat(topicObj);
        const newDataTopics = ndt.map((dt) => {
          if (dt.id === selectedTopics[0]) {
            dt.frequency = updatedTopicFrequency;
            dt.phrases = phraseBoxEvent
              ? dt.phrases.filter((el) => appendPhrase.phraseId !== el.phraseId)
              : dt.phrases.filter(
                  (el) => !selectedPhrases.includes(parseInt(el.phraseId))
                );
          }
          return dt;
        });
        setDataTopics(newDataTopics);

        const filteredPhrases = filterTopicalPhrases(fop);

        if (selectedTopicSentiment) {
          if (
            selectedTopicSentiment.toLowerCase() ===
            get(topicObj, "sentiment").toLowerCase()
          ) {
            setTopics(ut);
            setPhrases(EMPTY_ARRAY.concat(filteredPhrases));
            setSelectedPhrases(
              EMPTY_ARRAY.concat(
                filteredPhrases.length
                  ? EMPTY_ARRAY.concat(filteredPhrases[0].phraseId)
                  : EMPTY_ARRAY
              )
            );
          }
        } else {
          setTopics(ut);
          setPhrases(EMPTY_ARRAY.concat(filteredPhrases));
          const selPhraseIds = filteredPhrases.length
            ? EMPTY_ARRAY.concat(filteredPhrases[0].phraseId)
            : EMPTY_ARRAY;
          setSelectedPhrases(EMPTY_ARRAY.concat(selPhraseIds));
        }

        setByDrop(false);
        setAppendPhrase({});
        setPhraseBoxEvent(false);
        setNewTopicTitle(EMPTY_STRING);
        setAddedNewTopic(true);

        if (isCandidate || isNgram) {
          if (selectedPhrases.length === phrases.length) {
            setLoading(true);
            loadMorePhrases(selectedPhrases);
          }
        }
      } else {
        //update corresponding tabs(candidate/ngram/unclassified)
        const tabPhrases = isCandidate
          ? cloneDeep(candidatePhrases)
          : isNgram
          ? getGramPhrases(gramWeight[0])
          : unClassifiedPhrases;

        const highlightUpdatedPhrases = tabPhrases.map((p) => {
          if (p.phraseId === selectedContext.phraseId) {
            p.context.map((pc) => {
              if (pc.contextId === selectedContext.contextId) {
                const mainTopicObj = {
                  topic: "",
                  value: appendPhrase.text,
                  frequency: 1,
                };
                pc.context = candidateMainHighLight(
                  pc.context,
                  appendPhrase.text
                );
                pc.topics = pc.topics
                  ? pc.topics.concat(mainTopicObj)
                  : [mainTopicObj];
              }
              return pc;
            });
          }
          return p;
        });

        if (isCandidate)
          setCandidatePhrases(EMPTY_ARRAY.concat(highlightUpdatedPhrases));
        if (isNgram) setGramPhrases(highlightUpdatedPhrases, gramWeight[0]);

        //create new topic by selected text
        let newTopicObj = {};

        if (appendPhrase) {
          const nc = appendPhrase.context.map((ctx) => {
            const topicsObj = {
              frequency: 1,
              topic: dataObj.topicTitle,
              value: appendPhrase.phrase,
            };
            const topicsArry = selectedContext.topics
              ? [topicsObj].concat(selectedContext.topics)
              : EMPTY_ARRAY.concat(topicsObj);

            if (isCandidate || isNgram) {
              const canHigh = highLightCandidate(
                ctx.context,
                selectedContext.candidates.filter(
                  (p) => !p.includes(appendPhrase.phrase)
                )
              );
              const canMain = candidateMainHighLight(
                canHigh,
                appendPhrase.phrase
              );
              return {
                context: canMain,
                contextId: uuidv4(),
                topics: topicsArry,
              };
            } else {
              return {
                context: ctx.context
                  .replace(/\s+/g, " ")
                  .replace(/<\/?span[^>]*>/gi, ""),
                contextId: uuidv4(),
                topics: topicsArry,
              };
            }
          });
          appendPhrase.context = nc;
          appendPhrase.isSeed = true;
          appendPhrase.isCurated = true;
        }

        newTopicObj["createdAt"] = localDateTime(new Date());
        newTopicObj["frequency"] = 1;
        newTopicObj["id"] = Math.floor(1000000 + Math.random() * 900000);
        newTopicObj["isCurated"] = true;
        newTopicObj["isFolder"] = false;
        newTopicObj["modifiedAt"] = localDateTime(new Date());
        newTopicObj["name"] = dataObj.topicTitle;
        newTopicObj["parentId"] = selectedDiscoverdTopic.value;
        //newTopicObj["phrases"] = EMPTY_ARRAY.concat(appendPhrase);
        newTopicObj["phrases"] = EMPTY_ARRAY.concat(
          doClientMark([appendPhrase], "", "isTopics", {
            name: dataObj.topicTitle,
          })
        );
        newTopicObj["sentiment"] = dataObj.topicSentiment;
        newTopicObj["sentimentScore"] = 0;
        newTopicObj["userId"] = userDetails.id;
        newTopicObj["isLoaded"] = true;

        setCuratedTopics((state) => state.concat(newTopicObj.id));

        if (isCandidate || isNgram) {
          setDataTopics(cloneDeep(dataTopics).concat(newTopicObj));
          if (selectedTopicSentiment) {
            if (
              selectedTopicSentiment.toLowerCase() ===
              get(newTopicObj, "sentiment").toLowerCase()
            )
              setTopics(cloneDeep(topics).concat(newTopicObj));
          } else setTopics(cloneDeep(topics).concat(newTopicObj));
        } else {
          //update Data Topics
          const updatedDatatopics = cloneDeep(dataTopics).map((dt) => {
            if (dt.id === selectedTopics[0]) {
              dt.phrases.map((p) => {
                if (p.phraseId === selectedContext.phraseId) {
                  p.context.map((ctx) => {
                    if (ctx.contextId === selectedContext.contextId) {
                      const selectedTopic = cloneDeep(topics).filter(
                        (t) => t.id === selectedTopics[0]
                      );
                      let topicsObj = {
                        frequency: 1,
                        topic: dataObj.topicTitle,
                        value: appendPhrase.text,
                      };
                      ctx.context = sideTopicWithDataAttr(
                        ctx.context,
                        [topicsObj],
                        isTopicPaletteHighlightActive,
                        selectedTopic[0].name
                      );
                      ctx.topics = ctx.topics.concat(topicsObj);
                    }
                    return ctx;
                  });
                }
                return p;
              });
            }
            return dt;
          });
          setDataTopics(updatedDatatopics.concat(newTopicObj));

          //update topics
          const updatedTopics = cloneDeep(topics).map((t) => {
            if (t.id === selectedTopics[0]) {
              t.phrases.map((tp) => {
                if (tp.phraseId === selectedContext.phraseId) {
                  tp.context.map((tctx) => {
                    if (tctx.contextId === selectedContext.contextId) {
                      const selectedTopic = cloneDeep(topics).filter(
                        (t) => t.id === selectedTopics[0]
                      );
                      const topicsObj = {
                        frequency: 1,
                        topic: dataObj.topicTitle,
                        value: appendPhrase.text,
                      };
                      tctx.topics = tctx.topics.concat(topicsObj);
                      tctx.context = sideTopicWithDataAttr(
                        tctx.context,
                        [topicsObj],
                        isTopicPaletteHighlightActive,
                        selectedTopic[0].name
                      );
                    }
                    return tctx;
                  });
                }
                return tp;
              });
            }
            return t;
          });

          if (selectedTopicSentiment) {
            if (
              selectedTopicSentiment.toLowerCase() ===
              get(newTopicObj, "sentiment").toLowerCase()
            )
              setTopics(updatedTopics.concat(newTopicObj));
          } else setTopics(updatedTopics.concat(newTopicObj));

          //update phrases
          const up = cloneDeep(updatedTopics).filter(
            (ut) => ut.id === selectedTopics[0]
          );
          if (up.length > 0) {
            const ph = doClientOperations(
              up[0].phrases,
              phrasePattern,
              searchWord,
              sortBy,
              wordsBefore,
              wordsAfter,
              sortType
            );
            const filteredPhrases = filterTopicalPhrases(ph);
            setPhrases(EMPTY_ARRAY.concat(filteredPhrases));
            const selPhraseIds = filteredPhrases.length
              ? topicalPhraseType !== "machine"
                ? selectedPhrases
                : filteredPhrases[0].phraseId
              : EMPTY_ARRAY;
            setSelectedPhrases(EMPTY_ARRAY.concat(selPhraseIds));
          }
        }

        setByDrop(false);
        setAppendPhrase({});
        setAddedNewTopic(true);
      }

      setNewTopicTitle(EMPTY_STRING);
      setShouldBlockNavigation(true);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteHighlightedPhrase = () => {
    try {
      setUndoableEvent("CLEAR");
      const phraseId = dialogData.context.phraseId;
      const contextId = dialogData.context.contextId;

      const updateFreqAry = cloneDeep(phrases).filter(
        (p) => p.phraseId === phraseId
      );
      const updateFreq = updateFreqAry[0].frequency - 1; // since adding one phrase

      const fp = cloneDeep(phrases).map((p) => {
        if (p.phraseId === phraseId) {
          p.phrase = dialogData.selectedText;
          p.frequency = 1;
          p.context = p.context.filter((pc) => pc.contextId === contextId);
        }
        return p;
      });
      setPhrases(EMPTY_ARRAY.concat(fp));

      if (fp.length > 0) setSelectedPhrases(EMPTY_ARRAY.concat(fp[0].phraseId));

      //update topics model
      const ut = cloneDeep(topics).map((t) => {
        if (t.id === selectedTopics[0]) {
          t.frequency = t.frequency - updateFreq;
          t.phrases = t.phrases.map((tp) => {
            if (tp.phraseId === phraseId) {
              tp.phrase = dialogData.selectedText;
              tp.frequency = 1;
              tp.context = tp.context.filter(
                (tpc) => tpc.contextId === contextId
              );
            }
            return tp;
          });
        }
        return t;
      });
      setTopics(EMPTY_ARRAY.concat(ut));

      //update data topics model
      const udt = cloneDeep(dataTopics).map((t) => {
        if (t.id === selectedTopics[0]) {
          t.frequency = t.frequency - updateFreq;
          t.phrases = t.phrases.map((tp) => {
            if (tp.phraseId === phraseId) {
              tp.phrase = dialogData.selectedText;
              tp.frequency = 1;
              tp.context = tp.context.filter(
                (tpc) => tpc.contextId === contextId
              );
            }
            return tp;
          });
        }
        return t;
      });
      setDataTopics(EMPTY_ARRAY.concat(udt));

      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  //Handle Topic Rename
  const updateTopicRename = (eventTopic, value) => {
    try {
      setUndoableEvent("CLEAR");
      const oldName = eventTopic.name;
      const id = eventTopic.id;
      const renamedItem = { id: id, oldName: oldName, newName: value };
      const isExists = renamedTopics.some((el) => el.id === id);
      if (isExists) {
        const urt = cloneDeep(renamedTopics).map((rt) => {
          if (rt.id === id) {
            rt.newName = value;
          }
          return rt;
        });
        setRenamedTopics(EMPTY_ARRAY.concat(urt));
      } else setRenamedTopics(renamedTopics.concat(renamedItem));

      //Update Topics
      const ud = cloneDeep(topics).map((e) => {
        e.name = e.id === id ? value : e.name;
        e.phrases = e.phrases.map((p) => {
          p.context = p.context.map((ctx) => {
            ctx.topics = ctx.topics.map((ct) => {
              ct.topic =
                ct.topic.trim().toLowerCase() === oldName.trim().toLowerCase()
                  ? value
                  : ct.topic;
              return ct;
            });
            return ctx;
          });
          return p;
        });
        return e;
      });

      //Update data topics
      const udt = cloneDeep(dataTopics).map((dt) => {
        dt.name = dt.id === id ? value : dt.name;
        dt.phrases = dt.phrases.map((dp) => {
          dp.context = dp.context.map((dctx) => {
            dctx.topics = dctx.topics.map((dct) => {
              dct.topic =
                dct.topic.trim().toLowerCase() === oldName.trim().toLowerCase()
                  ? value
                  : dct.topic;
              return dct;
            });
            return dctx;
          });
          return dp;
        });
        return dt;
      });
      setTopics(EMPTY_ARRAY.concat(ud));
      setDataTopics(EMPTY_ARRAY.concat(udt));

      //update Phrases
      const up = cloneDeep(phrases).map((p) => {
        p.context = p.context.map((pctx) => {
          pctx.topics = pctx.topics.map((pctc) => {
            pctc.topic =
              pctc.topic.trim().toLowerCase() === oldName.trim().toLowerCase()
                ? value
                : pctc.topic;
            return pctc;
          });
          return pctx;
        });
        return p;
      });
      setPhrases(EMPTY_ARRAY.concat(up));
      updateTopicPaletteData(eventTopic.id, "rename", value);
      setShouldBlockNavigation(true);
    } catch (err) {
      console.log(err);
    }
  };

  //Handle Topic Delete
  const updateTopicDelete = async (topic) => {
    try {
      setUndoableEvent("CLEAR");
      //Add deleted topic phrases to candidates and update candidates
      let deletedPhrases = topic.phrases;

      if (!topic.isLoaded) {
        try {
          setLoading(true);
          const { data } = await getTopicDetails(topic.id);
          if (data.status === SUCCESS) {
            const fetchedPhrases = get(data.response[0], "phrases");
            deletedPhrases = topic.phrases.length
              ? loadashValues(
                  lodashMerge(
                    lodashKeyBy(fetchedPhrases, "phraseId"),
                    lodashKeyBy(topic.phrases, "phraseId")
                  )
                )
              : topic.phrases.concat(fetchedPhrases);
          }
        } catch (err) {
          console.log(err);
          toast.error(
            "Could not fetch deleted topic phrases. Please try later."
          );
          return;
        }
      }

      const modifiedDeletedPhrases = cloneDeep(deletedPhrases).map((p) => {
        p.topics = [];
        p.context = p.context.map((ctx) => {
          let cc = {
            context: ctx.context,
            contextId: ctx.contextId,
          };
          return cc;
        });
        return p;
      });

      //update candidates
      const updatedCandidates = cloneDeep(
        candidatePhrases.concat(modifiedDeletedPhrases)
      ).map((cp) => {
        cp.context = cp.context.map((tpc) => {
          tpc.candidates = [cp.phrase];
          tpc.context = tpc.context
            .replace(/\s+/g, " ")
            .replace(/(<([^>]+)>)/gi, "");
          tpc.topics = tpc.topics
            ? tpc.topics.filter(
                (tpct) =>
                  tpct.topic.trim().toLowerCase() !==
                  topic.name.trim().toLowerCase()
              )
            : [];
          return tpc;
        });
        return cp;
      });
      setCandidatePhrases(
        EMPTY_ARRAY.concat(doClientMark(updatedCandidates, searchWord))
      );
      setCandidateTotalCount((state) => state + modifiedDeletedPhrases.length);

      //update topics
      const ft = cloneDeep(topics)
        .filter((t) => t.id !== topic.id)
        .map((t) => {
          t.phrases = t.phrases.map((tp) => {
            tp.context = tp.context.map((tpc) => {
              tpc.topics = tpc.topics.filter(
                (tpct) =>
                  tpct.topic.trim().toLowerCase() !==
                  topic.name.trim().toLowerCase()
              );

              const mainTopicArray = tpc.topics.filter(
                (ct) => ct.value.toLowerCase() === tp.phrase.toLowerCase()
              );

              const sideTopicArray = tpc.topics.filter(
                (ct) => ct.value.toLowerCase() !== tp.phrase.toLowerCase()
              );

              const cleanContext = tpc.context
                .replace(/\s+/g, " ")
                .replace(/<\/?span[^>]*>/gi, "")
                .replace(/<\/?mark[^>]*>/gi, "");

              const highlightCandidate = tpc.candidates
                ? highLightCandidate(cloneDeep(cleanContext), tpc.candidates)
                : cloneDeep(cleanContext);

              const hightLightMainTopic = mainTopicWithDataAttr(
                highlightCandidate,
                mainTopicArray
              );
              const highlightSideTopic = sideTopicWithDataAttr(
                hightLightMainTopic,
                sideTopicArray,
                isTopicPaletteHighlightActive,
                topic.name
              );

              tpc.context =
                searchWord.length === 0
                  ? highlightSideTopic
                  : highLightSearchWord(highlightSideTopic, searchWord);

              return tpc;
            });
            return tp;
          });
          return t;
        });
      setDeletedTopic(topic.id);
      setTimeout(() => {
        setTopics(EMPTY_ARRAY.concat(ft));
      }, 200);

      //update data topics
      const dft = cloneDeep(dataTopics)
        .filter((dt) => dt.id !== topic.id)
        .map((t) => {
          t.phrases = t.phrases.map((tp) => {
            tp.context = tp.context.map((tpc) => {
              tpc.topics = tpc.topics.filter(
                (tpct) =>
                  tpct.topic.trim().toLowerCase() !==
                  topic.name.trim().toLowerCase()
              );

              const mainTopicArray = tpc.topics.filter(
                (ct) => ct.value.toLowerCase() === tp.phrase.toLowerCase()
              );

              const sideTopicArray = tpc.topics.filter(
                (ct) => ct.value.toLowerCase() !== tp.phrase.toLowerCase()
              );

              const cleanContext = tpc.context
                .replace(/\s+/g, " ")
                .replace(/<\/?span[^>]*>/gi, "")
                .replace(/<\/?mark[^>]*>/gi, "");

              const highlightCandidate = tpc.candidates
                ? highLightCandidate(cloneDeep(cleanContext), tpc.candidates)
                : cloneDeep(cleanContext);

              const hightLightMainTopic = mainTopicWithDataAttr(
                highlightCandidate,
                mainTopicArray
              );
              const highlightSideTopic = sideTopicWithDataAttr(
                hightLightMainTopic,
                sideTopicArray,
                isTopicPaletteHighlightActive,
                topic.name
              );

              tpc.context =
                searchWord.length === 0
                  ? highlightSideTopic
                  : highLightSearchWord(highlightSideTopic, searchWord);

              return tpc;
            });
            return tp;
          });
          return t;
        });
      setDataTopics(EMPTY_ARRAY.concat(dft));

      //update ngrams
      const updatedGrams = cloneDeep(getGramPhrases(gramWeight[0])).map(
        (cp) => {
          cp.context = cp.context.map((tpc) => {
            tpc.candidates = [cp.phrase];
            tpc.context = tpc.context
              .replace(/\s+/g, " ")
              .replace(/(<([^>]+)>)/gi, "");
            tpc.topics = tpc.topics
              ? tpc.topics.filter(
                  (tpct) =>
                    tpct.topic.trim().toLowerCase() !==
                    topic.name.trim().toLowerCase()
                )
              : [];
            return tpc;
          });
          return cp;
        }
      );
      setGramPhrases(updatedGrams, gramWeight[0]);

      if (isCandidate || isNgram || isunClassified) {
        const updatedPhrases = cloneDeep(
          isCandidate ? updatedCandidates : phrases
        ).map((p) => {
          p.context = p.context.map((pc) => {
            pc.candidates = [p.phrase];
            pc.context = pc.context
              .replace(/\s+/g, " ")
              .replace(/(<([^>]+)>)/gi, "");
            pc.topics = pc.topics
              ? pc.topics.filter(
                  (pct) =>
                    pct.topic.trim().toLowerCase() !==
                    topic.name.trim().toLowerCase()
                )
              : [];
            return pc;
          });
          return p;
        });
        setPhrases(
          EMPTY_ARRAY.concat(doClientMark(updatedPhrases, searchWord))
        );
        setTotalPhrases(
          (state) => state + (isCandidate ? modifiedDeletedPhrases.length : 0)
        );
      } else {
        //To update Selected Phrases
        if (ft.length === 0) {
          setPhrases(EMPTY_ARRAY);
          setSelectedTopics(EMPTY_ARRAY);
          setSelectedPhrases(EMPTY_ARRAY);
          setTotalPhrases(0);
        } else {
          if (topic.id === selectedTopics[0]) {
            setSelectedTopics(EMPTY_ARRAY.concat(ft[0].id));
            setPrevSelectedTopics(EMPTY_ARRAY.concat(ft[0].id));
            if (ft[0].isLoaded) {
              const filteredPhrases = filterTopicalPhrases(ft[0].phrases);
              setPhrases(
                EMPTY_ARRAY.concat(doClientMark(filteredPhrases, searchWord))
              );
              setSelectedPhrases(
                EMPTY_ARRAY.concat(
                  filteredPhrases.length ? filteredPhrases[0].phraseId : []
                )
              );
              setTotalPhrases(ft[0].phrases.length);
            } else {
              setTimeout(() => {
                setDeletedSelectedTopic(true);
              }, 500);
            }
          } else {
            const updatedPhrases = cloneDeep(phrases).map((p) => {
              p.context = p.context.map((pc) => {
                //pc.candidates = [p.phrase]
                pc.context = pc.context
                  .replace(/\s+/g, " ")
                  .replace(/(<([^>]+)>)/gi, "");
                pc.topics = pc.topics
                  ? pc.topics.filter(
                      (pct) =>
                        pct.topic.trim().toLowerCase() !==
                        topic.name.trim().toLowerCase()
                    )
                  : [];
                return pc;
              });
              return p;
            });
            setPhrases(
              EMPTY_ARRAY.concat(doClientMark(updatedPhrases, searchWord))
            );
          }
        }
      }

      if (!topic.isLoaded) setLoading(false);

      updateTopicPaletteData(topic.id, "delete");

      setShouldBlockNavigation(true);
    } catch (err) {
      console.log(err);
    }
  };

  //Update Topic Sentiment
  const updateTopicSentiment = (eventTopic, value) => {
    try {
      setUndoableEvent("CLEAR");
      //update data topics
      const udt = cloneDeep(dataTopics).map((dt) => {
        if (dt.id === eventTopic.id) dt.sentiment = value.toUpperCase();
        return dt;
      });
      setDataTopics(EMPTY_ARRAY.concat(udt));

      //update sentiment for topic
      let ud = [];
      ud = cloneDeep(topics).map((e) => {
        if (e.id === eventTopic.id) e.sentiment = value.toUpperCase();
        return e;
      });

      //if filters applied on topics by sentiment
      if (selectedTopicSentiment) {
        //if changed sentiment and topic sentiment are same
        if (value.toLowerCase() === selectedTopicSentiment.toLowerCase()) {
          setTopics(EMPTY_ARRAY.concat(ud));
          return;
        }

        //Filter topics
        const fud = ud.filter((e) => e.id !== eventTopic.id);
        setTopics(EMPTY_ARRAY.concat(fud));
        if (isCandidate || isNgram || isunClassified) return;
        //set phrases and selected phrases
        if (fud.length > 0) {
          setSelectedTopics(EMPTY_ARRAY.concat(fud[0].id));
          const filteredPhrases = filterTopicalPhrases(fud[0].phrases);
          setPhrases(filteredPhrases);
          setSelectedPhrases(
            EMPTY_ARRAY.concat(
              filteredPhrases.length ? filteredPhrases[0].phraseId : []
            )
          );
        } else {
          if (isCandidate || isNgram || isunClassified) return;
          setPhrases(EMPTY_ARRAY);
          setSelectedPhrases(EMPTY_ARRAY);
        }
      } else {
        setTopics(EMPTY_ARRAY.concat(ud));
      }
      setShouldBlockNavigation(true);
    } catch (err) {
      console.log(err);
    }
  };

  //Update Topic Emotion
  const updateTopicEmotion = (eventTopic, value) => {
    try {
      setUndoableEvent("CLEAR");
      //update data topics
      const udt = cloneDeep(dataTopics).map((dt) => {
        if (dt.id === eventTopic.id) {
          dt.emotion = value;
          if (value && POSITIVE_EMOTIONS.includes(value))
            dt.sentiment = "POSITIVE";
          else if (value && NEGATIVE_EMOTIONS.includes(value))
            dt.sentiment = "NEGATIVE";
        }
        return dt;
      });
      setDataTopics(EMPTY_ARRAY.concat(udt));

      //update emotion for topic
      let ud = [];
      ud = cloneDeep(topics).map((e) => {
        if (e.id === eventTopic.id) {
          e.emotion = value;
          if (value && POSITIVE_EMOTIONS.includes(value))
            e.sentiment = "POSITIVE";
          else if (value && NEGATIVE_EMOTIONS.includes(value))
            e.sentiment = "NEGATIVE";
        }
        return e;
      });
      setTopics(EMPTY_ARRAY.concat(ud));
      setShouldBlockNavigation(true);
    } catch (err) {
      console.log(err);
    }
  };

  const updateTopicCurated = (topicId) => {
    try {
      setUndoableEvent("CLEAR");
      //update data topics
      const udt = cloneDeep(dataTopics).map((dt) => {
        if (dt.id === topicId) {
          dt.isCurated = !dt.isCurated;
        }
        return dt;
      });
      setDataTopics(EMPTY_ARRAY.concat(udt));

      //update emotion for topic
      let ud = [];
      ud = cloneDeep(topics).map((e) => {
        if (e.id === topicId) {
          e.isCurated = !e.isCurated;
        }
        return e;
      });

      setTopics(EMPTY_ARRAY.concat(ud));
      setShouldBlockNavigation(true);
    } catch (error) {
      console.log(error);
    }
  };

  //Context Menu Events on Topics
  const updateTopics = (event, value, eventTopic) => {
    try {
      if (event === "rename") {
        updateTopicRename(eventTopic, value);
      }
      if (event === "delete") {
        updateTopicDelete(eventTopic);
      }
      if (event === "sentiment") {
        updateTopicSentiment(eventTopic, value);
      }
      if (event === "emotion") {
        updateTopicEmotion(eventTopic, value);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //Icon Events on Phrase Box
  const updatePhrases = (nd, id) => {
    try {
      setPhrases(EMPTY_ARRAY.concat(nd));
      if (nd.length > 0) {
        setSelectedPhrases(EMPTY_ARRAY.concat(nd[0].phraseId));
      }
      if (isCandidate) {
        const uc = cloneDeep(candidatePhrases).filter((c) => c.phraseId !== id);
        setCandidatePhrases(EMPTY_ARRAY.concat(uc));
      }
      if (isNgram) {
        const ug = getGramPhrases(gramWeight[0]).filter(
          (g) => g.phraseId !== id
        );
        setGramPhrases(ug, gramWeight[0]);
      }
      setShouldBlockNavigation(true);
    } catch (err) {
      console.log(err);
    }
  };

  //Handle selected text events
  const newCandidatePhrase = (ud, phraseContext, newPhrase) => {
    setUndoableEvent("CLEAR");
    const curatedCandidates = localStorage.getItem("newCandidates");
    if (curatedCandidates) {
      const cc = JSON.parse(curatedCandidates).concat(newPhrase);
      localStorage.setItem("newCandidates", JSON.stringify(cc));
    } else {
      localStorage.setItem("newCandidates", JSON.stringify([newPhrase]));
    }

    try {
      const prevCandidatesHighlights = highLightCandidate(
        phraseContext.context,
        selectedContext.candidates ? selectedContext.candidates : []
      );

      //update candidate phrases
      const ucp = cloneDeep(candidatePhrases).map((cp) => {
        if (cp.phraseId === phraseContext.phraseId) {
          cp.context.map((cpc) => {
            if (cpc.contextId === phraseContext.contextId) {
              cpc.context = prevCandidatesHighlights;
            }
            return cpc;
          });
        }
        return cp;
      });
      setCandidatePhrases(ucp.concat(newPhrase));
      setCandidateTotalCount(candidateTotalCount + 1);
      setSummaryData((prevState) => ({
        ...prevState,
        noOfCandidatePhrases: prevState.noOfCandidatePhrases + 1,
      }));

      if (isCandidate) {
        //update phrases
        const mud = cloneDeep(ud).map((cp) => {
          if (cp.phraseId === phraseContext.phraseId) {
            cp.context.map((cpc) => {
              if (cpc.contextId === phraseContext.contextId) {
                cpc.context = prevCandidatesHighlights;
              }
              return cpc;
            });
          }
          return cp;
        });
        setPhrases(mud.concat(newPhrase));
        setTotalPhrases(totalPhrases + 1);
      } else {
        const filteredPhrases = filterTopicalPhrases(ud);
        setPhrases(EMPTY_ARRAY.concat(filteredPhrases));
        if (isTopical && topicalPhraseType === "machine")
          setSelectedPhrases(
            filteredPhrases.length
              ? EMPTY_ARRAY.concat(filteredPhrases[0].phraseId)
              : EMPTY_ARRAY
          );
      }

      if (isNgram) {
        const ugp = getGramPhrases(gramWeight[0]).map((gp) => {
          if (gp.phraseId === phraseContext.phraseId) {
            gp.context.map((gpc) => {
              if (gpc.contextId === phraseContext.contextId) {
                gpc.context = phraseContext.context;
                gpc.candidates = gpc.candidates
                  ? gpc.candidates.concat(newPhrase.phrase)
                  : [newPhrase.phrase];
              }
              return gpc;
            });
          }
          return gp;
        });
        setGramPhrases(ugp, gramWeight[0]);
        setShouldBlockNavigation(true);
        return;
      }

      if (isunClassified) {
        const ugp = cloneDeep(unClassifiedPhrases).map((gp) => {
          if (gp.phraseId === phraseContext.phraseId) {
            gp.context.map((gpc) => {
              if (gpc.contextId === phraseContext.contextId) {
                gpc.context = prevCandidatesHighlights;
                gpc.candidates = gpc.candidates
                  ? gpc.candidates.concat(newPhrase.phrase)
                  : [newPhrase.phrase];
              }
              return gpc;
            });
          }
          return gp;
        });
        setUnClassifiedPhrases(EMPTY_ARRAY.concat(ugp));
        setShouldBlockNavigation(true);
        return;
      }

      //update data topics
      const udt = cloneDeep(dataTopics).map((dt) => {
        if (dt.id === selectedTopics[0]) {
          dt.phrases.map((dtp) => {
            if (dtp.phraseId === phraseContext.phraseId) {
              dtp.context.map((dtpc) => {
                if (dtpc.contextId === phraseContext.contextId) {
                  dtpc.context = phraseContext.context;
                  dtpc.candidates = dtpc.candidates
                    ? dtpc.candidates.concat(newPhrase.phrase)
                    : [newPhrase.phrase];
                }
                return dtpc;
              });
            }
            return dtp;
          });
        }
        return dt;
      });
      setDataTopics(EMPTY_ARRAY.concat(udt));

      //update topics
      const ut = cloneDeep(topics).map((t) => {
        if (t.id === selectedTopics[0]) {
          t.phrases.map((tp) => {
            if (tp.phraseId === phraseContext.phraseId) {
              tp.context.map((tpc) => {
                if (tpc.contextId === phraseContext.contextId) {
                  tpc.context = phraseContext.context;
                  tpc.candidates = tpc.candidates
                    ? tpc.candidates.concat(newPhrase.phrase)
                    : [newPhrase.phrase];
                }
                return tpc;
              });
            }
            return tp;
          });
        }
        return t;
      });
      setTopics(EMPTY_ARRAY.concat(ut));
      setShouldBlockNavigation(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleProcessedEntity = useCallback(
    (type, id, action) => {
      const payLoadData = new FormData();

      payLoadData.append("folderId", selectedDiscoverdTopic.value);
      payLoadData.append("type", type);
      payLoadData.append("id", id);
      payLoadData.append("status", "processed");
      payLoadData.append("action", action);

      client
        .post(PROCESSED_ENTITY, payLoadData)
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [selectedDiscoverdTopic]
  );

  //Handle Phrases Moved on Topics
  const handleMovedPhrases = async (topic, paletteUsed) => {
    try {
      setUndoableEvent("CLEAR");
      const topicId = topic.id;
      let selectedPaletteTopic;
      if (paletteUsed) {
        selectedPaletteTopic = dataTopics.find((t) => t.id === topicId);
        if (!selectedPaletteTopic) return;
      }

      if (topicId === selectedTopics[0]) {
        toast.warn("Phrase already exists.");
        return;
      }

      const draggedPhraseTopic = cloneDeep(topics).filter(
        (t) => t.id === selectedTopics[0]
      );
      const selPhrasesId = selectedPhrases;
      const currentPhrases = isCandidate
        ? candidatePhrases
        : isNgram
        ? getGramPhrases(gramWeight[0])
        : isunClassified
        ? unClassifiedPhrases
        : topics.find((t) => t.id === selectedTopics[0]).phrases;
      let movedPhrases = cloneDeep(currentPhrases).filter((p) =>
        selPhrasesId.includes(p.phraseId)
      );

      const dupPhrases = (
        paletteUsed ? selectedPaletteTopic : topic
      ).phrases.filter((p) =>
        movedPhrases.find((mp) => mp.phrase === p.phrase)
      );
      if (dupPhrases.length) {
        if (dupPhrases.length === 1) toast.warn("Phrase already exists.");
        else if (dupPhrases.length > 1)
          toast.warn("Some of the phrases already exist.");

        if (dupPhrases.length === movedPhrases.length) return;
        else
          movedPhrases = movedPhrases.filter(
            (mp) => !dupPhrases.find((dp) => dp.phrase === mp.phrase)
          );
      }

      const movedPhraseIds = movedPhrases.map((p) => p.phraseId);

      if (!curatedTopics.includes(topicId)) {
        const payLoadData = new FormData();
        payLoadData.append("folderId", selectedDiscoverdTopic.value);
        payLoadData.append(
          "type",
          isCandidate ? "candidate" : isNgram ? "ngram" : "topics"
        );
        payLoadData.append("id", topic.id);
        payLoadData.append("action", "move");
        payLoadData.append("phraseId", movedPhraseIds);

        try {
          await client.post(PROCESSED_ENTITY, payLoadData);
        } catch (err) {
          console.log(err);
          toast.error("Could not process the request.");
          return;
        }
      }

      const ump = movedPhrases.map((p) => {
        p.isSeed = true;
        p.context = p.context.map((ctx) => {
          let ctxobj = {};
          ctxobj["topics"] =
            !isCandidate && !isNgram
              ? ctx.topics.map((ct) => {
                  if (ct.topic === draggedPhraseTopic[0].name) {
                    ct.frequency = 1;
                    ct.topic = topic.name;
                  }
                  return ct;
                })
              : [{ frequency: 1, topic: topic.name, value: p.phrase }];
          ctxobj["contextId"] = ctx.contextId;
          ctxobj["candidates"] = ctx.candidates ? ctx.candidates : [];

          const mainTopicArray =
            isCandidate || isNgram
              ? [{ topic: topic.name, value: p.phrase }]
              : cloneDeep(ctx.topics).filter(
                  (ct) => ct.value.toLowerCase() === p.phrase.toLowerCase()
                );
          const sideTopicArray =
            isCandidate || isNgram
              ? []
              : cloneDeep(ctx.topics).filter(
                  (ct) => ct.value.toLowerCase() !== p.phrase.toLowerCase()
                );

          const mtda =
            isCandidate || isNgram
              ? mainTopicWithDataAttr(
                  ctx.context.replace(/\s+/g, " ").replace(/(<([^>]+)>)/gi, ""),
                  mainTopicArray
                )
              : mainTopicWithDataAttr(
                  ctx.context
                    .replace(/\s+/g, " ")
                    .replace(/<\/?mark[^>]*>/gi, ""),
                  mainTopicArray
                );

          if (!isCandidate && !isNgram && !isunClassified) {
            const selectedTopic = cloneDeep(topics).filter(
              (t) => t.id === selectedTopics[0]
            );
            ctxobj["context"] = sideTopicWithDataAttr(
              mtda,
              sideTopicArray,
              isTopicPaletteHighlightActive,
              selectedTopic[0].name
            );
          } else
            ctxobj["context"] = sideTopicWithDataAttr(
              mtda,
              sideTopicArray,
              isTopicPaletteHighlightActive
            );

          return ctxobj;
        });
        return p;
      });

      const remainingPhraseesInSelectedTopic = currentPhrases.filter(
        (p) => !movedPhraseIds.includes(p.phraseId)
      );
      const selectedTopicPhraseContextDocuments = uniqueArray(
        cloneDeep(remainingPhraseesInSelectedTopic)
      );
      const movedPhrasesTopic = dataTopics.find((t) => t.id === topicId);
      const topicNewPhrasesContextDocuments = uniqueArray(
        cloneDeep(movedPhrases.concat(movedPhrasesTopic.phrases))
      );
      const updatedMovedPhraseTopicFreq =
        topicNewPhrasesContextDocuments.length +
        (movedPhrasesTopic.isLoaded ? 0 : movedPhrasesTopic.frequency);

      //update topics
      const ut = cloneDeep(topics).map((t) => {
        if (t.id === selectedTopics[0]) {
          t.phrases = t.phrases.filter(
            (p) => !selPhrasesId.includes(p.phraseId)
          );
          t.frequency = selectedTopicPhraseContextDocuments.length;
        }
        if (t.id === topicId) {
          t.frequency = updatedMovedPhraseTopicFreq;
          t.phrases = t.phrases.concat(ump).map((p) => {
            p.context = p.context.map((pc) => {
              pc.topics = pc.topics.map((pct) => {
                if (pct.topic === topic.name)
                  pct.frequency = updatedMovedPhraseTopicFreq;
                return pct;
              });
              return pc;
            });
            return p;
          });
        }
        return t;
      });
      setTopics(EMPTY_ARRAY.concat(ut));

      //update data topics
      const dut = cloneDeep(dataTopics).map((t) => {
        if (t.id === selectedTopics[0]) {
          t.phrases = t.phrases.filter(
            (p) => !selPhrasesId.includes(p.phraseId)
          );
          t.frequency = selectedTopicPhraseContextDocuments.length;
        }
        if (t.id === topicId) {
          t.frequency = updatedMovedPhraseTopicFreq;
          t.phrases = t.phrases.concat(ump).map((p) => {
            p.context = p.context.map((pc) => {
              pc.topics = pc.topics.map((pct) => {
                if (pct.topic === topic.name)
                  pct.frequency = updatedMovedPhraseTopicFreq;
                return pct;
              });
              return pc;
            });
            return p;
          });
        }
        return t;
      });
      setDataTopics(EMPTY_ARRAY.concat(dut));

      if (isCandidate) {
        const cp = cloneDeep(candidatePhrases).filter(
          (p) => !selPhrasesId.includes(p.phraseId)
        );
        setCandidatePhrases(EMPTY_ARRAY.concat(cp));
      }

      if (isNgram) {
        const gp = getGramPhrases(gramWeight[0]).filter(
          (p) => !selPhrasesId.includes(p.phraseId)
        );
        setGramPhrases(gp, gramWeight[0]);
      }

      if (phrases.length === movedPhraseIds.length) {
        if (totalPhrases > 100 && (isCandidate || isNgram)) {
          setLoading(true);
          setTimeout(() => {
            loadMorePhrases(movedPhraseIds);
          }, 600);
        } else {
          //update phrases
          const up = phrases.filter((p) => !selPhrasesId.includes(p.phraseId));
          setPhrases(EMPTY_ARRAY.concat(up));
          if (up.length > 0)
            setSelectedPhrases(EMPTY_ARRAY.concat(up[0].phraseId));
        }
      } else {
        //update phrases
        const up = phrases.filter((p) => !selPhrasesId.includes(p.phraseId));
        setPhrases(EMPTY_ARRAY.concat(up));
        if (up.length > 0)
          setSelectedPhrases(EMPTY_ARRAY.concat(up[0].phraseId));
      }

      setTotalPhrases((state) => state - movedPhrases.length);
      setShouldBlockNavigation(true);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  //Add Dropped Phrases on Selected Topic
  const updateSelectedTopicPhrases = (topic, newPhrase) => {
    try {
      setUndoableEvent("CLEAR");
      const topicNewPhrases = cloneDeep(topic.phrases).concat(newPhrase);
      const uniqueAry = uniqueArray(cloneDeep(topicNewPhrases));

      const id = topic.id;
      const topicName = topic.name;
      const selectedTopicId = selectedTopics[0];

      const sideTopicObj = {
        topic: topicName,
        value: newPhrase.phrase,
        frequency: uniqueAry.length,
      };

      const updatedTopicFrequency = topic.isLoaded
        ? uniqueAry.length
        : topic.frequency + uniqueAry.length;
      //update data topics
      const pt = cloneDeep(dataTopics).map((dt) => {
        //update ondrop topic context topics
        if (parseInt(dt.id) === parseInt(id)) {
          dt.frequency = updatedTopicFrequency;
          dt.phrases = dt.phrases.concat(newPhrase).map((p) => {
            p.context = p.context.map((pc) => {
              pc.topics = pc.topics.map((pct) => {
                if (pct.topic === dt.name)
                  pct.frequency = updatedTopicFrequency;
                return pct;
              });
              return pc;
            });
            return p;
          });
        }
        //update selected topic context topics
        if (parseInt(dt.id) === parseInt(selectedTopicId)) {
          dt.phrases = dt.phrases.map((dlp) => {
            if (dlp.phraseId === selectedContext.phraseId) {
              dlp.context = dlp.context.map((cdlp) => {
                if (cdlp.contextId === selectedContext.contextId) {
                  cdlp.topics = cdlp.topics.concat(sideTopicObj);

                  const selectedTopic = cloneDeep(topics).filter(
                    (t) => t.id === selectedTopics[0]
                  );
                  const mainTopic = cdlp.topics.filter(
                    (ct) => ct.value.toLowerCase() === dlp.phrase.toLowerCase()
                  );
                  const sideTopicArry = cdlp.topics.filter(
                    (ct) => ct.value.toLowerCase() !== dlp.phrase.toLowerCase()
                  );
                  const plainCtx = cdlp.context
                    .replace(/\s+/g, " ")
                    .replace(/<\/?span[^>]*>/gi, "")
                    .replace(/<\/?mark[^>]*>/gi, "");

                  const mainHightlightCtx = mainTopicWithDataAttr(
                    plainCtx,
                    mainTopic
                  );

                  cdlp.context = sideTopicWithDataAttr(
                    mainHightlightCtx,
                    sideTopicArry,
                    isTopicPaletteHighlightActive,
                    selectedTopic[0].name
                  );
                }
                return cdlp;
              });
            }
            return dlp;
          });
        }
        return dt;
      });
      setDataTopics(EMPTY_ARRAY.concat(pt));

      //update topics
      const ut = cloneDeep(topics).map((el) => {
        //update ondrop topic context topics
        if (parseInt(el.id) === parseInt(id)) {
          el.frequency = updatedTopicFrequency;
          el.phrases = el.phrases.concat(newPhrase).map((p) => {
            p.context = p.context.map((pc) => {
              pc.topics = pc.topics.map((pct) => {
                if (pct.topic === el.name)
                  pct.frequency = updatedTopicFrequency;
                return pct;
              });
              return pc;
            });
            return p;
          });
        }
        //update selected topic context topics
        if (parseInt(el.id) === parseInt(selectedTopicId)) {
          el.phrases = el.phrases.map((elp) => {
            if (elp.phraseId === selectedContext.phraseId) {
              elp.context = elp.context.map((celp) => {
                if (celp.contextId === selectedContext.contextId) {
                  celp.topics = celp.topics.concat(sideTopicObj);

                  const selectedTopic = cloneDeep(topics).filter(
                    (t) => t.id === selectedTopics[0]
                  );
                  const mainTopic = celp.topics.filter(
                    (ct) => ct.value.toLowerCase() === elp.phrase.toLowerCase()
                  );
                  const sideTopicArry = celp.topics.filter(
                    (ct) => ct.value.toLowerCase() !== elp.phrase.toLowerCase()
                  );
                  const plainCtx = celp.context
                    .replace(/\s+/g, " ")
                    .replace(/<\/?span[^>]*>/gi, "")
                    .replace(/<\/?mark[^>]*>/gi, "");

                  const mainHightlightCtx = mainTopicWithDataAttr(
                    plainCtx,
                    mainTopic
                  );

                  celp.context = sideTopicWithDataAttr(
                    mainHightlightCtx,
                    sideTopicArry,
                    isTopicPaletteHighlightActive,
                    selectedTopic[0].name
                  );
                }
                return celp;
              });
            }
            return elp;
          });
        }
        return el;
      });
      setTopics(EMPTY_ARRAY.concat(ut));

      if (isCandidate || isNgram) return;

      //Update Phrases
      const selTopic = cloneDeep(ut).filter(
        (utt) => utt.id === selectedTopicId
      );
      if (selTopic.length > 0) {
        setSelectedTopics(EMPTY_ARRAY.concat(selTopic[0].id));
        const filteredPhrases = filterTopicalPhrases(selTopic[0].phrases);
        setPhrases(filteredPhrases);
        setTotalPhrases(selTopic[0].phrases.length);
        const selectedPhraseIds =
          topicalPhraseType !== "machine"
            ? selectedPhrases
            : selectedPhrases.length > 1
            ? selectedPhrases.filter((id) => id !== selectedContext.phraseId)
            : filteredPhrases[0].phraseId;
        setSelectedPhrases(EMPTY_ARRAY.concat(selectedPhraseIds));
      }

      setShouldBlockNavigation(true);
    } catch (err) {
      console.log(err);
    }
  };

  const markAsClassified = (ctx) => {
    try {
      setUndoableEvent("CLEAR");
      if (!shouldBlockNavigation) setShouldBlockNavigation(true);
      const id = ctx.phraseId,
        selectedContextId = ctx.contextId;

      const payLoadData = new FormData();

      payLoadData.append("folderId", selectedDiscoverdTopic.value);
      payLoadData.append("type", "unclassified");
      payLoadData.append("id", selectedContextId);
      payLoadData.append("status", "processed");
      payLoadData.append("action", "delete");

      client
        .post(PROCESSED_ENTITY, payLoadData)
        .then((res) => {
          if (res.data.status === SUCCESS) {
            //setTotalPhrases(totalPhrases - 1)

            if (isunClassified) {
              const uucp = cloneDeep(unClassifiedPhrases).map((uc) => {
                if (uc.phraseId === id) {
                  uc.context = uc.context.map((cp) => {
                    if (cp.contextId === selectedContextId)
                      cp["markClassified"] = true;
                    return cp;
                  });
                }
                return uc;
              });
              setUnClassifiedPhrases(EMPTY_ARRAY.concat(uucp));
              setSummaryData((state) => ({
                ...state,
                noOfUnclassifiedDocs: state.noOfUnclassifiedDocs - 1,
                noOfClassifiedDocs: state.noOfClassifiedDocs + 1,
              }));

              const up = cloneDeep(phrases).map((p) => {
                if (p.phraseId === id) {
                  p.context = p.context.map((cp) => {
                    if (cp.contextId === selectedContextId)
                      cp["markClassified"] = true;
                    return cp;
                  });
                }
                return p;
              });
              console.log(up);
              setPhrases(EMPTY_ARRAY.concat(up));
              setShouldBlockNavigation(true);
            }
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((error) => {
          toast.error(ERROR_MESSAGE);
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  //Add Phrase to Candidates
  const addToCandidates = (selPhrase, idToRemove, event) => {
    try {
      setUndoableEvent("CLEAR");
      if (event === "delete-selected" || event === "move-selected") {
        if (isCandidate) {
          if (event === "move-selected") return;

          const fcp = cloneDeep(candidatePhrases).filter(
            (p) => !selectedPhrases.includes(p.phraseId)
          );
          setCandidatePhrases(EMPTY_ARRAY.concat(fcp));
          setCandidateTotalCount((state) => state - selectedPhrases.length);
          setTotalPhrases((state) => state - selectedPhrases.length);

          const fcph = cloneDeep(phrases).filter(
            (fp) => !selectedPhrases.includes(fp.phraseId)
          );
          setPhrases(EMPTY_ARRAY.concat(fcph));
          if (fcph.length > 0)
            setSelectedPhrases(EMPTY_ARRAY.concat(fcph[0].phraseId));
          return;
        }

        // Move Candidates Phrases - multiple selected phrases
        const phrasesMoved = cloneDeep(phrases).filter((p) =>
          selectedPhrases.includes(p.phraseId)
        );
        const updatedPhrases = phrasesMoved.map((p) => ({
          char_sim: p.char_sim ? p.char_sim : 0,
          context: p.context.map((pc) => {
            pc.contextId = uuidv4();
            return pc;
          }),
          frequency: p.frequency,
          phrase: p.phrase,
          phraseId: Math.floor(100000 + Math.random() * 90000),
          pos_tags: p.pos_tags,
          sem_sim: p.sem_sim ? p.sem_sim : 0,
          sentiment: p.sentiment,
        }));

        const curatedCandidates = localStorage.getItem("newCandidates");
        if (curatedCandidates) {
          const cc = JSON.parse(curatedCandidates).concat(updatedPhrases);
          localStorage.setItem("newCandidates", JSON.stringify(cc));
        } else {
          localStorage.setItem("newCandidates", JSON.stringify(updatedPhrases));
        }

        setCandidatePhrases(candidatePhrases.concat(updatedPhrases));
        setCandidateTotalCount((state) => state + phrasesMoved.length);

        //update phrases
        const fph = cloneDeep(phrases).filter(
          (p) => !selectedPhrases.includes(p.phraseId)
        );
        setPhrases(EMPTY_ARRAY.concat(fph));
        if (fph.length > 0)
          setSelectedPhrases(EMPTY_ARRAY.concat(fph[0].phraseId));

        if (isCandidate)
          setTotalPhrases((state) => state + phrasesMoved.length);
        else setTotalPhrases((state) => state - phrasesMoved.length);

        //Update ngrams phrases
        if (isNgram) {
          const gp = getGramPhrases(gramWeight[0]).filter(
            (gp) => !selectedPhrases.includes(gp.phraseId)
          );
          setGramPhrases(gp, gramWeight[0]);
          return;
        }

        // Unique Contexts to update in topics and datatopics frequency
        const uniqueContexts = uniqueArray(fph);

        //update data topics
        const dtp = cloneDeep(dataTopics).map((dt) => {
          if (dt.id === selectedTopics[0]) {
            dt.frequency = uniqueContexts.length;
            dt.phrases = dt.phrases.filter(
              (p) => !selectedPhrases.includes(p.phraseId)
            );
          }
          return dt;
        });
        setDataTopics(EMPTY_ARRAY.concat(dtp));

        //update topics
        const tp = cloneDeep(topics).map((t) => {
          if (t.id === selectedTopics[0]) {
            t.frequency = uniqueContexts.length;
            t.phrases = t.phrases.filter(
              (p) => !selectedPhrases.includes(p.phraseId)
            );
          }
          return t;
        });
        setTopics(EMPTY_ARRAY.concat(tp));

        setShouldBlockNavigation(true);
        return;
      }

      if (isCandidate) {
        if (event === "move") return;

        const fcp = cloneDeep(candidatePhrases).filter(
          (p) => p.phraseId !== idToRemove
        );
        setCandidatePhrases(EMPTY_ARRAY.concat(fcp));
        setCandidateTotalCount((state) => state - 1);
        setTotalPhrases((state) => state - 1);

        const fcph = cloneDeep(phrases).filter(
          (fp) => fp.phraseId !== idToRemove
        );
        setPhrases(EMPTY_ARRAY.concat(fcph));
        if (fcph.length > 0 && selectedPhrases.length === 1)
          setSelectedPhrases(EMPTY_ARRAY.concat(fcph[0].phraseId));
        return;
      }

      //Change ids of phrase and context id to avoid duplicate keys
      const newPhrase = {
        char_sim: selPhrase.char_sim ? selPhrase.char_sim : 0,
        context: selPhrase.context.map((pc) => {
          pc.contextId = uuidv4(); // Math.floor(100000 + Math.random() * 9000);
          return pc;
        }),
        frequency: selPhrase.frequency,
        phrase: selPhrase.phrase,
        phraseId: Math.floor(10000 + Math.random() * 9000),
        pos_tags: selPhrase.pos_tags,
        sem_sim: selPhrase.sem_sim ? selPhrase.sem_sim : 0,
        sentiment: selPhrase.sentiment,
      };

      const curatedCandidates = localStorage.getItem("newCandidates");
      if (curatedCandidates) {
        const cc = JSON.parse(curatedCandidates).concat(newPhrase);
        localStorage.setItem("newCandidates", JSON.stringify(cc));
      } else {
        localStorage.setItem("newCandidates", JSON.stringify([newPhrase]));
      }

      //update Candidates Phrases
      setCandidatePhrases(candidatePhrases.concat(newPhrase));
      setCandidateTotalCount((state) => state + 1);

      //update phrases
      const fph = cloneDeep(phrases).filter((p) => p.phraseId !== idToRemove);
      setPhrases(EMPTY_ARRAY.concat(fph));
      if (fph.length > 0 && selectedPhrases.length === 1)
        setSelectedPhrases(EMPTY_ARRAY.concat(fph[0].phraseId));

      if (isCandidate) setTotalPhrases((state) => state + 1);
      else setTotalPhrases((state) => state - 1);

      //Update ngrams phrases
      if (isNgram) {
        const gp = getGramPhrases(gramWeight[0]).filter(
          (gp) => gp.phraseId !== idToRemove
        );
        setGramPhrases(gp, gramWeight[0]);
        return;
      }

      // Unique Contexts to update in topics and datatopics frequency
      const uniqueContexts = uniqueArray(fph);

      //update data topics
      const dtp = cloneDeep(dataTopics).map((dt) => {
        if (dt.id === selectedTopics[0]) {
          dt.frequency = uniqueContexts.length;
          dt.phrases = dt.phrases.filter((p) => p.phraseId !== idToRemove);
        }
        return dt;
      });
      setDataTopics(EMPTY_ARRAY.concat(dtp));

      //update topics
      const tp = cloneDeep(topics).map((t) => {
        if (t.id === selectedTopics[0]) {
          t.frequency = uniqueContexts.length;
          t.phrases = t.phrases.filter((p) => p.phraseId !== idToRemove);
        }
        return t;
      });
      setTopics(EMPTY_ARRAY.concat(tp));

      setShouldBlockNavigation(true);
    } catch (err) {
      console.log(err);
    }
  };

  //Add selected text to Topic Phrases
  const addTextToPhrases = (str, phrase, newContextTopic, paletteUsed) => {
    try {
      const selTopic =
        isSelectedTopic.active && isSelectedTopic.id
          ? cloneDeep(topics).filter((t) => t.id === isSelectedTopic.id)
          : cloneDeep(topics).filter((t) => t.id === selectedTopics[0]);

      if (selTopic.length === 0) return;

      //To check duplicate phrases
      const fp = selTopic[0].phrases.filter((p) => p.phrase === str);
      if (fp.length > 0) {
        if (fp[0].phrase === str) {
          toast.warn("Phrase already exists");
          return;
        }
      }
      const topicName = selTopic[0].name;

      phrase["phrase"] = str;
      phrase["context"] = phrase.context.map((ctx) => {
        let el = {};
        el.contextId = uuidv4();
        el.context = ctx.context;
        el.topics =
          ctx.topics.length === 0
            ? [{ value: str, topic: topicName, frequency: 1 }]
            : ctx.topics.map((t) => {
                if (t.topic === topicName) {
                  t.frequency = t.frequency + 1;
                }
                return t;
              });
        return el;
      });

      //update Topics
      const updateTopics = (topicIds) => {
        const ut = cloneDeep(topics).map((t) => {
          if (topicIds.includes(t.id)) {
            if (!isSelectedTopic.active) {
              t.frequency = t.frequency + 1;
            } else if (t.id === selTopic[0].id) {
              t.frequency = t.frequency + 1;
            }

            t.phrases = t.phrases
              .map((p) => {
                p.context.map((ctx) => {
                  ctx.topics = ctx.topics.map((ct) => {
                    if (ct.topic === t.name && t.id === selTopic[0].id) {
                      ct.frequency = ct.frequency + 1;
                    }
                    return ct;
                  });
                  if (ctx.contextId === selectedContext.contextId) {
                    ctx.topics = ctx.topics.concat(newContextTopic);
                    ctx.context = mainTopicWithDataAttr(
                      ctx.context
                        .replace(/\s+/g, " ")
                        .replace(/<\/?mark[^>]*>/gi, ""),
                      newContextTopic
                    );
                  }
                  return ctx;
                });
                return p;
              })
              .concat(phrase);
          }
          return t;
        });
        return ut;
      };

      //Update data topics
      const updateDataTopics = (topicIds) => {
        const udt = cloneDeep(dataTopics).map((dt) => {
          if (topicIds.includes(dt.id)) {
            if (!isSelectedTopic.active) {
              dt.frequency = dt.frequency + 1;
            } else if (dt.id === selTopic[0].id) {
              dt.frequency = dt.frequency + 1;
            }
            dt.phrases = dt.phrases
              .map((dp) => {
                dp.context.map((dctx) => {
                  dctx.topics = dctx.topics.map((dct) => {
                    if (dct.topic === dt.name && dt.id === selTopic[0].id) {
                      dct.frequency = dct.frequency + 1;
                    }
                    return dct;
                  });
                  if (dctx.contextId === selectedContext.contextId) {
                    dctx.topics = dctx.topics.concat(newContextTopic);
                    dctx.context = mainTopicWithDataAttr(
                      dctx.context
                        .replace(/\s+/g, " ")
                        .replace(/<\/?mark[^>]*>/gi, ""),
                      newContextTopic
                    );
                  }
                  return dctx;
                });
                return dp;
              })
              .concat(phrase);
          }
          return dt;
        });
        return udt;
      };

      //Filter Topics for Selected Topic Phrases
      let updateTopicIds =
        isCandidate || isNgram || isunClassified ? [] : [selectedTopics[0]];
      if (isSelectedTopic.active) updateTopicIds.push(selTopic[0].id);
      let ut = updateTopics(updateTopicIds);
      let udt = updateDataTopics(updateTopicIds);
      let utp = cloneDeep(ut).filter((top) => top.id === selectedTopics[0]);
      setTopics(ut);
      setDataTopics(udt);

      if (utp.length > 0) {
        //filter by searchWord
        const fp = doClientOperations(
          utp[0].phrases,
          phrasePattern,
          searchWord,
          sortBy,
          wordsBefore,
          wordsAfter,
          sortType
        );
        const markedContent = doClientMark(fp, searchWord);

        setTotalPhrases(markedContent.length);
        const filteredPhrases = filterTopicalPhrases(markedContent);
        setTimeout(() => {
          setPhrases(EMPTY_ARRAY.concat(filteredPhrases));
        }, 200);

        const selectedPhraseId = filteredPhrases.length
          ? topicalPhraseType !== "machine"
            ? filteredPhrases.find(
                (e) => e.phraseId === selectedContext.phraseId
              ).phraseId
            : filteredPhrases[0].phraseId
          : EMPTY_ARRAY;
        setSelectedPhrases(EMPTY_ARRAY.concat(selectedPhraseId));

        //setPhrases(utp[0].phrases);
      } else if (isCandidate || isNgram || isunClassified) {
        const updatedPhrases = cloneDeep(
          isCandidate
            ? candidatePhrases
            : isNgram
            ? getGramPhrases(gramWeight[0])
            : unClassifiedPhrases
        ).map((cp) => {
          if (cp.phraseId === selectedContext.phraseId) {
            cp.context = cp.context.map((ctx) => {
              if (ctx.contextId === selectedContext.contextId) {
                const selectedTopic = cloneDeep(topics).filter(
                  (t) => t.id === selectedTopics[0]
                );
                ctx.context = sideTopicWithDataAttr(
                  selectedContext.context,
                  newContextTopic,
                  isTopicPaletteHighlightActive,
                  selectedTopic[0].name
                );
              }
              return ctx;
            });
          }
          return cp;
        });
        setPhrases(
          isNgram
            ? updatedPhrases.filter((g) => gramWeight.includes(g.nGrams))
            : updatedPhrases
        );
        isCandidate
          ? setCandidatePhrases(updatedPhrases)
          : isNgram
          ? setGramPhrases(updatedPhrases, gramWeight[0])
          : setUnClassifiedPhrases(updatedPhrases);
      } else setPhrases(EMPTY_ARRAY);

      setShouldBlockNavigation(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddText = (options, text, paletteUsed) => {
    try {
      setUndoableEvent("CLEAR");
      if (paletteUsed) {
        if (isSelectedTopic.id) {
          if (isSelectedTopic.active && options === "phrases") {
            topicPaletteHighlight(text);
            return;
          }
        } else return;
      }

      let constructedPhrase = {};

      //popup New Topic event on candidates/ngrams/unclassified phrases
      if ((isCandidate || isNgram || isunClassified) && options === "topic") {
        selectedContext.candidates = selectedContext.candidates.concat(text);

        constructedPhrase.char_sim = 0;
        constructedPhrase.context = [selectedContext];
        constructedPhrase.frequency = 1;
        constructedPhrase.phrase = text;
        constructedPhrase.phraseId = Math.floor(100000 + Math.random() * 90000);
        constructedPhrase.pos_tags = [];
        constructedPhrase.sem_sim = 0;
        constructedPhrase.text = text;
        constructedPhrase.isSeed = true;
        constructedPhrase.isCurated = true;

        setNewTopicTitle(text);
        setByDrop(false);
        setAppendPhrase(constructedPhrase);
        setOpen(true);
        return;
      }

      //construct new phrase for popup events on topic phrases
      let contextObj = {};
      const sp = selectedPhrases
        .filter((id) => id === parseInt(selectedContext.phraseId))
        .toString();
      const phraseArray = cloneDeep(phrases).filter(
        (p) => p.phraseId === parseInt(sp)
      );
      const phrase = phraseArray[0];

      const topicSelected = cloneDeep(topics).find(
        (t) => t.id === selectedTopics[0]
      );

      const newContextTopic = cloneDeep(
        selectedContext.topics ? selectedContext.topics : []
      )
        .filter((ct) => ct.value === selectedContext.phrase)
        .map((ft) => {
          return { value: text, topic: ft.topic, frequency: ft.frequency };
        });

      if (selectedContext) {
        //selected phrase context
        if (options === "phrases") {
          const mainTopicArray = selectedContext.topics.filter(
            (ct) =>
              ct.value.toLowerCase() === selectedContext.phrase.toLowerCase()
          );

          contextObj["context"] = mainTopicWithDataAttr(
            selectedContext.context
              .replace(/\s+/g, " ")
              .replace(/<\/?mark[^>]*>/gi, ""),
            mainTopicArray
          );
          contextObj["contextId"] = selectedContext.contextId;
          contextObj["topics"] =
            isCandidate || isNgram || isunClassified
              ? newContextTopic
              : EMPTY_ARRAY.concat(selectedContext.topics).concat(
                  newContextTopic
                );
        } else {
          //popup New Topic event
          contextObj["context"] = selectedContext.context;
          contextObj["contextId"] = selectedContext.contextId;
          contextObj["topics"] = selectedContext.topics;
        }
      }

      if (phrase) {
        constructedPhrase.char_sim = phrase.char_sim;
        constructedPhrase.context = [contextObj];
        constructedPhrase.frequency = 1;
        constructedPhrase.phrase = phrase.phrase;
        constructedPhrase.phraseId = Math.floor(100000 + Math.random() * 90000);
        constructedPhrase.pos_tags = phrase.pos_tags;
        constructedPhrase.sem_sim = phrase.sem_sim;
        constructedPhrase.sentiment = phrase.sentiment;
        constructedPhrase.isCurated = true;
        constructedPhrase.isSeed = true;
      }

      if (text && !isEmptyObject(constructedPhrase)) {
        if (text.trim().length === 0) return;
        switch (options) {
          case "phrases":
            addTextToPhrases(text, constructedPhrase, newContextTopic);
            break;
          case "topic":
            constructedPhrase.phrase = text;
            constructedPhrase.text = text;
            setNewTopicTitle(text);
            setByDrop(false);
            setAppendPhrase(constructedPhrase);
            setOpen(true);
            break;
          default:
            break;
        }
      }
      setShouldBlockNavigation(true);
    } catch (err) {
      console.log(err);
    }
  };

  const topicPaletteHighlight = (text) => {
    try {
      if (!selectedContext || !text || !text.trim().length) return;

      const selectedPaletteTopic = cloneDeep(dataTopics).find(
        (t) => t.id === isSelectedTopic.id
      );
      const phrase = cloneDeep(phrases).find(
        (p) => p.phraseId === selectedContext.phraseId
      );

      if (!selectedPaletteTopic || !phrase) return;

      // Check duplicate phrases
      const fp = selectedPaletteTopic.phrases.find((p) => p.phrase === text);
      if (fp && fp.phrase === text) {
        toast.warn("Phrase already exists");
        return;
      }

      let constructedPhrase = {};
      let contextObj = {};
      const topicName = selectedPaletteTopic.name;
      const newContextTopic = [
        {
          value: text,
          topic: selectedPaletteTopic.name,
          frequency: selectedPaletteTopic.frequency,
          color: isSelectedTopic.color,
        },
      ];
      const mainTopicArray = selectedContext.topics
        ? selectedContext.topics
            .filter(
              (ct) =>
                ct.value.toLowerCase() === selectedContext.phrase.toLowerCase()
            )
            .concat(newContextTopic)
        : newContextTopic;
      const sideTopicsArray = selectedContext.topics
        ? selectedContext.topics.filter(
            (ct) =>
              ct.value.toLowerCase() !== selectedContext.phrase.toLowerCase()
          )
        : [];

      const selPhrase = isTopical
        ? topics
            .find((t) => t.id === selectedTopics[0])
            .phrases.find((p) => p.phraseId === selectedContext.phraseId)
        : (isCandidate
            ? candidatePhrases
            : isNgram
            ? getGramPhrases(gramWeight[0])
            : unClassifiedPhrases
          ).find((p) => p.phraseId === selectedContext.phraseId);
      let contextToBeUpdated = selPhrase.context.find(
        (c) => c.contextId === selectedContext.contextId
      ).context;
      let filteredCandidates;

      const plainContext = contextToBeUpdated
        .replace(/\s+/g, " ")
        .replace(/<\/?span[^>]*>/gi, "")
        .replace(/<\/?mark[^>]*>/gi, "");

      if (isNgram || isCandidate) {
        const firstIndex = plainContext.indexOf(text);
        const lastIndex = firstIndex + text.length - 1;
        const parser = new DOMParser();
        const contextHtml = parser
          .parseFromString(selectedContext.context, "text/html")
          .querySelector("body");
        for (const node of contextHtml.querySelectorAll("mark")) {
          const innerText = node.textContent;
          if (
            node.classList.contains("ngram-phrase") ||
            node.classList.contains("candidate-phrase")
          ) {
            const textFIndex = plainContext.indexOf(innerText);
            const textLIndex = textFIndex + innerText.length - 1;
            if (
              (textFIndex <= lastIndex && textFIndex >= firstIndex) ||
              (firstIndex >= textFIndex && firstIndex <= textLIndex)
            ) {
              node.replaceWith(...node.childNodes);
              if (
                selectedContext.candidates &&
                selectedContext.candidates.length
              ) {
                filteredCandidates = (
                  filteredCandidates
                    ? filteredCandidates
                    : selectedContext.candidates
                ).filter((c) => c.toLowerCase() !== innerText.toLowerCase());
              }
            }
          }
        }
        contextToBeUpdated = contextHtml.innerHTML;
      }

      const candidateHighlight = selectedContext.candidates
        ? highLightCandidate(plainContext, selectedContext.candidates)
        : plainContext;
      const mainTopicHighlight = mainTopicWithDataAttr(
        candidateHighlight,
        mainTopicArray
      );
      contextObj["context"] = sideTopicWithDataAttr(
        mainTopicHighlight,
        sideTopicsArray,
        isTopicPaletteHighlightActive,
        selectedPaletteTopic.name
      );
      contextObj["contextId"] = uuidv4();
      contextObj["topics"] = (
        selectedContext.topics
          ? newContextTopic.concat(selectedContext.topics)
          : newContextTopic
      ).map((t) => ({
        ...t,
        frequency: t.topic === topicName ? t.frequency + 1 : t.frequency,
      }));
      if (selectedContext.candidates)
        contextObj["candidates"] = filteredCandidates
          ? filteredCandidates
          : selectedContext.candidates;

      constructedPhrase.context = [contextObj];
      constructedPhrase.frequency = 1;
      constructedPhrase.phrase = text;
      constructedPhrase.phraseId = Math.floor(100000 + Math.random() * 90000);
      constructedPhrase.isSeed = true;
      constructedPhrase.isCurated = true;
      if (phrase.char_sim) constructedPhrase.char_sim = phrase.char_sim;
      if (phrase.pos_tags) constructedPhrase.pos_tags = phrase.pos_tags;
      if (phrase.sem_sim) constructedPhrase.sem_sim = phrase.sem_sim;
      if (phrase.sentiment) constructedPhrase.char_sim = phrase.sentiment;

      //update Topics
      const updateTopics = (topics, topicIds) => {
        const ut = cloneDeep(topics).map((t) => {
          if (topicIds.includes(t.id)) {
            if (t.id === selectedPaletteTopic.id) {
              t.frequency = t.frequency + 1;
            }
            t.phrases = t.phrases
              .map((p) => {
                p.context.map((ctx) => {
                  ctx.topics = ctx.topics.map((ct) => {
                    if (
                      ct.topic === t.name &&
                      t.id === selectedPaletteTopic.id
                    ) {
                      ct.frequency = ct.frequency + 1;
                    }
                    return ct;
                  });
                  if (ctx.contextId === selectedContext.contextId) {
                    ctx.context = sideTopicWithDataAttr(
                      contextToBeUpdated,
                      newContextTopic,
                      isTopicPaletteHighlightActive,
                      t.name
                    );
                    ctx.topics = constructedPhrase["context"][0].topics;
                    if (selectedContext.candidates)
                      ctx.candidates =
                        constructedPhrase["context"][0].candidates;
                  }
                  return ctx;
                });
                return p;
              })
              .concat(
                selectedPaletteTopic.id === t.id ? constructedPhrase : []
              );
          }
          return t;
        });
        return ut;
      };

      //Filter Topics for Selected Topic Phrases
      let updateTopicIds =
        isCandidate || isNgram || isunClassified ? [] : [selectedTopics[0]];
      updateTopicIds.push(selectedPaletteTopic.id);
      let ut = updateTopics(topics, updateTopicIds);
      let udt = updateTopics(dataTopics, updateTopicIds);
      let utp = cloneDeep(ut).filter((top) => top.id === selectedTopics[0]);
      if (searchWord.length) {
        const selDataTopic = udt.find((t) => t.id === selectedTopics[0]);
        if (
          selDataTopic &&
          !selDataTopic.phrases.find((p) => p.phraseId === selPhrase.phraseId)
        ) {
          udt = cloneDeep(udt).map((dt) => {
            if (dt.id === selectedTopics[0] && utp.length)
              dt.phrases = dt.phrases.concat(
                utp[0].phrases.find((e) => e.phraseId === selPhrase.phraseId)
              );
            return dt;
          });
        }
      }
      setTopics(ut);
      setDataTopics(udt);

      if (utp.length > 0) {
        const fp = doClientOperations(
          utp[0].phrases,
          phrasePattern,
          searchWord,
          sortBy,
          wordsBefore,
          wordsAfter,
          sortType
        );
        const markedContent = doClientMark(fp, searchWord);

        setTotalPhrases(markedContent.length);
        const filteredPhrases = filterTopicalPhrases(markedContent);
        setTimeout(() => {
          setPhrases(EMPTY_ARRAY.concat(filteredPhrases));
        }, 200);

        const selectedPhraseIds = filteredPhrases.length
          ? topicalPhraseType !== "machine"
            ? filteredPhrases.find(
                (e) => e.phraseId === selectedContext.phraseId
              ).phraseId
            : filteredPhrases[0].phraseId
          : EMPTY_ARRAY;
        setSelectedPhrases(EMPTY_ARRAY.concat(selectedPhraseIds));
      } else if (isCandidate || isNgram || isunClassified) {
        const updatedPhrases = cloneDeep(
          isCandidate
            ? candidatePhrases
            : isNgram
            ? getGramPhrases(gramWeight[0])
            : unClassifiedPhrases
        ).map((cp) => {
          if (cp.phraseId === selectedContext.phraseId) {
            cp.context = cp.context.map((ctx) => {
              if (ctx.contextId === selectedContext.contextId) {
                ctx.context = sideTopicWithDataAttr(
                  contextToBeUpdated,
                  newContextTopic,
                  isTopicPaletteHighlightActive,
                  topicName
                );
                ctx.topics = selectedContext.topics
                  ? newContextTopic.concat(selectedContext.topics)
                  : newContextTopic;
                if (selectedContext.candidates)
                  ctx.candidates = constructedPhrase["context"][0].candidates;
              }
              return ctx;
            });
          }
          return cp;
        });
        setPhrases(
          doClientOperations(
            isNgram ? uniqBy(updatedPhrases, "phraseId") : updatedPhrases,
            phrasePattern,
            searchWord,
            sortBy,
            wordsBefore,
            wordsAfter,
            sortType
          )
        );
        isCandidate
          ? setCandidatePhrases(updatedPhrases)
          : isNgram
          ? setGramPhrases(updatedPhrases, gramWeight[0])
          : setUnClassifiedPhrases(updatedPhrases);
      } else setPhrases(EMPTY_ARRAY);

      setShouldBlockNavigation(true);
      setUndoableEvent("TOPIC_PALETTE_UPDATE");
    } catch (err) {
      console.log(err);
      toast.error("There was some problem while highlighting phrase.");
    }
  };

  const updateHighlightedPhrase = (
    text,
    selectedCtx,
    markNodes,
    paletteUsed
  ) => {
    try {
      if (text.trim().length === 0) return;
      if (markNodes.length === 0) return;

      const isMaintopic = markNodes[0].className.search("main-topic") > -1;

      let sideTopic = null;

      let updatedCtx = cloneDeep(selectedCtx);
      let option = "";
      markNodes.forEach((node) => {
        if (
          isTopical &&
          (node.className.search("main-topic") > -1 ||
            node.className.search("side-topic") > -1)
        ) {
          option = "phrases";
          let found = false;
          updatedCtx.topics = updatedCtx.topics.map((e) => {
            if (
              e.value.toLowerCase().search(node.textContent.toLowerCase()) >
                -1 &&
              e.topic &&
              !found
            ) {
              if (node.className.search("side-topic") > -1) sideTopic = e;
              found = true;
              return { ...e, value: text };
            }
            return e;
          });
        } else if (node.className === "candidate-phrase") {
          option = option.length ? option : "candidate";
          updatedCtx.candidates = updatedCtx.candidates.filter(
            (e) =>
              !(e.toLowerCase().search(node.textContent.toLowerCase()) > -1)
          );
        }
      });

      const topicSelected = paletteUsed
        ? cloneDeep(topics).find((t) => t.id === isSelectedTopic.id)
        : cloneDeep(topics).find((t) => t.id === selectedTopics[0]);

      if (!topicSelected && isTopical) return;

      if (option === "candidate") {
        updatedCtx.candidates.push(text);
      }

      const mainTopicArray =
        isTopical &&
        cloneDeep(updatedCtx.topics).filter(
          (ct) => ct.value.toLowerCase() === selectedCtx.phrase.toLowerCase()
        );
      const sideTopics =
        isTopical &&
        cloneDeep(updatedCtx.topics).filter(
          (ct) => ct.value.toLowerCase() !== selectedCtx.phrase.toLowerCase()
        );

      let candidateHighlight = highLightCandidate(
        updatedCtx.context.replace(/\s+/g, " ").replace(/(<([^>]+)>)/gi, ""),
        updatedCtx.candidates
      );
      let mainTopicsHighlight = mainTopicArray
        ? mainTopicWithDataAttr(candidateHighlight, mainTopicArray)
        : candidateHighlight;

      updatedCtx.context = sideTopics
        ? sideTopicWithDataAttr(
            mainTopicsHighlight,
            sideTopics,
            isTopicPaletteHighlightActive,
            topicSelected.name
          )
        : mainTopicsHighlight;

      let newUpdatedPhrases = [];

      if (isCandidate) {
        const updatedCandidatePhrases = cloneDeep(candidatePhrases).map(
          (cp) => {
            if (cp.phraseId === updatedCtx.phraseId) {
              cp.context = cp.context.map((ctx) => {
                if (ctx.contextId === updatedCtx.contextId) {
                  ctx.context = updatedCtx.context;
                  ctx.candidates = updatedCtx.candidates;
                }
                return ctx;
              });
            }
            return cp;
          }
        );
        setCandidatePhrases(updatedCandidatePhrases);
        newUpdatedPhrases = updatedCandidatePhrases;
      } else if (isunClassified) {
        const updatedUnclassifiedPhrases = cloneDeep(unClassifiedPhrases).map(
          (ucp) => {
            if (ucp.phraseId === updatedCtx.phraseId) {
              ucp.context = ucp.context.map((ctx) => {
                if (ctx.contextId === updatedCtx.contextId) {
                  ctx.context = updatedCtx.context;
                  ctx.candidates = updatedCtx.candidates;
                }
                return ctx;
              });
            }
            return ucp;
          }
        );
        setUnClassifiedPhrases(updatedUnclassifiedPhrases);
        newUpdatedPhrases = updatedUnclassifiedPhrases;
      } else {
        //topics data model
        let updatedSideTopicPhrase;
        if (sideTopic) {
          //update context highlights
          const mainTopicArray = [{ value: text, topic: sideTopic.topic }];
          const sideTopicArray = cloneDeep(updatedCtx.topics).filter(
            (ct) => ct.topic.toLowerCase() !== text.toLowerCase()
          );

          const cleanContext = updatedCtx.context
            .replace(/\s+/g, " ")
            .replace(/<\/?span[^>]*>/gi, "")
            .replace(/<\/?mark[^>]*>/gi, "");

          const highlightCandidate = updatedCtx.candidates
            ? highLightCandidate(cloneDeep(cleanContext), updatedCtx.candidates)
            : cloneDeep(cleanContext);

          const hightLightMainTopic = mainTopicWithDataAttr(
            highlightCandidate,
            mainTopicArray
          );
          const highlightSideTopic = sideTopicWithDataAttr(
            hightLightMainTopic,
            sideTopicArray,
            isTopicPaletteHighlightActive,
            text
          );

          const modifiedCtx =
            searchWord.length === 0
              ? highlightSideTopic
              : highLightSearchWord(highlightSideTopic, searchWord);

          const modifedContext = {
            contextId: uuidv4(),
            candidates: updatedCtx.candidates ? updatedCtx.candidates : [],
            topics: cloneDeep(updatedCtx).topics.reverse(),
            context: modifiedCtx,
          };

          updatedSideTopicPhrase = {
            char_sim: 0,
            context: [modifedContext],
            frequency: 1,
            phrase: text,
            phraseId: Math.floor(100000 + Math.random() * 90000),
            pos_tags: [],
            sem_sim: 0,
            text: text,
            isSeed: true,
            isCurated: true,
          };
        }

        const ut = cloneDeep(topics).map((t) => {
          if (t.id === topicSelected.id) {
            t.phrases = t.phrases.map((p) => {
              p.context.map((ctx) => {
                if (ctx.contextId === updatedCtx.contextId) {
                  ctx.topics = updatedCtx.topics;
                  ctx.context = updatedCtx.context;
                  ctx.candidates = updatedCtx.candidates;
                }
                return ctx;
              });
              return p;
            });
          } else if (
            sideTopic &&
            updatedSideTopicPhrase &&
            t.name.toLowerCase() === sideTopic.topic.toLowerCase()
          ) {
            t.phrases = cloneDeep(t.phrases)
              .filter((p) => p.phrase !== sideTopic.value)
              .concat([updatedSideTopicPhrase]);
          }

          return t;
        });

        const udt = cloneDeep(dataTopics).map((dt) => {
          if (dt.id === topicSelected.id) {
            dt.phrases = dt.phrases.map((dp) => {
              dp.context.map((dctx) => {
                if (dctx.contextId === updatedCtx.contextId) {
                  dctx.topics = updatedCtx.topics;
                  dctx.context = updatedCtx.context;
                  dctx.candidates = updatedCtx.candidates;
                }
                return dctx;
              });
              return dp;
            });
          } else if (
            sideTopic &&
            dt.name.toLowerCase() === sideTopic.topic.toLowerCase()
          ) {
            dt.phrases = cloneDeep(dt.phrases)
              .filter((p) => p.phrase !== sideTopic.value)
              .concat([updatedSideTopicPhrase]);
          }
          return dt;
        });

        const utp = cloneDeep(ut).find((top) => top.id === selectedTopics[0]);
        newUpdatedPhrases = utp ? utp.phrases : [];
        setTopics(ut);
        setDataTopics(udt);

        setTimeout(() => {
          if (!isCandidate && !isNgram && !isunClassified) {
            const tt = cloneDeep(topics).filter(
              (t) => t.id === selectedTopics[0]
            );
            if (isMaintopic) {
              setDialogData({
                event: "delete",
                selPhrase: selectedCtx.phrase,
                selTopic: tt[0].name,
                context: selectedCtx,
                selectedText: text,
              });
              setOpen(true);
            }
          }
        }, 300);
      }

      if (newUpdatedPhrases.length) {
        const fp = doClientOperations(
          newUpdatedPhrases,
          phrasePattern,
          searchWord,
          sortBy,
          wordsBefore,
          wordsAfter,
          sortType
        );
        const markedContent = doClientMark(fp, searchWord);
        setTotalPhrases(
          isunClassified && markedContent.length
            ? markedContent[0].context.length
            : markedContent.length
        );
        const filteredPhrases = filterTopicalPhrases(markedContent);
        setTimeout(() => {
          setPhrases(EMPTY_ARRAY.concat(filteredPhrases));
        }, 200);

        const selPhraseIds =
          (isTopical || isCandidate) && filteredPhrases.length
            ? selectedPhrases.length
              ? filteredPhrases.find((e) => e.phraseId === selectedPhrases[0])
                  .phraseId
              : filteredPhrases[0].phraseId
            : EMPTY_ARRAY;
        setSelectedPhrases(EMPTY_ARRAY.concat(selPhraseIds));
      }

      setUndoableEvent(
        option === "candidate"
          ? "UPDATE_CANDIDATE_PHRASE"
          : "UPDATE_TOPIC_PHRASE"
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectedContext = (el) => setSelectedContext(el);

  //Show Topics View
  const handleTopical = (phraseType = topicalPhraseType) => {
    if (atdState === "locked") return;
    try {
      const tp = cloneDeep(topics).filter(
        (t) => t.id === prevSelectedTopics[0]
      );
      const selPhrases = tp.length > 0 ? tp[0].phrases : topics[0].phrases;

      const utp = doClientOperations(
        cloneDeep(selPhrases),
        phrasePattern,
        searchWord,
        sortBy,
        wordsBefore,
        wordsAfter,
        sortType
      );
      const marked_data =
        searchWord.trim().length ||
        wordsBefore.trim().length ||
        wordsAfter.trim().length
          ? doClientMark(
              utp,
              searchWord,
              "isTopics",
              tp.length ? tp[0] : topics[0]
            )
          : utp;
      const filteredPhrases =
        phraseType !== "all"
          ? marked_data.filter((p) =>
              phraseType === "curated" ? p.isSeed : !p.isSeed
            )
          : marked_data;
      setPhrases(EMPTY_ARRAY.concat(filteredPhrases));
      setIsunClassified(false);
      setIsCandidate(false);
      setIsNgram(false);
      setGramWeight([2]);
      setPhrasesPage(1);
      setSelectedTopics(
        EMPTY_ARRAY.concat(tp.length > 0 ? prevSelectedTopics : [topics[0].id])
      );
      if (marked_data.length) {
        setTotalPhrases(marked_data.length);
      }
      setSelectedPhrases(
        filteredPhrases.length
          ? EMPTY_ARRAY.concat(filteredPhrases[0].phraseId)
          : EMPTY_ARRAY
      );

      setTimeout(() => {
        if (!isCandidate && !isNgram && !isunClassified) return;
        if (searchWord.trim().length >= 3)
          hybridSearchSort(sortBy, sortType, "isTopics");
      }, 600);
    } catch (error) {
      console.log(error);
    }
  };

  //get unfilterd topics
  const getUnfilteredTopics = () => {
    let unFilteredTopics = [];
    try {
      unFilteredTopics = cloneDeep(dataTopics)
        .filter((t) => {
          if (selectedTopicSentiment)
            return t.sentiment === selectedTopicSentiment;
          else return true;
        })
        .filter((t) => {
          if (topicCurateType === "all") return true;
          else if (topicCurateType === "curated") return t.isCurated;
          else if (topicCurateType === "seed") return t.isSeedTopic;
          else if (topicCurateType === "non-seed") return !t.isSeedTopic;
          else return !t.isCurated;
        });
      return unFilteredTopics;
    } catch (error) {
      console.log(error);
    }
  };

  //Show Candidates View
  const handleCandidates = () => {
    if (atdState === "locked") return;
    if (isCandidate) return;

    try {
      setPhrases(EMPTY_ARRAY);
      setIsCandidate(true);
      setIsNgram(false);
      setIsunClassified(false);
      setGramWeight([2]);
      setSelectedTopics(EMPTY_ARRAY);
      setPhrasesPage(1);

      const cp = doClientOperations(
        cloneDeep(candidatePhrases),
        phrasePattern,
        searchWord,
        sortBy,
        wordsBefore,
        wordsAfter,
        sortType
      );
      const marked_data = doClientMark(cp, searchWord, "isCandidate");
      setPhrases(EMPTY_ARRAY.concat(marked_data));
      setTotalPhrases(candidateTotalCount);
      if (cp.length > 0) {
        setSelectedPhrases(EMPTY_ARRAY.concat(cp[0].phraseId));
        setLoadedContextCount(cp[0].context.length);
      }
      setTimeout(() => {
        if (searchWord.trim().length >= 3)
          hybridSearchSort(sortBy, sortType, "isCandidate");
        const filterdTopics = getUnfilteredTopics();
        setTopics(EMPTY_ARRAY.concat(filterdTopics));
      }, 600);
    } catch (error) {
      console.log(error);
    }
  };

  //Show N-grams View
  const handleNgrams = () => {
    if (atdState === "locked") return;
    if (isNgram) return;
    setPhrases(EMPTY_ARRAY);
    try {
      const gp = ngramData.filter((n) => n.weight === 2);
      const gData = doClientOperations(
        cloneDeep(get(gp[0], "data")),
        phrasePattern,
        searchWord,
        sortBy,
        wordsBefore,
        wordsAfter,
        sortType
      );
      const markedData = doClientMark(gData, searchWord, "isNgram");
      setPhrases(markedData);
      setGramWeight([get(gp[0], "weight")]);
      setTotalPhrases(get(gp[0], "count"));
      setPhrasesPage(1);
      setSelectedTopics(EMPTY_ARRAY);
      if (gData.length > 0) {
        setSelectedPhrases(EMPTY_ARRAY.concat(gData[0].phraseId));
        setLoadedContextCount(gData[0].context.length);
      } else setSelectedPhrases(EMPTY_ARRAY);
      setIsNgram(true);
      setIsCandidate(false);
      setIsunClassified(false);

      setTimeout(() => {
        if (searchWord.trim().length >= 3)
          hybridSearchSort(sortBy, sortType, "isNgram");
        const filterdTopics = getUnfilteredTopics();
        setTopics(EMPTY_ARRAY.concat(filterdTopics));
      }, 600);
    } catch (err) {
      console.error(err);
    }
  };

  //Show unClassified View
  const handleunClassified = () => {
    try {
      if (atdState === "locked") return;
      if (isunClassified) return;
      setPhrases(EMPTY_ARRAY);
      const ucp = doClientOperations(
        cloneDeep(unClassifiedPhrases),
        phrasePattern,
        searchWord,
        sortBy,
        wordsBefore,
        wordsAfter,
        sortType
      );
      const markedunData = doClientMark(ucp, searchWord, "isunClassified");
      setIsunClassified(true);
      setIsNgram(false);
      setIsCandidate(false);
      setSelectedTopics(EMPTY_ARRAY);
      setPhrasesPage(1);
      setPhrases(EMPTY_ARRAY.concat(markedunData));
      setTotalPhrases(unclassifiedTotalCount);

      setTimeout(() => {
        if (searchWord.trim().length >= 3)
          hybridSearchSort(sortBy, sortType, "isunClassified");
        const filterdTopics = getUnfilteredTopics();
        setTopics(EMPTY_ARRAY.concat(filterdTopics));
      }, 600);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOptions = (option) => {
    if (atdState === "locked") return;
    switch (option) {
      case "topical":
        handleTopical();
        break;
      case "candidates":
        handleCandidates();
        break;
      case "n-grams":
        handleNgrams();
        break;
      case "unclassified":
        handleunClassified();
        break;
      default:
        showTopicsByCreated(option);
        break;
    }
  };

  const handleTopicalOptions = (value) => {
    if (atdState === "locked") return;
    if (isTopical && value === topicalPhraseType) return;
    setTopicalPhraseType(value);
    handleTopical(value);
  };

  //Save ATD data
  const saveATDData = useCallback(() => {
    if (atdState === "locked") return;

    try {
      setSavingData(true);

      const selTopic = selectedDiscoverdTopicRef.current;

      if (saveLocation === "local") {
        localForage
          .getItem("atdData")
          .then((atdData) => {
            if (atdData) {
              let nd = [];
              try {
                //Check data availble in local or not
                if (atdData.some((d) => d.value === selTopic.value)) {
                  nd = cloneDeep(atdData).map((a) => {
                    if (a.value === selTopic.value)
                      a.data = {
                        topics: dataTopicsRef.current,
                        candidates: candidatePhrasesRef.current,
                        candidateCount: candidateTotalCount ? candidateTotalCount : summaryDataRef.current.noOfCandidatePhrases,
                        summary: summaryDataRef.current,
                        progress: progressDataRef.current,
                        paletteData: topicPalettesRef.current[defaultBrand],
                      };
                    return a;
                  });
                } else {
                  const localDataObj = {
                    value: selTopic.value,
                    label: selTopic.label,
                    data: {
                      topics: dataTopicsRef.current,
                      candidates: candidatePhrasesRef.current,
                      candidateCount: candidateTotalCount
                        ? candidateTotalCount
                        : summaryDataRef.current.noOfCandidatePhrases,
                      summary: summaryDataRef.current,
                      progress: progressDataRef.current,
                      paletteData: topicPalettesRef.current[defaultBrand],
                    },
                  };
                  nd = atdData.concat(localDataObj);
                }
                localForage
                  .setItem("atdData", nd)
                  .then(() => {
                    console.log("Saved Data.");
                    if (!autoSaveRef.current) toast.success("Saved data.");
                    setShouldBlockNavigation(false);
                    setSavingData(false);
                    checkStorageQuota();
                  })
                  .catch((err) => {
                    setSavingData(false);
                    console.log("Local Forage Error (Update Data):  ", err);
                    toast.error(
                      "Storage is full. No additional data can be saved."
                    );
                  });
              } catch (e) {
                setSavingData(false);
                console.log("Error (ATD Data exists in DB):  ", e);
                toast.error("There was some problem while saving data.");
              }
            } else {
              try {
                //Set local data for first time
                const atdobj = {
                  value: selTopic.value,
                  label: selTopic.label,
                  data: {
                    topics: dataTopicsRef.current,
                    candidates: candidatePhrasesRef.current,
                    candidateCount: candidateTotalCount
                      ? candidateTotalCount
                      : summaryDataRef.current.noOfCandidatePhrases,
                    summary: summaryDataRef.current,
                    progress: progressDataRef.current,
                    paletteData: topicPalettesRef.current[defaultBrand],
                  },
                };
                localForage
                  .setItem("atdData", [atdobj])
                  .then(() => {
                    console.log("Saved Data.");
                    if (!autoSaveRef.current) toast.success("Saved data.");
                    setShouldBlockNavigation(false);
                    setSavingData(false);
                    checkStorageQuota();
                  })
                  .catch((err) => {
                    setSavingData(false);
                    console.log("Local Forage Error (First Save):  ", err);
                    toast.error(
                      "Storage is full. No additional data can be saved."
                    );
                  });
              } catch (e) {
                setSavingData(false);
                console.log("Error (No ATD data in DB):  ", e);
                toast.error("There was some problem while saving data.");
              }
            }
          })
          .catch((err) => {
            setSavingData(false);
            console.log("Local Forage Error (Retreiving data):  ", err);
            toast.error("There was some problem while saving data.");
          });
      } else {
        const atdJSONData = JSON.stringify({
          topics: dataTopicsRef.current,
          candidates: candidatePhrasesRef.current,
          candidateCount: candidateTotalCount
            ? candidateTotalCount
            : summaryDataRef.current.noOfCandidatePhrases,
          summary: summaryDataRef.current,
          progress: progressDataRef.current,
          paletteData: topicPalettesRef.current[defaultBrand],
        });
        const jsonFile = new File([atdJSONData], "atdData.json", {
          type: "text/json",
        });
        const formData = new FormData();
        formData.append("folderId", selTopic.value);
        formData.append("atdData", jsonFile);

        client
          .post(SAVE_TO_CLOUD, formData)
          .then(() => {
            if (!autoSaveRef.current) toast.success("Saved data.");
            console.log("Saved data in cloud.");
            setSavingData(false);
            setShouldBlockNavigation(false);
            // Clear Locally saved atd data
            localForage
              .getItem("atdData")
              .then((atdData) => {
                if (
                  atdData &&
                  atdData.find((d) => d.value === selTopic.value)
                ) {
                  localForage.setItem(
                    "atdData",
                    atdData.filter((d) => d.value !== selTopic.value)
                  );
                }
              })
              .catch((err) => {
                console.log("LocalForage Error after Cloud Save", err);
              });
          })
          .catch((err) => {
            setSavingData(false);
            toast.error("Unable to save data on cloud.");
            console.log("CloudSave Error:  ", err);
          });
      }
    } catch (err) {
      setSavingData(false);
      console.log("Error before initializing localForage save:   ", err);
      toast.error("There was some problem while saving data.");
    }
  }, [atdState, candidateTotalCount, saveLocation, defaultBrand]);

  //Handle Regex Generation
  const updateRegEx = (classifierType, folderName, eventType) => {
    setUndoableEvent("CLEAR");
    if (seedingClassifer) return;

    const userEmail = get(
      JSON.parse(localStorage.getItem("userDetails")),
      "email"
    );
    updateSeedingEvent(eventType, true);

    client
      .get(GET_EXPORT_TOPICS, {
        params: { folderId: selectedDiscoverdTopic.value },
      })
      .then(async (res) => {
        if (res.data.status === SUCCESS) {
          const fetchedTopics = res.data.response;
          const loadedTopics = cloneDeep(topics);
          const updatedTopics = await loadedTopics.map((t) => {
            const pp = fetchedTopics.find((ft) => ft.id === t.id);
            if (pp && !t.isLoaded)
              t.phrases = uniqBy(t.phrases.concat(pp.phrases), "phraseId");
            return t;
          });

          let topicObj = cloneDeep(updatedTopics).map((t) => {
            let ob = {};
            ob["Name"] = t.name;
            ob["Type"] = "";
            ob["S/E"] = t.sentiment;
            ob["Implemented_by"] = t.isCurated ? userEmail : "";
            ob["Specified_by"] = "";
            ob["Specification_Document"] = "";
            ob["Description"] = "";
            ob["Categories"] = "";
            ob["Phrase"] = t.phrases.map((p) => p.phrase.trim()).join("@#");
            return ob;
          });

          const classifiersObj = custSpecialObjToCSV(topicObj);
          const classifierData = classifiersObj.replace(/@#/g, '","');

          setTimeout(() => {
            const file = new File([classifierData], "classifierData.csv", {
              type: "text/plain",
            });
            const formData = new FormData();
            formData.append("folderId", selectedDiscoverdTopic.value);
            formData.append("dataFile", file);
            formData.append("classifierType", classifierType);
            formData.append(
              "type",
              eventType === "createModel" ? "model" : "classifier"
            );
            formData.append("folderName", folderName);

            client
              .post(AUTO_REGEX_REQUEST, formData)
              .then((res) => {
                if (res.data.status === SUCCESS) {
                  updateSeedingEvent(eventType, false);
                } else {
                  updateSeedingEvent(eventType, false);
                  toast.error(res.data.message);
                }
              })
              .catch((error) => {
                console.log(error);
                updateSeedingEvent(eventType, false);
              });
          }, 400);
        } else updateSeedingEvent(eventType, false);
      })
      .catch((error) => {
        console.log(error);
        updateSeedingEvent(eventType, false);
      });
  };

  const updateSeedingEvent = (eventType, value) => {
    if (eventType === "createModel") {
      setSeedingModel(value);
    } else {
      setSeedingClassifer(value);
    }
  };

  //Export Discovered Topic
  const handleTopicExport = () => {
    toastId = toast("Processing seed file. Please wait...", {
      type: toast.TYPE.DEFAULT,
      autoClose: false,
    });

    client
      .get(GET_EXPORT_TOPICS, {
        params: { folderId: selectedDiscoverdTopic.value },
      })
      .then(async (res) => {
        if (res.data.status === SUCCESS) {
          const fetchedTopics = res.data.response;
          const loadedTopics = cloneDeep(topics);

          const updatedTopics = await loadedTopics.map((t) => {
            const pp = fetchedTopics.find((ft) => ft.id === t.id);
            if (!t.isLoaded && pp)
              t.phrases = uniqBy(t.phrases.concat(pp.phrases), "phraseId");
            return t;
          });

          try {
            const userEmail = get(
              JSON.parse(localStorage.getItem("userDetails")),
              "email"
            );
            let csvDataArray = cloneDeep(updatedTopics).map((t) => {
              let ob = {};
              ob["Classifier ID"] = t.id;
              ob["Name"] = t.name;
              ob["Type"] = "STANDARD";
              ob["S/E"] = t.emotion
                ? t.emotion.toUpperCase()
                : (t.sentiment || "").toUpperCase();
              ob["Implemented_by"] = t.isCurated ? userEmail : "";
              ob["Specified_by"] = "";
              ob["Specification_Document"] = "";
              ob["Description"] = "";
              ob["Categories"] = "";
              ob["Phrase"] = t.phrases.map((p) => p.phrase.trim()).join("@#");
              return ob;
            });
            if (!csvDataArray.length) {
              toast.warn("No data to export.");
            } else {
              try {
                const csvData = custSpecialObjToCSV(csvDataArray).replace(
                  /@#/g,
                  '","'
                );
                handleCsvFileDownload(csvData, exportFileName);
                toast.update(toastId, {
                  type: toast.TYPE.SUCCESS,
                  render: "File successfully exported.",
                  autoClose: 3500,
                });
              } catch (err) {
                toast.update(toastId, {
                  type: toast.TYPE.ERROR,
                  render: "Failed to export data.",
                  autoClose: 3500,
                });
                console.log(err);
              }
            }
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const calcDynamicHeight = () => {
    try {
      const main = document.getElementById("main")
        ? document.getElementById("main").clientHeight
        : 300;
      const topicHeight = document.getElementById("topicsHolder")
        ? document.getElementById("topicsHolder").clientHeight
        : 90;
      const progessBarsHeight = document.getElementById("progress-bars-holder")
        ? document.getElementById("progress-bars-holder").clientHeight
        : 0;
      setCalcHeight(main - topicHeight - progessBarsHeight - 40);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCreateTopicModal = () => {
    if (atdState === "locked") return;
    setCreateTopicModal(!createTopicModal);
  };

  const createMultipleTopics = (newTopics, seedFileUsed) => {
    try {
      setUndoableEvent("CLEAR");
      if (seedFileUsed) {
        createNewTopicsFromSeedFile(newTopics);
      } else {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        let duplicateTopics = [];

        const updatedNewTopics = cloneDeep(newTopics)
          .map((item) => {
            if (item.topicTitle) {
              if (
                dataTopics.find(
                  (t) => t.name.toLowerCase() === item.topicTitle.toLowerCase()
                )
              ) {
                duplicateTopics.push(item.topicTitle);
                return null;
              }

              let newTopic = {};
              newTopic["createdAt"] = localDateTime(new Date());
              newTopic["frequency"] = 0;
              newTopic["id"] = Math.floor(1000000 + Math.random() * 900000);
              newTopic["isCurated"] = true;
              newTopic["isFolder"] = false;
              newTopic["isLoaded"] = true;
              newTopic["modifiedAt"] = localDateTime(new Date());
              newTopic["name"] = item.topicTitle;
              newTopic["parentId"] = selectedDiscoverdTopic.value;
              newTopic["phrases"] = EMPTY_ARRAY;
              newTopic["sentiment"] = item.topicSentiment;
              newTopic["sentimentScore"] = 0;
              newTopic["userId"] = userDetails.id;

              setCuratedTopics((state) => state.concat(newTopic.id));
              return newTopic;
            } else return null;
          })
          .filter((t) => t);

        if (duplicateTopics.length) {
          toast.warn(`Topic ${duplicateTopics.join(", ")} already exist.`);
        }
        if (updatedNewTopics.length) {
          setTopics(cloneDeep(topics).concat(updatedNewTopics));
          setDataTopics(cloneDeep(dataTopics).concat(updatedNewTopics));
          setAddedNewTopic(true);
        }
        setNewTopicTitle(EMPTY_STRING);
        setShouldBlockNavigation(true);
      }
      handleCreateTopicModal();
      seedFileData.length && setSeedFileData(EMPTY_ARRAY);
    } catch (err) {
      console.log(err);
    }
  };

  //Move selected Highlight to selected topic
  const handleSuggestion = (topic, node, ctx) => {
    if (selectedTopics[0] === topic.id) {
      toast.warn("cannot move on same topic");
      return;
    }

    try {
      setUndoableEvent("CLEAR");
      const text = node.innerText,
        type = [...node.classList][0],
        topicSelectedName = ctx.topicSelected;

      if (topic.name !== "create new" && topic.id !== 0) {
        const pdata = isCandidate
          ? cloneDeep(candidatePhrases)
          : isNgram
          ? getGramPhrases(gramWeight[0])
          : isunClassified
          ? cloneDeep(unClassifiedPhrases)
          : cloneDeep(topics.find((t) => t.id === selectedTopics[0]).phrases);

        const reclassifyPhrase = cloneDeep(pdata).filter(
          (p) => p.phraseId === ctx.phraseId
        );

        //Topics updates
        if (type === "main-topic") {
          const updatePhrase = cloneDeep(reclassifyPhrase).map((rr) => {
            rr.phrase = text;
            rr.isSeed = true;
            rr.isCurated = true;
            return rr;
          });

          const ut = cloneDeep(topics).map((t) => {
            //selected topic update
            if (t.id === selectedTopics[0]) {
              const filterdPhrase = t.phrases.filter(
                (p) => p.phraseId !== ctx.phraseId
              );
              const negWeight = uniqueArray(cloneDeep(filterdPhrase));

              t.phrases = cloneDeep(filterdPhrase).map((nrp) => {
                nrp.context = nrp.context.map((nrpc) => {
                  nrpc.topics = nrpc.topics.map((nrpct) => {
                    nrpct.frequency = negWeight.length;
                    return nrpct;
                  });
                  return nrpc;
                });
                return nrp;
              });
              t.frequency = negWeight.length;
            }

            //search selected topic update
            if (t.id === topic.id) {
              const totalPhrases = t.phrases.concat(updatePhrase);
              const posWeight = t.frequency + 1;

              const up = cloneDeep(totalPhrases).map((rp) => {
                rp.context = rp.context.map((rpc) => {
                  const isTopicExists = rpc.candidates
                    ? rpc.candidates.includes(text)
                    : false;

                  const ctxTopics = rpc.topics
                    .map((rpct) => {
                      if (rpct.value.toLowerCase() === text.toLowerCase()) {
                        rpct.topic = topic.name;
                        rpct.frequency = posWeight;
                      }
                      return rpct;
                    })
                    .concat(
                      isTopicExists
                        ? [
                            {
                              topic: topic.name,
                              value: text,
                              frequency: posWeight,
                            },
                          ]
                        : []
                    )
                    .reverse();

                  rpc.topics = ctxTopics;

                  //Contex applied
                  const mainTopicArray = rpc.topics
                    ? rpc.topics.filter(
                        (ct) =>
                          ct.value.toLowerCase() === rp.phrase.toLowerCase() ||
                          ct.topic === ""
                      )
                    : [{ value: text, topic: topic.name, frequency: 1 }];

                  const sideTopicArray = rpc.topics
                    ? rpc.topics.filter(
                        (ct) =>
                          ct.value.toLowerCase() !== rp.phrase.toLowerCase()
                      )
                    : [];

                  const canHighlight =
                    rpc.candidates && !rpc.candidates.includes(text)
                      ? highLightCandidate(
                          cloneDeep(
                            rpc.context
                              .replace(/\s+/g, " ")
                              .replace(/<\/?span[^>]*>/gi, "")
                              .replace(/<\/?mark[^>]*>/gi, "")
                          ),
                          rpc.candidates
                        )
                      : cloneDeep(
                          rpc.context
                            .replace(/\s+/g, " ")
                            .replace(/<\/?span[^>]*>/gi, "")
                            .replace(/<\/?mark[^>]*>/gi, "")
                        );

                  const hightLightMainTopic = mainTopicWithDataAttr(
                    canHighlight,
                    mainTopicArray.length > 0
                      ? mainTopicArray
                      : [{ value: text, topic: topic.name, frequency: 1 }]
                  );
                  const highlightSideTopic = sideTopicWithDataAttr(
                    hightLightMainTopic,
                    sideTopicArray,
                    isTopicPaletteHighlightActive,
                    topicSelectedName
                  );

                  rpc.context = highlightSideTopic;
                  return rpc;
                });
                return rp;
              });
              t.phrases = up;
              t.frequency = posWeight;
            }
            return t;
          });
          setTopics(ut);

          const udt = cloneDeep(dataTopics).map((t) => {
            //selected topic update
            if (t.id === selectedTopics[0]) {
              const filterdPhrase = t.phrases.filter(
                (p) => p.phraseId !== ctx.phraseId
              );
              const negWeight = uniqueArray(cloneDeep(filterdPhrase));
              t.phrases = t.phrases
                .filter((p) => p.phraseId !== ctx.phraseId)
                .map((nrp) => {
                  nrp.context = nrp.context.map((nrpc) => {
                    nrpc.topics = nrpc.topics.map((nrpct) => {
                      nrpct.frequency = negWeight.length;
                      return nrpct;
                    });
                    return nrpc;
                  });
                  return nrp;
                });
              t.frequency = negWeight.length;
            }

            if (t.id === topic.id) {
              const totalPhrases = t.phrases.concat(cloneDeep(updatePhrase));
              const posWeight = uniqueArray(cloneDeep(totalPhrases));
              t.phrases = totalPhrases.map((rp) => {
                rp.context = rp.context.map((rpc) => {
                  const isTopicExists = rpc.candidates
                    ? rpc.candidates.includes(text)
                    : false;

                  const ctxTopics = rpc.topics
                    .map((rpct) => {
                      if (rpct.value.toLowerCase() === text.toLowerCase()) {
                        rpct.topic = topic.name;
                        rpct.frequency = posWeight.length;
                      }
                      return rpct;
                    })
                    .concat(
                      isTopicExists
                        ? [
                            {
                              topic: topic.name,
                              value: text,
                              frequency: posWeight.length,
                            },
                          ]
                        : []
                    )
                    .reverse();

                  rpc.topics = ctxTopics;

                  //Contex applied
                  const mainTopicArray = rpc.topics
                    ? cloneDeep(rpc.topics).filter(
                        (ct) =>
                          ct.value.toLowerCase() === rp.phrase.toLowerCase() ||
                          ct.topic === ""
                      )
                    : [{ value: text, topic: topic.name, frequency: 1 }];

                  const sideTopicArray = rpc.topics
                    ? cloneDeep(rpc.topics).filter(
                        (ct) =>
                          ct.value.toLowerCase() !== rp.phrase.toLowerCase()
                      )
                    : [];

                  const canHighlight =
                    rpc.candidates && !rpc.candidates.includes(text)
                      ? highLightCandidate(
                          cloneDeep(
                            rpc.context
                              .replace(/\s+/g, " ")
                              .replace(/<\/?span[^>]*>/gi, "")
                              .replace(/<\/?mark[^>]*>/gi, "")
                          ),
                          rpc.candidates
                        )
                      : cloneDeep(
                          rpc.context
                            .replace(/\s+/g, " ")
                            .replace(/<\/?span[^>]*>/gi, "")
                            .replace(/<\/?mark[^>]*>/gi, "")
                        );

                  const hightLightMainTopic = mainTopicWithDataAttr(
                    canHighlight,
                    mainTopicArray.length > 0
                      ? mainTopicArray
                      : [{ value: text, topic: topic.name, frequency: 1 }]
                  );
                  const highlightSideTopic = sideTopicWithDataAttr(
                    hightLightMainTopic,
                    sideTopicArray,
                    isTopicPaletteHighlightActive,
                    topicSelectedName
                  );

                  rpc.context = highlightSideTopic;

                  return rpc;
                });
                return rp;
              });
              t.frequency = posWeight.length;
            }
            return t;
          });
          setDataTopics(udt);

          if (ut.length > 0) {
            const x = cloneDeep(ut).filter((t) => t.id === selectedTopics[0]);
            if (x.length > 0) {
              const p = doClientOperations(
                cloneDeep(x[0].phrases),
                phrasePattern,
                searchWord,
                sortBy,
                wordsBefore,
                wordsAfter,
                sortType
              );
              const filteredPhrases = filterTopicalPhrases(p);
              setPhrases(EMPTY_ARRAY.concat(filteredPhrases));
              setSelectedPhrases(
                filteredPhrases.length
                  ? EMPTY_ARRAY.concat(filteredPhrases[0].phraseId)
                  : EMPTY_ARRAY
              );
            }
          }
        } else {
          //Candidates/sidephrases updates
          let movedPhrase = [];

          movedPhrase = cloneDeep(pdata)
            .filter((p) => p.phraseId === ctx.phraseId)
            .map((p) => {
              p.isSeed = true;
              p.isCurated = true;
              p.context = p.context
                .filter((pc) => pc.contextId === ctx.contextId)
                .map((pc) => {
                  pc.contextId = uuidv4();

                  pc.topics = pc.topics
                    ? pc.topics
                        .map((tp) => {
                          if (tp.value.toLowerCase() === text.toLowerCase())
                            tp.topic = topic.name;
                          return tp;
                        })
                        .reverse()
                    : [{ value: text, topic: topic.name, frequency: 1 }];

                  const sideTopicArray = pc.topics
                    ? cloneDeep(pc.topics).filter(
                        (ct) =>
                          ct.topic.toLowerCase() !== topic.name.toLowerCase()
                      )
                    : [];

                  const canHighlight =
                    pc.candidates && !pc.candidates.includes(topic.name)
                      ? highLightCandidate(
                          cloneDeep(
                            pc.context
                              .replace(/\s+/g, " ")
                              .replace(/<\/?span[^>]*>/gi, "")
                              .replace(/<\/?mark[^>]*>/gi, "")
                          ),
                          pc.candidates
                        )
                      : cloneDeep(
                          pc.context
                            .replace(/\s+/g, " ")
                            .replace(/<\/?span[^>]*>/gi, "")
                            .replace(/<\/?mark[^>]*>/gi, "")
                        );

                  const hightLightMainTopic = mainTopicWithDataAttr(
                    canHighlight,
                    [{ value: text, topic: topic.name }]
                  );
                  const highlightSideTopic = sideTopicWithDataAttr(
                    hightLightMainTopic,
                    sideTopicArray,
                    isTopicPaletteHighlightActive,
                    topic.name
                  );

                  pc.context = highlightSideTopic;

                  return pc;
                });
              p.phraseId = Math.floor(10000 + Math.random() * 9000);
              p.frequency = 1;
              p.phrase = text;
              return p;
            });

          const ut = cloneDeep(topics).map((t) => {
            if (t.id === topic.id) {
              t.phrases = cloneDeep(t.phrases)
                .concat(movedPhrase)
                .map((tp) => {
                  tp.context = cloneDeep(tp.context).map((tpc) => {
                    tpc.topics = tpc.topics
                      ? cloneDeep(tpc.topics).map((tpct) => {
                          if (tpct.topic === topic.name)
                            tpct.frequency =
                              tpct.frequency + movedPhrase.length;
                          return tpct;
                        })
                      : [];
                    return tpc;
                  });
                  return tp;
                });
              t.frequency = t.frequency + movedPhrase.length;
            }
            return t;
          });
          setTopics(ut);

          const udt = cloneDeep(dataTopics).map((t) => {
            if (t.id === topic.id) {
              t.phrases = cloneDeep(t.phrases)
                .concat(movedPhrase)
                .map((tp) => {
                  tp.context = cloneDeep(tp.context).map((tpc) => {
                    tpc.topics = tpc.topics
                      ? cloneDeep(tpc.topics).map((tpct) => {
                          if (tpct.topic === topic.name)
                            tpct.frequency =
                              tpct.frequency + movedPhrase.length;
                          return tpct;
                        })
                      : [];
                    return tpc;
                  });
                  return tp;
                });
              t.frequency = t.frequency + movedPhrase.length;
            }
            return t;
          });
          setDataTopics(udt);
        }
      }
      setShouldBlockNavigation(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleNewTopicCreate = (title, sentiment, text, selectedCtx) => {
    try {
      setUndoableEvent("CLEAR");
      if (
        dataTopics.find((t) => t.name.toLowerCase() === title.toLowerCase())
      ) {
        toast.warn(`Topic name ${title} already exists.`);
        return;
      }

      const userDetails = JSON.parse(localStorage.getItem("userDetails"));

      const pdata = isCandidate
        ? cloneDeep(candidatePhrases)
        : isNgram
        ? getGramPhrases(gramWeight[0])
        : isunClassified
        ? cloneDeep(unClassifiedPhrases)
        : cloneDeep(topics.find((t) => t.id === selectedTopics[0]).phrases);

      const reclassifyPhrase = cloneDeep(pdata).filter(
        (p) => p.phraseId === selectedCtx.phraseId
      );

      const matchedContextTopics = reclassifyPhrase.map((ec) => {
        ec.context = ec.context.filter(
          (ecc) => ecc.contextId === selectedCtx.contextId
        );
        return ec;
      });

      const contextTopics = get(matchedContextTopics[0], "context[0].topics");

      let newContext = {};
      newContext.contextId = uuidv4();

      const unFilteredContext = pdata
        .find((p) => p.phraseId === selectedCtx.phraseId)
        .context.find((ctx) => ctx.contextId === selectedCtx.contextId);

      if (isCandidate || isNgram || isunClassified) {
        newContext.topics = contextTopics
          ? cloneDeep(contextTopics).map((t) => {
              if (t.value.toLowerCase() === text.toLowerCase()) {
                t.topic = title;
                t.frequency = 1;
              }
              return t;
            })
          : [{ value: text, topic: title, frequency: 1 }];
        newContext.context = mainTopicWithDataAttr(
          unFilteredContext.context
            .replace(/\s+/g, " ")
            .replace(/<\/?span[^>]*>/gi, "")
            .replace(/<\/?mark[^>]*>/gi, ""),
          [{ topic: title, value: text }]
        );
      }

      if (!isCandidate && !isNgram && !isunClassified) {
        const isContextTopicExists = contextTopics
          ? cloneDeep(contextTopics).find((ct) => ct.topic === text)
          : undefined;

        const mainTopicArray = contextTopics
          ? cloneDeep(contextTopics)
              .filter(
                (ct) =>
                  ct.topic.toLowerCase() === text.toLowerCase() ||
                  ct.topic === ""
              )
              .map((t) => {
                t.topic = title;
                t.frequency = 1;
                return t;
              })
          : [{ value: text, topic: title, frequency: 1 }];

        const sideTopicArray = cloneDeep(contextTopics).filter(
          (ct) => ct.topic.toLowerCase() !== text.toLowerCase()
        );
        const selectedTopic = cloneDeep(topics).filter(
          (t) => t.id === selectedTopics[0]
        );

        const canHighlight =
          selectedCtx.candidates && !selectedCtx.candidates.includes(text)
            ? highLightCandidate(
                cloneDeep(
                  unFilteredContext.context
                    .replace(/\s+/g, " ")
                    .replace(/<\/?span[^>]*>/gi, "")
                    .replace(/<\/?mark[^>]*>/gi, "")
                ),
                selectedCtx.candidates
              )
            : cloneDeep(
                unFilteredContext.context
                  .replace(/\s+/g, " ")
                  .replace(/<\/?span[^>]*>/gi, "")
                  .replace(/<\/?mark[^>]*>/gi, "")
              );

        const hightLightMainTopic = mainTopicWithDataAttr(
          canHighlight,
          mainTopicArray.length > 0
            ? mainTopicArray
            : [{ value: text, topic: title, frequency: 1 }]
        );
        const highlightSideTopic = sideTopicWithDataAttr(
          hightLightMainTopic,
          sideTopicArray,
          isTopicPaletteHighlightActive,
          selectedTopic[0].name
        );

        newContext.topics = cloneDeep(contextTopics)
          .map((ct) => {
            if (ct.topic === text || ct.topic === "") {
              ct.topic = title;
              ct.value = text;
              ct.frequency = 1;
            }
            return ct;
          })
          .concat(
            !isContextTopicExists
              ? [{ value: text, topic: title, frequency: 1 }]
              : []
          )
          .reverse();
        newContext.context = highlightSideTopic;
      }

      let newPhraseData = {},
        newTopicData = {};
      newPhraseData.char_sim = 0;
      newPhraseData.context = [newContext];
      newPhraseData.frequency = 1;
      newPhraseData.phrase = text;
      newPhraseData.phraseId = Math.floor(100000 + Math.random() * 90000);
      newPhraseData.pos_tags = selectedCtx.pos_tags;
      newPhraseData.sem_sim = 0;
      newPhraseData.isSeed = true;
      newPhraseData.isCurated = true;

      newTopicData["createdAt"] = localDateTime(new Date());
      newTopicData["frequency"] = 1;
      newTopicData["id"] = Math.floor(10000 + Math.random() * 9000);
      newTopicData["isCurated"] = true;
      newTopicData["isLoaded"] = true;
      newTopicData["isFolder"] = false;
      newTopicData["modifiedAt"] = localDateTime(new Date());
      newTopicData["name"] = title;
      newTopicData["parentId"] = selectedDiscoverdTopic.value;
      newTopicData["phrases"] = [newPhraseData];
      newTopicData["sentiment"] = sentiment;
      newTopicData["sentimentScore"] = 0;
      newTopicData["userId"] = userDetails.id;

      setTopics(topics.concat(newTopicData));
      setDataTopics(dataTopics.concat(newTopicData));
      setCuratedTopics((state) => state.concat(newTopicData.id));
      setAddedNewTopic(true);
      setShouldBlockNavigation(true);
    } catch (err) {
      console.log(err);
    }
  };

  const createNewTopicsFromSeedFile = (data) => {
    try {
      let newTopics = [];
      let duplicates = false;

      const userDetails = JSON.parse(localStorage.getItem("userDetails"));

      data.forEach((topic) => {
        if (
          !dataTopics.find(
            (t) => t.name.toLowerCase() === topic.Name.toLowerCase()
          )
        ) {
          let newTopic = {};
          newTopic["createdAt"] = localDateTime(new Date());
          newTopic["frequency"] = 0;
          newTopic["id"] = Math.floor(10000 + Math.random() * 9000);
          newTopic["isCurated"] = true;
          newTopic["isLoaded"] = true;
          newTopic["isFolder"] = false;
          newTopic["isSeedTopic"] = true;
          newTopic["modifiedAt"] = localDateTime(new Date());
          newTopic["name"] = topic.Name;
          newTopic["parentId"] = selectedDiscoverdTopic.value;
          newTopic["phrases"] = topic.Phrase.map((p) => ({
            char_sim: 0,
            context: [],
            frequency: 0,
            phraseId: Math.floor(10000 + Math.random() * 9000),
            phrase: p,
            pos_tags: [],
            sem_sim: 0,
            isSeed: true,
            isCurated: true,
          }));
          newTopic["emotion"] = topic.Emotion;
          newTopic["sentiment"] = topic.Sentiment;
          newTopic["sentimentScore"] = 0;
          newTopic["userId"] = userDetails.id;

          newTopics.push(newTopic);

          setCuratedTopics((state) => state.concat(newTopic.id));
        } else if (!duplicates) duplicates = true;
      });

      if (duplicates) {
        toast.warn("Some of the topics already exist.");
      }

      setTopics((topics) => topics.concat(newTopics));
      setDataTopics((dataTopics) => dataTopics.concat(newTopics));
      setAddedNewTopic(true);
      setShouldBlockNavigation(true);
    } catch (err) {
      console.log(err);
    }
  };

  const processSeedFile = (
    data,
    pipeline,
    threshold,
    wordLengthMax,
    wordLengthMin,
    phraseMinWords,
    phraseMaxWords,
    ignoreSentiment,
    tags,
    ngramFrequency,
    frequencyPercentile,
    semanticSimilarity
  ) => {
    try {
      const userEmail = get(
        JSON.parse(localStorage.getItem("userDetails")),
        "email"
      );

      //upload seed file
      let topicObj = cloneDeep(data).map((t) => {
        let ob = {};
        ob["Name"] = t.name;
        ob["Type"] = "";
        ob["S/E"] =  t.emotion ? t.emotion.toUpperCase() : (t.sentiment || "").toUpperCase();
        ob["Implemented_by"] = t.isCurated ? userEmail : "";
        ob["Specified_by"] = "";
        ob["Specification_Document"] = "";
        ob["Description"] = "";
        ob["Categories"] = "";
        ob["Phrase"] = t.phrases.map((p) => p.phrase.trim()).join("@#");
        return ob;
      });

      let cb = {};
      cb["Name"] = "_candidate";
      cb["Type"] = "";
      cb["S/E"] = "";
      cb["Implemented_by"] = "";
      cb["Specified_by"] = "";
      cb["Specification_Document"] = "";
      cb["Description"] = "";
      cb["Categories"] = "";
      cb["Phrase"] = cloneDeep(candidatePhrases)
        .map((c) => c.phrase.trim())
        .join("@#");

      const dataObj = topicObj.concat(cb);

      const seedFile = custSpecialObjToCSV(dataObj);
      const modifiedSeedFile = seedFile.replace(/@#/g, '","');
      const file = new File([modifiedSeedFile], "seedFile.csv", {
        type: "text/plain",
      });

      const brandDetails = JSON.parse(
        localStorage.getItem("defaultSelectedBrand")
      );
      const decooda1Data = new FormData();
      const decooda2Data = new FormData();
      if (pipeline === "Decooda1") {
        decooda1Data.append(
          "pipeline",
          JSON.stringify({ name: pipeline, steps: {} })
        );
        decooda1Data.append("user_topics", file);
        decooda1Data.append(
          "parameters",
          JSON.stringify({
            semantic_similarity: semanticSimilarity,
            frequency_percentile: frequencyPercentile,
            ngram_frequency: parseInt(ngramFrequency),
            word_length_min: parseInt(wordLengthMin),
            word_length_max: parseInt(wordLengthMax),
            ignore_sentiment_range: {
              min: ignoreSentiment[0],
              max: ignoreSentiment[1],
            },
            semantic_similarity_threshold: threshold,
          })
        );
        decooda1Data.append("brandId", brandDetails.id);
      }
      if (pipeline === "Decooda2") {
        decooda2Data.append(
          "pipeline",
          JSON.stringify({ name: pipeline, steps: {} })
        );
        decooda2Data.append("user_topics", file);
        decooda2Data.append(
          "parameters",
          JSON.stringify({
            semantic_similarity: semanticSimilarity,
            ngram_frequency: parseInt(ngramFrequency),
            word_length_min: parseInt(wordLengthMin),
            word_length_max: parseInt(wordLengthMax),
            phrase_min_words: parseInt(phraseMinWords),
            phrase_max_words: parseInt(phraseMaxWords),
            ignore_sentiment_range: {
              min: ignoreSentiment[0],
              max: ignoreSentiment[1],
            },
            semantic_similarity_threshold: threshold,
            pos_focus_tags: tags,
          })
        );
        decooda2Data.append("brandId", brandDetails.id);
      }

      const reqData = pipeline === "Decooda1" ? decooda1Data : decooda2Data;

      client
        .post(UPLOAD_ATD_SEED_FILE, reqData)
        .then((res) => {
          if (res.data.status === SUCCESS) {
            setAtdState("locked");
            localForage
              .getItem("atdData")
              .then((atdData) => {
                if (
                  atdData &&
                  atdData.find((d) => d.value === selectedDiscoverdTopic.value)
                ) {
                  localForage.setItem(
                    "atdData",
                    atdData.filter(
                      (d) => d.value !== selectedDiscoverdTopic.value
                    )
                  );
                }
              })
              .catch((err) => {
                console.log("LocalForage Error after Run ATD  ", err);
              });
            toast.update(toastId, {
              type: toast.TYPE.SUCCESS,
              render: "Submitted file successfully",
              autoClose: 3500,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.error(err);
    }
  };

  //convert data topics/phrases/candidates into Seed File
  const handleSeedFile = (
    pipeline,
    threshold,
    wordLengthMax,
    wordLengthMin,
    phraseMinWords,
    phraseMaxWords,
    ignoreSentiment,
    tags,
    ngramFrequency,
    frequencyPercentile,
    semanticSimilarity
  ) => {
    if (atdState === "locked") return;
    try {
      setUndoableEvent("CLEAR");
      toastId = toast("Processing seed file. Please wait...", {
        type: toast.TYPE.DEFAULT,
        autoClose: false,
      });

      client
        .get(GET_TOPICS, { params: { folderId: selectedDiscoverdTopic.value } })
        .then(async (res) => {
          if (res.data.status === SUCCESS) {
            const fetchedTopics = get(res.data.response, "topics");
            const loadedTopics = cloneDeep(dataTopics);

            const updatedTopics = await loadedTopics.map((t) => {
              const pp = fetchedTopics.find((ft) => ft.id === t.id);
              if (!t.isLoaded)
                t.phrases = uniqBy(t.phrases.concat(pp.phrases), "phraseId");
              return t;
            });

            processSeedFile(
              updatedTopics,
              pipeline,
              threshold,
              wordLengthMax,
              wordLengthMin,
              phraseMinWords,
              phraseMaxWords,
              ignoreSentiment,
              tags,
              ngramFrequency,
              frequencyPercentile,
              semanticSimilarity
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const processedEntity = (id, action) => {
    if (isNgram) handleProcessedEntity("ngram", id, action);
  };

  const saveTemporaryData = useCallback(() => {
    try {
      if (atdState === "locked") return;

      const selTopic = selectedDiscoverdTopicRef.current;
      const atdobj = {
        value: selTopic.value,
        label: selTopic.label,
        data: {
          topics: dataTopicsRef.current,
          candidates: candidatePhrasesRef.current,
          candidateCount: candidateTotalCount
            ? candidateTotalCount
            : summaryDataRef.current.noOfCandidatePhrases,
          summary: summaryDataRef.current,
          progress: progressDataRef.current,
          paletteData: topicPalettesRef.current[defaultBrand],
        },
        location: saveLocation,
      };

      localStorage.setItem("tempAtdData", JSON.stringify(atdobj));
    } catch (err) {
      console.log(err);
    }
  }, [candidateTotalCount, atdState, defaultBrand, saveLocation]);

  const moveTempDataToIndexedDB = (data) => {
    localForage
      .setItem("atdData", data)
      .then(() => {
        console.log("Saved Temp Data.");
        localStorage.removeItem("tempAtdData");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkStorageQuota = () => {
    try {
      navigator.storage
        .estimate()
        .then((estimate) => {
          const usage = Math.ceil((estimate.usage / estimate.quota) * 100);
          if (usage > 95 || estimate.quota < 5 * 1024 * 1024) {
            toast.warn(
              "Storage is almost full. You may lose some saved data. Please clear some storage."
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  // Handle New Phrases Modal
  const handleAddNewPhrasesModal = () => {
    setOpenNewPhrasesModal((state) => !state);
  };

  // Handle New Phrase Input change
  const handleNewPhrasesChange = (e) => {
    setNewPhrases(e.target.value);
  };

  // Add New Phrases to the Selected Topic
  const addNewPhrases = () => {
    try {
      setUndoableEvent("CLEAR");
      const selectedTopic = topics.find((t) => t.id === selectedTopics[0]);
      const string = newPhrases;
      const phraseArray = string
        .trim()
        .split(/\n/)
        .filter((p) => p);
      const formattedPhrases = phraseArray
        .filter((p) => !selectedTopic.phrases.find((tp) => tp.phrase === p))
        .map((fp) => ({
          char_sim: 0,
          context: [],
          frequency: 0,
          phraseId: Math.floor(1000000 + Math.random() * 900000),
          phrase: fp,
          pos_tags: [],
          sem_sim: 0,
          isSeed: true,
          isCurated: true,
        }));

      if (!phraseArray.length || !formattedPhrases.length) {
        if (phraseArray.length !== formattedPhrases.length)
          toast.warn("Some phrases already exist.");
        handleAddNewPhrasesModal();
        return;
      } else if (phraseArray.length !== formattedPhrases.length) {
        toast.warn("Some phrases already exist.");
      }

      const updateTopics = (topicsToBeUpdated) => {
        return cloneDeep(topicsToBeUpdated).map((t) => {
          if (t.id === selectedTopics[0]) {
            t.phrases = t.phrases.concat(formattedPhrases);
          }
          return t;
        });
      };

      setPhrases((state) => state.concat(formattedPhrases));
      setTotalPhrases((state) => state + formattedPhrases.length);
      setTopics((state) => updateTopics(state));
      setDataTopics((state) => updateTopics(state));
      toast.success("Phrases added successfully.");
      handleAddNewPhrasesModal();
    } catch (err) {
      console.log(err);
      toast.error("Could not add new phrases.");
      handleAddNewPhrasesModal();
    }
  };

  // Filter Topic Phrases
  const filterTopicalPhrases = (tPhrases) => {
    return isTopical && topicalPhraseType !== "all"
      ? tPhrases.filter((p) =>
          topicalPhraseType === "curated" ? p.isSeed : !p.isSeed
        )
      : tPhrases;
  };

  // Update Saved Data Location
  const updateSaveLocation = (value) => {
    dispatch(setSaveLocation(value));
  };

  const handleUndo = useCallback(() => {
    if (undoCallbacks.length) {
      const undoEvent = undoCallbacks[undoCallbacks.length - 1];
      setUndoCallbacks((state) => state.slice(0, undoCallbacks.length - 1));
      undoEvent.callback();

      if (undoEvent.eventName === "Topics Merged" && prevTopicPalettes) {
        dispatch(savePaletteData(prevTopicPalettes));
        setPrevTopicPalettes(null);
      }
    }
  }, [undoCallbacks, dispatch, prevTopicPalettes]);

  const handleUndoPopupClose = () => setUndoCallbacks(EMPTY_ARRAY);

  const updateTopicPaletteData = (topicId, event, newTopicName) => {
    let flag = false;
    const paletteData = loadashValues(
      topicPalettes[defaultBrand].palettes.byId
    );
    paletteData.forEach((pl) => {
      if (pl.topicList.includes(topicId)) {
        if (event === "merge") {
          setPrevTopicPalettes(cloneDeep(topicPalettes));
        }
        flag = true;
        return;
      }
    });

    if (flag) {
      if (event !== "rename") {
        const paletteIds = topicPalettes[defaultBrand].palettes.allIds;
        dispatch(deleteTopic(paletteIds, topicId, topicPalettes, defaultBrand));
      } else {
        const updatedPaletteData = cloneDeep(topicPalettes);
        updatedPaletteData[defaultBrand].topicLists.byId[topicId].name =
          newTopicName;
        dispatch(savePaletteData(updatedPaletteData));
      }
    }
  };

  const disableTopicReordering =
    !!searchTopic.length ||
    !!searchWord.length ||
    topicCurateType !== "all" ||
    !!selectedTopicSentiment;

  const handleTopicsReordering = (data) => {
    if (disableTopicReordering || dataTopics.length !== topics.length) return;

    const updatedTopics = cloneDeep(data);
    setTopics(updatedTopics);
    setDataTopics(updatedTopics);
  };

  const handleNgramTopicClick = (topicName, phraseTitle) => {
    const topicObj = topics.find(
      (t) => t.name.toLowerCase() === topicName.toLowerCase()
    );
    if (topicObj) {
      handleTopicClick(topicObj, false, phraseTitle);
    }
  };

  useEffect(() => {
    let mounted = true;

    if (mounted && !dataNotAvailable) {
      setTimeout(() => {
        calcDynamicHeight();
      }, 300);

      const MutationObserver =
        window.MutationObserver ||
        window.WebKitMutationObserver ||
        window.MozMutationObserver;

      const observer = new MutationObserver(function (mutations) {
        setTimeout(() => {
          calcDynamicHeight();
        }, 300);
      });
      const target = document.getElementById("topicsList");
      if (target) {
        observer.observe(target, {
          attributes: true,
        });
      }
    }

    if (window.ResizeObserver) {
      const divElem = document.getElementById("main");
      const resizeObserver = new ResizeObserver((entries) => {
        setTimeout(() => {
          calcDynamicHeight();
        }, 200);
      });
      resizeObserver.observe(divElem);
    }

    //Promt dialog when reload
    if (autoSave) {
      window.onbeforeunload = () => {
        if (autoSaveRef.current) {
          saveTemporaryData();
        }
        return undefined;
      };
    } else if (shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    // Set AutoSave Ref
    autoSaveRef.current = autoSave && shouldBlockNavigation;

    return function cleanup() {
      mounted = false;
    };
  }, [
    topics,
    selectedTopics,
    selectedTopicSentiment,
    selectedPhrases,
    autoSave,
    shouldBlockNavigation,
    unClassifiedPhrases,
    dataNotAvailable,
    saveTemporaryData,
  ]);

  useEffect(() => {
    dataTopicsRef.current = dataTopics;
    summaryDataRef.current = summaryData;
    candidatePhrasesRef.current = candidatePhrases;
    unClassifiedPhrasesRef.current = unClassifiedPhrases;
    progressDataRef.current = {
      topics: [...auditedTopics],
      phrases: [...phrasesToBeAudited],
    };
    selectedDiscoverdTopicRef.current = selectedDiscoverdTopic;
  }, [
    dataTopics,
    summaryData,
    candidatePhrases,
    unClassifiedPhrases,
    auditedTopics,
    phrasesToBeAudited,
    selectedDiscoverdTopic,
  ]);

  useEffect(() => {
    topicPalettesRef.current = topicPalettes;
  }, [topicPalettes]);

  useEffect(() => {
    document.body.classList.add("atd-screen");
    setTimeout(() => {
      getDiscoveredTopics();
    }, 400);

    setCuratedTopics(EMPTY_ARRAY);

    if (window.performance) {
      if (performance.navigation.type === 1) {
        localStorage.removeItem("newCandidates");
      }
    }

    checkStorageQuota();

    return () => {
      setDataNotAvailable(false);
      setTopicCurateType("all");
      setNgramData(EMPTY_ARRAY);
      setIsCandidate(false);
      setIsNgram(false);
      setIsunClassified(false);
      setGramWeight([2]);
      setPhrases(EMPTY_ARRAY);
      setSelectedPhrases(EMPTY_ARRAY);
      setSelectedTopics(EMPTY_ARRAY);
      document.body.classList.remove("atd-screen");
      localStorage.removeItem("newCandidates");
    };
  }, [defaultBrand]);

  useEffect(() => {
    return () => {
      if (autoSaveRef.current) {
        saveATDData();
      }
    };
  }, [defaultBrand, saveATDData]);

  useEffect(() => {
    dispatch(setTopicsData(dataTopics));
  }, [dataTopics, dispatch]);

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === "hidden") {
      if (autoSaveRef.current) {
        saveATDData();
      }
    }
  }, [saveATDData]);

  useEffect(() => {
    window.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  useEffect(() => {
    if (!paletteMinimized) {
      resizeSetup("dragMe", 8);
    } else {
      const paletteSection = document.querySelector(".paletteSection");
      paletteSection.style.width = "calc(25% - 8px)";
    }
  }, [paletteMinimized, defaultBrand]);

  useEffect(() => {
    const handleKeyUpEvent = (e) => {
      if (e.ctrlKey && e.key === "z") {
        handleUndo();
      }
    };
    document.addEventListener("keyup", handleKeyUpEvent);
    return () => {
      document.removeEventListener("keyup", handleKeyUpEvent);
    };
  }, [handleUndo]);

  useEffect(() => {
    const updatePhrasesAfterUndo = () => {
      let currentPhrases = [];
      if (isTopical) {
        const selectedTopic = topics.find((t) => t.id === selectedTopics[0]);
        if (selectedTopic) currentPhrases = selectedTopic.phrases;
        else {
          currentPhrases = topics.length ? topics[0].phrases : [];
          setSelectedTopics(topics.length ? [topics[0].id] : []);
        }
      } else {
        currentPhrases = isCandidate
          ? cloneDeep(candidatePhrases)
          : isNgram
          ? cloneDeep(getGramPhrases(gramWeight[0]))
          : cloneDeep(unClassifiedPhrases);
      }

      if (currentPhrases.length) {
        const alteredPhrases = doClientOperations(
          currentPhrases,
          phrasePattern,
          searchWord,
          sortBy,
          wordsBefore,
          wordsAfter,
          sortType
        );

        const updatedPhrases =
          searchWord.trim().length ||
          wordsBefore.trim().length ||
          wordsAfter.trim().length
            ? doClientMark(alteredPhrases, searchWord)
            : alteredPhrases;

        const filteredPhrases =
          isTopical && topicalPhraseType !== "all"
            ? updatedPhrases.filter((p) =>
                topicalPhraseType === "curated" ? p.isSeed : !p.isSeed
              )
            : updatedPhrases;

        setPhrases(cloneDeep(filteredPhrases));
        const selectedPhraseIds = filteredPhrases.length
          ? selectedPhrases.length
            ? filteredPhrases
                .filter((p) => selectedPhrases.includes(p.phraseId))
                .map((p) => p.phraseId)
            : filteredPhrases[0].phraseId
          : EMPTY_ARRAY;
        setSelectedPhrases(cloneDeep(selectedPhraseIds));
        setTotalPhrases(
          isunClassified && filteredPhrases.length
            ? filteredPhrases[0].context.length
            : filteredPhrases.length
        );
      }
    };

    if (undoEventProcessed) {
      setUndoEventProcessed(false);
      updatePhrasesAfterUndo();
    }
  }, [
    undoEventProcessed,
    candidatePhrases,
    doClientMark,
    getGramPhrases,
    gramWeight,
    isCandidate,
    isNgram,
    isunClassified,
    isTopical,
    phrasePattern,
    searchWord,
    selectedPhrases,
    selectedTopics,
    sortBy,
    sortType,
    topicalPhraseType,
    topics,
    unClassifiedPhrases,
    wordsAfter,
    wordsBefore,
  ]);

  useEffect(() => {
    if (undoableEvent) {
      switch (undoableEvent) {
        case "MERGE_TOPICS":
          setUndoCallbacks((state) =>
            state.concat({
              eventName: "Topics Merged",
              callback: () => {
                undoTopics();
                undoDataTopics();
                setUndoEventProcessed(true);
              },
            })
          );
          break;
        case "UPDATE_TOPIC_PHRASE":
          setUndoCallbacks((state) =>
            state.concat({
              eventName: "Phrase Updated",
              callback: () => {
                undoTopics();
                undoDataTopics();
                setUndoEventProcessed(true);
              },
            })
          );
          break;
        case "UPDATE_CANDIDATE_PHRASE":
          setUndoCallbacks((state) =>
            state.concat({
              eventName: "Phrase Updated",
              callback: () => {
                if (isCandidate) undoCandidatePhrases();
                else if (isunClassified) undoUnClassifiedPhrases();
                else {
                  undoTopics();
                  undoDataTopics();
                }
                setUndoEventProcessed(true);
              },
            })
          );
          break;
        case "TOPIC_PALETTE_UPDATE":
          setUndoCallbacks((state) =>
            state.concat({
              eventName: "Phrase Updated",
              callback: () => {
                undoTopics();
                undoDataTopics();
                if (isCandidate) undoCandidatePhrases();
                else if (isNgram) undoNgramData();
                else if (isunClassified) undoUnClassifiedPhrases();
                setUndoEventProcessed(true);
              },
            })
          );
          break;
        default:
          if (prevTopicPalettes) {
            setPrevTopicPalettes(null);
          }
          setUndoCallbacks(EMPTY_ARRAY);
          break;
      }
      setUndoableEvent("");
    }
  }, [
    isCandidate,
    isNgram,
    isunClassified,
    undoableEvent,
    prevTopicPalettes,
    undoTopics,
    undoDataTopics,
    undoUnClassifiedPhrases,
    undoCandidatePhrases,
    undoNgramData,
  ]);

  useEffect(() => {
    if (deletedSelectedTopic) {
      handleTopicClick(dataTopics[0], false);
      setDeletedSelectedTopic(false);
    }
  }, [deletedSelectedTopic, dataTopics, handleTopicClick]);

  useEffect(() => {
    localForage.config({
      name: "MC3",
    });
    return () => {
      dispatch(loadAtd(true));
    };
  }, []);

  const handleSelectedKey = (key) => {
    setSelectedKey(key);
  };

  const pathName = useLocation().pathname;

  //Topics Header
  const topic_menu = () => (
    <div className="topicMenu">
      <FormControl className="search-phrases">
        <InputLabel htmlFor="searchPhrases">Search documents</InputLabel>
        <Input
          id="searchPhrases"
          type="text"
          value={searchWord}
          onChange={(e) => {
            let va = e.target.value.replace(/\s+/g, " ");
            setSearchWord(va);
          }}
          onKeyUp={(e) => handlePhraseSearch(e.key)}
          endAdornment={
            <InputAdornment position="end">
              {isSearching ? (
                <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
              ) : searchWord.trim().length > 2 && searchActive ? (
                <table id="searchInfo">
                  <thead>
                    <tr>
                      <th>unclassified</th>
                      <th>|</th>
                      <th>total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{searchInfo.classified}</td>
                      <td>|</td>
                      <td>{searchInfo.total}</td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <div></div>
              )}
            </InputAdornment>
          }
        />
      </FormControl>
      <TextField
        type="text"
        className="search-topics"
        id="searchTopics"
        label="Filter topics"
        value={searchTopic}
        onChange={(e) => setSearchTopic(e.target.value)}
        onKeyUp={(e) => handleTopicSearch(e.key)}
      />

      <DropdownSort
        id="sort-topics"
        label="Sort By"
        value={topicSortBy}
        sortType={topicSortType}
        options={["date", "alphabet", "frequency", "magic"]}
        handleChange={handleTopicSorting}
      />

      <TopicSentiments
        id="topic-sentiment"
        label="Topic Sentiment"
        value={selectedTopicSentiment}
        options={["ALL", "NEGATIVE", "NEUTRAL", "POSITIVE"]}
        handleChange={handleTopicsFilter}
      />
    </div>
  );

  //Phrases Header
  const phrase_menu = () => (
    <div className="phraseMenu">
      <div
        className="new-topic"
        onDragOver={(event) => allowDrop(event)}
        onDrop={(event) => newTopicDrop(event)}
        onClick={handleCreateTopicModal}
      >
        New Topic
      </div>
      {!isCandidate && !isNgram && !isunClassified && !!selectedTopics.length && (
        <div className="new-topic" onClick={handleAddNewPhrasesModal}>
          Add Phrases
        </div>
      )}

      <div style={{ flexGrow: 1 }}>
        {isNgram ? (
          <DropDownNgrams
            label="Select gram"
            value={gramWeight}
            options={[1, 2, 3, 4, 5]}
            handleChange={handleNgramWeight}
          />
        ) : null}

        <TextField
          type="text"
          id="words-before"
          label="Words before"
          value={wordsBefore}
          onChange={(e) => handleWordChange(e.target.value, "before")}
          onBlur={(e) => handleWordPhrases()}
        />

        <TextField
          type="text"
          id="words-after"
          label="Words after"
          value={wordsAfter}
          onChange={(e) => handleWordChange(e.target.value, "after")}
          onBlur={(e) => handleWordPhrases()}
        />

        <DropdownSort
          id="sort-phrases"
          label="Sort By"
          value={sortBy}
          sortType={sortType}
          options={["alphabet", "frequency"]}
          handleChange={handlePhraseSorting}
        />

        <DropdownPhrasePattern
          id="phrase-pattern"
          label="Phrase Pattern"
          value={phrasePattern}
          favoritePatterns={favoritePatterns}
          updateFavPattern={(val) => {
            setFavoritePatterns(EMPTY_ARRAY.concat(val));
          }}
          handlePatternfilter={handlePatternfilter}
        />
      </div>
    </div>
  );

  return !defaultBrand ? (
    <div style={{ padding: "0px 12px" }}>
      <h1>Workbench</h1>
      <NoBrandSelected history={props.history} />
    </div>
  ) : dataNotAvailable ? (
    <div className="data-not-available">
      <p>
        ATD was not enabled for this brand. Rescore the brand with ATD enabled
        in order to use the workbench.
      </p>
    </div>
  ) : (
    <ErrorBoundary>
      <Prompt
        when={pathName === "/atd" && shouldBlockNavigation && !autoSave}
        message="You have unsaved changes, are you sure you want to leave?"
      />

      <div>
        {createTopicModal && (
          <CreateTopicModal
            open={createTopicModal}
            seedFileData={seedFileData}
            setSeedFileData={setSeedFileData}
            handleModal={handleCreateTopicModal}
            handleOk={createMultipleTopics}
          />
        )}

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={!!undoCallbacks.length}
          message={
            undoCallbacks.length &&
            undoCallbacks[undoCallbacks.length - 1].eventName
          }
          action={
            <>
              <Button color="primary" size="small" onClick={handleUndo}>
                UNDO
              </Button>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleUndoPopupClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />

        {openNewPhrasesModal && (
          <Dialog
            id="add-new-phrases-modal"
            open={openNewPhrasesModal}
            onClose={handleAddNewPhrasesModal}
            aria-labelledby="add-new-phrases"
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle className={classes.dialogTitle} id="add-new-phrases">
              Add New Phrases
            </DialogTitle>
            <DialogContent>
              <TextField
                id="new-phrases-textarea"
                variant="outlined"
                placeholder="Paste new phrases here"
                margin="dense"
                autoFocus
                fullWidth
                multiline
                rows={8}
                rowsMax={8}
                onChange={handleNewPhrasesChange}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="text"
                color="default"
                onClick={handleAddNewPhrasesModal}
              >
                Cancel
              </Button>
              <Button variant="text" color="primary" onClick={addNewPhrases}>
                Add
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {(loading || atdLoading) && <DataPreloader />}

        <Grid container spacing={1} style={{ marginBottom: "0px" }}>
          {summaryData && (
            <Grid item xs={12} lg={12} id="progress-bars-holder">
              <HeaderStat
                summaryData={summaryData}
                phrasesToBeAudited={phrasesToBeAudited}
                auditedTopics={auditedTopics}
              />
            </Grid>
          )}

          {atdState === "locked" ? (
            <div className="banner-alert">
              This brand is locked for background AI processing. Please check
              back in 15 minutes to edit data.
              <span
                onClick={(e) => {
                  e.persist();
                  //console.log(e)
                  e.target.parentNode.classList.add("invisible");
                }}
              >
                X
              </span>
            </div>
          ) : null}
          <Grid item xs={12} lg={12}>
            <div className="topics-holder" id="topicsHolder">
              <HeaderCard
                sectionHeading={`${
                  topicCurateType === "all" ? "" : topicCurateType + " "
                }Topics`}
                menu={topic_menu({
                  selectedTopicSentiment,
                  handleTopicsFilter,
                })}
                count={topics ? topics.length : 0}
                options={["uncurated", "curated", "seed", "non-seed", "all"]}
                handleOptions={handleOptions}
                selected={topicCurateType}
                atdState={atdState}
                selectedTopic={topics.find((e) => e.id === selectedTopics[0])}
                holder={"topics"}
              >
                <div className="topics-list" id="topicsList">
                  <TopicScroll
                    data={topics}
                    brandId={defaultBrand}
                    atdState={atdState}
                    selectedTopics={selectedTopics}
                    handleClick={handleTopicClick}
                    updateTopics={updateTopics}
                    handleSwapCuration={updateTopicCurated}
                    curateType={topicCurateType}
                    topicSentiment={selectedTopicSentiment}
                    handleMovedPhrases={handleMovedPhrases}
                    isCandidate={isCandidate}
                    selectedContext={selectedContext}
                    updateSelectedTopicPhrases={updateSelectedTopicPhrases}
                    addedNewTopic={addedNewTopic}
                    setAddedNewTopic={setAddedNewTopic}
                    setAuditedTopics={setAuditedTopics}
                    isunClassified={isunClassified}
                    isTopicPaletteHighlightActive={
                      isTopicPaletteHighlightActive
                    }
                    setShouldBlockNavigation={setShouldBlockNavigation}
                    handleTopicsReordering={handleTopicsReordering}
                    deletedTopic={deletedTopic}
                    setDeletedTopic={setDeletedTopic}
                    disableTopicReordering={disableTopicReordering}
                  />
                </div>
              </HeaderCard>
            </div>
          </Grid>
        </Grid>
        <div
          className={clsx(
            "phrase-section",
            !paletteMinimized ? classes.phrasesAndPaletteContainer : ""
          )}
        >
          <div
            className="phrases-holder"
            id="phrasesHolder"
            style={{
              height: calcHeight,
              width: paletteMinimized ? "100%" : "",
            }}
          >
            <HeaderCard
              sectionHeading={
                isCandidate
                  ? "Candidates"
                  : isNgram
                  ? "N-Grams"
                  : isunClassified
                  ? "unClassified"
                  : "Topic Phrases"
              }
              menu={phrase_menu()}
              options={["topical", "candidates", "n-grams", "unclassified"]}
              topicalOptions={["machine", "curated", "all"]}
              handleTopicalOptions={handleTopicalOptions}
              handleOptions={handleOptions}
              topicalPhraseType={topicalPhraseType}
              selected={
                isTopical
                  ? "topical"
                  : isCandidate
                  ? "candidates"
                  : isNgram
                  ? "n-grams"
                  : "unclassified"
              }
              atdState={atdState}
              count={totalPhrases}
              loadedItems={
                isunClassified
                  ? phrases[0]
                    ? phrases[0].context.length
                    : 0
                  : phrases.length
              }
              holder={"phrases"}
            >
              <PhraseScroll
                dataLoading={dataLoading}
                data={phrases}
                topics={topics}
                dataTopics={dataTopics}
                brandId={defaultBrand}
                summaryData={summaryData}
                totalPhrases={totalPhrases}
                loadedContextCount={loadedContextCount}
                selectedDiscoverdTopic={selectedDiscoverdTopic}
                atdState={atdState}
                selectedPhrases={selectedPhrases}
                selectedT={selectedTopics}
                handleClick={handlePhraseClick}
                updatePhrases={updatePhrases}
                newCandidatePhrase={newCandidatePhrase}
                handleAddText={handleAddText}
                updateHighlightedPhrase={updateHighlightedPhrase}
                isCandidate={isCandidate}
                isNgram={isNgram}
                isunClassified={isunClassified}
                gramWeight={gramWeight}
                handleSelectedContext={handleSelectedContext}
                searchWord={searchWord}
                addToCandidates={addToCandidates}
                markAsClassified={markAsClassified}
                handleNewTopic={handleNewTopic}
                removeHighlight={removeHighlight}
                loadMorePhrases={loadMorePhrases}
                updateTopicalContexts={updateTopicalContexts}
                updateRequestPage={updateRequestPage}
                updateAuditedPhrases={updateAuditedPhrases}
                processedEntity={processedEntity}
                selectedKey={selectedKey}
                handleSelectedKey={handleSelectedKey}
                handleSuggestion={handleSuggestion}
                handleNewTopicCreate={handleNewTopicCreate}
                handleMovedPhrases={handleMovedPhrases}
                handleNgramTopicClick={handleNgramTopicClick}
              />
            </HeaderCard>
          </div>

          {!paletteMinimized && (
            <div className="resizer" id="dragMe" style={{ height: calcHeight }}>
              <div className="resize-divider"></div>
              <span className="resize-hook"></span>
            </div>
          )}

          <div
            className={clsx(
              "paletteSection",
              paletteMinimized ? classes.paletteMinimized : ""
            )}
            style={{ height: calcHeight }}
          >
            <TopicPalette
              brandId={defaultBrand}
              atdState={atdState}
              selectedKey={selectedKey}
              selectedT={selectedTopics}
              minimized={paletteMinimized}
              setMinimized={setPaletteMinimized}
              handleSelectedKey={handleSelectedKey}
              setShouldBlockNavigation={setShouldBlockNavigation}
            />
          </div>
        </div>

        <AtdDialog
          open={open}
          handleDialogClose={handleDialogClose}
          handleDialogSave={createNewTopic}
          handleDialogDel={deleteHighlightedPhrase}
          folderId={selectedDiscoverdTopic.value}
          title={newTopicTitle}
          dialogData={dialogData}
        />

        <AtdDrawer
          brandId={defaultBrand}
          exportFileName={exportFileName}
          handleExportFileName={(value) => {
            setExportFileName(value);
          }}
          handleTopicExport={handleTopicExport}
          updateSave={saveATDData}
          updateRegEx={updateRegEx}
          seedingClassifer={seedingClassifer}
          seedingModel={seedingModel}
          handleSeedFile={handleSeedFile}
          autoSave={autoSave}
          handleAutoSave={(checked) => setAutoSave(checked)}
          thresholdValues={thresholdValues}
          updateThresholdValue={getThresholdValues}
          savingData={savingData}
          saveLocation={saveLocation}
          updateSaveLocation={updateSaveLocation}
        />
      </div>
    </ErrorBoundary>
  );
};

export default React.memo(ATD);

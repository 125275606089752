import { ACTIVATE_PALETTE, DEACTIVATE_PALETTE } from '../actions/types'
import get from 'lodash.get'

let initialState = false

export default function ActivePalette(state = initialState, action) {
  const active = get(action, 'active')

  switch (action.type) {
    case ACTIVATE_PALETTE : {
      return active
    }
    case DEACTIVATE_PALETTE : {
      return active
    }
    default: {
      return state;
    }
  }
}
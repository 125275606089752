import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Drawer,
  Paper,
  Switch,
  FormControlLabel,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import {
  ArrowLeft,
  ArrowRight,
  Save,
  Sync,
  GetApp,
  Share,
  AddBox,
  Cloud,
} from "@material-ui/icons";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import PipelineParams from "./pipeline-params";
import client from "../../../_helpers/client";
import { GET_PIPELINE_PARAMS } from "../../../Routes";
import { SUCCESS } from "../../../Constants";

const useStyles = makeStyles({
  root: {
    paddingLeft: "5px",
  },
  label: {
    paddingLeft: "20px !important",
  },
  folderNameInput: {
    marginTop: "12px",
  },
});

export default function AtdDrawer({
  brandId,
  exportFileName,
  handleExportFileName,
  handleTopicExport,
  updateSave,
  updateRegEx,
  seedingClassifer,
  seedingModel,
  handleSeedFile,
  autoSave,
  handleAutoSave,
  thresholdValues,
  savingData,
  saveLocation,
  updateSaveLocation,
}) {
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [pipeline, setPipeline] = React.useState("Decooda1");
  const [threshold, setThreshold] = React.useState(0);
  const [dialogOpen, setdialogOpen] = React.useState(false);
  const [dialogEvent, setdialogEvent] = React.useState(null);
  const [frequencyPercentile, setfrequencyPercentile] = React.useState(0.8);
  const [documentLimit, setDocumentLimit] =  React.useState(5000);
  const [tags, setTags] = React.useState(["NOUN", "VERB", "ADJ"]);
  const [wordLengthMax, setWordLengthMax] = React.useState(20);
  const [wordLengthMin, setWordLengthMin] = React.useState(4);
  const [ngramFrequency, setNgramFrequency] = React.useState(1);
  const [phraseMinWords, setPhraseMinWords] = React.useState(3);
  const [phraseMaxWords, setPhraseMaxWords] = React.useState(10);
  const [ignoreSentiment, setIgnoreSentiment] = React.useState([0, 0]);
  const [semanticSimilarity, setSemanticSimilarity] =
    React.useState("Word2Vec");
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [classifierType, setClassifierType] = React.useState("regular");
  const [folderName, setFolderName] = useState("");
  const [folderNameError, setFolderNameError] = useState(false);

  const fileNameInput = React.useRef(null);

  const classes = useStyles();

  const toggleDrawer = () => {
    if (showDrawer) setShowDrawer(false);
    else setShowDrawer(true);
  };

  const handleList = (option) => {
    switch (option) {
      case "Save": {
        updateSave();
        break;
      }
      case "Run ATD": {
        setIsDisabled(false);
        setdialogEvent("runAtd");
        setdialogOpen(true);
        break;
      }
      case "Create Classifier":
      case "Create Model": {
        const defaultBrand = JSON.parse(
          localStorage.getItem("defaultSelectedBrand")
        );
        setFolderName(defaultBrand ? defaultBrand.name : "");
        setdialogEvent(
          option === "Create Model" ? "createModel" : "createClassifier"
        );
        setdialogOpen(true);
        break;
      }
      case "Share": {
        console.log("sharing...");
        break;
      }
      case "Auto-Save":
        handleAutoSave(!autoSave);
        break;
      default: {
        break;
      }
    }
  };

  const handleClose = () => {
    setdialogEvent(null);
    setdialogOpen(false);
  };

  const handleThresholdPipelineValues = (val, param) => {
    if (param === "threshold") setThreshold(val);
    if (param === "pipeline") setPipeline(val);
  };

  const setDefaults = () => {
    setSemanticSimilarity("Word2Vec");
    setNgramFrequency(1);
    setWordLengthMax(20);
    setWordLengthMin(4);
    setIgnoreSentiment([0, 0]);
    setfrequencyPercentile(0.8);
    setTags(["NOUN", "VERB", "ADJ"]);
    setPhraseMaxWords(10);
    setPhraseMinWords(3);
  };

  const fetchATDParameters = () => {
    client
      .get(GET_PIPELINE_PARAMS, {
        params: {
          brandId,
        },
      })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          const params = res.data.response;
          setSemanticSimilarity(
            params.semanticSimilarity ? params.semanticSimilarity : "Word2Vec"
          );
          setNgramFrequency(
            params.hasOwnProperty("ngramFrequency") ? params.ngramFrequency : 1
          );
          setWordLengthMax(
            params.hasOwnProperty("wordLengthMax") ? params.wordLengthMax : 20
          );
          setWordLengthMin(
            params.hasOwnProperty("wordLengthMin") ? params.wordLengthMin : 4
          );
          setIgnoreSentiment([
            params.hasOwnProperty("ignoreSentimentMinRange")
              ? params.ignoreSentimentMaxRange
              : 0,
            params.hasOwnProperty("ignoreSentimentMaxRange")
              ? params.ignoreSentimentMaxRange
              : 0,
          ]);
          setfrequencyPercentile(
            params.hasOwnProperty("frequencyPercentile")
              ? params.frequencyPercentile
              : 0.8
          );
          setTags(
            params.hasOwnProperty("posFocusTags")
              ? params.posFocusTags
              : ["NOUN", "VERB", "ADJ"]
          );
          setPhraseMaxWords(
            params.hasOwnProperty("phraseMaxWords") ? params.phraseMaxWords : 10
          );
          setPhraseMinWords(
            params.hasOwnProperty("phraseMinWords") ? params.phraseMinWords : 3
          );
        } else {
          setDefaults();
        }
      })
      .catch((error) => {
        console.log(error);
        setDefaults();
      });
  };

  const handleClassiferChange = (e) => {
    setClassifierType(e.target.value);
  };

  const handleSubmit = () => {
    if (dialogEvent !== "runAtd") {
      if (!folderName) {
        setFolderNameError(true);
        return;
      }
      updateRegEx(classifierType, folderName, dialogEvent);
    } else {
      handleSeedFile(
        pipeline,
        threshold,
        wordLengthMax,
        wordLengthMin,
        phraseMinWords,
        phraseMaxWords,
        ignoreSentiment,
        tags,
        ngramFrequency,
        frequencyPercentile,
        semanticSimilarity
      );
    }
    handleClose();
  };

  useEffect(() => {
    const currentPipeline = thresholdValues.find(
      (e) => e.parameter === "pipeline"
    );
    if (currentPipeline) setPipeline(currentPipeline.value);

    const currentThreshold = thresholdValues.find(
      (e) => e.parameter === "threshold"
    );
    if (currentThreshold) setThreshold(currentThreshold.value);
  }, [thresholdValues]);

  useEffect(() => {
    fetchATDParameters();
  }, [brandId]);

  return (
    <div
      className={
        showDrawer
          ? "fixed-position Workbench-drawer active"
          : "fixed-position Workbench-drawer"
      }
    >
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={dialogOpen}
        fullWidth
        maxWidth={"xs"}
      >
        {dialogEvent === "runAtd" ? (
          <DialogTitle id="pipeline-dialog">
            Pipeline parameters Configuration
          </DialogTitle>
        ) : null}
        <DialogContent>
          {dialogEvent === "runAtd" ? (
            <PipelineParams
              pipeline={pipeline}
              threshold={threshold}
              isDisabled={isDisabled}
              frequencyPercentile={frequencyPercentile}
              setfrequencyPercentile={setfrequencyPercentile}
              documentLimit = {documentLimit}
              setDocumentLimit={setDocumentLimit}
              semanticSimilarity={semanticSimilarity}
              setSemanticSimilarity={setSemanticSimilarity}
              tags={tags}
              setTags={setTags}
              phraseMinWords={phraseMinWords}
              setPhraseMinWords={setPhraseMinWords}
              phraseMaxWords={phraseMaxWords}
              setPhraseMaxWords={setPhraseMaxWords}
              ngramFrequency={ngramFrequency}
              setNgramFrequency={setNgramFrequency}
              wordLengthMin={wordLengthMin}
              setWordLengthMin={setWordLengthMin}
              wordLengthMax={wordLengthMax}
              setWordLengthMax={setWordLengthMax}
              ignoreSentiment={ignoreSentiment}
              setIgnoreSentiment={(e, newVal) => {
                setIgnoreSentiment(newVal);
              }}
              handleThresholdPipelineValues={handleThresholdPipelineValues}
            />
          ) : (
            <>
              <RadioGroup
                row
                aria-label="classifierType"
                name="classifierType"
                value={classifierType}
                onChange={handleClassiferChange}
              >
                <FormControlLabel
                  value="regular"
                  control={<Radio color="primary" />}
                  label="Create Regex Classifiers"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="standard"
                  control={<Radio color="primary" />}
                  label="Create Standard Classifiers"
                  labelPlacement="end"
                />
              </RadioGroup>
              <TextField
                className={classes.folderNameInput}
                id="folder-name"
                label="Folder Name"
                variant="outlined"
                margin="dense"
                value={folderName}
                onChange={(e) => {
                  setFolderName(e.target.value);
                  setFolderNameError(false);
                }}
                helperText={folderNameError ? "* Required" : ""}
                error={folderNameError}
                autoFocus
                fullWidth
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {isDisabled ? "Close" : "Cancel"}
          </Button>
          {isDisabled ? null : (
            <Button color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <div>
        <IconButton onClick={toggleDrawer}>
          {showDrawer ? <ArrowRight /> : <ArrowLeft />}
        </IconButton>
      </div>
      <Drawer
        anchor={"right"}
        open={showDrawer}
        onClose={() => toggleDrawer(false)}
      >
        <div
          role="presentation"
          style={{ backgroundColor: "#f2f2f2", height: "100vh" }}
          onClick={(e) => {
            e.preventDefault();
            //toggleDrawer(false)
          }}
        >
          <List>
            {[
              "Save Location",
              "Auto-Save",
              "Save",
              "Run ATD",
              "Create Classifier",
              "Create Model",
              "Share",
              "Export",
            ].map((text, index) => (
              <ListItem
                button={
                  text === "Export" || text === "Save Location" ? false : true
                }
                key={text}
                onClick={() => {
                  handleList(text);
                }}
                className={text === "Share" ? "hiddenShare" : ""}
                disabled={
                  text === "Create Classifier"
                    ? seedingClassifer
                    : text === "Create Model"
                    ? seedingModel
                    : text === "Save"
                    ? savingData
                    : false
                }
              >
                {text !== "Auto-Save" && (
                  <ListItemIcon>
                    {text === "Save" ? (
                      <Save />
                    ) : text === "Run ATD" ? (
                      <Sync />
                    ) : text === "Export" ? (
                      <GetApp />
                    ) : text === "Create Classifier" ||
                      text === "Create Model" ? (
                      <AddBox />
                    ) : text === "Save Location" ? (
                      <Cloud />
                    ) : (
                      <Share />
                    )}
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={
                    text === "Export" ? (
                      <div className="export-atd">
                        <FormControl variant="outlined">
                          <InputLabel htmlFor="export-fileName">
                            File Name
                          </InputLabel>
                          <OutlinedInput
                            id="export-fileName"
                            type="text"
                            ref={fileNameInput}
                            value={exportFileName}
                            onChange={(e) => {
                              handleExportFileName(e.target.value);
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    if (!exportFileName.trim().length) {
                                      toast.warn(
                                        "Please provide file name to export"
                                      );
                                      fileNameInput.current.children[0].focus();
                                      return;
                                    }
                                    handleTopicExport();
                                  }}
                                >
                                  Export
                                </Button>
                              </InputAdornment>
                            }
                            labelWidth={70}
                          />
                        </FormControl>
                      </div>
                    ) : (text === "Create Classifier" && seedingClassifer) ||
                      (text === "Create Model" && seedingModel) ? (
                      "processing..."
                    ) : text === "Auto-Save" ? (
                      <FormControlLabel
                        control={<Switch size="small" checked={autoSave} />}
                        label="Auto-Save"
                        classes={{ root: classes.root, label: classes.label }}
                      />
                    ) : text === "Save Location" ? (
                      <FormControl fullWidth>
                        <InputLabel id="save-location-lable">
                          Save Location
                        </InputLabel>
                        <Select
                          labelId="save-location-lable"
                          id="save-location-select"
                          value={saveLocation}
                          onChange={(e) => updateSaveLocation(e.target.value)}
                        >
                          <MenuItem value="local">Local</MenuItem>
                          <MenuItem value="cloud">Cloud</MenuItem>
                        </Select>
                      </FormControl>
                    ) : (
                      text
                    )
                  }
                />
              </ListItem>
            ))}
          </List>
          <div className="thresholdValues">
            <TableContainer component={Paper}>
              <Table aria-label="threshold table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "60%" }}>AI Parameter</TableCell>
                    <TableCell align="right">Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {thresholdValues.map((row, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {row.parameter}
                      </TableCell>
                      <TableCell align="right" className="inputRow">
                        {row.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              className="see-more"
              onClick={() => {
                setdialogOpen(true);
                setdialogEvent("runAtd");
                setIsDisabled(true);
              }}
            >
              see more
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

import "./BrandComparison.css";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Switch,
} from "@material-ui/core";
import {
  EMPTY_ARRAY,
  SUCCESS,
  WARN,
  COMP_TOPICS_DATA,
  COMP_PURCHASE_DATA,
  COMP_PERFORMANCE_DATA,
  COMP_PERSONA_DATA,
  COMP_EMOTION_DATA,
  COMP_SENTIMENT_DATA
} from "../../Constants";
import {
  BRAND_COMPARISON_HEADER,
  POPULATE_COMPARISON_CACHE_SELECTEDBRAND,
  PROPORTIONAL_VIEW,
  DISPLAY_SENTIMENT_CHART,
  DISPLAY_EMOTION_CHART,
  DISPLAY_PERSONAS_CHART,
  DISPLAY_PERFORMANCE_CHART,
  DISPLAY_PURCHASEPATH_CHART,
  DISPLAY_TOPICS_CHART,
} from "../../Routes";

import client from "../../_helpers/client";
import brandImg from "../../assets/brand-images/default-image.png";
import DataPreloader from "../../containers/Preloader/DataPreloader";
import RenderChart from "./Chart/Chart";
import BrandsList from "./BrandsList/BrandsList";

const BrandComparison = (props) => {
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [brands, setBrands] = useState(EMPTY_ARRAY);
  const [selectedBrandId, setSelectedBrandId] = useState(EMPTY_ARRAY);
  const [isProportional, setIsProportional] = useState(false);

  const [topicsPreloader, setTopicsPreloader] = useState(false);
  const [purchasePreloader, setPurchasePreloader] = useState(false);
  const [performancePreloader, setPerformancePreloader] = useState(false);
  const [personaPreloader, setPersonaPreloader] = useState(false);
  const [emotionPreloader, setEmotionPreloader] = useState(false);
  const [sentimentPreloader, setSentimentPreloader] = useState(false);

  const [topicsData, setTopicsData] = useState(COMP_TOPICS_DATA);
  const [purchaseData, setPurchaseData] = useState(COMP_PURCHASE_DATA);
  const [performanceData, setPerformanceData] = useState(COMP_PERFORMANCE_DATA);
  const [personaData, setPersonaData] = useState(COMP_PERSONA_DATA);
  const [emotionData, setEmotionData] = useState(COMP_EMOTION_DATA);
  const [sentimentData, setSentimentData] = useState(COMP_SENTIMENT_DATA);

  //Get Initial Selected Brands
  const getBrandComparisonHeader = () => {
    client
      .get(BRAND_COMPARISON_HEADER)
      .then((res) => {
        if (res.data.status === SUCCESS) {
          updateValues(res.data.response);
          setLoading(false);
        } else {
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  //On Brand Select
  const updateSelectedBrands = (e, id) => {
    let val = e.target.checked;
    const modifiedList = selectedBrandId.filter((el) => el !== id);

    client
      .post(POPULATE_COMPARISON_CACHE_SELECTEDBRAND, { brandIds: modifiedList })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          if (val) {
            let updated = EMPTY_ARRAY;
            updated = selectedBrandId.slice().concat(id);
            setSelectedBrandId(updated);
          } else {
            if (selectedBrandId.length <= 2) {
              toast.warn("Atleast two brands are needed for comparison.");
              return;
            }
            let updated = EMPTY_ARRAY;
            updated = selectedBrandId.filter((el) => el !== id);
            setSelectedBrandId(updated);
          }
          loadCharts();
        } else {
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //On change proportional view
  const handleProportionalChange = (e) => {
    setLoading(true);
    let val = e.target.checked;
    client
      .get(PROPORTIONAL_VIEW, { params: { isProportional: val } })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          setIsProportional(val);
          loadCharts();
          setLoading(false);
        } else {
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  //Get Sentiment Chart
  const getSentimentChart = () => {
    setSentimentPreloader(true);
    client
      .get(DISPLAY_SENTIMENT_CHART)
      .then((res) => {
        setSentimentData(res.data.response);
        setSentimentPreloader(false);
      })
      .catch((error) => {
        console.log(error);
        setSentimentPreloader(false);
      });
  };

  //Get Emotion Chart
  const getEmotionChart = () => {
    setEmotionPreloader(true);
    client
      .get(DISPLAY_EMOTION_CHART)
      .then((res) => {
        setEmotionData(res.data.response);
        setEmotionPreloader(false);
      })
      .catch((error) => {
        console.log(error);
        setEmotionPreloader(false);
      });
  };

  //Get Personas Chart
  const getPersonasChart = () => {
    setPersonaPreloader(true);
    client
      .get(DISPLAY_PERSONAS_CHART)
      .then((res) => {
        setPersonaData(res.data.response);
        setPersonaPreloader(false);
      })
      .catch((error) => {
        console.log(error);
        setPersonaPreloader(false);
      });
  };

  //Get Performance Chart
  const getPerformanceChart = () => {
    setPerformancePreloader(true);
    client
      .get(DISPLAY_PERFORMANCE_CHART)
      .then((res) => {
        setPerformanceData(res.data.response);
        setPerformancePreloader(false);
      })
      .catch((error) => {
        console.log(error);
        setPerformancePreloader(false);
      });
  };

  //Get Purchase Path Chart
  const getPurchasePathChart = () => {
    setPurchasePreloader(true);
    client
      .get(DISPLAY_PURCHASEPATH_CHART)
      .then((res) => {
        setPurchaseData(res.data.response);
        setPurchasePreloader(false);
      })
      .catch((error) => {
        console.log(error);
        setPurchasePreloader(false);
      });
  };

  //Get Topics Chart
  const getTopicsChart = () => {
    setTopicsPreloader(true);
    client
      .get(DISPLAY_TOPICS_CHART)
      .then((res) => {
        setTopicsData(res.data.response);
        setTopicsPreloader(false);
      })
      .catch((error) => {
        console.log(error);
        setTopicsPreloader(false);
      });
  };

  const loadCharts = () => {
    getSentimentChart();
    getEmotionChart();
    getPersonasChart();
    getPerformanceChart();
    getPurchasePathChart();
    getTopicsChart();
  };

  const updateValues = (data) => {
    setBrands(data.brands);
    setSelectedBrandId(data.selectedBrandId);
    setIsProportional(data.isProportional);
    if (data.brands.length > 1) loadCharts();
  };

  const isChecked = (id) => {
    let check = selectedBrandId.findIndex(
      (el) => parseInt(el) === parseInt(id)
    );
    if (check !== -1) return true;
    else return false;
  };

  useEffect(() => {
    getBrandComparisonHeader();
  }, []);

  return (
    <React.Fragment>
      <div className="contentBody brandComparisonView">
        {loading && <DataPreloader />}
        <BrandsList
          dialogOpen={openDialog}
          handleDialogClose={(val) => setOpenDialog(val)}
          updateValues={(d) => updateValues(d)}
          selectedBrandId={selectedBrandId}
        />
        {/* Page Heading & Breadcrumb */}
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12}>
            <h1>Brand Comparison</h1>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenDialog(true)}
            >
              Select Brands
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} className="rightSide">
            {brands.length !== 0 && (
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={isProportional}
                      onChange={handleProportionalChange}
                    />
                  }
                  label="Proportinal View"
                  labelPlacement="start"
                />
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className="brands-comp-div-header">
              {brands.length === 0 ? (
                <p className="no-brands-selected">
                  Please select brands for Comparison.
                </p>
              ) : (
                brands.map((el, i) => {
                  const checked = isChecked(el.id);
                  return (
                    <div key={i} className="selectedList">
                      <img src={brandImg} alt={el.name} />
                      <br />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked}
                            name={el.name}
                            onChange={(e) => updateSelectedBrands(e, el.id)}
                          />
                        }
                        label={el.name}
                      />
                    </div>
                  );
                })
              )}
            </div>
          </Grid>
        </Grid>

        {brands.length > 0 && (
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12}>
              <div id="brandCompareContent" className="brandCompareContent">
                <Grid container spacing={3}>
                  {/* topic comparison chart */}
                  <Grid item xs={12} sm={6} md={4}>
                    <div className="chartGrid">
                      <RenderChart
                        loading={topicsPreloader}
                        chartData={topicsData}
                      />
                    </div>
                  </Grid>

                  {/* purchase comparison chart */}
                  <Grid item xs={12} sm={6} md={4}>
                    <div className="chartGrid">
                      <RenderChart
                        loading={purchasePreloader}
                        chartData={purchaseData}
                      />
                    </div>
                  </Grid>

                  {/* performance comparison chart */}
                  <Grid item xs={12} sm={6} md={4}>
                    <div className="chartGrid">
                      <RenderChart
                        loading={performancePreloader}
                        chartData={performanceData}
                      />
                    </div>
                  </Grid>

                  {/* persona comparison chart */}
                  <Grid item xs={12} sm={6} md={4}>
                    <div className="chartGrid">
                      <RenderChart
                        loading={personaPreloader}
                        chartData={personaData}
                      />
                    </div>
                  </Grid>

                  {/* emotion comparison chart */}
                  <Grid item xs={12} sm={6} md={4}>
                    <div className="chartGrid">
                      <RenderChart
                        loading={emotionPreloader}
                        chartData={emotionData}
                      />
                    </div>
                  </Grid>

                  {/* sentiment comparison chart */}
                  <Grid item xs={12} sm={6} md={4}>
                    <div className="chartGrid">
                      <RenderChart
                        loading={sentimentPreloader}
                        chartData={sentimentData}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    </React.Fragment>
  );
};

export default BrandComparison;

import React, { Component, Fragment } from "react";
import {
  TextField,
  Button,
  FormControl,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import {
  SentimentVeryDissatisfied,
  SentimentSatisfiedAlt,
  SentimentSatisfied,
} from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Search, AddCircle } from "@material-ui/icons";
import "./autoComplete.scss";

class Autocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: "",
      showNewTopic: false,
      topicTitle: "",
      topicSentiment: "",
    };
  }

  onChange = (e) => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;

    const filteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: e.currentTarget.value.trim().length >= 3 ? true : false,
      userInput: e.currentTarget.value,
    });
  };

  onClick = (e, obj) => {
    e.persist();
    const node = [...document.getElementsByClassName("selectedHighlight")];
    if (obj.name === "create new" && obj.id === 0) {
      this.setState({
        showNewTopic: true,
        showSuggestions: false,
      });
    } else {
      this.setState({
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
        userInput: "",
      });
      setTimeout(() => {
        this.props.handleSuggestion(obj, node[0]);
      }, 400);
    }
  };

  onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion],
      });
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  handleTopicTitle = (e) => {
    this.setState({ topicTitle: e.target.value });
  };

  handleNewTopic = () => {
    const node = [...document.getElementsByClassName("selectedHighlight")];
    const { topicTitle, topicSentiment} = this.state;
    this.props.handleNewTopic(topicTitle, topicSentiment, node[0].innerText);
  }

  handleClose=()=>{
    this.setState({
      showNewTopic: false,
      showSuggestions: true,
    });
  }

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      handleTopicTitle,
      handleNewTopic,
      handleClose,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput,
        showNewTopic,
        topicTitle,
        topicSentiment,
      },
    } = this;

    const { filterLimit } = this.props;

    let suggestionsListComponent, newTopicComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            <li
              className="newList"
              key={"newList"}
              onClick={(e) => onClick(e, { name: "create new", id: 0 })}
            >
              <AddCircle />
              &nbsp;<span>Create New Topic</span>
            </li>
            {filteredSuggestions
              .slice(0, filterLimit)
              .map((suggestion, index) => {
                let className;

                // Flag the active suggestion with a class
                if (index === activeSuggestion) {
                  className = "suggestion-active";
                }
                return (
                  <li
                    className={className}
                    key={suggestion.id}
                    onClick={(e) => onClick(e, suggestion)}
                  >
                    {suggestion.name}
                  </li>
                );
              })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <ul className="suggestions">
            <li
              className="newList"
              key={"newList"}
              onClick={(e) => onClick(e, { name: "create new", id: 0 })}
            >
              <AddCircle />
              &nbsp;<span>Create New Topic</span>
            </li>
          </ul>
        );
      }
    }

    if (showNewTopic && userInput) {
      newTopicComponent = (
        <ul className="newTopicPopup">
          <li>
            <input
              placeholder="Topic Title"
              value={topicTitle}
              onChange={handleTopicTitle}
            />
          </li>
          <li className="sentimentIcons">
            <FormControl>
              <RadioGroup
                row
                value={topicTitle ? topicSentiment : ""}
                onChange={(e) => {
                  this.setState({ topicSentiment: e.target.value });
                }}
              >
                <Radio
                  value="NEGATIVE"
                  icon={<SentimentVeryDissatisfied className="iconBtn" />}
                  checkedIcon={
                    <SentimentVeryDissatisfied className="iconBtn negative" />
                  }
                  disabled={!topicTitle}
                  disableRipple
                />
                <Radio
                  value="NEUTRAL"
                  icon={<SentimentSatisfiedAlt className="iconBtn" />}
                  checkedIcon={
                    <SentimentSatisfiedAlt className="iconBtn neutral" />
                  }
                  disabled={!topicTitle}
                  disableRipple
                />
                <Radio
                  value="POSITIVE"
                  icon={<SentimentSatisfied className="iconBtn" />}
                  checkedIcon={
                    <SentimentSatisfied className="iconBtn positive" />
                  }
                  disabled={!topicTitle}
                  disableRipple
                />
              </RadioGroup>
            </FormControl>
          </li>
          <li style={{ display: "flex", padding: "0px" }}>
            <Button onClick={handleNewTopic} disabled={!topicTitle || !topicSentiment}>Create</Button>
            <Button onClick={handleClose}>Cancel</Button>
          </li>
        </ul>
      );
    }

    return (
      <Fragment>
        <TextField
          variant="standard"
          InputProps={{
            type: "search",
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          style={{ width: "200px" }}
          placeholder="search for topics"
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        {showNewTopic ? newTopicComponent : suggestionsListComponent}
      </Fragment>
    );
  }
}

export default Autocomplete;

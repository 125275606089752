import React, { useState, useEffect } from 'react'
import { POSITIVE, NEGATIVE } from '../../constants'
import styles from "./ToggleSwitch.module.css"

const RED = "#f53d2c"
const GREEN = "#18cb00"

const ToggleSwitch = props => {
  const [switchStyle, setSwitchStyle] = useState(true);

  useEffect(() => {
    if(props.sentiment === "positive") {
      setSwitchStyle(true)
    } else {
      setSwitchStyle(false)
    }
  }, [])

  const updateToggleSwitch = () => {
    const checkBox = document.getElementById("toggle");
    if(checkBox.checked) {
      setSwitchStyle(false)
      props.toggleSwitch(NEGATIVE)
      props.toggleSentiment(NEGATIVE)
    } else {
      setSwitchStyle(true)
      props.toggleSwitch(POSITIVE)
      props.toggleSentiment(POSITIVE)
    }
  }
  return (
    <div className={styles.sentimentToggle}>
    <div className={styles.Switch}>
      <span className={switchStyle ? styles.positive_active : styles.positive}>POSITIVE</span>
      <div className={styles.toggleSwitch}>
        <input type="checkbox" id="toggle" className={styles.checkbox} onClick={updateToggleSwitch} />
        <label htmlFor="toggle" className={styles.switch}></label>
      </div>
      <span className={switchStyle ? styles.negative : styles.negative_active}>NEGATIVE</span>
    </div>
    </div>
  );
}

export default ToggleSwitch;

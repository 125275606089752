import React, {lazy, Suspense} from "react";
import {Badge, Icon, IconButton, Tooltip, withStyles, Zoom} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const Filter = lazy(() => import("./Filter"));

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        minHeight: '240px',
        maxHeight: '300px',
        position: 'relative'
    },
}));

const FilterWrapper = (props) => {
    const classes = useStyles();
    const { showFilter, hasFilter, handleFilterOpen, handleFilterClose } = props;
    return (
        <>
            <div className="filterHolder">
                <Tooltip title="Filter" TransitionComponent={
                    Zoom
                }>
                    <IconButton
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="Filter"
                        onClick={handleFilterOpen}
                        data-testid="filterIcon"
                    >
                        {hasFilter ? <Badge
                            badgeContent="*"
                            color="error"
                        >
                            <Icon>filter_list</Icon>
                        </Badge> : <Icon>filter_list</Icon>
                        }
                    </IconButton>
                </Tooltip>
            </div>

            {showFilter ? (
                <Suspense fallback={null}>
                    <Filter handleFilterClose={handleFilterClose}/>
                </Suspense>
            ) : null}
        </>
    );
}

export default FilterWrapper

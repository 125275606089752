import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import Slider from "@material-ui/core/Slider";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Info } from "@material-ui/icons";

const PrettoSlider = withStyles({
  root: {
    color: "#2196F3",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const pos_tags = ["NOUN", "VERB", "ADJ"];
const SEMANTIC_SIMILARITY_OPTIONS = ["Word2Vec", "Doc2Vec", "FastText"];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  slider: {
    marginTop: theme.spacing(1),
  },
}));

const PipelineParams = ({
  pipeline,
  threshold,
  semanticSimilarity,
  frequencyPercentile,
  tags,
  phraseMinWords,
  phraseMaxWords,
  ignoreSentiment,
  ngramFrequency,
  wordLengthMin,
  wordLengthMax,
  setSemanticSimilarity,
  setfrequencyPercentile,
  documentLimit,
  setDocumentLimit,
  setTags,
  setPhraseMinWords,
  setPhraseMaxWords,
  setIgnoreSentiment,
  setNgramFrequency,
  setWordLengthMin,
  setWordLengthMax,
  isDisabled,
  handleThresholdPipelineValues,
  topicDiscoveryOption,
}) => {
  const clasess = useStyles();

  useEffect(() => {
    if (pipeline === "Decooda2" && semanticSimilarity === "Wu-Palmer") {
      setSemanticSimilarity("Word2Vec");
    }
  }, [pipeline, semanticSimilarity, setSemanticSimilarity]);

  return (
    <>
      {isDisabled ||
      (topicDiscoveryOption && topicDiscoveryOption !== "blank") ? null : (
        <>
          <FormControl className={clasess.formControl}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={7} className="display-flex">
                <span className="spanLabel">pipeline</span>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <Select
                  labelId="atd-decooda-pipeline-label"
                  id="atd-decooda-pipeline"
                  defaultValue={pipeline}
                  value={pipeline}
                  fullWidth
                  onChange={(e) => {
                    handleThresholdPipelineValues(e.target.value, "pipeline");
                  }}
                >
                  <MenuItem value="Decooda1">Decooda 1</MenuItem>
                  <MenuItem value="Decooda2">Decooda 2</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </FormControl>
          {topicDiscoveryOption !== "blank" && (
            <FormControl className={clasess.formControl}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={7} className="display-flex">
                  <span className="spanLabel">threshold</span>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Input
                    disabled={isDisabled}
                    id="threshold"
                    value={threshold}
                    type="number"
                    fullWidth
                    inputProps={{
                      min: 0,
                      max: 1,
                      step: 0.1,
                    }}
                    onChange={(e) => {
                      const floatValue = parseFloat(e.target.value);
                      if (
                        !Number.isNaN(floatValue) &&
                        floatValue <= 1 &&
                        floatValue >= 0
                      )
                        handleThresholdPipelineValues(floatValue, "threshold");
                    }}
                  />
                </Grid>
              </Grid>
            </FormControl>
          )}
        </>
      )}
      {topicDiscoveryOption !== "blank" && (
        <div>
          <FormControl className={clasess.formControl}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={7} className="display-flex">
                <span className="spanLabel">semantic similarity</span>
                <Tooltip
                  title="Method used to determine semantic similarity between phrases and topics."
                  placement="top"
                >
                  <Info className="text-blue" />
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <Select
                  id="semantic-similarity"
                  value={semanticSimilarity}
                  defaultValue={semanticSimilarity}
                  MenuProps={MenuProps}
                  input={<Input />}
                  onChange={(e) => setSemanticSimilarity(e.target.value)}
                  disabled={true}
                  data-testid="semantic-similarity-select"
                >
                  {SEMANTIC_SIMILARITY_OPTIONS.concat(
                    pipeline === "Decooda1" ? ["Wu-Palmer"] : []
                  ).map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </FormControl>
        </div>
      )}
      {pipeline === "Decooda1" ? (
        <div>
          <FormControl
            className={[clasess.formControl, clasess.slider].join(" ")}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={7} className="display-flex">
                <span className="spanLabel">frequency percentile</span>
                <Tooltip
                  title="Ngram frequency percentage threshold for topic phrases."
                  placement="top"
                >
                  <Info className="text-blue" />
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <PrettoSlider
                  disabled={isDisabled}
                  step={0.1}
                  min={0}
                  max={1}
                  valueLabelDisplay="auto"
                  aria-label="frequency_percentile"
                  value={frequencyPercentile}
                  onChange={(e, val) =>
                    setfrequencyPercentile(Number(val.toFixed(2)))
                  }
                />
              </Grid>
            </Grid>
          </FormControl>
        </div>
      ) : (
        <div>
          <FormControl className={clasess.formControl}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={7} className="display-flex">
                <span className="spanLabel">pos_focus_tags</span>
                <Tooltip
                  title="List of POS tags that phrases focus around."
                  placement="top"
                >
                  <Info className="text-blue" />
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <Select
                  disabled={isDisabled}
                  id="atd-decooda-pipeline"
                  multiple
                  value={tags}
                  MenuProps={MenuProps}
                  input={<Input />}
                  onChange={(e) => setTags(e.target.value)}
                  data-testid="pos-tags-select"
                >
                  {pos_tags.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </FormControl>
          <FormControl className={clasess.formControl}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={7} className="display-flex">
                <span className="spanLabel">phrase min words</span>
                <Tooltip
                  title="Minimum number of words in a phrase."
                  placement="top"
                >
                  <Info className="text-blue" />
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <Input
                  id="phrase_min_words"
                  aria-label="phrase min words"
                  disabled={isDisabled}
                  value={phraseMinWords}
                  type="number"
                  onChange={(e) => {
                    if (e.target.value > 0) setPhraseMinWords(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </FormControl>
          <FormControl className={clasess.formControl}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={7} className="display-flex">
                <span className="spanLabel">phrase max words</span>
                <Tooltip
                  title="Maximum number of words in a phrase."
                  placement="top"
                >
                  <Info className="text-blue" />
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <Input
                  id="phrase_max_words"
                  aria-label="phrase max words"
                  disabled={isDisabled}
                  value={phraseMaxWords}
                  type="number"
                  onChange={(e) => {
                    if (e.target.value > 0) setPhraseMaxWords(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </FormControl>
        </div>
      )}
      <FormControl className={clasess.formControl}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={7} className="display-flex">
            <span className="spanLabel">ngram frequency</span>
            <Tooltip
              title="Minimum number of occurrences for ngrams or topic phrases."
              placement="top"
            >
              <Info className="text-blue" />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <Input
              id="ngram_frequency"
              aria-label="ngram frequency"
              disabled={isDisabled}
              value={ngramFrequency}
              type="number"
              onChange={(e) => {
                if (e.target.value > 0) setNgramFrequency(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </FormControl>
      <FormControl className={clasess.formControl}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={7} className="display-flex">
            <span className="spanLabel">word length min</span>
            <Tooltip
              title="Maximum character length for a word in ngrams and topic phrases."
              placement="top"
            >
              <Info className="text-blue" />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <Input
              id="word_length_min"
              aria-label="word length min"
              disabled={isDisabled}
              value={wordLengthMin}
              type="number"
              onChange={(e) => {
                if (e.target.value > 0) setWordLengthMin(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </FormControl>
      <FormControl className={clasess.formControl}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={7} className="display-flex">
            <span className="spanLabel">word length max</span>
            <Tooltip
              title="Maximum character length for a word in ngrams and topic phrases."
              placement="top"
            >
              <Info className="text-blue" />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <Input
              id="word_length_max"
              aria-label="word length max"
              disabled={isDisabled}
              value={wordLengthMax}
              type="number"
              onChange={(e) => {
                if (e.target.value > 0) setWordLengthMax(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </FormControl>
      <FormControl className={[clasess.formControl, clasess.slider].join(" ")}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={7} className="display-flex">
            <span className="spanLabel">ignore sentiment range</span>
            <Tooltip
              title="Exclude phrases with a sentiment score between ignore_sentiment_min and ignore_sentiment_max."
              placement="top"
            >
              <Info className="text-blue" />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <Slider
              disabled={isDisabled}
              min={-1}
              step={0.1}
              max={1}
              value={ignoreSentiment}
              onChange={setIgnoreSentiment}
              valueLabelDisplay="auto"
              data-testid="ignoreSentimentSlider"
              marks={[
                {
                  value: -1,
                  label: "min",
                },
                {
                  value: 1,
                  label: "max",
                },
              ]}
            />
          </Grid>
        </Grid>
      </FormControl>
      <FormControl className={[clasess.formControl, clasess.slider].join(" ")}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={7} className="display-flex">
            <span className="spanLabel">Document limit</span>
            <Tooltip
                title="We recommend that you use at least 200 documents. ATD will use a maximum of 5000 documents. If your corpus contains fewer documents than the Document Limit, all documents will be used."
                placement="top"
            >
              <Info className="text-blue" />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <PrettoSlider
                step={1}
                min={200}
                max={5000}
                valueLabelDisplay="auto"
                aria-label="document_limit"
                value={documentLimit}
                onChange={(e,val) => setDocumentLimit(val)}
            />
          </Grid>
        </Grid>
      </FormControl>
    </>
  );
};

export default PipelineParams;

import "./Layout.css";
import React from "react";
import client from "../../_helpers/client";
import Header from "./Header/Header";
import Aside from "./Aside/Aside";

import DataPreloader from "../../containers/Preloader/DataPreloader";
import { getUserDetails, getDefaultBrandDetails } from "../../_helpers";
import { GET_USER_DETAILS, GET_DEFAULT_BRAND } from "../../Routes";
import { SUCCESS } from "../../Constants";

class AppLayout extends React.Component {
  _isMounted = false;

  state = {
    loadingUserDetails: true,
    loadingBrandDetails: true,
  };
  //Get User Details
  fetchUserDetails = () => {
    client
      .get(GET_USER_DETAILS)
      .then((res) => {
        if (res.data.status === SUCCESS) {
          localStorage.setItem("userDetails", JSON.stringify(res.data.response.userDetails));
        } else {
          localStorage.setItem("userDetails", JSON.stringify(null));
        }
       setTimeout(() => {
          if (this._isMounted)
            this.setState({ loadingUserDetails: false });
       }, 500);
      })
      .catch((error) => {
        localStorage.setItem("userDetails", JSON.stringify(null));
        setTimeout(() => {
          if (this._isMounted)
            this.setState({ loadingUserDetails: false });
        }, 500);
      });
  };

  //Get Default Selected Brand Details
  fetchDefaultBrandDetails = () => {
    client
      .get(GET_DEFAULT_BRAND)
      .then((res) => {
        if (res.data.status === SUCCESS) {
          localStorage.setItem(
            "defaultSelectedBrand",
            JSON.stringify(res.data.response)
          );
        } else {
          localStorage.setItem("defaultSelectedBrand", JSON.stringify(null));
        }

        setTimeout(() => {
          if (this._isMounted)
            this.setState({ loadingBrandDetails: false });
        }, 500);
      })
      .catch((error) => {
        localStorage.setItem("defaultSelectedBrand", JSON.stringify(null));
        setTimeout(() => {
          if (this._isMounted)
            this.setState({ loadingBrandDetails: false });
        }, 500);
      });
  };

  async componentDidUpdate() {
    const userDetails = await getUserDetails();
    const brandDetails = await getDefaultBrandDetails();
    if (!userDetails) {
      getUserDetails();
    }

    if (!brandDetails) {
      getDefaultBrandDetails();
    }
  }

  async componentDidMount() {
    this._isMounted = true;

    const userDetails = await getUserDetails();
    const brandDetails = await getDefaultBrandDetails();

    if (!userDetails) this.fetchUserDetails();
    else if (this._isMounted)
      this.setState({
        loadingUserDetails: false,
      });

    if (!brandDetails) this.fetchDefaultBrandDetails();
    else if (this._isMounted)
      this.setState({
        loadingBrandDetails: false,
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { loadingUserDetails, loadingBrandDetails } = this.state;
    return (
      <React.Fragment>
        {(loadingUserDetails && loadingBrandDetails) ? (
          <div style={{ background: "#e6eef2 " }}>
            <DataPreloader text="Fetching details" />
          </div>
        ) : (
          <div>
            <Header />
            <Aside />
            <main id="main" className="app-body">
              {this.props.children}
            </main>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default AppLayout;

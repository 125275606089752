import "./Preloader.css";
import React from "react";
import PreloaderIcon from "react-preloader-icon";
import Oval from "react-preloader-icon/loaders/Oval";

const DataPreloader = ({ text = "Loading data" }) => {
  return (
    <div className="gridPreloaderHolder">
      <div className="gridPreloaderDiv">
        <PreloaderIcon
          loader={Oval}
          size={34}
          strokeWidth={14}
          strokeColor="#2196f3"
          duration={1000}
        />
        <br />
        <div className="loaderText">
          <span>{text}&nbsp;please wait...</span>
        </div>
      </div>
    </div>
  );
};

export default DataPreloader;

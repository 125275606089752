import axios from 'axios';
import { AUTHENTICATE, FORGET_PASSWORD, REGISTER, CHECK_MAIL, CHECK_PASSWORD, LOGOUT } from '../Routes'
import client from '../_helpers/client';
import { SUCCESS } from '../Constants';

export const userService = {
    login,
    logout,
    forgetPassword,
    userRegister,
    checkMail,
    checkPassword,
};

function login(username, password) {
    return axios
        .post(AUTHENTICATE, { email: username, password: password })
        .then(response => {
            if (response.data.status === SUCCESS) {
                const userAuth = { authorizationToken: response.headers.authorization, };

                localStorage.setItem("userAuth", JSON.stringify(userAuth));
            }
            return response
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function logout() {
    return client.get(LOGOUT).then(res => {
        return res.data;
    });
}

function getGeoInfo() {
    return axios.get('https://ipapi.co/json/').then((response) => {
        var country = response.data.country_name;
        var zone = response.data.region;
        var district = response.data.city;
        var time = new Date().toLocaleString();
        var location = time + " from " + country + ", " + zone + ", " + district;
        return location
    }).catch((error) => {
        alert(error);
    });
};

function forgetPassword(email) {
    return getGeoInfo().then(res => {
        return axios
            .get(FORGET_PASSWORD, { params: { email: email, location: res } })
    });
}

function userRegister(email, pswd, firstName, lastName, phone, companyName, address, captha) {
    return axios
        .post(REGISTER, {
            'firstName': firstName,
            'lastName': lastName,
            'password': pswd,
            'email': email,
            'mobileNumber': phone,
            'companyName': companyName,
            'companyAddress': address,
            'g-recaptcha-response': captha
        })
}

function checkMail(email) {
    return axios
        .post(CHECK_MAIL, { 'email': email, 'checkEmailFun': email })
}

function checkPassword(str) {
    return axios
        .post(CHECK_PASSWORD, { 'password': str })
}
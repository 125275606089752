var Highcharts = require('highcharts');

const getDateToString = (sTime) => {
  var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
    "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  var dateObject = new Date(sTime);
  var date = dateObject.getDate();
  var year = dateObject.getFullYear();
  var month = monthNames[(dateObject.getMonth())];
  return "" + month + " " + date + ", " + year;
};

export const trendChart = (props) => {

  return {
    chart: {
      zoomType: 'x'
    },
    credits: {
      enabled: false
    },
    global: {
      useUTC: false
    },
    title: {
      text: ''
    },
    rangeSelector: {
      selected: 4
    },
    xAxis: {
      min: 0,
      allowDecimals: false,
      type: 'datetime',
      tickPixelInterval: 200
    },
    tooltip: {
      style: {
        padding: '0px',
        margin: '0px'
      },
      shadow: false,
      borderColor: 'none',
      positioner: function () {
        return { x: 0, y: 0 };
      },

      formatter: function () {
        var date = getDateToString(this.x);
        var s = "<div style='background:#fff'><table class='table table-condensed table-bordered' style='margin-bottom:0px !important'><tr>" +
          "<td rowspan='2'><b>" + Highcharts.dateFormat('%b %d, %Y', this.x);
        date = date + "</b></td>";

        this.points.forEach(function (entry) {
          s += '<td style="text-align: center; font-weight:bold; color:' + entry.series.color + '">' + entry.series.name + '</td>'
        });
        s += "</tr><tr>";

        this.points.forEach(function (entry) {
          s += '<td><b> ' + parseInt(entry.y) + '</b></td>';
        });

        return s + "</tr></table></div>";
      },
      shared: true,
      useHTML: true
    },
    yAxis: {
      allowDecimals: false,
      gridLineColor: '#E6E6E6',
      min: 0,
      plotLines: [{
        value: 0,
        width: 2,
        color: '#ef6a6a'
      }]
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
        point: {
          events: {
            click: function (event) { props.drillDown(event); },
          }
        }
      }
    }
  }
}

export const pieChart = (props) => {

  return {
    drilldown: props.drilldown,

    credits: {
      enabled: false
    },
    legend: {
      itemWidth: 100,
      enable: true,
      events: false
    },
    chart: {
      type: 'pie',
      events: {

      }
    },
    title: {
      text: ''
    },

    tooltip: {
      pointFormat: '<b>{point.percentage:.5f}%</b>',
      shadow: true,
      positioner: function () {
        return { x: 0, y: 0 };
      }
    },

    plotOptions: {
      pie: {
        cursor: 'pointer',
        showInLegend: true,
        dataLabels: {
          enabled: false,
        },
        innerSize: '50%',
        startAngle: 0,
        endAngle: 360,
        center: ['50%', '50%']
      },
      series: {
        point: {
          events: {
            click: function (event) { props.singleClick(event); },
            legendItemClick: function () {
              return false;
            }
          }
        }
      }
    }

  };
}

export const barChart = (props) => {

  return {
    credits: {
      enabled: false
    },
    chart: {
      type: 'bar',
      zoomType: 'xy',
      resetZoomButton: {
        position: {
          x: -5,
          y: 5
        },
        relativeTo: 'chart'
      }
    },

    legend: {
      enabled: false
    },
    title: {
      text: ''
    },
    subtitle: {
      text: null
    },
    yAxis: {
      allowDecimals: false,
      ceiling: 100,
      title: {
        text: 'Percentage (%)'
      },
      labels: {
        overflow: 'justify'
      }
    },
    tooltip: {
      formatter: function () {
        return this.x + '<br/><b>' + this.y + '% </b>';
      }
    },
    plotOptions: {
      cursor: 'pointer',
      bar: {
        dataLabels: {
          enabled: false
        }
      },
      series: {
        colorByPoint: true,
        point: {
          animation: {
            duration: 2000
          },
          events: {
            dblclick: (event) => { props.drillDown(event) }
          }
        },
      }

    }
  }

}

export const columnChart = (props) => {
  return {
    enabled: false,
    chart: {
      type: 'column',
      zoomType: 'xy',
      resetZoomButton: {
        position: {
          x: -10,
          y: 5
        },
        relativeTo: 'chart'
      }
    },
    legend: {
      enabled: true
    },
    title: {
      text: ''
    },
    subtitle: {
      text: null
    },
    xAxis: {
      title: {
      },

    },
    yAxis: {
      allowDecimals: false,
      ceiling: 100,
      title: {
        text: 'Percentage (%)'
      },
      labels: {
        overflow: 'justify'
      }
    },
    tooltip: {
      pointFormat: '<tr><td style="padding:0"><b>{point.y:.6f}</b>%</td></tr>',
      shared: true,
      useHTML: true,
      style: {
        padding: '0px',
        margin: '0px'
      },
      borderColor: 'none',
      shadow: false,
      positioner: function () {
        return { x: 0, y: 25 };
      },

      formatter: function () {
        var s = "<div><table class='table table-condensed table-bordered' style='margin-bottom:0px !important'><tr>" +
          "<td rowspan='2'><b>" + this.x + "</b></td>";

        this.points.forEach(function (entry) {
          s += '<td style="text-align: center; font-weight:bold; color:' + entry.series.color + '">' + entry.series.name + '</td>'
        });
        s += "</tr><tr>";

        this.points.forEach(function (entry) {
          s += '<td><b> ' + entry.y + '%</b></td>';
        });

        return s + "</tr></table></div>";
      },

    },
    plotOptions: {
      cursor: 'pointer',
      bar: {
        dataLabels: {
          enabled: false
        }
      },
      series: {
        point: {
          events: {
            dblclick: (event) => { props.drillDown(event) }
          }
        }
      }
    }
  }
}
import React, { useState } from "react";
import { Button, IconButton, Zoom, Popover } from "@material-ui/core";
import MuiTooltip from "@material-ui/core/Tooltip";
import { Delete, LowPriority, CheckCircle } from "@material-ui/icons";

const PhraseCard = ({
  phrase,
  selectedPhrases,
  updateAuditedPhrases,
  handleClick,
  handlePhraseOptions,
  handleNewTopicCreation,
  isNgram,
  handleNgramTopicClick
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'topics-list' : undefined;

  const phraseDragStart = (e, elm) => {
    try {
      var crt = document.createElement("p");
      crt.className = "draged-elem";
      crt.innerHTML = selectedPhrases.length + " items";
      crt.style.transform = "translateX(-500px)";
      document.body.appendChild(crt);
      e.dataTransfer.setDragImage(crt, 0, 0);
      e.dataTransfer.setData("Phrases", selectedPhrases);
    } catch(err) {
      console.log(err);
    }
  };

  const phraseDragEnd = (e) => {
    try {
      let elements = document.getElementsByClassName("draged-elem");
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
    } catch(err) {
      console.log(err);
    }
  };

  const handlePhraseClick = (e) => {
    handleClick(e.shiftKey, phrase);
  }

  const handlePopoverOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isDraggable = phrase ? selectedPhrases.includes(phrase.phraseId) : false;

  return (
    <React.Fragment>
      { phrase && 
      <div
        className={
          selectedPhrases.includes(phrase.phraseId)
            ? "phrasePhrase selected"
            : "phrasePhrase"
        }
      >
        <div
          id={`${"phrase_" + phrase.phraseId}`}
          className="phrase-title"
          draggable={isDraggable}
          onDragStart={(event) => {
            phraseDragStart(event, phrase);
            if (!phrase.isCurated) updateAuditedPhrases([phrase.phraseId]);
          }}
          onDragEnd={(event) => phraseDragEnd(event, phrase)}
          onClick={handlePhraseClick}
          data-testid={`${"phrase_" + phrase.phraseId}`}
        >
          <div className='phrase-card-top'>
            {isNgram && phrase.topics && !!phrase.topics.length && (
              <>
                <span
                  id='topics-list-icon'
                  className='ngram-topic-list-icon'
                  onClick={handlePopoverOpen}
                >
                  <CheckCircle />
                </span>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                  }}
                >
                  <div className='ngram-topics-list'>
                    <h6>MEMBER OF</h6>
                    {phrase.topics && phrase.topics.map((item) => (
                      <Button
                        key={item}
                        color='primary'
                        size='small'
                        onClick={() => {
                          handleNgramTopicClick(item, phrase.phrase);
                          handlePopoverClose();
                        }}
                      >
                        {item}
                      </Button>
                    ))}
                  </div>
                </Popover>
              </>
            )}
            {phrase.isSeed && (
              <MuiTooltip title='Seed Phrase' placement="right">
                <span className='seed-icon'>S</span>
              </MuiTooltip>
            )}
          </div>
          <MuiTooltip title={phrase.phrase} placement="bottom" TransitionComponent={Zoom}>
            <div className="phrase-name isDrag">
              {phrase.phrase && phrase.phrase.length > 22
                ? phrase.phrase.trim().substring(0, 20) + "..."
                : phrase.phrase}
            </div>
          </MuiTooltip>
          <div className="phrase-frequency">{phrase.frequency}</div>
          <div className="phrase-actions">
            <span>
              <MuiTooltip title="Move to Candidates" TransitionComponent={Zoom}>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePhraseOptions(phrase, "move");
                  }}
                >
                  <LowPriority />
                </IconButton>
              </MuiTooltip>
            </span>

            <span>
              <MuiTooltip title="Create New Topic" TransitionComponent={Zoom}>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleNewTopicCreation(phrase);
                  }}
                >
                  <LowPriority style={{ transform: "rotate(180deg)" }} />
                </IconButton>
              </MuiTooltip>
            </span>

            <span>
              <MuiTooltip title="Delete" TransitionComponent={Zoom}>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePhraseOptions(phrase, "delete");
                  }}
                >
                  <Delete />
                </IconButton>
              </MuiTooltip>
            </span>
          </div>
        </div>
      </div>  }
    </React.Fragment>
  );
};

export default React.memo(PhraseCard);

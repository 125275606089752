import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import { Tooltip, Zoom, IconButton, TableSortLabel, TableRow, TableHead, TableCell, TableBody, Table, Switch, Paper, Button, FormGroup, FormControl, FormControlLabel, InputAdornment, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import RefreshIcon from '@material-ui/icons/Refresh';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import client from '../../../../_helpers/client';
import { toast } from 'react-toastify';

import {
    GET_ALL_USERS,
    DELETE_USER,
    UPDATE_USER_PLAN,
    UPDATE_ADMIN_STATUS,
    UPDATE_ACTIVE_STATUS,
    UPDATE_KIBANA_STATUS,
    GET_USER_DETAILS,
    GET_DEFAULT_BRAND,
    SET_USER_INDEX,
    GET_INDEX_STATUS,
    CONFIRM_USER_STATUS
} from '../../../../Routes';
import { WARN, EMPTY_ARRAY, SUCCESS, EMPTY_OBJECT, EMPTY_STRING, PLAN_BASIC, PLAN_ADMINISTRATION, PLAN_ENTERPRISE, PLAN_PROFESSIONAL } from '../../../../Constants';
import { getSorting, stableSort, updateAppUser } from '../../../../_helpers/index';
import DataPreloader from '../../../../containers/Preloader/DataPreloader';
import RenderUserBrands from './RenderUserBrands'
import { Search, Close } from '@material-ui/icons';
import NoAccess from '../../../../containers/NoAccess/NoAccess';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    tableWrapper: {
        overflow: 'auto',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    typography: {
        padding: theme.spacing(2),
    },
    margin: {
        margin: theme.spacing(1),
    }
}));

const columns = [
    { id: 'email', label: 'Email' },
    { id: 'administrator', label: 'Admin' },
    { id: 'isActive', label: 'Active' },
    { id: 'isConfirmed', label: 'Confirmed'},
    { id: 'id', label: 'Plan' },
    { id: 'indexSetup', label: 'Index Setup' },
    { id: 'brands', label: 'Brands'}
];


export default function UserManagement(props) {

    let toastId = null;

    const classes = useStyles();

    const [visiblePreloader, setVisiblePreloader] = useState(true);
    const [userList, setUserList] = useState(EMPTY_ARRAY);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('fullName');
    const [switchState, setSwitchState] = useState(EMPTY_OBJECT);

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({
        modalEvent: null, modalLabel: EMPTY_STRING, modalId: null
    });

    const [isAccessible, setIsAccessible] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const showPopover = Boolean(anchorEl);
    const id = showPopover ? 'plan-popover' : undefined;
    const [planType, setPlanType] = useState(PLAN_BASIC);

    const [userStr, setUserStr] = useState(EMPTY_STRING);

    const setDynamicSwitchState = (userList, planList) => {
        if (!userList && planList) {
            return
        }
        const switchChange = EMPTY_OBJECT;
        userList.forEach((item) => {
            switchChange['admin-' + item.id] = item.administrator;
            switchChange['active-' + item.id] = item.isActive;
            switchChange['kibana-' + item.id] = item.isKibanaActive;
            switchChange['confirmed-' + item.id] = item.isConfirmed;
        });
        
        planList.forEach((item)=> {
            switchChange['plan-' + item.userId] = item.planId;
        });
        setSwitchState(switchChange);
    }

    const createSortHandler = property => event => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleChange = (id, event, checked, column) => {
        const switchList = Object.assign({}, switchState);
        switch (column) {
            case 'admin':
                client
                    .get(UPDATE_ADMIN_STATUS, { params: { userId: parseInt(id), status: checked } })
                    .then(res => {
                        if (res.data.status === SUCCESS) {
                            switchList['admin-' + id] = checked;
                            setSwitchState(switchList);
                            toast.success(res.data.message);
                        } else toast.warn(res.data.message)
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.data.status === WARN) toast.warn(error.response.data.message)
                            else toast.error(error.response.data.message)
                        }
                    });
                break;
            case 'active':
                client
                    .get(UPDATE_ACTIVE_STATUS, { params: { userId: parseInt(id), status: checked } })
                    .then(res => {
                        if (res.data.status === SUCCESS) {
                            switchList['active-' + id] = checked;
                            setSwitchState(switchList);
                            toast.success(res.data.message);
                        } else toast.warn(res.data.message)
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.data.status === WARN) toast.warn(error.response.data.message)
                            else toast.error(error.response.data.message)
                        }
                    });
                break;

            case 'confirmed':
                client
                    .get(CONFIRM_USER_STATUS, {params: {userId: parseInt(id), status: checked}})
                    .then(res => {
                        if (res.data.status === SUCCESS) {
                            switchList['confirmed-' + id] = checked;
                            setSwitchState(switchList);
                            toast.success(res.data.message);
                        } else toast.warn(res.data.message)
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.data.status === WARN) toast.warn(error.response.data.message)
                            else toast.error(error.response.data.message)
                        }
                    });
                break;

            default:
                client
                    .get(UPDATE_KIBANA_STATUS, { params: { userId: id, activate: checked } })
                    .then(res => {
                        if (res.data.status === SUCCESS) {
                            switchList['kibana-' + id] = checked;
                            setSwitchState(switchList);
                            toast.success(res.data.message);
                        } else toast.warn(res.data.message)
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.data.status === WARN) toast.warn(error.response.data.message)
                            else toast.error(error.response.data.message)
                        }
                    });
                break;
        }
    };

    const setUserImpersonation = user => {  
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        localStorage.setItem("spyUser", JSON.stringify({id: user.sub, mainUser: userDetails.firstName+' '+userDetails.lastName}));
        setVisiblePreloader(true);
        const requestOne = client.get(GET_USER_DETAILS);
        const requestTwo = client.get(GET_DEFAULT_BRAND);

        axios
            .all([requestOne, requestTwo])
            .then(axios.spread((...responses) => {
                const responseOne = responses[0];
                const responseTwo = responses[1];
                updateAppUser(responseOne, responseTwo);
            }))
            .catch(errors => {
                console.log(errors);
                setVisiblePreloader(false);
            })
    }

    //Open Modal 
    const handleModalOpen = (user, modalEvent) => {
        setModalData((modalData) => ({
            ...modalData,
            modalEvent: modalEvent, modalLabel: user.email, modalId: user.id
        }));
        setShowModal(true);
    }

    //Close Modal
    const handleModalClose = () => {
        setShowModal(false);
        setModalData((modalData) => ({
            ...modalData,
            modalEvent: null, modalLabel: EMPTY_STRING, modalId: null
        }));
    }

    //Handle User delete
    const handleUserDelete = () => {
        toastId = toast("Delete in progress, please wait...", { autoClose: false })
        const userId = modalData.modalId;
        setShowModal(false);
        client
            .get(DELETE_USER, { params: { userId: parseInt(modalData.modalId) } })
            .then(res => {
                if (res.data.status === SUCCESS) {
                    let delAry = [];
                    delAry = userList.filter(user => user.id !== userId);
                    setUserList(delAry);
                    toast.update(toastId, { type: toast.TYPE.SUCCESS, render: res.data.message, autoClose: 3500 });
                } else {
                    if (res.data.status === WARN)
                        toast.update(toastId, { type: toast.TYPE.WARNING, render: res.data.message, autoClose: 3500 });
                    else
                        toast.update(toastId, { type: toast.TYPE.ERROR, render: res.data.message, autoClose: 3500 });
                }
                setModalData((modalData) => ({
                    ...modalData,
                    modalEvent: null, modalLabel: EMPTY_STRING, modalId: null
                }));
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.status === WARN)
                        toast.update(toastId, { type: toast.TYPE.WARNING, render: error.response.data.message, autoClose: 3500 });
                    else
                        toast.update(toastId, { type: toast.TYPE.ERROR, render: error.response.data.message, autoClose: 3500 });
                }
                setModalData((modalData) => ({
                    ...modalData,
                    modalEvent: null, modalLabel: EMPTY_STRING, modalId: null
                }));
            });
    }

    //Show Popover
    const handlePopover = (event, user) => {
        const switchList = Object.assign({}, switchState);
        setPlanType(switchList['plan-' + user.id]);
        setAnchorEl(event.currentTarget);
        setModalData((modalData) => ({
            ...modalData,
            modalEvent: 'plan', modalLabel: EMPTY_STRING, modalId: user.id
        }));
    }

    //Handle Plan change
    const handlePlanChange = value => event => {
        const prevVal = planType;
        setPlanType(value);
        client.get(UPDATE_USER_PLAN, { params: { userId: parseInt(modalData.modalId), planId: value } })
            .then(res => {
                if (res.data.status === SUCCESS) {
                    const switchList = Object.assign({}, switchState);
                    switchList['plan-' + modalData.modalId] = value;
                    setSwitchState(switchList);
                    toast.success(res.data.message);
                } else {
                    if (res.data.status === WARN) toast.warn(res.data.message)
                    else toast.error(res.data.message)
                    setPlanType(prevVal)
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.status === WARN) toast.warn(error.response.data.message)
                    else toast.error(error.response.data.message)
                }
                setPlanType(prevVal);
            });
    };

    //Get User Lists
    const getUsersList = () => {
        client
            .get(GET_ALL_USERS)
            .then(res => {
                if (res.data.status === SUCCESS) {
                    setDynamicSwitchState(res.data.response.userList, res.data.response.userPlans);
                    let updatedUserList = res.data.response.userList;
                    updatedUserList.forEach(function(li) {
                        li.fullName = li.firstName+' '+li.lastName;
                    });
                    setUserList(updatedUserList);
                    setVisiblePreloader(false);
                } else {
                    if (res.data.status === WARN) toast.warn(res.data.message);
                    else toast.error(res.data.message);
                    setVisiblePreloader(false);
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.status === WARN) toast.warn(error.response.data.message)
                    else toast.error(error.response.data.message)
                }
                setVisiblePreloader(false)
            });
    }

    const handleMouseDown = e => e.preventDefault();
    const handleDisableClose = e => setUserStr(EMPTY_STRING);

    const handleIndexSetup = user => {
        setUserList(state =>(
            state.slice().map(u => {
                return u.id === user.id ? {
                    ...u,
                    indexLoading: true
                } : u
            })
        ));
        client
            .get(SET_USER_INDEX, { params: { userId: user.id } })
            .then(res => {
                if (res.data.status === SUCCESS) {
                    setUserList(state =>(
                        state.slice().map(u => {
                            return u.id === user.id ? {
                                ...u,
                                indexLoading: false,
                                isIndexExists: "Created"
                            } : u
                        })
                    ));
                    toast.success(res.data.message)
                } else {
                    if (res.data.status === WARN) toast.warn(res.data.message)
                    else toast.error(res.data.message)
                    setUserList(state =>(
                        state.slice().map(u => {
                            return u.id === user.id ? {
                                ...u,
                                indexLoading: false
                            } : u
                        })
                    ));
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.data.status === WARN) toast.warn(error.response.data.message)
                    else toast.error(error.response.data.message)
                }
                setUserList(state =>(
                    state.slice().map(u => {
                        return u.id === user.id ? {
                            ...u,
                            indexLoading: false
                        } : u
                    })
                ));
            })
    }

    const checkIndexStatus = user => {
        setUserList(state =>(
            state.slice().map(u => {
                return u.id === user.id ? {
                    ...u,
                    indexLoading: true
                } : u
            })
        ));
        client
            .get(GET_INDEX_STATUS, { params: { userId: user.id } })
            .then(res => {
                if (res.data.status === SUCCESS) {
                    setUserList(state =>(
                        state.slice().map(u => {
                            return u.id === user.id ? {
                                ...u,
                                indexLoading: false,
                                isIndexExists: res.data.response
                            } : u
                        })
                    ));
                } else {
                    if (res.data.status === WARN) toast.warn(res.data.message)
                    else toast.error(res.data.message)
                    setUserList(state =>(
                        state.slice().map(u => {
                            return u.id === user.id ? {
                                ...u,
                                indexLoading: false
                            } : u
                        })
                    ));
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.data.status === WARN) toast.warn(error.response.data.message)
                    else toast.error(error.response.data.message)
                }
                setUserList(state =>(
                    state.slice().map(u => {
                        return u.id === user.id ? {
                            ...u,
                            indexLoading: false
                        } : u
                    })
                ));
            })
    }

    useEffect(() => {
        const spyUser = JSON.parse(localStorage.getItem('spyUser'));
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    
        if(!spyUser) {
            if(userDetails) {
                if(userDetails.isAdmin) {
                    setIsAccessible(true);
                    getUsersList();
                } else setVisiblePreloader(false)
            }
            else setVisiblePreloader(false)
        } else
            setVisiblePreloader(false)
            
    }, []);

    const sortingColumns = ['fullName', 'email'];
    return (
        <React.Fragment>
            { /* PopOver */}
            <Popover
                id={id}
                open={showPopover}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className={classes.typography}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch checked={planType === PLAN_BASIC} onChange={handlePlanChange(PLAN_BASIC)} />
                            }
                            label="Basic"
                        />
                        <FormControlLabel
                            control={
                                <Switch checked={planType === PLAN_PROFESSIONAL} onChange={handlePlanChange(PLAN_PROFESSIONAL)} />
                            }
                            label="Professional"
                        />
                        <FormControlLabel
                            control={
                                <Switch checked={planType === PLAN_ENTERPRISE} onChange={handlePlanChange(PLAN_ENTERPRISE)} />
                            }
                            label="Enterprise"
                        />
                        <FormControlLabel
                            control={
                                <Switch checked={planType === PLAN_ADMINISTRATION} onChange={handlePlanChange(PLAN_ADMINISTRATION)} />
                            }
                            label="Administration"
                        />
                    </FormGroup>
                </div>
            </Popover>

            { /* Modal */}
            <Dialog
                open={showModal}
                onClose={handleModalClose}
                fullWidth={true}
                maxWidth={modalData.modalEvent === 'delete' ? "sm" : "md"}
                aria-labelledby="user-management-dialog-label"
                aria-describedby="user-management-dialog-description"
            >
                <div className={modalData.modalEvent === 'delete' ? 'uiDialog deleteModel' : 'uiDialog'}>
                    {modalData.modalEvent === 'delete'
                        ? <div className="uiDialogTitle"><h6>Delete</h6></div>
                        : null
                    }
                    <div className="uiDialogContent">
                        {
                            modalData.modalEvent === 'delete'
                                ? <div>Are you sure want to delete <span className="delete-highlight">{modalData.modalLabel}</span>user.
                            It will delete all related to user. Please make sure!</div>
                                : modalData.modalEvent === 'brands' ? <RenderUserBrands userId={modalData.modalId} /> : null
                        }
                    </div>
                    <footer><div>
                        <Button onClick={handleModalClose}> 
                            {modalData.modalEvent === 'delete' ? 'Cancel' : 'Close'}
                        </Button>
                        {modalData.modalEvent === 'delete' ? <Button onClick={handleUserDelete} color="primary" data-testid="userDeleteBtn">Delete</Button> : null}
                    </div></footer>

                </div>
            </Dialog>

            <h1>User Management</h1>
            { visiblePreloader 
                ? <DataPreloader /> 
                : !isAccessible 
                    ? <NoAccess />
                    : <div className="brandContent user-management">
                <Paper className={classes.root}>
                    <div className="userTableSearch">
                        <div className="userSearchHolder">
                            <FormControl className={"listViewSearch"}>
                                <TextField
                                    id="input-with-icon-textfield"
                                    placeholder={"Search user"}
                                    value={userStr}
                                    onChange={(e) => setUserStr(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    aria-label="clean input"
                                                    disabled={userStr.length === 0}
                                                    onClick={handleDisableClose}
                                                    onMouseDown={handleMouseDown}
                                                >
                                                    <Close />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className={classes.tableWrapper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {columns.map(column => (
                                        <TableCell key={column.id}>
                                           { sortingColumns.includes(column.id) ?
                                            <TableSortLabel
                                                active={orderBy === column.id}
                                                direction={order}
                                                onClick={createSortHandler(column.id)}
                                                //IconComponent={ArrowDropDown}
                                            >
                                                {column.label}
                                            </TableSortLabel>
                                            : column.label }
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stableSort(userList, getSorting(order, orderBy))
                                    .filter(row => row.fullName.toLowerCase().includes(userStr.toLowerCase()) || row.email.toLowerCase().includes(userStr.toLowerCase()) )
                                    .map(row => {
                                        return (
                                            <TableRow hover tabIndex={-1} key={row.id} data-id={row.id}>
                                                <TableCell>
                                                <div className="username">
                                                    <span onClick={() => setUserImpersonation(row)}>{row.email}</span>
                                                </div>
                                                </TableCell>
                                                <TableCell>
                                                    { /* toggle user admin */}
                                                    <Switch
                                                        checked={switchState['admin-' + row.id]}
                                                        onChange={(event, checked) => handleChange(row.id, event, checked, 'admin')}
                                                        value="admin"
                                                        inputProps={{ 'aria-label': 'admin-' + row.id }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    { /* toggle user active   */}
                                                    <Switch
                                                        checked={switchState['active-' + row.id]}
                                                        onChange={(event, checked) => handleChange(row.id, event, checked, 'active')}
                                                        value={"active"}
                                                        inputProps={{ 'aria-label': 'active-' + row.id }}
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    { /* confirm User   */}
                                                    <Switch
                                                        checked={switchState['confirmed-' + row.id]}
                                                        onChange={(event, checked) => handleChange(row.id, event, checked, 'confirmed')}
                                                        value={"confirmed"}
                                                        inputProps={{ 'aria-label': 'confirmed-' + row.id }}
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    <div className="username">
                                                        <span onClick={(e) => handlePopover(e, row)}>
                                                            {switchState['plan-' + row.id] === 1
                                                                ? 'Basic'
                                                                : switchState['plan-' + row.id] === 2
                                                                    ? 'Professional'
                                                                    : switchState['plan-' + row.id] === 3
                                                                        ? 'Enterprise' 
                                                                        : switchState['plan-' + row.id] === 4 
                                                                            ? 'Administration' 
                                                                            : <span className="noDataMessage">Not assigned</span>
                                                            }
                                            </span></div>
                                                </TableCell>
                                                <TableCell>
                                                    <div className="userIndex">
                                                        {row.indexLoading
                                                            ? <CircularProgress size={16} />
                                                            : !row.isIndexExists 
                                                                ? <Tooltip title="Check Index" 
                                                                    TransitionComponent={Zoom}>
                                                                        <span className="refreshIcon" 
                                                                            onClick={() => checkIndexStatus(row)}><RefreshIcon /></span>
                                                                    </Tooltip>
                                                                : row.isIndexExists === "Failed"
                                                                    ? <span 
                                                                        className="indexSetup" 
                                                                        style={{color: "#ff5454", cursor:'pointer'}} 
                                                                        onClick={() => handleIndexSetup(row)}
                                                                        >
                                                                            {row.isIndexExists}
                                                                        </span>
                                                                    : row.isIndexExists === "Created" 
                                                                        ? <span style={{color: "#33b912"}}>{row.isIndexExists}</span>
                                                                        : row.isIndexExists
                                                        }
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div className="username"><span onClick={() => handleModalOpen(row, 'brands')}>View</span></div>
                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip title="Remove User">
                                                        <IconButton onClick={() => handleModalOpen(row, 'delete')}><DeleteIcon /></IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>
            </div>
        }
        </React.Fragment>
    )
}
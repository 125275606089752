import React, { Component } from "react";
import { connect } from "react-redux";
import MuiTooltip from "@material-ui/core/Tooltip";
import { IconButton, Zoom } from "@material-ui/core";
import { DoneAll } from "@material-ui/icons";
import parse from "html-react-parser";
import Highlight from "../../highlight";
import { PALETTE_COLORS } from "../../../../Constants";

class UnclassifiedContext extends Component {
  render() {
    const { docCtx, phraseMoved, hIsActive, paletteList } = this.props;

    const renderHtmlParse = (strElm) => {
      if (strElm) return parse(strElm);
    };
    
    return (
      <React.Fragment>
        { docCtx && 
        <div
          className={phraseMoved ? "atd-uncla-doc" : ""}
          key={docCtx.phraseId}
        >
          <div
            key={`${"document" + docCtx.contextId}`}
            id={`${"context-" + docCtx.contextId}`}
            className="phrase-list-items unClassifiedDocs"
          >
            <div
              className={
                docCtx.markClassified
                  ? "phrase-context full-width markClassified"
                  : "phrase-context full-width"
              }
              onMouseUp={(e) => {
                if (this.props.atdState === "locked") return;
                this.props.handleTextSelection(e, docCtx);
              }}
              data-testid="phrase-context"
            >
              <Highlight
                topics={hIsActive ? paletteList : []}
                colors={PALETTE_COLORS}
              >
                {renderHtmlParse(docCtx.context)} &nbsp;
              </Highlight>
              <span className="unbtn">
                {docCtx.markClassified ? (
                  "Classified"
                ) : (
                  <MuiTooltip
                    TransitionComponent={Zoom}
                    title="Mark as classified"
                  >
                    <IconButton
                      size="small"
                      onClick={() => this.props.markAsClassified(docCtx)}
                    >
                      <DoneAll />
                    </IconButton>
                  </MuiTooltip>
                )}
              </span>
            </div>
          </div>
        </div> }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    paletteList: state.topicList,
    hIsActive: state.isActiveHighlight
  }
}

export default connect(mapStateToProps, null)(UnclassifiedContext);

import '../../Brand/EditBrand.css'
import React, { useState, useRef } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import Stepper from './Stepper'
import DataSource from '../../Brand/InBoundDataSourceAccordian/InBoundDataSource'
import OutboundDS from '../../Brand/OutBoundDataSourceAccordian/OutBoundDataSource'
import ATDPanel from '../../Brand/ATDAccordian/ATDAccordian'
import ScoringPanel from '../../Brand/ScoringAccordian/Scoring'
import { EMPTY_ARRAY, EMPTY_STRING, SUCCESS, WARN } from '../../../Constants'
import client from '../../../_helpers/client'
import {
  IS_BRAND_EXISTS,
  CREATE_BRAND,
  BRAND_SCORE,
  DELETE_BRAND
} from '../../../Routes'
import { toast } from 'react-toastify'
import { updateNoBrandDetails } from '../../../_helpers'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  brandDesc: {
    fontSize: '18px',
    marginBottom: theme.spacing(1)
  },
  scoringPanel: {
    '& h4': {
      fontWeight: 500,
      margin: '0'
    },
    '& .flexGrow-01 .rowGrid': {
      marginBottom: 0
    }
  }
}))

function getSteps() {
  return ['Create Brand', 'Upload Data', 'Add Outbound', 'Machine Learning', 'Select Model']
}

const CreateProjectModal = ({
  open,
  handleModal,
  projectDetails,
  updateProjectDetails,
  handleProjectModalClose
}) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const steps = getSteps()
  const [loading, setLoading] = useState(false)
  const [brandName, setBrandName] = useState(EMPTY_STRING)
  const [objective, setObjective] = useState(EMPTY_STRING)
  const [brandId, setBrandId] = useState(0)
  const [nodeId, setNodeId] = useState(0)
  const [modelId, setModelId] = useState('360')
  const [emptyError, setEmptyError] = useState(false)
  const [pipeline, setPipeline] = useState('Decooda1')
  const [topicDiscoveryOption, setTopicDiscoveryOption] = useState('disable')
  const [thresholdValue, setThresholdValue] = useState(0.5)
  const [seedFile, setSeedFile] = useState(EMPTY_STRING)
  const [semanticSimilarity, setSemanticSimilarity] = useState('Word2Vec')
  const [frequencyPercentile, setfrequencyPercentile] = useState(0.8)
  const [documentLimit, setDocumentLimit] = useState(5000)
  const [tags, setTags] = useState(['NOUN', 'VERB', 'ADJ'])
  const [wordLengthMax, setWordLengthMax] = useState(20)
  const [wordLengthMin, setWordLengthMin] = useState(4)
  const [ngramFrequency, setNgramFrequency] = useState(1)
  const [phraseMinWords, setPhraseMinWords] = useState(3)
  const [phraseMaxWords, setPhraseMaxWords] = useState(10)
  const [ignoreSentiment, setIgnoreSentiment] = useState([0, 0])
  const [dataSourcesList, setDataSourcesList] = useState(EMPTY_ARRAY)
  const [brandDataSources, setBrandDataSources] = useState(EMPTY_ARRAY)
  const brandInputRef = useRef()

  const handleCancelProject = () => {
    updateProjectDetails(null)
    handleModal()
    if (activeStep !== 0 && nodeId > 0)
      client.post(DELETE_BRAND, { id: [nodeId] })
  }

  const handleClose = () => {
    if (projectDetails && projectDetails.name) {
      handleProjectModalClose(projectDetails.name)
      updateProjectDetails(null)
    }
    handleModal()
  }

  const isStepOptional = (step) => {
    return step === 2 || step === 3 || step === 4
  }

  const handleNext = () => {
    if (activeStep === 0) {
      if (!brandName) {
        setEmptyError(true)
        brandInputRef.current.focus()
      } else {
        if (
          !projectDetails ||
          (projectDetails && projectDetails.name !== brandName)
        )
          createBrand()
        else setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    } else if (activeStep === 1) {
      if (brandDataSources === EMPTY_ARRAY)
        toast.warn('Please add at least one data source.')
      else setActiveStep((prevActiveStep) => prevActiveStep + 1)
    } else if (activeStep === 4) {
      score()
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleBrandNameChange = (e) => {
    const newValue = e.target.value.trim()
    if (emptyError && newValue) setEmptyError(false)
    if (newValue || (brandName && !newValue)) setBrandName(e.target.value)
  }

  const handleObjectiveChange = (e) => {
    setObjective(e.target.value)
  }

  const createBrand = () => {
    setLoading(true)
    let data = {
      parentId: 0,
      name: brandName,
      type: 'file',
      languageId: 1
    }

    client
      .get(IS_BRAND_EXISTS, {
        params: { parentId: 0, name: brandName, nodeType: 'file' }
      })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          client
            .post(CREATE_BRAND, data)
            .then((res) => {
              if (res.data.status === SUCCESS) {
                if (dataSourcesList.length) setDataSourcesList(EMPTY_ARRAY)
                if (brandDataSources.length) setBrandDataSources(EMPTY_ARRAY)
                if (seedFile) setSeedFile(EMPTY_STRING)
                setBrandId(res.data.response.id)
                setNodeId(res.data.response.nodeId)
                updateProjectDetails({
                  id: res.data.response.id,
                  name: brandName
                })
                setLoading(false)
                setActiveStep((prevActiveStep) => prevActiveStep + 1)
              } else {
                if (res.data.status === WARN) toast.warn(res.data.message)
                else toast.error(res.data.message)
                setLoading(false)
              }
            })
            .catch((error) => {
              if (error.response) {
                toast.error(error.response.data.message)
              }
              setLoading(false)
            })
        } else {
          if (res.data.status === WARN) toast.warn(res.data.message)
          else toast.error(res.data.message)
          setLoading(false)
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message)
        }
        setLoading(false)
      })
  }

  const handleDataSourcesList = (val) => {
    setDataSourcesList(val)
  }

  const handleBrandDataSources = (val) => {
    setBrandDataSources(val)
  }

  const updatePipeline = (val) => {
    setPipeline(val)
  }

  const handleThresholdValue = (val) => {
    setThresholdValue(val)
  }

  const handleSeedFile = (val) => {
    setSeedFile(val)
  }

  const handleModelDetails = (nodeData, treeNode) => {
    const isModelSelected =
      treeNode &&
      treeNode.selected &&
      !treeNode.node.props.hasOwnProperty('children') &&
      treeNode.node.props.className !== 'emptyFolder'

    setModelId((state) =>
      treeNode ? (isModelSelected ? nodeData[0] : state) : nodeData[0]
    )
  }

  const score = () => {
    setLoading(true)
    const data = new FormData()
    data.append('brandId', brandId)
    data.append('brandName', brandName)
    data.append('objective', objective)
    data.append('language', 1)
    data.append('brandDescription', EMPTY_STRING)
    data.append('modelId', modelId)
    data.append('focus', EMPTY_STRING)
    data.append('topicDiscoveryOption', topicDiscoveryOption)
    data.append('scoreFlag', EMPTY_STRING)
    data.append('seed_file_path', seedFile)
    data.append('pipeline', pipeline)
    data.append('documentLimit', documentLimit)
    if (topicDiscoveryOption === 'enable')
      data.append('thresholdValue', thresholdValue)
    if (topicDiscoveryOption === 'enable' || topicDiscoveryOption === 'blank') {
      if (topicDiscoveryOption === 'enable')
        data.append('semanticSimilarity', semanticSimilarity)
      data.append('ngramFrequency', ngramFrequency)
      data.append(
        'wordLengthRange',
        JSON.stringify({
          min: parseInt(wordLengthMin),
          max: parseInt(wordLengthMax)
        })
      )
      data.append(
        'ignoreSentimentRange',
        JSON.stringify({
          min: parseFloat(ignoreSentiment[0]),
          max: parseFloat(ignoreSentiment[1])
        })
      )
      if (pipeline === 'Decooda1') {
        data.append('frequencyPercentile', frequencyPercentile)
      } else {
        data.append('posFocusTags', tags)
        data.append(
          'phraseWordsRange',
          JSON.stringify({
            min: parseInt(phraseMinWords),
            max: parseInt(phraseMaxWords)
          })
        )
      }
    }

    client
      .post(BRAND_SCORE, data)
      .then((res) => {
        if (res.data.status === SUCCESS) {
          toast.success('Brand successfully submitted for Scoring')
          updateNoBrandDetails(brandId)
        } else {
          if (res.data.status === WARN) toast.warn(res.data.message)
          else toast.error(res.data.message)
        }
        handleClose()
      })
      .catch((error) => {
        if (error && error.response) toast.error(error.response.data.message)
        console.log('Scoring Error:  ', error)
        handleClose()
      })
  }

  return (
    <Dialog
      open={open}
      aria-labelledby='create-project'
      onClose={handleClose}
      maxWidth='md'
      fullWidth
    >
      <DialogTitle id='create-project'>
        Create Project
        <Box mt={2}>
          <Stepper
            activeStep={activeStep}
            steps={steps}
            isStepOptional={isStepOptional}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          {activeStep === 0 ? (
            <Box paddingX={2}>
              <Typography className={classes.brandDesc}>
                Brands are container of your data projects
              </Typography>
              <TextField
                id='brand-name-input'
                variant='standard'
                label='Brand Name'
                value={brandName}
                onChange={handleBrandNameChange}
                required
                fullWidth
                helperText='*Required'
                error={emptyError}
                inputRef={brandInputRef}
                autoFocus
              />
              <TextField
                id='brand-objective-input'
                variant='standard'
                label='Objective'
                value={objective}
                onChange={handleObjectiveChange}
                margin='dense'
                fullWidth
                multiline
                rowsMax={4}
              />
            </Box>
          ) : activeStep === 1 && brandId ? (
            <Box paddingX={2}>
              <DataSource
                brandId={brandId}
                brandStatus={-1}
                dataSourcesList={dataSourcesList}
                brandDataSources={brandDataSources}
                handleDataSourcesList={handleDataSourcesList}
                handleBrandDataSources={handleBrandDataSources}
              />
            </Box>
          ) : activeStep === 2 && brandId ? (
            <Box paddingX={2}>
              <OutboundDS
                brandId={brandId}
              />
            </Box>
          ) : activeStep === 3 && brandId ? (
            <Box>
              <ATDPanel
                brandId={brandId}
                handleTopicDiscoveryDetails={(val) =>
                  setTopicDiscoveryOption(val)
                }
                handleThresholdValueDetails={handleThresholdValue}
                pipeline={pipeline}
                updatePipeline={updatePipeline}
                seedFileAvailable={seedFile}
                handleSeedFile={handleSeedFile}
                topicDiscoveryOption={topicDiscoveryOption}
                thresholdValue={thresholdValue}
                frequencyPercentile={frequencyPercentile}
                setfrequencyPercentile={setfrequencyPercentile}
                documentLimit={documentLimit}
                setDocumentLimit={setDocumentLimit}
                semanticSimilarity={semanticSimilarity}
                setSemanticSimilarity={setSemanticSimilarity}
                tags={tags}
                setTags={setTags}
                phraseMinWords={phraseMinWords}
                setPhraseMinWords={setPhraseMinWords}
                phraseMaxWords={phraseMaxWords}
                setPhraseMaxWords={setPhraseMaxWords}
                ngramFrequency={ngramFrequency}
                setNgramFrequency={setNgramFrequency}
                wordLengthMin={wordLengthMin}
                setWordLengthMin={setWordLengthMin}
                wordLengthMax={wordLengthMax}
                setWordLengthMax={setWordLengthMax}
                ignoreSentiment={ignoreSentiment}
                setIgnoreSentiment={(val) => {
                  setIgnoreSentiment(val)
                }}
              />
            </Box>
          ) : (
            <Box className={classes.scoringPanel}>
              <ScoringPanel
                brandId={brandId}
                handleModelDetails={handleModelDetails}
                mountedInProjectModal={true}
              />
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions
        style={{ marginTop: '20px', borderTop: 'dashed 1px #bdbdbd' }}
      >
        <div style={{ flexGrow: 1 }}>
          <Button
            style={{ marginLeft: '10px' }}
            variant='text'
            onClick={handleCancelProject}
            className={classes.button}
          >
            Cancel
          </Button>
        </div>
        <div>
          <Button
            variant='text'
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.button}
          >
            Back
          </Button>
          <Button
            variant='text'
            color='primary'
            size='large'
            onClick={handleNext}
            className={classes.button}
            disabled={loading}
          >
            {activeStep === steps.length - 1 ? 'Create' : 'Next'}
            {loading && (
              <CircularProgress size={20} style={{ position: 'absolute' }} />
            )}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default CreateProjectModal

import { toast } from "react-toastify";
import {
  EMPTY_STRING,
  EMPTY_ARRAY,
  NO_DEFAUL_BRAND_SELECTED,
  SUCCESS,
} from "../Constants";

//Descending function
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

//Perform Sorting
export function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

//Return Sort order and sort column
export function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

//Replace numbers with comma separated number
export function replaceNumberWithCommas(yourNumber) {
  let n = yourNumber.toString().split(".");
  n[0] = n[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return n.join(".");
}

//Captalize string
export function toCapitalize(s) {
  if (s === EMPTY_STRING) return EMPTY_STRING;
  return s[0].toUpperCase() + s.slice(1);
}

//To return url parameter
export function getFilterQuery(str) {
  let a = str.split("=").reverse();
  return a[0];
}

//String Filter Date
export function stringToFilterDate(sTime) {
  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let dateObject = new Date(sTime);
  let date = dateObject.getDate();
  let year = dateObject.getFullYear();
  let month = monthNames[dateObject.getMonth()];
  return "" + date + " " + month + " " + year;
}

//String Filter Date
export function stringToFilterDateTime(sTime) {
  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let dateObject = new Date(sTime);
  let date = dateObject.getDate();
  let year = dateObject.getFullYear();
  let month = monthNames[dateObject.getMonth()];
  let hours = dateObject.getHours();
  let minutes = dateObject.getMinutes();
  let seconds = dateObject.getSeconds();
  return (
    "" +
    month +
    " " +
    date +
    " " +
    year +
    " " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds
  );
}

export function decodeEntities(s) {
  let str = "";
  if (s !== "") {
    let temp = document.createElement("p");

    temp.innerHTML = s;

    str = temp.textContent || temp.innerText;
    temp = null;
  } else {
    str = s;
  }
  return str;
}

//Get Selected HTML
export function getSelectionHtml() {
  let html = "";
  if (typeof window.getSelection != "undefined") {
    let sel = window.getSelection();
    if (sel.rangeCount) {
      let container = document.createElement("div");
      for (let i = 0, len = sel.rangeCount; i < len; ++i) {
        container.appendChild(sel.getRangeAt(i).cloneContents());
      }
      html = container.innerHTML;
    }
  } else if (typeof document.selection != "undefined") {
    if (document.selection.type === "Text") {
      html = document.selection.createRange().htmlText;
    }
  }
  return html;
}

//Extract selected text as string
export function getSelections() {
  let SELECTED_TEXT = "";
  if (window.getSelection()) {
    let str = "" + window.getSelection();
    if (str !== SELECTED_TEXT && str.length > 0) {
      SELECTED_TEXT = str;
    }
  } else if (document.getSelection()) {
    let str = "" + document.getSelection();
    if (str !== SELECTED_TEXT && str.length > 0) {
      SELECTED_TEXT = str;
    }
  }
  return SELECTED_TEXT;
}

//User selected text
export function getSelectedText(str) {
  let selectedItem = getSelectionHtml();
  let selectedText = getSelections();
  if (selectedItem.length > 0) {
    selectedText = selectedItem;
  } else {
    if (!(selectedText.length > 0)) {
      selectedText = str;
    } else {
      selectedText = getSelections();
    }
  }
  return selectedText.trim();
}

//Get Default Selected Brand Details
export const getDefaultBrandDetails = async () => {
  let DEFAULT_BRAND_DETAILS;
  try {
    const defaultSelectedBrand = JSON.parse(
      localStorage.getItem("defaultSelectedBrand")
    );
    DEFAULT_BRAND_DETAILS = defaultSelectedBrand;
  } catch (error) {
    DEFAULT_BRAND_DETAILS = null;
  }
  return DEFAULT_BRAND_DETAILS;
};

//Get Default Selected Brand Id
export const getUserDetails = async () => {
  let USER_DETAILS;
  try {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    USER_DETAILS = userDetails;
  } catch (error) {
    USER_DETAILS = null;
  }
  return USER_DETAILS;
};

//Check Object is empty or not
export function isEmptyObject(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

//Get today date
export function getTodayDate() {
  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + time;
  return dateTime;
}

//Conver Object entites to Array
export function objToArray(obj) {
  if (isEmptyObject(obj)) {
    return EMPTY_ARRAY;
  } else {
    return Object.keys(obj)
      .slice()
      .map((key) => ({ column: [key], value: obj[key] }));
  }
}

//Convert dd/mm/yyyy to mm/dd/yyyy format
export function monthDateYear(d) {
  if (d === null) {
    return EMPTY_STRING;
  } else {
    const d1 = d.toLocaleDateString();
    const dd = d1.replace(/^(\d{1,2}\/)(\d{1,2}\/)(\d{4})$/, "$2$1$3");
    const split = dd.split("/");
    return [split[1], split[0], split[2]].join("/");
  }
}

export function localDateTime(date_ob) {
  if (date_ob === null) {
    return EMPTY_STRING;
  } else {
    

// adjust 0 before single digit date
let date = ("0" + date_ob.getDate()).slice(-2);

// current month
let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

// current year
let year = date_ob.getFullYear();

// current hours
let hours = date_ob.getHours();

// current minutes
let minutes = date_ob.getMinutes();

// current seconds
let seconds = date_ob.getSeconds();
// return date & time in YYYY-MM-DD HH:MM:SS format
return (year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds);
  }
}

//Removed Deafult Selected Brand if matches the deleted brand
export function updateNoBrandDetails(id) {
  const defaultSelectedBrand = JSON.parse(
    localStorage.getItem("defaultSelectedBrand")
  );
  if (defaultSelectedBrand !== null) {
    const defaultSelectedBrandId = defaultSelectedBrand.id;
    if (parseInt(id) === parseInt(defaultSelectedBrandId)) {
      document.getElementById("selectedBrandName").innerText = "no Brand Selected";
      //document.getElementById("selectedBrandId").innerText = 0;
      localStorage.setItem(
        "defaultSelectedBrand",
        JSON.stringify(NO_DEFAUL_BRAND_SELECTED)
      );
    }
  }
}

//Conver single char to two digit char
export function singleToDoubleChar(d) {
  return d.toString().length === 1 ? "0" + d : d;
}

//Set or unset Spy user
export function updateAppUser(responseOne, responseTwo) {
  if (responseOne.data.status === SUCCESS) {
    localStorage.setItem(
      "userDetails",
      JSON.stringify(responseOne.data.response.userDetails)
    );
  } else {
    toast.error(responseOne.data.message);
  }

  if (responseTwo.data.status === SUCCESS) {
    localStorage.setItem(
      "defaultSelectedBrand",
      JSON.stringify(responseTwo.data.response)
    );
  } else {
    toast.error(responseTwo.data.message);
  }

  setTimeout(() => {
    window.location.href = "/workshop";
  }, 1000);
}

export const getKibanaUrl = async () => {
  try {
    const userDetails = await JSON.parse(localStorage.getItem("userDetails"));
    const kibanaURL = userDetails.kibanaURL;
    return kibanaURL;
  } catch (error) {
    return null;
  }
};

export const replaceSpaces = (str, char) => {
  try {
    return str.replace(" ", char);
  } catch (error) {
    return str;
  }
};

export const removeEmptyValueObj = (obj) => {
  for (var prop in obj) {
    //if (obj[prop] === null || obj[prop] === undefined) {
    if (obj[prop] === undefined) {
      delete obj[prop];
    }
  }
  return obj;
};

// JSON to CSV Converter
export const ConvertToCSV = (objArray) => {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = "";

  str += '"'+ Object.keys(objArray[0]).join(',')+'"'+'\r\n'

  for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
          if (line !== "") line += ","

          line += array[i][index];
      }

      str += '"'+line+'"' + '\r\n';
  }

  return str;
}

//Returns File Type
export const getFileType = (file) => {
  if(file) {
    const nameArry = file.name.split('.').reverse();
    return nameArry[0];
  }
  return null
}

//Download JSON File
export const handleJSONFileDownload = (data, fileName) => {
  const blob = new Blob([data], {
    type: "text/json;charset=utf-8;"
  });

  const url = window.URL.createObjectURL(new Blob([blob]));
  var link=document.createElement('a');
  document.body.appendChild(link);
  link.href=url;
  link.download = fileName+".json";
  link.click();
  document.body.removeChild(link);
}

//Download csv File
export const handleCsvFileDownload = (data, fileName) => {
  const blob = new Blob([data], {
    type: "text/csv;charset=utf-8;"
  });

  const url = window.URL.createObjectURL(new Blob([blob]));
  var link=document.createElement('a');
  document.body.appendChild(link);
  link.href=url;
  link.download = fileName+".csv";
  link.click();
  document.body.removeChild(link);
}

//Download EXcell File Format
export const handleExcelFileDownload=(data, fileName) => {
  const blob = new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"
  });

  const url = window.URL.createObjectURL(new Blob([blob]));
  var link=document.createElement('a');
  document.body.appendChild(link);
  link.href=url;
  link.download = fileName+".xlsx";
  link.click();
  document.body.removeChild(link);
}

//Hightlight by word with clean string
export const stripHtmlTagHighLightWord = (str, word) => {
  if (str.trim().length === 0) return "";
  if (word === undefined) return str;
  const substring = new RegExp(word, "ig");
  const strippedString =  str.replace( /(<([^>]+)>)/ig, '');
  return strippedString.replace(substring, (match) => `<span>${match}</span>`);
};

//Replace string with matched word
export const replaceWord= (str, old, newWord) => {
  console.log(str)
  console.log(old)
  console.log(newWord)
  if (str.trim().length === 0) return "";
  if (old === undefined) return str;
  if (newWord === undefined) return str;
  //const strippedString =  str.replace( /(<([^>]+)>)/ig, '');
  const substring = new RegExp(old, "ig");

  return str.replace(substring, newWord);
};

//Remove duplicates from array
export const removeDuplicates = (array) => {
  const uniqueSet = new Set(array)
  return [...uniqueSet]
}

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.reverse().map(item => [item[key], item])).values()]
}

// Clear Cognito Local Storage Items
export const clearCognitoLocalStorageItems = () => {
  const keys = Object.entries(localStorage).map(
    x => x[0]
  ).filter(
    x => x.search("CognitoIdentityServiceProvider") > -1
  )

  keys.forEach((key) => {
    localStorage.removeItem(key)
  })
}

// Clear Application LocalStorage items
export const clearAppLocalStorage = () => {
  localStorage.removeItem("defaultSelectedBrand");
  localStorage.removeItem("userDetails");
  localStorage.removeItem("userGroups");
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("spyUser");
  localStorage.removeItem("kibanaUrl");
  localStorage.removeItem("pipeline");
}

export const resizeSetup = (elementId, spacing) => {
  try {
    // Query the element
    const resizer = document.getElementById(elementId);
    if (!resizer) return
    
    const leftSide = resizer.previousElementSibling;
    const rightSide = resizer.nextElementSibling;

    // The current position of mouse
    let x = 0;
    let y = 0;
    let leftWidth = 0;

    // Handle the mousedown event
    // that's triggered when user drags the resizer
    const mouseDownHandler = function(e) {
      // Get the current mouse position
      x = e.clientX;
      y = e.clientY;
      leftWidth = leftSide.getBoundingClientRect().width;
      // Attach the listeners to `document`
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };

    const mouseMoveHandler = function(e) {
      // How far the mouse has been moved
      const dx = e.clientX - x;
      const dy = e.clientY - y;

      const newLeftWidth = (leftWidth + dx) * 100 / resizer.parentNode.getBoundingClientRect().width;
      leftSide.style.width = `${newLeftWidth}%`;
      rightSide.style.width = `calc(${100 - newLeftWidth}% - ${spacing}px)`

      resizer.style.cursor = 'col-resize';
      document.body.style.cursor = 'col-resize';

      leftSide.style.userSelect = 'none';
      leftSide.style.pointerEvents = 'none';

      rightSide.style.userSelect = 'none';
      rightSide.style.pointerEvents = 'none';
    };

    const mouseUpHandler = function() {
      resizer.style.removeProperty('cursor');
      document.body.style.removeProperty('cursor');

      leftSide.style.removeProperty('user-select');
      leftSide.style.removeProperty('pointer-events');

      rightSide.style.removeProperty('user-select');
      rightSide.style.removeProperty('pointer-events');

      // Remove the handlers of `mousemove` and `mouseup`
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };

    // Attach the handler
    resizer.addEventListener('mousedown', mouseDownHandler);
  } catch(err) {
    console.log(err);
  }
}

export const updateDefaultBrandDetails = (id, name) => {
  const defaultBrand = JSON.parse(
    localStorage.getItem("defaultSelectedBrand")
  );
  if (defaultBrand && defaultBrand.id === id) {
    const updatedData = {
      ...defaultBrand,
      name: name
    }
    localStorage.setItem("defaultSelectedBrand", JSON.stringify(updatedData));
  }
}

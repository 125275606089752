import { ADD_TOPIC, CREATE_PALETTE, DELETE_PALETTE, CLEAR_TOPICS, DELETE_TOPIC, ADD_BRAND_PALETTES, SAVE_PALETTE_DATA } from '../actions/types'
import get from 'lodash.get'

let initialState = {}

export default function TopicPaletteReducer(state = initialState, action) {
  const palette = get(action, 'palette')

  switch (action.type) {
    case ADD_TOPIC: {
      return palette
    }
    case CREATE_PALETTE: {
      return palette
    }
    case DELETE_PALETTE: {
      return palette
    }
    case CLEAR_TOPICS: {
      return palette
    }
    case DELETE_TOPIC: {
      return palette
    }
    case ADD_BRAND_PALETTES: {
      return palette
    }
    case SAVE_PALETTE_DATA: {
      return palette
    }
    default: {
      return state
    }
  }
}
export const RECAPTCHA_SITE_KEY = "6LcoL1QUAAAAAH9rHWAqiayic2oIXNuZ2U6wUG0Q";
export const SERVER_IMG_PATH =
  "https://s3.amazonaws.com/coraid_001/decooda-images/brand-images/";

export const APIGEE_URL = process.env.REACT_APP_APIGEE_URL;

export const RELEASE_VERSION = '0.10.8B';

export const PLAN_BASIC = 1;
export const PLAN_PROFESSIONAL = 2;
export const PLAN_ENTERPRISE = 3;
export const PLAN_ADMINISTRATION = 4;

export const SENTIMENT = "Sentiment";
export const PERFORMANCE = "Performance";
export const PERSONA = "Personas";
export const PURCHASEPATH = "PurchasePath";
export const TOPICS = "Topics";

export const BRAND = "brand";
export const MODEL = "model";
export const CLASSIFIER = "classifier";
export const COLLECTION = "collection";
export const CATALOGUE = "catalogue";

export const REGULAR_CLASSIFIER = "regular_classifier";
export const VIRTUAL_CLASSIFIER = "virtual_classifier";
export const CALCULATED_CLASSIFIER = "calculated_classifier";

export const EMPTY_STRING = "";
export const DEFAULT = "default";
export const FOLDER = "folder";
export const FILE = "file";
export const ASCENDING_ORDER = "asc";
export const DESCENDING_ORDER = "desc";

export const NEW = "New";
export const CREATE = "Create";
export const RENAME = "Rename";
export const DELETE = "Delete";
export const COPY = "Copy";
export const CREATE_FOLDER = "Create Folder";
export const EXPORT = "Export";
export const IMPORT = "Import";
export const LISTVIEW = "List View";
export const DISCOVER_TOPIC = "Discover Topic";
export const EXPORT_CLASSIFIER_NAMES = "Export Classifier Names";
export const EXPORT_ID_NAMES = "Export Id and Name";

export const WARN = "WARN";
export const ERROR = "ERROR";
export const SUCCESS = "SUCCESS";

export const LOADING_TREE = "Loading tree...";
export const EMPTY_MSG = "No data available";
export const EMPTY_EXPORT_DATA = "No data to export";
export const DEFAULT_FREQUENCY = 5;
export const EMPTY_OBJECT = {};
export const EMPTY_ARRAY = [];
export const DEFAULT_COUNT = 0;
export const DEFAULT_PAGE = 1;
export const DEFAULT_ROWS_PER_PAGE = 100;
export const DEFAULT_GRAMS = "All";
export const ERROR_UNABLE = "Unable to process";
export const NO_DEFAUL_BRAND_SELECTED = {
  id: 0,
  name: "no Brand Selected",
  modelName: EMPTY_STRING,
};

export const ERROR_MESSAGE =
  "Oops: There is some error in server side. Please try after some time.";
export const ERROR_IN_PROCESS =
  "ERROR: There is some error with the process. Please check after some time.";
export const ERROR_SERVER_NOT_RESPONDING =
  "Server is not responding right now. Please try after some time.";
export const FAILED_RESPONSE_MESSAGE = "Failed to load response data.";
export const FAILED_RESPONSE_MESSAGE2 =
  "Something went wrong. Please try after some time.";
export const SESSION_EXPIRED = "Your Session Expired. Please login.";

export const NOTE_TEXTDETAILS = "You can add selected phrases from ContextMenu on Title."
export const NOTE_ADD_TO_SPAM = "It will add author link of post in selected classifier."

export const GENDER_OPTIONS = ["male", "female", "unknown"];
export const CONFIDENCE_OPTIONS = ["high", "medium", "low"];
export const IS_PERSONAL_OPTIONS = ["no", "all", "only 1st person"];
export const SAMPLE_OPTIONS = ["10", "25", "50", "75", "100"];
export const SENTIMENT_OPTIONS = ["positive", "negative", "nuetral"];
export const EMOTION_OPTIONS = [
  "anger",
  "confusion",
  "crave",
  "frustration",
  "gratitude",
  "happiness",
];
export const PURCHASEPATH_OPTIONS = [
  "unclassified",
  "churn_definate",
  "purch_intent_pos_v2",
  "unclassified",
  "churn_definate",
  "purch_intent_pos_v2",
  "unclassified",
  "churn_definate",
  "purch_intent_pos_v2",
  "unclassified",
  "churn_definate",
  "purch_intent_pos_v2",
];
export const TOPICS_OPTIONS = [
  "unclassified",
  "agent_communication",
  "agent_cust_exp_gen",
  "billing_price",
];
export const PERSONA_OPTIONS = ["unclassified", "green_buyer"];
export const PERFORMANCE_OPTIONS = [
  "unclassified",
  "amaze",
  "as_expeted",
  "attitude_behavior_shift",
  "better_than",
];
export const FILTER_OPTIONS = ["spam", "promo", "unclassified"];

export const DATA_SOURCE_UPLOD = "UPLOAD";
export const DATA_SOURCE_TWITTER = "TWITTER";
export const DATA_SOURCE_FACEBOOK = "FACEBOOK";
export const DATA_SOURCE_BAZAAR_VOICE = "BAZAARVOICE";
export const DATA_SOURCE_TYPE_FORM = "TYPEFORM";
export const DATA_SOURCE_WUFOO = "WUFOO";
export const DATA_SOURCE_API_DATA = "API DATA";
export const DATA_SOURCE_S3 = "S3";

export const OB_IP_ERROR_MESSAGE = "IP Address cannot be empty.";
export const OB_PORT_ERROR_MESSAGE = "Port cannot be empty.";
export const OB_USERNAME_ERROR_MESSAGE = "User Name cannot be empty.";
export const OB_DATABASENAME_ERROR_MESSAGE = "Data Base Name cannot be empty.";
export const OB_PASSWORD_ERROR_MESSAGE = "Password cannot be empty.";
export const OB_SCHEMA_ERROR_MESSAGE = "Schema cannot be empty.";
export const OB_WAREHOUSE_ERROR_MESSAGE = "Warehouse cannot be empty.";

export const SENTIMENT_SUMMARY_DETAILS = {
  title: "Sentiment Summary",
  chartData: EMPTY_ARRAY,
  categories: EMPTY_STRING,
};
export const SENTIMENT_TRENDS_DETAILS = {
  title: "Sentiment Trend",
  chartData: EMPTY_ARRAY,
  categories: EMPTY_STRING,
};
export const SENTIMENT_MEDIATABLE_DETAILS = {
  title: "Sentiment By Media Table",
  data: EMPTY_ARRAY,
};
export const EMOTION_SUMMARY_DETAILS = {
  title: "Emotion Summary",
  chartData: EMPTY_ARRAY,
  categories: EMPTY_STRING,
};
export const EMOTION_TRENDS_DETAILS = {
  title: "Emotion Trend",
  chartData: EMPTY_ARRAY,
  categories: EMPTY_STRING,
};
export const TOPICS_MEDIATABLE_DETAILS = {
  title: "Topics Table",
  data: EMPTY_ARRAY,
};
export const PERFORMANCE_MEDIATALE_DETAILS = {
  title: "Performance Table",
  data: EMPTY_ARRAY,
};
export const PERSONA_MEDIATABLE_DETAILS = {
  title: "Personas Table",
  data: EMPTY_ARRAY,
};
export const PURCHASEPATH_MEDIATABLE_DETAILS = {
  title: "PurchasePath Table",
  data: EMPTY_ARRAY,
};

export const COMP_SENTIMENT_DATA = {
  type: "bar",
  title: "Sentiment Comparison",
  chartData: [{ name: EMPTY_STRING, data: EMPTY_ARRAY }],
  categories: EMPTY_ARRAY,
  attribute: EMPTY_STRING,
};
export const COMP_EMOTION_DATA = {
  type: "column",
  title: "Emotion Comparison",
  chartData: [{ name: EMPTY_STRING, data: EMPTY_ARRAY }],
  categories: EMPTY_ARRAY,
  attribute: EMPTY_STRING,
};
export const COMP_PERSONA_DATA = {
  type: "column",
  title: "Persona Comparison",
  chartData: [{ name: EMPTY_STRING, data: EMPTY_ARRAY }],
  categories: EMPTY_ARRAY,
  attribute: EMPTY_STRING,
};
export const COMP_PERFORMANCE_DATA = {
  type: "column",
  title: "Performance Comparison",
  chartData: [{ name: EMPTY_STRING, data: EMPTY_ARRAY }],
  categories: EMPTY_ARRAY,
  attribute: EMPTY_STRING,
};
export const COMP_PURCHASE_DATA = {
  type: "column",
  title: "Purchase Comparison",
  chartData: [{ name: EMPTY_STRING, data: EMPTY_ARRAY }],
  categories: EMPTY_ARRAY,
  attribute: EMPTY_STRING,
};
export const COMP_TOPICS_DATA = {
  type: "column",
  title: "Topics Comparison",
  chartData: [{ name: EMPTY_STRING, data: EMPTY_ARRAY }],
  categories: EMPTY_ARRAY,
  attribute: EMPTY_STRING,
};

export const POSITIVE_EMOTIONS = ['happiness', 'crave', 'gratitude', 'excitement']
export const NEGATIVE_EMOTIONS = ['anger', 'frustration', 'disappointment', 'confusion']

// Data Source Pre-Processing Constants
export const NORMALIZE_PUNCTUATION_OPTIONS = [
  { name: 'hyphen', label: 'Replace “-” with nothing ““', regex: '-', replacement: '', checked: true },
  { name: 'forwardSlash', label: 'Replace “/” with a space', regex: '\\/', replacement: ' ', checked: true},
  { name: 'brackets', label: 'Replace parenthesis and brackets ()[]{} with nothing ““', regex: '[\\(\\)\\[\\]\\{\\}]', replacement: '', checked: false },
  { name: 'sentenceBoundaries', label: 'Normalize sentence boundaries: Replace *?!:; characters with a period', regex: '[*?!:;]', replacement: '.', checked: false }
]

export const NORMALIZE_SPACING_OPTION = {
  regex: '[\\t\\r\\n]|\\s+', replacement: ' ', checked: true
}

// Palette Colors
export const PALETTE_COLORS = [
  '#d1c4e9',
  '#f8bbd0',
  '#c5cae9',
  '#d4e157',
  '#d7ccc8',
  '#ffcc80',
  '#ccff90',
  '#fff176'
]

/*********** MOCK DATA **********/

export const COLUMN_CATEGORIES = [
  "excitement",
  "happiness",
  "crave",
  "gratitude",
  "confusion",
  "disappointment",
  "frustration",
  "anger",
];
export const BAR_CATEGORIES = ["positive", "negative", "neutral"];

export const COLUMN_DATA = [
  {
    name: "test-02",
    data: [
      0.398406,
      2.390438,
      6.374502,
      0.398406,
      0.796813,
      0.398406,
      2.788845,
      0,
    ],
    color: "#bbbbbb",
  },
  { name: "test-03", data: [0, 16.666667, 0, 0, 0, 0, 0, 0], color: "#bbbbbb" },
  { name: "cxvb", data: [0, 16.666667, 0, 0, 0, 0, 0, 0], color: "#bbbbbb" },
];
export const BAR_DATA = [
  {
    name: "test-02",
    data: [22.310757, 10.358566, 67.330677],
    color: "#bbbbbb",
  },
  { name: "test-03", data: [66.666667, 0, 33.333333], color: "#bbbbbb" },
  { name: "cxvb", data: [66.666667, 0, 33.333333], color: "#bbbbbb" },
];

export const AVAILABLE_LIST = [
  {
    column: ["Retail (In Store)"],
    value: [
      {
        brandId: 30020,
        brandName: "test-bd-02",
        brandOwner: false,
        image: "default-image",
        isDefault: false,
        isSelected: false,
        modelId: 378,
        modelName: "Retail (In Store)",
        userId: "00u4l0c7agoY0GtdM4x6",
      },
      {
        brandId: 30327,
        brandName: "test-new-bd-01",
        brandOwner: false,
        image: "default-image",
        isDefault: false,
        isSelected: false,
        modelId: 378,
        modelName: "Retail (In Store)",
        userId: "00u4l0c7agoY0GtdM4x6",
      },
    ],
  },
  {
    column: ["Telecom_2"],
    value: [
      {
        brandId: 30051,
        brandName: "test-bd-04",
        brandOwner: false,
        image: "default-image",
        isDefault: false,
        isSelected: false,
        modelId: 861,
        modelName: "Telecom_2",
        userId: "00u4l0c7agoY0GtdM4x6",
      },
      {
        brandId: 30019,
        brandName: "test-bd-01",
        brandOwner: false,
        image: "default-image",
        isDefault: false,
        isSelected: false,
        modelId: 861,
        modelName: "Telecom_2",
        userId: "00u4l0c7agoY0GtdM4x6",
      },
      {
        brandId: 30049,
        brandName: "te",
        brandOwner: false,
        image: "default-image",
        isDefault: false,
        isSelected: false,
        modelId: 861,
        modelName: "Telecom_2",
        userId: "00u4l0c7agoY0GtdM4x6",
      },
      {
        brandId: 30034,
        brandName: "tevc",
        brandOwner: false,
        image: "default-image",
        isDefault: false,
        isSelected: false,
        modelId: 861,
        modelName: "Telecom_2",
        userId: "00u4l0c7agoY0GtdM4x6",
      },
      {
        brandId: 30076,
        brandName: "tes",
        brandOwner: false,
        image: "default-image",
        isDefault: false,
        isSelected: false,
        modelId: 861,
        modelName: "Telecom_2",
        userId: "00u4l0c7agoY0GtdM4x6",
      },
      {
        brandId: 30041,
        brandName: "tess",
        brandOwner: false,
        image: "default-image",
        isDefault: false,
        isSelected: false,
        modelId: 861,
        modelName: "Telecom_2",
        userId: "00u4l0c7agoY0GtdM4x6",
      },
    ],
  },
];

export const TREE_MOCK_DATA = [
  {
    key: "1",
    title: "decooda",
    parentId: 0,
    children: [
      {
        key: "315",
        title: "Quick Service Resturants - Deprecated",
        parentId: 1,
        isLeaf: true,
      },
      {
        key: "360",
        title: "Standard Cognitive States (NPS)",
        parentId: 1,
        isLeaf: true,
      },
      {
        key: "377",
        title: "Telecom",
        parentId: 1,
        isLeaf: true,
      },
      {
        key: "378",
        title: "Retail (In Store)",
        parentId: 1,
        isLeaf: true,
      },
      {
        key: "437",
        title: "Telecom_2",
        parentId: 1,
        isLeaf: true,
      },
      {
        key: "438",
        title: "Hotel & Hospitality",
        parentId: 1,
        isLeaf: true,
      },
      {
        key: "479",
        title: "Retail (eCommerce)",
        parentId: 1,
        isLeaf: true,
      },
    ],
  },
  {
    key: "0",
    title: "My Models",
    parentId: 0,
    children: [
      {
        key: "604",
        title: "dowjones",
        parentId: 0,
        isLeaf: true,
      },
      {
        key: "614",
        title: "apple",
        parentId: 0,
        isLeaf: true,
      },
      {
        key: "642",
        title: "m",
        parentId: 0,
        isLeaf: true,
      },
      {
        key: "751",
        title: "tes-01",
        parentId: 0,
        isLeaf: true,
      },
      {
        key: "754",
        title: "test-folder",
        parentId: 0,
      },
      {
        key: "7543",
        title: "test-folder2",
        parentId: 0,
        children: [
          {
            key: "7475",
            title: "sder",
            parentId: 754,
          },
        ],
      },
      {
        key: "768",
        title: "ghd",
        parentId: 0,
        isLeaf: true,
      },
      {
        key: "769",
        title: "test-empty",
        parentId: 0,
        isLeaf: true,
      },
      {
        key: "770",
        title: "check-emp",
        parentId: 0,
        isLeaf: true,
      },
      {
        key: "771",
        title: "emp-tes",
        parentId: 0,
        isLeaf: true,
      },
      {
        key: "772",
        title: "ers",
        parentId: 0,
        isLeaf: true,
      },
      {
        key: "773",
        title: "empty-model-01",
        parentId: 0,
        isLeaf: true,
      },
      {
        key: "774",
        title: "emmmm",
        parentId: 0,
        isLeaf: true,
      },
    ],
  },
  {
    key: "-1",
    title: "Shared",
    parentId: 0,
    children: [
      {
        key: "509",
        title: "MultiSector ",
        parentId: 554,
        isLeaf: true,
      },
      {
        key: "707",
        title: "mn",
        parentId: 0,
        isLeaf: true,
      },
      {
        key: "728",
        title: "model_test",
        parentId: 727,
        isLeaf: true,
      },
      {
        key: "729",
        title: "share_model",
        parentId: 0,
        isLeaf: true,
      },
      {
        key: "730",
        title: "humpty_model",
        parentId: 727,
        isLeaf: true,
      },
    ],
  },
];

export const DRILLDOWN_MOCK_DATA = [
  {
    uid: "3437|my_data|598ed712-038c-4214-b39b-48a11564fd0c|3",
    contentId: "598ed712-038c-4214-b39b-48a11564fd0c",
    date: "2018-05-16",
    gender: "unknown",
    media: "pinterest",
    sentiment: "positive",
    emotion: ["unclassified"],
    confidence: "low",
    personas: ["unclassified"],
    topics: ["standard_comparatives", "standard_adjectives"],
    performance: ["unclassified"],
    purchasePath: ["unclassified"],
    filters: ["unclassified"],
    authorLink: "",
    authorName: "unknown",
    authorUserName: "",
    title:
      "Trying Nguyen Coffee. Vietnam's version of Starbucks. Best coffee I've had in a very long time! http://t.co/DSKIvIeI",
    isRemoveable: false,
    socialType: "",
  },
  {
    uid: "3437|my_data|093d1e08-f2d0-4b51-8b3b-e3a0d53e8b66|3",
    contentId: "093d1e08-f2d0-4b51-8b3b-e3a0d53e8b66",
    date: "2018-05-16",
    gender: "unknown",
    media: "my_data",
    sentiment: "negative",
    emotion: ["unclassified"],
    confidence: "low",
    personas: ["unclassified"],
    topics: ["unclassified"],
    performance: ["unclassified"],
    purchasePath: ["unclassified"],
    filters: ["unclassified"],
    authorLink: "",
    authorName: "unknown",
    authorUserName: "",
    title: "tell me of you want me to bring you Starbucks",
    isRemoveable: false,
    socialType: "",
  },
  {
    uid: "3437|my_data|d449646b-30d8-4ed8-aea7-bc9696ce232b|3",
    contentId: "d449646b-30d8-4ed8-aea7-bc9696ce232b",
    date: "2018-05-16",
    gender: "unknown",
    media: "wufoo",
    sentiment: "neutral",
    emotion: ["unclassified"],
    confidence: "low",
    personas: ["unclassified"],
    topics: ["unclassified"],
    performance: ["unclassified"],
    purchasePath: ["unclassified"],
    filters: ["unclassified"],
    authorLink: "",
    authorName: "unknown",
    authorUserName: "",
    title: "Who can check for me how much is the Starbucks bottle?",
    isRemoveable: false,
    socialType: "",
  },
  {
    uid: "3437|my_data|9ac42627-e7d0-4d71-b5df-f2706c53ed6c|3",
    contentId: "9ac42627-e7d0-4d71-b5df-f2706c53ed6c",
    date: "2018-05-16",
    gender: "unknown",
    media: "facebook",
    sentiment: "negative",
    emotion: ["frustration"],
    confidence: "medium",
    personas: ["unclassified"],
    topics: ["unclassified"],
    performance: ["needs_unmet"],
    purchasePath: ["unclassified"],
    filters: ["unclassified"],
    authorLink: "",
    authorName: "unknown",
    authorUserName: "",
    title:
      "@Nik_niks666 I've been waiting in starbucks since 2:30 for martin to meet me and I'm still waiting",
    isRemoveable: false,
    socialType: "",
  },
  {
    uid: "3437|my_data|ccf7cc8d-a9be-40f7-9f74-f9723d68df0b|3",
    contentId: "ccf7cc8d-a9be-40f7-9f74-f9723d68df0b",
    date: "2018-05-16",
    gender: "unknown",
    media: "api_data",
    sentiment: "neutral",
    emotion: ["unclassified"],
    confidence: "low",
    personas: ["unclassified"],
    topics: ["unclassified"],
    performance: ["unclassified"],
    purchasePath: ["unclassified"],
    filters: ["unclassified"],
    authorLink: "",
    authorName: "unknown",
    authorUserName: "",
    title:
      "I'm at Starbucks w/ @boraksy @erykaradeniz @altugbalki @cemsobe http://t.co/jVATooDB",
    isRemoveable: false,
    socialType: "",
  },
  {
    uid: "3437|my_data|5fb06eba-36d8-4479-b501-6a88decd30f2|3",
    contentId: "5fb06eba-36d8-4479-b501-6a88decd30f2",
    date: "2018-05-16",
    gender: "unknown",
    media: "bazaarvoice",
    sentiment: "neutral",
    emotion: ["unclassified"],
    confidence: "low",
    personas: ["unclassified"],
    topics: ["unclassified"],
    performance: ["unclassified"],
    purchasePath: ["unclassified"],
    filters: ["unclassified"],
    authorLink: "",
    authorName: "unknown",
    authorUserName: "",
    title: "my mom ordering at Starbucks is the funniest thing ever",
    isRemoveable: false,
    socialType: "",
  },
  {
    uid: "3437|my_data|aca98e84-2838-4224-bcb7-b023479ca6d7|3",
    contentId: "aca98e84-2838-4224-bcb7-b023479ca6d7",
    date: "2018-05-16",
    gender: "unknown",
    media: "twitter",
    sentiment: "neutral",
    emotion: ["unclassified"],
    confidence: "low",
    personas: ["unclassified"],
    topics: ["unclassified"],
    performance: ["unclassified"],
    purchasePath: ["unclassified"],
    filters: ["unclassified"],
    authorLink: "",
    authorName: "unknown",
    authorUserName: "",
    title:
      "I'm at Starbucks (?stanbul, T�rkiye) w/ 4 others http://t.co/6mPcqg6N",
    isRemoveable: false,
    socialType: "",
  },
  {
    uid: "3437|my_data|d0b1f8f7-54f8-454d-aa1a-469fa993f694|3",
    contentId: "d0b1f8f7-54f8-454d-aa1a-469fa993f694",
    date: "2018-05-16",
    gender: "unknown",
    media: "email",
    sentiment: "positive",
    emotion: ["unclassified"],
    confidence: "low",
    personas: ["unclassified"],
    topics: ["unclassified"],
    performance: ["unclassified"],
    purchasePath: ["unclassified"],
    filters: ["unclassified"],
    authorLink: "",
    authorName: "unknown",
    authorUserName: "",
    title:
      "The guys that ride that bike and have crazy hats all the time are next to me in line at Starbucks.",
    isRemoveable: false,
    socialType: "",
  },
];

export const TRENDS_MOCK_DATA = [
  {
    name: "positive",
    data: [
      [1330387222528, 2],
      [1330473598976, 1],
      [1330819235840, 1],
      [1330905612288, 1],
      [1330991988736, 1],
      [1331424002048, 1],
      [1331510378496, 1],
      [1331856015360, 1],
      [1331942391808, 1],
      [1332028768256, 2],
      [1332115144704, 1],
      [1332201652224, 6],
      [1332288028672, 13],
      [1332374405120, 14],
      [1332460781568, 10],
      [1332547158016, 1],
      [1332633665536, 2],
      [1332720041984, 2],
      [1332806418432, 4],
      [1332892794880, 8],
      [1332979171328, 1],
      [1333065547776, 5],
      [1333152055296, 5],
      [1333324808192, 3],
      [1333411184640, 2],
      [1333497561088, 3],
      [1333583937536, 7],
      [1333670445056, 4],
      [1333756821504, 1],
      [1333843197952, 2],
      [1333929574400, 3],
      [1334015950848, 4],
      [1334102458368, 1],
      [1334275211264, 1],
      [1334361587712, 2],
      [1334447964160, 4],
      [1334534340608, 7],
      [1334620848128, 5],
      [1334707224576, 9],
      [1334793601024, 14],
      [1334879977472, 3],
      [1334966353920, 2],
      [1335052861440, 1],
      [1335225614336, 4],
      [1335311990784, 2],
      [1335398367232, 5],
      [1335484743680, 1],
      [1335571251200, 2],
      [1335657627648, 1],
      [1335744004096, 1],
      [1335830380544, 2],
      [1336003264512, 1],
      [1336089640960, 1],
      [1336176017408, 1],
      [1336348770304, 1],
      [1336608030720, 1],
      [1336780783616, 1],
      [1337126420480, 1],
      [1337212796928, 4],
      [1337299173376, 3],
      [1337385549824, 1],
      [1337472057344, 1],
      [1337558433792, 4],
      [1337817563136, 6],
      [1337990447104, 4],
      [1338076823552, 4],
      [1338249576448, 2],
      [1338595213312, 1],
      [1338854342656, 1],
      [1339027226624, 2],
      [1339113603072, 1],
      [1340064006144, 1],
      [1340236759040, 1],
      [1340409643008, 1],
      [1340668772352, 1],
      [1340755148800, 2],
      [1341100785664, 1],
      [1341187162112, 1],
      [1341705551872, 1],
      [1342396825600, 1],
      [1342483202048, 1],
      [1342828838912, 1],
      [1343001591808, 1],
      [1343347228672, 1],
      [1343606358016, 1],
      [1343692734464, 1],
      [1343779241984, 1],
      [1344470384640, 2],
      [1345334411264, 2],
      [1346198437888, 1],
      [1346457567232, 1],
      [1346630451200, 1],
      [1346803204096, 1],
      [1347321593856, 1],
      [1347839983616, 2],
      [1348271996928, 1],
      [1348617633792, 1],
      [1348704010240, 1],
      [1348790386688, 1],
      [1348963139584, 2],
      [1349308776448, 1],
      [1349654413312, 1],
      [1349913542656, 1],
      [1350863945728, 1],
      [1351468843008, 2],
      [1351987232768, 1],
      [1352419246080, 1],
      [1352764751872, 1],
      [1353110388736, 1],
      [1353369649152, 1],
      [1353542402048, 2],
      [1353888038912, 4],
      [1353974415360, 1],
      [1354147168256, 1],
      [1354233544704, 1],
      [1354579181568, 1],
      [1354752065536, 2],
      [1354838441984, 1],
      [1354924818432, 1],
      [1355011194880, 1],
      [1355097571328, 1],
      [1355270455296, 1],
      [1355443208192, 2],
      [1355529584640, 1],
      [1355615961088, 1],
      [1355702337536, 1],
      [1355788845056, 1],
      [1355875221504, 1],
      [1356047974400, 1],
      [1356307234816, 1],
      [1356393611264, 1],
      [1356566364160, 1],
      [1356652740608, 1],
      [1356739248128, 1],
      [1356912001024, 1],
      [1357084753920, 2],
      [1357257637888, 1],
      [1357344014336, 2],
      [1357689651200, 1],
      [1358121664512, 2],
      [1358294417408, 1],
      [1358380793856, 2],
      [1358467170304, 1],
      [1358985560064, 1],
      [1359071936512, 1],
      [1359158444032, 1],
      [1359244820480, 2],
      [1359417573376, 1],
      [1359503949824, 1],
      [1359849586688, 2],
      [1359935963136, 1],
      [1360022339584, 3],
      [1360195223552, 1],
      [1360454352896, 1],
      [1360540860416, 1],
      [1360713613312, 2],
      [1360886366208, 1],
      [1360972742656, 1],
      [1361059250176, 1],
      [1361145626624, 1],
      [1361404755968, 2],
      [1361664016384, 1],
      [1361750392832, 2],
      [1361836769280, 1],
      [1361923145728, 2],
      [1362009653248, 1],
      [1362096029696, 1],
      [1362355159040, 1],
      [1362700795904, 3],
      [1362787172352, 1],
      [1362873548800, 1],
      [1362960056320, 2],
      [1363132809216, 1],
      [1363391938560, 3],
      [1363651198976, 2],
      [1363910459392, 1],
      [1364169588736, 1],
      [1364255965184, 1],
      [1364342341632, 1],
      [1364515225600, 1],
      [1364774354944, 1],
      [1365033615360, 1],
      [1365206368256, 2],
      [1365292744704, 2],
      [1365379252224, 1],
      [1365465628672, 3],
      [1365724758016, 3],
      [1365984018432, 1],
      [1366070394880, 1],
      [1366156771328, 2],
      [1366243147776, 1],
      [1366329655296, 1],
      [1366502408192, 1],
      [1366588784640, 1],
      [1366675161088, 1],
    ],
    color: "#0ea994",
  },
  {
    name: "negative",
    data: [
      [1330559975424, 1],
      [1332201652224, 2],
      [1332288028672, 4],
      [1332374405120, 3],
      [1332547158016, 2],
      [1332633665536, 2],
      [1332720041984, 1],
      [1332806418432, 1],
      [1332979171328, 3],
      [1333238431744, 3],
      [1333411184640, 1],
      [1333843197952, 1],
      [1334015950848, 1],
      [1334188834816, 1],
      [1334361587712, 1],
      [1334707224576, 2],
      [1334793601024, 2],
      [1335052861440, 1],
      [1335139237888, 1],
      [1335225614336, 2],
      [1335398367232, 1],
      [1335484743680, 2],
      [1336003264512, 1],
      [1336176017408, 1],
      [1336521654272, 1],
      [1336694407168, 1],
      [1337212796928, 1],
      [1337472057344, 1],
      [1337817563136, 2],
      [1338508836864, 1],
      [1339459239936, 1],
      [1340236759040, 1],
      [1340496019456, 2],
      [1340668772352, 1],
      [1341532798976, 1],
      [1342051188736, 1],
      [1344556761088, 1],
      [1345680048128, 1],
      [1347753607168, 1],
      [1348271996928, 1],
      [1348531257344, 1],
      [1349222400000, 1],
      [1350691192832, 1],
      [1351295959040, 1],
      [1352937635840, 1],
      [1353974415360, 1],
      [1354838441984, 2],
      [1355270455296, 1],
      [1355356831744, 2],
      [1355875221504, 1],
      [1356652740608, 1],
      [1357689651200, 1],
      [1357776027648, 2],
      [1359676833792, 1],
      [1359935963136, 1],
      [1360627236864, 1],
      [1360886366208, 1],
      [1362268782592, 1],
      [1362441535488, 1],
      [1364515225600, 1],
      [1365638381568, 2],
      [1366156771328, 1],
      [1366502408192, 1],
      [1367193550848, 1],
    ],
    color: "#ef6a6a",
  },
  {
    name: "neutral",
    data: [
      [1332201652224, 1],
      [1332374405120, 1],
      [1332460781568, 1],
      [1332892794880, 1],
      [1333065547776, 1],
      [1333324808192, 1],
      [1333843197952, 1],
      [1334620848128, 1],
      [1334793601024, 1],
      [1334879977472, 1],
      [1335744004096, 1],
      [1336262393856, 2],
      [1336694407168, 1],
      [1340928032768, 1],
      [1341014409216, 1],
      [1341964812288, 1],
      [1342137565184, 1],
      [1342655954944, 1],
      [1344902397952, 1],
      [1346025553920, 1],
      [1346457567232, 1],
      [1349308776448, 1],
      [1350172803072, 1],
      [1350518439936, 1],
      [1351641595904, 1],
      [1353888038912, 1],
      [1353974415360, 1],
      [1356393611264, 1],
      [1357084753920, 1],
      [1357430390784, 1],
      [1358899183616, 1],
      [1360195223552, 2],
      [1360972742656, 1],
      [1362614419456, 1],
      [1363305562112, 1],
      [1363910459392, 1],
      [1364601602048, 1],
      [1364947238912, 1],
      [1365465628672, 1],
      [1365724758016, 1],
    ],
    color: "#607D88",
  },
];

export const COPARISON_BRANDS = {
  test1: [
    {
      brandId: 111,
      userId: "0L",
      brandName: "test11",
      brandOwner: true,
      isDefault: false,
      image: "test",
      modelId: 0,
      modelName: "test1",
      isSelected: false,
    },
    {
      brandId: 112,
      userId: "0L",
      brandName: "test12",
      brandOwner: true,
      isDefault: false,
      image: "test",
      modelId: 0,
      modelName: "test1",
      isSelected: false,
    },
    {
      brandId: 113,
      userId: "0L",
      brandName: "test13",
      brandOwner: true,
      isDefault: false,
      image: "test",
      modelId: 0,
      modelName: "test1",
      isSelected: false,
    },
    {
      brandId: 114,
      userId: "0L",
      brandName: "test14",
      brandOwner: true,
      isDefault: false,
      image: "test",
      modelId: 0,
      modelName: "test1",
      isSelected: false,
    },
    {
      brandId: 115,
      userId: "0L",
      brandName: "test15",
      brandOwner: true,
      isDefault: false,
      image: "test",
      modelId: 0,
      modelName: "test1",
      isSelected: false,
    },
    {
      brandId: 116,
      userId: "0L",
      brandName: "test16",
      brandOwner: true,
      isDefault: false,
      image: "test",
      modelId: 0,
      modelName: "test1",
      isSelected: false,
    },
  ],
  test2: [
    {
      brandId: 117,
      userId: "0L",
      brandName: "test21",
      brandOwner: true,
      isDefault: false,
      image: "test",
      modelId: 1,
      modelName: "test2",
      isSelected: false,
    },
    {
      brandId: 118,
      userId: "0L",
      brandName: "test22",
      brandOwner: true,
      isDefault: false,
      image: "test",
      modelId: 1,
      modelName: "test2",
      isSelected: false,
    },
  ],
  test3: [
    {
      brandId: 119,
      userId: "0L",
      brandName: "test31",
      brandOwner: true,
      isDefault: false,
      image: "test",
      modelId: 2,
      modelName: "test3",
      isSelected: false,
    },
    {
      brandId: 120,
      userId: "0L",
      brandName: "test32",
      brandOwner: true,
      isDefault: false,
      image: "test",
      modelId: 2,
      modelName: "test3",
      isSelected: false,
    },
    {
      brandId: 121,
      userId: "0L",
      brandName: "test33",
      brandOwner: true,
      isDefault: false,
      image: "test",
      modelId: 2,
      modelName: "test3",
      isSelected: false,
    },
  ],
};


export const ADT_MOCK_DATA = {
  "topic_and_phrases": [
    {
      "topic": "good place",
      "phrases": [
        {
          "phrase": "friendly place",
          "frequency": 1,
          "sem_sim": 0.6605876088142395,
          "char_sim": 0.7749999999999999,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.999848484992981
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS is a clean and friendly place to shop. It usually isn\u0027t my first choice because it is rather pricey."
          ]
        },
        {
          "phrase": "good quick place",
          "frequency": 1,
          "sem_sim": 0.8799357414245605,
          "char_sim": 0.7139435361276575,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998500943183899
            }
          ],
          "pos_tags": [
            "ADJ",
            "ADJ",
            "NOUN"
          ],
          "context": [
            "They are a good quick place to fill but prices are kind of high."
          ]
        },
        {
          "phrase": "wonderful place",
          "frequency": 1,
          "sem_sim": 0.784397542476654,
          "char_sim": 0.7113912121115784,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998827576637268
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS is a wonderful place to shop. It has a large number of available products for all sorts of needs."
          ]
        },
        {
          "phrase": "fantastic place",
          "frequency": 0,
          "sem_sim": 0.812269389629364,
          "char_sim": 0.715569529103174,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998807907104492
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": []
        },
        {
          "phrase": "one place",
          "frequency": 3,
          "sem_sim": 0.704402506351471,
          "char_sim": 0.835,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9936085939407349
            }
          ],
          "pos_tags": [
            "NUM",
            "NOUN"
          ],
          "context": [
            "local and near by location with all my health aid needs in one place",
            "CVS is a good overall drug store that carries all your needs in one place.  Prices are good and fair",
            "CVS has a wide range of products. I can find just about anything in one place."
          ]
        },
        {
          "phrase": "amazing place",
          "frequency": 1,
          "sem_sim": 0.7249034643173218,
          "char_sim": 0.865,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998801350593567
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "I fill all of my prescriptions at CVS  primarily due to their extended hours. Their pharmacists are always extremely knowledgeable and helpful. I have never had a negative experience there. I also love that I am able to shop for other items  during my short wait time. And if I don\u0027t feel like waiting  there is a drive-up window. It is an amazing place to fill prescriptions and shop. Furthermore  every CVS location I have visited is clean and has conveniently placed wide aisles."
          ]
        },
        {
          "phrase": "good place",
          "frequency": 48,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998519420623779
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "They are a good place to shop for personal items such as beauty products or filling prescription. The only inconvenience is that the prices are too high",
            "It is a really good place",
            "I have always liked CVS but do not have one close by.   If you had one close to you  it would likely be a good place to shop",
            "CVs is a good place to fill your prescriptions depending on your insurance.  Other products are higher price unless you have a coupon or exbucks or a sale.",
            "Its a good place to shop as long as its convenient.  You would need to check the prices on subscription meds",
            "CVS is a good place to shop. The staff is helpful and friendly and you can get good deals.",
            "CVS is a good place to shop ut it isn\u0027t very conveniently located in this neighborhood",
            "its good place to buy unique products where we cant get anywhere else but the prices are very soaring",
            "8use CVS its a good store reliable good place to shop",
            "it is a good place to shop",
            "Depends on your insurance whether filling a prescription is covered at CVS check to find out it\u0027s a good place to shop.",
            "CVS is a good place to shop. The employees and pharmacists are very helpful. They offer a lot of coupons.",
            "CVS is a good place to shop but is a little pricey.",
            "I like Cvs it is a good place to shop",
            "CVS is a good place to shop but they are a little more expensive than other places and they sometimes don\u0027t have certain prescriptions or they run out of them to quick do some scripts are hard to get filled.",
            "CVS is a good place to get your meds  you will receive reminders to pick up your meds  which Is awesome ",
            "i am sure it is a good place but there none located in my city so its hard to give a good evaluation",
            "good place",
            "that it is a good place to shop just bad service and stand in line for 15 or more minutes",
            "CVS is a good place to shop but it is not close to my home but Wallgreen\u0027s is close to my home",
            "good place for advice and assistance",
            "good place to pick up a few things and your meds too  staff is friendly and helpful",
            "It is a good place to shop till and get your medication",
            "I would say go to CVS because of it a good place to shop or fill your prescription",
            "Its a pretty good store with reasonable prices and pretty good reward program.  Unfortunately  a lot of their weekly specials sell out fast and store is a little difficult to navigate but overall its a pretty good place to go for snacks  personal care and drugs.",
            "CVS is a good place to shop. Easily in and out of the store. Staff is always friendly. Easy to to find things. Pharmacy is very knowledgable.",
            "CVS is a good place to shop  but I would not recommend for prescription  because the prices are expensive and the service is a bit slow",
            "good place  prices are higher",
            "It is a good place to get something if you are in a hurry",
            "Filling prescriptions depends on the specific store. For everything else  they are limited in selection  but still a good place to check for specific brands if you can\u0027t find them elsewhere.",
            "CVS is a good place to shop and it is generally convenient for both shopping and filling prescriptions. Sometimes they are a bit slow with the pharmacy depending on the store.",
            "cvs is a good place for certain products",
            "good place for prescriptions",
            "it\u0027s a good place to get anything you need for daily life. Not necessary have the best price  but convenient.",
            "It\u0027s a good place too shop",
            "Some items are priced out of my range but still consider a good place to shop.",
            "well it\u0027s a good store. it has nice stuff you can buy  like cosmetics  but it\u0027 not a good place to go to if you\u0027re grocery shopping.",
            "I would  tell  them Cvs is a good place to shop",
            "CVS is a good place  and convenient.",
            "this place is good place to shop your prescriptions other things have a nice loyalty program also",
            "It is a good place to get prescriptions filled but other items are to expensive if not on sale.",
            "its a good place to fill prescriptions",
            "I would tell them that CVS is a good place to shop for many products but to practice patience because they can be somewhat slow at times.",
            "A good place to shop for stuff you may need in all area of life. Cheap afforadable products.",
            "Cvs is a good place to shop the staff is nice and knowledgeable they just cost a little more and their selection is not the biggest!",
            "CVS is a good place to shop just not as convenient as Walgreens.",
            "I would say that CVS seems like a good place to shop but that I can\u0027t really give my opinion since I have only gone in there once.",
            "I don\u0027t use this store anymore since my divorce because I no longer have any medical problems but in the past this was a good place to shop"
          ]
        },
        {
          "phrase": "bad place",
          "frequency": 0,
          "sem_sim": 0.8592038750648499,
          "char_sim": 0.835,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9998005032539368
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": []
        },
        {
          "phrase": "awful place",
          "frequency": 1,
          "sem_sim": 0.7529130578041077,
          "char_sim": 0.7060316302193558,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9997462034225464
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS employees are very rude and they never want to help and the prices are very expensive. i really do not like shopping at CVS because it is a awful place to shop."
          ]
        },
        {
          "phrase": "decent place",
          "frequency": 2,
          "sem_sim": 0.8430336117744446,
          "char_sim": 0.7099448295814954,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998554587364197
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "We haven\u0027t had many issues with CVS. They have always been patient with us and willing to help if there was insurance issues. CVS doesn\u0027t have all the best deals but it\u0027s a decent place to ship for quick items.",
            "I don\u0027t know a lot about CVS and I don\u0027t feel prescriptions  but it seems like a decent place to do so."
          ]
        },
        {
          "phrase": "local place",
          "frequency": 1,
          "sem_sim": 0.6369876861572266,
          "char_sim": 0.7749999999999999,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9992072582244873
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "it is the most local place with easy parking and has the longest hours the pharmacy is open along with a druvethru"
          ]
        },
        {
          "phrase": "best place",
          "frequency": 4,
          "sem_sim": 0.7972395420074463,
          "char_sim": 0.7032308411061492,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998294115066528
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Its one of the best places to shop from their rewards program is great and very helpful after you get used to the system",
            "CVS is one of the best place to shop if you are looking for deals  promotions  and good customer service",
            "It\u0027s the best place to purchase beauty products.",
            "It is the best place ri shook.  Great prices SMS nice folks love their app work all the discounts"
          ]
        },
        {
          "phrase": "adequate place",
          "frequency": 1,
          "sem_sim": 0.6557736992835999,
          "char_sim": 0.7053253812031308,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998284578323364
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS IS an adequate place to shop but you need to carefully check prices"
          ]
        },
        {
          "phrase": "nice shopping place",
          "frequency": 1,
          "sem_sim": 0.8458522059479539,
          "char_sim": 0.7397959183673469,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998767375946045
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS is a nice shopping place. They just don\u0027t have everything I need that I get at Walgreens."
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9998519420623779
        }
      ],
      "topic_frequency": 14,
      "topic_tags": [
        "ADJ",
        "NOUN"
      ],
      "topic_context": [
        "They are a good place to shop for personal items such as beauty products or filling prescription. The only inconvenience is that the prices are too high",
        "It is a really good place",
        "I have always liked CVS but do not have one close by.   If you had one close to you  it would likely be a good place to shop",
        "CVs is a good place to fill your prescriptions depending on your insurance.  Other products are higher price unless you have a coupon or exbucks or a sale.",
        "Its a good place to shop as long as its convenient.  You would need to check the prices on subscription meds",
        "CVS is a good place to shop. The staff is helpful and friendly and you can get good deals.",
        "CVS is a good place to shop ut it isn\u0027t very conveniently located in this neighborhood",
        "its good place to buy unique products where we cant get anywhere else but the prices are very soaring",
        "8use CVS its a good store reliable good place to shop",
        "it is a good place to shop",
        "Depends on your insurance whether filling a prescription is covered at CVS check to find out it\u0027s a good place to shop.",
        "CVS is a good place to shop. The employees and pharmacists are very helpful. They offer a lot of coupons.",
        "CVS is a good place to shop but is a little pricey.",
        "I like Cvs it is a good place to shop",
        "CVS is a good place to shop but they are a little more expensive than other places and they sometimes don\u0027t have certain prescriptions or they run out of them to quick do some scripts are hard to get filled.",
        "CVS is a good place to get your meds  you will receive reminders to pick up your meds  which Is awesome ",
        "i am sure it is a good place but there none located in my city so its hard to give a good evaluation",
        "good place",
        "that it is a good place to shop just bad service and stand in line for 15 or more minutes",
        "CVS is a good place to shop but it is not close to my home but Wallgreen\u0027s is close to my home",
        "good place for advice and assistance",
        "good place to pick up a few things and your meds too  staff is friendly and helpful",
        "It is a good place to shop till and get your medication",
        "I would say go to CVS because of it a good place to shop or fill your prescription",
        "Its a pretty good store with reasonable prices and pretty good reward program.  Unfortunately  a lot of their weekly specials sell out fast and store is a little difficult to navigate but overall its a pretty good place to go for snacks  personal care and drugs.",
        "CVS is a good place to shop. Easily in and out of the store. Staff is always friendly. Easy to to find things. Pharmacy is very knowledgable.",
        "CVS is a good place to shop  but I would not recommend for prescription  because the prices are expensive and the service is a bit slow",
        "good place  prices are higher",
        "It is a good place to get something if you are in a hurry",
        "Filling prescriptions depends on the specific store. For everything else  they are limited in selection  but still a good place to check for specific brands if you can\u0027t find them elsewhere.",
        "CVS is a good place to shop and it is generally convenient for both shopping and filling prescriptions. Sometimes they are a bit slow with the pharmacy depending on the store.",
        "cvs is a good place for certain products",
        "good place for prescriptions",
        "it\u0027s a good place to get anything you need for daily life. Not necessary have the best price  but convenient.",
        "It\u0027s a good place too shop",
        "Some items are priced out of my range but still consider a good place to shop.",
        "well it\u0027s a good store. it has nice stuff you can buy  like cosmetics  but it\u0027 not a good place to go to if you\u0027re grocery shopping.",
        "I would  tell  them Cvs is a good place to shop",
        "CVS is a good place  and convenient.",
        "this place is good place to shop your prescriptions other things have a nice loyalty program also",
        "It is a good place to get prescriptions filled but other items are to expensive if not on sale.",
        "its a good place to fill prescriptions",
        "I would tell them that CVS is a good place to shop for many products but to practice patience because they can be somewhat slow at times.",
        "A good place to shop for stuff you may need in all area of life. Cheap afforadable products.",
        "Cvs is a good place to shop the staff is nice and knowledgeable they just cost a little more and their selection is not the biggest!",
        "CVS is a good place to shop just not as convenient as Walgreens.",
        "I would say that CVS seems like a good place to shop but that I can\u0027t really give my opinion since I have only gone in there once.",
        "I don\u0027t use this store anymore since my divorce because I no longer have any medical problems but in the past this was a good place to shop"
      ]
    },
    {
      "topic": "nothing special",
      "phrases": [
        {
          "phrase": "theres nothing special",
          "frequency": 0,
          "sem_sim": 0.8315775394439697,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9991445541381836
            }
          ],
          "pos_tags": [
            "PRON",
            "VERB",
            "PRON",
            "ADJ"
          ],
          "context": []
        },
        {
          "phrase": "nothing special",
          "frequency": 10,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9996480941772461
            }
          ],
          "pos_tags": [
            "PRON",
            "ADJ"
          ],
          "context": [
            "just another drug store; nothing special; not available where I live.",
            "It is just like all the other chain drug stores; nothing special or interesting",
            "It is OK nothing special",
            "It\u0027s convenient if you have it around but nothing special.",
            "I feel CVS is just another store that is nothing special",
            "this is an ok store  nothing special  not easy to find items",
            "A good store but nothing special.",
            "CVS is like any other pharmacy. I\u0027d shop there but AARP doesn\u0027t view them as a preferred provider  so why go there? There\u0027s nothing special about them.",
            "They\u0027re okay  but have nothing special to offer.  They don\u0027t have the lowest prices  they don\u0027t have the friendliest staff  they don\u0027t have the most convenient locations.  You can\u0027t really go wrong shopping there  but there are many better alternatives.",
            "CVS I am brand neutral. Never had a bad experience  but feel itâs nothing special either."
          ]
        },
        {
          "phrase": "say nothing",
          "frequency": 3,
          "sem_sim": 0.6240683197975159,
          "char_sim": 0.784,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9934072494506836
            }
          ],
          "pos_tags": [
            "VERB",
            "PRON"
          ],
          "context": [
            "this is an honest and truthful answer....I don\u0027t talk about this stuff so I would say nothing",
            "I don\u0027t go there so I would say nothing",
            "I would say nothing to either encourage or discourage about shopping at CVS.  I haven\u0027t shopped there for years so I don\u0027t know how it would feel shopping there now."
          ]
        },
        {
          "phrase": "theres nothing wrong",
          "frequency": 0,
          "sem_sim": 0.6012241244316101,
          "char_sim": 0.742,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9987627267837524
            }
          ],
          "pos_tags": [
            "PRON",
            "VERB",
            "PRON",
            "ADJ"
          ],
          "context": []
        },
        {
          "phrase": "theres nothing",
          "frequency": 0,
          "sem_sim": 0.5994611382484436,
          "char_sim": 0.7143505934628427,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9968205094337463
            }
          ],
          "pos_tags": [
            "PRON",
            "VERB",
            "PRON"
          ],
          "context": []
        }
      ],
      "topic_sentiment": [
        {
          "label": "NEGATIVE",
          "score": 0.9996480941772461
        }
      ],
      "topic_frequency": 5,
      "topic_tags": [
        "PRON",
        "ADJ"
      ],
      "topic_context": [
        "just another drug store; nothing special; not available where I live.",
        "It is just like all the other chain drug stores; nothing special or interesting",
        "It is OK nothing special",
        "It\u0027s convenient if you have it around but nothing special.",
        "I feel CVS is just another store that is nothing special",
        "this is an ok store  nothing special  not easy to find items",
        "A good store but nothing special.",
        "CVS is like any other pharmacy. I\u0027d shop there but AARP doesn\u0027t view them as a preferred provider  so why go there? There\u0027s nothing special about them.",
        "They\u0027re okay  but have nothing special to offer.  They don\u0027t have the lowest prices  they don\u0027t have the friendliest staff  they don\u0027t have the most convenient locations.  You can\u0027t really go wrong shopping there  but there are many better alternatives.",
        "CVS I am brand neutral. Never had a bad experience  but feel itâs nothing special either."
      ]
    },
    {
      "topic": "good variety",
      "phrases": [
        {
          "phrase": "good variety",
          "frequency": 9,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998621940612793
            }
          ],
          "pos_tags": [
            "ADJ",
            "PROPN"
          ],
          "context": [
            "they have a good variety to pick from and is a good drug store",
            "Longs is a good store. Its spacious and walking distance from myhouse. They have approachable people and good variety of products.",
            "Prices are higher but good variety of items",
            "They\u0027re convenient  have a loyalty reward program  have competitive prices  and have a good variety of goods.",
            "When I lived in George  I shopped at CVS.  They filled my prescriptions well and had a good variety of products to choose from.  However there are no CVS stores in the area where I live now.",
            "CVS is great but can be a little pricy. They have good variety.",
            "CVS is a good store with a good variety of products and easy to shop at.",
            "I like CVS because it\u0027s easy to fill prescription there and they have a good variety of merchandise for reasonable prices.",
            "Like the rewards  have a good variety.  Have had vaccinations there but not filled prescriptions"
          ]
        },
        {
          "phrase": "large variety",
          "frequency": 4,
          "sem_sim": 0.8360679210448752,
          "char_sim": 0.8622448979591837,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9993899464607239
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS is convenient. They are on every other corner. They have a large variety of products in store and even more online. They are more on the expensive side though.",
            "Itâs good value  convenient  with large variety",
            "CVS is a good pharmacy  staffed by professionals and with a large variety of products.  I don\u0027t particularly like the fact that they stopped selling tobacco products  and I\u0027m not sure the prices are all that competitive  but overall they\u0027re OK.",
            "They carry a large variety of products and have a knowledgeable staff. They are somewhat overpriced."
          ]
        },
        {
          "phrase": "nice variety",
          "frequency": 1,
          "sem_sim": 0.926590772307649,
          "char_sim": 0.8367346938775511,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998636841773987
            }
          ],
          "pos_tags": [
            "PROPN",
            "PROPN"
          ],
          "context": [
            "CVS is a good alternative to Walgreens.  They have a nice variety with decent prices.  I don\u0027t know about prescriptions because I have not had one filled there.  I only go to Walgreens because it is closer."
          ]
        },
        {
          "phrase": "broad variety",
          "frequency": 1,
          "sem_sim": 0.8282424089859943,
          "char_sim": 0.9030612244897959,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9985567927360535
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS has a broad variety of healthcare and beauty aids  plus staples like toilet paper and paper towels. They also have a great selection of candy and snacks  and they have seasonal items  too."
          ]
        },
        {
          "phrase": "great variety",
          "frequency": 2,
          "sem_sim": 0.9461456573739344,
          "char_sim": 0.8622448979591837,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998773336410522
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "They have great variety of products and a lot of conveniently located stores",
            "Has a great variety of products and a lot of locations.  However  I think they are a bit expensive."
          ]
        },
        {
          "phrase": "wide variety",
          "frequency": 17,
          "sem_sim": 0.820779603354785,
          "char_sim": 0.8775510204081632,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9996585249900818
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "They have a wide variety of options and at a reasonable price",
            "CVS has a wide variety of choices with high quality products.",
            "CVS sells a wide variety of things from decorations to care products to beauty products to candy. I really like going there to get some cosmetics to try out. They have many choices and there is a lot of brands to decide from.",
            "CVS has a very nice atmosphere and carries a wide variety of products. The employees are nice and helpful.",
            "is a well stocked drug tore store that carries a wide variety of health products. Staff a pharmacy are very willing to assist with questions regarding over the counter products for various health products. Also have someone to talk to you about various beauty products. Good loyalty program and sales. Vast numbers of stores in the area.",
            "CVS is very efficient  with a wide variety of products and very friendly staff to assist you with questions or purchases",
            "They offer a wide variety of quality merchandise at a good value and it is easy to get personal assistance.",
            "CVS has a wide variety of products.  They\u0027re conveniently located.  Customer assistance could be better.  They\u0027re higher priced than competitors but sometimes have good sales.",
            "Stock is organized and signage is clear.  Very wide variety of products but staff arenât very helpful.  Never had a prescription filled there so wonât comment on that",
            "cvs is like a run of the mill pharmacy chain like walgreens and riteaid. they have good trustworthy pharmacies and overall fair prices and carry a wide variety of personal care items.",
            "The store has a wide variety of products and services.  If they were closer to where I live I would shop there more often.",
            "CVS carries a wide variety of products and the pricing seems very competitor.  They have a lot of product that I often buy on impulse",
            "They carry a wide variety and have a good loyalty program",
            "If you have the CVS card with loaded deals  CVS is a good choice  but there\u0027s not a wide variety and without the card it is extraordinarily expensive.",
            "Knowledgeable pharmacy staff  friendly check out staff  clean stores and wide variety of products.",
            "CVS has a wide variety of products that are competitively priced. They have a good customer rewards program which helps save money. I have had prescriptions filled there in the past and have been satisfied with the service.",
            "They have several convenient locations and offer a wide variety of products and selections"
          ]
        },
        {
          "phrase": "big variety",
          "frequency": 2,
          "sem_sim": 0.8917037479731502,
          "char_sim": 0.8928571428571428,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9995406270027161
            }
          ],
          "pos_tags": [
            "ADJ",
            "PROPN"
          ],
          "context": [
            "I like shopping there  they have a big variety of items at a good price",
            "I don\u0027t shop at CVS too much because they don\u0027t have a big variety of stuff."
          ]
        },
        {
          "phrase": "big variety2",
          "frequency": 2,
          "sem_sim": 0.8917037479731502,
          "char_sim": 0.8928571428571428,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9995406270027161
            }
          ],
          "pos_tags": [
            "ADJ",
            "PROPN"
          ],
          "context": [
            "I like shopping there lorem  they have a big variety2 of items at a good price",
            "I don\u0027t shop at CVS too much because they don\u0027t have a big variety2 of stuff."
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9998621940612793
        }
      ],
      "topic_frequency": 8,
      "topic_tags": [
        "ADJ",
        "PROPN"
      ],
      "topic_context": [
        "they have a good variety to pick from and is a good drug store",
        "Longs is a good store. Its spacious and walking distance from myhouse. They have approachable people and good variety of products.",
        "Prices are higher but good variety of items",
        "They\u0027re convenient  have a loyalty reward program  have competitive prices  and have a good variety of goods.",
        "When I lived in George  I shopped at CVS.  They filled my prescriptions well and had a good variety of products to choose from.  However there are no CVS stores in the area where I live now.",
        "CVS is great but can be a little pricy. They have good variety.",
        "CVS is a good store with a good variety of products and easy to shop at.",
        "I like CVS because it\u0027s easy to fill prescription there and they have a good variety of merchandise for reasonable prices.",
        "Like the rewards  have a good variety.  Have had vaccinations there but not filled prescriptions"
      ]
    },
    {
      "topic": "good deal",
      "phrases": [
        {
          "phrase": "good deal",
          "frequency": 40,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998379349708557
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "They have lots of good deals and a great loyalty  program",
            "It\u0027s conveniently located and has occasional good deals but supplies and choices are limited and often out of stock.",
            "Convenient location  some good deals.",
            "They have good deals that you can stack to get things really cheap but it takes some work to figure out how and what you can do that with.",
            "cvs has good quality and some good deals. store brands oare very high quality.  sometimes the stock is low and it can be difficult to find things.  assistance is not always avaialbale and is sometines not friendly",
            "I would encourage prescription shopping at CVS because they are the fastest and the friendliest pharmacy in our neighborhood and I have never had any prescriptions out of stock with them.  occasionally they have good deals on other merchandize but for the most part CVS has really high prices compared to other stores",
            "CVS is a good place to shop. The staff is helpful and friendly and you can get good deals.",
            "They offer pretty good deals mostly  but can be expensive.",
            "They have very good deals on certain products weekly and they give you coupons for your next visit",
            "Would encourage them to shop there and enroll in there loyalty program because you can get some good deals if you know your prices and use coupons.",
            "Lots of good deals",
            "I like CVS and you can get some good deals there  but they no longer sell cigerettes or vape or any tobacco related items and that hurts them with me",
            "I find there prices to be a bit high  and not a huge selection of products.  Thye do have good deals sometimes on items i shop for  which is the only reason I go there",
            "OK to shop there for good deals",
            "I donât shop there so I donât know much about it  but I heard cvs has good deals often.",
            "CVS has good deals I never ran into someone that made me regret going there I love the vibes there!",
            "I would encourage them to shop at CVS mainly because of the good deals and sales",
            "it\u0027s a lot of work  because their coupons always have exclusions and they rarely post all of their prices clearly in store  but if you put in the time and effort you can get a good deal.",
            "Great pharmacy with good deals",
            "I\u0027ve shopped there before.  They have some good deals.",
            "They have good deals and coupons  but regularly are somewhat overpriced!",
            "Prices at CVS are generally high but you can really score some good deals when using coupons and their rewards program. Also  the stores are friendly  clean and conveniently located.",
            "Easy to navigate around store and friendly staff fair prices good deals",
            "Convenient location  easy to find what you need pricey but can get a good deal with coupons.",
            "Whenever I go to CVS  I can find what I want quickly and easily.  The stores are organized and clean.  The cashiers are always helpful and friendly.  There are two CVS stores within ten minutes of me   so it\u0027s a convenient place to shop.  Between CVS coupons  Extra Bucks Rewards  BOGO offers  and sale prices  I get a lot of good deals.  It\u0027s interesting to see the seasonal products they carry.",
            "CVS has all the products you need and has good deals and offers that makes purchases affordable.",
            "good deals  always coupons  reward bucks  friendly staff  large selection",
            "Has items that Walgreens does not carry. Not many CVS stores in my city. Occasionally has good deals.",
            "CVS has some good deals but I mostly buy candy  cookies and bus passes when I go there.",
            "Cvs has a great loyalty program that pays you extra dollars if you buy certain items.  Their everyday prices are high  but sometimes you can get good deals there.  Their customer service is great too.",
            "good deal  convenient",
            "Cvs has lots of products with good deals and the employees are very helpful",
            "CVS is a really quick  easy way to get household staples and they usually have good promotions or coupons so it feels like a good deal.",
            "There isn\u0027t one where I live  but I\u0027ve heard great things \u0026 they have good deals",
            "good deals because of coupons",
            "I would tell my friend that CVS is a great place to shop.  I shop there for handicapped equipment like a cane.  And they have good deals on toilet paper usually.",
            "The staff is very friendly and helpful.  I love the coupons and get very good deals.",
            "When items are on sale or  come with CVS bucks at the backend of the purchase you can get a good deal.  Otherwise  regular priced merchandise is often the most costly and  there always seems to be stock issues with sales items.",
            "Their rewards system give you a good deals.",
            "Very good deals  products and excellent customer service"
          ]
        },
        {
          "phrase": "good deal otherwise regular priced",
          "frequency": 0,
          "sem_sim": 0.7131829261779785,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9992755055427551
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "ADV",
            "ADV",
            "VERB"
          ],
          "context": []
        },
        {
          "phrase": "deal otherwise regular priced",
          "frequency": 0,
          "sem_sim": 0.5432579517364502,
          "char_sim": 0.712269659947511,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9822941422462463
            }
          ],
          "pos_tags": [
            "VERB",
            "ADV",
            "ADV",
            "VERB"
          ],
          "context": []
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9998379349708557
        }
      ],
      "topic_frequency": 3,
      "topic_tags": [
        "ADJ",
        "NOUN"
      ],
      "topic_context": [
        "They have lots of good deals and a great loyalty  program",
        "It\u0027s conveniently located and has occasional good deals but supplies and choices are limited and often out of stock.",
        "Convenient location  some good deals.",
        "They have good deals that you can stack to get things really cheap but it takes some work to figure out how and what you can do that with.",
        "cvs has good quality and some good deals. store brands oare very high quality.  sometimes the stock is low and it can be difficult to find things.  assistance is not always avaialbale and is sometines not friendly",
        "I would encourage prescription shopping at CVS because they are the fastest and the friendliest pharmacy in our neighborhood and I have never had any prescriptions out of stock with them.  occasionally they have good deals on other merchandize but for the most part CVS has really high prices compared to other stores",
        "CVS is a good place to shop. The staff is helpful and friendly and you can get good deals.",
        "They offer pretty good deals mostly  but can be expensive.",
        "They have very good deals on certain products weekly and they give you coupons for your next visit",
        "Would encourage them to shop there and enroll in there loyalty program because you can get some good deals if you know your prices and use coupons.",
        "Lots of good deals",
        "I like CVS and you can get some good deals there  but they no longer sell cigerettes or vape or any tobacco related items and that hurts them with me",
        "I find there prices to be a bit high  and not a huge selection of products.  Thye do have good deals sometimes on items i shop for  which is the only reason I go there",
        "OK to shop there for good deals",
        "I donât shop there so I donât know much about it  but I heard cvs has good deals often.",
        "CVS has good deals I never ran into someone that made me regret going there I love the vibes there!",
        "I would encourage them to shop at CVS mainly because of the good deals and sales",
        "it\u0027s a lot of work  because their coupons always have exclusions and they rarely post all of their prices clearly in store  but if you put in the time and effort you can get a good deal.",
        "Great pharmacy with good deals",
        "I\u0027ve shopped there before.  They have some good deals.",
        "They have good deals and coupons  but regularly are somewhat overpriced!",
        "Prices at CVS are generally high but you can really score some good deals when using coupons and their rewards program. Also  the stores are friendly  clean and conveniently located.",
        "Easy to navigate around store and friendly staff fair prices good deals",
        "Convenient location  easy to find what you need pricey but can get a good deal with coupons.",
        "Whenever I go to CVS  I can find what I want quickly and easily.  The stores are organized and clean.  The cashiers are always helpful and friendly.  There are two CVS stores within ten minutes of me   so it\u0027s a convenient place to shop.  Between CVS coupons  Extra Bucks Rewards  BOGO offers  and sale prices  I get a lot of good deals.  It\u0027s interesting to see the seasonal products they carry.",
        "CVS has all the products you need and has good deals and offers that makes purchases affordable.",
        "good deals  always coupons  reward bucks  friendly staff  large selection",
        "Has items that Walgreens does not carry. Not many CVS stores in my city. Occasionally has good deals.",
        "CVS has some good deals but I mostly buy candy  cookies and bus passes when I go there.",
        "Cvs has a great loyalty program that pays you extra dollars if you buy certain items.  Their everyday prices are high  but sometimes you can get good deals there.  Their customer service is great too.",
        "good deal  convenient",
        "Cvs has lots of products with good deals and the employees are very helpful",
        "CVS is a really quick  easy way to get household staples and they usually have good promotions or coupons so it feels like a good deal.",
        "There isn\u0027t one where I live  but I\u0027ve heard great things \u0026 they have good deals",
        "good deals because of coupons",
        "I would tell my friend that CVS is a great place to shop.  I shop there for handicapped equipment like a cane.  And they have good deals on toilet paper usually.",
        "The staff is very friendly and helpful.  I love the coupons and get very good deals.",
        "When items are on sale or  come with CVS bucks at the backend of the purchase you can get a good deal.  Otherwise  regular priced merchandise is often the most costly and  there always seems to be stock issues with sales items.",
        "Their rewards system give you a good deals.",
        "Very good deals  products and excellent customer service"
      ]
    },
    {
      "topic": "one stop",
      "phrases": [
        {
          "phrase": "stop sending",
          "frequency": 1,
          "sem_sim": 0.6913489699363708,
          "char_sim": 0.7001334049880891,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9996560215950012
            }
          ],
          "pos_tags": [
            "VERB",
            "VERB"
          ],
          "context": [
            "All the stores are well-organized \u0026 well-stocked. They carry all the brands I need. They have so many locations \u0026 flexible hours that I don\u0027t have to worry about restricted times to shop. Their pharmacy dept runs smoothly. But I wish they would stop sending me texts to accelerate the filling of prescriptions that are not due yet. With one exception over the years I have had positive interactions with CVS staff  i.e.- clerks  pharmacists  and managers."
          ]
        },
        {
          "phrase": "quick stop",
          "frequency": 3,
          "sem_sim": 0.7311550378799438,
          "char_sim": 0.772,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9978360533714294
            }
          ],
          "pos_tags": [
            "ADV",
            "VERB"
          ],
          "context": [
            "Cvs is good for quick stops",
            "Its good for a quick stop but its expensive.",
            "Itâs great but doesnât always have everything I need for a quick stop"
          ]
        },
        {
          "phrase": "good stop",
          "frequency": 1,
          "sem_sim": 0.7324812412261963,
          "char_sim": 0.85,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9995406270027161
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "It\u0027s a good stop if I need something quickly.  They have some good specials.  Not much selection with certain items.  Not for groceries."
          ]
        },
        {
          "phrase": "non stop",
          "frequency": 1,
          "sem_sim": 0.738959014415741,
          "char_sim": 0.9279999999999999,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9978083372116089
            }
          ],
          "pos_tags": [
            "ADJ",
            "VERB"
          ],
          "context": [
            "I would say just be careful since they can call you non stop to come back in to shop."
          ]
        },
        {
          "phrase": "one stop",
          "frequency": 17,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9569044709205627
            }
          ],
          "pos_tags": [
            "NUM",
            "NOUN"
          ],
          "context": [
            "CVS is my one stop shop.  I am a loyal customer of CVS.  I continuously receive coupons for 30 to 40% off purchases through the mail and email.  I have saved so much money on my purchases throughout the year.  I am a top saver with CVS.  I can  always  find everything I need when I shop with CVS.",
            "There are tons of locations  so there is probably one close by. They carry a variety of products so it\u0027s a one stop shop.",
            "CVS is alright for shopping. I personally prefer Wal Mart due to the one stop for all of my needs.",
            "Cvs offers a one stop shop for everything on your list. You can use your reward card to gain valuable coupons and rewards off of products you actually will use.",
            "I wish a CVS store was closer to me  as I have been in one. I like going to a store that has a larger selection of products  for an all one stop.",
            "I would tell my friend that CVS is a one stop shop for all health and beauty aids that you may need.",
            "conveniently located...one stop shop for all your daily needs including pharmacy",
            "I love shopping at CVS because they are my one stop shop for everything I need.",
            "It is a one stop convenient shop with a great loyalty program. you can also get photos printed here and have a nice print shop",
            "CVS is a one stop shop for medicine and anything extra you can get",
            "A good retailer to get everything in one stop",
            "Good prices.  Just prefer one stop shop for other needs",
            "CVS is a good brand and a one stop for all your basic household and health care needs. In fact  they offer amazing discounts",
            "I love CVS. They have competitive prices  friendly staff and have everything I need in one stop.",
            "CVS is a great one stop shopping center. It convenient for your everyday pick me up. The customers offer assistance and the pharmacy center is very knowledgeable",
            "Cvs has convenient locations and is a one stop shop everything",
            "I would tell my friend that CVS is a good one stop shopping place if you have coupons and especially CVS coupons which will make a big difference in what you pay."
          ]
        },
        {
          "phrase": "preferred stop",
          "frequency": 1,
          "sem_sim": 0.6778813600540161,
          "char_sim": 0.85,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9903616905212402
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Not that familiar  thing they are close to the same as Walgreens.  CVS is not a preferred stop"
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "NEGATIVE",
          "score": 0.9569044709205627
        }
      ],
      "topic_frequency": 6,
      "topic_tags": [
        "NUM",
        "NOUN"
      ],
      "topic_context": [
        "CVS is my one stop shop.  I am a loyal customer of CVS.  I continuously receive coupons for 30 to 40% off purchases through the mail and email.  I have saved so much money on my purchases throughout the year.  I am a top saver with CVS.  I can  always  find everything I need when I shop with CVS.",
        "There are tons of locations  so there is probably one close by. They carry a variety of products so it\u0027s a one stop shop.",
        "CVS is alright for shopping. I personally prefer Wal Mart due to the one stop for all of my needs.",
        "Cvs offers a one stop shop for everything on your list. You can use your reward card to gain valuable coupons and rewards off of products you actually will use.",
        "I wish a CVS store was closer to me  as I have been in one. I like going to a store that has a larger selection of products  for an all one stop.",
        "I would tell my friend that CVS is a one stop shop for all health and beauty aids that you may need.",
        "conveniently located...one stop shop for all your daily needs including pharmacy",
        "I love shopping at CVS because they are my one stop shop for everything I need.",
        "It is a one stop convenient shop with a great loyalty program. you can also get photos printed here and have a nice print shop",
        "CVS is a one stop shop for medicine and anything extra you can get",
        "A good retailer to get everything in one stop",
        "Good prices.  Just prefer one stop shop for other needs",
        "CVS is a good brand and a one stop for all your basic household and health care needs. In fact  they offer amazing discounts",
        "I love CVS. They have competitive prices  friendly staff and have everything I need in one stop.",
        "CVS is a great one stop shopping center. It convenient for your everyday pick me up. The customers offer assistance and the pharmacy center is very knowledgeable",
        "Cvs has convenient locations and is a one stop shop everything",
        "I would tell my friend that CVS is a good one stop shopping place if you have coupons and especially CVS coupons which will make a big difference in what you pay."
      ]
    },
    {
      "topic": "good choice",
      "phrases": [
        {
          "phrase": "good choice",
          "frequency": 4,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998458623886108
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Should be a good choice for you",
            "CVS has convenient locations and a good choice of products  but that tend to have higher prices than other places.  They do have a good loyalty program that can make certain things affordable.",
            "If you have the CVS card with loaded deals  CVS is a good choice  but there\u0027s not a wide variety and without the card it is extraordinarily expensive.",
            "CVS is a good choice.  They are very similar to Walgreens and Rite Aid.  I feel like all 3 are about the same for what they do."
          ]
        },
        {
          "phrase": "first choice",
          "frequency": 4,
          "sem_sim": 0.6953861117362976,
          "char_sim": 0.7054017114980909,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9992925524711609
            }
          ],
          "pos_tags": [
            "PROPN",
            "PROPN"
          ],
          "context": [
            "CVS is someone I would consider purchasing from  but would not be my first choice.",
            "I feel it is a great store 62 prices Akron Ohio so it\u0027s not always my first choice",
            "CVS is a clean and friendly place to shop. It usually isn\u0027t my first choice because it is rather pricey.",
            "It\u0027s an okay drug store  but certainly not my first choice."
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9998458623886108
        }
      ],
      "topic_frequency": 2,
      "topic_tags": [
        "ADJ",
        "NOUN"
      ],
      "topic_context": [
        "Should be a good choice for you",
        "CVS has convenient locations and a good choice of products  but that tend to have higher prices than other places.  They do have a good loyalty program that can make certain things affordable.",
        "If you have the CVS card with loaded deals  CVS is a good choice  but there\u0027s not a wide variety and without the card it is extraordinarily expensive.",
        "CVS is a good choice.  They are very similar to Walgreens and Rite Aid.  I feel like all 3 are about the same for what they do."
      ]
    },
    {
      "topic": "personal care",
      "phrases": [
        {
          "phrase": "personal care",
          "frequency": 8,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9992973208427429
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Drug store similar to others with a good selection of health and personal care products.",
            "CVS is a very convenient and helpful store to shop for personal care items. They often have good sales promotions. There is a rewards program. The employees are helpful. Shopping and checkout are always easy.",
            "Its a pretty good store with reasonable prices and pretty good reward program.  Unfortunately  a lot of their weekly specials sell out fast and store is a little difficult to navigate but overall its a pretty good place to go for snacks  personal care and drugs.",
            "They generally have everything i need for personal care items and there are several local locations",
            "The pharmacy staff is excellent and very helpful.  The store overall is more expensive than all other personal care stores  and their prescriptions are also more expensive than most others.  The store is always clean and well laid out  but I find much better values at Meijer and MUCH bigger selection of items.",
            "cvs is like a run of the mill pharmacy chain like walgreens and riteaid. they have good trustworthy pharmacies and overall fair prices and carry a wide variety of personal care items.",
            "Pharmacy technicians are always knowledgeable and offer a sense of personal care",
            "CVS is a great for quick shopping needs such as snacks  beverages   personal care products and refilling prescriptions."
          ]
        },
        {
          "phrase": "really care",
          "frequency": 5,
          "sem_sim": 0.8478475626634092,
          "char_sim": 0.8367346938775511,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9996825456619263
            }
          ],
          "pos_tags": [
            "ADV",
            "VERB"
          ],
          "context": [
            "don\u0027t really care for CVS anymore because everytime I go in there shopping I have to hunt all over the store for someone to check me out. They have their employees stocking and not paying attention to the front check out counter!",
            "I feel like CVS has your back and really cares for the customer  even offering them good coupon deals",
            "The customer service isnât necessarily bad but they arenât the most welcoming when people come in. I donât really care too much about that though. Theyâre super organized and itâs easy to find anything and everything you are looking for. Thereâs organic and vegan nutrition options located here that arenât offered at any of my local grocery stores too  so thatâs why I recommend them automatically.",
            "cvs is a great place to fill your prescriptions they have super friendly staff and really care about you their store is super clean and well stocked and they have pretty good sales",
            "Cvs pharmacist really care about there customers. Go out of there way to help"
          ]
        },
        {
          "phrase": "extra care",
          "frequency": 5,
          "sem_sim": 0.8327700811989454,
          "char_sim": 0.8520408163265305,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9984275102615356
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "cvs has great extra care offers",
            "great loyalty program gives you $5 extra care bucks for 10 prescriptions filled.",
            "Friendly pharmacists  but expensive products. ALL products are expensive. i can get medicine at Walmart for 5$ but then at cvs the SAME medicine they want almost 9$ for it. They have extra care rewards. It\u0027s a last resort for me personally  on buying any merchandise there. I use the pharmacy though for my children and husband.",
            "CVS has good value with extra care card",
            "For in store shopping id1recommend cvs for the extra care eeward deals. Pharmacy is a different story.  The in store pharmacy would be fine if the website to check status of prescriptions was at least 55% accurate. Ive had so much trouble and am thinking of changing pharmacies as im tired of every prescription being a tug of war. Never know what is accurate on website. Call pharmacy regarding prescription that says in process for over 6 days. According to pharmacy they never received prescriptio"
          ]
        },
        {
          "phrase": "show care",
          "frequency": 1,
          "sem_sim": 0.8340043839143247,
          "char_sim": 0.8214285714285714,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9974132180213928
            }
          ],
          "pos_tags": [
            "PROPN",
            "PROPN"
          ],
          "context": [
            "CVS always gets my prescriptions right in a timely manner. They show care and concern."
          ]
        },
        {
          "phrase": "truly care",
          "frequency": 1,
          "sem_sim": 0.8439218061310905,
          "char_sim": 0.8061224489795917,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9996731877326965
            }
          ],
          "pos_tags": [
            "ADV",
            "VERB"
          ],
          "context": [
            "Great location  never a problem with getting the prescriptions. The staff seems to truly care"
          ]
        },
        {
          "phrase": "taken care",
          "frequency": 2,
          "sem_sim": 0.8392075957084189,
          "char_sim": 0.8061224489795917,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9958072900772095
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN"
          ],
          "context": [
            "The employees were very efficient when filling prescriptions.  Tried to make sure you were taken care of right away.  Also they are very knowledgeable and have given very good advice.",
            "Cvs is always on top of my prescriptions. Refills are always taken care of. I never have any issues with my prescriptions at cvs. And i can do other shopping while i am there. Check out new products"
          ]
        },
        {
          "phrase": "family care",
          "frequency": 1,
          "sem_sim": 0.8783389573194542,
          "char_sim": 0.7908163265306123,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9994029998779297
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "Store is conveniently located. Has most items you need for family care and has great photo services."
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9992973208427429
        }
      ],
      "topic_frequency": 7,
      "topic_tags": [
        "ADJ",
        "NOUN"
      ],
      "topic_context": [
        "Drug store similar to others with a good selection of health and personal care products.",
        "CVS is a very convenient and helpful store to shop for personal care items. They often have good sales promotions. There is a rewards program. The employees are helpful. Shopping and checkout are always easy.",
        "Its a pretty good store with reasonable prices and pretty good reward program.  Unfortunately  a lot of their weekly specials sell out fast and store is a little difficult to navigate but overall its a pretty good place to go for snacks  personal care and drugs.",
        "They generally have everything i need for personal care items and there are several local locations",
        "The pharmacy staff is excellent and very helpful.  The store overall is more expensive than all other personal care stores  and their prescriptions are also more expensive than most others.  The store is always clean and well laid out  but I find much better values at Meijer and MUCH bigger selection of items.",
        "cvs is like a run of the mill pharmacy chain like walgreens and riteaid. they have good trustworthy pharmacies and overall fair prices and carry a wide variety of personal care items.",
        "Pharmacy technicians are always knowledgeable and offer a sense of personal care",
        "CVS is a great for quick shopping needs such as snacks  beverages   personal care products and refilling prescriptions."
      ]
    },
    {
      "topic": "good quality",
      "phrases": [
        {
          "phrase": "high quality",
          "frequency": 8,
          "sem_sim": 0.7479233741760254,
          "char_sim": 0.7191571558366383,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998687505722046
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS has a wide selection of high quality products for your everyday needs. They have the most competitive prices. And they have a convenient health clinic for minor illnesses and services like flu shots or the common cold. They have convenient locations so you save time and money",
            "I feel that cvs has high quality products and a good range",
            "cvs has good quality and some good deals. store brands oare very high quality.  sometimes the stock is low and it can be difficult to find things.  assistance is not always avaialbale and is sometines not friendly",
            "CVS is very reliable and the products they sell are high quality",
            "They are unique and high quality",
            "CVS is of high quality  but it also can be pricey.",
            "CVS has a wide variety of choices with high quality products.",
            "good and high quality brand  but a little higher cost than other stores"
          ]
        },
        {
          "phrase": "good quality",
          "frequency": 6,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.999855101108551
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Good deals with coupons and cash rewards. also good quality products and variety of items to choose from like groceries  health aids etc",
            "It has many different products and really good quality stuff but the prices are higher then other retailers",
            "cvs has good quality and some good deals. store brands oare very high quality.  sometimes the stock is low and it can be difficult to find things.  assistance is not always avaialbale and is sometines not friendly",
            "cvs has good quality brands  customer service is good  clean stores",
            "Provides good quality services and discounts",
            "I have always been pleased with them because they provide good quality products and good service.  I was VERY pleased when they stopped selling tobacco products."
          ]
        },
        {
          "phrase": "need selling quality goods",
          "frequency": 1,
          "sem_sim": 0.7833671667137925,
          "char_sim": 0.7346938775510203,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9683927297592163
            }
          ],
          "pos_tags": [
            "VERB",
            "VERB",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS is more than a drug store. They have a good assortment of just about anything you need selling quality goods at very nice prices"
          ]
        },
        {
          "phrase": "quality help",
          "frequency": 1,
          "sem_sim": 0.8752550859840549,
          "char_sim": 0.7908163265306123,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9994380474090576
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "cvs is a good store to shop in  with quality help that will help you find what you need - they also have rewards for shopping there and good coupons and sales"
          ]
        },
        {
          "phrase": "convenient quality staff product",
          "frequency": 0,
          "sem_sim": 0.8137212578131228,
          "char_sim": 0.7040816326530612,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9953321218490601
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN",
            "NOUN"
          ],
          "context": []
        },
        {
          "phrase": "good quality stuff",
          "frequency": 1,
          "sem_sim": 0.9442091146293952,
          "char_sim": 0.9030612244897959,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998663663864136
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "It has many different products and really good quality stuff but the prices are higher then other retailers"
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.999855101108551
        }
      ],
      "topic_frequency": 6,
      "topic_tags": [
        "ADJ",
        "NOUN"
      ],
      "topic_context": [
        "Good deals with coupons and cash rewards. also good quality products and variety of items to choose from like groceries  health aids etc",
        "It has many different products and really good quality stuff but the prices are higher then other retailers",
        "cvs has good quality and some good deals. store brands oare very high quality.  sometimes the stock is low and it can be difficult to find things.  assistance is not always avaialbale and is sometines not friendly",
        "cvs has good quality brands  customer service is good  clean stores",
        "Provides good quality services and discounts",
        "I have always been pleased with them because they provide good quality products and good service.  I was VERY pleased when they stopped selling tobacco products."
      ]
    },
    {
      "topic": "great location",
      "phrases": [
        {
          "phrase": "current location",
          "frequency": 1,
          "sem_sim": 0.6831966042518616,
          "char_sim": 0.9023255813953488,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9962601065635681
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Good retailer  but not as convenient to my current location.  Find other sources priced better."
          ]
        },
        {
          "phrase": "closet location",
          "frequency": 1,
          "sem_sim": 0.5275813937187195,
          "char_sim": 0.8674418604651163,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9891544580459595
            }
          ],
          "pos_tags": [
            "PROPN",
            "NOUN"
          ],
          "context": [
            "For me  CVS is not close enough to be considered for most purchases. I don\u0027t think they stand out enough for me to consider traveling to their closet location. It\u0027s not merely a matter of distance  it\u0027s about traffic. So  I\u0027d tell my friend that to shop wherever it\u0027s convenient. Most of these places are the same."
          ]
        },
        {
          "phrase": "clean location",
          "frequency": 1,
          "sem_sim": 0.6149083971977234,
          "char_sim": 0.8534883720930232,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9997698664665222
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "I think CVS is a convenient and overpriced store that doesn\u0027t offer anything special but a small and clean location."
          ]
        },
        {
          "phrase": "quick location",
          "frequency": 1,
          "sem_sim": 0.7254831194877625,
          "char_sim": 0.7488372093023256,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9867808818817139
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "I would encourage them shopping at CVS because it is a convenient and quick location to get most whatever you need. They also offer a lot of coupons."
          ]
        },
        {
          "phrase": "close location",
          "frequency": 2,
          "sem_sim": 0.7023887634277344,
          "char_sim": 0.7976744186046512,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9908875226974487
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS is not a close location for me  otherwise they are a good store.",
            "good service  close location  ease of finding products in store"
          ]
        },
        {
          "phrase": "location easy",
          "frequency": 0,
          "sem_sim": 0.7295030355453491,
          "char_sim": 0.8325581395348837,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9953351020812988
            }
          ],
          "pos_tags": [
            "NOUN",
            "ADV"
          ],
          "context": []
        },
        {
          "phrase": "location convent",
          "frequency": 1,
          "sem_sim": 0.514108419418335,
          "char_sim": 0.7118120170339501,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.8746351599693298
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "I don\u0027t shop at CVS because there is not a location convent for me. I have no connection to them"
          ]
        },
        {
          "phrase": "great location",
          "frequency": 3,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998713731765747
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "great location and good product available",
            "I like CVS because I trust the company and have dealt with CVS for over ten years  The staff is friendly  the prices is right they are in a great location and they have a variety of product",
            "Great hours  priced reasonably and great location."
          ]
        },
        {
          "phrase": "particular store location",
          "frequency": 1,
          "sem_sim": 0.8413873010752153,
          "char_sim": 0.7806122448979591,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.7307168245315552
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS is flaky.  Some days you\u0027ll have a good experience and other days you\u0027d be better off avoiding this store at all costs. NEVER expect the CVS care card kiosk in the store to work (ie spit out coupons  provide deals  etc) as it\u0027s generally OFF-LINE  NOT working  out of paper  etc.  Items listed in weekly circulars may or may not be in stock or even carried by the particular store location you plan to shop at.  It\u0027s a hit and miss proposition if you\u0027ll be satisfied by your experience.  When it\u0027"
          ]
        },
        {
          "phrase": "particular location shopping",
          "frequency": 0,
          "sem_sim": 0.8378189899483506,
          "char_sim": 0.760204081632653,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9960889220237732
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": []
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9998713731765747
        }
      ],
      "topic_frequency": 3,
      "topic_tags": [
        "ADJ",
        "NOUN"
      ],
      "topic_context": [
        "great location and good product available",
        "I like CVS because I trust the company and have dealt with CVS for over ten years  The staff is friendly  the prices is right they are in a great location and they have a variety of product",
        "Great hours  priced reasonably and great location."
      ]
    },
    {
      "topic": "good pharmacy",
      "phrases": [
        {
          "phrase": "good pharmacy staffed",
          "frequency": 0,
          "sem_sim": 0.7954578995704651,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9991658329963684
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "VERB"
          ],
          "context": []
        },
        {
          "phrase": "pharmacy chain",
          "frequency": 2,
          "sem_sim": 0.6917717456817627,
          "char_sim": 0.7188908656666404,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.8337012529373169
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS is by far the best pharmacy chain in the country",
            "cvs is like a run of the mill pharmacy chain like walgreens and riteaid. they have good trustworthy pharmacies and overall fair prices and carry a wide variety of personal care items."
          ]
        },
        {
          "phrase": "chain pharmacy",
          "frequency": 2,
          "sem_sim": 0.6917717456817627,
          "char_sim": 0.7552631578947369,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.739488959312439
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "Cvs is a very reputable chain pharmacy and would be a good option. Personally I havenât shopped there much since I have an established relationship with walgreens",
            "Cvs is the best chain pharmacy  they are easy to work with and usually fix any issues"
          ]
        },
        {
          "phrase": "good pharmacy",
          "frequency": 22,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998068809509277
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "good pharmacy  and store is well-stocked  clean and neat layout",
            "Has a good pharmacy and lots of shelf items but does not take Tricare at pharmacy.",
            "Cvs has a good pharmacy. Whet I love the most is their seasonal section. I always find something cute to buy",
            "It\u0027s a good pharmacy I\u0027ve used it many times before I have shopped there.",
            "good pharmacy with lots of specials",
            "CVS is expensive with their prices of their products. They do have a good pharmacy to look into. They also have a variety of products that may not be found at other retailers but the prices are not so favorable when purchasing.",
            "They have a good pharmacy and great photo dept",
            "good pharmacy  dreadful customer service otherwise",
            "CVS has a good pharmacy.",
            "CVS is a good pharmacy.  Unfortunately  It isn\u0027t convenient for me so I use another pharmacy in stead.  Most of my prescriptions are mailed to me by my benefits preferred mail order pharmacy.",
            "has convenience and good pharmacy help. it has good rewards sometimes.",
            "CVS is a good pharmacy  staffed by professionals and with a large variety of products.  I don\u0027t particularly like the fact that they stopped selling tobacco products  and I\u0027m not sure the prices are all that competitive  but overall they\u0027re OK.",
            "CVS is a good store but they don\u0027t really offer any real good pharmacy discounts and are sometimes very expensive",
            "CVS is a good pharmacy that sells products we need and medical needs as well.",
            "CVS is a good pharmacy to go to  but I dont like to shop there because it is expensive.",
            "this is a good pharmacy",
            "Decent product selection and friendly staff  good pharmacy discounts. Generally preferable to Walgreens.",
            "CVS is a good pharmacy place to get products for skin  hair and your overall health.",
            "Cvs is a good pharmacy store",
            "CVS is a good pharmacy for prescriptions even though their prices on other items are expensive when not on sale.",
            "its a good pharmacy",
            "CVS has a good pharmacy located in their store.  While you are waiting for a prescription you can shop for other items.  Store is always very clean and organized."
          ]
        },
        {
          "phrase": "good pharmacy place",
          "frequency": 1,
          "sem_sim": 0.9542524972740485,
          "char_sim": 0.9081632653061225,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998243451118469
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS is a good pharmacy place to get products for skin  hair and your overall health."
          ]
        },
        {
          "phrase": "price limited variety good pharmacy",
          "frequency": 0,
          "sem_sim": 0.8596087450883827,
          "char_sim": 0.7704081632653061,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9785351157188416
            }
          ],
          "pos_tags": [
            "NOUN",
            "VERB",
            "NOUN",
            "ADJ",
            "NOUN"
          ],
          "context": []
        },
        {
          "phrase": "stop pharmacy",
          "frequency": 1,
          "sem_sim": 0.8677198120525905,
          "char_sim": 0.8877551020408163,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9995246529579163
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN"
          ],
          "context": [
            "cvs is a 1 stop pharmacy with all of your beauty and healthcare needs. You get bonus rewards when you shop."
          ]
        },
        {
          "phrase": "pharmacy horrible service",
          "frequency": 0,
          "sem_sim": 0.8883947474615914,
          "char_sim": 0.7091836734693877,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9985166788101196
            }
          ],
          "pos_tags": [
            "NOUN",
            "ADJ",
            "NOUN"
          ],
          "context": []
        },
        {
          "phrase": "pharmacy service",
          "frequency": 3,
          "sem_sim": 0.8649243542126247,
          "char_sim": 0.7755102040816326,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.6527701616287231
            }
          ],
          "pos_tags": [
            "NOUN",
            "PROPN"
          ],
          "context": [
            "Best pharmacy services in helping the customers with questionns",
            "They have fast pharmacy service and they try to get your prescription filled right away.",
            "Cvs is very convenient. The pharmacy service is excellent. The store is well laid out. There are also price advantages"
          ]
        },
        {
          "phrase": "trust pharmacy staff",
          "frequency": 1,
          "sem_sim": 0.8384018686352943,
          "char_sim": 0.7755102040816326,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.7742571234703064
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "I think it\u0027s a convenient location for personal items where the locations are great. I think you can usually trust pharmacy staff to answer questions."
          ]
        },
        {
          "phrase": "typical pharmacy store",
          "frequency": 1,
          "sem_sim": 0.8730487409903078,
          "char_sim": 0.7551020408163265,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9959017634391785
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "I would say they are a typical pharmacy store with proces a hair higher than Walgreens."
          ]
        },
        {
          "phrase": "nice store pharmacy",
          "frequency": 0,
          "sem_sim": 0.9196078448879476,
          "char_sim": 0.8112244897959183,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9993540644645691
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": []
        },
        {
          "phrase": "convenient pharmacy",
          "frequency": 1,
          "sem_sim": 0.8751249289026066,
          "char_sim": 0.8112244897959183,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9946923851966858
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "I do not like CVS  but it is the most convenient pharmacy so my prescriptions are sent there."
          ]
        },
        {
          "phrase": "entire pharmacy market",
          "frequency": 1,
          "sem_sim": 0.8416906461423757,
          "char_sim": 0.7551020408163265,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9450613856315613
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS has good sales. They own almost the entire pharmacy market here in Rhode Island.  Their extra bucks program is actually pretty good."
          ]
        },
        {
          "phrase": "store pharmacy",
          "frequency": 4,
          "sem_sim": 0.8787938988938624,
          "char_sim": 0.8724489795918366,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.8139839172363281
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "I am required to fill Rx with CVS. That said  my local store pharmacy is open daily  with great staff ",
            "CVS is a great store pharmacy  but shop around as their prices might be higher than other stores.",
            "For in store shopping id1recommend cvs for the extra care eeward deals. Pharmacy is a different story.  The in store pharmacy would be fine if the website to check status of prescriptions was at least 55% accurate. Ive had so much trouble and am thinking of changing pharmacies as im tired of every prescription being a tug of war. Never know what is accurate on website. Call pharmacy regarding prescription that says in process for over 6 days. According to pharmacy they never received prescriptio",
            "I think CVS is a nice little convenient store pharmacy. I dont shop there as much as others but it\u0027s still great"
          ]
        },
        {
          "phrase": "helpful pharmacy staff",
          "frequency": 1,
          "sem_sim": 0.8595309549448442,
          "char_sim": 0.7551020408163265,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9991297125816345
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "Cvs is convenient with very helpful pharmacy staff when you have questions.  The selection of products is very comprehensive and the store has quick check out"
          ]
        },
        {
          "phrase": "great store pharmacy",
          "frequency": 1,
          "sem_sim": 0.9231172812228301,
          "char_sim": 0.8367346938775511,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.998889148235321
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS is a great store pharmacy  but shop around as their prices might be higher than other stores."
          ]
        },
        {
          "phrase": "pharmacy staff",
          "frequency": 17,
          "sem_sim": 0.859796986287954,
          "char_sim": 0.7959183673469388,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.7335594296455383
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "Prices at CVS are higher than WalMart  but I use them because they are convenient  especially for prescription renewals. I have a good relationship with the pharmacy staff at my local CVS.",
            "They are like family. The pharmacy staff knows my name and acknowledges me with it every time i go in.",
            "I find the store has a friendly and knowledgeable pharmacy staff when there in person  and the chain works well with timely text messages.  Outside of pharmacy  they are well stocked and often have good sale events  especially for OTC products.",
            "I use the CVS inside of Target. The pharmacy staff is very helpful at all times. They genuinely care about their customers.",
            "i find the store to be more exspensive so i tend not to shope there the pharmacy staff is not friendly and they are very disorganized",
            "Carries quality brands  including store brand. Knowledgable pharmacy staff. Pharmacy generally understaffed resulting in wait times",
            "my location has an excellent pharmacy staff",
            "CVS is conveniently located  and they have a variety of products  including a pharmacy and knowledgeable pharmacy staff.",
            "Cvs is convenient with very helpful pharmacy staff when you have questions.  The selection of products is very comprehensive and the store has quick check out",
            "I think it\u0027s a convenient location for personal items where the locations are great. I think you can usually trust pharmacy staff to answer questions.",
            "The pharmacy staff is excellent and very helpful.  The store overall is more expensive than all other personal care stores  and their prescriptions are also more expensive than most others.  The store is always clean and well laid out  but I find much better values at Meijer and MUCH bigger selection of items.",
            "I like CVS  have found their pharmacy staff to be professional and helpful through the years. Comfortable with my local store layout. Not at all happy to notice the subtle introduction of self checkout and will seriously consider shopping elsewhere if this gains traction",
            "The pharmacy staff is very friendly and knowledgeable. Store is cluttered and disorganized. Parking lot is difficult to enter and exit.",
            "CVS has good weekly ads and is easy to shop at. Their aisles are wide enough to get around the store easily. The employees and pharmacy staff are always very friendly.",
            "I\u0027ve always had great service at CVS with filling my prescriptions there. My medications area always ready for pick up as expected. The pharmacy staff is always helpful in answering my questions. I also like the automatic refills.",
            "Knowledgeable pharmacy staff  friendly check out staff  clean stores and wide variety of products.",
            "The pharmacy staff is helpful  knowledgeable and always go out of their way to provide me with the best service possible. They are well trained in their craft and customer service as well."
          ]
        },
        {
          "phrase": "pharmacy store",
          "frequency": 4,
          "sem_sim": 0.8787938988938624,
          "char_sim": 0.7959183673469388,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.8810147643089294
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "It is an OK place similar to all other pharmacy stores",
            "I donât know much about this pharmacy store since there is not one in my area.",
            "I would say they are a typical pharmacy store with proces a hair higher than Walgreens.",
            "Cvs is a good pharmacy store"
          ]
        },
        {
          "phrase": "staff pharmacy",
          "frequency": 0,
          "sem_sim": 0.859796986287954,
          "char_sim": 0.8367346938775511,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.870323896408081
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": []
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9998068809509277
        }
      ],
      "topic_frequency": 22,
      "topic_tags": [
        "ADJ",
        "NOUN"
      ],
      "topic_context": [
        "good pharmacy  and store is well-stocked  clean and neat layout",
        "Has a good pharmacy and lots of shelf items but does not take Tricare at pharmacy.",
        "Cvs has a good pharmacy. Whet I love the most is their seasonal section. I always find something cute to buy",
        "It\u0027s a good pharmacy I\u0027ve used it many times before I have shopped there.",
        "good pharmacy with lots of specials",
        "CVS is expensive with their prices of their products. They do have a good pharmacy to look into. They also have a variety of products that may not be found at other retailers but the prices are not so favorable when purchasing.",
        "They have a good pharmacy and great photo dept",
        "good pharmacy  dreadful customer service otherwise",
        "CVS has a good pharmacy.",
        "CVS is a good pharmacy.  Unfortunately  It isn\u0027t convenient for me so I use another pharmacy in stead.  Most of my prescriptions are mailed to me by my benefits preferred mail order pharmacy.",
        "has convenience and good pharmacy help. it has good rewards sometimes.",
        "CVS is a good pharmacy  staffed by professionals and with a large variety of products.  I don\u0027t particularly like the fact that they stopped selling tobacco products  and I\u0027m not sure the prices are all that competitive  but overall they\u0027re OK.",
        "CVS is a good store but they don\u0027t really offer any real good pharmacy discounts and are sometimes very expensive",
        "CVS is a good pharmacy that sells products we need and medical needs as well.",
        "CVS is a good pharmacy to go to  but I dont like to shop there because it is expensive.",
        "this is a good pharmacy",
        "Decent product selection and friendly staff  good pharmacy discounts. Generally preferable to Walgreens.",
        "CVS is a good pharmacy place to get products for skin  hair and your overall health.",
        "Cvs is a good pharmacy store",
        "CVS is a good pharmacy for prescriptions even though their prices on other items are expensive when not on sale.",
        "its a good pharmacy",
        "CVS has a good pharmacy located in their store.  While you are waiting for a prescription you can shop for other items.  Store is always very clean and organized."
      ]
    },
    {
      "topic": "friendly staff",
      "phrases": [
        {
          "phrase": "friendly staff",
          "frequency": 30,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.999846339225769
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "friendly staff",
            "A great place with friendly staff and convenient to get many of the things I need.",
            "CVS is a convenient place to shop  has a good selections of products  and has a friendly staff. The only concern I have is with the pharmacy which too often makes mistakes or does not have the medication in stock.",
            "CVS is very efficient  with a wide variety of products and very friendly staff to assist you with questions or purchases",
            "Very convenient location; knowledgeable  friendly staff; prices commensurate with other pharmacies; drive-through is usually fairly quick",
            "Great place  friendly staff  eager to please.",
            "Good prices good coupons friendly staff",
            "I like cvs because it has friendly staff  and a lot of different products and most of the time check out is easy and quick",
            "I enjoy shopping at CVS for their products  friendly staff and great sales",
            "friendly staff  quick service  medications easily available  reasonable prices",
            "Easy to navigate around store and friendly staff fair prices good deals",
            "Convenient and friendly staff",
            "good deals  always coupons  reward bucks  friendly staff  large selection",
            "I love CVS. They have competitive prices  friendly staff and have everything I need in one stop.",
            "they have friendly staff quality items",
            "Decent product selection and friendly staff  good pharmacy discounts. Generally preferable to Walgreens.",
            "CVS has a great rewards program  and competitive prices. They are conveniently located with clean  organized stores. Overwhelming majority of shopping experiences includes friendly staff and a great selection of products.",
            "Very cleannnn friendly staff",
            "generally reliable  timely filling of prescriptions  knowledgeable and friendly staff most of the time",
            "Many locations in the aream Clean well lit stores. Variety of brand name and store brand products.Well stocked and mostly friendly staff.",
            "Cvs has friendly staff to make shopping easier and convenient",
            "Caring pharmacist and friendly staff.  High prices",
            "Very good customer service  quick filling of prescriptions  friendly staff.",
            "Great customer friendly staff. Has products I need for fair prices and a great rewards program.",
            "it is conveniently located  easy to get in and out of  well stocked and has friendly staff",
            "It has a full pharmacy beauty household food beverages. Great deals and promotions clean and friendly staff",
            "cvs is a great place to fill your prescriptions they have super friendly staff and really care about you their store is super clean and well stocked and they have pretty good sales",
            "Easy to shop at  clean friendly staff",
            "They have pretty friendly staff who ask about your needs. Every now and again there is a staff person who is less than friendly  who can border on rude. But for the most part  everyone is pretty helpful.",
            "Popular brand with many locations  easy access and great prices  nice friendly staff"
          ]
        },
        {
          "phrase": "friendly helpful staff",
          "frequency": 0,
          "sem_sim": 0.886130690574646,
          "char_sim": 0.7878048780487805,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998347759246826
            }
          ],
          "pos_tags": [
            "ADJ",
            "ADJ",
            "NOUN"
          ],
          "context": []
        },
        {
          "phrase": "friendly staff welcoming",
          "frequency": 0,
          "sem_sim": 0.8986527919769287,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998560547828674
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "VERB"
          ],
          "context": []
        },
        {
          "phrase": "staff free",
          "frequency": 1,
          "sem_sim": 0.6061955094337463,
          "char_sim": 0.7585365853658537,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.8413876295089722
            }
          ],
          "pos_tags": [
            "NOUN",
            "ADJ"
          ],
          "context": [
            "Items can be hard to find and often there are not staff free to help"
          ]
        },
        {
          "phrase": "great staff",
          "frequency": 2,
          "sem_sim": 0.6376930475234985,
          "char_sim": 0.7365853658536585,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998586773872375
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "cvs has excellent prices on their products  they have  lots of sales  great staff  good rewards program  name brands",
            "I am required to fill Rx with CVS. That said  my local store pharmacy is open daily  with great staff "
          ]
        },
        {
          "phrase": "good staff",
          "frequency": 3,
          "sem_sim": 0.6719184517860413,
          "char_sim": 0.7804878048780488,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998448491096497
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "great place  clean  economical  good staff",
            "CVS has very good staff  and the prescription department is very good.  They have worthwhile sales  but they are quite expensive for regular  in store items.",
            "It\u0027s a store that has good staff and the pharmacists are very helpful as well.  The price(s) are reasonable and they often have very good sales on a lot of their products.  The parking is always very good as well."
          ]
        },
        {
          "phrase": "entire staff",
          "frequency": 0,
          "sem_sim": 0.5630760192871094,
          "char_sim": 0.7804878048780488,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.997584342956543
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": []
        },
        {
          "phrase": "poor staff",
          "frequency": 1,
          "sem_sim": 0.5851699709892273,
          "char_sim": 0.7073170731707317,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9997918605804443
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Sore is run down with poor staff .  However  CareMark is great for perscriptions."
          ]
        },
        {
          "phrase": "enough staff",
          "frequency": 2,
          "sem_sim": 0.5746498107910156,
          "char_sim": 0.7804878048780488,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9951354265213013
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Cvs can be hit or miss. If its a nice cvs they have good prices  selection  and enough staff. If its a \"miss\" cvs  prices are high  selection is low  and theres only ever 1-2 people working  so finding help is impossible.",
            "They have a food selection of products but the pricing is too high not enough staff on board"
          ]
        },
        {
          "phrase": "staff person",
          "frequency": 2,
          "sem_sim": 0.5709630250930786,
          "char_sim": 0.7089295865798029,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9906492233276367
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS runs fairly decent sales  but regular prices are high.  We do not have a local CVS  but the one we went to in Boston when we were on vacation had a very crabby staff person at the register.  We mainly went there because it was a convenient location  but otherwise I would not have gone back to that one in particular.",
            "They have pretty friendly staff who ask about your needs. Every now and again there is a staff person who is less than friendly  who can border on rude. But for the most part  everyone is pretty helpful."
          ]
        },
        {
          "phrase": "pharmacy staff friendly",
          "frequency": 0,
          "sem_sim": 0.8918851772133185,
          "char_sim": 0.7142857142857143,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9980708360671997
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN",
            "ADJ"
          ],
          "context": []
        },
        {
          "phrase": "pharmacy staff friendly check",
          "frequency": 0,
          "sem_sim": 0.8517849360193526,
          "char_sim": 0.7091836734693877,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9690392017364502
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN",
            "ADJ",
            "NOUN"
          ],
          "context": []
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.999846339225769
        }
      ],
      "topic_frequency": 30,
      "topic_tags": [
        "ADJ",
        "NOUN"
      ],
      "topic_context": [
        "friendly staff",
        "A great place with friendly staff and convenient to get many of the things I need.",
        "CVS is a convenient place to shop  has a good selections of products  and has a friendly staff. The only concern I have is with the pharmacy which too often makes mistakes or does not have the medication in stock.",
        "CVS is very efficient  with a wide variety of products and very friendly staff to assist you with questions or purchases",
        "Very convenient location; knowledgeable  friendly staff; prices commensurate with other pharmacies; drive-through is usually fairly quick",
        "Great place  friendly staff  eager to please.",
        "Good prices good coupons friendly staff",
        "I like cvs because it has friendly staff  and a lot of different products and most of the time check out is easy and quick",
        "I enjoy shopping at CVS for their products  friendly staff and great sales",
        "friendly staff  quick service  medications easily available  reasonable prices",
        "Easy to navigate around store and friendly staff fair prices good deals",
        "Convenient and friendly staff",
        "good deals  always coupons  reward bucks  friendly staff  large selection",
        "I love CVS. They have competitive prices  friendly staff and have everything I need in one stop.",
        "they have friendly staff quality items",
        "Decent product selection and friendly staff  good pharmacy discounts. Generally preferable to Walgreens.",
        "CVS has a great rewards program  and competitive prices. They are conveniently located with clean  organized stores. Overwhelming majority of shopping experiences includes friendly staff and a great selection of products.",
        "Very cleannnn friendly staff",
        "generally reliable  timely filling of prescriptions  knowledgeable and friendly staff most of the time",
        "Many locations in the aream Clean well lit stores. Variety of brand name and store brand products.Well stocked and mostly friendly staff.",
        "Cvs has friendly staff to make shopping easier and convenient",
        "Caring pharmacist and friendly staff.  High prices",
        "Very good customer service  quick filling of prescriptions  friendly staff.",
        "Great customer friendly staff. Has products I need for fair prices and a great rewards program.",
        "it is conveniently located  easy to get in and out of  well stocked and has friendly staff",
        "It has a full pharmacy beauty household food beverages. Great deals and promotions clean and friendly staff",
        "cvs is a great place to fill your prescriptions they have super friendly staff and really care about you their store is super clean and well stocked and they have pretty good sales",
        "Easy to shop at  clean friendly staff",
        "They have pretty friendly staff who ask about your needs. Every now and again there is a staff person who is less than friendly  who can border on rude. But for the most part  everyone is pretty helpful.",
        "Popular brand with many locations  easy access and great prices  nice friendly staff"
      ]
    },
    {
      "topic": "good option",
      "phrases": [
        {
          "phrase": "good option",
          "frequency": 4,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998419880867004
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS is still a good option if it is good in convenience  however based on prices I have also find CVS to be a bit more expensive than Walgreens  so I would prefer to go to the location with the better prices.",
            "Cvs is a very reputable chain pharmacy and would be a good option. Personally I havenât shopped there much since I have an established relationship with walgreens",
            "CVS is a good option for lots of things. Go when they have sales on the things you want.",
            "Has very good options"
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9998419880867004
        }
      ],
      "topic_frequency": 4,
      "topic_tags": [
        "ADJ",
        "NOUN"
      ],
      "topic_context": [
        "CVS is still a good option if it is good in convenience  however based on prices I have also find CVS to be a bit more expensive than Walgreens  so I would prefer to go to the location with the better prices.",
        "Cvs is a very reputable chain pharmacy and would be a good option. Personally I havenât shopped there much since I have an established relationship with walgreens",
        "CVS is a good option for lots of things. Go when they have sales on the things you want.",
        "Has very good options"
      ]
    },
    {
      "topic": "good job",
      "phrases": [
        {
          "phrase": "good job",
          "frequency": 4,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998357892036438
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "I\u0027m currently using Walgreens for prescription drugs because it is my insurance preferred drug store.  Previously CVS was my insurance preferred drug store.  Both companies do a good job  but a like CVS better because the staff is friendlier and more helpful and the store is less crowded  therefore  easier to get in and out quickly. The only drawback to CVS is they are not open 24 hours and Walgreens is. But I did not have any after hours emergencies.So if a had a choice I would shop at CVS for",
            "I think they do a pretty good job with their customer service and efficiency",
            "They do a good job.",
            "I do not \"feel\" about CVS. They are a retail outlet and do a very good job of providing services that I need. the stores are always clean and the employees have always treated me well."
          ]
        },
        {
          "phrase": "job done",
          "frequency": 3,
          "sem_sim": 0.8986284392220634,
          "char_sim": 0.6887755102040816,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9989445209503174
            }
          ],
          "pos_tags": [
            "NOUN",
            "VERB"
          ],
          "context": [
            "Always the best phTmacist who go out of their way to get the job done for me",
            "It gets the job done but its not organized particularly well nor does it offer any oustanding deals or discounts.",
            "Nothing special about CVS.  They get the job done."
          ]
        },
        {
          "phrase": "great job",
          "frequency": 3,
          "sem_sim": 0.956142329439825,
          "char_sim": 0.7959183673469388,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998588562011719
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "I would say that they do a great job there.",
            "Even though they are always unserstaffed they do a great job",
            "I have used CVS to fill my prescriptions for the past 5 years. Normally they do a great job and know me by name and I know them by name but recently they have been overwhelmed by the amount of people needing prescriptions and CVS will not give them extra help so there has been a lot of turn over lately which has led to them not ordering my prescriptions when they were supposed to or loosing my prescription forms from the doctor. The staff is even begging people to call corporate and complain so"
          ]
        },
        {
          "phrase": "new job",
          "frequency": 1,
          "sem_sim": 0.8756187655487839,
          "char_sim": 0.7653061224489796,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9983413815498352
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Cvs will not allow for you today lol I\u0027m just waiting on a new job so far I can get the car"
          ]
        },
        {
          "phrase": "good game",
          "frequency": 0,
          "sem_sim": 0.8141840148945244,
          "char_sim": 0.7959183673469388,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998469948768616
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": []
        },
        {
          "phrase": "good assistance",
          "frequency": 1,
          "sem_sim": 0.7915476305144173,
          "char_sim": 0.7142857142857143,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998254776000977
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Try to shop at CVS they have a good assistance and convenient to shop"
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9998357892036438
        }
      ],
      "topic_frequency": 4,
      "topic_tags": [
        "ADJ",
        "NOUN"
      ],
      "topic_context": [
        "I\u0027m currently using Walgreens for prescription drugs because it is my insurance preferred drug store.  Previously CVS was my insurance preferred drug store.  Both companies do a good job  but a like CVS better because the staff is friendlier and more helpful and the store is less crowded  therefore  easier to get in and out quickly. The only drawback to CVS is they are not open 24 hours and Walgreens is. But I did not have any after hours emergencies.So if a had a choice I would shop at CVS for",
        "I think they do a pretty good job with their customer service and efficiency",
        "They do a good job.",
        "I do not \"feel\" about CVS. They are a retail outlet and do a very good job of providing services that I need. the stores are always clean and the employees have always treated me well."
      ]
    },
    {
      "topic": "good store",
      "phrases": [
        {
          "phrase": "good store",
          "frequency": 49,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998533725738525
            }
          ],
          "pos_tags": [
            "ADJ",
            "PROPN"
          ],
          "context": [
            "it is a good store if you are a member of their reward\u0027s program. there is no store where i live.",
            "It\u0027s a good store and I wish it were closer to my house.",
            "I like CVS it is a good store but their prices are a little bit higher than other retailers I like the customer service and I can find things easily I just don\u0027t like paying high prices for the same products",
            "CVS is a good store.  If needed something fast I would go to CVS",
            "They are a good store and have a good selection of make up and beauty brands.",
            "it a good store",
            "CVS is a good store. I like it but itâs just too far from my home.",
            "good store  no sales to speak of  have allot of products I use but prices are higher than rite-aid",
            "CVS is a good store but sometimes the prices are cheaper elsewhere.",
            "CVS is a good store that carries a lot of personal hygiene products",
            "8use CVS its a good store reliable good place to shop",
            "CVS is a good store  has almost everything you need  but  there is not a store close to me",
            "I believe that CVS is a good store to purchase Medication  cosmetics  cards   etc. The stores are clean and easy to find what Iâm looking for.",
            "CVS is a good store..very clean",
            "Its a good store to go",
            "CVS is a good store  but their regular prices are too high.  However  they have frequent excellent regular coupons  loyalty rewards  and or specials which make their prices very competitive.  The problem is  the effort it takes to find out if the item you are looking for is available at a good price at the time you want to purchase it.",
            "its a good store",
            "I believe CVS is a good store to shop for with the good sales they have.",
            "cvs is a good store for medicines and  some everyday items",
            "good store but mainly go to Walgreen",
            "I honestly donât shop there but they are still a good store I would recommend",
            "CVS is a good store with a good variety of products and easy to shop at.",
            "convenient location and good store hours",
            "Cvs- It is ok.  There is nothing I dislike about it but I have no reason to go there since I already have a number of good stores to do my shopping in",
            "CVS is a good store",
            "good store",
            "Itâs a good store for cash back",
            "CVS is a good store to shop for cards  drinks  and buy pharmacueticals.",
            "Its a pretty good store with reasonable prices and pretty good reward program.  Unfortunately  a lot of their weekly specials sell out fast and store is a little difficult to navigate but overall its a pretty good place to go for snacks  personal care and drugs.",
            "CVS is a good store but is fairly expensive.",
            "CVS is a good store but they don\u0027t really offer any real good pharmacy discounts and are sometimes very expensive",
            "cvs is a good store to shop in  with quality help that will help you find what you need - they also have rewards for shopping there and good coupons and sales",
            "good store with easy location and carries a variety of goods",
            "CVS is a good store with a variety of items.",
            "A good store but nothing special.",
            "That it is a good store with people that are helpful.",
            "Itâs a good store there is just not close to me but there is one close to you.",
            "Cvs is a good store",
            "Cvs is a good store to shop staff is knowledgeable and always says hi",
            "CVS is a good store to shop in. They always have discounts and you can join CVS cares for extra discounts. The store is always well stocked and checkout is quick",
            "Longs is a good store. Its spacious and walking distance from myhouse. They have approachable people and good variety of products.",
            "It is a good store good products to shop for",
            "well it\u0027s a good store. it has nice stuff you can buy  like cosmetics  but it\u0027 not a good place to go to if you\u0027re grocery shopping.",
            "Is a good store",
            "CVS is a good store to go to to buy greeting cards  birthday cards  mother and father\u0027s day cards and medication.",
            "CVS is a good store to get most of what you need",
            "CVS employees are always helpful. The pharmacy is always there for me. The prices are reasonable and they have good store name products.",
            "CVS is a good store for common health and beauty needs.",
            "CVS is not a close location for me  otherwise they are a good store."
          ]
        },
        {
          "phrase": "nice place store",
          "frequency": 1,
          "sem_sim": 0.9016367099723037,
          "char_sim": 0.7295918367346939,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998537302017212
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS is a nice place store with many items to pick from with usually good sales."
          ]
        },
        {
          "phrase": "favorite store",
          "frequency": 2,
          "sem_sim": 0.836735766761157,
          "char_sim": 0.7908163265306123,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9987806677818298
            }
          ],
          "pos_tags": [
            "ADJ",
            "PROPN"
          ],
          "context": [
            "one of my favorite stores",
            "CVS seems pricey but their Extrabucks makes it very affordable. CVS is my favorite store to coupon at"
          ]
        },
        {
          "phrase": "shop great people work",
          "frequency": 0,
          "sem_sim": 0.8148456568620643,
          "char_sim": 0.6530612244897959,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9995203614234924
            }
          ],
          "pos_tags": [
            "PROPN",
            "ADJ",
            "NOUN",
            "VERB"
          ],
          "context": []
        },
        {
          "phrase": "store location",
          "frequency": 3,
          "sem_sim": 0.8330737911925024,
          "char_sim": 0.7091836734693877,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9891520738601685
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "I used to go to CVS but donât go now.  The store location is farther away and I donât participate in a loyalty program.",
            "The store location is very convenient to me. I like the pharmacist and value his opinion and judgement. The store has a good selection of products and the staff is knowledgable.",
            "CVS is flaky.  Some days you\u0027ll have a good experience and other days you\u0027d be better off avoiding this store at all costs. NEVER expect the CVS care card kiosk in the store to work (ie spit out coupons  provide deals  etc) as it\u0027s generally OFF-LINE  NOT working  out of paper  etc.  Items listed in weekly circulars may or may not be in stock or even carried by the particular store location you plan to shop at.  It\u0027s a hit and miss proposition if you\u0027ll be satisfied by your experience.  When it\u0027"
          ]
        },
        {
          "phrase": "nice print shop",
          "frequency": 1,
          "sem_sim": 0.8120557641496464,
          "char_sim": 0.6173469387755102,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9997549653053284
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "It is a one stop convenient shop with a great loyalty program. you can also get photos printed here and have a nice print shop"
          ]
        },
        {
          "phrase": "positive shop",
          "frequency": 2,
          "sem_sim": 0.8243823392050607,
          "char_sim": 0.673469387755102,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998232126235962
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "positive shop because of location convenience variety Costco is better value  but less convenient  longer lines",
            "Any time that i have visited CVS I have had a positive shopping experience. However  their prices are quite a bit higher than many other places."
          ]
        },
        {
          "phrase": "good shop",
          "frequency": 3,
          "sem_sim": 0.913768961721537,
          "char_sim": 0.8724489795918366,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998520612716675
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS is well organized and offers good shopping experiences  but unless the items are on sale  the prices are higher than other retailers.",
            "cvs is a good shop for shopping in holiday season as well as regular prescription  one minute clinic",
            "good shop to purchase prescription aswll as quick items"
          ]
        },
        {
          "phrase": "great store",
          "frequency": 19,
          "sem_sim": 0.9529028169962825,
          "char_sim": 0.8367346938775511,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998225569725037
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Itâs a great store to get prescriptions and a bunch of other cool stuff. One stop quick shop",
            "Cvs is a great store to use and offers convenience over pricing.  CVS is convenient but high in price",
            "great store",
            "great store ts o shop    many coupons and the prices are always great",
            "cvs is a great store. good rewards. plenty of selection. pharmacy inside is convenient",
            "CVS is a great store with tons of brand products but pricey even with their loyalty program. CARE",
            "It is a great store with plenty of choices.",
            "Cvs is a great store",
            "CVS is a great store to have prescriptions filled. They are quick.",
            "CVS is a great store to put up something quick. If you forgot a gift for someone You can get gifts cards or Chocolate as a gift.",
            "It\u0027s a great store just not near me to shop.",
            "CVS is a great store pharmacy  but shop around as their prices might be higher than other stores.",
            "It\u0027s a great store and they have some cool benefits with their membership program. I don\u0027t know them all because we have several Walgreens closer than our closest CVS  so it\u0027s normally only somewhere I stop if I happen to be close. They have good clearance though.",
            "cvs is a great store its just i do not shop there any more",
            "CVS is a great store to shop for most every thing you could need.  Plus the offer in store coupons and also cash to use on your next visit.",
            "CVS is a great store. It is convenient and they have a good selection.",
            "I feel it is a great store 62 prices Akron Ohio so it\u0027s not always my first choice",
            "I would tell them that CVS is a great store to deal with. I would tell them that they carry great products and that they offer great deals as well.",
            "it is a great store with great products and customer service"
          ]
        },
        {
          "phrase": "clean store",
          "frequency": 15,
          "sem_sim": 0.8471164630383861,
          "char_sim": 0.7857142857142857,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9989551901817322
            }
          ],
          "pos_tags": [
            "ADJ",
            "PROPN"
          ],
          "context": [
            "love their \u0027deals\u0027 and the neat and clean stores that are well organized",
            "It is a clean store but overall more expensive than  other  retailers",
            "CVS is a clean store and generally well stocked.  If you need to have a prescription filled  be sure you carve out a lot of time in your day because there is always a relatively long wait time.   As far as the store goes  CVS is coupon-driven  so be sure you obtain a loyalty card to get their coupons.  They love paper at CVS and you will even get coupons on your receipt  as well as you can obtain coupons at a store kiosk.",
            "It is a nice clean store",
            "Nice  clean store.   I do not personally shop there  as not in a convenient location.",
            "CVS is close to home.  Good clean stores.  Easy to find products I am looking for.  Friendly employees",
            "its convenient location and clean stores  but check out can take a long time depending on when you are shopping.  they offer a lot of coupons",
            "cvs has good quality brands  customer service is good  clean stores",
            "very clean store  nice employee help  items in stock  ease of checking out",
            "I like the clean store and the bonus rewards.",
            "they have clean stores  helpful and knowledgeable employees  offer coupons of value  convenient locations to home  rain checks.",
            "Knowledgeable pharmacy staff  friendly check out staff  clean stores and wide variety of products.",
            "They have clean stores  very organized and tidy and products are easy to find  great customer service",
            "Large  clean store  convenient  reasonable prices",
            "get all my prescriptions here  clean store and I like their candy"
          ]
        },
        {
          "phrase": "nice store",
          "frequency": 9,
          "sem_sim": 0.9391952692245951,
          "char_sim": 0.8010204081632653,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998422265052795
            }
          ],
          "pos_tags": [
            "ADJ",
            "PROPN"
          ],
          "context": [
            "cvs is a nice store but the store in my area is small and old.  I like cvs but their prices are higher than Walmart and except for their coupons  they are too expensive.",
            "nice store but expensive",
            "I would tell my friend that CVS is a very nice store Pharmacy. where you can get alot shopping done plus get prescription  filled and with very pleasant atmosphere .",
            "CVS is a nice store  but I prefer Walgreens.  It\u0027s closer to home.  I have heard that CVS pharmacists are friendlier and more helpful than those at Walgreens  though.",
            "It\u0027s a nice store but a bit pricey on things",
            "Great locations  nice stores",
            "I like CVS.  They have nice stores.  I especially like the in house clinic with the nurses.  I\u0027ve used them and they\u0027re good.  Now about shopping.  They have good stuff  but prices are much higher than Walmart  target  or Marc\u0027s.",
            "Clean and nice store",
            "Cvs is a nice store. But very limited in location around here."
          ]
        },
        {
          "phrase": "preferred drugstore",
          "frequency": 1,
          "sem_sim": 0.728415561573846,
          "char_sim": 0.7397959183673469,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9623725414276123
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS is my preferred drugstore. Itâs my preferred drugstore because the products I need are usually in stock and I never need to wait long in the checkout line. They also seem to be better about fixing prescription issues than many other pharmacies I have used."
          ]
        },
        {
          "phrase": "typical drugstore",
          "frequency": 1,
          "sem_sim": 0.763200573775233,
          "char_sim": 0.7193877551020408,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9963141083717346
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS is a typical drugstore.  There isn\u0027t one conveniently located near me  so I don\u0027t shop there."
          ]
        },
        {
          "phrase": "good drugstore",
          "frequency": 1,
          "sem_sim": 0.8810807709791222,
          "char_sim": 0.9183673469387754,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998244047164917
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Its a good drugstore with many brand and private label products and is a national chain"
          ]
        },
        {
          "phrase": "local drugstore needs",
          "frequency": 1,
          "sem_sim": 0.7526101360515672,
          "char_sim": 0.6938775510204082,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9633204340934753
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "VERB"
          ],
          "context": [
            "Cvs is a place where you can find your local drugstore needs for an everyday low price"
          ]
        },
        {
          "phrase": "grocery item",
          "frequency": 2,
          "sem_sim": 0.7114957491962277,
          "char_sim": 0.7244897959183674,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.600893497467041
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "Everyone who works at CVS are very friendly and helpful for all of your needs. Whether it\u0027s a health question  medication question  beauty products question  or just a simple question about the grocery items they treat you like family.",
            "I used to shop there  but when bigger stores with more variety arrived  I found that I can pick up a grocery item and a beauty item at discounted prices. In today\u0027s world  shopping is about convenience and saving money.  I don\u0027t find that combination at CVS and have never filled a prescription there."
          ]
        },
        {
          "phrase": "regular grocery store",
          "frequency": 1,
          "sem_sim": 0.841893301934612,
          "char_sim": 0.760204081632653,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.81516432762146
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "Stores are clean and employees helpful but prices are much higher than those of Wal-Mart or my regular grocery store."
          ]
        },
        {
          "phrase": "well organized store",
          "frequency": 3,
          "sem_sim": 0.835702203974432,
          "char_sim": 0.7653061224489796,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9996869564056396
            }
          ],
          "pos_tags": [
            "INTJ",
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS is a clean and well organized store.  Employees are always friendly and helpful.  It\u0027s a little on the pricey side  but they do occasionally have good sales and the rewards program is not bad.  If deals are loaded to your rewards card  though  you have to be on top of things at checkout. If you\u0027re not paying attention  cashiers will be selective in the coupons they apply  and sometimes will only apply loaded coupons if you ask - even though they can see them on the register when they finish",
            "a well organized store with a large parking lot and chairs to sit on while you wait",
            "Is a very clean and well organized store and the pharmacist are polite and informative."
          ]
        },
        {
          "phrase": "nice clean store",
          "frequency": 1,
          "sem_sim": 0.8854347686378323,
          "char_sim": 0.7295918367346939,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998233318328857
            }
          ],
          "pos_tags": [
            "ADJ",
            "ADJ",
            "NOUN"
          ],
          "context": [
            "It is a nice clean store"
          ]
        },
        {
          "phrase": "store quick",
          "frequency": 2,
          "sem_sim": 0.8927628251971031,
          "char_sim": 0.7397959183673469,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9478605389595032
            }
          ],
          "pos_tags": [
            "NOUN",
            "ADJ"
          ],
          "context": [
            "CVS is a great place to shop  surprises can be found in every corner.  It\u0027s not just a place to pick up prescriptions  magazines  and toothpaste.  My CVS carries almost. everything that you\u0027d find in a Walmart. Most of all I can get in an out of the store quickly  the sales items are wonderful and hen there are the coupons and Extra bucks rewards.  This has become my go to store as its always open.",
            "I love to go there   they have great costumer services and they get you in and out of the store quick"
          ]
        },
        {
          "phrase": "store name",
          "frequency": 2,
          "sem_sim": 0.8136279582977295,
          "char_sim": 0.75,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9872227311134338
            }
          ],
          "pos_tags": [
            "PROPN",
            "PROPN"
          ],
          "context": [
            "CVS has a selection of brand- and store name products and usually offers competitive pricing.",
            "CVS employees are always helpful. The pharmacy is always there for me. The prices are reasonable and they have good store name products."
          ]
        },
        {
          "phrase": "bigger store",
          "frequency": 3,
          "sem_sim": 0.8618993272586745,
          "char_sim": 0.8214285714285714,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9987094402313232
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "I used to shop there  but when bigger stores with more variety arrived  I found that I can pick up a grocery item and a beauty item at discounted prices. In today\u0027s world  shopping is about convenience and saving money.  I don\u0027t find that combination at CVS and have never filled a prescription there.",
            "I\u0027ve been in CVS. CVS is great for the quick purchases as supposed going into a bigger store.",
            "Cvs has a variety of items that you can buy so you don\u0027t have to go a bigger store. It is a tied to over store"
          ]
        },
        {
          "phrase": "store nearby",
          "frequency": 1,
          "sem_sim": 0.8413378231379451,
          "char_sim": 0.7244897959183674,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9618249535560608
            }
          ],
          "pos_tags": [
            "PROPN",
            "ADV"
          ],
          "context": [
            "Cvs is an okay store if you need something and there is a store nearby. However they have limited selections and cost a bit more than most stores"
          ]
        },
        {
          "phrase": "decent store",
          "frequency": 2,
          "sem_sim": 0.9386424969653694,
          "char_sim": 0.8214285714285714,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9997931122779846
            }
          ],
          "pos_tags": [
            "ADJ",
            "PROPN"
          ],
          "context": [
            "It is a decent store if it is located conveniently near you.  Pretty much like all other similar stores.",
            "An ok decent store a little pricey"
          ]
        },
        {
          "phrase": "average store",
          "frequency": 2,
          "sem_sim": 0.821959379984408,
          "char_sim": 0.8061224489795917,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9952560663223267
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "this is just an average store to shop at for anyting",
            "It is just an average store that offers no exceptional experience"
          ]
        },
        {
          "phrase": "another store",
          "frequency": 4,
          "sem_sim": 0.8858383468219212,
          "char_sim": 0.8061224489795917,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9904819130897522
            }
          ],
          "pos_tags": [
            "DET",
            "NOUN"
          ],
          "context": [
            "I would tell them Cvs is extremely expensive and to look for another store to shop.",
            "CVS is convenient  and it is helpful when there is a CVS located in another store.  That is typically the only time I buy from CVS.  It is a good brand  just not the first store I shop at.",
            "I feel CVS is just another store that is nothing special",
            "I feel their prices are too high. You could do better at another store."
          ]
        },
        {
          "phrase": "store layout",
          "frequency": 7,
          "sem_sim": 0.7989195086518113,
          "char_sim": 0.7244897959183674,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9953243732452393
            }
          ],
          "pos_tags": [
            "PROPN",
            "PROPN"
          ],
          "context": [
            "Our CVS is conveniently located  the store layout is simple to find things in  and we like the product selection including the CVS brand. The pharmacy is also staffed byknowledgeable  friendly employees.",
            "Good retailer  many locations  convenient  store layout can be confusing  good pharmacist and associates",
            "Bad store layout and expensive",
            "Periodically shop at CVS - just more familiar with alternative store layouts and products",
            "I like CVS  have found their pharmacy staff to be professional and helpful through the years. Comfortable with my local store layout. Not at all happy to notice the subtle introduction of self checkout and will seriously consider shopping elsewhere if this gains traction",
            "enjoy the coupons; coupons on my phone; don\u0027t like the long strip of paper coupons from cash register  sometimes wasteful; usually have product I need; the store layout doesn\u0027t change so I can find things; clean; helpful personnel; pharmacy is a plus; open 24 7; flu shots offered",
            "Nice store layout"
          ]
        },
        {
          "phrase": "helpful store",
          "frequency": 1,
          "sem_sim": 0.8852275816761718,
          "char_sim": 0.760204081632653,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9990912079811096
            }
          ],
          "pos_tags": [
            "ADJ",
            "PROPN"
          ],
          "context": [
            "CVS is a very convenient and helpful store to shop for personal care items. They often have good sales promotions. There is a rewards program. The employees are helpful. Shopping and checkout are always easy."
          ]
        },
        {
          "phrase": "store close",
          "frequency": 1,
          "sem_sim": 0.8725426184887789,
          "char_sim": 0.7397959183673469,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9930061101913452
            }
          ],
          "pos_tags": [
            "NOUN",
            "ADJ"
          ],
          "context": [
            "CVS is a good store  has almost everything you need  but  there is not a store close to me"
          ]
        },
        {
          "phrase": "dirty store",
          "frequency": 1,
          "sem_sim": 0.8548221575970553,
          "char_sim": 0.8367346938775511,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9983397722244263
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "I\u0027m not very comfortable in CVS.  I think it is a dirty store and the shelves always seem to be a little bare of product."
          ]
        },
        {
          "phrase": "store kiosk",
          "frequency": 1,
          "sem_sim": 0.837470637292278,
          "char_sim": 0.7397959183673469,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9711254835128784
            }
          ],
          "pos_tags": [
            "NOUN",
            "PROPN"
          ],
          "context": [
            "CVS is a clean store and generally well stocked.  If you need to have a prescription filled  be sure you carve out a lot of time in your day because there is always a relatively long wait time.   As far as the store goes  CVS is coupon-driven  so be sure you obtain a loyalty card to get their coupons.  They love paper at CVS and you will even get coupons on your receipt  as well as you can obtain coupons at a store kiosk."
          ]
        },
        {
          "phrase": "specific store",
          "frequency": 1,
          "sem_sim": 0.8405065512170597,
          "char_sim": 0.75,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.5588617920875549
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Filling prescriptions depends on the specific store. For everything else  they are limited in selection  but still a good place to check for specific brands if you can\u0027t find them elsewhere."
          ]
        },
        {
          "phrase": "reliable store",
          "frequency": 1,
          "sem_sim": 0.8465667014219322,
          "char_sim": 0.75,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9987025856971741
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS is a reliable store.  Not the most impressive or the best service  but you can get in and out pretty easily.  They also have a pretty good selection of over the counter products"
          ]
        },
        {
          "phrase": "perfect size store",
          "frequency": 0,
          "sem_sim": 0.8405450509518992,
          "char_sim": 0.7142857142857143,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.999795138835907
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": []
        },
        {
          "phrase": "first store",
          "frequency": 2,
          "sem_sim": 0.868083126690923,
          "char_sim": 0.7857142857142857,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9880332350730896
            }
          ],
          "pos_tags": [
            "PROPN",
            "PROPN"
          ],
          "context": [
            "My experience with CVS is it\u0027s a place I would shop at but not the first store I would shop atif I couldn\u0027t find what I was looking for at Amazon or Walmart that\u0027s when I would shop at CVS",
            "CVS is convenient  and it is helpful when there is a CVS located in another store.  That is typically the only time I buy from CVS.  It is a good brand  just not the first store I shop at."
          ]
        },
        {
          "phrase": "dollar store",
          "frequency": 1,
          "sem_sim": 0.8021439678814947,
          "char_sim": 0.8214285714285714,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.6248382925987244
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS prices are to high and you can literally get the same thing at Wal-Mart or the dollar store and it works the same"
          ]
        },
        {
          "phrase": "large store",
          "frequency": 1,
          "sem_sim": 0.8581072858401707,
          "char_sim": 0.8367346938775511,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.7789500951766968
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS is a large store that carries many different products. They have coupons and special deals."
          ]
        },
        {
          "phrase": "particular store",
          "frequency": 3,
          "sem_sim": 0.8719114174648207,
          "char_sim": 0.7295918367346939,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.894783079624176
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS is flaky.  Some days you\u0027ll have a good experience and other days you\u0027d be better off avoiding this store at all costs. NEVER expect the CVS care card kiosk in the store to work (ie spit out coupons  provide deals  etc) as it\u0027s generally OFF-LINE  NOT working  out of paper  etc.  Items listed in weekly circulars may or may not be in stock or even carried by the particular store location you plan to shop at.  It\u0027s a hit and miss proposition if you\u0027ll be satisfied by your experience.  When it\u0027",
            "I like the pharmacy at a particular store.  They are always friendly and concerned about getting my prescriptions filled in a timely manner.  I also like that particular store because it\u0027s always neat and clean and the employees are helpful.  And  sale items are usually available.",
            "In my experience  CVS does not have good customer service.  The employees are rude and uncaring.  The exception to that being  the most recent CVS I went into in Mooresville  NC.  I dealt with the pharmacy employees and all were very nice and helping.  I would not encourage friends to use a particular store or brand  as that has come back to bite me in the past no matter what store dr. etc. it is."
          ]
        },
        {
          "phrase": "local store",
          "frequency": 4,
          "sem_sim": 0.8646088753427779,
          "char_sim": 0.8367346938775511,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.7234554290771484
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "That my local store knows me and treats me well as long as its close with easy parking and good rewards program. They work well with my insurance and making sure my Rx are filled and when next is due or I need to refill etc. by helping me to keep them up to date for me.",
            "they are alright but the people that work at our local store are not the best to deal with.",
            "I am required to fill Rx with CVS. That said  my local store pharmacy is open daily  with great staff ",
            "I like CVS  have found their pharmacy staff to be professional and helpful through the years. Comfortable with my local store layout. Not at all happy to notice the subtle introduction of self checkout and will seriously consider shopping elsewhere if this gains traction"
          ]
        },
        {
          "phrase": "front store",
          "frequency": 3,
          "sem_sim": 0.8539938050873426,
          "char_sim": 0.8367346938775511,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.7700535655021667
            }
          ],
          "pos_tags": [
            "ADJ",
            "PROPN"
          ],
          "context": [
            "CVS has a great app for the pharmacy. The prices aren\u0027t great unless there is a coupon or sale and even then you need to compare prices. The clearance section should always be checked out for great deals especially after holidays. I have had issues with the pharmacy taking forever to fill a prescription but that used to be rare and is occurring more often. The front store people are always kind.",
            "The pharmacy department canât be beat. The staff there are simply great to deal with. On the other hand the front store is a bit different their prices are usually insanely high. They must figure they get you in the store to pick up your drugs and then make another purchase at ridiculously high prices.",
            "this place is too high  needs more help in front store  cares only about profits  not customer!"
          ]
        },
        {
          "phrase": "regular store",
          "frequency": 1,
          "sem_sim": 0.8605679237112707,
          "char_sim": 0.8061224489795917,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9159612655639648
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Itâs worth a trip if the items you need are on sale   if not then go to your regular store."
          ]
        },
        {
          "phrase": "small store",
          "frequency": 1,
          "sem_sim": 0.8678098880514806,
          "char_sim": 0.7857142857142857,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.990090012550354
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "They have a good selection of items for a small store. The pharmacists are knowledgeable and helpful."
          ]
        },
        {
          "phrase": "physical store",
          "frequency": 2,
          "sem_sim": 0.8463855458765615,
          "char_sim": 0.75,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9726316332817078
            }
          ],
          "pos_tags": [
            "ADJ",
            "PROPN"
          ],
          "context": [
            "Stores are well stocked  prices and coupons are excellent  and there are many physical stores in the area where I live.",
            "CVS is very limited in their selection of advertised items.  To purchase a weekly advertised item often means making more than one trip to a physical store."
          ]
        },
        {
          "phrase": "local store layout",
          "frequency": 1,
          "sem_sim": 0.7993510341157719,
          "char_sim": 0.75,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9597530961036682
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "I like CVS  have found their pharmacy staff to be professional and helpful through the years. Comfortable with my local store layout. Not at all happy to notice the subtle introduction of self checkout and will seriously consider shopping elsewhere if this gains traction"
          ]
        },
        {
          "phrase": "organized store",
          "frequency": 4,
          "sem_sim": 0.8139691948890686,
          "char_sim": 0.8214285714285714,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9171593189239502
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN"
          ],
          "context": [
            "CVS has a great rewards program  and competitive prices. They are conveniently located with clean  organized stores. Overwhelming majority of shopping experiences includes friendly staff and a great selection of products.",
            "a well organized store with a large parking lot and chairs to sit on while you wait",
            "CVS is a clean and well organized store.  Employees are always friendly and helpful.  It\u0027s a little on the pricey side  but they do occasionally have good sales and the rewards program is not bad.  If deals are loaded to your rewards card  though  you have to be on top of things at checkout. If you\u0027re not paying attention  cashiers will be selective in the coupons they apply  and sometimes will only apply loaded coupons if you ask - even though they can see them on the register when they finish",
            "Is a very clean and well organized store and the pharmacist are polite and informative."
          ]
        },
        {
          "phrase": "reputable store",
          "frequency": 2,
          "sem_sim": 0.8314366705563604,
          "char_sim": 0.7397959183673469,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9778600335121155
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Its a reputable store able to do mailing prescription orders",
            "I believe CVS is a reputable store for you if you have the income to afford their products."
          ]
        },
        {
          "phrase": "friendly store",
          "frequency": 3,
          "sem_sim": 0.8661078105167467,
          "char_sim": 0.7908163265306123,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9995822906494141
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "A friendly store.",
            "There is a very affordable and customer friendly store called CVS.. and they should go try it out",
            "Convenient friendly store but with limited staff and slow prescription filling services."
          ]
        },
        {
          "phrase": "chain store",
          "frequency": 1,
          "sem_sim": 0.8372369627563321,
          "char_sim": 0.7857142857142857,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9275290966033936
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "It is open 24 hours and the pharmacist is also very helpful. Even though it is a chain store it seems like a neighborhood store."
          ]
        },
        {
          "phrase": "entire store",
          "frequency": 2,
          "sem_sim": 0.8469099451084526,
          "char_sim": 0.7755102040816326,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.988573431968689
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Their merchandise is priced too high on everything across the entire store.",
            "Pharmacy is always friendly and very helpful to answer all questions I might have.  Staff in the entire store is always eager to help out.  Love the friendliness and quality service."
          ]
        },
        {
          "phrase": "small express store",
          "frequency": 0,
          "sem_sim": 0.79787770461063,
          "char_sim": 0.7040816326530612,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9873300194740295
            }
          ],
          "pos_tags": [
            "ADJ",
            "ADJ",
            "NOUN"
          ],
          "context": []
        },
        {
          "phrase": "stock store personnel ready",
          "frequency": 0,
          "sem_sim": 0.7838020981574545,
          "char_sim": 0.6887755102040816,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.8084180355072021
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN",
            "NOUN",
            "ADJ"
          ],
          "context": []
        },
        {
          "phrase": "pop store",
          "frequency": 2,
          "sem_sim": 0.8158456160097707,
          "char_sim": 0.8724489795918366,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9638559818267822
            }
          ],
          "pos_tags": [
            "NOUN",
            "PROPN"
          ],
          "context": [
            "CVS is a predatory and rapacious brand that has an incredible amount of financial muscle. Wherever there is an established pharmacy  be it a mom and pop store or a bigger brand  CVS will build one of their stores and try to put their competitors out of business.CVS sucks.",
            "Were I live  the CVS is just a little mo pop store.The bigger place is 15 miles from me. They are a good provider depending on how you use them. I do like their mail order pharmacy."
          ]
        },
        {
          "phrase": "difficult store",
          "frequency": 1,
          "sem_sim": 0.8695029640684322,
          "char_sim": 0.7806122448979591,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9994060397148132
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS is not far from my home  so I go there once in a while.  It is a difficult store to navigate  and it is hard to find help.  The pharmacy is not well planned out  and there is usually a long line waiting to ask questions or pay"
          ]
        },
        {
          "phrase": "store good",
          "frequency": 2,
          "sem_sim": 1.0,
          "char_sim": 0.75,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.999755322933197
            }
          ],
          "pos_tags": [
            "NOUN",
            "ADJ"
          ],
          "context": [
            "It is a good store good products to shop for",
            "I do not shop there often but find the store good"
          ]
        },
        {
          "phrase": "target store",
          "frequency": 1,
          "sem_sim": 0.8027579565437473,
          "char_sim": 0.8214285714285714,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9837196469306946
            }
          ],
          "pos_tags": [
            "VERB",
            "PROPN"
          ],
          "context": [
            "Convenient location within target stores."
          ]
        },
        {
          "phrase": "retail outlet",
          "frequency": 1,
          "sem_sim": 0.7563295571171508,
          "char_sim": 0.5816326530612245,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9953667521476746
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "I do not \"feel\" about CVS. They are a retail outlet and do a very good job of providing services that I need. the stores are always clean and the employees have always treated me well."
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9998533725738525
        }
      ],
      "topic_frequency": 49,
      "topic_tags": [
        "ADJ",
        "PROPN"
      ],
      "topic_context": [
        "it is a good store if you are a member of their reward\u0027s program. there is no store where i live.",
        "It\u0027s a good store and I wish it were closer to my house.",
        "I like CVS it is a good store but their prices are a little bit higher than other retailers I like the customer service and I can find things easily I just don\u0027t like paying high prices for the same products",
        "CVS is a good store.  If needed something fast I would go to CVS",
        "They are a good store and have a good selection of make up and beauty brands.",
        "it a good store",
        "CVS is a good store. I like it but itâs just too far from my home.",
        "good store  no sales to speak of  have allot of products I use but prices are higher than rite-aid",
        "CVS is a good store but sometimes the prices are cheaper elsewhere.",
        "CVS is a good store that carries a lot of personal hygiene products",
        "8use CVS its a good store reliable good place to shop",
        "CVS is a good store  has almost everything you need  but  there is not a store close to me",
        "I believe that CVS is a good store to purchase Medication  cosmetics  cards   etc. The stores are clean and easy to find what Iâm looking for.",
        "CVS is a good store..very clean",
        "Its a good store to go",
        "CVS is a good store  but their regular prices are too high.  However  they have frequent excellent regular coupons  loyalty rewards  and or specials which make their prices very competitive.  The problem is  the effort it takes to find out if the item you are looking for is available at a good price at the time you want to purchase it.",
        "its a good store",
        "I believe CVS is a good store to shop for with the good sales they have.",
        "cvs is a good store for medicines and  some everyday items",
        "good store but mainly go to Walgreen",
        "I honestly donât shop there but they are still a good store I would recommend",
        "CVS is a good store with a good variety of products and easy to shop at.",
        "convenient location and good store hours",
        "Cvs- It is ok.  There is nothing I dislike about it but I have no reason to go there since I already have a number of good stores to do my shopping in",
        "CVS is a good store",
        "good store",
        "Itâs a good store for cash back",
        "CVS is a good store to shop for cards  drinks  and buy pharmacueticals.",
        "Its a pretty good store with reasonable prices and pretty good reward program.  Unfortunately  a lot of their weekly specials sell out fast and store is a little difficult to navigate but overall its a pretty good place to go for snacks  personal care and drugs.",
        "CVS is a good store but is fairly expensive.",
        "CVS is a good store but they don\u0027t really offer any real good pharmacy discounts and are sometimes very expensive",
        "cvs is a good store to shop in  with quality help that will help you find what you need - they also have rewards for shopping there and good coupons and sales",
        "good store with easy location and carries a variety of goods",
        "CVS is a good store with a variety of items.",
        "A good store but nothing special.",
        "That it is a good store with people that are helpful.",
        "Itâs a good store there is just not close to me but there is one close to you.",
        "Cvs is a good store",
        "Cvs is a good store to shop staff is knowledgeable and always says hi",
        "CVS is a good store to shop in. They always have discounts and you can join CVS cares for extra discounts. The store is always well stocked and checkout is quick",
        "Longs is a good store. Its spacious and walking distance from myhouse. They have approachable people and good variety of products.",
        "It is a good store good products to shop for",
        "well it\u0027s a good store. it has nice stuff you can buy  like cosmetics  but it\u0027 not a good place to go to if you\u0027re grocery shopping.",
        "Is a good store",
        "CVS is a good store to go to to buy greeting cards  birthday cards  mother and father\u0027s day cards and medication.",
        "CVS is a good store to get most of what you need",
        "CVS employees are always helpful. The pharmacy is always there for me. The prices are reasonable and they have good store name products.",
        "CVS is a good store for common health and beauty needs.",
        "CVS is not a close location for me  otherwise they are a good store."
      ]
    },
    {
      "topic": "encourage shopping",
      "phrases": [
        {
          "phrase": "encourage filling",
          "frequency": 1,
          "sem_sim": 0.6088353991508484,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998196363449097
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN"
          ],
          "context": [
            "I would encourage filling a prescription at CVS if their insurance covers it... mine does not."
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9996889233589172
        }
      ],
      "topic_frequency": 2,
      "topic_tags": [
        "VERB",
        "NOUN"
      ],
      "topic_context": [
        "I would encourage shopping at cvs especially for prescriptions because they are accurate.  the pharmacist are always willing to answer my question in a professional and polite manner",
        "I would encourage shopping because itâs fast and stress free."
      ]
    },
    {
      "topic": "stopped selling tobacco",
      "phrases": [
        {
          "phrase": "stopped selling tobacco",
          "frequency": 4,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9990803003311157
            }
          ],
          "pos_tags": [
            "VERB",
            "VERB",
            "NOUN"
          ],
          "context": [
            "CVS is a good pharmacy  staffed by professionals and with a large variety of products.  I don\u0027t particularly like the fact that they stopped selling tobacco products  and I\u0027m not sure the prices are all that competitive  but overall they\u0027re OK.",
            "I have always been pleased with them because they provide good quality products and good service.  I was VERY pleased when they stopped selling tobacco products.",
            "I don\u0027t smoke but I know that they stopped selling tobacco which made their business taper off.",
            "I respect them because they stopped selling tobacco products. Their prices are reasonable  they have a lot of BOGOs  their ExtraBucks rewards program is pretty good and their store brand products are reliable. They also offer vaccines there so I\u0027ve been able to get several right at the prescription counter."
          ]
        },
        {
          "phrase": "selling tobacco",
          "frequency": 4,
          "sem_sim": 0.9453991091981226,
          "char_sim": 0.8979591836734693,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9848430752754211
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN"
          ],
          "context": [
            "CVS is a good pharmacy  staffed by professionals and with a large variety of products.  I don\u0027t particularly like the fact that they stopped selling tobacco products  and I\u0027m not sure the prices are all that competitive  but overall they\u0027re OK.",
            "I have always been pleased with them because they provide good quality products and good service.  I was VERY pleased when they stopped selling tobacco products.",
            "I don\u0027t smoke but I know that they stopped selling tobacco which made their business taper off.",
            "I respect them because they stopped selling tobacco products. Their prices are reasonable  they have a lot of BOGOs  their ExtraBucks rewards program is pretty good and their store brand products are reliable. They also offer vaccines there so I\u0027ve been able to get several right at the prescription counter."
          ]
        },
        {
          "phrase": "sell tobacco",
          "frequency": 1,
          "sem_sim": 0.8908705601886827,
          "char_sim": 0.846938775510204,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9857122898101807
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN"
          ],
          "context": [
            "cvs is like a walgreens.  one on every corner but they do not sell tobacco"
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "NEGATIVE",
          "score": 0.9990803003311157
        }
      ],
      "topic_frequency": 4,
      "topic_tags": [
        "VERB",
        "VERB",
        "NOUN"
      ],
      "topic_context": [
        "CVS is a good pharmacy  staffed by professionals and with a large variety of products.  I don\u0027t particularly like the fact that they stopped selling tobacco products  and I\u0027m not sure the prices are all that competitive  but overall they\u0027re OK.",
        "I have always been pleased with them because they provide good quality products and good service.  I was VERY pleased when they stopped selling tobacco products.",
        "I don\u0027t smoke but I know that they stopped selling tobacco which made their business taper off.",
        "I respect them because they stopped selling tobacco products. Their prices are reasonable  they have a lot of BOGOs  their ExtraBucks rewards program is pretty good and their store brand products are reliable. They also offer vaccines there so I\u0027ve been able to get several right at the prescription counter."
      ]
    },
    {
      "topic": "waste paper",
      "phrases": [
        {
          "phrase": "waste paper",
          "frequency": 4,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.999790370464325
            }
          ],
          "pos_tags": [
            "PROPN",
            "PROPN"
          ],
          "context": [
            "I sometimes have problems filling prescriptions with CVS. They refill prescriptions before I need them. They do not call or text me when my refills are ready. They lie in person when a prescription will be ready to fill. Their coupons from their redbox totally waste paper.",
            "They waste paper",
            "Convenient location with easy parking.  Good selection of medications and other items.  Prescriptions can be ordered  but be prepared to wait in line for pickup.   Has some self check out lines  but they waste paper on receipts and it slows down the process of paying for items.",
            "CVS is a good drug store and has a good rebate program when you join their club. They do waste paper with all of the coupons that print out  after you swipe you member card."
          ]
        },
        {
          "phrase": "toilet paper",
          "frequency": 2,
          "sem_sim": 0.8008534969115744,
          "char_sim": 0.8520408163265305,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9993448853492737
            }
          ],
          "pos_tags": [
            "PROPN",
            "NOUN"
          ],
          "context": [
            "CVS has a broad variety of healthcare and beauty aids  plus staples like toilet paper and paper towels. They also have a great selection of candy and snacks  and they have seasonal items  too.",
            "I would tell my friend that CVS is a great place to shop.  I shop there for handicapped equipment like a cane.  And they have good deals on toilet paper usually."
          ]
        },
        {
          "phrase": "love paper",
          "frequency": 1,
          "sem_sim": 0.7767782381602696,
          "char_sim": 0.8367346938775511,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9992356300354004
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS is a clean store and generally well stocked.  If you need to have a prescription filled  be sure you carve out a lot of time in your day because there is always a relatively long wait time.   As far as the store goes  CVS is coupon-driven  so be sure you obtain a loyalty card to get their coupons.  They love paper at CVS and you will even get coupons on your receipt  as well as you can obtain coupons at a store kiosk."
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "NEGATIVE",
          "score": 0.999790370464325
        }
      ],
      "topic_frequency": 3,
      "topic_tags": [
        "PROPN",
        "PROPN"
      ],
      "topic_context": [
        "I sometimes have problems filling prescriptions with CVS. They refill prescriptions before I need them. They do not call or text me when my refills are ready. They lie in person when a prescription will be ready to fill. Their coupons from their redbox totally waste paper.",
        "They waste paper",
        "Convenient location with easy parking.  Good selection of medications and other items.  Prescriptions can be ordered  but be prepared to wait in line for pickup.   Has some self check out lines  but they waste paper on receipts and it slows down the process of paying for items.",
        "CVS is a good drug store and has a good rebate program when you join their club. They do waste paper with all of the coupons that print out  after you swipe you member card."
      ]
    },
    {
      "topic": "stop shopping",
      "phrases": [
        {
          "phrase": "shopping review",
          "frequency": 0,
          "sem_sim": 0.6691504716873169,
          "char_sim": 0.701278227081673,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.856430172920227
            }
          ],
          "pos_tags": [
            "NOUN",
            "PROPN"
          ],
          "context": []
        },
        {
          "phrase": "feel shopping",
          "frequency": 1,
          "sem_sim": 0.6851199269294739,
          "char_sim": 0.7724137931034483,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9989900588989258
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN"
          ],
          "context": [
            "I would say nothing to either encourage or discourage about shopping at CVS.  I haven\u0027t shopped there for years so I don\u0027t know how it would feel shopping there now."
          ]
        },
        {
          "phrase": "consider shopping",
          "frequency": 1,
          "sem_sim": 0.7655999064445496,
          "char_sim": 0.7724137931034483,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.6738222241401672
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN"
          ],
          "context": [
            "I like CVS  have found their pharmacy staff to be professional and helpful through the years. Comfortable with my local store layout. Not at all happy to notice the subtle introduction of self checkout and will seriously consider shopping elsewhere if this gains traction"
          ]
        },
        {
          "phrase": "enjoy shopping",
          "frequency": 3,
          "sem_sim": 0.7193247079849243,
          "char_sim": 0.8551724137931034,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998579621315002
            }
          ],
          "pos_tags": [
            "PROPN",
            "VERB"
          ],
          "context": [
            "CVS is expensive  but does have a good selection of products and a variety of brands. There aren\u0027t any CVS\u0027 convenient to me  but when there was  I did enjoy shopping with them for everyday items  such as stockings  hair ties  women\u0027s products  and things like that  that I would need on my way to work possibly at the last minute.",
            "I enjoy shopping at CVS for their products  friendly staff and great sales",
            "I enjoy shopping at CVS on occasion. I like to go when I know they have good sales and browse the store. They have interesting seasonal products."
          ]
        },
        {
          "phrase": "go shopping",
          "frequency": 1,
          "sem_sim": 0.7714637517929077,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9968365430831909
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN"
          ],
          "context": [
            "CVS is nice if you\u0027re looking to get a few essentials for your home in a pinch. It\u0027s not the place to go shopping for everything but if there\u0027s a few things you\u0027re looking for like toiletries or water and you wanna skip long lines at normal grocery stores CVS is the way to go"
          ]
        },
        {
          "phrase": "go wrong shopping",
          "frequency": 1,
          "sem_sim": 0.6894156336784363,
          "char_sim": 0.8551724137931034,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9990752339363098
            }
          ],
          "pos_tags": [
            "VERB",
            "ADJ",
            "NOUN"
          ],
          "context": [
            "They\u0027re okay  but have nothing special to offer.  They don\u0027t have the lowest prices  they don\u0027t have the friendliest staff  they don\u0027t have the most convenient locations.  You can\u0027t really go wrong shopping there  but there are many better alternatives."
          ]
        },
        {
          "phrase": "make shopping",
          "frequency": 3,
          "sem_sim": 0.7586559653282166,
          "char_sim": 0.7724137931034483,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9988663196563721
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN"
          ],
          "context": [
            "I have shopped at cvs .the people are very knowledgeable and make shopping fast and easy.",
            "Cvs has friendly staff to make shopping easier and convenient",
            "CVS offers many coupons that can make shopping not cost intensive"
          ]
        },
        {
          "phrase": "quick shopping",
          "frequency": 1,
          "sem_sim": 0.7219104170799255,
          "char_sim": 0.7724137931034483,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9920620322227478
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS is a great for quick shopping needs such as snacks  beverages   personal care products and refilling prescriptions."
          ]
        },
        {
          "phrase": "impulse shopping",
          "frequency": 1,
          "sem_sim": 0.6343366503715515,
          "char_sim": 0.9172413793103449,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9745957255363464
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "This is a great little drugstore. You can find pretty much anything you need as far as items you\u0027d find in a drug store are concerned. I often find myself impulse shopping. Apart from pharmaceuticals though  some items cost more than like Target or Walmart."
          ]
        },
        {
          "phrase": "make shopping fast",
          "frequency": 1,
          "sem_sim": 0.7059812545776367,
          "char_sim": 0.7724137931034483,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9992343187332153
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN",
            "ADV"
          ],
          "context": [
            "I have shopped at cvs .the people are very knowledgeable and make shopping fast and easy."
          ]
        },
        {
          "phrase": "single purchase giving",
          "frequency": 0,
          "sem_sim": 0.7173257784414254,
          "char_sim": 0.37755102040816324,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.8407149314880371
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": []
        },
        {
          "phrase": "stop shopping place",
          "frequency": 1,
          "sem_sim": 0.953269355151118,
          "char_sim": 0.9081632653061225,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9985319972038269
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "I would tell my friend that CVS is a good one stop shopping place if you have coupons and especially CVS coupons which will make a big difference in what you pay."
          ]
        },
        {
          "phrase": "shopping place",
          "frequency": 2,
          "sem_sim": 0.8587305193044701,
          "char_sim": 0.7959183673469388,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9988613724708557
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS is a nice shopping place. They just don\u0027t have everything I need that I get at Walgreens.",
            "I would tell my friend that CVS is a good one stop shopping place if you have coupons and especially CVS coupons which will make a big difference in what you pay."
          ]
        },
        {
          "phrase": "one stop shopping",
          "frequency": 2,
          "sem_sim": 0.9799559967858451,
          "char_sim": 0.9387755102040816,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9578639268875122
            }
          ],
          "pos_tags": [
            "NUM",
            "VERB",
            "VERB"
          ],
          "context": [
            "CVS is a great one stop shopping center. It convenient for your everyday pick me up. The customers offer assistance and the pharmacy center is very knowledgeable",
            "I would tell my friend that CVS is a good one stop shopping place if you have coupons and especially CVS coupons which will make a big difference in what you pay."
          ]
        },
        {
          "phrase": "stop shopping center",
          "frequency": 1,
          "sem_sim": 0.95094668986846,
          "char_sim": 0.8979591836734693,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9924526810646057
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS is a great one stop shopping center. It convenient for your everyday pick me up. The customers offer assistance and the pharmacy center is very knowledgeable"
          ]
        },
        {
          "phrase": "store shopping",
          "frequency": 1,
          "sem_sim": 0.8609333366763835,
          "char_sim": 0.9489795918367346,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9756121039390564
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "For in store shopping id1recommend cvs for the extra care eeward deals. Pharmacy is a different story.  The in store pharmacy would be fine if the website to check status of prescriptions was at least 55% accurate. Ive had so much trouble and am thinking of changing pharmacies as im tired of every prescription being a tug of war. Never know what is accurate on website. Call pharmacy regarding prescription that says in process for over 6 days. According to pharmacy they never received prescriptio"
          ]
        },
        {
          "phrase": "shopping experience",
          "frequency": 13,
          "sem_sim": 0.8326358795166016,
          "char_sim": 0.75,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9950445294380188
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS has a great rewards program  and competitive prices. They are conveniently located with clean  organized stores. Overwhelming majority of shopping experiences includes friendly staff and a great selection of products.",
            "CVS is well organized and offers good shopping experiences  but unless the items are on sale  the prices are higher than other retailers.",
            "Everytime I go to cvs I always find what I\u0027m looking for and the shopping experience is great.",
            "knowledgeable staff  quick and easy shopping experience.",
            "Have had only limited shopping experience.",
            "CVS is a great shopping experience.  However  their prices tend to be higher than Walmart  Target and Amazon.",
            "CVS is a trustworthy drug store that deliver consistently a great shopping experience",
            "Any time that i have visited CVS I have had a positive shopping experience. However  their prices are quite a bit higher than many other places.",
            "Depends on the store  shopping experience varies with the location. Prices are not always competitive and employees not always trained properly.",
            "Itâs a nice easy shopping experience. They have a lot of deals if you have the rewards card",
            "great loyalty program  convenient locations and online shopping experience",
            "convenient  pleasant shopping experience.",
            "CVS is always a consistent and reliable shopping experience."
          ]
        },
        {
          "phrase": "discourage shopping",
          "frequency": 1,
          "sem_sim": 0.8849532178470066,
          "char_sim": 0.846938775510204,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9994652271270752
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "I do not know enough about CVS  nor do I shop there often enough  to either recommend or discourage shopping there."
          ]
        },
        {
          "phrase": "shopping experience however",
          "frequency": 0,
          "sem_sim": 0.810192237094957,
          "char_sim": 0.6989795918367346,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.8474567532539368
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN",
            "ADV"
          ],
          "context": []
        },
        {
          "phrase": "easy shopping experience",
          "frequency": 2,
          "sem_sim": 0.8021713981823045,
          "char_sim": 0.7704081632653061,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9978485107421875
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "Itâs a nice easy shopping experience. They have a lot of deals if you have the rewards card",
            "knowledgeable staff  quick and easy shopping experience."
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "NEGATIVE",
          "score": 0.9996857643127441
        }
      ],
      "topic_frequency": 20,
      "topic_tags": [
        "VERB",
        "VERB"
      ],
      "topic_context": [
        "I would tell my friend that CVS is a good one stop shopping place if you have coupons and especially CVS coupons which will make a big difference in what you pay.",
        "CVS has everything you need  it is one-stop shopping.",
        "CVS is a great one stop shopping center. It convenient for your everyday pick me up. The customers offer assistance and the pharmacy center is very knowledgeable",
        "One stop shopping",
        "One stop shopping  conveniently located"
      ]
    },
    {
      "topic": "fine store",
      "phrases": [
        {
          "phrase": "everything went fine",
          "frequency": 1,
          "sem_sim": 0.5523304343223572,
          "char_sim": 0.7106549489299412,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9997866153717041
            }
          ],
          "pos_tags": [
            "PRON",
            "VERB",
            "ADJ"
          ],
          "context": [
            "Haven\u0027t shopped there a lot   but everything went fine when I did shop there."
          ]
        },
        {
          "phrase": "fine went",
          "frequency": 0,
          "sem_sim": 0.5680341124534607,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9997646808624268
            }
          ],
          "pos_tags": [
            "PROPN",
            "VERB"
          ],
          "context": []
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9997195601463318
        }
      ],
      "topic_frequency": 3,
      "topic_tags": [
        "ADJ",
        "NOUN"
      ],
      "topic_context": [
        "CVS is a fine store  but it is not as convenient for me as Walgreens",
        "CVS is a fine store and pharmacy but it is not convenient to me and my location.  Anytime I have gone in CVS I have felt the prices were higher than other places and did NOT have as good of sales as Walgreens.  I just do not know as much about CVS as I do about the other stores.",
        "I\u0027m sure this is a perfectly fine store to shop in.They have many conveniently located stores."
      ]
    },
    {
      "topic": "loyalty program",
      "phrases": [
        {
          "phrase": "program general",
          "frequency": 0,
          "sem_sim": 0.5254940390586853,
          "char_sim": 0.8222222222222222,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9948121905326843
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": []
        },
        {
          "phrase": "membership program",
          "frequency": 2,
          "sem_sim": 0.5979723930358887,
          "char_sim": 0.7116931039369989,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9955805540084839
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "It\u0027s a great store and they have some cool benefits with their membership program. I don\u0027t know them all because we have several Walgreens closer than our closest CVS  so it\u0027s normally only somewhere I stop if I happen to be close. They have good clearance though.",
            "There are CVS locations everywhere. You can join the membership program online and get great discounts. Very often I get 40% off coupons. They have a pharmacy and a good range of products. I never wait on a long line because there are a lot of check out options."
          ]
        },
        {
          "phrase": "coupon program",
          "frequency": 1,
          "sem_sim": 0.5442657470703125,
          "char_sim": 0.8222222222222222,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9873499274253845
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "The pharmacy is usually staffed sufficiently to avoid long wait and the drive-thru is convenient. Items in the store are easy to find and they stock a lot of the name brands. I also recommend their coupon program."
          ]
        },
        {
          "phrase": "rebate program",
          "frequency": 2,
          "sem_sim": 0.5255880355834961,
          "char_sim": 0.9,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9898717999458313
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS is a good drug store and has a good rebate program when you join their club. They do waste paper with all of the coupons that print out  after you swipe you member card.",
            "CVS is a good drug store that has a pretty good selection of health and beauty products. They have good sales promotions  good coupons and a good in store rebate program called Extra Bucks."
          ]
        },
        {
          "phrase": "auto refill program",
          "frequency": 1,
          "sem_sim": 0.40714356303215027,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.987697184085846
            }
          ],
          "pos_tags": [
            "NOUN",
            "PROPN",
            "NOUN"
          ],
          "context": [
            "CVS is conveniently located near my home and it\u0027s easy to locate items in the store.  They tend to always have the brands I\u0027m looking for in stock.  They have a very convenient texting notification and auto refill program."
          ]
        },
        {
          "phrase": "good rebate program",
          "frequency": 1,
          "sem_sim": 0.5053143501281738,
          "char_sim": 0.8777777777777778,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.997735321521759
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS is a good drug store and has a good rebate program when you join their club. They do waste paper with all of the coupons that print out  after you swipe you member card."
          ]
        },
        {
          "phrase": "excellent loyalty",
          "frequency": 2,
          "sem_sim": 0.6949265003204346,
          "char_sim": 0.8222222222222222,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998666644096375
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Has a wide selection of products at a good price and offers an excellent loyalty program.  The pharmacist takes the time to answer questions.",
            "I shop at CVS because of its convenience and excellent loyalty program and prices."
          ]
        },
        {
          "phrase": "great loyalty",
          "frequency": 12,
          "sem_sim": 0.7281016707420349,
          "char_sim": 0.711111111111111,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.999866247177124
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "They have lots of good deals and a great loyalty  program",
            "CVS - friendly  caring  great loyalty program  great value",
            "great loyalty program  convenient locations and online shopping experience",
            "friendly and informed associates  great loyalty program",
            "great loyalty program gives you $5 extra care bucks for 10 prescriptions filled.",
            "I would say they have excellent customer service. Their employees are always friendly and they have a great loyalty program.",
            "special deals from time to time great loyalty program caring helpful pharmacists",
            "It is a one stop convenient shop with a great loyalty program. you can also get photos printed here and have a nice print shop",
            "have all prescriptions plus a great loyalty program",
            "The CVS stores are easy to navigate and easy to find just what you are looking for because they have their shelves well marked.  The pharmacy is well staffed and they are very helpful.  They have a great loyalty program.",
            "It has a great loyalty program.",
            "Cvs has a great loyalty program that pays you extra dollars if you buy certain items.  Their everyday prices are high  but sometimes you can get good deals there.  Their customer service is great too."
          ]
        },
        {
          "phrase": "good loyalty",
          "frequency": 6,
          "sem_sim": 0.7172810435295105,
          "char_sim": 0.7555555555555555,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998610019683838
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS has a variety of products - from health products to groceries. Its prices are decent and it has a lot of sales and discounts. It has a good loyalty program as well  and its pharmacy is good.",
            "They have a good loyalty program.",
            "They carry a wide variety and have a good loyalty program",
            "CVS has a good loyalty program with lots of discounts and store coupons and a full service drug store.",
            "CVS has convenient locations and a good choice of products  but that tend to have higher prices than other places.  They do have a good loyalty program that can make certain things affordable.",
            "Is a convenient place to get your perscriptions and other houshold items.its organized and clean and they have a good loyalty program."
          ]
        },
        {
          "phrase": "mail order plan",
          "frequency": 0,
          "sem_sim": 0.7052262163463298,
          "char_sim": 0.3571428571428571,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9943678975105286
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN",
            "NOUN"
          ],
          "context": []
        },
        {
          "phrase": "loyalty program care",
          "frequency": 0,
          "sem_sim": 0.9429284054405835,
          "char_sim": 0.9336734693877551,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.994491457939148
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN",
            "NOUN"
          ],
          "context": []
        },
        {
          "phrase": "assistance great loyalty program",
          "frequency": 0,
          "sem_sim": 0.9180906329836165,
          "char_sim": 0.8214285714285714,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9997250437736511
            }
          ],
          "pos_tags": [
            "NOUN",
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": []
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9973224997520447
        }
      ],
      "topic_frequency": 44,
      "topic_tags": [
        "NOUN",
        "PROPN"
      ],
      "topic_context": [
        "CVS is great because their loyalty program is second to none  their customer service is fantastic and their staff is amazing",
        "Sign up for loyalty program.  The coupons and offers are great.  The accept coupons.",
        "I would say they have excellent customer service. Their employees are always friendly and they have a great loyalty program.",
        "great loyalty program gives you $5 extra care bucks for 10 prescriptions filled.",
        "CVS is a little over priced but once you join their loyalty program you get major discounts and the customer service is always fast and excellent",
        "Their shopping f experience is good if you are a member of their loyalty program  but NEVER fill a prescription with them or else they will call you day and night and harrase you",
        "special deals from time to time great loyalty program caring helpful pharmacists",
        "Great loyalty program  convenient locations.",
        "Their loyalty program offers good incentives. Staff is helpful when I have questions.",
        "Good loyalty program and the employees are nice but my location is never stocked well",
        "There is a CVS location very close to my home--in fact  closer than the Jewel-Osco where I ordinarily shop--but I avoid it. The prices are a bit higher  the loyalty program is average at best  and I just don\u0027t get a good feeling when I shop there.",
        "Would encourage them to shop there and enroll in there loyalty program because you can get some good deals if you know your prices and use coupons.",
        "Very convenient and have great sales and pricing. Solid loyalty program",
        "The CVS stores are easy to navigate and easy to find just what you are looking for because they have their shelves well marked.  The pharmacy is well staffed and they are very helpful.  They have a great loyalty program.",
        "CVS is a great store with tons of brand products but pricey even with their loyalty program. CARE",
        "Is a convenient place to get your perscriptions and other houshold items.its organized and clean and they have a good loyalty program.",
        "CVS has convenient locations and a good choice of products  but that tend to have higher prices than other places.  They do have a good loyalty program that can make certain things affordable.",
        "I think theyâre loyalty program and coupons are ridiculous. They just need to make it simple and change their prices. They are like the jcpenney of pharmacies.",
        "Prescription meds are usually in stock and ready on time  staff are friendly  there is a good and useful loyalty program",
        "Great loyalty program  knowledgeable workers  good prices and products",
        "CVS has the best promotions  coupons and loyalty program.",
        "They have a good loyalty program.",
        "They are pretty good  nice loyalty program",
        "CVS has a good loyalty program with lots of discounts and store coupons and a full service drug store.",
        "Friendly-always greeted when I walk in. Can ask anyone for assistance. Great loyalty program",
        "I\u0027ve never understood their loyalty program--and they\u0027ve always seemed to assume that it was clear to all.",
        "I would encourage my friend to shop at CVS.  It is so convenient to shop there.  There loyalty program is great and their coupons are fantastic.",
        "CVS - friendly  caring  great loyalty program  great value",
        "friendly and informed associates  great loyalty program",
        "It is a one stop convenient shop with a great loyalty program. you can also get photos printed here and have a nice print shop",
        "CVS has a variety of products - from health products to groceries. Its prices are decent and it has a lot of sales and discounts. It has a good loyalty program as well  and its pharmacy is good.",
        "is a well stocked drug tore store that carries a wide variety of health products. Staff a pharmacy are very willing to assist with questions regarding over the counter products for various health products. Also have someone to talk to you about various beauty products. Good loyalty program and sales. Vast numbers of stores in the area.",
        "They carry a wide variety and have a good loyalty program",
        "I used to go to CVS but donât go now.  The store location is farther away and I donât participate in a loyalty program.",
        "It has a great loyalty program.",
        "Cvs has a great loyalty program that pays you extra dollars if you buy certain items.  Their everyday prices are high  but sometimes you can get good deals there.  Their customer service is great too.",
        "I shop at CVS because of its convenience and excellent loyalty program and prices.",
        "this place is good place to shop your prescriptions other things have a nice loyalty program also",
        "In general  I cannot recommend CVS to me.  On the plus side  they have a good app.  If you regularly shop there and are a member of their loyalty program  prices are OK  but not great.  Occasional shoppers at CVS are taken advantage of via high prices.  The quality of the stores is a decidedly mixed bag.  Some are well run and clean.  Others are a disgrace.  The same uneven quality applies to the pharmacies.  I\u0027ve encountered wonderful pharmacists and technicians inside CVS stores and I\u0027ve also",
        "Their loyalty program is the best one around and they do have a lot of buy one  get one sales.",
        "have all prescriptions plus a great loyalty program",
        "great loyalty program  convenient locations and online shopping experience",
        "Has a wide selection of products at a good price and offers an excellent loyalty program.  The pharmacist takes the time to answer questions.",
        "Good use of coupons and promotions. Good loyalty program"
      ]
    },
    {
      "topic": "gift card",
      "phrases": [
        {
          "phrase": "gift card",
          "frequency": 5,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9975752830505371
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "I hate CVS had a digital cbs digital gift card and the guy behind the counter said that they don\u0027t accept digital cgift cards",
            "They do not honor gift cards unless you call corporate",
            "I usually like CVS  except to buy food because food is expensive there. The CVS where I live made me come back because a computer was broken and 2 weeks later it was still broken.  They didn\u0027t want to give me my $10 off  but I got a gift card out of them.",
            "I visit it as needed for over the counter medicines  toiletries  greeting cards  gift cards and other items when I\u0027m in a hurry and need a quick item.",
            "Can I just quickly get this dollar because I really need this gift card to get something that I want so hurry up"
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9975752830505371
        }
      ],
      "topic_frequency": 5,
      "topic_tags": [
        "NOUN",
        "NOUN"
      ],
      "topic_context": [
        "I hate CVS had a digital cbs digital gift card and the guy behind the counter said that they don\u0027t accept digital cgift cards",
        "They do not honor gift cards unless you call corporate",
        "I usually like CVS  except to buy food because food is expensive there. The CVS where I live made me come back because a computer was broken and 2 weeks later it was still broken.  They didn\u0027t want to give me my $10 off  but I got a gift card out of them.",
        "I visit it as needed for over the counter medicines  toiletries  greeting cards  gift cards and other items when I\u0027m in a hurry and need a quick item.",
        "Can I just quickly get this dollar because I really need this gift card to get something that I want so hurry up"
      ]
    },
    {
      "topic": "good customer service",
      "phrases": [
        {
          "phrase": "customer chose",
          "frequency": 1,
          "sem_sim": 0.6476696729660034,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9731473326683044
            }
          ],
          "pos_tags": [
            "NOUN",
            "VERB"
          ],
          "context": [
            "Itâs a store that sells products. I do not have any reason to encourage or discourage anyone from making a purchase at CVS. We live in a free market society so let the customer chose as to where they spend their money!"
          ]
        },
        {
          "phrase": "regular customer receive",
          "frequency": 1,
          "sem_sim": 0.6494722366333008,
          "char_sim": 0.8758620689655172,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9938708543777466
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS has many convenient locations. They are overall more expensive throughout all product ranges  however with weekly coupons (I as a very regular customer receive a 30% or 40% off coupon every Thursday!) it is more affordable."
          ]
        },
        {
          "phrase": "regular customer",
          "frequency": 2,
          "sem_sim": 0.7293084859848022,
          "char_sim": 0.8241379310344827,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9950703382492065
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "I used to be a regular customer but found prices were better at Walmart.",
            "CVS has many convenient locations. They are overall more expensive throughout all product ranges  however with weekly coupons (I as a very regular customer receive a 30% or 40% off coupon every Thursday!) it is more affordable."
          ]
        },
        {
          "phrase": "loyal customer",
          "frequency": 1,
          "sem_sim": 0.6937175393104553,
          "char_sim": 0.9172413793103449,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998117685317993
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS is my one stop shop.  I am a loyal customer of CVS.  I continuously receive coupons for 30 to 40% off purchases through the mail and email.  I have saved so much money on my purchases throughout the year.  I am a top saver with CVS.  I can  always  find everything I need when I shop with CVS."
          ]
        },
        {
          "phrase": "excellent customer",
          "frequency": 4,
          "sem_sim": 0.8208270072937012,
          "char_sim": 0.70920276727312,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998564720153809
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Has wide selection of products and excellent customer service",
            "I would say they have excellent customer service. Their employees are always friendly and they have a great loyalty program.",
            "CVS is very reliable and always has excellent customer service.  If they were closer to my house I would go there more often.",
            "Very good deals  products and excellent customer service"
          ]
        },
        {
          "phrase": "consumer friendly",
          "frequency": 2,
          "sem_sim": 0.7122906051953951,
          "char_sim": 0.5510204081632653,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9811946153640747
            }
          ],
          "pos_tags": [
            "NOUN",
            "ADJ"
          ],
          "context": [
            "I do not recommend CVS. I find that the prices are more expensive then other stores. The print coupons that I receive the mail or circulars and they tell me that you canât use it for the item I purchase. Even though there is nothing stating that. I just find CVS not very consumer friendly.",
            "The staff at cvs is very helpful .... layout of stores are consumer friendly"
          ]
        },
        {
          "phrase": "awesome customer service",
          "frequency": 2,
          "sem_sim": 0.9431056076166582,
          "char_sim": 0.9030612244897959,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.999865710735321
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "awesome customer service",
            "Great prices and awesome customer service too"
          ]
        },
        {
          "phrase": "provide great customer service",
          "frequency": 1,
          "sem_sim": 0.9493023619359853,
          "char_sim": 0.8571428571428571,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998327493667603
            }
          ],
          "pos_tags": [
            "VERB",
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "They provide great customer service and are always willing to assist and direct when needed."
          ]
        },
        {
          "phrase": "customer service experience problem",
          "frequency": 1,
          "sem_sim": 0.9033137292278056,
          "char_sim": 0.7857142857142857,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9990267753601074
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "Cvs has yet to reply to my customer service experience problem. they send coupons but deny their use when you are instore. Will not be going back until this problem is resolved."
          ]
        },
        {
          "phrase": "great customer service",
          "frequency": 8,
          "sem_sim": 0.977428486152571,
          "char_sim": 0.923469387755102,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998531341552734
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "They have friendly and attentive employees.  They give great customer service at their pharmacy.",
            "Cvs has great customer service  and they care about your health",
            "Good sales and great customer service",
            "cvs has great customer service from their in store employees. the employees have knowledge on the products they carry. the time to wait in line to make a purchase is not long. the time to wait for a prescription has never been more than 15 minutes  if i did not have it ready a head of time.",
            "Cvs has great customer service and the pharmacy is the beat",
            "great sales  bonus rewards  convenient  great customer service",
            "They provide great customer service and are always willing to assist and direct when needed.",
            "They have clean stores  very organized and tidy and products are easy to find  great customer service"
          ]
        },
        {
          "phrase": "customer service",
          "frequency": 78,
          "sem_sim": 0.9528908960673275,
          "char_sim": 0.9336734693877551,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9823682308197021
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "awesome customer service",
            "CVS is great because their loyalty program is second to none  their customer service is fantastic and their staff is amazing",
            "I would say they have excellent customer service. Their employees are always friendly and they have a great loyalty program.",
            "CVS has proven to me that their profit is more important to them than customer satisfaction. Their pricing is higher and their customer service is abhorrent.",
            "I trust CVS to fill my prescriptions because they always have the best customer service Nd staff. It is always nice to get coupons and dollar off money to spend. I just wish that the Coupons were good for a longer time period.",
            "Pretty good customer service!",
            "They have clean stores  very organized and tidy and products are easy to find  great customer service",
            "Good sales and great customer service",
            "Far too many negative customer service interactions  specifically with the pharmacy.",
            "Good customer service",
            "Cvs has great customer service and the pharmacy is the beat",
            "Cvs has yet to reply to my customer service experience problem. they send coupons but deny their use when you are instore. Will not be going back until this problem is resolved.",
            "I had good experiences with CVS  they have a really good rewards program or at least they used to  I don\u0027t go there often because I switched to a cheaper pharmacy. But customer service at CVS is good.",
            "The CVS in Eden  NC has the worse customer service I have ever seen in any drug store.  You could die of old age waiting for your prescription to be ready.",
            "There is the convenience of filling out my prescriptions near my home because of the close proximity. I like the customer service and the store has other items beside filling out my medication that I like to shop for when I\u0027m in the store.",
            "This is a rather silly question...but I\u0027d stress the customer service and convenience.",
            "Has wide selection of products and excellent customer service",
            "Good customer service  knowledgeable staff  competitive prices",
            "Cvs has great customer service  and they care about your health",
            "CVS has friendly customer service employees.  Accurate filling prescriptions.  Quick service",
            "very good customer service",
            "They have great coupons and customer service",
            "I\u0027ve never been to a CVS that had good customer service. All the employees were rude  especially in the pharmacy dept. I always had trouble getting my prescriptions filled with them and do not shop there unless there\u0027s no other option.",
            "Great customer service but very pricy at times",
            "it is a great store with great products and customer service",
            "They have friendly and attentive employees.  They give great customer service at their pharmacy.",
            "I like CVS it is a good store but their prices are a little bit higher than other retailers I like the customer service and I can find things easily I just don\u0027t like paying high prices for the same products",
            "Great customer service \u0026 many options of most products. Sale items are priced well.",
            "they have best prices and customer service",
            "CVS has good customer service and is a well stocked store.",
            "It\u0027s good customer service and good products but is more expensive",
            "Location is fairly close  good rewards program  general merchandise and assistance is good  Pharmacist on staff is very rude and non customer service focused",
            "The customer service isnât necessarily bad but they arenât the most welcoming when people come in. I donât really care too much about that though. Theyâre super organized and itâs easy to find anything and everything you are looking for. Thereâs organic and vegan nutrition options located here that arenât offered at any of my local grocery stores too  so thatâs why I recommend them automatically.",
            "They have good customer service",
            "Very customer service orientedd",
            "cvs has good quality brands  customer service is good  clean stores",
            "CVS is one of the best place to shop if you are looking for deals  promotions  and good customer service",
            "Great customer service and friendly employees who are helpful and polite",
            "In my experience  CVS does not have good customer service.  The employees are rude and uncaring.  The exception to that being  the most recent CVS I went into in Mooresville  NC.  I dealt with the pharmacy employees and all were very nice and helping.  I would not encourage friends to use a particular store or brand  as that has come back to bite me in the past no matter what store dr. etc. it is.",
            "good customer service",
            "CVS is my preferred Pharmacy. They have conveinient locations. They have the slowest service and don\u0027t care about customer service.",
            "They provide great customer service and are always willing to assist and direct when needed.",
            "Good customer service asnd a variety of products.",
            "It has very poor customer service  basically sucks",
            "The pharmacy staff is helpful  knowledgeable and always go out of their way to provide me with the best service possible. They are well trained in their craft and customer service as well.",
            "CVS is a little over priced but once you join their loyalty program you get major discounts and the customer service is always fast and excellent",
            "I would say that CVS is the best Drug Store there is. They have all the products you need and want and the prices are good. Their customer service is outstanding which is most important to me.",
            "Their staff is always customer service oriented and I like their Rx app.",
            "Horrible customer service. Avoid this store at all costs",
            "CVS is sometimes a hit or miss depending on the location  the specific staff working  and overall customer service.",
            "I think they do a pretty good job with their customer service and efficiency",
            "customer service is not very good",
            "they have terrible customer service. dont care about you.",
            "CVS is very reliable and always has excellent customer service.  If they were closer to my house I would go there more often.",
            "cvs has great customer service from their in store employees. the employees have knowledge on the products they carry. the time to wait in line to make a purchase is not long. the time to wait for a prescription has never been more than 15 minutes  if i did not have it ready a head of time.",
            "I have never used CVS to fill my prescriptions but I have heard they have good customer service there",
            "CVS is great because of customer service and time",
            "Good quality goods and customer services",
            "CVS does not seem to have good prices and customer service does not appear to be a priority with them.",
            "Products are great and selection of health products you can find is a broad selection. Great gift items. Cvs could work on their customer service  when you walk into a cvs store itâs always quiet and almost an unwelcoming feeling.",
            "Although their prices are not lowest  they are still better than other places.The customer service is good. Pharmacists are pleasant and knowledgable.",
            "The customer service is not very good.",
            "CVS has good cashiers and customer service they also have a variety of products but they aren\u0027t open 24 hours",
            "Very good deals  products and excellent customer service",
            "Great prices and awesome customer service too",
            "Great  customer service",
            "good customer service  prices a bit high",
            "The customer service in the store and at the pharmacy is great  but you will pay a lot for most things in their store.  They do have good sales from time to time.",
            "wide selection of items  good prices and good customer service",
            "I would suggest they shop anywhere other than CVS. The customer service is non-existent  pharmacy personnel cannot be bothered to wait on you and the prices are too high.  Their auto reminder for prescriptions is so annoying I won\u0027t fill prescriptions there if they were the only pharmacy left.",
            "good pharmacy  dreadful customer service otherwise",
            "While their prices are high  in comparison to other stores  the customer service is quite good  and I trust the pharmacists",
            "Great customer service",
            "great sales  bonus rewards  convenient  great customer service",
            "Cvs has a great loyalty program that pays you extra dollars if you buy certain items.  Their everyday prices are high  but sometimes you can get good deals there.  Their customer service is great too.",
            "Very good customer service  quick filling of prescriptions  friendly staff.",
            "cvs is ok but I prefer walgreens or walmart. their customer service is lacking",
            "CVS is convenient  although the customer service is somewhat lacking. There is rarely anyone to help if you have questions and the pharmacists are not very helpful  if you have any questions."
          ]
        },
        {
          "phrase": "overall customer service",
          "frequency": 1,
          "sem_sim": 0.9371213925128081,
          "char_sim": 0.9030612244897959,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9964741468429565
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS is sometimes a hit or miss depending on the location  the specific staff working  and overall customer service."
          ]
        },
        {
          "phrase": "give great customer service",
          "frequency": 1,
          "sem_sim": 0.9484425649351003,
          "char_sim": 0.8775510204081632,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998496174812317
            }
          ],
          "pos_tags": [
            "VERB",
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "They have friendly and attentive employees.  They give great customer service at their pharmacy."
          ]
        },
        {
          "phrase": "excellent customer service",
          "frequency": 4,
          "sem_sim": 0.9674976528907309,
          "char_sim": 0.8622448979591837,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998500943183899
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "Has wide selection of products and excellent customer service",
            "I would say they have excellent customer service. Their employees are always friendly and they have a great loyalty program.",
            "CVS is very reliable and always has excellent customer service.  If they were closer to my house I would go there more often.",
            "Very good deals  products and excellent customer service"
          ]
        },
        {
          "phrase": "good customer card",
          "frequency": 1,
          "sem_sim": 0.876636666910989,
          "char_sim": 0.8877551020408163,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998372793197632
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "They have good sales and a good customer card to use and get special prices plus staff is usually cordial and professional."
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9998473525047302
        }
      ],
      "topic_frequency": 13,
      "topic_tags": [
        "ADJ",
        "NOUN",
        "NOUN"
      ],
      "topic_context": [
        "In my experience  CVS does not have good customer service.  The employees are rude and uncaring.  The exception to that being  the most recent CVS I went into in Mooresville  NC.  I dealt with the pharmacy employees and all were very nice and helping.  I would not encourage friends to use a particular store or brand  as that has come back to bite me in the past no matter what store dr. etc. it is.",
        "good customer service  prices a bit high",
        "good customer service",
        "very good customer service",
        "wide selection of items  good prices and good customer service",
        "Pretty good customer service!",
        "Very good customer service  quick filling of prescriptions  friendly staff.",
        "I have never used CVS to fill my prescriptions but I have heard they have good customer service there",
        "I\u0027ve never been to a CVS that had good customer service. All the employees were rude  especially in the pharmacy dept. I always had trouble getting my prescriptions filled with them and do not shop there unless there\u0027s no other option.",
        "CVS is one of the best place to shop if you are looking for deals  promotions  and good customer service",
        "They have good customer service",
        "CVS has good customer service and is a well stocked store.",
        "It\u0027s good customer service and good products but is more expensive"
      ]
    },
    {
      "topic": "take care",
      "phrases": [
        {
          "phrase": "take forever",
          "frequency": 4,
          "sem_sim": 0.542375922203064,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9973974227905273
            }
          ],
          "pos_tags": [
            "VERB",
            "ADV"
          ],
          "context": [
            "It seems to take forever to check out at the closest CVS to me.  The price of things is much higher than other stores.",
            "It\u0027s been a long time since I\u0027ve filled a prescription at CVS and maybe they\u0027ve changed  but the pharmacy was a hot mess in the past. They used to take forever to fill a prescription. If I were you  I\u0027d go ahead and get your prescription filled there and see if they are fast and efficient now. Also  know that everything else in that store is expensive. Convenience comes with a big price tag at CVS.",
            "I would let my friend know that they\u0027re friendly but filling prescriptions there is a hassle. They take forever.  I would not recommend got prescriptions.",
            "They never have enough help in the stores. Always have long lines that take forever  need more help on the sales floor."
          ]
        },
        {
          "phrase": "take time",
          "frequency": 1,
          "sem_sim": 0.6543734669685364,
          "char_sim": 0.7111144847632321,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.7999981641769409
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN"
          ],
          "context": [
            "Trustworthy  helpful and will take time with individual to provide information and assistance as needed."
          ]
        },
        {
          "phrase": "take advantage",
          "frequency": 1,
          "sem_sim": 0.607027530670166,
          "char_sim": 0.7090623828071149,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9960625171661377
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN"
          ],
          "context": [
            "has great sales but is too confusing on how to take advantage of them"
          ]
        },
        {
          "phrase": "take care clinic available",
          "frequency": 1,
          "sem_sim": 0.891614280184921,
          "char_sim": 0.7551020408163265,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9931838512420654
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN",
            "NOUN",
            "ADJ"
          ],
          "context": [
            "There are not that many convenient locations available  your would have to drive far. the also don\u0027t have a take care clinic available  just in case of an emergency."
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9989274740219116
        }
      ],
      "topic_frequency": 3,
      "topic_tags": [
        "VERB",
        "NOUN"
      ],
      "topic_context": [
        "they take care of my prescription needs",
        "cvs is quite ok  and will take care of your needs but is not as convenient",
        "There are not that many convenient locations available  your would have to drive far. the also don\u0027t have a take care clinic available  just in case of an emergency."
      ]
    },
    {
      "topic": "good price",
      "phrases": [
        {
          "phrase": "price compare",
          "frequency": 1,
          "sem_sim": 0.6723218560218811,
          "char_sim": 0.7130744688715203,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.823984682559967
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "I would price compare at other retailers because generally I find CVS to have more expensive prices on most items"
          ]
        },
        {
          "phrase": "reasonable price",
          "frequency": 13,
          "sem_sim": 0.7840897440910339,
          "char_sim": 0.76,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.998640775680542
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Great selection of products at reasonable prices",
            "They have a wide variety of options and at a reasonable price",
            "CVS have what you need at a reasonable price.",
            "CVS has a variety of products at a reasonable price. They provide good rewards program.",
            "Its a pretty good store with reasonable prices and pretty good reward program.  Unfortunately  a lot of their weekly specials sell out fast and store is a little difficult to navigate but overall its a pretty good place to go for snacks  personal care and drugs.",
            "CVS is a traditional drug store carrying the traditional drug store items at reasonable prices.",
            "friendly staff  quick service  medications easily available  reasonable prices",
            "CVS is convenient tom me  usually has what I need at a reasonable price and often offers good discounts.",
            "I receive good service at reasonable prices.",
            "I like CVS because it\u0027s easy to fill prescription there and they have a good variety of merchandise for reasonable prices.",
            "Large  clean store  convenient  reasonable prices",
            "They have numerous locations to fill prescriptions and reasonable prices for over the counter products.",
            "They have everything you need at very reasonable price"
          ]
        },
        {
          "phrase": "get good price",
          "frequency": 1,
          "sem_sim": 0.9026551842689514,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9997174739837646
            }
          ],
          "pos_tags": [
            "AUX",
            "ADJ",
            "NOUN"
          ],
          "context": [
            "I trust CVS but they are expensive so please shop around to get good price."
          ]
        },
        {
          "phrase": "higher price",
          "frequency": 19,
          "sem_sim": 0.7296209931373596,
          "char_sim": 0.82,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9964216351509094
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVs is a good place to fill your prescriptions depending on your insurance.  Other products are higher price unless you have a coupon or exbucks or a sale.",
            "Overpriced  items out of stock. Thinks customers are stupid. Everything appears to be on sale so you cannot use coupons but still higher prices than elsewhere.  Most of the management is incompetent and unfriendly.",
            "Small express store to buy things quickly but pay a little higher price for",
            "CVS has convenient locations and a good choice of products  but that tend to have higher prices than other places.  They do have a good loyalty program that can make certain things affordable.",
            "Good store if you don\u0027t mind paying a higher price. Limited variety. Good pharmacy",
            "I would say that it\u0027s very similar to Walgreens  but with slightly higher prices.",
            "be prepared for higher prices",
            "It\u0027s convenient but usually has higher prices and not as many generic brands as other stores",
            "higher prices than any other store.",
            "convenient locations  higher price  OK sales  good cash dollar program",
            "You would go there primarily for you prescriptions  the store items are higher priced but convenience is what you pay for.",
            "It\u0027s a place that had a business model created on convenience  and with that covers slightly higher prices and less selection.",
            "Don\u0027t shop CVS much because they advertise one price and charge a higher price when you shop there",
            "CVS seems to have higher prices for their merchandise. They are more personable though.",
            "very friendly  generally higher price",
            "I do not know enough to give them a recommendation other than my perception that CVS has higher prices than many other stores.",
            "Decent pharmacy. Store hours on weekend limited. Seem to be a bit higher priced than some other stores",
            "CVS has a wide variety of products.  They\u0027re conveniently located.  Customer assistance could be better.  They\u0027re higher priced than competitors but sometimes have good sales.",
            "Not convenient and higher priced"
          ]
        },
        {
          "phrase": "lower price",
          "frequency": 5,
          "sem_sim": 0.7183670401573181,
          "char_sim": 0.82,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9990975856781006
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "I fill my prescriptions at CVS because my insurance offers better coverage there than at other pharmacies. Prescription medication is the only thing I will buy there. In all other product categories a better selection and lower prices can be found at Walmart  Target  and most supermarkets.",
            "I am not thrilled about shopping at CVS. Usually the check out process is slow. Some things are hard to find. I don\u0027t like the store\u0027s layout. I\u0027d rather go to Walmart and get everything at a lower price. For prescriptions  I prefer Express Scripts. CVS bugs me constantly about refilling prescriptions and I don\u0027t like that. An automated caller calls and you can\u0027t stop it. It\u0027s a nuisance and a hassle. I wish they would discontinue that.",
            "Using the store coupons makes the items purchased lower priced than other stores.",
            "I shop at the CVS near the Albertson\u0027s near where I live. I don\u0027t know if they are all the same  but this CVS is insufficiently stocked  disorganized and dirty. It honestly looks like it is preparing to close most of the time. Despite this  the prices are higher than a cat\u0027s ass. I can go 3 doors down to the Albertson\u0027s (which is overpriced as well) and buy at a lower price.",
            "CVS is good for the same things as Walgreens and may have lower prices and an on-site clinic."
          ]
        },
        {
          "phrase": "low price",
          "frequency": 5,
          "sem_sim": 0.7446195483207703,
          "char_sim": 0.868,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9995758533477783
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Cvs is a place where you can find your local drugstore needs for an everyday low price",
            "CVS is convenient and has the products I want but prices are fairly high.  Even though they offer weekly deals and weekly sales and a rewards program  it is to much effort to shop at CVS and receive a low price unless you make a special trip and happen to catch the item on sale.  I have stopped shopping at stores where the reward program is too complicated like CVS and Walgrens.",
            "they have great produce at low prices",
            "Great variety of products low prices and friendly empliuyees",
            "they provide low prices on some items every week"
          ]
        },
        {
          "phrase": "special price",
          "frequency": 2,
          "sem_sim": 0.728405237197876,
          "char_sim": 0.76,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9980183243751526
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "I would not shop at CVS. When I shopped there to get a special price  it was too confusing. I could not get or use the coupon offered.",
            "They have good sales and a good customer card to use and get special prices plus staff is usually cordial and professional."
          ]
        },
        {
          "phrase": "good price",
          "frequency": 30,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.999832808971405
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "If item you need is on sale or you have a coupon it may be a good price. If not go somewhere else.",
            "CVS has good prices and lots of coupons and discounts.",
            "I like shopping there  they have a big variety of items at a good price",
            "wide selection of items  good prices and good customer service",
            "Great loyalty program  knowledgeable workers  good prices and products",
            "CVS is a good store  but their regular prices are too high.  However  they have frequent excellent regular coupons  loyalty rewards  and or specials which make their prices very competitive.  The problem is  the effort it takes to find out if the item you are looking for is available at a good price at the time you want to purchase it.",
            "Very friendly  convenient good prices \u0026 almost always has what you need",
            "I trust CVS but they are expensive so please shop around to get good price.",
            "friendly salespeople and good prices",
            "it is friendly and they have good prices on many things I need",
            "good prices and specials but long line to pick up meds",
            "Convenient locations  good prices  organized well",
            "quality merchandise at good prices is always in stock. store personnel ready to help and offer suggestions.  Whenever a problem at CVs  I have found that the staff has been more than accommodating and settling the problem in my favor.",
            "CVS does not seem to have good prices and customer service does not appear to be a priority with them.",
            "Cvs can be hit or miss. If its a nice cvs they have good prices  selection  and enough staff. If its a \"miss\" cvs  prices are high  selection is low  and theres only ever 1-2 people working  so finding help is impossible.",
            "I enjoy this store more now because I get discounts.  Quality products good prices",
            "Cv s offers.convenience and has good prices and great selection",
            "Watch for sales and digital and printed coupons that you can use together to get a very good price.",
            "CVS has good prices and a decent discount.",
            "close by  easy to shop  good prices",
            "CVS is a preferred pharmacy on my Drug Plan and usually has a good price on my less than 90 day supply prescriptions.",
            "good prices and service",
            "Itâs a great place to get things at a good price.",
            "they are a good retailer  a lot like Walgreens...good selection  good prices...shop the weekly flyer",
            "Good service for a good price",
            "good prices only when they have sales or extrabucks",
            "Cvs is a great place to shop. Lots of selection and good prices",
            "They have a good selection and sometimes good prices",
            "Has a wide selection of products at a good price and offers an excellent loyalty program.  The pharmacist takes the time to answer questions.",
            "There are a lot of CVS stores around  so it\u0027s probably quick and convenient to get to one.  If you try hard  you might be able to get a good price on something using their Extra Bucks rewards program.  Those are about the only good things I can think of.  The selection is fairly limited and the prices seem quite high."
          ]
        },
        {
          "phrase": "price unless",
          "frequency": 2,
          "sem_sim": 0.6903307437896729,
          "char_sim": 0.7107524597759777,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.684368371963501
            }
          ],
          "pos_tags": [
            "NOUN",
            "SCONJ"
          ],
          "context": [
            "CVs is a good place to fill your prescriptions depending on your insurance.  Other products are higher price unless you have a coupon or exbucks or a sale.",
            "CVS is convenient and has the products I want but prices are fairly high.  Even though they offer weekly deals and weekly sales and a rewards program  it is to much effort to shop at CVS and receive a low price unless you make a special trip and happen to catch the item on sale.  I have stopped shopping at stores where the reward program is too complicated like CVS and Walgrens."
          ]
        },
        {
          "phrase": "decent price",
          "frequency": 4,
          "sem_sim": 0.877286970615387,
          "char_sim": 0.76,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998394846916199
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "decent prices on items",
            "They\u0027re located everywhere  have coupons \u0026 decent prices  that\u0027s about it.",
            "CVS is a good alternative to Walgreens.  They have a nice variety with decent prices.  I don\u0027t know about prescriptions because I have not had one filled there.  I only go to Walgreens because it is closer.",
            "They tend to be a little more expensive but they usually have what I need at a decent price"
          ]
        },
        {
          "phrase": "nice price",
          "frequency": 3,
          "sem_sim": 0.8731379508972168,
          "char_sim": 0.76,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.999823272228241
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "I would say that CVS offer a great deal of products for very nice price and good service.",
            "Cvs is always clean retail reps are very nice prices are resonable too",
            "CVS is more than a drug store. They have a good assortment of just about anything you need selling quality goods at very nice prices"
          ]
        },
        {
          "phrase": "best price",
          "frequency": 10,
          "sem_sim": 0.8396437764167786,
          "char_sim": 0.76,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9993244409561157
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS is the best damned drug store and pharmacy in the business George and they have the best prices and the most knowledgeable staff and they offer an Extra Care loyalty card rewards which saves you money! Easy to get to and in a pinch I can even walk the 3 and a half miles up and back home if I need to do so  man!",
            "best prices",
            "They donât have the best prices. Great if you need something and happen to be driving by one. But  if you are budget conscious there are better alternatives.",
            "they have best prices and customer service",
            "Not the best prices and only good for prescriptions",
            "convenient locations  but not always the best prices",
            "cvs is convenient and it has the best prices",
            "Iâve only gone for a few items years ago. I had my passport photo taken there but mostly went for snacks when I was in high school. Itâs pricey so I rather get in the car and find the best price for the items that I am looking to purchase.",
            "Good for Rx but other items are not he best priced",
            "it\u0027s a good place to get anything you need for daily life. Not necessary have the best price  but convenient."
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.999832808971405
        }
      ],
      "topic_frequency": 30,
      "topic_tags": [
        "ADJ",
        "NOUN"
      ],
      "topic_context": [
        "If item you need is on sale or you have a coupon it may be a good price. If not go somewhere else.",
        "CVS has good prices and lots of coupons and discounts.",
        "I like shopping there  they have a big variety of items at a good price",
        "wide selection of items  good prices and good customer service",
        "Great loyalty program  knowledgeable workers  good prices and products",
        "CVS is a good store  but their regular prices are too high.  However  they have frequent excellent regular coupons  loyalty rewards  and or specials which make their prices very competitive.  The problem is  the effort it takes to find out if the item you are looking for is available at a good price at the time you want to purchase it.",
        "Very friendly  convenient good prices \u0026 almost always has what you need",
        "I trust CVS but they are expensive so please shop around to get good price.",
        "friendly salespeople and good prices",
        "it is friendly and they have good prices on many things I need",
        "good prices and specials but long line to pick up meds",
        "Convenient locations  good prices  organized well",
        "quality merchandise at good prices is always in stock. store personnel ready to help and offer suggestions.  Whenever a problem at CVs  I have found that the staff has been more than accommodating and settling the problem in my favor.",
        "CVS does not seem to have good prices and customer service does not appear to be a priority with them.",
        "Cvs can be hit or miss. If its a nice cvs they have good prices  selection  and enough staff. If its a \"miss\" cvs  prices are high  selection is low  and theres only ever 1-2 people working  so finding help is impossible.",
        "I enjoy this store more now because I get discounts.  Quality products good prices",
        "Cv s offers.convenience and has good prices and great selection",
        "Watch for sales and digital and printed coupons that you can use together to get a very good price.",
        "CVS has good prices and a decent discount.",
        "close by  easy to shop  good prices",
        "CVS is a preferred pharmacy on my Drug Plan and usually has a good price on my less than 90 day supply prescriptions.",
        "good prices and service",
        "Itâs a great place to get things at a good price.",
        "they are a good retailer  a lot like Walgreens...good selection  good prices...shop the weekly flyer",
        "Good service for a good price",
        "good prices only when they have sales or extrabucks",
        "Cvs is a great place to shop. Lots of selection and good prices",
        "They have a good selection and sometimes good prices",
        "Has a wide selection of products at a good price and offers an excellent loyalty program.  The pharmacist takes the time to answer questions.",
        "There are a lot of CVS stores around  so it\u0027s probably quick and convenient to get to one.  If you try hard  you might be able to get a good price on something using their Extra Bucks rewards program.  Those are about the only good things I can think of.  The selection is fairly limited and the prices seem quite high."
      ]
    },
    {
      "topic": "good product selection",
      "phrases": [
        {
          "phrase": "good product selection",
          "frequency": 2,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998458027839661
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "The store is well organized  has a good product selection  the pharmacy is very professional",
            "They don\u0027t take many considerations regarding their information security but they have many convenient locations and good product selection"
          ]
        },
        {
          "phrase": "close location good product selection",
          "frequency": 0,
          "sem_sim": 0.9190811040450115,
          "char_sim": 0.8775510204081632,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9994587898254395
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": []
        },
        {
          "phrase": "product selection",
          "frequency": 11,
          "sem_sim": 0.9421600529125759,
          "char_sim": 0.9387755102040816,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.992181658744812
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "The stores are very clean  the employees are knowledgeable  the product selection is  and they offer good value!!!",
            "Our CVS is conveniently located  the store layout is simple to find things in  and we like the product selection including the CVS brand. The pharmacy is also staffed byknowledgeable  friendly employees.",
            "They have a great product selection",
            "Close location.  Good product selection.  Can be pricey and slow to check out",
            "The store is well organized  has a good product selection  the pharmacy is very professional",
            "they have the best product selection and the prices are reasonable",
            "They are just okay  but not outstanding in product selection and service.",
            "they have good sales and product selection  but regular prices are not frequently competitive with the big box stores.",
            "They don\u0027t take many considerations regarding their information security but they have many convenient locations and good product selection",
            "good location  knowledgeable staff  decent product selection and prices",
            "Decent product selection and friendly staff  good pharmacy discounts. Generally preferable to Walgreens."
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9998458027839661
        }
      ],
      "topic_frequency": 2,
      "topic_tags": [
        "ADJ",
        "NOUN",
        "NOUN"
      ],
      "topic_context": [
        "The store is well organized  has a good product selection  the pharmacy is very professional",
        "They don\u0027t take many considerations regarding their information security but they have many convenient locations and good product selection"
      ]
    },
    {
      "topic": "special trip",
      "phrases": [
        {
          "phrase": "special trip",
          "frequency": 2,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9992785453796387
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Their sale prices are good and worth making a special trip.  But if it\u0027s not on sale  prices are high.",
            "CVS is convenient and has the products I want but prices are fairly high.  Even though they offer weekly deals and weekly sales and a rewards program  it is to much effort to shop at CVS and receive a low price unless you make a special trip and happen to catch the item on sale.  I have stopped shopping at stores where the reward program is too complicated like CVS and Walgrens."
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9992785453796387
        }
      ],
      "topic_frequency": 2,
      "topic_tags": [
        "ADJ",
        "NOUN"
      ],
      "topic_context": [
        "Their sale prices are good and worth making a special trip.  But if it\u0027s not on sale  prices are high.",
        "CVS is convenient and has the products I want but prices are fairly high.  Even though they offer weekly deals and weekly sales and a rewards program  it is to much effort to shop at CVS and receive a low price unless you make a special trip and happen to catch the item on sale.  I have stopped shopping at stores where the reward program is too complicated like CVS and Walgrens."
      ]
    },
    {
      "topic": "get help",
      "phrases": [
        {
          "phrase": "get help",
          "frequency": 2,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9314835071563721
            }
          ],
          "pos_tags": [
            "AUX",
            "NOUN"
          ],
          "context": [
            "It often doesnt have what is needed  staff isnt helpful  you need to search for them to get help.",
            "I DON\u0027T LIKE TO SHOP CVS stores. It is hard to find employees to get help if you need it."
          ]
        },
        {
          "phrase": "need help",
          "frequency": 1,
          "sem_sim": 0.893306413475348,
          "char_sim": 0.8571428571428571,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9835426807403564
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN"
          ],
          "context": [
            "Cbs is a great place to shop they have nice employees that always greet you when you walk in and ask if you need help and they have deals weekly and also a coupon center that gives you coupons. The pharmacist are very knowledgeable and nice too."
          ]
        },
        {
          "phrase": "extra help",
          "frequency": 1,
          "sem_sim": 0.8406920250581236,
          "char_sim": 0.8928571428571428,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.995103120803833
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "I have used CVS to fill my prescriptions for the past 5 years. Normally they do a great job and know me by name and I know them by name but recently they have been overwhelmed by the amount of people needing prescriptions and CVS will not give them extra help so there has been a lot of turn over lately which has led to them not ordering my prescriptions when they were supposed to or loosing my prescription forms from the doctor. The staff is even begging people to call corporate and complain so"
          ]
        },
        {
          "phrase": "good help",
          "frequency": 1,
          "sem_sim": 0.8643616985301582,
          "char_sim": 0.8571428571428571,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998306035995483
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "The mgmt at CVS has changed lately  and because of that  they\u0027ve lost a lot of good help."
          ]
        },
        {
          "phrase": "assistance finding",
          "frequency": 0,
          "sem_sim": 0.7161370807764482,
          "char_sim": 0.5357142857142857,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9977712035179138
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": []
        },
        {
          "phrase": "offer assistance",
          "frequency": 2,
          "sem_sim": 0.7133612833460983,
          "char_sim": 0.6224489795918368,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9964096546173096
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN"
          ],
          "context": [
            "They have a convenient location and friendly associates  however their pharmacist seem preoccupied and donât offer assistance unless you ask for their attention.",
            "CVS is a great one stop shopping center. It convenient for your everyday pick me up. The customers offer assistance and the pharmacy center is very knowledgeable"
          ]
        },
        {
          "phrase": "get personal assistance",
          "frequency": 1,
          "sem_sim": 0.8303944103571834,
          "char_sim": 0.6938775510204082,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9516690969467163
            }
          ],
          "pos_tags": [
            "AUX",
            "ADJ",
            "NOUN"
          ],
          "context": [
            "They offer a wide variety of quality merchandise at a good value and it is easy to get personal assistance."
          ]
        },
        {
          "phrase": "use auto refill",
          "frequency": 0,
          "sem_sim": 0.5093342981758398,
          "char_sim": 0.7142857142857143,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9807478189468384
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN",
            "NOUN"
          ],
          "context": []
        }
      ],
      "topic_sentiment": [
        {
          "label": "NEGATIVE",
          "score": 0.9314835071563721
        }
      ],
      "topic_frequency": 2,
      "topic_tags": [
        "AUX",
        "NOUN"
      ],
      "topic_context": [
        "It often doesnt have what is needed  staff isnt helpful  you need to search for them to get help.",
        "I DON\u0027T LIKE TO SHOP CVS stores. It is hard to find employees to get help if you need it."
      ]
    },
    {
      "topic": "good service",
      "phrases": [
        {
          "phrase": "good service",
          "frequency": 18,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998498558998108
            }
          ],
          "pos_tags": [
            "ADJ",
            "PROPN"
          ],
          "context": [
            "It is a good service",
            "I would say that CVS offer a great deal of products for very nice price and good service.",
            "more expensive but good service",
            "Fast quick good service",
            "good service",
            "The provide pretty good service and have a lot of items in inventory",
            "best prescription prices-good service",
            "I have received good service at CBS  quality is good and prices are usually competitive.",
            "CVS has always given me good service and while the prices are not as good as other organizations they are fair.",
            "I have always  had good service at CVS and it\u0027s convenient for me because I shop at Target regularly and the CVS pharmacy is in my Target.",
            "I have always been pleased with them because they provide good quality products and good service.  I was VERY pleased when they stopped selling tobacco products.",
            "good service  store brands  good specials",
            "good savings but not good service",
            "good service  close location  ease of finding products in store",
            "I receive good service at reasonable prices.",
            "CVS is a store that gives good service and products we need.",
            "Is a good brand with good service but too expensive",
            "There is good service and prices on sale items  but the other prices are somewhat high."
          ]
        },
        {
          "phrase": "expensive service",
          "frequency": 0,
          "sem_sim": 0.7885584879894646,
          "char_sim": 0.7755102040816326,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9856791496276855
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": []
        },
        {
          "phrase": "service however",
          "frequency": 0,
          "sem_sim": 0.840609186766099,
          "char_sim": 0.760204081632653,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.8265076875686646
            }
          ],
          "pos_tags": [
            "NOUN",
            "ADV"
          ],
          "context": []
        },
        {
          "phrase": "hour service",
          "frequency": 0,
          "sem_sim": 0.7687300905889394,
          "char_sim": 0.8775510204081632,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9489263296127319
            }
          ],
          "pos_tags": [
            "PROPN",
            "PROPN"
          ],
          "context": []
        },
        {
          "phrase": "friendly service",
          "frequency": 9,
          "sem_sim": 0.8368729182652065,
          "char_sim": 0.8214285714285714,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998258948326111
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Small selections. Not friendly service.",
            "Fast and friendly service. Convienent locations",
            "Fast friendly service with rewards programs",
            "friendly service",
            "Fast convenience friendly service",
            "they are convenient located and friendly services",
            "Good customer friendly service",
            "good friendly service",
            "I would tell them Iâve shopped at cvs for a long time they have friendly service and knowledgeable staff and quality products"
          ]
        },
        {
          "phrase": "service possible",
          "frequency": 1,
          "sem_sim": 0.8281945732175087,
          "char_sim": 0.75,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.999133825302124
            }
          ],
          "pos_tags": [
            "NOUN",
            "ADJ"
          ],
          "context": [
            "The pharmacy staff is helpful  knowledgeable and always go out of their way to provide me with the best service possible. They are well trained in their craft and customer service as well."
          ]
        },
        {
          "phrase": "quality service",
          "frequency": 2,
          "sem_sim": 0.8400641996033338,
          "char_sim": 0.7959183673469388,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9997134208679199
            }
          ],
          "pos_tags": [
            "PROPN",
            "PROPN"
          ],
          "context": [
            "Provides good quality services and discounts",
            "Pharmacy is always friendly and very helpful to answer all questions I might have.  Staff in the entire store is always eager to help out.  Love the friendliness and quality service."
          ]
        },
        {
          "phrase": "great service",
          "frequency": 6,
          "sem_sim": 0.9393855479298805,
          "char_sim": 0.8622448979591837,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998435378074646
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "great service and great value",
            "CVS has great service",
            "CVS is  great service provider ",
            "It is a very nice place with great service and lots of merchandise",
            "I\u0027ve always had great service at CVS with filling my prescriptions there. My medications area always ready for pick up as expected. The pharmacy staff is always helpful in answering my questions. I also like the automatic refills.",
            "great service and quick prescription fulfillment"
          ]
        },
        {
          "phrase": "fast service",
          "frequency": 6,
          "sem_sim": 0.8357784431807849,
          "char_sim": 0.8367346938775511,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9991244673728943
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Best value for good product and very convenient \u0026 fast service",
            "They are knowledgeable  fast service  convenient location",
            "Very friendly very helpful fast service",
            "fast service",
            "They offer fast service  easy to do business with  good rewards program",
            "Friendly carries medicines most needed  fast service."
          ]
        },
        {
          "phrase": "poor service",
          "frequency": 1,
          "sem_sim": 0.869790890995337,
          "char_sim": 0.9183673469387754,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9996535181999207
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS is a professional place to do business. The main drawbacks are poor service at the register and the prices have shown to be higher. The rewards bucks for using their card is only useful if you plan on buying something else there in the near future. I feel the adds are misleading  making one feel they are getting a great deal."
          ]
        },
        {
          "phrase": "best service",
          "frequency": 2,
          "sem_sim": 0.8959434324381303,
          "char_sim": 0.8367346938775511,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998091459274292
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS is a reliable store.  Not the most impressive or the best service  but you can get in and out pretty easily.  They also have a pretty good selection of over the counter products",
            "The pharmacy staff is helpful  knowledgeable and always go out of their way to provide me with the best service possible. They are well trained in their craft and customer service as well."
          ]
        },
        {
          "phrase": "excellent service",
          "frequency": 2,
          "sem_sim": 0.9175523361381219,
          "char_sim": 0.7755102040816326,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998576641082764
            }
          ],
          "pos_tags": [
            "PROPN",
            "PROPN"
          ],
          "context": [
            "very customer friendly and excellent service",
            "excellent service"
          ]
        },
        {
          "phrase": "quick service",
          "frequency": 4,
          "sem_sim": 0.8659932844492855,
          "char_sim": 0.8214285714285714,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9939091205596924
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "convenient locations  quick service",
            "friendly staff  quick service  medications easily available  reasonable prices",
            "Always friendly and quick service",
            "Convenient location  easy parking  usually quick service"
          ]
        },
        {
          "phrase": "bad service",
          "frequency": 1,
          "sem_sim": 0.9313611205743284,
          "char_sim": 0.8928571428571428,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.999719500541687
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "that it is a good place to shop just bad service and stand in line for 15 or more minutes"
          ]
        },
        {
          "phrase": "mail service",
          "frequency": 1,
          "sem_sim": 0.7638752618614508,
          "char_sim": 0.8367346938775511,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9519881010055542
            }
          ],
          "pos_tags": [
            "PROPN",
            "PROPN"
          ],
          "context": [
            "cvs is a realiabely online service that has always been there for me even though it has been my fault. They notified the pharmacy itself and i was allowed to get meds for a little while at cvs until the meds came from mail service."
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9998498558998108
        }
      ],
      "topic_frequency": 18,
      "topic_tags": [
        "ADJ",
        "PROPN"
      ],
      "topic_context": [
        "It is a good service",
        "I would say that CVS offer a great deal of products for very nice price and good service.",
        "more expensive but good service",
        "Fast quick good service",
        "good service",
        "The provide pretty good service and have a lot of items in inventory",
        "best prescription prices-good service",
        "I have received good service at CBS  quality is good and prices are usually competitive.",
        "CVS has always given me good service and while the prices are not as good as other organizations they are fair.",
        "I have always  had good service at CVS and it\u0027s convenient for me because I shop at Target regularly and the CVS pharmacy is in my Target.",
        "I have always been pleased with them because they provide good quality products and good service.  I was VERY pleased when they stopped selling tobacco products.",
        "good service  store brands  good specials",
        "good savings but not good service",
        "good service  close location  ease of finding products in store",
        "I receive good service at reasonable prices.",
        "CVS is a store that gives good service and products we need.",
        "Is a good brand with good service but too expensive",
        "There is good service and prices on sale items  but the other prices are somewhat high."
      ]
    },
    {
      "topic": "reward program",
      "phrases": [
        {
          "phrase": "good reward",
          "frequency": 31,
          "sem_sim": 0.7316376566886902,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998703598976135
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Cvs is quick and easy to get in and out of they have a huge beauty section and fun clearance items and good rewards program",
            "Convenient location well stocked and good rewards program",
            "good rewards program and good sales",
            "Fair prices with a good rewards program",
            "Store is local .. close to me ... frequent sales and good rewards program ... easy clinic access ..  rewards are usually not okay for sale items",
            "That my local store knows me and treats me well as long as its close with easy parking and good rewards program. They work well with my insurance and making sure my Rx are filled and when next is due or I need to refill etc. by helping me to keep them up to date for me.",
            "cvs is a great store. good rewards. plenty of selection. pharmacy inside is convenient",
            "I shop at CVS a lot. They have a good rewards program and good sales.",
            "I can usually fine what I needed.  Sometime the price is a little more but they have a good rewards program",
            "Location is fairly close  good rewards program  general merchandise and assistance is good  Pharmacist on staff is very rude and non customer service focused",
            "They offer fast service  easy to do business with  good rewards program",
            "I had good experiences with CVS  they have a really good rewards program or at least they used to  I don\u0027t go there often because I switched to a cheaper pharmacy. But customer service at CVS is good.",
            "Convenient and good rewards program.",
            "has convenience and good pharmacy help. it has good rewards sometimes.",
            "They have really good rewards programs.",
            "CVS has a variety of products at a reasonable price. They provide good rewards program.",
            "Very convenient locations and a good rewards program",
            "They have a nice selection of products and a good rewards program.",
            "Good place to get prescriptions filled  convenient location  good rewards",
            "Its a pretty good store with reasonable prices and pretty good reward program.  Unfortunately  a lot of their weekly specials sell out fast and store is a little difficult to navigate but overall its a pretty good place to go for snacks  personal care and drugs.",
            "good rewards",
            "They have good rewards and they are nice to me but thatâs my store next to me   donât know how they were nationally",
            "good reward",
            "I would tell them that CVS has a good rewards program for purchases  prescription fills  and beauty buys. They also have clinics inside.",
            "CVS has a good reward program if you get prescriptions filled at a local pharmacy",
            "Offers good rewards program and competitive prices on a wide selection of products.",
            "slow filling prescriptions  good rewards program.  friendly  helpful staff",
            "they have good sales and a good rewards program  but never buy anything regular price without a coupon because Target regular prices are much lower.",
            "Convenient locations and very good rewards program.  Excellent selection and pries ",
            "cvs has excellent prices on their products  they have  lots of sales  great staff  good rewards program  name brands",
            "has a good rewards program"
          ]
        },
        {
          "phrase": "reward system",
          "frequency": 2,
          "sem_sim": 0.8366392254829407,
          "char_sim": 0.94,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9996861815452576
            }
          ],
          "pos_tags": [
            "NOUN",
            "PROPN"
          ],
          "context": [
            "Best value and quality when using their reward system",
            "CVS is usually cleaner than Walgreens and RiteAid. Their prices are pretty high though which is a deterrent for me. But they have coupons occasionally. Iâm not familiar with their reward system."
          ]
        },
        {
          "phrase": "great reward",
          "frequency": 14,
          "sem_sim": 0.7252777218818665,
          "char_sim": 0.7015162747423381,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998877644538879
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS has a great rewards program  and competitive prices. They are conveniently located with clean  organized stores. Overwhelming majority of shopping experiences includes friendly staff and a great selection of products.",
            "CVS is the store you want to shop in because they have terrific sales before and after the actual holiday.  Has great rewards program and they know me personally.  Always there lto help you",
            "They\u0027re friendly helpful and accommodating and they have a great rewards program",
            "Have a good selection of products  easy to find  convenient and great rewards program.",
            "I love CVS !!!  They are so helpful and friendly and have great reward program.",
            "CVS is conveniently located for me  is open 24 hours  has a great self-checkout and a great rewards program.",
            "They have a great rewards program for filling prescriptions and usually always have coupons to help with the cost of paying for your medications. They tend to have some really good sales as well.",
            "CVS has a great reward program. Lots of incentives  coupons and cash back. It is very rare that I pay anything for my products. They accept all coupons  their coupons  extra bucks and it makes shopping a delight especially when everything is free or near free",
            "There products are over priced bbn ut they have a great rewards program that can make prices competitive",
            "has great deals and a great rewards program  convinient locations",
            "Great customer friendly staff. Has products I need for fair prices and a great rewards program.",
            "Friendly and has a great rewards system.  Good sales",
            "Rarely have problems with prescriptions  convenient hours  excellent sales  and a great reward program",
            "Cvs has a great rewards program so that is why I shop there"
          ]
        },
        {
          "phrase": "reward card",
          "frequency": 1,
          "sem_sim": 0.8551701757372643,
          "char_sim": 0.8214285714285714,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.999657928943634
            }
          ],
          "pos_tags": [
            "PROPN",
            "NOUN"
          ],
          "context": [
            "Cvs offers a one stop shop for everything on your list. You can use your reward card to gain valuable coupons and rewards off of products you actually will use."
          ]
        },
        {
          "phrase": "great reward program",
          "frequency": 3,
          "sem_sim": 0.9475603857818915,
          "char_sim": 0.9132653061224489,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.999890148639679
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS has a great reward program. Lots of incentives  coupons and cash back. It is very rare that I pay anything for my products. They accept all coupons  their coupons  extra bucks and it makes shopping a delight especially when everything is free or near free",
            "I love CVS !!!  They are so helpful and friendly and have great reward program.",
            "Rarely have problems with prescriptions  convenient hours  excellent sales  and a great reward program"
          ]
        },
        {
          "phrase": "great reward program lots",
          "frequency": 0,
          "sem_sim": 0.893368298910102,
          "char_sim": 0.8622448979591837,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.999885082244873
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN",
            "NOUN"
          ],
          "context": []
        },
        {
          "phrase": "pretty good reward program",
          "frequency": 1,
          "sem_sim": 0.8778477663896522,
          "char_sim": 0.8520408163265305,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998757839202881
            }
          ],
          "pos_tags": [
            "ADV",
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "Its a pretty good store with reasonable prices and pretty good reward program.  Unfortunately  a lot of their weekly specials sell out fast and store is a little difficult to navigate but overall its a pretty good place to go for snacks  personal care and drugs."
          ]
        },
        {
          "phrase": "good reward program",
          "frequency": 2,
          "sem_sim": 0.9538287873170814,
          "char_sim": 0.9285714285714286,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998763203620911
            }
          ],
          "pos_tags": [
            "ADJ",
            "PROPN",
            "NOUN"
          ],
          "context": [
            "CVS has a good reward program if you get prescriptions filled at a local pharmacy",
            "Its a pretty good store with reasonable prices and pretty good reward program.  Unfortunately  a lot of their weekly specials sell out fast and store is a little difficult to navigate but overall its a pretty good place to go for snacks  personal care and drugs."
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9997619390487671
        }
      ],
      "topic_frequency": 12,
      "topic_tags": [
        "VERB",
        "NOUN"
      ],
      "topic_context": [
        "I love CVS !!!  They are so helpful and friendly and have great reward program.",
        "With the reward program CVS offers  I get many products at a price lower than most other places. They also have great coupons and a large selection of products.",
        "They have a reward program",
        "I like CVS and it has a convenient location but it always seems more expensive than other drug stores. I also cannot understand their reward program so I never end up using my reward points which is frustrating. The last experience I had was also bad- their credit card machine was down  I had no cash  so I had to walk out of the store",
        "Cvs has a reward program that helps you save money on prescriptions and other items",
        "CVS has a great reward program. Lots of incentives  coupons and cash back. It is very rare that I pay anything for my products. They accept all coupons  their coupons  extra bucks and it makes shopping a delight especially when everything is free or near free",
        "CVS has a good reward program if you get prescriptions filled at a local pharmacy",
        "They\u0027re convenient  have a loyalty reward program  have competitive prices  and have a good variety of goods.",
        "Its a pretty good store with reasonable prices and pretty good reward program.  Unfortunately  a lot of their weekly specials sell out fast and store is a little difficult to navigate but overall its a pretty good place to go for snacks  personal care and drugs.",
        "Has a nice reward program. Also has ok coupons.",
        "CVS is convenient and has the products I want but prices are fairly high.  Even though they offer weekly deals and weekly sales and a rewards program  it is to much effort to shop at CVS and receive a low price unless you make a special trip and happen to catch the item on sale.  I have stopped shopping at stores where the reward program is too complicated like CVS and Walgrens.",
        "Rarely have problems with prescriptions  convenient hours  excellent sales  and a great reward program"
      ]
    },
    {
      "topic": "large number",
      "phrases": [
        {
          "phrase": "large number",
          "frequency": 2,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9982956647872925
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS is a wonderful place to shop. It has a large number of available products for all sorts of needs.",
            "It has a large number of products but it isn\u0027t the easiest to shop because of the way that the store is arranged."
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9982956647872925
        }
      ],
      "topic_frequency": 2,
      "topic_tags": [
        "ADJ",
        "NOUN"
      ],
      "topic_context": [
        "CVS is a wonderful place to shop. It has a large number of available products for all sorts of needs.",
        "It has a large number of products but it isn\u0027t the easiest to shop because of the way that the store is arranged."
      ]
    },
    {
      "topic": "good retailer",
      "phrases": [
        {
          "phrase": "good provider depending",
          "frequency": 1,
          "sem_sim": 0.4851343631744385,
          "char_sim": 0.7058684688056156,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9996802806854248
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "VERB"
          ],
          "context": [
            "Were I live  the CVS is just a little mo pop store.The bigger place is 15 miles from me. They are a good provider depending on how you use them. I do like their mail order pharmacy."
          ]
        },
        {
          "phrase": "adequate retailer",
          "frequency": 1,
          "sem_sim": 0.7743847370147705,
          "char_sim": 0.701684631407635,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9996607303619385
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "I prefer Walgreens over CVS.  I would guess that CVS is an adequate retailer but I prefer the Walgreens brand over them and would probably not shop there unless I really needed to."
          ]
        },
        {
          "phrase": "good retailer",
          "frequency": 4,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.999845564365387
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "they are a good retailer  a lot like Walgreens...good selection  good prices...shop the weekly flyer",
            "A good retailer to get everything in one stop",
            "CVS is a good retailer  but their prices are higher than others.",
            "CVS is a good retailer but I shop around to see if I can get a better price"
          ]
        },
        {
          "phrase": "preferred retailer",
          "frequency": 2,
          "sem_sim": 0.7506045699119568,
          "char_sim": 0.7774193548387096,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9876097440719604
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Have only used as a backup in the past when preferred retailer does not carry the item",
            "my experiences with cvs have all been very positive  the only reason i changed from their pharmacy is because my health insurance didn\u0027t pick them as a preferred retailer  i still do shop the store however."
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.999845564365387
        }
      ],
      "topic_frequency": 4,
      "topic_tags": [
        "ADJ",
        "NOUN"
      ],
      "topic_context": [
        "they are a good retailer  a lot like Walgreens...good selection  good prices...shop the weekly flyer",
        "A good retailer to get everything in one stop",
        "CVS is a good retailer  but their prices are higher than others.",
        "CVS is a good retailer but I shop around to see if I can get a better price"
      ]
    },
    {
      "topic": "discourage anyone",
      "phrases": [
        {
          "phrase": "discourage anyone",
          "frequency": 3,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9996313452720642
            }
          ],
          "pos_tags": [
            "VERB",
            "PRON"
          ],
          "context": [
            "I have not shopped at CVS enough to encourage or discourage anyone from shopping there.",
            "Itâs a store that sells products. I do not have any reason to encourage or discourage anyone from making a purchase at CVS. We live in a free market society so let the customer chose as to where they spend their money!",
            "I could not encourage or discourage anyone from shopping there  because I don\u0027t shop there."
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "NEGATIVE",
          "score": 0.9996313452720642
        }
      ],
      "topic_frequency": 3,
      "topic_tags": [
        "VERB",
        "PRON"
      ],
      "topic_context": [
        "I have not shopped at CVS enough to encourage or discourage anyone from shopping there.",
        "Itâs a store that sells products. I do not have any reason to encourage or discourage anyone from making a purchase at CVS. We live in a free market society so let the customer chose as to where they spend their money!",
        "I could not encourage or discourage anyone from shopping there  because I don\u0027t shop there."
      ]
    },
    {
      "topic": "bad experience",
      "phrases": [
        {
          "phrase": "first experience",
          "frequency": 1,
          "sem_sim": 0.6434704661369324,
          "char_sim": 0.7827586206896552,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9899806380271912
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "I like CVS.  Had my first experience with them on Dupont Circle in D.C.  Here  they are not as close to my residence as Walmart.  Also  my previous prescription plan didn\u0027t work as cost effective at CVS."
          ]
        },
        {
          "phrase": "complete experience",
          "frequency": 1,
          "sem_sim": 0.5703997015953064,
          "char_sim": 0.7827586206896552,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9996200203895569
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "I like the assortment of CVS products and I used to shop there  but I feel Walmart offers a more complete experience."
          ]
        },
        {
          "phrase": "bad experience",
          "frequency": 5,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9997825622558594
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "I have never had any bad experiences with this retailer",
            "CVS I am brand neutral. Never had a bad experience  but feel itâs nothing special either.",
            "They are fine I\u0027ve never had a bad experience",
            "I have had good and bad experience with pharmacy. They are too slow nd understaffed",
            "ive never had a bad experience with cvs  except for the phone system  they have mandatory robo ordering and sometimes the voice misunderstands words"
          ]
        },
        {
          "phrase": "negative experience",
          "frequency": 2,
          "sem_sim": 0.7168391942977905,
          "char_sim": 0.7827586206896552,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.999743640422821
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "I fill all of my prescriptions at CVS  primarily due to their extended hours. Their pharmacists are always extremely knowledgeable and helpful. I have never had a negative experience there. I also love that I am able to shop for other items  during my short wait time. And if I don\u0027t feel like waiting  there is a drive-up window. It is an amazing place to fill prescriptions and shop. Furthermore  every CVS location I have visited is clean and has conveniently placed wide aisles.",
            "I\u0027ve had negative experiences with the pharmacy  I would always choose walgreens over cvs"
          ]
        },
        {
          "phrase": "experience however",
          "frequency": 0,
          "sem_sim": 0.681150496006012,
          "char_sim": 0.7057444975066874,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.7561907172203064
            }
          ],
          "pos_tags": [
            "NOUN",
            "ADV"
          ],
          "context": []
        },
        {
          "phrase": "pleasant experience",
          "frequency": 2,
          "sem_sim": 0.6981104016304016,
          "char_sim": 0.8551724137931034,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998760223388672
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Less chaos and more pleasant experience in the store as compared to Walgreens hustle.",
            "I\u0027ve had pleasant experiences shopping at CVS."
          ]
        },
        {
          "phrase": "little experience",
          "frequency": 5,
          "sem_sim": 0.691663384437561,
          "char_sim": 0.7827586206896552,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9929286241531372
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "I would say I have little experience with CVS on this topic as I don\u0027t shop there that often.",
            "I have too little experience with them to give a recommendation.",
            "I had a little experience with CVS over the years  but not that much. There really isn\u0027t a Store convenient to me. Once a month I pass the Store on the way to a regular appointment that I have. I don\u0027t really like the atmosphere in that Store  so I don\u0027t really go in there. I have  tried a few of their Store brand items and didn\u0027t like them.",
            "CVS is a typical drug store  if one were closer I would use it. I have very little experience with CVS.",
            "I only go to CVS when it\u0027s the holiday season so I have little experience with them."
          ]
        },
        {
          "phrase": "great shopping experience",
          "frequency": 2,
          "sem_sim": 0.8158847543658043,
          "char_sim": 0.8112244897959183,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998605251312256
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS is a great shopping experience.  However  their prices tend to be higher than Walmart  Target and Amazon.",
            "CVS is a trustworthy drug store that deliver consistently a great shopping experience"
          ]
        },
        {
          "phrase": "positive shopping experience",
          "frequency": 1,
          "sem_sim": 0.8122282526930984,
          "char_sim": 0.760204081632653,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998719096183777
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "Any time that i have visited CVS I have had a positive shopping experience. However  their prices are quite a bit higher than many other places."
          ]
        },
        {
          "phrase": "nice easy shopping experience",
          "frequency": 1,
          "sem_sim": 0.8019493404699831,
          "char_sim": 0.7806122448979591,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998572468757629
            }
          ],
          "pos_tags": [
            "PROPN",
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "Itâs a nice easy shopping experience. They have a lot of deals if you have the rewards card"
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "NEGATIVE",
          "score": 0.9997825622558594
        }
      ],
      "topic_frequency": 5,
      "topic_tags": [
        "ADJ",
        "NOUN"
      ],
      "topic_context": [
        "I have never had any bad experiences with this retailer",
        "CVS I am brand neutral. Never had a bad experience  but feel itâs nothing special either.",
        "They are fine I\u0027ve never had a bad experience",
        "I have had good and bad experience with pharmacy. They are too slow nd understaffed",
        "ive never had a bad experience with cvs  except for the phone system  they have mandatory robo ordering and sometimes the voice misunderstands words"
      ]
    },
    {
      "topic": "stop shop",
      "phrases": [
        {
          "phrase": "stop shop",
          "frequency": 15,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9987130761146545
            }
          ],
          "pos_tags": [
            "VERB",
            "PROPN"
          ],
          "context": [
            "CVS is my one stop shop.  I am a loyal customer of CVS.  I continuously receive coupons for 30 to 40% off purchases through the mail and email.  I have saved so much money on my purchases throughout the year.  I am a top saver with CVS.  I can  always  find everything I need when I shop with CVS.",
            "There are tons of locations  so there is probably one close by. They carry a variety of products so it\u0027s a one stop shop.",
            "Cvs offers a one stop shop for everything on your list. You can use your reward card to gain valuable coupons and rewards off of products you actually will use.",
            "I would tell my friend that CVS is a one stop shop for all health and beauty aids that you may need.",
            "conveniently located...one stop shop for all your daily needs including pharmacy",
            "I love shopping at CVS because they are my one stop shop for everything I need.",
            "CVS has everything you need  it is one-stop shopping.",
            "CVS is a one stop shop for medicine and anything extra you can get",
            "CVS is a great one stop shopping center. It convenient for your everyday pick me up. The customers offer assistance and the pharmacy center is very knowledgeable",
            "One stop shopping",
            "Good prices.  Just prefer one stop shop for other needs",
            "I would tell my friend that CVS is a good one stop shopping place if you have coupons and especially CVS coupons which will make a big difference in what you pay.",
            "Cvs has convenient locations and is a one stop shop everything",
            "One stop shopping  conveniently located",
            "One stop shop"
          ]
        },
        {
          "phrase": "one stop shop",
          "frequency": 11,
          "sem_sim": 0.9790632651776683,
          "char_sim": 0.9132653061224489,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.8664696216583252
            }
          ],
          "pos_tags": [
            "NUM",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS is my one stop shop.  I am a loyal customer of CVS.  I continuously receive coupons for 30 to 40% off purchases through the mail and email.  I have saved so much money on my purchases throughout the year.  I am a top saver with CVS.  I can  always  find everything I need when I shop with CVS.",
            "There are tons of locations  so there is probably one close by. They carry a variety of products so it\u0027s a one stop shop.",
            "Cvs offers a one stop shop for everything on your list. You can use your reward card to gain valuable coupons and rewards off of products you actually will use.",
            "I would tell my friend that CVS is a one stop shop for all health and beauty aids that you may need.",
            "conveniently located...one stop shop for all your daily needs including pharmacy",
            "I love shopping at CVS because they are my one stop shop for everything I need.",
            "CVS is a great one stop shopping center. It convenient for your everyday pick me up. The customers offer assistance and the pharmacy center is very knowledgeable",
            "CVS is a one stop shop for medicine and anything extra you can get",
            "I would tell my friend that CVS is a good one stop shopping place if you have coupons and especially CVS coupons which will make a big difference in what you pay.",
            "Good prices.  Just prefer one stop shop for other needs",
            "Cvs has convenient locations and is a one stop shop everything"
          ]
        },
        {
          "phrase": "stop quick shop",
          "frequency": 1,
          "sem_sim": 0.9485341310501099,
          "char_sim": 0.8775510204081632,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9961752891540527
            }
          ],
          "pos_tags": [
            "VERB",
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Itâs a great store to get prescriptions and a bunch of other cool stuff. One stop quick shop"
          ]
        },
        {
          "phrase": "stop convenient shop",
          "frequency": 1,
          "sem_sim": 0.9319814042169221,
          "char_sim": 0.8112244897959183,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9695074558258057
            }
          ],
          "pos_tags": [
            "VERB",
            "ADJ",
            "NOUN"
          ],
          "context": [
            "It is a one stop convenient shop with a great loyalty program. you can also get photos printed here and have a nice print shop"
          ]
        },
        {
          "phrase": "shop staff",
          "frequency": 1,
          "sem_sim": 0.8310154846736363,
          "char_sim": 0.7653061224489796,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.5367609858512878
            }
          ],
          "pos_tags": [
            "PROPN",
            "PROPN"
          ],
          "context": [
            "Cvs is a good store to shop staff is knowledgeable and always says hi"
          ]
        },
        {
          "phrase": "never shop",
          "frequency": 20,
          "sem_sim": 0.8573714725825252,
          "char_sim": 0.7653061224489796,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9910640716552734
            }
          ],
          "pos_tags": [
            "ADV",
            "PROPN"
          ],
          "context": [
            "I have never shopped there before. So I would probably recommend something else",
            "I know personally of some business practices observed by CVS that I find reprehensible. I recognize that the employees in the store are not responsible for these business practices and therefore I will shop there is no other convenient choice is available  but I would never shop there voluntarily. They treat their employees poorly  they are only interested in financial gain and not the well being of their store employees.",
            "I have never shopped at CVS  but I would tell my friend it is worth trying it.",
            "have no experience with this store - never shop there",
            "Have never shopped at CVS  so would not know how to describe the store",
            "I have never shopped there so therefore I do have an opinion",
            "Unfamiliar with CVS as Iâve never shopped there.",
            "I have never shopped there but it is a pharmacy drug store place",
            "I have never shopped at CVS so I don\u0027t really have an opinion one way or another",
            "I\u0027ve never shopped there so I can\u0027t give them any advice or not",
            "I would not be able to give them an unbiased optimism because I have never shopped there.",
            "I would honestly tell them I don\u0027t know because I have never shopped there",
            "never shop there but I will have to try to not convenient to my house",
            "I don\u0027t know enough about CVS to comment.  I\u0027ve never shopped there at all.",
            "I never shop there. But I  will go and see what they have",
            "I have no comments on CVS as I have never shopped there and I am not aware of a store in my area.",
            "I have never shopped at cvs and that is mainly because for 3 generations in my family we always shop walgreens.",
            "I have never shopped there  so I would not say anything",
            "I have never shopped CVS as there are no stores within 100 miles",
            "I have never shopped there; but  you can try it."
          ]
        },
        {
          "phrase": "please shop",
          "frequency": 1,
          "sem_sim": 0.8010691988224886,
          "char_sim": 0.8010204081632653,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9992150664329529
            }
          ],
          "pos_tags": [
            "INTJ",
            "PROPN"
          ],
          "context": [
            "I trust CVS but they are expensive so please shop around to get good price."
          ]
        },
        {
          "phrase": "usually shop",
          "frequency": 3,
          "sem_sim": 0.8387846764253111,
          "char_sim": 0.7857142857142857,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.95890873670578
            }
          ],
          "pos_tags": [
            "ADV",
            "NOUN"
          ],
          "context": [
            "noy a Tricare provider  so don\u0027t usually shop there",
            "i don\u0027t usually shop at CVS because I think their stores are a bit disorganized  they do not always look neat",
            "I like a CVS is mission statement they have a great selection when when items are on sale or discounted is when I usually shop at CVS otherwise they\u0027re their cost tend to be a little bit higher then let\u0027s say Costco or Amazon."
          ]
        },
        {
          "phrase": "shop elsewhere",
          "frequency": 2,
          "sem_sim": 0.8629187795580651,
          "char_sim": 0.673469387755102,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9968491196632385
            }
          ],
          "pos_tags": [
            "PROPN",
            "ADV"
          ],
          "context": [
            "CVS has so many weekly coupons and bonuses I can\u0027t afford to shop elsewhere. I love being able to check out new makeup and skincare while picking up medicine.",
            "CVS is okay but prices are a bit higher so I prefer to shop elsewhere."
          ]
        },
        {
          "phrase": "shop wherever",
          "frequency": 1,
          "sem_sim": 0.8417365660472792,
          "char_sim": 0.6785714285714286,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.8663832545280457
            }
          ],
          "pos_tags": [
            "NOUN",
            "ADV"
          ],
          "context": [
            "For me  CVS is not close enough to be considered for most purchases. I don\u0027t think they stand out enough for me to consider traveling to their closet location. It\u0027s not merely a matter of distance  it\u0027s about traffic. So  I\u0027d tell my friend that to shop wherever it\u0027s convenient. Most of these places are the same."
          ]
        },
        {
          "phrase": "rarely shop",
          "frequency": 4,
          "sem_sim": 0.8138079886533776,
          "char_sim": 0.75,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9530520439147949
            }
          ],
          "pos_tags": [
            "ADV",
            "PROPN"
          ],
          "context": [
            "I have no positive or negative feelings for CVS. I rarely shop there because it is just not convenient for me.",
            "I rarely shop there so I would feel unable to give an honest opinion.",
            "That I rarely shop there because my house is closer to Walgreens",
            "CVS is very convenient to my house  has a drive through and a 24 hour prescription service. However  on the inside of the store I find their prices higher than other places  so I rarely shop inside even when I have a coupon  because the coupons have so many restrictions."
          ]
        },
        {
          "phrase": "shop ut",
          "frequency": 1,
          "sem_sim": 0.8282063420937986,
          "char_sim": 0.75,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9541682004928589
            }
          ],
          "pos_tags": [
            "PROPN",
            "INTJ"
          ],
          "context": [
            "CVS is a good place to shop ut it isn\u0027t very conveniently located in this neighborhood"
          ]
        },
        {
          "phrase": "comparison shop",
          "frequency": 2,
          "sem_sim": 0.8037070084591301,
          "char_sim": 0.7908163265306123,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9948640465736389
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "need to comparison shop as prices vary so CVS is sometimes better than others.",
            "CVS prices can be very high vs other stores  so check out their coupons  sales and Rewards  and comparison shop with other retailers."
          ]
        },
        {
          "phrase": "regularly shop",
          "frequency": 1,
          "sem_sim": 0.8581846198257135,
          "char_sim": 0.7142857142857143,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9818926453590393
            }
          ],
          "pos_tags": [
            "ADV",
            "PROPN"
          ],
          "context": [
            "In general  I cannot recommend CVS to me.  On the plus side  they have a good app.  If you regularly shop there and are a member of their loyalty program  prices are OK  but not great.  Occasional shoppers at CVS are taken advantage of via high prices.  The quality of the stores is a decidedly mixed bag.  Some are well run and clean.  Others are a disgrace.  The same uneven quality applies to the pharmacies.  I\u0027ve encountered wonderful pharmacists and technicians inside CVS stores and I\u0027ve also"
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "NEGATIVE",
          "score": 0.9987130761146545
        }
      ],
      "topic_frequency": 15,
      "topic_tags": [
        "VERB",
        "PROPN"
      ],
      "topic_context": [
        "CVS is my one stop shop.  I am a loyal customer of CVS.  I continuously receive coupons for 30 to 40% off purchases through the mail and email.  I have saved so much money on my purchases throughout the year.  I am a top saver with CVS.  I can  always  find everything I need when I shop with CVS.",
        "There are tons of locations  so there is probably one close by. They carry a variety of products so it\u0027s a one stop shop.",
        "Cvs offers a one stop shop for everything on your list. You can use your reward card to gain valuable coupons and rewards off of products you actually will use.",
        "I would tell my friend that CVS is a one stop shop for all health and beauty aids that you may need.",
        "conveniently located...one stop shop for all your daily needs including pharmacy",
        "I love shopping at CVS because they are my one stop shop for everything I need.",
        "CVS has everything you need  it is one-stop shopping.",
        "CVS is a one stop shop for medicine and anything extra you can get",
        "CVS is a great one stop shopping center. It convenient for your everyday pick me up. The customers offer assistance and the pharmacy center is very knowledgeable",
        "One stop shopping",
        "Good prices.  Just prefer one stop shop for other needs",
        "I would tell my friend that CVS is a good one stop shopping place if you have coupons and especially CVS coupons which will make a big difference in what you pay.",
        "Cvs has convenient locations and is a one stop shop everything",
        "One stop shopping  conveniently located",
        "One stop shop"
      ]
    },
    {
      "topic": "convenient location easy",
      "phrases": [
        {
          "phrase": "convenient easy parking",
          "frequency": 0,
          "sem_sim": 0.8205001950263977,
          "char_sim": 0.7157904450227224,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9981390237808228
            }
          ],
          "pos_tags": [
            "ADJ",
            "ADJ",
            "NOUN"
          ],
          "context": []
        },
        {
          "phrase": "convenient parking",
          "frequency": 0,
          "sem_sim": 0.6740683317184448,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.998543918132782
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": []
        },
        {
          "phrase": "many convenient",
          "frequency": 7,
          "sem_sim": 0.7804929614067078,
          "char_sim": 0.8636363636363636,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.99586021900177
            }
          ],
          "pos_tags": [
            "ADJ",
            "ADJ"
          ],
          "context": [
            "has many convenient locations",
            "There are not that many convenient locations available  your would have to drive far. the also don\u0027t have a take care clinic available  just in case of an emergency.",
            "They don\u0027t take many considerations regarding their information security but they have many convenient locations and good product selection",
            "CVS has many convenient locations. CVS Pharmacy is a preferred provider with my drug prescription plan. CVS has the best money-saving incentives  and weekly advertised specials I regularly use.",
            "I would say that CVS has many convenient locations and it is easy to find the products that you need.  Also there are perks for have the CVS card.",
            "I\u0027m sure this is a perfectly fine store to shop in.They have many conveniently located stores.",
            "CVS has many convenient locations. They are overall more expensive throughout all product ranges  however with weekly coupons (I as a very regular customer receive a 30% or 40% off coupon every Thursday!) it is more affordable."
          ]
        },
        {
          "phrase": "convenient place",
          "frequency": 9,
          "sem_sim": 0.9042536883938069,
          "char_sim": 0.8520408163265305,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9993898272514343
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Whenever I go to CVS  I can find what I want quickly and easily.  The stores are organized and clean.  The cashiers are always helpful and friendly.  There are two CVS stores within ten minutes of me   so it\u0027s a convenient place to shop.  Between CVS coupons  Extra Bucks Rewards  BOGO offers  and sale prices  I get a lot of good deals.  It\u0027s interesting to see the seasonal products they carry.",
            "A convenient place with fair pricing",
            "I like CVS\u0027s rewards program. When I live near a CVS  I go fairly often- it is a convenient place to get many household essentials. When it is no longer geographically convenient  I go less often.",
            "A quick convenient place to shop if you are going in for just a couple things",
            "CVS is a convenient place to shop  has a good selections of products  and has a friendly staff. The only concern I have is with the pharmacy which too often makes mistakes or does not have the medication in stock.",
            "They have convenient places all over the city they have a drive-through if you donât have the time to go in you can drop off your prescriptions and then pick some up when theyâre ready and they have the convenience of a store if you need anything else",
            "CVS has most anything you need  but you\u0027ll pay a premium for everything in the store. All of the prices are extremely high and I usually only visit if it\u0027s the only convenient place to purchase what I need.",
            "Is a convenient place to get your perscriptions and other houshold items.its organized and clean and they have a good loyalty program.",
            "I would say it is a convenient place to shop if you just need to pick up a little something  but I wouldn\u0027t recommend shopping there regularly because it is more expensive."
          ]
        },
        {
          "phrase": "quick convenient place",
          "frequency": 1,
          "sem_sim": 0.908445810785099,
          "char_sim": 0.8061224489795917,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.993849515914917
            }
          ],
          "pos_tags": [
            "ADJ",
            "ADJ",
            "NOUN"
          ],
          "context": [
            "A quick convenient place to shop if you are going in for just a couple things"
          ]
        },
        {
          "phrase": "location convenient",
          "frequency": 1,
          "sem_sim": 0.9684157772940032,
          "char_sim": 0.7346938775510203,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.999002993106842
            }
          ],
          "pos_tags": [
            "NOUN",
            "ADJ"
          ],
          "context": [
            "I would tell the friend that cvs has a location convenient to her where she can easily refill scripts with drive through options."
          ]
        },
        {
          "phrase": "convenient location",
          "frequency": 67,
          "sem_sim": 0.9684157772940032,
          "char_sim": 0.943877551020408,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9993704557418823
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS is costly and a lot of times they do not have stock that I\u0027m looking for.  BUT  it is a convenient location",
            "no convenient locations nearby",
            "Cvs has fast pharmacy pick up  convenient locations and loyalty rewards",
            "Nice  clean store.   I do not personally shop there  as not in a convenient location.",
            "Great loyalty program  convenient locations.",
            "There are convenient locations nearby.",
            "good selection and convenient location",
            "trusted pharmacy  has convenient locations",
            "I like the auto refill on prescriptions. convenient locations",
            "Very convenient location; knowledgeable  friendly staff; prices commensurate with other pharmacies; drive-through is usually fairly quick",
            "I like Cvs. Thereâs usually always a convenient location  and they have store brand products that are pretty reasonably priced.",
            "Cvs has convenient locations and is a one stop shop everything",
            "They\u0027re okay  but have nothing special to offer.  They don\u0027t have the lowest prices  they don\u0027t have the friendliest staff  they don\u0027t have the most convenient locations.  You can\u0027t really go wrong shopping there  but there are many better alternatives.",
            "Clean store  convenient location",
            "has convenient locations and it\u0027s easy to find things because they all have a standard set-up",
            "Not a convenient location.",
            "they have clean stores  helpful and knowledgeable employees  offer coupons of value  convenient locations to home  rain checks.",
            "convenient location but prices tend to be high except for sale itemms",
            "CVS has convenient locations and a good choice of products  but that tend to have higher prices than other places.  They do have a good loyalty program that can make certain things affordable.",
            "They have several convenient locations and offer a wide variety of products and selections",
            "Not a convenient location",
            "I like CVS because it has very convenient locations. You can often times find them within 2 miles of each other in the neighborhood. They also offer alot of coupons and discounts.",
            "its convenient location and clean stores  but check out can take a long time depending on when you are shopping.  they offer a lot of coupons",
            "Good selection of products. Many convenient locations.",
            "convenient locations  quick service",
            "CVS has convenient locations.  The store is well organized.  They offer good weekly specials.",
            "Store is clean  fair prices  convenient location",
            "Its not a convenient location for me.  I hardly ever shop there.",
            "Ok prices are high but very convenient location",
            "convenient location",
            "convenient locations  but not always the best prices",
            "They have a convenient location and friendly associates  however their pharmacist seem preoccupied and donât offer assistance unless you ask for their attention.",
            "Stores are in odd inconvenient locations",
            "I like CVS and they are always in a convenient location. But  their prices are pretty expensive!",
            "convenient location and good store hours",
            "convenient locations  higher price  OK sales  good cash dollar program",
            "has many convenient locations",
            "Itâs at a convenient location but there pretty expensive",
            "CVS has a wide selection of high quality products for your everyday needs. They have the most competitive prices. And they have a convenient health clinic for minor illnesses and services like flu shots or the common cold. They have convenient locations so you save time and money",
            "They have convenient locations and the prices aren\u0027t too bad.  Cashiers are friendly and store employees can help you find what you need.",
            "There are not that many convenient locations available  your would have to drive far. the also don\u0027t have a take care clinic available  just in case of an emergency.",
            "Very convenient locations and a good rewards program",
            "They have good medicine and pharmacy assistance and convenient locations",
            "It has convenient locations.",
            "Good place to get prescriptions filled  convenient location  good rewards",
            "Easy to find what I want  convenient locations",
            "I think it\u0027s a convenient location for personal items where the locations are great. I think you can usually trust pharmacy staff to answer questions.",
            "CVS runs fairly decent sales  but regular prices are high.  We do not have a local CVS  but the one we went to in Boston when we were on vacation had a very crabby staff person at the register.  We mainly went there because it was a convenient location  but otherwise I would not have gone back to that one in particular.",
            "CVS has many convenient locations. CVS Pharmacy is a preferred provider with my drug prescription plan. CVS has the best money-saving incentives  and weekly advertised specials I regularly use.",
            "Would recommend if had convenient location to home and business.",
            "convenient locations and hours  prices are a little high but always have our prescription ready when promised",
            "CVS has many convenient locations. They are overall more expensive throughout all product ranges  however with weekly coupons (I as a very regular customer receive a 30% or 40% off coupon every Thursday!) it is more affordable.",
            "Prices are higher compared to competitors. Have convenient locations",
            "convenient locations  helpful service",
            "There are a lot of convenient locations to visit in person",
            "They are knowledgeable  fast service  convenient location",
            "CVS has very convenient locations.  Its quick and easy and the prices are competitive",
            "I like their convenient location to my home and the knowledgeable staff.  The check out process is also fairly quick.",
            "I like CVS and it has a convenient location but it always seems more expensive than other drug stores. I also cannot understand their reward program so I never end up using my reward points which is frustrating. The last experience I had was also bad- their credit card machine was down  I had no cash  so I had to walk out of the store",
            "CVS is okay  but there are other drug stores I prefer because of more convenient locations and lower sales prices regular prices.",
            "It has a convenient location  easy to get in and out  the store is organized and clean  has a large selection of products.",
            "CVS has convenient locations and a pretty good assortment of products to buy.",
            "They don\u0027t take many considerations regarding their information security but they have many convenient locations and good product selection",
            "I would say that CVS has many convenient locations and it is easy to find the products that you need.  Also there are perks for have the CVS card.",
            "great loyalty program  convenient locations and online shopping experience",
            "CVS is convenient location to Muncy  the store is clean  the employees helpful  the store is well stocked  etc.",
            "They don\u0027t really do anything to stand or so it would come down to convenient location"
          ]
        },
        {
          "phrase": "store convenient",
          "frequency": 0,
          "sem_sim": 0.8687890768051147,
          "char_sim": 0.75,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9741039276123047
            }
          ],
          "pos_tags": [
            "VERB",
            "ADJ"
          ],
          "context": []
        },
        {
          "phrase": "convenient store",
          "frequency": 7,
          "sem_sim": 0.8687890768051147,
          "char_sim": 0.8265306122448979,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9954055547714233
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "It is a convenient store that is good to shop at if you have coupons or deals",
            "It is convenient and has convenient store hours. They are usually reliable and dependable",
            "i used to fill prescriptions at CVS  but don\u0027t now because a more convenient store opened. never had a problem at CVS.",
            "I think CVS is a nice little convenient store pharmacy. I dont shop there as much as others but it\u0027s still great",
            "It is a convenient store with overpriced things.",
            "they have convenient stores and they are very helpful when filling prescriptions",
            "CVS is a very convenient store but every time I go in I feel as though they treat me as if I\u0027m a thief becuse on my race"
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9981637001037598
        }
      ],
      "topic_frequency": 0,
      "topic_tags": [
        "ADJ",
        "NOUN",
        "ADV"
      ],
      "topic_context": []
    },
    {
      "topic": "bad thing",
      "phrases": [
        {
          "phrase": "bad thing",
          "frequency": 3,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9997674822807312
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "Other than avoiding one particular location  shopping at CVS is not a bad thing to do.",
            "Cvs is like Walgreen in a lot of ways. And that\u0027s not a bad thing.",
            "I don\u0027t have any opinions about CVS but have not heard bad things"
          ]
        },
        {
          "phrase": "every thing",
          "frequency": 3,
          "sem_sim": 0.657078206539154,
          "char_sim": 0.7192286552578055,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9930130839347839
            }
          ],
          "pos_tags": [
            "DET",
            "NOUN"
          ],
          "context": [
            "CVS Caremark mail order is the most frustrating pharmacy with pushy sales tactics in the entire universe. The stores are OK \u003d to Walgreens for stuff sold in the aisles. CVS is more expensive for every thing than Walmart.",
            "they have every thing you need",
            "CVS is a great store to shop for most every thing you could need.  Plus the offer in store coupons and also cash to use on your next visit."
          ]
        },
        {
          "phrase": "familiar thing",
          "frequency": 0,
          "sem_sim": 0.6003100275993347,
          "char_sim": 0.7999999999999999,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9683718085289001
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": []
        }
      ],
      "topic_sentiment": [
        {
          "label": "NEGATIVE",
          "score": 0.9997674822807312
        }
      ],
      "topic_frequency": 3,
      "topic_tags": [
        "ADJ",
        "NOUN"
      ],
      "topic_context": [
        "Other than avoiding one particular location  shopping at CVS is not a bad thing to do.",
        "Cvs is like Walgreen in a lot of ways. And that\u0027s not a bad thing.",
        "I don\u0027t have any opinions about CVS but have not heard bad things"
      ]
    },
    {
      "topic": "pay way",
      "phrases": [
        {
          "phrase": "pay attention",
          "frequency": 1,
          "sem_sim": 0.5963841676712036,
          "char_sim": 0.8953488372093024,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9977094531059265
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN"
          ],
          "context": [
            "Its great so long aa you watch the prices and pay attention to savings options"
          ]
        },
        {
          "phrase": "pay anything",
          "frequency": 2,
          "sem_sim": 0.7576944828033447,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.5282738208770752
            }
          ],
          "pos_tags": [
            "VERB",
            "PRON"
          ],
          "context": [
            "CVS has a great reward program. Lots of incentives  coupons and cash back. It is very rare that I pay anything for my products. They accept all coupons  their coupons  extra bucks and it makes shopping a delight especially when everything is free or near free",
            "Cvs knows how to bill my sons prescriptions to Medi-cal and most pharmacies do not  so we have a huge incentive to only go to CVS because we donât pay anything."
          ]
        },
        {
          "phrase": "feeling either way",
          "frequency": 1,
          "sem_sim": 0.45727789402008057,
          "char_sim": 0.7976744186046512,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.6329211592674255
            }
          ],
          "pos_tags": [
            "VERB",
            "DET",
            "NOUN"
          ],
          "context": [
            "dont have significant feeling either way since they are not conviently located compared to others"
          ]
        },
        {
          "phrase": "feeling either way since",
          "frequency": 1,
          "sem_sim": 0.4421311616897583,
          "char_sim": 0.7976744186046512,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.7433611750602722
            }
          ],
          "pos_tags": [
            "VERB",
            "DET",
            "NOUN",
            "SCONJ"
          ],
          "context": [
            "dont have significant feeling either way since they are not conviently located compared to others"
          ]
        },
        {
          "phrase": "one way",
          "frequency": 2,
          "sem_sim": 0.5614319443702698,
          "char_sim": 0.7976744186046512,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9910004138946533
            }
          ],
          "pos_tags": [
            "NUM",
            "NOUN"
          ],
          "context": [
            "I have never shopped at CVS so I don\u0027t really have an opinion one way or another",
            "I don\u0027t really know much about them. I really cannot say one way or the other. I have noticed the few times I have been to one that their prices seem to be high."
          ]
        },
        {
          "phrase": "quick easy way",
          "frequency": 0,
          "sem_sim": 0.4109094738960266,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9987374544143677
            }
          ],
          "pos_tags": [
            "ADJ",
            "ADJ",
            "NOUN"
          ],
          "context": []
        },
        {
          "phrase": "wast way",
          "frequency": 1,
          "sem_sim": 0,
          "char_sim": 0.8953488372093024,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.77805495262146
            }
          ],
          "pos_tags": [
            "PROPN",
            "PROPN"
          ],
          "context": [
            "very convenient.  BUT they wast way too much paper in printing a receipt."
          ]
        },
        {
          "phrase": "either way",
          "frequency": 3,
          "sem_sim": 0.5491462349891663,
          "char_sim": 0.7976744186046512,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.6167083978652954
            }
          ],
          "pos_tags": [
            "DET",
            "NOUN"
          ],
          "context": [
            "I don\u0027t use and have never used CVS so I can\u0027t really make a comment either way. But I would tell them if they are wanting to try CVS to go for it.",
            "dont have significant feeling either way since they are not conviently located compared to others",
            "Neutral no strong feelings either way regarding cvs"
          ]
        },
        {
          "phrase": "way higher",
          "frequency": 2,
          "sem_sim": 0.5099043846130371,
          "char_sim": 0.7138487583094688,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9997437596321106
            }
          ],
          "pos_tags": [
            "NOUN",
            "ADJ"
          ],
          "context": [
            "CVS is typically always clean  which I appreciate  but their prices are always way higher than Walgreens. And the Staff isn\u0027t as friendly as Walgreens",
            "I donât know a lot about CVS but when I go in  the prices are usually way higher than anywhere else. Wouldnât recommend it."
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9946245551109314
        }
      ],
      "topic_frequency": 2,
      "topic_tags": [
        "VERB",
        "NOUN"
      ],
      "topic_context": [
        "Prices are inflated so you think you\u0027re getting a deal with their coupons  but you need to buy stuff in order to get the coupons. You can just go elsewhere and pay way less off the bat. Also  the stores can be dingy and cluttered and are generally just unattractive.",
        "would say then need to buy only things that  are on sale or they will pay way to much for  things at CVS"
      ]
    },
    {
      "topic": "good selection",
      "phrases": [
        {
          "phrase": "decent selection",
          "frequency": 1,
          "sem_sim": 0.8675903677940369,
          "char_sim": 0.7013979078682586,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998442530632019
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS has a pretty decent selection of products and their locations are convenient  but I find their prices to be inflated."
          ]
        },
        {
          "phrase": "large selection",
          "frequency": 11,
          "sem_sim": 0.691953182220459,
          "char_sim": 0.8448275862068966,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9990647435188293
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "With the reward program CVS offers  I get many products at a price lower than most other places. They also have great coupons and a large selection of products.",
            "CVS has a large selection of products but the prices are relatively higher than Target or Walmart. I really like the pharmacy at CVS",
            "large selection but prices are somewhat higher",
            "I go to CVS especially when I want snacks. They have a large selection of snacks. They have all kinds of candy.  It\u0027s one of the places that I go to for a snack-run.",
            "It has a convenient location  easy to get in and out  the store is organized and clean  has a large selection of products.",
            "good deals  always coupons  reward bucks  friendly staff  large selection",
            "I would say that CVS has a large selection of products and they are reasonably priced and if they need to fill a prescription it\u0027s a great place to go.",
            "CVS is a store that have great sales and carries large selection.",
            "I would say that CVS is a convenient chice and has a large selection.  I can say much the same about Walgreenâs.",
            "CVS is reliable and has a large selection to choose from.  They often have promotions that help to defray the cost of some items.",
            "They have a large selection and easy to shop at"
          ]
        },
        {
          "phrase": "amazing selection",
          "frequency": 1,
          "sem_sim": 0.7754184603691101,
          "char_sim": 0.8758620689655172,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998767971992493
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "My sister in law needed a prescription asap and they jumped through hoops to get her covered just last week! Plus they have an amazing selection of makeup  and you can grab that wine you wanted too!"
          ]
        },
        {
          "phrase": "easy parking good selection",
          "frequency": 0,
          "sem_sim": 0.7509928941726685,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.99852055311203
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "ADJ",
            "NOUN"
          ],
          "context": []
        },
        {
          "phrase": "provide good selection",
          "frequency": 1,
          "sem_sim": 0.8670358061790466,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998319745063782
            }
          ],
          "pos_tags": [
            "VERB",
            "ADJ",
            "NOUN"
          ],
          "context": [
            "It is somewhat far away  there are sources closer by. They provide good selection as do other closer by stores."
          ]
        },
        {
          "phrase": "wide selection",
          "frequency": 10,
          "sem_sim": 0.6954507827758789,
          "char_sim": 0.8034482758620689,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9997031688690186
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS has a wide selection of high quality products for your everyday needs. They have the most competitive prices. And they have a convenient health clinic for minor illnesses and services like flu shots or the common cold. They have convenient locations so you save time and money",
            "It\u0027s on the pricey side and doesn\u0027t carry a wide selection.",
            "wide selection of items  good prices and good customer service",
            "Offers good rewards program and competitive prices on a wide selection of products.",
            "I like the convenience of the location and the wide selection of products.  Their store brands are great quality too.  The only thing that is tough is the local pharmacy hours.  The store hours are great  but pharmacy a little more limited.",
            "CVS has a friendly knowledgble staff and they carry a wide selection of products. I also use their photo services. The coupons are helpful and save me money.",
            "The stores have a wide selection of products. Like a mini department store. Drugs are a bit higher than other places. Seems like they try to do too much",
            "I have shipped CVS in the past but no longer do. They carry a wide selection of products  but find they are a little more expensive than others.",
            "Has wide selection of products and excellent customer service",
            "Has a wide selection of products at a good price and offers an excellent loyalty program.  The pharmacist takes the time to answer questions."
          ]
        },
        {
          "phrase": "pretty good selection",
          "frequency": 2,
          "sem_sim": 0.8921017050743103,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998577237129211
            }
          ],
          "pos_tags": [
            "ADV",
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS is a reliable store.  Not the most impressive or the best service  but you can get in and out pretty easily.  They also have a pretty good selection of over the counter products",
            "CVS is a good drug store that has a pretty good selection of health and beauty products. They have good sales promotions  good coupons and a good in store rebate program called Extra Bucks."
          ]
        },
        {
          "phrase": "food selection",
          "frequency": 1,
          "sem_sim": 0.6178789138793945,
          "char_sim": 0.9275862068965517,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9991806149482727
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "They have a food selection of products but the pricing is too high not enough staff on board"
          ]
        },
        {
          "phrase": "good selection",
          "frequency": 37,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998401999473572
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "They are a good store and have a good selection of make up and beauty brands.",
            "I find CVS to have a good selection  but the prices are high.",
            "CVS offers many weekly specials.  CVS has a good selection of various items.  CVS participates in most insurance plans.  CVS stores are conveniently located.",
            "CVS is a convenient place to shop  has a good selections of products  and has a friendly staff. The only concern I have is with the pharmacy which too often makes mistakes or does not have the medication in stock.",
            "CVS is expensive  but does have a good selection of products and a variety of brands. There aren\u0027t any CVS\u0027 convenient to me  but when there was  I did enjoy shopping with them for everyday items  such as stockings  hair ties  women\u0027s products  and things like that  that I would need on my way to work possibly at the last minute.",
            "Fair prices good selection",
            "good selection and convenient location",
            "It\u0027s ok with good selection but their prices are too high. Also they annoyed me pretending to care so much about their customers when they \"courageously\" stopped selling cigarettes........Phony.",
            "good selection with fair pricing",
            "they have long lines  not helpful because they are so busy - have a good selection of greeting cards - pharmacy people rude most of the time - that is why i don\u0027t go there any more",
            "good selection and service",
            "good selection",
            "Itâs convenient  good selection  helpful pharmacy.",
            "They have a good selection of items for a small store. The pharmacists are knowledgeable and helpful.",
            "Have a good selection of products  easy to find  convenient and great rewards program.",
            "CVS is eh for me.  They don\u0027t have a good selection of products and they are overpriced.  And what is with those receipts!",
            "Drug store similar to others with a good selection of health and personal care products.",
            "CVS is a good drug store that has a pretty good selection of health and beauty products. They have good sales promotions  good coupons and a good in store rebate program called Extra Bucks.",
            "It\u0027s always been very convenient and they have a good selection of products",
            "Easy to park.  Easy in and out.  Very seldom a long line at check out.  Store is clean and organized.  Has a good selection of items.",
            "CVS is a reliable store.  Not the most impressive or the best service  but you can get in and out pretty easily.  They also have a pretty good selection of over the counter products",
            "CVS has a very good selection of products but need to be aware of their prices.  Good to shop there when their items are on sale.",
            "CVS offers a good selection of products and good coupons.",
            "good selection and prices",
            "The store location is very convenient to me. I like the pharmacist and value his opinion and judgement. The store has a good selection of products and the staff is knowledgable.",
            "CVS is okay. They have a good selection. They are just kind of expensive. I can get it cheaper at Target.",
            "I would say that CVS has a good selection of products but the prices are high and the sales are usually buy one at full price get another half off.",
            "The store is very conveniently located and usually has a good selection of items on sale. Their pharmacy is never too crowded and the staff is always willing to provide any help requested.",
            "They have the products I need at prices I like and good selection of everything I need",
            "they are a good retailer  a lot like Walgreens...good selection  good prices...shop the weekly flyer",
            "they have a good selection",
            "It is somewhat far away  there are sources closer by. They provide good selection as do other closer by stores.",
            "Have a good selection but can be. A bit pricey",
            "Good value  good selection  ease of shopping",
            "They have a good selection and sometimes good prices",
            "CVS is a great store. It is convenient and they have a good selection.",
            "CVS has a good selection and good sales"
          ]
        },
        {
          "phrase": "nice selection",
          "frequency": 1,
          "sem_sim": 0.8645211458206177,
          "char_sim": 0.7026198740753372,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.999840259552002
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "They have a nice selection of products and a good rewards program."
          ]
        },
        {
          "phrase": "reasonable selection",
          "frequency": 1,
          "sem_sim": 0.7470933794975281,
          "char_sim": 0.7097205873750453,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9995912909507751
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "It\u0027s an ok place to shop.  They have a reasonable selection of items but tend to be a little more expensive than other places."
          ]
        },
        {
          "phrase": "week good selection",
          "frequency": 0,
          "sem_sim": 0.9450852420865272,
          "char_sim": 0.9285714285714286,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.999825656414032
            }
          ],
          "pos_tags": [
            "NOUN",
            "ADJ",
            "NOUN"
          ],
          "context": []
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9998401999473572
        }
      ],
      "topic_frequency": 37,
      "topic_tags": [
        "ADJ",
        "NOUN"
      ],
      "topic_context": [
        "They are a good store and have a good selection of make up and beauty brands.",
        "I find CVS to have a good selection  but the prices are high.",
        "CVS offers many weekly specials.  CVS has a good selection of various items.  CVS participates in most insurance plans.  CVS stores are conveniently located.",
        "CVS is a convenient place to shop  has a good selections of products  and has a friendly staff. The only concern I have is with the pharmacy which too often makes mistakes or does not have the medication in stock.",
        "CVS is expensive  but does have a good selection of products and a variety of brands. There aren\u0027t any CVS\u0027 convenient to me  but when there was  I did enjoy shopping with them for everyday items  such as stockings  hair ties  women\u0027s products  and things like that  that I would need on my way to work possibly at the last minute.",
        "Fair prices good selection",
        "good selection and convenient location",
        "It\u0027s ok with good selection but their prices are too high. Also they annoyed me pretending to care so much about their customers when they \"courageously\" stopped selling cigarettes........Phony.",
        "good selection with fair pricing",
        "they have long lines  not helpful because they are so busy - have a good selection of greeting cards - pharmacy people rude most of the time - that is why i don\u0027t go there any more",
        "good selection and service",
        "good selection",
        "Itâs convenient  good selection  helpful pharmacy.",
        "They have a good selection of items for a small store. The pharmacists are knowledgeable and helpful.",
        "Have a good selection of products  easy to find  convenient and great rewards program.",
        "CVS is eh for me.  They don\u0027t have a good selection of products and they are overpriced.  And what is with those receipts!",
        "Drug store similar to others with a good selection of health and personal care products.",
        "CVS is a good drug store that has a pretty good selection of health and beauty products. They have good sales promotions  good coupons and a good in store rebate program called Extra Bucks.",
        "It\u0027s always been very convenient and they have a good selection of products",
        "Easy to park.  Easy in and out.  Very seldom a long line at check out.  Store is clean and organized.  Has a good selection of items.",
        "CVS is a reliable store.  Not the most impressive or the best service  but you can get in and out pretty easily.  They also have a pretty good selection of over the counter products",
        "CVS has a very good selection of products but need to be aware of their prices.  Good to shop there when their items are on sale.",
        "CVS offers a good selection of products and good coupons.",
        "good selection and prices",
        "The store location is very convenient to me. I like the pharmacist and value his opinion and judgement. The store has a good selection of products and the staff is knowledgable.",
        "CVS is okay. They have a good selection. They are just kind of expensive. I can get it cheaper at Target.",
        "I would say that CVS has a good selection of products but the prices are high and the sales are usually buy one at full price get another half off.",
        "The store is very conveniently located and usually has a good selection of items on sale. Their pharmacy is never too crowded and the staff is always willing to provide any help requested.",
        "They have the products I need at prices I like and good selection of everything I need",
        "they are a good retailer  a lot like Walgreens...good selection  good prices...shop the weekly flyer",
        "they have a good selection",
        "It is somewhat far away  there are sources closer by. They provide good selection as do other closer by stores.",
        "Have a good selection but can be. A bit pricey",
        "Good value  good selection  ease of shopping",
        "They have a good selection and sometimes good prices",
        "CVS is a great store. It is convenient and they have a good selection.",
        "CVS has a good selection and good sales"
      ]
    },
    {
      "topic": "loyalty card",
      "phrases": [
        {
          "phrase": "loyalty card",
          "frequency": 3,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9661369919776917
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "Get the loyalty card it has great deals and the selection is very varied",
            "CVS is a clean store and generally well stocked.  If you need to have a prescription filled  be sure you carve out a lot of time in your day because there is always a relatively long wait time.   As far as the store goes  CVS is coupon-driven  so be sure you obtain a loyalty card to get their coupons.  They love paper at CVS and you will even get coupons on your receipt  as well as you can obtain coupons at a store kiosk.",
            "CVS is the best damned drug store and pharmacy in the business George and they have the best prices and the most knowledgeable staff and they offer an Extra Care loyalty card rewards which saves you money! Easy to get to and in a pinch I can even walk the 3 and a half miles up and back home if I need to do so  man!"
          ]
        },
        {
          "phrase": "store card via",
          "frequency": 1,
          "sem_sim": 0.7918682183538164,
          "char_sim": 0.7295918367346939,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9826845526695251
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN",
            "ADP"
          ],
          "context": [
            "It\u0027s a very well stocked store and they send savings to your store card via e-mail."
          ]
        },
        {
          "phrase": "store card",
          "frequency": 1,
          "sem_sim": 0.8252069208086754,
          "char_sim": 0.7755102040816326,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9846005439758301
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "It\u0027s a very well stocked store and they send savings to your store card via e-mail."
          ]
        },
        {
          "phrase": "card via",
          "frequency": 1,
          "sem_sim": 0.8234568858633236,
          "char_sim": 0.6989795918367346,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.817121684551239
            }
          ],
          "pos_tags": [
            "NOUN",
            "ADP"
          ],
          "context": [
            "It\u0027s a very well stocked store and they send savings to your store card via e-mail."
          ]
        },
        {
          "phrase": "club card",
          "frequency": 1,
          "sem_sim": 0.8301204722754809,
          "char_sim": 0.7857142857142857,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9952541589736938
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS is pretty good. They carry a lot of items  and if you get a club card  the deals can be quite good  such as bogo on vitamins."
          ]
        },
        {
          "phrase": "member card",
          "frequency": 1,
          "sem_sim": 0.8386618178717944,
          "char_sim": 0.7142857142857143,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9804441332817078
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS is a good drug store and has a good rebate program when you join their club. They do waste paper with all of the coupons that print out  after you swipe you member card."
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9661369919776917
        }
      ],
      "topic_frequency": 3,
      "topic_tags": [
        "NOUN",
        "NOUN"
      ],
      "topic_context": [
        "Get the loyalty card it has great deals and the selection is very varied",
        "CVS is a clean store and generally well stocked.  If you need to have a prescription filled  be sure you carve out a lot of time in your day because there is always a relatively long wait time.   As far as the store goes  CVS is coupon-driven  so be sure you obtain a loyalty card to get their coupons.  They love paper at CVS and you will even get coupons on your receipt  as well as you can obtain coupons at a store kiosk.",
        "CVS is the best damned drug store and pharmacy in the business George and they have the best prices and the most knowledgeable staff and they offer an Extra Care loyalty card rewards which saves you money! Easy to get to and in a pinch I can even walk the 3 and a half miles up and back home if I need to do so  man!"
      ]
    },
    {
      "topic": "good assortment",
      "phrases": [
        {
          "phrase": "good assortment",
          "frequency": 3,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998430013656616
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS has convenient locations and a pretty good assortment of products to buy.",
            "CVS has a good assortment of products  but I feel like they are always trying to rope me into buying items I don\u0027t need  and they have all these coupons and \"rewards\" but it is too complicated to juggle and remember them all.",
            "CVS is more than a drug store. They have a good assortment of just about anything you need selling quality goods at very nice prices"
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9998430013656616
        }
      ],
      "topic_frequency": 3,
      "topic_tags": [
        "ADJ",
        "NOUN"
      ],
      "topic_context": [
        "CVS has convenient locations and a pretty good assortment of products to buy.",
        "CVS has a good assortment of products  but I feel like they are always trying to rope me into buying items I don\u0027t need  and they have all these coupons and \"rewards\" but it is too complicated to juggle and remember them all.",
        "CVS is more than a drug store. They have a good assortment of just about anything you need selling quality goods at very nice prices"
      ]
    },
    {
      "topic": "save money",
      "phrases": [
        {
          "phrase": "taking insurance money",
          "frequency": 1,
          "sem_sim": 0.5537942051887512,
          "char_sim": 0.7391304347826086,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.7981947660446167
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "I do not like CVS. They try to police your medicine. They refuse to let you pay out of pocket if your insurance chooses not to cover your prescription. they call it insurance fraud  which is stupid. That\u0027s like saying that if you choose to pay for expenses after your house burns down instead of taking insurance money  that you are committing fraud."
          ]
        },
        {
          "phrase": "save money",
          "frequency": 10,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9871343374252319
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN"
          ],
          "context": [
            "Cvs is a great place to shop. They have an app that has coupons that can help you save money. They have up to date products for anything youâre looking for. They have great sales. They are conveniently located.",
            "Cvs has a reward program that helps you save money on prescriptions and other items",
            "I like shopping at CVS because you save money at the checkout counter when you have accumulated points on your cvs card. CVS tracks your purchases and sends out coupons of products on sale. You can also scan your card at the checkout counter or enter your email address.",
            "CVS is friendly  convenient and trustworthy. I like how you can earn extra bucks in different ways. I always save money when shopping here using coupons and extra bucks.",
            "I like that they have so many coupons and rewards that help me save money.",
            "It depends on what my friend is shopping for. CVS prices tend to be higher. Probably because of the rewards program. If they were looking to save money  I would suggest elsewhere.",
            "CVS has a wide variety of products that are competitively priced. They have a good customer rewards program which helps save money. I have had prescriptions filled there in the past and have been satisfied with the service.",
            "shop at cvs and get a cvs card to save money",
            "Cvs offers variety of products and not only do you save money but they help you build up points to get free stuff. I\u0027m always receiving coupons and savings when I shop there.",
            "With the rewards program  I can save money."
          ]
        },
        {
          "phrase": "money go",
          "frequency": 1,
          "sem_sim": 0.6929792761802673,
          "char_sim": 0.7020301954662086,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9865677952766418
            }
          ],
          "pos_tags": [
            "NOUN",
            "VERB"
          ],
          "context": [
            "They are the same as any of the others - if they offer it for less money go with them"
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9871343374252319
        }
      ],
      "topic_frequency": 10,
      "topic_tags": [
        "VERB",
        "NOUN"
      ],
      "topic_context": [
        "Cvs is a great place to shop. They have an app that has coupons that can help you save money. They have up to date products for anything youâre looking for. They have great sales. They are conveniently located.",
        "Cvs has a reward program that helps you save money on prescriptions and other items",
        "I like shopping at CVS because you save money at the checkout counter when you have accumulated points on your cvs card. CVS tracks your purchases and sends out coupons of products on sale. You can also scan your card at the checkout counter or enter your email address.",
        "CVS is friendly  convenient and trustworthy. I like how you can earn extra bucks in different ways. I always save money when shopping here using coupons and extra bucks.",
        "I like that they have so many coupons and rewards that help me save money.",
        "It depends on what my friend is shopping for. CVS prices tend to be higher. Probably because of the rewards program. If they were looking to save money  I would suggest elsewhere.",
        "CVS has a wide variety of products that are competitively priced. They have a good customer rewards program which helps save money. I have had prescriptions filled there in the past and have been satisfied with the service.",
        "shop at cvs and get a cvs card to save money",
        "Cvs offers variety of products and not only do you save money but they help you build up points to get free stuff. I\u0027m always receiving coupons and savings when I shop there.",
        "With the rewards program  I can save money."
      ]
    },
    {
      "topic": "good value",
      "phrases": [
        {
          "phrase": "great value",
          "frequency": 4,
          "sem_sim": 0.8898903727531433,
          "char_sim": 0.7143383447379097,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.999880313873291
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "great service and great value",
            "I can get great value at CVS being a frequent rewards member.",
            "I prefer to shop at independent pharmacies when possible. CVS has a great selection of items but isnât a great value.",
            "CVS - friendly  caring  great loyalty program  great value"
          ]
        },
        {
          "phrase": "good value anything",
          "frequency": 0,
          "sem_sim": 0.8758159279823303,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.999850869178772
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "PRON"
          ],
          "context": []
        },
        {
          "phrase": "good value",
          "frequency": 14,
          "sem_sim": 1.0,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998570084571838
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS offers good value if you know their sales.  With their extra buck refunds it becomes an even better value.",
            "I don\u0027t find CVS to be a good value at all.",
            "The stores are very clean  the employees are knowledgeable  the product selection is  and they offer good value!!!",
            "It\u0027s convenient and they have good values",
            "good value",
            "CVS has good value with extra care card",
            "CVS agood relater to purchase item for the whole famely their prise someway good value for the money.",
            "Cvs is reliable and good value for money",
            "Convenient and helpful staff and good value.",
            "They offer a wide variety of quality merchandise at a good value and it is easy to get personal assistance.",
            "The sale items are a good value.  Anything not on sale is a little pricey.",
            "good value when sales",
            "Itâs good value  convenient  with large variety",
            "Joining their cvs extra program gives you a good value"
          ]
        },
        {
          "phrase": "better value",
          "frequency": 4,
          "sem_sim": 0.8456417322158813,
          "char_sim": 0.7147250827605914,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9994925260543823
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "positive shop because of location convenience variety Costco is better value  but less convenient  longer lines",
            "The pharmacy staff is excellent and very helpful.  The store overall is more expensive than all other personal care stores  and their prescriptions are also more expensive than most others.  The store is always clean and well laid out  but I find much better values at Meijer and MUCH bigger selection of items.",
            "CVS offers good value if you know their sales.  With their extra buck refunds it becomes an even better value.",
            "I love CVS because you can use their app coupons and paper coupons together for a single purchase  giving you better value."
          ]
        },
        {
          "phrase": "offer good value",
          "frequency": 1,
          "sem_sim": 0.8760117292404175,
          "char_sim": 1.0,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998716711997986
            }
          ],
          "pos_tags": [
            "VERB",
            "ADJ",
            "NOUN"
          ],
          "context": [
            "The stores are very clean  the employees are knowledgeable  the product selection is  and they offer good value!!!"
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9998570084571838
        }
      ],
      "topic_frequency": 14,
      "topic_tags": [
        "ADJ",
        "NOUN"
      ],
      "topic_context": [
        "CVS offers good value if you know their sales.  With their extra buck refunds it becomes an even better value.",
        "I don\u0027t find CVS to be a good value at all.",
        "The stores are very clean  the employees are knowledgeable  the product selection is  and they offer good value!!!",
        "It\u0027s convenient and they have good values",
        "good value",
        "CVS has good value with extra care card",
        "CVS agood relater to purchase item for the whole famely their prise someway good value for the money.",
        "Cvs is reliable and good value for money",
        "Convenient and helpful staff and good value.",
        "They offer a wide variety of quality merchandise at a good value and it is easy to get personal assistance.",
        "The sale items are a good value.  Anything not on sale is a little pricey.",
        "good value when sales",
        "Itâs good value  convenient  with large variety",
        "Joining their cvs extra program gives you a good value"
      ]
    },
    {
      "topic": "good drug store",
      "phrases": [
        {
          "phrase": "counter medication",
          "frequency": 2,
          "sem_sim": 0.7194921253618377,
          "char_sim": 0.19387755102040816,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.971921443939209
            }
          ],
          "pos_tags": [
            "PROPN",
            "PROPN"
          ],
          "context": [
            "CVS carries wide range of over the counter medication  however  the wait time at their pharmacies can be lengthy sometimes because they are very popular.",
            "Over the counter medications and supplies are way overpriced."
          ]
        },
        {
          "phrase": "medication filled",
          "frequency": 1,
          "sem_sim": 0.7,
          "char_sim": 0.1530612244897959,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9907167553901672
            }
          ],
          "pos_tags": [
            "NOUN",
            "VERB"
          ],
          "context": [
            "At cvs they greet you by your name with a smile.they are always quick at getting your medication filled in a timely manner."
          ]
        },
        {
          "phrase": "good drug",
          "frequency": 7,
          "sem_sim": 0.9128374566837233,
          "char_sim": 0.8775510204081632,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998294115066528
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "they have a good variety to pick from and is a good drug store",
            "CVS is a good drug store that has a pretty good selection of health and beauty products. They have good sales promotions  good coupons and a good in store rebate program called Extra Bucks.",
            "Cvs is a good drug store to go to. The prices can be expensive but they often have sales and coupons.",
            "CVS is a good drug store and has a good rebate program when you join their club. They do waste paper with all of the coupons that print out  after you swipe you member card.",
            "Its a good drugstore with many brand and private label products and is a national chain",
            "itr good drug place",
            "CVS is a good drug store  that I can order and buy personal needs."
          ]
        },
        {
          "phrase": "drug plan",
          "frequency": 2,
          "sem_sim": 0.7927302657341471,
          "char_sim": 0.7091836734693877,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9902846813201904
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "cvs does not honor my prescription drug plan. their pricing on  health and beuty aids is not very conpetitive",
            "It isnât a preferred pharmacy in my drug plan."
          ]
        },
        {
          "phrase": "another drug",
          "frequency": 4,
          "sem_sim": 0.8272443961124031,
          "char_sim": 0.7193877551020408,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9906921982765198
            }
          ],
          "pos_tags": [
            "DET",
            "NOUN"
          ],
          "context": [
            "CVS always looks at your past history to inform you about the new prescription that you are refilling.   If they see a conflict with another drug they will advise you to contact your dr. before filling your prescription",
            "just another drug store; nothing special; not available where I live.",
            "My feelings are pretty neutral.  I see CVS as another drug store chain like Walgreen  Rite Aid etc",
            "CVS offers great products and pharmaceutical support.  My insurance plan is with another drug store and that is the only reason I donât frequently shop at CVS."
          ]
        },
        {
          "phrase": "full service drug store",
          "frequency": 1,
          "sem_sim": 0.8804805886988738,
          "char_sim": 0.7908163265306123,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9914692044258118
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS has a good loyalty program with lots of discounts and store coupons and a full service drug store."
          ]
        },
        {
          "phrase": "drug store chain",
          "frequency": 1,
          "sem_sim": 0.8994334668529277,
          "char_sim": 0.8265306122448979,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9907239675521851
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "My feelings are pretty neutral.  I see CVS as another drug store chain like Walgreen  Rite Aid etc"
          ]
        },
        {
          "phrase": "use drug store",
          "frequency": 1,
          "sem_sim": 0.9138110803098094,
          "char_sim": 0.8826530612244898,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9937917590141296
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "I use drug store predominantly for prescriptions. Check insurance policy or prices. cvs is fine to use."
          ]
        },
        {
          "phrase": "insurance preferred drug store",
          "frequency": 1,
          "sem_sim": 0.8698825483419457,
          "char_sim": 0.7653061224489796,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.6377484202384949
            }
          ],
          "pos_tags": [
            "NOUN",
            "VERB",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "I\u0027m currently using Walgreens for prescription drugs because it is my insurance preferred drug store.  Previously CVS was my insurance preferred drug store.  Both companies do a good job  but a like CVS better because the staff is friendlier and more helpful and the store is less crowded  therefore  easier to get in and out quickly. The only drawback to CVS is they are not open 24 hours and Walgreens is. But I did not have any after hours emergencies.So if a had a choice I would shop at CVS for"
          ]
        },
        {
          "phrase": "basic generic drug store",
          "frequency": 1,
          "sem_sim": 0.8824388749745428,
          "char_sim": 0.8112244897959183,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9933117032051086
            }
          ],
          "pos_tags": [
            "ADJ",
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "basic generic drug store only interested in sales that tries to put up good front to get people in to spend money"
          ]
        },
        {
          "phrase": "damned drug store",
          "frequency": 1,
          "sem_sim": 0.9205946970959099,
          "char_sim": 0.8775510204081632,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9977677464485168
            }
          ],
          "pos_tags": [
            "VERB",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS is the best damned drug store and pharmacy in the business George and they have the best prices and the most knowledgeable staff and they offer an Extra Care loyalty card rewards which saves you money! Easy to get to and in a pinch I can even walk the 3 and a half miles up and back home if I need to do so  man!"
          ]
        },
        {
          "phrase": "preferred drug store",
          "frequency": 2,
          "sem_sim": 0.90789978966421,
          "char_sim": 0.846938775510204,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.7407025098800659
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "I\u0027m currently using Walgreens for prescription drugs because it is my insurance preferred drug store.  Previously CVS was my insurance preferred drug store.  Both companies do a good job  but a like CVS better because the staff is friendlier and more helpful and the store is less crowded  therefore  easier to get in and out quickly. The only drawback to CVS is they are not open 24 hours and Walgreens is. But I did not have any after hours emergencies.So if a had a choice I would shop at CVS for",
            "i go there because they are preferred drug store under my drug insurance"
          ]
        },
        {
          "phrase": "good overall drug store",
          "frequency": 1,
          "sem_sim": 0.9677947942091494,
          "char_sim": 0.8979591836734693,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9998323917388916
            }
          ],
          "pos_tags": [
            "ADJ",
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS is a good overall drug store that carries all your needs in one place.  Prices are good and fair"
          ]
        },
        {
          "phrase": "another drug store",
          "frequency": 3,
          "sem_sim": 0.9398100485607069,
          "char_sim": 0.8673469387755102,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9968838691711426
            }
          ],
          "pos_tags": [
            "DET",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "just another drug store; nothing special; not available where I live.",
            "My feelings are pretty neutral.  I see CVS as another drug store chain like Walgreen  Rite Aid etc",
            "CVS offers great products and pharmaceutical support.  My insurance plan is with another drug store and that is the only reason I donât frequently shop at CVS."
          ]
        },
        {
          "phrase": "drug insurance",
          "frequency": 2,
          "sem_sim": 0.7730817162260717,
          "char_sim": 0.7755102040816326,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.7543200850486755
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "i go there because they are preferred drug store under my drug insurance",
            "CVS values my time and communicates with me when I have a prescription filled and picked-up. I also pay the least given my drug insurance. I have available a pharmacist if I have questions. I can rely on CVS"
          ]
        },
        {
          "phrase": "drug service",
          "frequency": 1,
          "sem_sim": 0.7970420231624525,
          "char_sim": 0.7959183673469388,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9664928317070007
            }
          ],
          "pos_tags": [
            "NOUN",
            "PROPN"
          ],
          "context": [
            "My prescription drug service is through CVS.   Prescription refill and employees are awesome.  My complaint is with the robo calls to come pick up your prescription. It was difficult to get these stopped"
          ]
        },
        {
          "phrase": "typical drug store",
          "frequency": 2,
          "sem_sim": 0.9253260286486878,
          "char_sim": 0.8367346938775511,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9970448017120361
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS is a typical drug store  if one were closer I would use it. I have very little experience with CVS.",
            "I have no strong feelings about CVS. It\u0027s just a typical drug store. If it\u0027s conveniently located and you feel like it\u0027s worth the price than you can shop there"
          ]
        },
        {
          "phrase": "preferred drug",
          "frequency": 3,
          "sem_sim": 0.7832272478512355,
          "char_sim": 0.7040816326530612,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.9727239608764648
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS is my preferred drugstore. Itâs my preferred drugstore because the products I need are usually in stock and I never need to wait long in the checkout line. They also seem to be better about fixing prescription issues than many other pharmacies I have used.",
            "I\u0027m currently using Walgreens for prescription drugs because it is my insurance preferred drug store.  Previously CVS was my insurance preferred drug store.  Both companies do a good job  but a like CVS better because the staff is friendlier and more helpful and the store is less crowded  therefore  easier to get in and out quickly. The only drawback to CVS is they are not open 24 hours and Walgreens is. But I did not have any after hours emergencies.So if a had a choice I would shop at CVS for",
            "i go there because they are preferred drug store under my drug insurance"
          ]
        },
        {
          "phrase": "drug store similar",
          "frequency": 0,
          "sem_sim": 0.9286729510949583,
          "char_sim": 0.8061224489795917,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9836334586143494
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN",
            "ADJ"
          ],
          "context": []
        },
        {
          "phrase": "typical drug",
          "frequency": 3,
          "sem_sim": 0.8154131174087524,
          "char_sim": 0.6836734693877551,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9906958341598511
            }
          ],
          "pos_tags": [
            "ADJ",
            "NOUN"
          ],
          "context": [
            "CVS is a typical drugstore.  There isn\u0027t one conveniently located near me  so I don\u0027t shop there.",
            "CVS is a typical drug store  if one were closer I would use it. I have very little experience with CVS.",
            "I have no strong feelings about CVS. It\u0027s just a typical drug store. If it\u0027s conveniently located and you feel like it\u0027s worth the price than you can shop there"
          ]
        },
        {
          "phrase": "drug store carrying",
          "frequency": 1,
          "sem_sim": 0.8975863614860846,
          "char_sim": 0.7959183673469388,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9896976947784424
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN",
            "NOUN"
          ],
          "context": [
            "CVS is a traditional drug store carrying the traditional drug store items at reasonable prices."
          ]
        },
        {
          "phrase": "insurance preferred drug",
          "frequency": 1,
          "sem_sim": 0.7610280148837032,
          "char_sim": 0.6530612244897959,
          "sentiment": [
            {
              "label": "POSITIVE",
              "score": 0.8240478038787842
            }
          ],
          "pos_tags": [
            "NOUN",
            "VERB",
            "NOUN"
          ],
          "context": [
            "I\u0027m currently using Walgreens for prescription drugs because it is my insurance preferred drug store.  Previously CVS was my insurance preferred drug store.  Both companies do a good job  but a like CVS better because the staff is friendlier and more helpful and the store is less crowded  therefore  easier to get in and out quickly. The only drawback to CVS is they are not open 24 hours and Walgreens is. But I did not have any after hours emergencies.So if a had a choice I would shop at CVS for"
          ]
        },
        {
          "phrase": "drug store",
          "frequency": 54,
          "sem_sim": 0.9484247139522007,
          "char_sim": 0.9030612244897959,
          "sentiment": [
            {
              "label": "NEGATIVE",
              "score": 0.9920711517333984
            }
          ],
          "pos_tags": [
            "NOUN",
            "NOUN"
          ],
          "context": [
            "basic generic drug store only interested in sales that tries to put up good front to get people in to spend money",
            "I don\u0027t know the drug store well enough to give any advice.",
            "CVS is a traditional drug store carrying the traditional drug store items at reasonable prices.",
            "Cvs is a good drug store to go to. The prices can be expensive but they often have sales and coupons.",
            "CVS is a trustworthy drug store that deliver consistently a great shopping experience",
            "It\u0027s the same as any other drug store and it really doesn\u0027t matter which one you go to. Target has better prices than any of them though.",
            "it\u0027s like any other drug store that\u0027s all over the city",
            "CVS is a good overall drug store that carries all your needs in one place.  Prices are good and fair",
            "I have no strong feelings about CVS. It\u0027s just a typical drug store. If it\u0027s conveniently located and you feel like it\u0027s worth the price than you can shop there",
            "It\u0027s got a lot of drug store items but not somewhere that o would ship for all my needs",
            "It is just like all the other chain drug stores; nothing special or interesting",
            "My feelings are pretty neutral.  I see CVS as another drug store chain like Walgreen  Rite Aid etc",
            "This is a great little drugstore. You can find pretty much anything you need as far as items you\u0027d find in a drug store are concerned. I often find myself impulse shopping. Apart from pharmaceuticals though  some items cost more than like Target or Walmart.",
            "CVS is the best damned drug store and pharmacy in the business George and they have the best prices and the most knowledgeable staff and they offer an Extra Care loyalty card rewards which saves you money! Easy to get to and in a pinch I can even walk the 3 and a half miles up and back home if I need to do so  man!",
            "CVS is a good drug store that has a pretty good selection of health and beauty products. They have good sales promotions  good coupons and a good in store rebate program called Extra Bucks.",
            "CVS is a good drug store and has a good rebate program when you join their club. They do waste paper with all of the coupons that print out  after you swipe you member card.",
            "I have never shopped there but it is a pharmacy drug store place",
            "CVS has a good loyalty program with lots of discounts and store coupons and a full service drug store.",
            "I use drug store predominantly for prescriptions. Check insurance policy or prices. cvs is fine to use.",
            "Its an ok drug store  knida pricey",
            "CVS is fine as any drug store  they are not located near me",
            "i go there because they are preferred drug store under my drug insurance",
            "The CVS in Eden  NC has the worse customer service I have ever seen in any drug store.  You could die of old age waiting for your prescription to be ready.",
            "It is a solid drug store",
            "just another drug store; nothing special; not available where I live.",
            "CVS is a trusted drug store and located nearby.  You can get most of you prescriptions there quickly.",
            "It is a mainline drug store  but only location locally is in the Target so very limited selection of items for purchase.",
            "Fairly comprehensive but expensive drug store.",
            "I do not like dealing with any large drug stores",
            "Cvs is a convenient drug store",
            "I can\u0027t imagine any circumstances where I would bother entering into a conversation with a friend about CVS or any other drug store",
            "As good as any drug store",
            "It is a typical pharmacy or drug store  nothing in particular",
            "I\u0027m currently using Walgreens for prescription drugs because it is my insurance preferred drug store.  Previously CVS was my insurance preferred drug store.  Both companies do a good job  but a like CVS better because the staff is friendlier and more helpful and the store is less crowded  therefore  easier to get in and out quickly. The only drawback to CVS is they are not open 24 hours and Walgreens is. But I did not have any after hours emergencies.So if a had a choice I would shop at CVS for",
            "I like cvs its one of my favorite drug stores.",
            "CVS is more than a drug store. They have a good assortment of just about anything you need selling quality goods at very nice prices",
            "Great drug store and have decent sales weekly",
            "they have a good variety to pick from and is a good drug store",
            "I like CVS and it has a convenient location but it always seems more expensive than other drug stores. I also cannot understand their reward program so I never end up using my reward points which is frustrating. The last experience I had was also bad- their credit card machine was down  I had no cash  so I had to walk out of the store",
            "It\u0027s an okay drug store  but certainly not my first choice.",
            "CVS is okay  but there are other drug stores I prefer because of more convenient locations and lower sales prices regular prices.",
            "It\u0027s a long-standing drug store and pharmacy that many customers find convenient.",
            "I am of the belief that regardless of how I describe CVS is of no useful benefit to those other than the sponsors of this survey.  If CVS were no longer in business  there would not be a shortage of other drug stores from which to shop.",
            "Nice store. Reputable. Been there most of my life. Trustworthy. But most items are way overpriced and I can get them cheaper at Walmart. And I don\u0027t go to a drug store to buy groceries! or unhealthy snack foods. I recently got a pneumonia shot at the clinic there and it was very little space for the number of people that were in line for meds  etc. They could do well to expand their space around the pharmacy counter and make the shot dispensing for private! Less toys and junk and more medical se",
            "It\u0027s a drug store that has some other products that all cost more than you could get elsewhwere.",
            "CVS is the same as any big box drug store",
            "CVS is a typical drug store  if one were closer I would use it. I have very little experience with CVS.",
            "cvs is a decent drug store to get prescriptions filled but somewhat expensive",
            "I would tell my friends  CVS is easy to find. They carry all you need from a drug store. They fill your prescriptions in a short period of time. You can freely ask the pharmacist questions.",
            "CVS offers great products and pharmaceutical support.  My insurance plan is with another drug store and that is the only reason I donât frequently shop at CVS.",
            "It\u0027s an average drug store that\u0027s too far away from my home",
            "CVS is a good drug store  that I can order and buy personal needs.",
            "Your neighborhood drug store",
            "Cvs is a drug store that offers a variety of merchandise. I find them to be overpriced"
          ]
        }
      ],
      "topic_sentiment": [
        {
          "label": "POSITIVE",
          "score": 0.9997140169143677
        }
      ],
      "topic_frequency": 5,
      "topic_tags": [
        "ADJ",
        "NOUN",
        "NOUN"
      ],
      "topic_context": [
        "they have a good variety to pick from and is a good drug store",
        "CVS is a good drug store that has a pretty good selection of health and beauty products. They have good sales promotions  good coupons and a good in store rebate program called Extra Bucks.",
        "CVS is a good drug store and has a good rebate program when you join their club. They do waste paper with all of the coupons that print out  after you swipe you member card.",
        "Cvs is a good drug store to go to. The prices can be expensive but they often have sales and coupons.",
        "CVS is a good drug store  that I can order and buy personal needs."
      ]
    }
  ]
}

export const NGRAM_MOCK_DATA = {
  ngrams : [
    {
      gramWeight: "1-gram",
      phrase: 'cash',
      frequency: 24,
      pos_tags: ["verb","noun"],
      context : [
        { contextId: 1, context: "good cash back offers" },
        { contextId: 2, context: "good cash back rewards" },
        { contextId: 3, context: "It’s a good store for cash back" },
        { contextId: 4, context: "love CVS coupons and cash back progrram" },
        { contextId: 5, context: "Very attractive store but cashiers too slow", },
        { contextId: 6, context: "convenient locations higher price OK sales good cash dollar program",},
        { contextId: 7, context: "It's sucks the prices are outrageous and the cashiers there are rude", },
        { contextId: 8, context: "the go to place when you are out of something and want to get the longest cash register receipts", },
        { contextId: 9, context: "CVS has good cashiers and customer service they also have a variety of products but they aren't open 24 hours", },
        { contextId: 10, context: "I've only visied this store once but the cashier was welcoming and I found what I was looking for easily.", },
        { contextId: 11, context: "excellent pharmacy at least our local CVS however lack of emplyees in the store to assist customers usually just one at cash register", },
        { contextId: 12, context: "Good deals with coupons and cash rewards. also good quality products and variety of items to choose from like groceries health aids etc", },
        { contextId: 13, context: "They have convenient locations and the prices aren't too bad. Cashiers are friendly and store employees can help you find what you need.", },
        { contextId: 14, context: "CVS is a great store to shop for most every thing you could need. Plus the offer in store coupons and also cash to use on your next visit.",},
        { contextId: 15, context: "CVS is overpriced but very convenient and purchase there when product is on sale or have a reward to cash in. Pharmacy is convenient and priced same as other pharmacies with my insurance", },
        { contextId: 16, context: "I would not talk about CVS the one we have near us the pharmacy people are rude its always a mess and there is hardly ever anyone to help and no one at the cashier.", },
        { contextId: 17, context: "CVS has a great reward program. Lots of incentives coupons and cash back. It is very rare that I pay anything for my products. They accept all coupons their coupons extra bucks and it makes shopping a delight especially when everything is free or near free",},
        { contextId: 18, context: "enjoy the coupons; coupons on my phone; don't like the long strip of paper coupons from cash register sometimes wasteful; usually have product I need; the store layout doesn't change so I can find things; clean; helpful personnel; pharmacy is a plus; open 24 7; flu shots offered", },
        { contextId: 19, context: "The prices are high unless something is on sale. The woman cashier is very friendly and nice. The pharmacy is hit and miss. It's fine unless they get crowded. Parking is never a problem. Their various discounts are a bit of a joke.", },
        { contextId: 20, context: "They have rewards and coupons but they expire quickly. You have to go back very frequently to use them up or you lose them. I stopped going there because they had too many rules and cashiers are not trained and don’t know how to help. They also made mistakes and dispensed someone else’s medication to me.", },
        { contextId: 21, context: "I like CVS and it has a convenient location but it always seems more expensive than other drug stores. I also cannot understand their reward program so I never end up using my reward points which is frustrating. The last experience I had was also bad- their credit card machine was down I had no cash so I had to walk out of the store", },
        { contextId: 22, context: "Whenever I go to CVS I can find what I want quickly and easily. The stores are organized and clean. The cashiers are always helpful and friendly. There are two CVS stores within ten minutes of me so it's a convenient place to shop. Between CVS coupons Extra Bucks Rewards BOGO offers and sale prices I get a lot of good deals. It's interesting to see the seasonal products they carry.", },
        { contextId: 23, context: "Earlier in the year my husband got the flu and I had to fill two prescriptions quickly--as he was very ill. There is a CVS right by my house so I decided to go there to fill the prescriptions. When I got the total I was shocked at how expensive the two drugs were. The cashier was so nice! She helped me get a $90 discount. I will always have some loyalty to CVS because of her actions.", },
        { contextId: 24, context: "CVS is a clean and well organized store. Employees are always friendly and helpful. It's a little on the pricey side but they do occasionally have good sales and the rewards program is not bad. If deals are loaded to your rewards card though you have to be on top of things at checkout. If you're not paying attention cashiers will be selective in the coupons they apply and sometimes will only apply loaded coupons if you ask - even though they can see them on the register when they finish" }
      ]
    },
    {
      gramWeight: "2-gram",
      phrase: 'store layout',
      frequency: 8,
      pos_tags: ["noun","verb"],
      context : [
        { contextId: 25, context: "Nice store layout", },
        { contextId: 26, context: "Bad store layout and expensive", },
        { contextId: 27, context: "Good retailer many locations convenient store layout can be confusing good pharmacist and associates", },
        { contextId: 28, context: "Periodically shop at CVS - just more familiar with alternative store layouts and products", },
        { contextId: 29, context: "Our CVS is conveniently located the store layout is simple to find things in and we like the product selection including the CVS brand. The pharmacy is also staffed byknowledgeable friendly employees.", },
        { contextId: 30, context: "I like CVS have found their pharmacy staff to be professional and helpful through the years. Comfortable with my local store layout. Not at all happy to notice the subtle introduction of self checkout and will seriously consider shopping elsewhere if this gains traction",},
        { contextId: 31, context: "enjoy the coupons; coupons on my phone; don't like the long strip of paper coupons from cash register sometimes wasteful; usually have product I need; the store layout doesn't change so I can find things; clean; helpful personnel; pharmacy is a plus; open 24 7; flu shots offered", },
        { contextId: 32, context: "CVS feels like a family owned small business. Store layout is consistent no matter what location you shop at making it easy to find what you're looking for quickly. Employees are always friendly and helpful when needed. prescriptions can be filled on the spot while waiting or ordered ahead and picked up at the drive thru window." },
        
      ]
    },
    {
      gramWeight: "3-gram",
      phrase: 'great customer service',
      frequency: 13,
      pos_tags: ["noun","verb"],
      context : [
        { contextId: 33, context: "Great customer service", },
        { contextId: 34, context: "Great customer service", },
        { contextId: 35, context: "Good sales and great customer service", },
        { contextId: 36, context: "Great customer service but very pricy at times", },
        { contextId: 37, context: "great sales bonus rewards convenient great customer service", },
        { contextId: 38, context: "Cvs has great customer service and the pharmacy is the beat",},
        { contextId: 39, context: "Cvs has great customer service and they care about your health", },
        { contextId: 40, context: "Great customer service and friendly employees who are helpful and polite", },
        { contextId: 41, context: "Great customer service & many options of most products. Sale items are priced well.", },
        { contextId: 42, context: "They have friendly and attentive employees. They give great customer service at their pharmacy.", },
        { contextId: 43, context: "They provide great customer service and are always willing to assist and direct when needed.", },
        { contextId: 44, context: "They have clean stores very organized and tidy and products are easy to find great customer service",},
        { contextId: 45, context: "cvs has great customer service from their in store employees. the employees have knowledge on the products they carry. the time to wait in line to make a purchase is not long. the time to wait for a prescription has never been more than 15 minutes if i did not have it ready a head of time." },
        
      ]
    }
  ]
}

export const SEED_FILE_MOCK_DATA = `Name,Type,S/E,Implemented_by,Specified_by,Specification_Document,Description,Categories,Phrase,,,,,,
  gift card,,negative,,,,,,gift card,card via,care card,club card,loyalty card,member card,reward card`
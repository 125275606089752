import { combineReducers } from 'redux'
import TopicPalette from './TopicPalette';
import SelectedPaletteType from './SelectedPaletteType'
import ActiveTP from './ActiveTP'
import SelectedTopic from './SelectedTopic'
import GetTopicsData from './GetTopicsData'
import ActiveHighlight from './ActiveHighlights'
import LoadATD from './LoadATD'
import TopicList from './TopicList'
import SetSaveLocation from './SetSaveLocation'

export default combineReducers({
  topicPalettes: TopicPalette,
  selectedPalette: SelectedPaletteType,
  isActive: ActiveTP,
  isActiveHighlight: ActiveHighlight,
  selectedTopic: SelectedTopic,
  topicData: GetTopicsData,
  atdLoading: LoadATD,
  topicList: TopicList,
  saveLocation: SetSaveLocation
});
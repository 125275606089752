import React from 'react'
import styles from './OutlierTable.module.css'
import logo from "../../images/restore_icon.svg"

const OutlierTable = ({ data, restoreOutlier }) => {
  return (
    <div className={styles.OutlierTable}>
      <h3>OUTLIERS:</h3>
      <table>
        <thead>
          <tr>
            <th>Topic</th>
            <th>Impact</th>
            <th>Frequency</th>
            <th className={styles.icon}>Restore</th>
          </tr>
        </thead>
        <tbody>
          {data.map(item => {
            return (
              <tr key={item.topic}>
                <td>{item.topic}</td>
                <td>{item.impact}</td>
                <td>{item.frequency}</td>
                <td className={styles.icon} onClick={() => restoreOutlier(item)}><img src={logo} width="20px"/></td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
}

export default OutlierTable;

import React from "react";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import clsx from "clsx";
import { NEGATIVE_EMOTIONS, POSITIVE_EMOTIONS } from "../../../../Constants";

class TopicCard extends React.Component {
  sentimentWithEmotion = NEGATIVE_EMOTIONS.concat(POSITIVE_EMOTIONS).includes(
    this.props.item.sentiment.toLowerCase()
  );

  render() {
    return (
      <React.Fragment>
        <Tooltip
          title={
            this.props.item.emotion
              ? this.props.item.emotion[0].toUpperCase() +
                this.props.item.emotion.slice(1)
              : this.sentimentWithEmotion
              ? this.props.item.sentiment[0] +
                this.props.item.sentiment.toLowerCase().slice(1)
              : "No Emotion"
          }
        >
          <Chip
            id={`topic_card_${this.props.item.id}`}
            clickable
            className={clsx("badge", {
              seedTopic: this.props.item.isSeedTopic,
              phraseDropped:
                this.props.phraseDroppedOnId === this.props.item.id,
              "atd-topic-enter-active": this.props.addedNewTopic,
              "atd-topic-exit-active": this.props.deletedTopic,
            })}
            avatar={
              this.props.item.isCurated ? (
                <Avatar>
                  <i className="fa fa-user" aria-hidden="true"></i>
                </Avatar>
              ) : null
            }
            label={
              <>
                {this.props.item.name}
                {this.props.item.emotion || this.sentimentWithEmotion ? (
                  <span className="emotionTag">Ⓔ</span>
                ) : (
                  " | "
                )}
                {this.props.item.frequency || 0}
              </>
            }
            variant={this.props.variant}
            color={
              NEGATIVE_EMOTIONS.includes(
                this.props.item[
                  this.props.item.emotion ? "emotion" : "sentiment"
                ].toLowerCase()
              )
                ? "secondary"
                : POSITIVE_EMOTIONS.includes(
                    this.props.item[
                      this.props.item.emotion ? "emotion" : "sentiment"
                    ].toLowerCase()
                  )
                ? "primary"
                : this.props.item.sentiment === "POSITIVE"
                ? "primary"
                : this.props.item.sentiment === "NEGATIVE"
                ? "secondary"
                : "default"
            }
            onClick={() => this.props.onClick(this.props.item)}
            onDragOver={(e) => this.props.onDragOver(e)}
            onDrop={(e) => this.props.onDrop(e, this.props.item)}
            onContextMenu={(e) => this.props.onContextMenu(e, this.props.item)}
            ref={this.props.innerRef}
          />
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default React.memo(
  React.forwardRef((props, ref) => <TopicCard innerRef={ref} {...props} />)
);

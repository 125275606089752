import { SWITCH_HIGHLIGHT } from '../actions/types'
import get from 'lodash.get'

let initialState = false

export default function ActiveHighlight(state = initialState, action) {
  const value = get(action, 'value')

  switch (action.type) {
    case SWITCH_HIGHLIGHT: {
      return value
    }
    default: {
      return state;
    }
  }
}
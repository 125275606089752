import { BRAND_TOTAL_CURRENT_POST, BRAND_NET_FILTER_POST } from "../Routes";
import client from "../_helpers/client";

export const brandPostService = {
  getTotalCurrentPost,
  getNetFilterPost,
};

function getTotalCurrentPost(brandId) {
  return client
    .get(BRAND_TOTAL_CURRENT_POST, { params: { brandId: brandId } })
    .then((res) => {
      return res.data;
    });
}

function getNetFilterPost(brandId) {
  return client
    .get(BRAND_NET_FILTER_POST, { params: { brandId: brandId } })
    .then((res) => {
      return res.data;
    });
}

import "rc-tree-select/assets/index.css";
import "./TreeView.css";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import Tooltip from "rc-tooltip";
import cssAnimation from "css-animation";
import Tree, { TreeNode } from "rc-tree";
import { toast } from "react-toastify";

import TreeModal from "./TreeModal";
import ErrorModal from "../../../containers/Modal/ErrorModal";
import client from "../../../_helpers/client";
import { toCapitalize, updateNoBrandDetails, replaceSpaces, handleCsvFileDownload, handleExcelFileDownload, handleJSONFileDownload } from "../../../_helpers/utils";
import { DELETE_CATALOGUE, GET_BRAND_STATUS, GET_CATALOGUE, IS_CATALOGUE_EXISTS, RENAME_CATALOGUE } from "../../../Routes";

import {
  BRAND,
  MODEL,
  COPY,
  CLASSIFIER,
  FOLDER,
  FILE,
  RENAME,
  DELETE,
  CREATE,
  CREATE_FOLDER,
  SUCCESS,
  WARN,
  DEFAULT,
  NEW,
  EXPORT,
  EXPORT_CLASSIFIER_NAMES,
  EXPORT_ID_NAMES,
  LISTVIEW,
  DISCOVER_TOPIC,
  IMPORT,
  EMPTY_STRING,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  ERROR_MESSAGE,
  ERROR_IN_PROCESS,
  CALCULATED_CLASSIFIER,
  REGULAR_CLASSIFIER,
  VIRTUAL_CLASSIFIER,
  CATALOGUE
} from "../../../Constants";
import {
  GET_BRANDS,
  CREATE_BRAND,
  RENAME_BRAND,
  DELETE_BRAND,
  GET_MODELS,
  CREATE_MODEL,
  RENAME_MODEL,
  DELETE_MODEL,
  GET_CLASSIFIERS,
  CREATE_CLASSIFIER,
  RENAME_CLASSIFIER,
  DELETE_CLASSIFIER,
  GET_COLLECTIONS,
  RENAME_COLLECTION,
  DELETE_COLLECTION,
  CREATE_COLLECTION,
  DISCOVER_TOPIC_API,
  EXPORT_JSON_MODEL,
  EXPORT_CSV_MODEL,
  EXPORT_CLASSIFIERS,
  EXPORT_CLASSIFIERS_IDNAMES,
  COPY_MODEL_TREEDATA,
  COPY_CLASSIFIER_TREEDATA,
  COPY_CLASSIFIER,
  COPY_MODEL,
  IMPORT_CLASSIFIERS,
  MOVE_BRAND,
  MOVE_MODEL,
  MOVE_CLASSIFIER,
  MOVE_COLLECTION,
  IS_BRAND_EXISTS,
  IS_MODEL_EXISTS,
  IS_CLASSIFIER_EXISTS,
  IS_COLLECTION_EXISTS,
  GET_EXPORT_CLASSIFIER_NAMES,
  GET_DECOODA_CHINESE_ID
} from "../../../Routes";

const STYLE = `
.collapse {
  overflow: hidden;
  display: block;
}
.collapse-active {
  transition: height 0.3s ease-out;
}
`;

function animate(node, show, done) {
  let height = node.offsetHeight;
  return cssAnimation(node, "collapse", {
    start() {
      if (!show) {
        node.style.height = `${node.offsetHeight}px`;
      } else {
        height = node.offsetHeight;
        node.style.height = 0;
      }
    },
    active() {
      node.style.height = `${show ? height : 0}px`;
    },
    end() {
      node.style.height = "";
      done();
    }
  });
}

const animation = {
  enter(node, done) {
    return animate(node, true, done);
  },
  leave(node, done) {
    return animate(node, false, done);
  }
};

class TreeView extends Component {
  toastId = null;
  decoodachinesId = null;

  state = {
    contextPosX: 0,
    contextPosY: 0,
    cmVisible: false,
    cmNodeType: null,
    cmModalStatus: false,
    modelData: EMPTY_OBJECT,
    itemLabel: EMPTY_STRING,
    val: EMPTY_STRING,
    setModelOpen: false,
    bindedBrandsmodels: EMPTY_OBJECT,
    autoExpandParent: true,
    nodeId: null,
    modalTreeData: EMPTY_ARRAY,
    draggedNode: null,
    onDroppedNode: null,
    switcherIcon: <i className="fa fa-plus-square"></i>,
    modelTreeLoading: false,
    rootId: null,
    progress: 0,
  };

  UNSAFE_componentWillMount() {
    this.getContainer();
  }

  componentDidMount() {
    client.get(GET_DECOODA_CHINESE_ID).then( res => {
      if(res.data.status === SUCCESS) {
        const dcid = res.data.response;
        this.decoodachinesId = dcid;
      }
    })
  }

  componentWillUnmount() {
    if (this.cmContainer) {
      ReactDOM.unmountComponentAtNode(this.cmContainer);
      document.body.removeChild(this.cmContainer);
      this.cmContainer = null;
      this.setState({
        ...this.state,
        cmVisible: !this.state.cmVisible
      });
    }
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({ ...this.state, rootId: this.props.rootId })
  }

  //On tree node expand
  onLoadData = (treeNode, type) => {
    const isAutoRegex = treeNode.props["data-id"] === "-4" 
    ? true 
    : (treeNode.props["data-root"] === -4) ? true : false

    return new Promise(resolve => {
      let treeUrl =
        type === BRAND
          ? GET_BRANDS
          : type === MODEL
            ? GET_MODELS
            : type === CLASSIFIER
              ? GET_CLASSIFIERS
              : type === CATALOGUE
                ? GET_CATALOGUE
                : GET_COLLECTIONS;

      client
        .get(treeUrl, { params: {
          parentId: treeNode.props.eventKey,
          isAutoRegex: isAutoRegex,
          sortColumn: this.props.treeSortBy,
          sortOrder: this.props.treeSortOrder
        }})
        .then(res => {
          if (res.data.status === SUCCESS) {
            this.props.handleDynamicNodes(
              treeNode.props.eventKey,
              res.data.response
            );
            resolve();
          } else {
            if (res.data.status === WARN) toast.warn(res.data.message);
            else toast.error(res.data.message);
            resolve();
          }
        })
        .catch(error => {
          if (error.response) toast.error(error.response.data.message);
        });
    });
  };

  getContainer() {
    if (!this.cmContainer) {
      this.cmContainer = document.createElement("div");
      document.body.appendChild(this.cmContainer);
    }
    return this.cmContainer;
  }


  getBrandStatus=(id) => {
    let brandStatus = 'Created';
    client.get(GET_BRAND_STATUS, { params: { 
      brandId: id } })
        .then( res => {
          if(res.data.status === SUCCESS)
          brandStatus = res.data.response;
        }).catch(error => {
          console.log(error)
        });
        return brandStatus
  }

  // Render Tree Conttext-Menu
  renderCm(treeNode, treeType) {
    
    this.setState({
      ...this.state,
      nodeId:
        treeType === BRAND
          ? treeNode.node.props["data-brandid"]
          : treeNode.node.props.eventKey
    });

    let nodeType = this.state.cmNodeType,
      nodeTypeList = EMPTY_ARRAY;

    const type = toCapitalize(this.props.type);
    const nodeKey = parseInt(treeNode.node.props.eventKey);
    const nodeRootId = parseInt(treeNode.node.props["data-root"]);
    let isTopicDiscovered = false;

    if(treeType === BRAND && nodeType !== FOLDER) {
      if(treeNode.node.props["data-status"] === 1)
        isTopicDiscovered = true;
      else {
        const bs = this.getBrandStatus(treeNode.node.props["data-brandid"]);
        isTopicDiscovered = bs === "Rescore" ? true : false
      }
        
    }
      

    if (nodeType === FOLDER) {
      //Tree ContextMenu options for Folder
      switch (treeType) {
        //Model Options
        case MODEL:
          if (nodeKey === 0 || nodeKey === 1 || nodeKey === this.decoodachinesId)
            nodeTypeList = [
              {
                list: NEW,
                child: [
                  { list: FOLDER },
                  { list: type },
                  { list: "Empty Model" }
                ]
              }
            ];
          else
            nodeTypeList = [
              {
                list: NEW,
                child: [
                  { list: FOLDER },
                  { list: type },
                  { list: "Empty Model" }
                ]
              },
              { list: RENAME },
              { list: DELETE }
            ];
          break;
        //Classifier Options
        case CLASSIFIER:
          if (nodeKey === -2 || nodeKey === 0 || nodeKey === 1 || nodeKey === -3 || nodeKey === this.decoodachinesId) {
            if(nodeKey === -3)
              nodeTypeList = [
                { list: NEW, child: [{ list: FOLDER }, { list: type }, { list: IMPORT + " " + CLASSIFIER }] },
                { list: EXPORT },
                { list: EXPORT_ID_NAMES }
              ];
            else if (nodeKey === -2)
              nodeTypeList = [
                { list: NEW, child: [{ list: FOLDER }, { list: type }] }, 
                { list: EXPORT_ID_NAMES }
              ];
            else if(nodeKey === 0) {
              nodeTypeList = [
                { list: NEW, child: [{ list: FOLDER }, { list: type }, { list: IMPORT + " " + CLASSIFIER }] }, 
                { list: EXPORT },
                { list: EXPORT_ID_NAMES }
              ]
            }
            else
              nodeTypeList = [
                { list: NEW, child: [{ list: FOLDER }, { list: type },] }, 
                { list: EXPORT_ID_NAMES }
              ];
          } else {
            if(nodeRootId === -3) 
            nodeTypeList = [
              { list: NEW, child: [ { list: FOLDER }, { list: type }, { list: IMPORT + " " + CLASSIFIER }]},
              { list: RENAME }, 
              { list: DELETE},
              { list: EXPORT },
              { list: EXPORT_ID_NAMES }
            ];
            else if(nodeRootId === -2) 
            nodeTypeList = [
              { list: NEW, child: [ { list: FOLDER }, { list: type } ]},{ list: RENAME }, 
              { list: DELETE},
              { list: EXPORT_ID_NAMES }
            ];
            else if (nodeRootId === 0)
              nodeTypeList = [
                { list: NEW, child: [ { list: FOLDER }, { list: type }, { list: IMPORT + " " + CLASSIFIER }]},
                { list: RENAME },
                { list: DELETE },
                { list: EXPORT },
                { list: EXPORT_ID_NAMES },
                { list: LISTVIEW }
              ];
            else {
              nodeTypeList = [
                { list: NEW, child: [{ list: FOLDER }, { list: type },] },
                { list: RENAME },
                { list: DELETE },
                { list: EXPORT_ID_NAMES },
              ];
            }
          }
          break;
        // Catalogue Options
        case CATALOGUE:
          nodeTypeList = [
            { list: NEW, child: [{ list: type }] }
          ];
          break;
        //Default Node Options
        default:
          if (nodeKey === 0 || nodeKey === 1 || nodeKey === this.decoodachinesId)
            nodeTypeList = [
              { list: NEW, child: [{ list: FOLDER }, { list: type }] }
            ];
          else
            nodeTypeList = [
              { list: NEW, child: [{ list: FOLDER }, { list: type }] },
              { list: RENAME },
              { list: DELETE }
            ];
          break;
      }
    } else {
      //Tree ContextMenu options for File
      switch (treeType) {
        //Brand Options
        case BRAND:
          if (isTopicDiscovered)
            nodeTypeList = [
              { list: RENAME },
              { list: DELETE },
            ];
          else {
            nodeTypeList = [{ list: RENAME }, { list: DELETE }];
          }
          break;
        //Model Options
        case MODEL:
          if (treeNode.node.props["data-shared"]) return;
          else
            nodeTypeList = [
              { list: RENAME },
              { list: DELETE },
              { list: COPY },
              { list: EXPORT },
              { list: EXPORT_CLASSIFIER_NAMES }
            ];
          break;
        //Classifier Options
        case CLASSIFIER:
          if (treeNode.node.props["data-shared"]) return;
          else if(nodeRootId === -1 || nodeRootId === 1 || nodeRootId === this.decoodachinesId)
            nodeTypeList = [{ list: RENAME }, { list: DELETE },];
          else
            nodeTypeList = [{ list: RENAME }, { list: DELETE }, { list: COPY }];
          break;
        //Default Options
        default:
          nodeTypeList = [{ list: RENAME }, { list: DELETE }];
          break;
      }
    }

    const cmLoop = data => {
      return data.map((item, i) => {
        if (item.child) {
          return (
            <li key={i}>
              <span className="contextmenu-parent">{item.list}</span>
              <ul className="contextmenu-ul-ul">{cmLoop(item.child)}</ul>
            </li>
          );
        } else {
          return (
            <li
              onClick={() => {
                this.treeContextEvents(item, this.props.type, treeNode);
              }}
              key={i}
            >
              <span>{item.list}</span>
            </li>
          );
        }
      });
    };

    if (this.toolTip) {
      if (this.cmContainer != null) {
        ReactDOM.unmountComponentAtNode(this.cmContainer);
        this.toolTip = null;
      }
    }

    this.toolTip = (
      <Tooltip
        trigger="click"
        placement="bottomRight"
        prefixCls="rc-tree-contextmenu"
        defaultVisible
        overlay={<ul className="contextmenu-ul">{cmLoop(nodeTypeList)}</ul>}
      >
        <span />
      </Tooltip>
    );

    const container = this.getContainer();
    Object.assign(this.cmContainer.style, {
      position: "absolute",
      left: `${this.state.contextPosX + 0}px`,
      top: `${this.state.contextPosY + 0}px`
    });

    ReactDOM.render(this.toolTip, container);
  }

  //On Tree Node Click
  onSelect = (info, e) => {
    if(e.nativeEvent.shiftKey && info.length) {
      if(this.props.type === CLASSIFIER) {  
        const { rootId } = this.state;
        if( rootId !== e.node.props["data-root"]) {
          toast.warn("Cannot select different classifer type")
          return
        }
      }
      this.props.handleMultipleNodeClick(info);
    } else {
      this.setState({ ...this.state, rootId: e.node.props["data-root"] })
      this.props.handleNodeClick([e.node.props.eventKey], e.node.props["data-shared"], e.node.props["data-root"]);
    }
  };

  //On Tree Node Expand
  onExpand = expandedKeys => {
    this.props.handleExpandNode(expandedKeys);
  };

  //On Tree Node Right Click
  onRightClick = info => {
    
    //Restrict right click on Topics Folders
    if(this.props.type === 'topics')
      return;
    
    //Restrict right click on Shared Folders
    if ((info.node.props.eventKey === "-1") || (info.node.props["data-root"] === "-1"))
      return;
    
    this.setState({
      ...this.state,
      selectedKeys: [info.node.props.eventKey],
      cmVisible: !this.state.cmVisible,
      cmNodeType: info.node.props.isLeaf && info.node.props.className !== "emptyFolder" ? FILE : FOLDER,
      contextPosX: info.event.pageX,
      contextPosY: info.event.pageY,
      itemLabel: info.node.props.title
    });

    setTimeout(() => {
      this.renderCm(info, this.props.type);
    }, 200);
    
  };

  // To get dialogue tree data
  getModalTreeData = type => {
    if (type === MODEL) {
      client
        .get(COPY_MODEL_TREEDATA, {
          params: {
            parentId: "#",
            langId: 1
          }
        })
        .then(res => {
          if (res.data.status === SUCCESS)
            this.setState({
              modalTreeData: res.data.response,
              modelTreeLoading: false
            });
          else {
            if (res.data.status === WARN) toast.warn(res.data.message);
            else toast.error(res.data.message);
            this.setState({ modelTreeLoading: false });
          }
        })
        .catch(error => {
          if (error.response) {
            toast.error(error.response.data.message);
            this.setState({ modelTreeLoading: false });
          }
        });
    } else if (type === CLASSIFIER) {
      client
        .get(COPY_CLASSIFIER_TREEDATA, { params: { parentId: "#", langId: 1 } })
        .then(res => {
          if (res.data.status === SUCCESS)
            this.setState({
              modalTreeData: res.data.response,
              modelTreeLoading: false
            });
          else {
            if (res.data.status === WARN) toast.warn(res.data.message);
            else toast.error(res.data.message);
            this.setState({ modelTreeLoading: false });
          }
        })
        .catch(error => {
          if (error.response) {
            toast.error(error.response.data.message);
            this.setState({ modelTreeLoading: false });
          }
        });
    }
  };

  // Tree Context-Menu Events
  treeContextEvents = (options, type, node) => {
    let OPTIONS = options.list === "Import classifier" ? IMPORT : options.list,
        nodeName = node.node.props.title,
        nodeParentId = node.node.props["data-parent"],
        nodeType = node.node.props.isLeaf ? FILE : DEFAULT,
        brandId = node.node.props["data-brandid"],
        nodeRootId = node.node.props["data-root"];

    //Handle Export Classifier Names
    if(type === MODEL && options.list === EXPORT_CLASSIFIER_NAMES) {
      const mData = {
        itemTitle: "Selct Export Type",
        itemId: this.state.selectedKeys,
        itemEvent: EXPORT_CLASSIFIER_NAMES,
        nodeName: nodeName
      };
      this.setState({
        ...this.state,
        modelData: mData,
        modelTreeLoading: true,
        cmModalStatus: true
      });
      ReactDOM.unmountComponentAtNode(this.cmContainer);
      document.body.removeChild(this.cmContainer);
      this.cmContainer = null;
      return
    }

    if (type === CATALOGUE && options.list !== RENAME && options.list !== DELETE) {
      this.props.createCatalogueFile();
      ReactDOM.unmountComponentAtNode(this.cmContainer);
      document.body.removeChild(this.cmContainer);
      this.cmContainer = null;
      return;
    }

    if(type === CLASSIFIER && options.list === EXPORT_ID_NAMES) {
      const mData = {
        itemTitle: "Select Export Type",
        itemId: this.state.selectedKeys,
        itemEvent: EXPORT_ID_NAMES,
        nodeName: nodeName
      };
      this.setState({
        ...this.state,
        modelData: mData,
        modelTreeLoading: true,
        cmModalStatus: true
      });
      ReactDOM.unmountComponentAtNode(this.cmContainer);
      document.body.removeChild(this.cmContainer);
      this.cmContainer = null;
      return
    } else if (type === CLASSIFIER && options.list === LISTVIEW) {
      //List View Render
      this.props.handleListView(node);
    } else {
      //Fetch Copy Tree Data
      if ((type === MODEL || type === CLASSIFIER) && options.list === COPY)
        this.getModalTreeData(type);
      
      let aryToString = this.state.selectedKeys,
        initVal = this.state.itemLabel,
        deleteArry = [parseInt(aryToString.toString())];

      let mData = {};

      switch (OPTIONS) {
        case RENAME:
          mData = {
            itemTitle: RENAME,
            itemId: aryToString,
            intialVal: initVal,
            itemEvent: RENAME,
            nodeName: nodeName,
            nodeType: nodeType,
            nodeParentId: nodeParentId
          };
          break;
        case DELETE:
          mData = {
            itemTitle: DELETE,
            itemId: deleteArry,
            itemEvent: DELETE,
            nodeName: nodeName,
            brandId: [brandId],
            nodeType: nodeType,
            nodeParentId: nodeParentId,
            nodeRootId: nodeRootId,
          };
          break;
        case COPY:
          mData = {
            itemTitle: COPY + " " + type,
            itemId: aryToString,
            itemEvent: COPY,
            nodeName: nodeName + "_copy",
            nodeRootId: nodeRootId,
            nodeFrameType: nodeRootId === -3 ? "calculated_classifier" : nodeRootId === -2 ? "virtual"  : "regular"
          };
          break;
        case DISCOVER_TOPIC:
          mData = {
            itemTitle: "Select Threshold Value",
            itemId: aryToString,
            itemEvent: DISCOVER_TOPIC,
            nodeName: nodeName
          };
          break;
        case IMPORT:
          mData = {
            itemTitle: IMPORT + " " + type,
            itemId: aryToString,
            itemEvent: IMPORT,
            nodeName: nodeName,
            nodeFrameType: nodeRootId === -3 ? "calculated_classifier" : nodeRootId === -2 ? "virtual"  : "regular"
          };
          break;
        case EXPORT:
          mData = {
            itemTitle: "Select Export Type",
            itemId: aryToString,
            itemEvent: EXPORT,
            nodeName: nodeName,
            nodeModule: type
          };
          break;
        case FOLDER:
          mData = {
            itemTitle: CREATE_FOLDER,
            itemId: aryToString,
            itemEvent: FOLDER,
            nodeName: EMPTY_STRING,
            nodeRootId: nodeRootId
          };
          break;
        default:
          mData = {
            itemTitle: CREATE + " " + options.list,
            itemId: aryToString,
            itemEvent: CREATE,
            nodeName: EMPTY_STRING,
            nodeRootId: nodeRootId
          };
          break;
      }
      this.setState({
        ...this.state,
        modelData: mData,
        modelTreeLoading: true,
        cmModalStatus: true
      });
    }

    ReactDOM.unmountComponentAtNode(this.cmContainer);
    document.body.removeChild(this.cmContainer);
    this.cmContainer = null;
  };

  //Modal Close Event
  handleClose = () =>
    this.setState({
      cmModalStatus: false,
      setModelOpen: false,
      bindedBrandsmodels: EMPTY_ARRAY
    });

  //Modal Ok Event
  handleOk = isEmpty => {
    let parentId = this.state.modelData.itemId,
      label = this.state.modelData.nodeName.trim(),
      nodeParentId = this.state.modelData.nodeParentId,
      nodeType = this.state.modelData.nodeType,
      nodeRootId = this.state.modelData.nodeRootId,
      item = {
        pid: parentId,
        label: label,
        modelType: this.state.modelData.itemTitle,
        nodeParentId: nodeParentId,
        nodeType: nodeType,
        nodeRootId: nodeRootId,
        isEmpty: isEmpty
      };

    switch (this.state.modelData.itemTitle) {
      case RENAME:
        this.renameNode(item);
        break;
      default:
        this.addNewNode(item);
        break;
    }
  };

  //Discover Topic Threshold change
  updateThresholdSubmit = (value, pipeline) => {
    client
      .get(DISCOVER_TOPIC_API, {
        params: { brandId: this.state.nodeId, thresholdValue: value.toString(), pipeline: pipeline }
      })
      .then(res => {
        if (res.data.status === SUCCESS)
          toast.success("Request sent successful.");
        else {
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
        }
      })
      .catch(error => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
      });
    this.setState({ ...this.state, cmModalStatus: false, val: EMPTY_STRING });
  };

  //Classifier Export Id and Name
  exportClassifierIdName=(exportFormat, nodeName) => {
    this.toastId = toast("Classifier Export in progress, please wait...", { autoClose: false });
    
    const headerAccept = exportFormat === 'csv' 
    ? 'text/csv; charset=utf-8'
    : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

      client
        .get(EXPORT_CLASSIFIERS_IDNAMES, { 
          params: { folderId: this.state.nodeId, isCsv: exportFormat === 'csv' },
          headers: { 
            'accept': headerAccept,
            'content-type': 'application/json',
          },
          'responseType': 'blob',
        }).then(res => {
          let responseData = res.data;
          if (responseData.size === 0 || responseData.length === 0) {
            toast.update(this.toastId, {
              type: toast.TYPE.WARNING,
              render: "Empty classifier can't be exported.",
              autoClose: 3500
            })
            return
          }
          if( exportFormat === 'csv')
            handleCsvFileDownload(res.data, nodeName);
          else
            handleExcelFileDownload(res.data, nodeName);
          
          toast.update(this.toastId, {
              type: toast.TYPE.SUCCESS,
              render: "Classifier Exported successfully.",
              autoClose: 3500,
          })
        })
        .catch(error => {
          if (error.response) {
            toast.update(this.toastId, {
              type: toast.TYPE.ERROR,
              render: ERROR_MESSAGE,
              autoClose: 3500
            });
          }
        });
        this.setState({ ...this.state, cmModalStatus: false, val: EMPTY_STRING });
  }

  //Export Classifier Names only - Model
  exportClassifierNames=(exportFormat, nodeName)=> {
    this.toastId = toast("Classifier Names export in progress, please wait...", {
      autoClose: false
    });

    const headerAccept = exportFormat === 'csv' 
    ? 'text/csv; charset=utf-8'
    : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

    client.get(GET_EXPORT_CLASSIFIER_NAMES, { 
      params: { modelId: this.state.nodeId, isCsv: exportFormat === 'csv' },
      headers: { 
        'accept': headerAccept,
        'content-type': 'application/json',
      },
      'responseType': 'blob',
    }).then(res=> {
      let responseData = res.data;
      
          if (responseData.size === 0 || responseData.length === 0) {
            toast.update(this.toastId, {
              type: toast.TYPE.WARNING,
              render: "Empty Model can't be exported.",
              autoClose: 3500
            })
            return
          }
          if(exportFormat === 'csv')
            handleCsvFileDownload(responseData, nodeName);
          else
            handleExcelFileDownload(responseData, nodeName);

          toast.update(this.toastId, {
              type: toast.TYPE.SUCCESS,
              render: "Classifier names exported successfully.",
              autoClose: 3500,
          })
    })
    .catch(error => {
      if (error.response) {
        toast.update(this.toastId, {
          type: toast.TYPE.ERROR,
          render: ERROR_MESSAGE,
          autoClose: 3500
        });
      }
    });
    this.setState({ ...this.state, cmModalStatus: false, val: EMPTY_STRING });
  }

  submitExport=(exportFormat, nodeName, type) => {
    if(type === CLASSIFIER){
      this.classifierExport(exportFormat, nodeName)
      return
    }
    if(type === MODEL)
      this.modelExport(exportFormat, nodeName)
  }

  //Classifier Export
  classifierExport=(exportFormat, nodeName) => {
    this.toastId = toast("Classifier Export in progress, please wait...", { autoClose: false });
    
    const headerAccept = exportFormat === 'csv' 
    ? 'text/csv; charset=utf-8'
    : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

    client
      .get(EXPORT_CLASSIFIERS, { 
        headers: { 
          'accept': headerAccept,
          'content-type': 'application/json',
        },
        'responseType': 'blob',
        params: { folderId: this.state.nodeId, isCsv : exportFormat === 'csv' } 
      }).then(res => {
        let responseData = res.data;
        if (responseData.size === 0 || responseData.length === 0) {
          toast.update(this.toastId, {
            type: toast.TYPE.WARNING,
            render: "Empty classifier can't be exported.",
            autoClose: 3500
          })
          return
        }
        if(exportFormat === 'csv')
            handleCsvFileDownload(res.data, nodeName);
        else
            handleExcelFileDownload(res.data, nodeName);
    
        toast.update(this.toastId, {
            type: toast.TYPE.SUCCESS,
            render: "Classifier Exported successfully.",
            autoClose: 3500,
        })
      })
      .catch(error => {
        if (error.response) {
          toast.update(this.toastId, {
            type: toast.TYPE.ERROR,
            render: ERROR_MESSAGE,
            autoClose: 3500
          });
        }
      });
      this.setState({ ...this.state, cmModalStatus: false, val: EMPTY_STRING });
  };

  //Model File Export
  modelExport = (exportFormat, nodeName) => {
    this.toastId = toast("Model Export in progress, please wait...", {
      autoClose: false,
      type: toast.TYPE.DEFAULT
    });

    //JSON api
    if(exportFormat === "json") {
      client
      .get(EXPORT_JSON_MODEL, { params: { modelId: this.state.nodeId } })
      .then(res => {

        if (res.status === 200) {

          let responseData = JSON.stringify(res.data.response);
          

          if (responseData === undefined) {
            toast.update(this.toastId, {
              type: toast.TYPE.WARNING,
              render: "Empty model can't be exported.",
              autoClose: 3500
            })
            return
          }

          handleJSONFileDownload(responseData, nodeName);
  
          toast.update(this.toastId, {
              type: toast.TYPE.SUCCESS,
              render: "Model Exported Successfully.",
              autoClose: 3500,
          })
        } else
          toast.update(this.toastId, {
            type: toast.TYPE.ERROR,
            render: ERROR_IN_PROCESS,
            autoClose: 3500
          });
      })
      .catch(error => {
        console.log(error)
        if (error.response) {
          toast.update(this.toastId, {
            type: toast.TYPE.ERROR,
            render: error.response.data.message,
            autoClose: 3500
          });
        }
      });

      this.setState({ ...this.state, cmModalStatus: false, val: EMPTY_STRING });
      return
    } 

    const headerAccept = exportFormat === 'csv' 
    ? 'text/csv; charset=utf-8'
    : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  
    // CSV/XLSX api 
    client
      .get(EXPORT_CSV_MODEL, { 
        params: { modelId: this.state.nodeId, isCsv: exportFormat === 'csv' },
        headers: { 
          'accept': headerAccept,
          'content-type': 'application/json',
        },
        'responseType': 'blob',
      }).then(res => {

        if (res.status === 200) {
          let csvData = res.data;

          if (csvData.size === 0 || csvData.length === 0) {
            toast.update(this.toastId, {
              type: toast.TYPE.WARNING,
              render: "Empty model can't be exported.",
              autoClose: 3500
            })
            return
          }

          if(exportFormat === 'csv')
            handleCsvFileDownload(csvData,nodeName);
          else
            handleExcelFileDownload(csvData,nodeName);
  
          toast.update(this.toastId, {
              type: toast.TYPE.SUCCESS,
              render: "Model Exported Successfully.",
              autoClose: 3500,
          })
        } else
          toast.update(this.toastId, {
            type: toast.TYPE.ERROR,
            render: ERROR_IN_PROCESS,
            autoClose: 3500
          });
      })
      .catch(error => {
        console.log(error)
        if (error.response) {
          toast.update(this.toastId, {
            type: toast.TYPE.ERROR,
            render: error.response.data.message,
            autoClose: 3500
          });
        }
      });
    this.setState({ ...this.state, cmModalStatus: false, val: EMPTY_STRING });
  };

  //Import File
  handleFileUpload = file => {
    const nodeId = this.state.modelData.itemId.toString();
    const classifierType = this.state.modelData.nodeFrameType;
    const formData = new FormData();
    
    formData.append("dataFile", file);
    formData.append("id", nodeId);
    formData.append("languageId", 1);
    formData.append("classifierType", classifierType);

    const CustomMsg = (props)=>{
      return (<span>Classifier Import in progress, {`${props.value}%`}</span>)
    }

    this.toastId = toast(<CustomMsg value={0}/>, {
      autoClose: false, hideProgressBar: false, progress: 0 }); 

    client.post(IMPORT_CLASSIFIERS, formData, {
      onUploadProgress: data => {
          toast.update(this.toastId, {
            render: <CustomMsg value={(Math.round((100 * data.loaded) / data.total))}/>,
            progress: data.loaded / data.total,
          });    
      }
    }).then(res => {
      if(res.data.status === SUCCESS) {
        toast.update(this.toastId, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          hideProgressBar: true,
          autoClose: 1500
        });
      } else {
        if (res.data.status === WARN)
              toast.warn(res.data.message);
            else
            toast.error(res.data.message)
      }
    }).catch(error=>{
      console.log(error)
      if(error.response) {
        toast.error(error.response.data.message)
      }
    })
    
    this.setState({ cmModalStatus: false, val: EMPTY_STRING });
  };

  //Modal dialouge input value change
  handleInputChange = text => {
    this.setState({
      ...this.state,
      modelData: {
        ...this.state.modelData,
        nodeName: text
      }
    });
  };

  //Tree Context-Menu Copy Event
  copyNodeHandler = nodeId => {
    this.toastId = toast("Copying in progress, please wait...", {
      autoClose: false
    });
    const { modelData } = this.state;

    let copyFrom = modelData.itemId.toString(),
      copyTo = nodeId.toString(),
      name = modelData.nodeName,
      planType = 1,
      languageId = 1;

    const data = {};
    data["copyFrom"] = copyFrom;
    data["copyTo"] = copyTo;
    data["name"] = name;
    data["planType"] = planType;
    data["languageId"] = languageId;

    if (this.props.type === MODEL) {
      client
        .post(COPY_MODEL, data)
        .then(res => {
          if (res.data.status === SUCCESS) {
            this.props.handleDataUpdate(data, "copy");
            toast.update(this.toastId, {
              type: toast.TYPE.SUCCESS,
              render: res.data.message,
              autoClose: 3500
            });
            this.setState({ ...this.state, cmModalStatus: false });
          } else {
            if (res.data.status === WARN)
              toast.update(this.toastId, {
                type: toast.TYPE.WARNING,
                render: res.data.message,
                autoClose: 3500
              });
            else
              toast.update(this.toastId, {
                type: toast.TYPE.ERROR,
                render: res.data.message,
                autoClose: 3500
              });
          }
        })
        .catch(error => {
        console.log(error)
          if (error.response) {
            toast.update(this.toastId, {
              type: toast.TYPE.ERROR,
              render: error.response.data.message,
              autoClose: 3500
            });
          }
        });
    } else {
      client
        .post(COPY_CLASSIFIER, {
          copyFrom: copyFrom,
          copyTo: copyTo,
          name: name,
          langId: languageId,
          frameType: modelData.nodeFrameType
        })
        .then(res => {
          if (res.data.status === SUCCESS) {
            this.props.handleDataUpdate(data, "copy");
            toast.update(this.toastId, {
              type: toast.TYPE.SUCCESS,
              render: res.data.message,
              autoClose: 3500
            });
            this.setState({ ...this.state, cmModalStatus: false });
          } else {
            if (res.data.status === WARN)
              toast.update(this.toastId, {
                type: toast.TYPE.WARNING,
                render: res.data.message,
                autoClose: 3500
              });
            else
              toast.update(this.toastId, {
                type: toast.TYPE.ERROR,
                render: res.data.message,
                autoClose: 3500
              });
          }
        })
        .catch(error => {
          console.log(error)
          if (error.response)
            toast.update(this.toastId, {
              type: toast.TYPE.ERROR,
              render: error.response.data.message,
              autoClose: 3500
            });
        });
    }
    
  };

  //Tree Context-Menu Create New File/Folder Event
  addNewNode = info => {
    const { type } = this.props;
    const parentId = info.pid.toString();
    const nodeName = info.label.toString();
    const nodeType = info.modelType === CREATE_FOLDER ? DEFAULT : FILE;
    const nodeRootId = info.nodeRootId;

    let data = {
      parentId: parentId,
      name: nodeName,
      type: nodeType,
      languageId: 1,
    };

    if (type === MODEL && (info.isEmpty === "true" || info.isEmpty === true)) {
      data["isEmpty"] = info.isEmpty;
    }

    if (type === CLASSIFIER) {
      data["classifierType"] =
        nodeRootId === -3
          ? CALCULATED_CLASSIFIER
          : nodeRootId === -2
          ? VIRTUAL_CLASSIFIER
          : REGULAR_CLASSIFIER;
    }

    let createUrl =
      type === BRAND
        ? CREATE_BRAND
        : type === MODEL
          ? CREATE_MODEL
          : type === CLASSIFIER
            ? CREATE_CLASSIFIER
            : CREATE_COLLECTION;

    let duplicateCheckUrl =
      type === BRAND
        ? IS_BRAND_EXISTS
        : type === MODEL
          ? IS_MODEL_EXISTS
          : type === CLASSIFIER
            ? IS_CLASSIFIER_EXISTS
            : IS_COLLECTION_EXISTS;

    client
      .get(duplicateCheckUrl, {
        params: { parentId: parentId, name: nodeName, nodeType: nodeType }
      })
      .then(res => {
        if (res.data.status === SUCCESS) {
          client
            .post(createUrl, data)
            .then(res => {
              if (res.data.status === SUCCESS) {
                toast.success(res.data.message);
                
                this.props.handleNodeCreate(
                  res.data.response,
                  nodeName,
                  nodeType,
                  nodeRootId
                );
                if(type === CLASSIFIER)
                this.setState({ rootId: nodeRootId })
              } else {
                if (res.data.status === WARN) toast.warn(res.data.message);
                else toast.error(res.data.message);
              }
            })
            .catch(error => console.log(error));
          this.setState({ cmModalStatus: false, val: EMPTY_STRING });
        } else {
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
          this.setState({
            ...this.state,
            cmModalStatus: true,
            val: EMPTY_STRING
          });
        }
      })
      .catch(error => {
        console.log(error)
        if (error.response) {
          toast.error(error.response.data.message);
          this.setState({
            ...this.state,
            cmModalStatus: false,
            val: EMPTY_STRING
          });
        }
      });
  };

  //Tree Context-Menu Rename Event
  renameNode = info => {
    const { type } = this.props;
    const nodeParentId = info.nodeParentId;
    const nodeId = info.pid.toString();
    const nodeName = info.label.toString();
    const nodeType = info.nodeType === "default" ? "folder" : "file";

    let data = { id: nodeId, name: type === CLASSIFIER ? replaceSpaces(nodeName,'_') : nodeName };

    let renameUrl =
      this.props.type === BRAND
        ? RENAME_BRAND
        : this.props.type === CLASSIFIER
          ? RENAME_CLASSIFIER
          : this.props.type === MODEL
            ? RENAME_MODEL
            : this.props.type === CATALOGUE
              ? RENAME_CATALOGUE
              : RENAME_COLLECTION;

    let duplicateCheckUrl =
      type === BRAND
        ? IS_BRAND_EXISTS
        : type === MODEL
          ? IS_MODEL_EXISTS
          : type === CLASSIFIER
            ? IS_CLASSIFIER_EXISTS
            : type === CATALOGUE
              ? IS_CATALOGUE_EXISTS
              : IS_COLLECTION_EXISTS;

    this.toastId = toast("Rename in progress, please wait...", {
      autoClose: false
    });
    client
      .get(duplicateCheckUrl, {
        params: { parentId: nodeParentId, name: nodeName, nodeType: nodeType }
      })
      .then(res => {
        if (res.data.status === SUCCESS) {
          //update renamed item
          client
            .post(renameUrl, data)
            .then(res => {
              if (res.data.status === SUCCESS) {
                this.props.handleDataUpdate(data, "rename");
                toast.update(this.toastId, {
                  type: toast.TYPE.SUCCESS,
                  render: res.data.message,
                  autoClose: 3500
                });

                //To update Default Selected Brand
                if (this.props.type === BRAND) {
                  const defaultBrandDetails = JSON.parse(
                    localStorage.getItem("defaultSelectedBrand")
                  );
                  if (defaultBrandDetails === null) {
                    return;
                  } else {
                    if (res.data.response === defaultBrandDetails.id) {
                      document.getElementById(
                        "selectedBrandName"
                      ).innerText = nodeName;

                      //reassign local variable defaultSelectedBrand
                      let newValues = defaultBrandDetails;
                      newValues.name = nodeName;
                      localStorage.setItem(
                        "defaultSelectedBrand",
                        JSON.stringify(newValues)
                      );

                    }
                  }
                }
              } else {
                if (res.data.status === WARN)
                  toast.update(this.toastId, {
                    type: toast.TYPE.WARNING,
                    render: res.data.message,
                    autoClose: 3500
                  });
                else
                  toast.update(this.toastId, {
                    type: toast.TYPE.ERROR,
                    render: res.data.message,
                    autoClose: 3500
                  });
              }
            })
            .catch(error => {
              if (error.response)
                toast.update(this.toastId, {
                  type: toast.TYPE.ERROR,
                  render: error.response.data.message,
                  autoClose: 3500
                });
            });
          this.setState({
            ...this.state,
            cmModalStatus: false,
            val: EMPTY_STRING
          });
        } else {
          if (res.data.status === WARN)
            toast.update(this.toastId, {
              type: toast.TYPE.WARNING,
              render: res.data.message,
              autoClose: 3500
            });
          else
            toast.update(this.toastId, {
              type: toast.TYPE.ERROR,
              render: res.data.message,
              autoClose: 3500
            });
          this.setState({
            ...this.state,
            cmModalStatus: true,
            val: EMPTY_STRING
          });
        }
      })
      .catch(error => {
        if (error.response)
          toast.update(this.toastId, {
            type: toast.TYPE.ERROR,
            render: error.response.data.message,
            autoClose: 3500
          });
      });
    this.setState({ ...this.state, cmModalStatus: false });
  };

  //Tree Context-Menu Delete Event
  deleteItem = () => {
    this.toastId = toast("Delete in progress, please wait...", {
      autoClose: false
    });
    let deleteUrl =
      this.props.type === BRAND
        ? DELETE_BRAND
        : this.props.type === MODEL
          ? DELETE_MODEL
          : this.props.type === CLASSIFIER
            ? DELETE_CLASSIFIER
            : this.props.type === CATALOGUE
              ? DELETE_CATALOGUE
              : DELETE_COLLECTION;
    
    let data = {};
    data["id"] = this.state.modelData.itemId;
    if(this.props.type === CLASSIFIER)
    data["isAutoRegex"] = this.state.modelData.nodeRootId === -4 ? true : false;

    client
      .post(deleteUrl, data)
      .then(res => {
        if (res.data.status === SUCCESS) {
          toast.update(this.toastId, {
            type: toast.TYPE.SUCCESS,
            render: res.data.message,
            autoClose: 3500
          });

          //Update Default Selected Brand Details
          if (res.data.response.isDefaultBrandChanged) updateNoBrandDetails(res.data.response.id);

          this.props.handleDataUpdate(this.state.modelData, "delete");
        } else {
          if (res.data.status === WARN) {
            if (this.props.type === CLASSIFIER || this.props.type === MODEL) {
              toast.update(this.toastId, {
                type: toast.TYPE.WARNING,
                render: res.data.message,
                autoClose: 3500
              });
              this.setState({
                ...this.state,
                setModelOpen: true,
                bindedBrandsmodels: res.data.response
              });
            }
          } else {
            toast.update(this.toastId, {
              type: toast.TYPE.ERROR,
              render: res.data.message,
              autoClose: 3500
            });
          }
        }
      })
      .catch(error => {
        if (error.response)
          toast.update(this.toastId, {
            type: toast.TYPE.ERROR,
            render: error.response.data.message,
            autoClose: 3500
          });
        else if (error.request) {
          toast.update(this.toastId, {
            type: toast.TYPE.ERROR,
            render: "There is some error with the process",
            autoClose: 3500
          });
        } else {
          console.log(error.message);
        }
      }); 
    this.setState({ cmModalStatus: false });
  };

  onTreeNodeDragOver = info => { };

  // Tree Drag and Drop Events
  onTreeNodeDragStart = info => {
    if (this.props.type === CLASSIFIER)
      if(info.node.props) {
        let nodeProps = {
          "loaded": "false",
          "data-id": info.node.props['data-id'],
          "eventKey": info.node.props['eventKey'],
          "data-root": info.node.props['data-root'],
          "title": info.node.props['title'],
          "isLeaf": info.node.props['isLeaf'],
          "data-classifiertype": info.node.props['data-classifiertype']
        };
        info.event.dataTransfer.setData("node", JSON.stringify(nodeProps));  
      }
  };

  onTreeNodeDragEnd = info => { };

  //onDrop tree node handler
  onTreeNodeDrop = (info,e) => {
    this.setState({
      draggedNode: info.dragNode.props,
      onDroppedNode: info.node.props
    });
    const draggedNode = info.dragNode.props;
    const onDroppedNode = info.node.props;
    const droppedRootId = info.node.props['data-root'];

    setTimeout(() => {
      const { type } = this.props;
      const { rootId } = this.state;

      if(type === CLASSIFIER && droppedRootId !== rootId) {
        toast.warn("Same classifer types are allowed to move.");
        return
      }

      const unDroppableNodes = ["-1", "1", this.decoodachinesId.toString()];

      if (draggedNode.eventKey === "-1") {
        if (draggedNode["data-root"] === "-1")
          toast.warn("Cannot move Shared items.");
        toast.warn("Cannot move Shared items.");
        return false;
      } else {
        const nodeType = draggedNode.isLeaf ? FILE : DEFAULT;
        let treeUrl =
          type === BRAND
            ? MOVE_BRAND
            : type === MODEL
              ? MOVE_MODEL
              : type === CLASSIFIER
                ? MOVE_CLASSIFIER
                : MOVE_COLLECTION;

        let data = {};
        data["ids"] = this.props.selectedKey.length > 1 ? this.props.selectedKey : [parseInt(draggedNode.eventKey)];
        data["parentId"] = parseInt(onDroppedNode.eventKey);
        data["type"] = nodeType;
        if (type === MODEL) data["planType"] = 0;

        if (!info.dropToGap) {
          if (onDroppedNode.isLeaf && onDroppedNode.className !== "emptyFolder") {
            toast.warn("Cannot move on file.");
          } else {
            if (draggedNode["data-root"] !== droppedRootId) {
             
              let warnMsg = onDroppedNode["data-root"] === -2 ? "Virtual Classifiers" : "Other Classifiers";

              toast.warn("Cannot move on " + warnMsg);
              return false;
            } else {

              if (unDroppableNodes.includes(onDroppedNode.eventKey) && onDroppedNode.eventKey !== "0") {
                toast.warn("Cannot move on root folders.");
                return false;
              } else {
                this.toastId = toast("Moving in process, please wait...", { autoClose: false });
                client
                  .post(treeUrl, data)
                  .then(res => {
                    if (res.data.status === SUCCESS) {
                      toast.update(this.toastId, {
                        type: toast.TYPE.SUCCESS,
                        render: res.data.message,
                        autoClose: 3500
                      });
                      data["dropped-node"] = [info.node.props.eventKey];
                      this.props.handleDataUpdate(data, "drop");
                    } else {
                      if (res.data.status === WARN)
                        toast.update(this.toastId, {
                          type: toast.TYPE.WARNING,
                          render: res.data.message,
                          autoClose: 3500
                        });
                      else
                        toast.update(this.toastId, {
                          type: toast.TYPE.ERROR,
                          render: res.data.message,
                          autoClose: 3500
                        });
                    }
                  })
                  .catch(error => {
                    if (error.response)
                      toast.update(this.toastId, {
                        type: toast.TYPE.ERROR,
                        render: error.response.data.message,
                        autoClose: 3500
                      });
                  });
              }
            }
          }
        } else return false;
      }
    }, 400);
  };

  setTreeRef = tree => {
    this.tree = tree;
  };

  render() {
    const { isSearchTree, selectedKey, expandedKey, type, data } = this.props;
    const { switcherIcon } = this.state;

    const loop = data => {
      return data.map(item => {
        if (item.children) {
          if (item.children.length === 0) {
            return (
              <TreeNode
                className={"emptyFolder"}
                key={item.key}
                title={item.title}
                data-id={item.key}
                data-parent={item.parentId}
                data-shared={item.isShared}
                data-root={item.dataRoot}
              />
            );
          } else {
            return (
              <TreeNode
                key={item.key}
                title={item.title}
                data-id={item.key}
                data-parent={item.parentId}
                data-shared={item.isShared}
                data-root={item.dataRoot}
              >
                {loop(item.children)}
              </TreeNode>
            );
          }
        } else if (item.isEmptyFolder) {
          return (
            <TreeNode
              className={"emptyFolder"}
              key={item.key}
              title={item.title}
              isLeaf={true}
              data-id={item.key}
              data-parent={item.parentId}
              data-shared={item.isShared}
              data-root={item.dataRoot}
            />
          );
        } else {
          return (
            <TreeNode
              key={item.key}
              title={item.title}
              isLeaf={item.isLeaf}
              data-id={item.key}
              data-status={item.status}
              data-parent={item.parentId}
              data-brandid={item.brandId}
              data-shared={item.isShared}
              data-root={item.dataRoot}
              data-classifiertype={item.classifierType}
            />
          );
        }
      });
    };

    const treeNodes = loop(data);

    return (
      <div className="treeComponent">
        <style dangerouslySetInnerHTML={{ __html: STYLE }} />

        <ErrorModal
          isOpen={this.state.setModelOpen}
          type={this.props.type}
          bindedBrands={this.state.bindedBrandsmodels}
          handleClose={this.handleClose}
        />

        <TreeModal
          treeType={type}
          open={this.state.cmModalStatus}
          data={this.state.modelData}
          val={this.state.val}
          initialVal={this.state.itemLabel}
          handleClose={this.handleClose}
          handleOk={this.handleOk}
          deleteItem={this.deleteItem}
          updateThresholdSubmit={this.updateThresholdSubmit}
          handleFileUpload={this.handleFileUpload}
          submitExport={this.submitExport}
          submitClassiferNameExport={this.exportClassifierNames}
          submitClassiferIdNameExport={this.exportClassifierIdName}
          handleInputChange={this.handleInputChange}
          modelTreeData={this.state.modalTreeData}
          copyNodeHandler={this.copyNodeHandler}
          modelTreeLoading={this.state.modelTreeLoading}
        />

        <div id="jstree" className="tree-holder">
          {isSearchTree ? (
            <Tree
              ref={this.setTreeRef}
              className="myCls"
              multiple
              draggable
              defaultExpandAll
              switcherIcon={switcherIcon}
              selectedKeys={selectedKey}
              onSelect={this.onSelect}
              openAnimation={animation}
              onRightClick={this.onRightClick}
              onDragStart={this.onTreeNodeDragStart}
              onDragEnd={this.onTreeNodeDragEnd}
              onDragOver={this.onTreeNodeDragOver}
              onDrop={this.onTreeNodeDrop}
            >
              {treeNodes}
            </Tree>
          ) : (
              <Tree
                className="myCls"
                multiple
                draggable
                switcherIcon={switcherIcon}
                expandedKeys={expandedKey}
                selectedKeys={selectedKey}
                onSelect={this.onSelect}
                onExpand={this.onExpand}
                openAnimation={animation}
                onRightClick={this.onRightClick}
                loadData={e => this.onLoadData(e, type)}
                onDragStart={this.onTreeNodeDragStart}
                onDragEnd={this.onTreeNodeDragEnd}
                onDragOver={this.onTreeNodeDragOver}
                onDrop={this.onTreeNodeDrop}
              >
                {treeNodes}
              </Tree>
            )}
        </div>
      </div>
    );
  }
}

export default TreeView;

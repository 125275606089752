import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, TextField, Button } from '@material-ui/core';
import client from '../../../../_helpers/client';
import { toast } from 'react-toastify';
import DataPreloader from '../../../../containers/Preloader/DataPreloader';
import * as FormValidator from '../../../../_helpers/formValidation';
import { Phone, Mail, Edit } from '@material-ui/icons';
import { IconButton, Tooltip } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { SUCCESS, EMPTY_STRING, WARN } from '../../../../Constants';
import { CHECK_OLD_PASSWORD, USER_PROFILE_EDIT, UPDATE_USER_DETAILS } from '../../../../Routes'
import { userService } from '../../../../_services';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    updateButton: {
        margin: '20px 20px 20px 0px',
    }
}));

export default function UserEdit(props) {

    const { history } = props;
    const classes = useStyles();

    const [visiblePreloader, setVisiblePreloader] = useState(true);
    const [firstName, setFirstName] = useState(EMPTY_STRING);
    const [lastName, setLastName] = useState(EMPTY_STRING);
    const [phone, setPhone] = useState(EMPTY_STRING);
    const [email, setEmail] = useState(EMPTY_STRING);
    const [currentPlan, setCurrentPlan] = useState(EMPTY_STRING);
    const [userImage, setUserImage] = useState(null);

    const [formFirstName, setFormFirstName] = useState(EMPTY_STRING);
    const [formLastName, setFormLastName] = useState(EMPTY_STRING);
    const [formPhone, setFormPhone] = useState(EMPTY_STRING);
    const [oldPassword, setOldPassword] = useState(EMPTY_STRING);
    const [newPassword, setNewPassword] = useState(EMPTY_STRING);
    const [confirmPassword, setConfirmPassword] = useState(EMPTY_STRING);

    const [formFirstNameError, setFormFirstNameError] = useState(EMPTY_STRING);
    const [formLastNameError, setFormLastNameError] = useState(EMPTY_STRING);
    const [formPhoneError, setFormPhoneError] = useState(EMPTY_STRING);
    const [formOldPasswordError, setFormOldPasswordError] = useState(EMPTY_STRING);
    const [formNewPasswordError, setFormNewPasswordError] = useState(EMPTY_STRING);
    const [formConfirmPasswordError, setFormConfirmPasswordError] = useState(EMPTY_STRING);

    const getUserDetails = () => {
        client
            .get(USER_PROFILE_EDIT)
            .then(res => {
                if (res.data.status === SUCCESS) {
                    setFirstName(res.data.response.user.firstName);
                    setLastName(res.data.response.user.lastName);

                    setEmail(res.data.response.user.email);
                    setCurrentPlan(res.data.response.userPlan);

                    setFormFirstName(res.data.response.user.firstName);
                    setFormLastName(res.data.response.user.lastName);

                    setPhone(res.data.response.user.hasOwnProperty('mobileNumber') ? res.data.response.user.mobileNumber : EMPTY_STRING);
                    setFormPhone(res.data.response.user.hasOwnProperty('mobileNumber') ? res.data.response.user.mobileNumber : EMPTY_STRING);
                } else {
                    if (res.data.status === WARN) toast.warn(res.data.message)
                    else toast.error(res.data.message)
                }
                setVisiblePreloader(false);
            })
            .catch(error => {
                setVisiblePreloader(false);
                if (error.response) {
                    if (error.response.data.status === WARN) toast.warn(error.response.data.message)
                    else toast.error(error.response.data.message)
                }
            })
    }

    const handleReset = () => {
        setFormFirstName(firstName);
        setFormLastName(lastName);
        setFormPhone(phone);
        setOldPassword(EMPTY_STRING);
        setNewPassword(EMPTY_STRING);
        setConfirmPassword(EMPTY_STRING);

        setFormFirstNameError(EMPTY_STRING);
        setFormLastNameError(EMPTY_STRING);
        setFormPhoneError(EMPTY_STRING);
        setFormOldPasswordError(EMPTY_STRING);
        setFormNewPasswordError(EMPTY_STRING);
        setFormConfirmPasswordError(EMPTY_STRING);
    }

    const handleChange = (e, elm) => {
        e.persist();
        const value = e.target.value;

        //First Name Validator
        if (elm === 'formFirstName') {
            setFormFirstName(value);
            if (value.length === 0) {
                setFormFirstNameError("First name cannot be empty.");
            } else if (value.length < 3) {
                setFormFirstNameError("First name must include at least 3 characters.");
            } else {
                setFormFirstNameError(EMPTY_STRING);
            }

        }

        //Last Name Validator
        if (elm === 'formLastName') {
            setFormLastName(value);
            if (value.length === 0) {
                setFormLastNameError("Last name cannot be empty.");
            } else if (value.length < 3) {
                setFormLastNameError("Last name must include at least 3 characters.");
            } else {
                setFormLastNameError(EMPTY_STRING);
            }

        }

        //Phone Number validator
        if (elm === 'formPhone') {
            setFormPhone(value);
            let validPhone = FormValidator.validatePhone(value);

            if (value.length === 0)
                setFormPhoneError("Phone nuber cannot be empty.");
            else if (value.length > 10)
                setFormPhone(value.slice(0, 10));
            else {
                if (!(validPhone))
                    setFormPhoneError('Please enter only numbers.')
                else {
                    if (value.length < 10)
                        setFormPhoneError('Please enter your 10 digit mobile number.')
                    else
                        setFormPhoneError(EMPTY_STRING);
                }
            }
        }

        //Old Password validator
        if (elm === 'oldPassword') {
            setOldPassword(value);
            let data = new FormData();
            data.append('oldPassword', value);

            if (value.length === 0)
                setFormOldPasswordError("Please enter your old password.");
            else {
                client.post(CHECK_OLD_PASSWORD, data)
                    .then(res => {
                        if (!res.data.response)
                            setFormOldPasswordError("The password you entered does not match with existing password.")
                        else
                            setFormOldPasswordError(EMPTY_STRING);
                    })
                    .catch(error => {
                        if (error.response) {
                            setFormOldPasswordError("The password you entered does not match with existing password.")
                        }
                    })
            }
        }

        //new Password validator
        if (elm === 'newPassword') {
            setNewPassword(value);
            if (value.length === 0) {
                setFormNewPasswordError('Please enter your password.')
            } else if (value.length < 8) {
                setFormNewPasswordError('Please enter at least 8 characters.')
            } else if (value.length > 15) {
                setFormNewPasswordError('Please enter no more than 15 characters.')
            } else {
                userService.checkPassword(value)
                    .then(res => {
                        if (res.data.response) {
                            setFormNewPasswordError(EMPTY_STRING)
                        } else {
                            setFormNewPasswordError('Invalid format. Password does not matches the criteria.')
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            setFormNewPasswordError('Invalid format. Password does not matches the criteria.')
                        }
                    })
            }
        }

        //confirm Password validator
        if (elm === 'confirmPassword') {
            setConfirmPassword(value);
            let comp = newPassword.localeCompare(value);

            if (value.length === 0) {
                setFormConfirmPasswordError('Please enter your password one more time.');
            } else {
                if (comp === 0) {
                    setFormConfirmPasswordError(EMPTY_STRING);
                } else {
                    setFormConfirmPasswordError('Please enter the same password.');
                }
            }
        }
    }

    //Update Details
    const handleUpdateDetails = () => {

        if (oldPassword) {
            if (!newPassword && !confirmPassword) {
                setFormNewPasswordError("Please enter new password.");
                setFormConfirmPasswordError("Please enter the same password.");
                return
            }
        }

        if (newPassword) {
            if (!oldPassword && !confirmPassword) {
                setFormOldPasswordError("Please enter old password.");
                setFormConfirmPasswordError("Please enter the same password.");
                return
            }
        }

        if (confirmPassword) {
            if (!oldPassword && !newPassword) {
                setFormOldPasswordError("Please enter old password.");
                setFormNewPasswordError("Please enter new password.");
                return
            }
        }

        setTimeout(() => {
            if (
                (formFirstNameError === EMPTY_STRING) &&
                (formLastNameError === EMPTY_STRING) &&
                (formPhoneError === EMPTY_STRING) &&
                (formOldPasswordError === EMPTY_STRING) &&
                (formNewPasswordError === EMPTY_STRING) &&
                (formConfirmPasswordError === EMPTY_STRING)
            ) {
                let data = new FormData();
                data.append('firstName', formFirstName);
                data.append('lastName', formLastName);
                data.append('mobileNumber', formPhone);

                if ((oldPassword !== EMPTY_STRING && newPassword !== EMPTY_STRING && confirmPassword !== EMPTY_STRING)) {
                    if (oldPassword === newPassword) {
                        toast.warn("Password is identical to your previous passwords. Please add a new password.");
                        return
                    } else {
                        data.append('oldPassword', oldPassword);
                        data.append('password', newPassword);
                    }
                } else if ((formFirstName === firstName) && (formLastName === lastName) && (formPhone === phone)) {
                    console.log("No changes to update.")
                    return
                }

                client
                    .post(UPDATE_USER_DETAILS, data)
                    .then(res => {
                        if (res.data.status === SUCCESS) {

                            setFirstName(formFirstName);
                            setLastName(formLastName);
                            setPhone(formPhone);

                            //Update Loacal Variable User Details if Password not changed
                            if (oldPassword === EMPTY_STRING) {
                                toast.success(res.data.message);
                                document.getElementById('userTitle').innerText = formFirstName + ' ' + formLastName;
                                const userDetails = JSON.parse(localStorage.getItem("userDetails"));
                                let updatedDetails = userDetails;
                                updatedDetails.firstName = formFirstName;
                                updatedDetails.lastName = formLastName;
                                localStorage.setItem("userDetails", JSON.stringify(updatedDetails));
                                return
                            } else {
                                //Redirect to login page if password changed successfully
                                toast.success("passowrd changed successfully. Please login again.")
                                userService.logout().then(result => {
                                    if (result.status === SUCCESS) {
                                        localStorage.removeItem('defaultSelectedBrand');
                                        localStorage.removeItem('userDetails');
                                        localStorage.removeItem('userAuth');
                                        localStorage.removeItem('spyUser');
                                        setTimeout(() => {
                                            history.push('/');
                                        }, 1400);
                                    } else {
                                        if (res.data.status === WARN) toast.warn(res.data.message)
                                        else toast.error(res.data.message)
                                    }
                                }).catch(error => toast.error(error.response.data.message))
                            }
                        } else {
                            if (res.data.status === WARN) toast.warn(res.data.message)
                            else toast.error(res.data.message)
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.data.status === WARN) toast.warn(error.response.data.message)
                            else toast.error(error.response.data.message)
                        }
                    });
            } else
                toast.warn("Invalid Form Data");
        }, 200);
    }

    const handleImageUpload = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        let reader = new FileReader();

        reader.onload = () => {
            setUserImage(reader.result);
        }

        if (file)
            reader.readAsDataURL(file)
    }

    const resetImage = (event) => {
        event.stopPropagation();
        setUserImage(null);
    }

    useEffect(() => {
        getUserDetails();
    }, []);

    return (
        <React.Fragment>
            {visiblePreloader ? <DataPreloader /> : null}
            <h1>Edit User Profile</h1>
            <Paper className={classes.root}>
                <div className="edit-user">
                    <Grid container className={classes.root} spacing={0}>
                        <Grid item xs={12} sm={12} md={2}>
                            <div className="userProfilePic">
                                <div className="profilePicHolderTable">
                                    <div className="profilePicHolder">
                                        {!userImage
                                            ? <React.Fragment>
                                                <i className="fa fa-user" aria-hidden="true"></i>
                                                <input title="" type="file" accept="image/*" onChange={handleImageUpload} data-testid="image-input" />
                                            </React.Fragment>
                                            : <React.Fragment>
                                                <span style={{ backgroundImage: 'url(' + userImage + ')' }}></span>
                                                <Tooltip title="remove image">
                                                    <IconButton onClick={resetImage}>
                                                        <Close />
                                                    </IconButton>
                                                </Tooltip>
                                            </React.Fragment>
                                        }
                                    </div>

                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={10}>
                            <div className="userProfileDetails">
                                <h1>{firstName + ' ' + lastName}</h1>
                                <h3><div><Phone /> {phone}</div></h3>
                                <h3><div><Mail /> {email}</div></h3>
                                <h3 style={{ color: '#2396f3' }}><span>Current Plan: </span>{currentPlan}</h3>
                            </div>

                            <div className="userProfileDetails">
                                <h4><div><Edit /> Edit Details</div></h4>
                                <form className={classes.container} noValidate autoComplete="off">
                                    <Grid container className={classes.root} spacing={0}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <div className="paddingRight-12">
                                                <TextField
                                                    //autoComplete="on"
                                                    id="user-first-name"
                                                    name="user-first-name"
                                                    label="First Name"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={formFirstName}
                                                    onChange={(e) => handleChange(e, 'formFirstName')}
                                                    inputProps={{ "data-testid": "firstname" }}
                                                    helperText="Enter First Name"
                                                />
                                                {formFirstNameError === EMPTY_STRING ? null : <span className="errorMsg">{formFirstNameError}</span>}
                                                <TextField
                                                    //autoComplete="on"
                                                    id="user-last-name"
                                                    name="user-last-name"
                                                    label="Last Name"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={formLastName}
                                                    onChange={(e) => handleChange(e, 'formLastName')}
                                                    inputProps={{ "data-testid": "lastname" }}
                                                    helperText="Enter Last Name"
                                                />
                                                {formLastNameError === EMPTY_STRING ? null : <span className="errorMsg">{formLastNameError}</span>}
                                                <TextField
                                                    autoComplete="off"
                                                    id="user-phone-number"
                                                    name="user-phone-number"
                                                    label="Phone"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={formPhone}
                                                    onChange={(e) => handleChange(e, 'formPhone')}
                                                    inputProps={{ "data-testid": "phone" }}
                                                    helperText={<span>Phone Number Format should be like <em>9876543210</em></span>}
                                                />
                                                {formPhoneError === EMPTY_STRING ? null : <span className="errorMsg">{formPhoneError}</span>}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <div className="paddingLeft-12">
                                                <TextField
                                                    autoComplete="user-old-password"
                                                    type={"password"}
                                                    id="user-old-password"
                                                    name="user-old-password"
                                                    label="Old Password"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    helperText="Enter Old Password"
                                                    value={oldPassword}
                                                    onChange={(e) => handleChange(e, 'oldPassword')}
                                                    inputProps={{ "data-testid": "oldPassword" }}
                                                />
                                                {formOldPasswordError === EMPTY_STRING ? null : <span className="errorMsg">{formOldPasswordError}</span>}
                                                <TextField
                                                    autoComplete="user-new-password"
                                                    type={"password"}
                                                    id="user-new-password"
                                                    name="user-new-password"
                                                    label="New Password"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    helperText={<span>Password should be min 8 and max 15 characters and must contain any three of the following: <br />
                                                        One uppercase alphabet <br />
                                                        One lowercase alphabet (A-Z,a-z) <br />
                                                        One digit (0-9)<br />
                                                        One special character</span>}
                                                    value={newPassword}
                                                    onChange={(e) => handleChange(e, 'newPassword')}
                                                    inputProps={{ "data-testid": "newPassword" }}
                                                />
                                                {formNewPasswordError === EMPTY_STRING ? null : <span className="errorMsg">{formNewPasswordError}</span>}
                                                <TextField
                                                    autoComplete="user-confirm-password"
                                                    type={"password"}
                                                    id="user-confirm-password"
                                                    name="user-confirm-password"
                                                    label="Confirm Password"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    helperText="Don't forget to confirm your password"
                                                    value={confirmPassword}
                                                    onChange={(e) => handleChange(e, 'confirmPassword')}
                                                    inputProps={{ "data-testid": "confirmPassword" }}
                                                />
                                                {formConfirmPasswordError === EMPTY_STRING ? null : <span className="errorMsg">{formConfirmPasswordError}</span>}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container className={classes.root} spacing={0}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Button variant="contained" color="primary" className={classes.updateButton} onClick={handleUpdateDetails}>Update Details</Button>
                                            <Button variant="contained" color="default" onClick={handleReset}>Reset Details</Button>
                                        </Grid>
                                    </Grid>

                                </form>

                            </div>
                        </Grid>
                    </Grid>

                </div>
            </Paper>
        </React.Fragment>
    )
}

import React, { Component } from "react";
import { uniqBy } from "lodash";
import parse from "html-react-parser";
import { connect } from "react-redux";
import { PALETTE_COLORS } from "../../../../Constants";
import ErrorBoundary from "../../../ErrorBoundary/ErrorBoundary";
import Highlight from "../../highlight";

class ContextWrapper extends Component {
  render() {
    const {
      context,
      isCandidate,
      isNgram,
      atdState,
      hIsActive,
      paletteList
    } = this.props;

    const renderHtmlParse = (strElm) => {
      if (strElm) return parse(strElm);
    };

    const uniqCtxTopics = context.topics
     ? uniqBy(context.topics, "topic")
     : []

    const contextTopics = uniqCtxTopics
      .filter(t => t.topic.toLowerCase() === context.topicSelected.toLowerCase())
      .concat(uniqCtxTopics.filter(t => t.topic !== "" && t.topic.toLowerCase() !== context.topicSelected.toLowerCase()));

    return (
      <React.Fragment> 
        <ErrorBoundary>
      { 
        context &&         
        <div
          id={`${"context-" + context.contextId}`}
          className="phrase-list-items"
        >
          {(isCandidate || isNgram) ? null : (
            <div className="phrase-context-topics">
              {contextTopics
                .map((t, k) => {
                    const topicClass =
                      t.topic.toLowerCase() === context.topicSelected.toLowerCase()
                        ? "context-topic-tag context-topic-tag-phrase"
                        : "context-topic-tag";
                    return (
                      <div
                        key={k}
                        className={topicClass}
                        data-value={t.value}
                        data-topic={t.topic}
                        onMouseOver={(e) => {
                          const value = e.target.dataset.value;
                          const childrenArray = [
                            ...e.target.parentNode.nextSibling.childNodes[0]
                              .children,
                          ];
                          childrenArray.forEach((el) => {
                            if (
                              el.localName === "mark" &&
                              el.innerText.trim().toLowerCase() === value.trim().toLowerCase()
                            ) {
                              el.classList.add("phraseHighlight");
                            }
                          });
                        }}
                        onMouseLeave={(e) => {
                          const childrenArray = [
                            ...e.target.parentNode.nextSibling.childNodes[0]
                              .children,
                          ];
                          childrenArray.forEach((el) => {
                            if (el.localName === "mark") {
                              el.classList.remove("phraseHighlight");
                            }
                          });
                        }}
                        data-testid={`topic_${t.topic}`}
                      >
                        {`${t.topic} | ${t.frequency}`}
                      </div>
                    );
                  })}
            </div>
                )}
        { 
          <div
            className={
              (isCandidate || isNgram)
                ? "phrase-context full-width"
                : "phrase-context"
            }
            onMouseUp={(e) => {
              if (atdState === "locked") return;
              this.props.handleTextSelection(e, context);
            }}
            data-testid='phrase-context'
          >
            <Highlight
              topics={hIsActive ? paletteList : []}
              colors={PALETTE_COLORS}
            >
              {renderHtmlParse(context.context)}
            </Highlight>
          </div>  }
        </div> } 
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    paletteList: state.topicList,
  }
}

export default React.memo(connect(mapStateToProps, null)(ContextWrapper))

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles, makeStyles } from '@material-ui/core/styles'

import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt'
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied'

import { EMPTY_ARRAY, EMPTY_STRING } from '../../../Constants'
import { toast } from 'react-toastify'
import { seedFileParser } from '../atd-utils'
import { getFileType } from '../../../_helpers/utils'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    padding: '0px',
    [theme.breakpoints.up(500)]: {
      padding: '0px'
    }
  },
  dialogContent: {
    padding: '0px',
    [theme.breakpoints.up(500)]: {
      padding: '0px',
    }
  },
  textField: {
    paddingLeft: '5px',
    marginTop: '-5px',
    marginBottom: '10px',
    [theme.breakpoints.up(500)]: {
      marginTop: '0',
      paddingLeft: '10px',
    },
  },
  radioGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  radioButton: {
    padding: '2px',
    [theme.breakpoints.up(500)]: {
      padding: '5px'
    },
  },
  icon: {
    fontSize: '40px',
    color: '#ccc',
    [theme.breakpoints.up(500)]: {
      fontSize: '50px'
    },
    [theme.breakpoints.down(321)]: {
      fontSize: '35px'
    },
  },
  positiveIcon: {
    fontSize: '40px',
    color: '#69a009',
    [theme.breakpoints.up(500)]: {
      fontSize: '50px'
    },
    [theme.breakpoints.down(321)]: {
      fontSize: '35px'
    },
  },
  negativeIcon: {
    fontSize: '40px',
    color: '#e86740',
    [theme.breakpoints.up(500)]: {
      fontSize: '50px'
    },
    [theme.breakpoints.down(321)]: {
      fontSize: '35px'
    },
  },
  neutralIcon: {
    fontSize: '40px',
    color: 'rgba(0, 0, 0, 0.87)',
    [theme.breakpoints.up(500)]: {
      fontSize: '50px'
    },
    [theme.breakpoints.down(321)]: {
      fontSize: '35px'
    },
  },
  dropzoneWrapper: {
    width: '100%',
    height: '216px',
    position: 'relative'
  },
  dropzone: {
    width: 'calc(100% - 20px)',
    height: '100%',
    margin: '10px',
    border: '2px dashed #1890FF',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  hiddenInput: {
    opacity: 0,
    width: '100%',
    height: '100% !important'
  },
  inputLabel: {
    position: 'absolute'
  },
  spinner: {
    width: '100%',
    height: '200px',
    display: 'flex',
    justifyContent:'center',
    alignItems: 'center',
  },
  table: {
    border: '1px solid black',
    borderRadius: '8px'
  },
  textAreaWrapper: {
    width: '100%',
    height: '216px',
    margin: '5px 0px'
  },
  textArea: {
    width: '100%',
    height: '100% !important',
    overflowY: 'auto !important',
  }
}))

const EMPTY_TOPIC = { topicTitle: EMPTY_STRING, topicSentiment: EMPTY_STRING }

const CreateTopicModal = ({ open, seedFileData, setSeedFileData, handleModal, handleOk }) => {
  const [topicOne, setTopicOne] = useState(EMPTY_TOPIC)
  const [topicTwo, setTopicTwo] = useState(EMPTY_TOPIC)
  const [topicThree, setTopicThree] = useState(EMPTY_TOPIC)
  const [tabValue, setTabValue] = useState(0)
  const [uploading, setUploading] = useState(false)
  const [dataPasted, setDataPasted] = useState(false)

  const classes = useStyles()

  const disableSave = tabValue === 0 && Boolean(
    (!topicOne.topicTitle && !topicTwo.topicTitle && !topicThree.topicTitle) ||
    (topicOne.topicTitle && !topicOne.topicSentiment) ||
    (topicTwo.topicTitle && !topicTwo.topicSentiment) ||
    (topicThree.topicTitle && !topicThree.topicSentiment)
  ) || Boolean((tabValue === 1 && (!seedFileData.length || (dataPasted && seedFileData.length))))
    || Boolean((tabValue === 2 && (!seedFileData.length || (!dataPasted && seedFileData.length))))

  const handleSave = () => {
    if ((tabValue === 1 || tabValue === 2) && seedFileData.length) {
      if (dataPasted) setDataPasted(false)
      handleOk(seedFileData, true)
    } else {
      handleOk([topicOne, topicTwo, topicThree], false)
    }
    setTopicOne(EMPTY_TOPIC)
    setTopicTwo(EMPTY_TOPIC)
    setTopicThree(EMPTY_TOPIC)
  }

  const handleClose = () => {
    setTopicOne(EMPTY_TOPIC)
    setTopicTwo(EMPTY_TOPIC)
    setTopicThree(EMPTY_TOPIC)
    seedFileData.length && setSeedFileData(EMPTY_ARRAY)
    handleModal()
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDragEnter = e => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragLeave = e => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragOver = e => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleSeedFile = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (uploading) return

    let files = e.dataTransfer ? [...e.dataTransfer.files] : [...e.target.files]

    let inputFile = files.length > 0 ? files[0] : [];
    const fileType = getFileType(inputFile)
    if (files.length > 1 || fileType !== 'csv') {
      toast.warn('Only a single CSV file allowed.')
    } else {
      if (dataPasted) setDataPasted(false)
      setUploading(true)
      updateSeedData(files[0], true)
    }
  }

  const updateSeedData = async (data, fromFile) => {
    try {
      const seedData = await seedFileParser(data, fromFile)
      setSeedFileData(seedData)
      setUploading(false)
    } catch(err) {
      console.log(err)
      setUploading(false)
      toast.error('Unable to parse data.')
    }
  }

  const handlePastedData = (e) => {
    try {
      if (!e.clipboardData ||
        !e.clipboardData.items) return
      var data = e.clipboardData.items[0]
      setDataPasted(true)
      if (data && data.type === 'text/plain') {
        data.getAsString((text) => {
          updateSeedData(text, false)
        })
      }
    } catch(err) {
      console.log(err)
      setDataPasted(false)
      toast.error('Unable to parse data.')
    }
  }

  const PositiveRadio = ({ disabled }) => (
    <Radio
      classes={{
        root: classes.radioButton
      }}
      value='POSITIVE'
      icon={<SentimentSatisfiedAltIcon className={classes.icon} />}
      checkedIcon={<SentimentSatisfiedAltIcon className={classes.positiveIcon} />}
      disabled={disabled}
    />
  )

  const NegativeRadio = ({ disabled }) => (
    <Radio
      classes={{
        root: classes.radioButton
      }}
      value='NEGATIVE'
      icon={<SentimentVeryDissatisfiedIcon className={classes.icon} />}
      checkedIcon={<SentimentVeryDissatisfiedIcon className={classes.negativeIcon} />}
      disabled={disabled}
    />
  )

  const NeutralRadio = ({ disabled }) => (
    <Radio
      classes={{
        root: classes.radioButton
      }}
      value='NEUTRAL'
      icon={<SentimentSatisfiedIcon className={classes.icon} />}
      checkedIcon={<SentimentSatisfiedIcon className={classes.neutralIcon}  />}
      disabled={disabled}
    />
  )

  const SeedDataTable = () => (
    <TableContainer className={classes.table}>
      <Table aria-label="seed data table">
        <TableBody>
          <StyledTableRow>
            <TableCell variant='head'># Negative Topics</TableCell>
            <TableCell>
              {seedFileData.filter(e => e.Sentiment === 'NEGATIVE').length}
            </TableCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell variant='head'># Positive Topics</TableCell>
            <TableCell>
              {seedFileData.filter(e => e.Sentiment === 'POSITIVE').length}
            </TableCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell variant='head'># Neutral Topics</TableCell>
            <TableCell>
              {seedFileData.filter(e => e.Sentiment === '').length}
            </TableCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell variant='head'># Phrases</TableCell>
            <TableCell>
              {seedFileData.reduce((acc, e) => (acc + e.Phrase.length), 0)}
            </TableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='sm'
        fullWidth={true}
      >
        <DialogTitle className={classes.dialogTitle} id='alert-dialog-title'>
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            variant="fullWidth"
            centered
            aria-label="Create Topics Modal Navigation"
          >
            <Tab label="Create Topics" {...a11yProps(0)} />
            <Tab label="Drop Seed File" {...a11yProps(1)} />
            <Tab label="Paste Data" {...a11yProps(2)} />
          </Tabs>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <TabPanel value={tabValue} index={0}>
            <div className='createAtdTopics'>
              <Grid container>
                <Grid item xs={6} sm={7}></Grid>
                <Grid item xs={6} sm={5}>
                  <h4>Sentiment</h4>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} sm={7} className={classes.textField}>
                  <TextField
                    autoFocus
                    margin='dense'
                    id='topic-one'
                    label='Topic Title'
                    value={topicOne.topicTitle}
                    onChange={(e) => {
                      if(e.target.value.split(" ").length <= 5)
                      setTopicOne(state => ({
                        ...state,
                        topicTitle: e.target.value
                      }))
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={5} className={classes.radioGroup}>
                  <FormControl>
                    <RadioGroup 
                      row
                      value={topicOne.topicTitle ? topicOne.topicSentiment : ''}
                      onChange={e => setTopicOne(state => ({
                        ...state, topicSentiment: e.target.value
                      }))}
                    >
                      <NegativeRadio disabled={!topicOne.topicTitle}/>
                      <NeutralRadio  disabled={!topicOne.topicTitle}/>
                      <PositiveRadio disabled={!topicOne.topicTitle}/>
                    </RadioGroup>
                </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} sm={7} className={classes.textField}>
                  <TextField
                    margin='dense'
                    id='topic-two'
                    label='Topic Title'
                    value={topicTwo.topicTitle}
                    onChange={(e) => {
                      if(e.target.value.split(" ").length <= 5)
                      setTopicTwo(state => ({
                        ...state,
                        topicTitle: e.target.value
                      }))
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={5} className={classes.radioGroup}>
                  <FormControl>
                    <RadioGroup 
                      row
                      value={topicTwo.topicTitle ? topicTwo.topicSentiment : ''}
                      onChange={e => setTopicTwo(state => ({
                        ...state, topicSentiment: e.target.value
                      }))}
                    >
                      <NegativeRadio disabled={!topicTwo.topicTitle}/>
                      <NeutralRadio  disabled={!topicTwo.topicTitle}/>
                      <PositiveRadio disabled={!topicTwo.topicTitle}/>
                    </RadioGroup>
                </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} sm={7} className={classes.textField}>
                  <TextField
                    margin='dense'
                    id='topic-three'
                    label='Topic Title'
                    value={topicThree.topicTitle}
                    onChange={(e) => {
                      if(e.target.value.split(" ").length <= 5)
                      setTopicThree(state => ({
                        ...state,
                        topicTitle: e.target.value
                      }))
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={5} className={classes.radioGroup}>
                  <FormControl>
                    <RadioGroup 
                      row
                      value={topicThree.topicTitle ? topicThree.topicSentiment : ''}
                      onChange={e => setTopicThree(state => ({
                        ...state, topicSentiment: e.target.value
                      }))}
                    >
                      <NegativeRadio disabled={!topicThree.topicTitle}/>
                      <NeutralRadio  disabled={!topicThree.topicTitle}/>
                      <PositiveRadio disabled={!topicThree.topicTitle}/>
                    </RadioGroup>
                </FormControl>
                </Grid>
              </Grid>
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            { seedFileData.length && !dataPasted ? (
              <SeedDataTable />
            ) : (
              <Box className={classes.dropzoneWrapper}>
                <Box
                  className={classes.dropzone}
                  onDrop={e => handleSeedFile(e)}
                  onDragOver={e => handleDragOver(e)}
                  onDragEnter={e => handleDragEnter(e)}
                  onDragLeave={e => handleDragLeave(e)}
                >
                  { uploading ? (
                      <div className={classes.spinner}>
                        <span>Uploading</span>&nbsp;&nbsp;
                        <CircularProgress size={20} />
                      </div>
                    ) : <>
                      <label htmlFor='fileInput' className={classes.inputLabel}>Drag and drop your CSV file here.</label>
                      <input
                        id='fileInput'
                        type='file'
                        accept='.csv'
                        onChange={handleSeedFile}
                        className={classes.hiddenInput}
                        title=''
                      />
                    </>}
                </Box>
              </Box>
            )}
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            { seedFileData.length && dataPasted ? (
              <SeedDataTable />
            ) : (
              <Box className={classes.textAreaWrapper}>
                <TextareaAutosize
                  className={classes.textArea}
                  placeholder='Paste data here'
                  onPaste={handlePastedData}
                />
              </Box>
            )}
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='default'>
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color='primary'
            autoFocus
            disabled={disableSave}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CreateTopicModal
import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Input
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginRight: theme.spacing(1),
    minWidth: 100,
    maxWidth: 185
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))

export default function DropdownMultiple({
  label,
  value,
  options,
  handleChange
}) {
  const classes = useStyles()

  return (
    <Fragment>
      <FormControl className={classes.formControl} id='gram-dropdown-id'>
        <InputLabel id='gram-dropdown'>{label}</InputLabel>
        <Select
          labelId='gram-dropdown'
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          input={<Input />}
        >
          {options.map((weight) => (
            <MenuItem key={weight} value={weight}>
              {weight + '-gram'}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Fragment>
  )
}

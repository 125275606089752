import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import ReactDOM from "react-dom";
import RCTooltip from "rc-tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import {
  EMPTY_STRING,
  POSITIVE_EMOTIONS,
  NEGATIVE_EMOTIONS,
} from "../../../Constants";
import { mainTopicWithDataAttr, sideTopicWithDataAttr } from "../atd-utils";
import cloneDeep from "lodash.clonedeep";
import TopicCard from "./topic-card";
import { v4 as uuid } from "uuid";
import { toast } from "react-toastify";
import { ReactSortable } from "react-sortablejs";

class TopicScroll extends Component {
  state = {
    cmVisible: false,
    contextPosX: 0,
    contextPosY: 0,
    open: false,
    value: EMPTY_STRING,
    disabledBtn: false,
    phraseDroppedOnId: null,
    disableATP: false,
    prevData: 0,
    emotion: EMPTY_STRING,
    emotionKeyPressed: false,
    altKeyDown: false,
  };

  newTopic = React.createRef();

  modalItem = null;
  modalEvent = null;

  createNewPhrase = (topic, selectedText, context) => {
    try {
      const filteredCtxTopics = context.topics
        ? cloneDeep(context.topics).filter((st) => {
            if (selectedText.toLowerCase().search(st.value.toLowerCase()) > -1)
              return false;
            else return true;
          })
        : [];

      const mainTopic = [
        { frequency: 1, topic: topic.name, value: selectedText },
      ];
      const sideTopicObj = context.topics ? cloneDeep(filteredCtxTopics) : [];

      const hightLightMainTopic = mainTopicWithDataAttr(
        context.context.replace(/(<([^>]+)>)/gi, ""),
        mainTopic
      );

      const highlightSideTopic = sideTopicWithDataAttr(
        hightLightMainTopic,
        sideTopicObj,
        this.props.isTopicPaletteHighlightActive,
        selectedText
      );
      const updatedContext = {
        context: highlightSideTopic,
        contextId: uuid(),
        topics: filteredCtxTopics.length
          ? filteredCtxTopics.concat(mainTopic)
          : mainTopic,
      };

      let phraseObj = {};
      phraseObj["char_sim"] = 0;
      phraseObj["context"] = [updatedContext];
      phraseObj["frequency"] = 1;
      phraseObj["phrase"] = selectedText;
      phraseObj["phraseId"] = Math.floor(10000 + Math.random() * 9000);
      phraseObj["pos_tags"] = [];
      phraseObj["sem_sim"] = 0;
      phraseObj["isSeed"] = true;
      phraseObj["isCurated"] = true;

      this.props.updateSelectedTopicPhrases(topic, phraseObj);
    } catch (err) {
      console.log(err);
    }
  };

  allowDrop = (e) => {
    e.preventDefault();
  };

  drop = (e, topic) => {
    try {
      e.preventDefault();
      if (this.props.atdState === "locked") return;

      const droppedPhrases = e.dataTransfer.getData("Phrases");
      const str = window.getSelection().toString().trim();

      if (droppedPhrases.length !== 0)
        this.setState({ phraseDroppedOnId: topic.id }, () => {
          this.props.handleMovedPhrases(topic);
        });
      else {
        if (str.length === 0) return;
        this.setState({ phraseDroppedOnId: topic.id }, () => {
          this.createNewPhrase(topic, str, this.props.selectedContext);
        });
      }
      setTimeout(() => {
        this.setState({ phraseDroppedOnId: null });
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  getContainer = () => {
    try {
      if (!this.cmContainer) {
        this.cmContainer = document.createElement("div");
        document.body.appendChild(this.cmContainer);
      }
      return this.cmContainer;
    } catch (err) {
      console.log(err);
    }
  };

  /******************************* */
  /**** TOPIC PALETTE METHODS ****/
  /******************************* */

  handleAddTopicToPalette = (topic) => {
    try {
      if (this.props.atdState === "locked") return;
      if (!topic.isCurated)
        this.props.setAuditedTopics((state) => new Set(state).add(topic.id));
      const {
        addTopicToPalette,
        topicPalettes,
        selectedP,
        brandId,
        setShouldBlockNavigation,
      } = this.props;
      const palettes = topicPalettes[brandId].palettes;
      if (palettes.byId[selectedP].topicList.includes(topic.id)) {
        toast.warn("Topic already exists in the current palette.");
      } else {
        palettes.allIds.forEach((pId) => {
          if (pId === selectedP) {
            const pType = palettes.byId[pId];
            if (pType.topicList.length < 8) {
              addTopicToPalette(topic, selectedP, topicPalettes, brandId);
              setShouldBlockNavigation(true);
            } else {
              this.setState({
                disableATP: true,
              });
            }
          }
        });
      }

      ReactDOM.unmountComponentAtNode(this.cmContainer);
      document.body.removeChild(this.cmContainer);
      this.cmContainer = null;
    } catch (err) {
      console.log(err);
    }
  };

  /******************************* */
  /**** END TOPIC PALETTE METHODS ****/
  /******************************* */

  handleContextEvents = (topic, options) => {
    try {
      if (this.props.atdState === "locked") return;
      if (!topic.isCurated)
        this.props.setAuditedTopics((state) => new Set(state).add(topic.id));
      this.modalItem = topic;
      this.modalEvent = options;
      switch (options) {
        case "sentiment":
          this.setState({
            value: topic.sentiment.toLowerCase(),
            open: true,
          });
          break;
        case "emotion":
          this.setState({
            emotion: topic.emotion ? topic.emotion.toLowerCase() : "",
            disabledBtn: true,
            open: true,
          });
          break;
        case "rename":
          this.setState({
            value: topic.name,
            open: true,
          });
          break;
        case "delete":
          this.setState({ open: true });
          break;
        case "curation":
          this.props.handleSwapCuration(topic.id);
          break;
        default:
          break;
      }

      ReactDOM.unmountComponentAtNode(this.cmContainer);
      document.body.removeChild(this.cmContainer);
      this.cmContainer = null;
    } catch (err) {
      console.log(err);
    }
  };

  renderContextMenu = (e, topic) => {
    try {
      e.preventDefault();

      const { disableATP } = this.state;

      const disabledStyle = {
        color: "rgba(0, 0, 0, 0.3)",
      };

      const enabledStyle = {
        color: "inherit",
      };

      this.setState({
        cmVisible: !this.state.cmVisible,
        contextPosX: e.pageX,
        contextPosY: e.pageY,
      });

      setTimeout(() => {
        if (this.toolTip) {
          if (this.cmContainer != null) {
            ReactDOM.unmountComponentAtNode(this.cmContainer);
            this.toolTip = null;
          }
        }

        this.toolTip = (
          <RCTooltip
            trigger="click"
            placement="bottomRight"
            prefixCls="rc-tree-contextmenu"
            defaultVisible
            overlay={
              <ul className="contextmenu-ul">
                <li
                  style={disableATP ? disabledStyle : enabledStyle}
                  onClick={() => this.handleAddTopicToPalette(topic)}
                >
                  Add To Palette
                </li>
                <li onClick={() => this.handleContextEvents(topic, "emotion")}>
                  Change Emotion
                </li>
                {!topic.emotion && (
                  <li
                    onClick={() => this.handleContextEvents(topic, "sentiment")}
                  >
                    Change Sentiment
                  </li>
                )}
                <li onClick={() => this.handleContextEvents(topic, "curation")}>
                  Change Curation Status
                </li>
                <li onClick={() => this.handleContextEvents(topic, "rename")}>
                  Rename
                </li>
                <li onClick={() => this.handleContextEvents(topic, "delete")}>
                  Delete
                </li>
              </ul>
            }
          >
            <span />
          </RCTooltip>
        );

        const container = this.getContainer();
        Object.assign(this.cmContainer.style, {
          position: "absolute",
          left: `${this.state.contextPosX + 30}px`,
          top: `${this.state.contextPosY - 0}px`,
        });

        ReactDOM.render(this.toolTip, container);
      }, 200);
    } catch (err) {
      console.log(err);
    }
  };

  handleClose = () => {
    this.setState((state) => ({
      ...state,
      open: false,
      value: EMPTY_STRING,
      disabledBtn: false,
    }));
    setTimeout(() => {
      this.modalItem = null;
      this.modalEvent = null;
    }, 600);
  };

  //Rename Topic
  handleRename = () => {
    try {
      const { value } = this.state;
      if (value.trim().length === 0) {
        this.setState({ inputError: true });
        document.getElementById("topic-rename").focus();
        return;
      } else this.setState((state) => ({ ...state, inputError: false }));
      if (value.trim() === this.modalItem.name) {
        this.handleClose();
        return;
      }

      this.setState({ ...this.state, disabledBtn: true });

      this.props.updateTopics("rename", value, this.modalItem);
      this.handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  //Delete Topic
  handleDelete = () => {
    this.setState((state) => ({
      ...state,
      disabledBtn: true,
      deletedTopic: this.modalItem.id,
    }));
    this.props.updateTopics("delete", "", this.modalItem);
    this.handleClose();
  };

  //Update Topic Sentiment
  updateSentiment = () => {
    this.setState({ ...this.state, disabledBtn: true });
    const { value } = this.state;
    this.props.updateTopics("sentiment", value, this.modalItem);
    this.handleClose();
  };

  updateEmotion = () => {
    this.setState((state) => ({ ...state, disabledBtn: true }));
    this.props.updateTopics("emotion", this.state.emotion, this.modalItem);
    this.handleClose();
  };

  removeEmotion = () => {
    this.setState((state) => ({ ...state, disabledBtn: true }));
    this.props.updateTopics("emotion", "", this.modalItem);
    this.handleClose();
  };

  handleKeyDown = (e) => {
    try {
      if (e.key === "e" && !this.state.emotionKeyPressed) {
        this.setState((state) => ({
          ...state,
          emotionKeyPressed: true,
        }));
      } else if (e.key === "Alt" && !this.state.altKeyDown) {
        this.setState((state) => ({ ...state, altKeyDown: true }));
      } else if (e.key === "Tab" && this.state.altKeyDown) {
        console.log("Alt-TAB");
        this.setState((state) => ({ ...state, altKeyDown: false }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleKeyUp = (e) => {
    try {
      const { selectedTopics, updateTopics } = this.props;

      if (e.key === "e") {
        this.setState((state) => ({
          ...state,
          emotionKeyPressed: false,
        }));
      } else if (selectedTopics.length && this.state.emotionKeyPressed) {
        const key = parseInt(e.key);
        if (key === 0 || key === 9) {
          updateTopics("emotion", "", { id: selectedTopics[0] });
        } else if (key > 0 && key < 9) {
          updateTopics(
            "emotion",
            NEGATIVE_EMOTIONS.concat(POSITIVE_EMOTIONS)[key - 1],
            { id: selectedTopics[0] }
          );
        }
      } else if (e.key === "Alt" && this.state.altKeyDown) {
        this.setState((state) => ({ ...state, altKeyDown: false }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleTopicClick = (topic) => {
    this.props.handleClick(topic, this.state.altKeyDown);
  };

  componentDidMount() {
    this.getContainer();

    window.addEventListener("keydown", (e) => this.handleKeyDown(e));
    window.addEventListener("keyup", (e) => this.handleKeyUp(e));

    window.onfocus = () => {
      if (this.state.altKeyDown) {
        this.setState((state) => ({ ...state, altKeyDown: false }));
      }
    };
  }

  componentDidUpdate() {
    const { addedNewTopic, setAddedNewTopic, deletedTopic, setDeletedTopic } =
      this.props;

    if (addedNewTopic) {
      this.newTopic.current && this.newTopic.current.scrollIntoView();
      setTimeout(() => {
        setAddedNewTopic(false);
      }, 1000);
    }

    if (deletedTopic) {
      setTimeout(() => {
        setDeletedTopic(null);
      }, 1000);
    }
  }

  componentWillUnmount() {
    if (this.cmContainer) {
      ReactDOM.unmountComponentAtNode(this.cmContainer);
      document.body.removeChild(this.cmContainer);
      this.cmContainer = null;
      this.setState({ cmVisible: !this.state.cmVisible });
    }

    window.removeEventListener("keydown", (e) => this.handleEmotionKeyPress(e));
    window.removeEventListener("keyup", (e) => this.handleEmotionKeyPress(e));
  }

  renderEmotionModal = () => (
    <FormControl component="fieldset" className="emotion-radio-model">
      <RadioGroup
        row
        aria-label="emotion"
        name="emotion"
        value={this.state.emotion}
        onChange={(e) => {
          e.persist();
          this.setState((state) => ({
            ...state,
            emotion: e.target.value,
            disabledBtn: false,
          }));
        }}
      >
        <FormControlLabel
          label="Anger"
          value="anger"
          control={<Radio color="primary" size="small" />}
        />
        <FormControlLabel
          label="Frustration"
          value="frustration"
          control={<Radio color="primary" size="small" />}
        />
        <FormControlLabel
          label="Disappointment"
          value="disappointment"
          control={<Radio color="primary" size="small" />}
          style={{ minWidth: "176px" }}
        />
        <FormControlLabel
          label="Confusion"
          value="confusion"
          control={<Radio color="primary" size="small" />}
        />
        <FormControlLabel
          label="Happiness"
          value="happiness"
          control={<Radio color="primary" size="small" />}
        />
        <FormControlLabel
          label="Crave"
          value="crave"
          control={<Radio color="primary" size="small" />}
        />
        <FormControlLabel
          label="Gratitude"
          value="gratitude"
          control={<Radio color="primary" size="small" />}
          style={{ minWidth: "176px" }}
        />
        <FormControlLabel
          label="Excitement"
          value="excitement"
          control={<Radio color="primary" size="small" />}
        />
      </RadioGroup>
    </FormControl>
  );

  render() {
    return (
      <Fragment>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            {this.modalEvent === "delete"
              ? "Topic Delete"
              : this.modalEvent === "rename"
              ? "Topic Rename"
              : this.modalEvent === "sentiment"
              ? "Change Sentiment"
              : this.modalEvent === "emotion"
              ? "Change Emotion"
              : null}
          </DialogTitle>
          <DialogContent>
            {this.modalEvent === "delete" ? (
              <span>
                <span>Are you Sure to Delete&nbsp;</span>
                <span style={{ color: "#f55c5c" }}>{this.modalItem.name}</span>
                <span>?</span>
              </span>
            ) : this.modalEvent === "rename" ? (
              <TextField
                fullWidth
                id="topic-rename"
                placeholder="Topic Name"
                value={this.state.value}
                onChange={(e) => {
                  if (e.target.value.split(" ").length <= 5)
                    this.setState({ value: e.target.value });
                }}
                autoFocus
                onFocus={(e) => e.target.select()}
                error={this.state.inputError}
              />
            ) : this.modalEvent === "sentiment" ? (
              <FormControl component="fieldset" className="modal-radio">
                <RadioGroup
                  row
                  aria-label="sentiment"
                  name="sentiment"
                  value={this.state.value}
                  onChange={(e) => {
                    this.setState({ value: e.target.value });
                  }}
                >
                  <FormControlLabel
                    label="Positive"
                    value="positive"
                    control={<Radio color="primary" />}
                  />
                  <FormControlLabel
                    label="Negative"
                    value="negative"
                    control={<Radio color="primary" />}
                  />
                  <FormControlLabel
                    label="Neutral"
                    value="neutral"
                    control={<Radio color="primary" />}
                  />
                </RadioGroup>
              </FormControl>
            ) : this.modalEvent === "emotion" ? (
              this.renderEmotionModal()
            ) : null}
          </DialogContent>
          <DialogActions>
            {this.modalEvent === "emotion" && this.modalItem && (
              <Button
                disabled={!this.modalItem.emotion}
                onClick={this.removeEmotion}
              >
                Remove Emotion
              </Button>
            )}
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button
              color="primary"
              disabled={this.state.disabledBtn}
              onClick={
                this.modalEvent === "rename"
                  ? this.handleRename
                  : this.modalEvent === "delete"
                  ? this.handleDelete
                  : this.modalEvent === "sentiment"
                  ? this.updateSentiment
                  : this.modalEvent === "emotion"
                  ? this.updateEmotion
                  : null
              }
            >
              {this.modalEvent === "rename"
                ? "Rename"
                : this.modalEvent === "delete"
                ? "Delete"
                : this.modalEvent === "sentiment" ||
                  this.modalEvent === "emotion"
                ? "Change"
                : null}
            </Button>
          </DialogActions>
        </Dialog>

        <ReactSortable
          key={`sortable_${this.props.disableTopicReordering}`}
          list={this.props.data}
          setList={this.props.handleTopicsReordering}
          disabled={this.props.disableTopicReordering}
        >
          {this.props.data
            .filter((t) => t.frequency > -1)
            .map((list, index) => (
              <TopicCard
                key={list.id}
                index={index}
                className
                item={list}
                variant={
                  this.props.selectedTopics.includes(list.id)
                    ? "default"
                    : "outlined"
                }
                modalItem={this.modalItem}
                phraseDroppedOnId={this.state.phraseDroppedOnId}
                addedNewTopic={
                  this.props.addedNewTopic &&
                  index === this.props.data.length - 1
                }
                deletedTopic={
                  this.props.deletedTopic && list.id === this.props.deletedTopic
                }
                onClick={this.handleTopicClick}
                onDragOver={this.allowDrop}
                onDrop={this.drop}
                onContextMenu={this.renderContextMenu}
                ref={index >= this.props.data.length - 1 ? this.newTopic : ""}
              />
            ))}
        </ReactSortable>

        <div className="divider my-4" />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    topicPalettes: state.topicPalettes,
    selectedP: state.selectedPalette,
  };
};

export default React.memo(connect(mapStateToProps, actions)(TopicScroll));

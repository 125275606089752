import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import ReactDOM from "react-dom";
import Tooltip from "rc-tooltip";
import clsx from "clsx";
import { get } from "lodash";
import isEqual from "lodash.isequal";
import styled from 'styled-components';
import ReactList from "react-list";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { toast } from "react-toastify";
import cloneDeep from "lodash.clonedeep";
import { v4 as uuidv4 } from 'uuid';
import Rangy from 'rangy';
import 'rangy/lib/rangy-textrange';

import { Lock } from "@material-ui/icons";
import Popover from '@material-ui/core/Popover';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { SUCCESS } from "../../../Constants";
import { GET_UNCLASSIFIED_DATA, GET_TOPICS_PHRASE_CONTEXT } from "../../../Routes";
import client from "../../../_helpers/client";
import { highLightCandidate, 
  candidateMainHighLight, 
  candidateSideHighLight, 
  ngramMainHighLight
} from "../atd-utils";

import PhraseCard from './phrase-card';
import ContextWrapper from './context-wrapper';
import UnclassifiedContext from './unclassified-context';
import Autocomplete from "../../../containers/AutoComplete";

import InfiniteScroll from 'react-infinite-scroll-component';

const ContextHighlight = styled.div`
  div[data-topic="${props => props.selected}"]{
    background-color: ${props => props.color} !important; 
  };
`
class PhraseScroll extends Component {
  constructor(props) {
    super(props);
    this.currentPhrase = React.createRef();
  }
  state = {
    items: Array.from({ length: 20 }),
    hasMore: true,
    contextPosX: 0,
    contextPosY: 0,
    open: false,
    selectedContext: [],
    phraseMoved: null,
    phraseToBeDeleted: null,
    highLightEvents : false,
    removeHighlight: false,
    highlightedText: null,
    openPopover: false,
    anchorEl: null,
    visibleOptions: false,
    keyDown: null
  };

  selectedCtx = null;
  selectedText = null;
  modalItem = null;
  modalEvent = null;
  modifiedContext = [];

  fetchedPage = 0;
  
  //Handle Phrase option events
  handlePhraseOptions = (el, options) => {
    try {
      if(this.props.atdState === 'locked')
        return
      if (!el.isCurated) this.props.updateAuditedPhrases([el.phraseId]);
      this.modalItem = el;
      this.modalEvent = options;
      // Clicked phrase is also selected
      const isSelected = this.props.selectedPhrases.includes(el.phraseId);
      switch (options) {
        case "move":
          el.topics = [];
          if (isSelected && this.props.selectedPhrases.length > 1) {
            this.setState({ phraseMoved: el.phraseId }, () => {
              this.props.addToCandidates(el, el.phraseId, "move-selected");
              this.setState({ phraseMoved: null });
            });
          } else {
            this.setState({ phraseMoved: el.phraseId }, () => {
              this.props.addToCandidates(el, el.phraseId, "move");
              this.setState({ phraseMoved: null });
            });
          }
          break;
        case "delete":
          this.setState({ phraseToBeDeleted: el.phraseId, open: true });
          break;
        default:
          break;
      }
      this.setState({highLightEvents: false})
    } catch(err) {
      console.log(err);
    }
  };

  // New Topics Creation using Create New Topic icon
  handleNewTopicCreation = (phrase) => {
    try {
      if(this.props.atdState === 'locked')
        return

      if (!phrase.isCurated) this.props.updateAuditedPhrases([phrase.phraseId]);
      this.props.processedEntity(phrase.phraseId, "move");

      if (this.isSelected(phrase.phraseId) && this.props.selectedPhrases.length > 1) {
        this.props.handleNewTopic(phrase, true);
      } else {
        this.props.handleNewTopic(phrase, false);
      }
    } catch(err) {
      console.log(err);
    }
  }

  handlePopupEvent = (options, paletteUsed) => {
    try {
      const text = cloneDeep(this.selectedText);
      const ctx = this.selectedCtx;
      const { data, isCandidate, isNgram } = this.props;

      //Add to Candidate Event
      if (options === "candidate") {
        let newPhrase = {};

        //update selected context
        let updatedCtx = {};
        updatedCtx = {
          context: highLightCandidate(ctx.context, [text]),
          contextId: ctx.contextId,
          phrase: this.props.isunClassified ? text : ctx.phrase,
          phraseId: ctx.phraseId
        }

        //on unClassified Documents
        if (this.props.isunClassified) {
          //update unclassified phrases
          const updatedDocs = cloneDeep(data).map(p => {
            if (p.phraseId === ctx.phraseId) {
              p.context = p.context.map(c => {
                if (c.contextId === ctx.contextId) {
                  const updatedCandidates = c.candidates ? c.candidates.concat(text) : [text];
                  const ct = highLightCandidate(c.context, updatedCandidates);
                  c.context = ct;
                  c.candidates = updatedCandidates
                }
                return c
              })
            }
            return p
          });

          const newPhraseAry = cloneDeep(data).filter(p => p.phraseId === ctx.phraseId);
          newPhrase = newPhraseAry[0];

          newPhrase.phraseId = Math.floor(10000 + Math.random() * 9000);
          newPhrase.phrase = text;
          newPhrase.frequency = 1;
          newPhrase.context = newPhrase.context.filter(c => c.contextId === ctx.contextId).map(c => {
            c.context = highLightCandidate(ctx.context.replace(/(<([^>]+)>)/ig, ''), [text])
            c.contextId = uuidv4()
            c.topics = []
            c.candidates = c.candidates ? c.candidates.concat(text) : [text]
            return c
          })
          newPhrase.isCurated =  true;
          this.props.newCandidatePhrase(updatedDocs, updatedCtx, newPhrase);
        } else {
          //Update Topic Phrases/Ngram Phrases/Candidate Phrases
          const updatedPhrases = cloneDeep(data).map(p => {
            if (p.phraseId === ctx.phraseId) {
              p.context = p.context.map(c => {
                if (c.contextId === ctx.contextId) {
                  let sideWordss = c.topics
                    ? c.topics.filter(m => m.value.toLowerCase() !== ctx.phrase.toLowerCase()).map(t => t.value)
                    : []
                  const ct = highLightCandidate(c.context.replace(/(<([^>]+)>)/ig, ''), [text]);
                  const ctm = isNgram ? ngramMainHighLight(ct, ctx.phrase) : candidateMainHighLight(ct, ctx.phrase)
                  if (isCandidate) {
                    //remove class and data attribute
                    c.context = candidateSideHighLight(ctm, sideWordss);
                  } else {
                    c.context = highLightCandidate(c.context, [text]);
                  }
                  c.candidates = c.candidates ? c.candidates.concat(text) : [text]
                }
                return c
              })
            }
            return p
          });

          if (isNgram || isCandidate) { }
          else updatedCtx.topics = ctx.topics;

          //define new phrase data
          const newPhraseAry = cloneDeep(data).filter(p => p.phraseId === ctx.phraseId);
          newPhrase = newPhraseAry[0];
          const filterdContext = newPhrase.context.filter(fct => fct.contextId === ctx.contextId)
          newPhrase.phraseId = Math.floor(10000 + Math.random() * 9000);
          newPhrase.phrase = text;
          newPhrase.frequency = 1;
          newPhrase.isCurated = true;
          newPhrase.pos_tags = ctx.pos_tags;
          newPhrase.context = filterdContext.map(c => {
            let mainWord = ctx.phrase;
            let sideWords = ctx.topics
              ? ctx.topics.filter(m => m.value.toLowerCase() !== ctx.phrase.toLowerCase()).map(t => t.value)
              : []
            const ct = highLightCandidate(ctx.context.replace(/(<([^>]+)>)/ig, ''), [text])
            const mct = candidateMainHighLight(ct, mainWord)
            c.context = candidateSideHighLight(mct, sideWords)
            c.contextId = uuidv4()
            c.topics = (isCandidate || isNgram) ? [] : c.topics
            c.candidates = c.candidates ? c.candidates.concat(text) : [text]
            return c
          })
          this.props.newCandidatePhrase(updatedPhrases, updatedCtx, newPhrase)
        }

      } else if (options === "update") {
        const { highlightedText } = this.state;
        this.props.updateHighlightedPhrase(text, ctx, highlightedText, paletteUsed);
      }  else {
        this.props.handleAddText(options, text, paletteUsed);
      }

      if (this.cmContainer) {
        ReactDOM.unmountComponentAtNode(this.cmContainer);
        document.body.removeChild(this.cmContainer);
        this.cmContainer = null;
      }
      this.selectedText = null;
      window.getSelection().removeAllRanges();
    } catch(err) {
      console.log(err);
    }
  };

  //Tooltip after close
  afterVisibleChange = (visible) => {
    this.selectedText = null;
  }

  // Key Down Event
  keyDownHandling = (e) => {
    if (e.target.nodeName === "INPUT") return;
    else if (!this.state.keyDown && (e.key === 'p' || e.key === 'e')) {
      this.setState((state) => ({
        ...state,
        keyDown: e.key
      }));
    }
  }

  //Key Up Event 
  keyUpHandling = (event) => {
    try {
      const { keyDown } = this.state;
      if (event.target.nodeName === "INPUT") return;
      else if (keyDown && event.key !== keyDown) return;
      else {
        this.setState((state) => ({
          ...state,
          keyDown: null
        }));
        const { isunClassified, selectedPhrases } = this.props;
        if (this.cmContainer) {
          if (event.key === '9' && !window.getSelection().isCollapsed) {
            this.handlePopupEvent('candidate')
          } else if (
            (this.selectedText && event.key < 9 && event.key > 0) ||
            (event.key <= 8 &&
              event.key >= 1 &&
              !isunClassified &&
              selectedPhrases.length)
          ) {
            this.handlePaletteKeyPress(event, event.keyCode)
          }
        } else if (window.getSelection().isCollapsed && event.key <= 8 && event.key >= 1) {
          this.handlePaletteKeyPress(event, event.keyCode)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  // Move Selected Phrase on Topic Palette Action
  moveSelectedPhrases = () => {
    try {
      const { selectedTopic, handleMovedPhrases } = this.props;
      if (selectedTopic.id) {
        handleMovedPhrases(selectedTopic, true)
      }
    } catch(err) {
      console.log(err);
    }
  }

  //Highlight key Events
  HighlightKeyHandling=(event)=> {
    try {
      if(this.state.highLightEvents) {
        const data = this.props.data;
        const selPhrase = cloneDeep(data).filter(p => p.phraseId === this.selectedCtx.phraseId);
        if((event.key === "0" || event.key === 'Delete') && selPhrase.length) {
          this.setState({removeHighlight: true},() => {
            this.handlePhraseOptions(selPhrase[0], "delete");
          })
        } else if(event.key === "9" && selPhrase.length) {
          this.handlePopoverClose();
          this.handlePhraseOptions(selPhrase[0], "move");
        } else if (this.selectedText && !window.getSelection().isCollapsed) {
          this.handlePaletteKeyPress(event, event.keyCode)
        }
      }
    } catch(err) {
      console.log(err);
    }
  }

  getContainer = () => {
    try {
      if (!this.cmContainer) {
        this.cmContainer = document.createElement("div");
        document.body.appendChild(this.cmContainer);
      }
      return this.cmContainer;
    } catch(err) {
      console.log(err);
    }
  };

  //Context Menu for List
  renderTooltip = (e, highlightedTextSelected) => {
    try {
      e.persist();
      e.preventDefault();

      const selectedText = window.getSelection().toString();
      if (selectedText.trim().length === 0) return;

      const nodeName = e.target.nodeName; 

      const contextPosX = e.pageX + 5, contextPosY = e.pageY - 10;
      setTimeout(() => {
        if (this.toolTip) {
          if (this.cmContainer !== null) {
            ReactDOM.unmountComponentAtNode(this.cmContainer);
            this.toolTip = null;
          }
        }

        if (!this.selectedText) return;
        
        this.toolTip = (
          <Tooltip
            id="popUp"
            trigger="click"
            placement={"top"}
            prefixCls="rc-tree-contextmenu"
            defaultVisible
            afterVisibleChange={this.afterVisibleChange}
            overlay={
              (<ul className="phrase-context-options">
                {((highlightedTextSelected || nodeName === 'MARK') && !this.props.isNgram) ? (
                  <li onClick={() => this.handlePopupEvent("update")}>
                    Update Phrase
                  </li>
                ) : (
                  <>
                    {(this.props.isCandidate || this.props.isNgram || this.props.isunClassified) ? null : (
                      <li onClick={() => { this.handlePopupEvent("phrases") }}>
                        Add to Phrases
                      </li>
                    )}
                    <li onClick={() => this.handlePopupEvent("candidate")}>
                      Add to Candidate
                    </li>
                    <li onClick={() => this.handlePopupEvent("topic")}>
                      Create New Topic
                    </li>
                  </>
                )}
              </ul>)
            }
          >
            <span />
          </Tooltip>
        );

        const container = this.getContainer();
        Object.assign(this.cmContainer.style, {
          position: "absolute",
          left: `${contextPosX}px`,
          top: `${contextPosY}px`,
        });

        ReactDOM.render(this.toolTip, container);
      }, 200);
    } catch(err) {
      console.log(err);
    }
  };

  handleClose = () => {
    try {
      let childelm = [...document.getElementsByClassName("selectedHighlight")];
      childelm.forEach((elm)=>{ elm.classList.remove('selectedHighlight') })
      this.setState({ open: false, openPopover: false });
      setTimeout(() => {
        this.modalItem = null;
        this.modalEvent = null;
      }, 600);
    } catch(err) {
      console.log(err);
    }
  };

  //Phrase delete event
  handleDelete = () => {
    try {
      const { removeHighlight } = this.state;
      if(removeHighlight) {
        this.props.removeHighlight(this.selectedText,this.modalItem,this.selectedCtx);
        this.handleClose();
        setTimeout(() => {
          this.setState({ removeHighlight: false });
        }, 200);
      } else {
        const isSelected = this.props.selectedPhrases.includes(this.modalItem.phraseId)
        if(isSelected && this.props.selectedPhrases.length > 1) {
          this.props.addToCandidates(this.modalItem, this.modalItem.phraseId, 'delete-selected');
        } else {
          this.props.addToCandidates(this.modalItem, this.modalItem.phraseId, 'delete');
        }
        this.props.processedEntity(this.modalItem.phraseId,"delete")
        this.handleClose();
        setTimeout(() => {
          this.setState({ phraseToBeDeleted: null });
        }, 50);
      }
    } catch(err) {
      console.log(err);
    }
  };

  handlePopoverClose = () => {
    this.selectedText = null;
    this.setState({ openPopover: false, anchorEl: null, highLightEvents: false });
    let childelm = [...document.getElementsByClassName('selectedHighlight')];
    childelm.forEach((elm) => {
      elm.classList.remove('selectedHighlight')
    });
    window.removeEventListener("keyup", this.HighlightKeyHandling);
  }

  getMarkHighlight=(e, ctx) => {
    try {
      let childelm = [...document.getElementsByClassName("selectedHighlight")];
      childelm.forEach((elm)=>{ elm.classList.remove('selectedHighlight') })
      if(e.target.nodeName === 'MARK') {
        ctx.textType = [...e.target.classList]
        this.selectedText = e.target.outerText;
        this.selectedCtx = ctx;
        this.props.handleSelectedContext(ctx);
        e.target.classList.add("selectedHighlight");
        this.setState({ highLightEvents: true, openPopover: true, anchorEl: e.target }, () => {
          window.addEventListener("keyup", this.HighlightKeyHandling);
        })
      } else {
        this.selectedCtx = null;
        window.removeEventListener("keyup", this.HighlightKeyHandling);
        this.setState({ highLightEvents: false })
      }
    } catch(err) {
      console.log(err);
    }
  }

  selectedHighlightedText = (selection) => {
    try {
      const selectedNode = selection.getRangeAt(0).cloneContents();

      if (selectedNode.querySelector('.phrase-list-items'))
        return "ERROR";

      if(selectedNode.querySelector('mark')) {
        this.setState(state => ({
          ...state, highlightedText: [selectedNode.querySelector('mark')]
        }));
        return "YES"
      }

      return "NO";
    } catch(err) {
      return "NO";
    }
  }

  // Phrase selection event
  handleTextSelection = (e, ctx) => {
    try {
      e.persist();
      if (!window.getSelection().isCollapsed)
        Rangy.getSelection().expand("word", { trim: true });
      const selection = window.getSelection();
      const str = selection.toString().trim();
      this.selectedText = str;
      const selectedHighlight = str.length && this.selectedHighlightedText(selection);

      if (str.trim().length && selectedHighlight !== "ERROR") {
        this.props.handleSelectedContext(ctx);
        this.selectedCtx = ctx;
        setTimeout(() => {
          this.renderTooltip(e, selectedHighlight === "YES");
        }, 100)
      } else if (str.trim().length) {
        toast.warn("Selection over multiple documents not allowed.");
        selection.removeAllRanges()
      } else {
        this.getMarkHighlight(e, ctx)
      }
    } catch(err) {
      console.log(err);
    }
  }

  isSelected = (id) => (
    this.props.selectedPhrases.includes(id)
  )

  /******************************* */
  /**** TOPIC PALETTE METHODS ****/
  /******************************* */

  handlePaletteKeyPress = (e, key) => {
    e.preventDefault()
    this.props.handleSelectedKey(key)
    this.setState({ highLightEvents: false, openPopover: false, anchorEl: null })
    window.removeEventListener("keyup", this.HighlightKeyHandling);
  }
  
  /******************************* */
  /**** END TOPIC PALETTE METHODS ****/
  /******************************* */

  fetchMoreData = (currentLength) => {
    setTimeout(() => {
      const { data, selectedPhrases, totalPhrases, isCandidate, isNgram, isunClassified, selectedDiscoverdTopic, searchWord } = this.props;

      try {
        if(searchWord.trim().length >= 3) {
            this.setState({ ...this.state, hasMore: false })
            return
        }
        const page = Math.ceil(currentLength/50);
        const totalContext = cloneDeep(data).filter( p => p.phraseId === selectedPhrases[0]);
        
        if(selectedPhrases.length > 1) {
          this.setState({ ...this.state, hasMore: false })
          return
        };
        
        if(isunClassified) 
          if (data.length >= totalPhrases) {
            this.setState({ ...this.state, hasMore: false })
            return
          };
        if(currentLength >= get(totalContext[0],'frequency')) {
          this.setState({ ...this.state, hasMore: false })
          return
        }; 

        let docType = "topics";
        if (isCandidate) 
          docType = "candidates";
        if (isNgram) 
          docType = "ngrams";
          
        const payLoad = isunClassified ? {
          folderId: selectedDiscoverdTopic.value,
          phraseId: parseInt(selectedPhrases[0]),
          pageNo: parseInt(page) + 1, 
          searchWord: searchWord.trim().length > 0 ? searchWord: null,
        } : {
          folderId: selectedDiscoverdTopic.value, 
          phraseId: parseInt(selectedPhrases[0]), 
          contextPageNo: parseInt(page) + 1, 
          searchWord: searchWord.trim().length > 0 ? searchWord : null,
          docType: docType,
        }

        let payUrl = isunClassified ? GET_UNCLASSIFIED_DATA : GET_TOPICS_PHRASE_CONTEXT;

        client
          .get(payUrl, { params: payLoad })
          .then((res) => {
            if (res.data.status === SUCCESS) {
              this.fetchedPage = page + 1;
              if (typeof res.data.response !== "string") {
                if (res.data.response.length === 0) 
                  return;
                const newContext = isunClassified ? get(res.data.response, "docList") : get(res.data,"response");
                const newCount = isunClassified ? get(res.data.response, "count") : 0;
                if(!isCandidate && !isNgram && !isunClassified) 
                  this.props.updateTopicalContexts(newContext);
                else
                this.props.updateRequestPage(newContext, newCount);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch(err) {
        console.log(err);
      }
    }, 600)
  }

  handleSuggestion=(topic, selectedNode) => {
    this.handlePopoverClose();
    this.props.handleSuggestion(topic, selectedNode, this.selectedCtx)
  }

  handleNewTopic=(title, sentiment, text)=>{
    this.handlePopoverClose();
    this.props.handleNewTopicCreate(title, sentiment, text, this.selectedCtx)
  }

  handleAuditedPhrases = (id) => {
    this.props.updateAuditedPhrases(id)
  }

  handlePhraseCardClick = (key, phrase) => {
    this.props.handleClick(key, phrase)
  }

  handleListScroll = () => {
    try {
      if (this.cmContainer) {
        ReactDOM.unmountComponentAtNode(this.cmContainer);
        document.body.removeChild(this.cmContainer);
        this.cmContainer = null;
      }
  
      const selection = window.getSelection();
      if (selection.toString().trim()) {
        selection.removeAllRanges();
      }
    } catch(err) {
      console.log(err);
    }
  }

  componentDidMount() {
    this.getContainer();
    window.addEventListener("keyup", this.keyUpHandling);
    window.addEventListener("keydown", this.keyDownHandling);
  }

  componentWillUnmount() {
    if (this.cmContainer) {
      ReactDOM.unmountComponentAtNode(this.cmContainer);
      document.body.removeChild(this.cmContainer);
      this.cmContainer = null;
    }
    window.removeEventListener("keyup", this.keyUpHandling);
    window.removeEventListener("keydown", this.keyDownHandling);
  }

  componentDidUpdate(prevProps) {
    const { selectedTopic, loadedContextCount } = this.props
    if (selectedTopic.name !== "null" && selectedTopic.key === this.props.selectedKey) {
      if (this.props.selectedKey) {
        if (this.selectedText && !window.getSelection().isCollapsed) {
          this.handlePopupEvent("phrases", true)
        } else if (window.getSelection().isCollapsed) {
          this.moveSelectedPhrases()
        }
      }
      this.props.handleSelectedKey(null)
    }

    if (!isEqual(loadedContextCount, prevProps.loadedContextCount)) {
      this.forceUpdate()
    }

    if(prevProps.selectedPhrases[0] !== this.props.selectedPhrases[0]) {
      this.setState({ hasMore: true })
    }

  }

  render() {
    const { data, topics, selectedT,selectedPhrases, hIsActive, selectedTopic, totalPhrases, isCandidate, isNgram, isunClassified } = this.props;

    const checkVisibleLoadMore = () => {
      if(isCandidate || isNgram) {
        if(data.length > 0) {
          if(data.length < totalPhrases)
            return true
          else
            return false
        } else {
          return false
        }
      } else {
        return false
      }
    } 
    const visibleLoadMore = checkVisibleLoadMore();
    
    //modified context data for phrases
    if(isunClassified) {
      //modified context data for unclassified data
      this.modifiedContext = cloneDeep(data).map((p) => {
        return p.context.map(pctx => {
          pctx.phraseId = p.phraseId;
          pctx.candidates = pctx.candidates ? pctx.candidates : [];
          pctx.pos_tags = p.pos_tags ? p.pos_tags : [];
          return pctx
        })
      })
    } else {
      //modified context data for candidates/ngram/topic phrases
      const selT = topics.filter(t => t.id === selectedT[0]);

      this.modifiedContext = cloneDeep(data)
      .filter((phrase) => selectedPhrases.includes(phrase.phraseId))
      .map((p) => {
        return p.context.map((pctx) => {
          pctx.phrase = p.phrase;
          pctx.phraseId = p.phraseId;
          pctx.candidates = pctx.candidates ? pctx.candidates : [];
          pctx.pos_tags = p.pos_tags;
          pctx.topicSelected = selT.length > 0 ? selT[0].name : p.phrase;
          return pctx;
        });
      })
    }

    const contextList = this.modifiedContext.reduce((a, b) => a.concat(b), []);

    return (
      <Fragment>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {this.modalEvent === "delete"
              ? "Delete"
              : this.modalEvent === "rename"
                ? "Rename"
                : null}
          </DialogTitle>
          <DialogContent>
            {this.modalEvent === "delete" ? (
              this.state.removeHighlight 
              ? <span>
                Are you sure to delete&nbsp;
                <span style={{ color: "#f55c5c" }}>{this.selectedText}</span>&nbsp;phrase?
              </span> 
              : <span>
                <span>Are you sure to delete&nbsp;</span>
                <span style={{ color: "#f55c5c" }}>
                  { selectedPhrases.length > 1 && selectedPhrases.includes(this.modalItem.phraseId) 
                    ? selectedPhrases.length : this.modalItem.phrase }
                </span>
                &nbsp;
                { selectedPhrases.length > 1 && selectedPhrases.includes(this.modalItem.phraseId) 
                  ? "selected phrases" : "phrase" }
                <span>?</span>
              </span>
            ) : this.modalEvent === "rename" ? (
              <TextField
                fullWidth
                id="ml-rename"
                value={this.state.value}
                onChange={(e) => {
                  this.setState({ value: e.target.value });
                }}
                autoFocus
                onFocus={(e) => e.target.select()}
              />
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button
              color="primary"
              onClick={
                this.modalEvent === "rename"
                  ? this.handleRename
                  : this.modalEvent === "delete"
                    ? this.handleDelete
                    : null
              }
            >
              {this.modalEvent === "rename"
                ? "Rename"
                : this.modalEvent === "delete"
                  ? "Delete"
                  : null}
            </Button>
          </DialogActions>
        </Dialog>
        <div className="phrase-palette-division">
          <div className="phraseContextSection">
            <div className="phrase-title-section">
              {isunClassified ? null :
                <div className="horizontal-scroll">
                  <TransitionGroup enter={false}>
                    <CSSTransition
                      timeout={{ enter: 800, exit: 600 }}
                      classNames={clsx({
                        'atd-phrase': this.state.phraseMoved,
                        'atd-phrase-delete': this.modalEvent === 'delete'
                      })}
                      exit={Boolean(this.state.phraseToBeDeleted) || Boolean(this.state.phraseMoved)}
                    >
                      <ReactList
                        axis="x"
                        pageSize={30}
                        length={data.length}
                        itemRenderer={(index, key) =>
                          this.props.data[index] !== null ? (
                            <PhraseCard 
                              key={index}
                              phrase={this.props.data[index]}
                              selectedPhrases={this.props.selectedPhrases}
                              updateAuditedPhrases={this.handleAuditedPhrases}
                              handleClick={this.handlePhraseCardClick}
                              handlePhraseOptions={this.handlePhraseOptions}
                              handleNewTopicCreation={this.handleNewTopicCreation}
                              isNgram={this.props.isNgram}
                              handleNgramTopicClick={this.props.handleNgramTopicClick}
                            />
                          ) : null }
                      />
                    </CSSTransition>
                  </TransitionGroup>
                  { visibleLoadMore && 
                    <div className="loadmore-btn">
                      <div onClick={this.props.loadMorePhrases}>
                        {this.props.dataLoading ? 'Loading data...' : 'Load more'}
                      </div>
                  </div> }
                </div>
              }

              <Fragment>
                <div
                  className={clsx("phrase-context-section",{
                    'candidate-context': isCandidate,
                    'ngrams-context': isNgram,
                    'uncla': isunClassified,
                  })}
                  onScroll={this.handleListScroll}
                > 
                  <ContextHighlight 
                    selected={hIsActive ? selectedTopic.name : 'default'} 
                    color={hIsActive ? selectedTopic.hex : 'default'}
                  >
                    <div className="phrase-context-list" id="scrollableDiv" style={{height: '100%'}} data-testid='context-container'>
                        { isunClassified ? 
                          
                              <InfiniteScroll
                                dataLength={contextList.length}
                                next={()=>this.fetchMoreData(contextList.length)}
                                hasMore={this.state.hasMore}
                                loader={(contextList.length === 0) ? null :<div className="mainDrillBox">Loading…</div>}
                                height={'calc(100% - 10px)'}
                                scrollThreshold={1}
                                pullDownToRefresh={true}
                                refreshFunction={()=>{
                                  return contextList
                                }}
                                endMessage={
                                  <p style={{ textAlign: "center" }}>
                                    <b>Yay! You have seen it all</b>
                                  </p>
                                }
                              >
                             {contextList.map((i, index) => (
                                <UnclassifiedContext
                                key={`${"unclassifed-" + index}`}
                                docCtx={contextList[index]}
                                phraseMoved={this.state.phraseMoved}
                                atdState={this.props.atdState}
                                handleTextSelection={this.handleTextSelection}
                                markAsClassified={(docCtx)=>{ this.props.markAsClassified(docCtx) }}
                              />
                              
                             ))}
                           </InfiniteScroll>
                             : 
                             <InfiniteScroll
                                dataLength={contextList.length}
                                next={()=>this.fetchMoreData(contextList.length)}
                                hasMore={this.state.hasMore}
                                loader={(contextList.length === 0) ? null :<div className="mainDrillBox">Loading…</div>}
                                height={'calc(100% - 10px)'}
                                scrollThreshold={1}
                                pullDownToRefresh={true}
                                refreshFunction={()=>{
                                  return contextList
                                }}
                                endMessage={
                                  <p style={{ textAlign: "center" }}>
                                    <b>Yay! You have seen it all</b>
                                  </p>
                                }
                              >
                             {contextList.map((i, index) => (
                                <ContextWrapper 
                                    key={`${"context-" + index}`}
                                    context={contextList[index]}
                                    isCandidate={this.props.isCandidate}
                                    isNgram={this.props.isNgram}
                                    atdState={this.props.atdState}
                                    hIsActive={hIsActive}
                                    selectedTopic={selectedTopic}
                                    handleTextSelection={this.handleTextSelection}
                                />
                              
                             ))}
                           </InfiniteScroll>

                            }
                          </div>
                    
                  </ContextHighlight>
                  <Popover
                    id={this.state.openPopover ? 'context-popover' : undefined}
                    open={this.state.openPopover}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handlePopoverClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                      <Autocomplete 
                        filterLimit={3} 
                        suggestions={this.props.dataTopics.map((option) => {
                         return {name: option.name, id: option.id}
                        })} 
                        handleSuggestion={this.handleSuggestion}
                        handleNewTopic={this.handleNewTopic}
                      />
                  </Popover>
                </div>
              </Fragment>
              
            </div>
          </div>
          { this.props.atdState === "locked" ? <div className="atd-lock-icon"><Lock/></div> : null } 
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hIsActive: state.isActiveHighlight,
    selectedTopic: state.selectedTopic
  };
};

export default React.memo(connect(mapStateToProps, actions)(PhraseScroll));
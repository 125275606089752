import styled from "styled-components";

export default styled.div`
  mark[data-topic="${(props) => props.topics[0]}"] {
    background: ${(props) => props.colors[0]} !important;
      .side-topic {
        background: ${(props) => props.colors[0]} !important;
      }
  }
  mark[data-topic="${(props) => props.topics[1]}"] {
    background: ${(props) => props.colors[1]} !important;
      .side-topic {
        background: ${(props) => props.colors[1]} !important;
      }
  }
  mark[data-topic="${(props) => props.topics[2]}"] {
    background: ${(props) => props.colors[2]} !important;
      .side-topic {
        background: ${(props) => props.colors[2]} !important;
      }
  }
  mark[data-topic="${(props) => props.topics[3]}"] {
    background: ${(props) => props.colors[3]} !important;
      .side-topic {
        background: ${(props) => props.colors[3]} !important;
      }
  }
  mark[data-topic="${(props) => props.topics[4]}"] {
    background: ${(props) => props.colors[4]} !important;
      .side-topic {
        background: ${(props) => props.colors[4]} !important;
      }
  }
  mark[data-topic="${(props) => props.topics[5]}"] {
    background: ${(props) => props.colors[5]} !important;
      .side-topic {
        background: ${(props) => props.colors[5]} !important;
      }
  }
  mark[data-topic="${(props) => props.topics[6]}"] {
    background: ${(props) => props.colors[6]} !important;
      .side-topic {
        background: ${(props) => props.colors[6]} !important;
      }
  }
  mark[data-topic="${(props) => props.topics[7]}"] {
    background: ${(props) => props.colors[7]} !important;
      .side-topic {
        background: ${(props) => props.colors[7]} !important;
      }
  }
`;
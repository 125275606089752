import React, { Fragment, useState } from "react";

import { Menu, MenuItem } from "@material-ui/core";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";

import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { EMPTY_STRING } from "../../../Constants";

export default function DropdownSplit({
  id,
  label,
  value,
  sortType,
  options,
  handleChange,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateSort = (sortBy) => {
    const sb = sortBy === "none" ? EMPTY_STRING : sortBy,
      st = sortType === "asc" ? "desc" : "asc";
    handleChange(sb, st);
    handleClose();
  };

  return (
    <Fragment>
      <div className="display-inlineflex sort-head">
        <div style={{ height: "22px" }}>{label + ":"}&nbsp; </div>
        <div
          style={{ display: "flex", cursor: "pointer" }}
          onClick={handleClick}
        >
          <div
            style={{
              height: "24px",
              lineHeight: "26px",
              textTransform: "capitalize",
            }}
          >
            {value}&nbsp;
          </div>
          <div>
            {value === EMPTY_STRING ? (
              <ArrowDropDown />
            ) : sortType === "asc" ? (
              <ArrowDropUp />
            ) : (
              <ArrowDropDown />
            )}
          </div>
        </div>
      </div>

      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          value="none"
          className="none-btn"
          onClick={() => {
            updateSort("none");
          }}
        >
          None
        </MenuItem>
        {options.map((li, i) => {
          return (
            <MenuItem
              key={i}
              value={li}
              className={value === li ? "cust-sel selected" : "cust-sel"}
              onClick={() => {
                updateSort(li);
              }}
            >
              <span>{li}</span> &nbsp;
              {value === li ? (
                sortType === "asc" ? (
                  <ArrowUpward />
                ) : (
                  <ArrowDownward />
                )
              ) : null}
            </MenuItem>
          );
        })}
      </Menu>
    </Fragment>
  );
}

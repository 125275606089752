import React from "react";

const Image = ({ icon }) => {
  const Img = require(`../../assets/icons/${icon}.png`);
  return (
    <div className="mediaHolder">
      <img className={icon} src={Img} alt={icon} />
    </div>
  );
};

export default Image;

import React, { useState, useEffect } from "react";
import {
  Paper,
  IconButton,
  Button,
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  makeStyles,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Tooltip,
  Zoom,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { toast } from "react-toastify";
import {
  POPULATE_BRANDS_COMPARISON_CACHE,
  BRANDS_FOR_COMPARISON,
  CLEAR_BRANDS_COMPARISON,
} from "../../../Routes";
import {
  EMPTY_ARRAY,
  SUCCESS,
  WARN
} from "../../../Constants";
import { objToArray } from "../../../_helpers/utils";
import client from "../../../_helpers/client";
import DataPreloader from "../../../containers/Preloader/DataPreloader";
import brandImg from "../../../assets/brand-images/default-image.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#B3719A",
    height: "58px",
  },
  title: {
    flex: 1,
    marginLeft: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    minHeight: "240px",
    maxHeight: "300px",
    position: "relative",
  },
}));

const BrandsList = ({
  dialogOpen,
  handleDialogClose,
  updateValues,
  selectedBrandId,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [availableBrands, setAvailableBrands] = useState(EMPTY_ARRAY);
  const [selectedListId, setSelectedListId] = useState(EMPTY_ARRAY);
  const [modelId, setModelId] = useState(null);

  //Get all Brands with Models for Comparison
  const getComparisonBrands = () => {
    client
      .get(BRANDS_FOR_COMPARISON)
      .then(async (res) => {
        if (res.data.status === SUCCESS) {
          let brandsUserMap = res.data.response.brandsUserMap;
          const d = await objToArray(brandsUserMap);
          let m = null;
          d.map((el) => {
            el.value.map((li) => {
              if (selectedBrandId.includes(li.brandId)) m = li.modelId;
              return;
            });
          });
          setModelId(m);
          setAvailableBrands(d);
          setLoading(false);
        } else {
          setLoading(false);
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  //Fetch Brands compare Data
  const handleBrandComparison = () => {
    setLoading(true);
    client
      .post(POPULATE_BRANDS_COMPARISON_CACHE, { brandIds: selectedListId })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          updateValues(res.data.response);
          setLoading(false);
          handleDialogClose(false);
        } else {
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  //Clear Brands for comparison
  const handleClear = () => {
    client
      .get(CLEAR_BRANDS_COMPARISON)
      .then((res) => {
        if (res.data.status === SUCCESS) {
          updateValues(res.data.response);
          setSelectedListId(EMPTY_ARRAY);
          setModelId(null);
        } else {
          if (res.data.status === WARN) toast.warn(res.data.message);
          else toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //update selected brands
  const handleSelected = (brandId) => {
    const selectedIndex = selectedListId.findIndex((el) => el === brandId);
    let newSelected = EMPTY_ARRAY;

    if (selectedIndex === -1) {
      if (selectedListId.length >= 5) {
        toast.warn("Max 5 brands are allowed to compare.");
        return;
      }
      newSelected = newSelected.concat(selectedListId, brandId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedListId.slice(1));
    } else if (selectedIndex === selectedListId.length - 1) {
      newSelected = newSelected.concat(selectedListId.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedListId.slice(0, selectedIndex),
        selectedListId.slice(selectedIndex + 1)
      );
    }
    setSelectedListId(newSelected);
  };

  //on select/deselect brands list
  const handleBrandSelect = (e, brand) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedListId.length === 0) {
      setModelId(brand.modelId);
      handleSelected(brand.brandId);
    } else if (modelId === brand.modelId) {
      handleSelected(brand.brandId);
    } else return;
  };

  //check brand selected or not
  const isSelected = (id) => {
    let check = selectedListId.findIndex((el) => parseInt(el) === parseInt(id));
    if (check !== -1) return true;
    else return false;
  };

  //check brand disabled or not
  const isDisabled = (id) => {
    if (selectedListId.length === 0) {
      return false;
    } else {
      if (modelId === id) return false;
      else return true;
    }
  };

  //Render Brands Grid
  const renderAvailableBrands = (
    <div className="availableBrands">
      {availableBrands.length === 0 ? (
        <div className="noScoredBrands">
          <h3>
            You don't have any scored brands.
            <br />
            <small>Please score at least two brands to compare.</small>
          </h3>
        </div>
      ) : (
        availableBrands.map((li, i) => {
          return (
            <div key={i} className="brand-model">
              <h2>{li.column[0]}</h2>
              <FormGroup row>
                {li.value.map((el, j) => {
                  return (
                    <Paper elevation={1} key={j}>
                      <div className={"brand-grid " + isDisabled(el.modelId)}>
                        <img src={brandImg} alt={el.brandName} />
                        <br />
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={isDisabled(el.modelId)}
                              checked={isSelected(el.brandId)}
                              name={el.brandName}
                              onClick={(e) => handleBrandSelect(e, el)}
                            />
                          }
                          label={el.brandName}
                        />
                      </div>
                    </Paper>
                  );
                })}
              </FormGroup>
            </div>
          );
        })
      )}
    </div>
  );

  useEffect(() => {
    if (dialogOpen) {
      console.log("selected ids: " + selectedBrandId);
      setLoading(true);
      getComparisonBrands();
      setSelectedListId(selectedBrandId);
    }
  }, [dialogOpen]);

  return (
    <React.Fragment>
      <Dialog
        id="available-brands"
        fullScreen
        open={dialogOpen}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Tooltip title="Close" TransitionComponent={Zoom}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => handleDialogClose(false)}
                aria-label="close"
              >
                <Close />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" className={classes.title}>
              Models and Brands
            </Typography>

            <Button
              className="r-btn"
              color="inherit"
              disabled={selectedListId.length === 0}
              onClick={() => handleClear(true)}
              data-testid='clearBtn'
            >
              Clear
            </Button>
            <Button
              className="r-btn cmp-btn"
              color="inherit"
              disabled={selectedListId.length <= 1}
              onClick={handleBrandComparison}
            >
              Compare
            </Button>
          </Toolbar>
        </AppBar>
        {loading ? <DataPreloader /> : renderAvailableBrands}
      </Dialog>
    </React.Fragment>
  );
};

export default BrandsList;

import { SET_SAVE_LOCATION } from '../actions/types'
import get from 'lodash.get'

let initialState = 'cloud'

export default function loadAtd(state = initialState, action) {
  const value = get(action, 'value')

  switch (action.type) {
    case SET_SAVE_LOCATION: {
      return value
    }
    default: {
      return state
    }
  }
}